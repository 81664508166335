import Vue from 'vue';
import router from '@/router.js';
import findIndex from 'lodash.findindex';
import moment from 'moment-timezone';

const state = {
	modalAccreditation: false,
	accreditation: {
		title: 'AKREDYTACJE',
		header: [
			{
				text: 'Nazwa',
				value: 'title',
				class: 'central__table__header',
				width: '75%',
			},
			// {
			//     text: 'Data',
			//     align: 'left',
			//     value: 'dataArchive',
			//     class: 'central__table__header',
			//     width: '115px',
			// },
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Menager',
				value: 'menager',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Akcja',
				align: 'right',
				width: '10%',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
			responsiblePerson: [],
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
		},
	},
	fieldsNames: {
		title: 'Nazwa',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		responsiblePerson: 'Osoba odpowiedzialna',
	},
};

const getters = {
	getAccreditation: (state) => state.accreditation,
	getAccreditationModal: (state) => state.modal.fields,
	getAccreditationError: (state) => state.modal.errors,
	getAccreditationDetails: (state) => state.modal.details,
	getAccreditationFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	createAccreditation({ dispatch, state, commit }, payload) {
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		} else {
			Vue.axios({
				method: 'POST',
				url: '/accreditation',
				data: {
					...state.modal.fields,
					...payload,
				},
			})
				.then((response) => {
					dispatch('fetchData', {
						page: 1,
						pageSize: 11,
						search: '',
						sortBy: [],
						sortDesc: [],
						routeName: 'accreditation',
					});
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie dodano akredytacje',
					});
				})
				.catch((error) => {
					commit('setErrors', {
						route: 'accreditation',
						err: error.response.data.error,
					});
				});
		}
	},

	accreditationDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/accreditation/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setAccreditationModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setAccreditationDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'accreditation',
					err: error.response.data.error,
				});
			});
	},

	updateAccreditation({ state, commit }, payload) {
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		} else {
			Vue.axios({
				method: 'PUT',
				url: `/accreditation/edit/${payload}`,
				data: {
					...state.modal.fields,
				},
			})
				.then((response) => {
					commit('setAccreditationDeatils', response.data.item);
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano informacje',
					});
				})
				.catch((error) => {
					console.log(error);
					commit('setErrors', {
						route: 'accreditation',
						err: error.response.data.error,
					});
				});
		}
	},
};

const mutations = {
	setAccreditationDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setAccreditationModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	removeUserAccreditation(state, payload) {
		let arr = state.modal.fields.responsiblePerson;
		let index = findIndex(arr, (e) => e == payload);
		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
