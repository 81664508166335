import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';
import findIndex from 'lodash.findindex';
const state = {
	modalCurrentInformation: false,
	CurrentInformation: {
		title: 'INFORMACJE BIEŻĄCE',
		header: [{
				text: 'Data publikacji',
				align: 'left',
				value: 'startDate',
				class: 'central__table__header',
				width: '160px',
			},
			{
				text: 'Treść',
				value: 'name',
				class: 'central__table__header',
				width: '55%',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Wprowadził',
				value: 'createdBy',
				class: 'central__table__header',
				width: '20%',
			},
			{
				text: 'Załącznik',
				align: 'center',
				value: 'informations',
				width: '6%',
				sortable: false,
				class: 'central__table__header',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				width: '5%',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	dashbordInformation: [],
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
			homePage: null,
			startDate: null,
			priority: {
				priorityName: '',
				background: '',
			},
			to: {
				users: [],
				departments: [],
			},
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
			homePage: null,
			priority: {
				priorityName: '',
			},
			to: '',
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
			startDate: null,
			homePage: null,
			priority: {
				priorityName: '',
			},
		},
	},
	fieldsNames: {
		title: 'Tytuł informacji',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		homePage: 'Ustaw na stronie domowej',
		startDate: 'Data publikacji',
		notification: 'Wyślij powiadomienie do wszystkich',
		priority: 'Priorytet',
		background: 'Kolor tła',
		priorityName: 'Priorytet',
	},
};

const getters = {
	getCurrentInformation: (state) => state.CurrentInformation,
	getCurrentInformationModal: (state) => state.modal.fields,
	getUpcomingEvents: (state) => state.dashbordInformation,
	getCurrentInformationError: (state) => state.modal.errors,
	getCurrentInformationDetails: (state) => state.modal.details,
	getCurrentInformationFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	createCurrentInformation({
		dispatch,
		state,
		commit
	}, payload) {
		commit('resetErrors', 'currentInformations');
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		}
		if (state.modal.fields.priority === '') {
			state.modal.errors.priority = 'To pole jest wymagane';
		} else {
			Vue.axios({
					method: 'POST',
					url: '/current-informations',
					data: {
						...state.modal.fields,
						...payload,
					},
				})
				.then((response) => {
					// let users = [];
					// response.data.item.to.departments.forEach(department => {
					//     users.push(...department.users);
					// });
					// users.push(...response.data.item.to.users);
					// dispatch('sendMail', {
					//     case: 'newInformation',
					//     receivers: users,
					//     info: {
					//         description: response.data.item.information
					//     }
					// });
					dispatch('fetchData', {
						page: 1,
						pageSize: 11,
						search: '',
						sortBy: [],
						sortDesc: [],
						routeName: 'current-informations',
					});
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie dodano informację',
					});
				})
				.catch((error) => {
					console.log(error);
					commit('setErrors', {
						route: 'currentInformations',
						err: error.response.data.error,
					});
				});
		}
	},

	async fetchDashboardCurrentInformation({
		commit,
		state
	}, payload) {
		await Vue.axios({
				method: 'GET',
				url: `/current-informations/dashboard/information`,
			})
			.then((response) => {

				commit('setCurrentInformation', response.data.item);
				// if (payload.next) payload.next();
			})
			.catch((error) => {});
	},

	currentInformationsDeatils({
		commit,
		dispatch
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/current-informations/${payload.id}`,
			})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setCurrentInformationModal', response.data.item);
				} else if (payload.sendEmail == true) {
					let users = [];
					response.data.item.to.departments.forEach((department) => {
						users.push(...department.users);
					});
					users.push(...response.data.item.to.users);
					dispatch('sendMail', {
						case: 'newInformation',
						receivers: users,
						info: {
							description: response.data.item.information,
							createdBy: `${response.data.item.createdBy.name +
								' ' +
								response.data.item.createdBy.lastname}`,
						},
					});
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setCurrentInformationDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);

				commit('setErrors', {
					route: 'currentInformations',
					err: error.response.data.error,
				});
			});
	},

	updatecurrentInformations({
		state,
		commit
	}, payload) {
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		} else {
			Vue.axios({
					method: 'PUT',
					url: `/current-informations/edit/${payload}`,
					data: {
						...state.modal.fields,
					},
				})
				.then((response) => {
					commit('setCurrentInformationDeatils', response.data.item);
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano informacje',
					});
				})
				.catch((error) => {
					console.log(error);
					commit('setErrors', {
						route: 'currentInformations',
						err: error.response.data.error,
					});
				});
		}
	},
};

const mutations = {
	setCurrentInformationDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate ?
			moment(payload.archiveDate).format('YYYY-MM-DD') :
			'';
	},
	setAllIUsers(state, payload) {
		state.modal.fields.to.users = [];
		state.modal.fields.to.departments = [];

		payload.forEach((item) => {
			state.modal.fields.to.users = [...state.modal.fields.to.users, item._id];
		});
	},
	removeIACPeople(state, payload) {
		let arr = state.modal.fields.to[payload.section];
		console.log(state);

		let index = findIndex(arr, (e) => e == payload.id);
		arr.splice(index, 1);
	},
	setCurrentInformationModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate ?
			moment(payload.archiveDate).format('YYYY-MM-DD') :
			'';
		state.modal.fields.startDate = payload.startDate ?
			moment(payload.startDate).format('YYYY-MM-DD') :
			'';

	},
	setCurrentInformation(state, payload) {
		const events = []
		payload.forEach((element) => {
			if (element.homePage == true && element.status.possibleStatus.name == "Aktualny") {
				events.push(element);
			}
		});
		state.dashbordInformation.items = [];
		state.dashbordInformation = events;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};