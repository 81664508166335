<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved  ? 'color: #9e9e9e' : ''"
    >
      <!-- === === INDEX === === -->
      <td style="font-size: 14px">{{item.index}}</td>
      <!-- === === DATA === === -->
      <td>
        <v-list-item-title
          style="font-size: 14px; max-width: 380px"
          @click="$router.push(`/contacts/${item.contractorAgreement._id}`)"
        >{{ item.contractorAgreement ? item.contractorAgreement.name : 'Brak'}}</v-list-item-title>

        <v-list-item-subtitle
          style="font-size: 12px; max-width: 380px"
          class="grey--text lighten-1"
          @click="$router.push(`/contacts/${item.contractorAgreement._id}`)"
        >{{item.responsibleAgreement ? showcorrectResponsible(item.responsibleAgreement.name, item.responsibleAgreement.lastname,item.responsibleAgreement.phoneNumber,item.responsibleAgreement.email) : 'Brak'}}</v-list-item-subtitle>
      </td>

      <!-- === === NUMER UMOWY === === -->
      <td style="font-size: 14px">{{item.nrAgreement}}</td>

      <!-- === === TYTUL UMOWY === === -->
      <td style="font-size: 14px">{{item.titleAgreement}}</td>

      <!-- === === POCZĄTEK UMOWY === === -->
      <td
        style="font-size: 14px"
      >{{item.databeginningAgreement ? item.databeginningAgreement : 'Brak'}}</td>

      <!-- === === KONIEC UMOWY === === -->
      <td style="font-size: 14px">{{item.dataendAgreement ? item.dataendAgreement : 'Brak'}}</td>

      <!-- === === STATUS === === -->
      <td style=" max-width: 100px">
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
      </td>
      <!-- === === IKONA - ANEKS === === -->
      <td style="width: 50px">
        <v-tooltip top v-if="item.annexAgreement">
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on" color="green" size="28" class="mr-1">
              <span class="white--text">A</span>
            </v-avatar>
          </template>
          <span>Aneks</span>
        </v-tooltip>
      </td>
      <!-- === === AKCJA === === -->
      <td style="text-align: right; width: 25px; min-width: 25px; ">
        <v-btn icon small @click="$router.push({path:`/agreement/${item._id}`})">
          <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
  props: ["items"],
  data: () => ({}),
  computed: { ...mapGetters(["getPermission", "getUser"]) },
  methods: {
    showcorrectResponsible(name, lastname, phoneNumber, email) {
      let Responsible;
      if (name != undefined && name != "") {
        Responsible = name;
      }
      if (lastname != undefined && lastname != "") {
        Responsible = Responsible + " " + lastname;
      }
      if (email != undefined && email != "") {
        Responsible = Responsible + " • " + email;
      }
      if (phoneNumber != undefined && phoneNumber != "") {
        Responsible = Responsible + " • " + phoneNumber;
      }
      return `${Responsible}`;
    },
  },
};
</script>
