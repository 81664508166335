<template>
    <v-navigation-drawer
        right
        src="@/assets/img/global/menu-background.jpg"
        app
        v-if="$route.name !== 'dashboard'"
        clipped
        touchless
        permanent
        mini-variant-width="50"
        :mini-variant.sync="mini"
        width="384"
        :class="getLayoutWSD.wsd === true ?  'navigation-WSD' : 'navigation'"
        style="transition: 0.5s"
    >
        <v-tabs
            class="rightBox"
            :slider-color="getLayoutWSD.wsd === true ? '#ffa63f' : '#19aa8d'"
            slider-size="3"
            vertical
            icons-and-text
            v-model="model"
        >
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-tab v-on="on" class="rightBox__panel" @click="RBTasks(mini)" href="#tasks">
                        <v-badge
                            class="badgeRB"
                            color="#ffcb00"
                            v-if="tasksCount > 0"
                            :content="tasksCount"
                            offset-x="-4"
                            offset-y="-16"
                            overlap
                        ></v-badge>
                        <v-icon color="white">mdi-format-list-checks</v-icon>
                    </v-tab>
                </template>
                <span>Zadania</span>
            </v-tooltip>
            <!-- <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-tab v-on="on" class="rightBox__panel">
            <v-icon color="white">mdi-calendar-multiple</v-icon>
          </v-tab>
        </template>
        <span>Przypomnienia</span>
            </v-tooltip>-->

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-tab
                        v-on="on"
                        class="rightBox__panel"
                        @click="RBNotifications(mini)"
                        href="#reminders"
                    >
                        <template v-slot:badge>
                            <span>6</span>
                        </template>
                        <v-badge
                            class="badgeRB"
                            color="#ffcb00"
                            v-if="alertsCount > 0"
                            :content="alertsCount"
                            offset-x="-4"
                            offset-y="-16"
                            overlap
                        ></v-badge>

                        <v-icon color="white">mdi-bell-ring</v-icon>
                    </v-tab>
                </template>
                <span>Alerty</span>
            </v-tooltip>

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-tab v-on="on" class="rightBox__panel" href="#contacts">
                        <v-icon color="white">mdi-account-box</v-icon>
                    </v-tab>
                </template>
                <span>Kontakty</span>
            </v-tooltip>

            <v-tab-item class="rightBox__height" value="tasks">
                <transition name="fade" v-on:enter="enter">
                    <Tasks v-show="!mini" />
                </transition>
            </v-tab-item>
            <v-tab-item class="rightBox__height" value="reminders">
                <transition name="fade" v-on:enter="enter">
                    <Reminders v-show="!mini" />
                </transition>
            </v-tab-item>

            <!-- <v-tab-item v-if="!notificationToDelete" class="rightBox__height">
        <transition name="fade" v-on:enter="enter">
          <Notifications v-show="!mini" />
        </transition>
            </v-tab-item>-->
            <v-tab-item class="rightBox__height" value="contacts">
                <transition name="fade" v-on:enter="enter">
                    <Contacts v-show="!mini" />
                </transition>
            </v-tab-item>
        </v-tabs>
        <v-btn class="rightBox__btnRight" icon @click.stop="mini = !mini">
            <v-icon color="white" v-if="mini">mdi-chevron-left</v-icon>
            <v-icon color="white" v-else>mdi-chevron-right</v-icon>
        </v-btn>
    </v-navigation-drawer>
</template>

<script>
// =====IMPORT====
import Tasks from "@/components/Global/RightBox/Tasks.vue";
import Reminders from "@/components/Global/RightBox/Reminders.vue";
import Notifications from "@/components/Global/RightBox/Notifications.vue";
import Contacts from "@/components/Global/RightBox/Contacts.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            notificationToDelete: false,
            mini: true,
            overlap: true,
            show: false,
            model: "tasks",
        };
    },
    components: {
        Tasks,
        Reminders,
        Contacts,
        Notifications,
    },
    methods: {
        ...mapActions(["RBTasks", "RBNotifications"]),
        fadeMe: function () {
            this.mini = !this.mini;
        },

        enter: function (el, done) {
            var that = this;

            setTimeout(function () {
                that.mini = false;
            }, 200);
        },
    },
    computed: {
        ...mapGetters([
            "getLayoutWSD",
            "getRightBoxTasks",
            "getRBNotifications",
        ]),
        tasksCount: {
            get() {
                return this.getRightBoxTasks.filter((el) => el.isDone == false)
                    .length;
            },
        },
        alertsCount: {
            get() {
                const moment = require("moment");
                const today = moment().startOf("day");
                let arr = this.getRBNotifications.filter(
                    (el) =>
                        new Date(el.dueDate) >=
                        moment(today).startOf("day").toDate() <=
                        moment(today).endOf("day").toDate()
                ).length;
                return arr;
            },
        },
    },
    watch: {
        mini(newVal) {
            if (newVal === false) {
                switch (this.model) {
                    case "tasks":
                        this.RBTasks(newVal);
                        break;
                    case "reminders":
                        this.RBNotifications(newVal);
                        break;
                }
            }
        },
    },
};
</script>
