<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="d-flex flex-wrap pa-0">
        <v-col cols="12" md="12">
          <Separator :text="'Wyszukiwanie części'" />
        </v-col>
        <v-col cols="12" md="12" class="d-flex">
          <v-col cols="12" md="4">
            <v-autocomplete
              :items="getSearchedPartsItems"
              item-text="producent"
              dense
              label="Index producenta"
              return-object
              @keyup="searchPartModal($event)"
            >
              <!-- <template slot="no-data" class="ml-4 d-flex">
                                <span class="px-3" style="font-size: 14px">Dodaj część</span>
                                <v-btn
                                    height="25px"
                                    width="25px"
                                    @click="dialog = true"
                                    text
                                    icon
                                    color="#19aa8d"
                                >
                                    <v-icon size="18">mdi-plus</v-icon>
                                </v-btn>
              </template>-->

              <template v-slot:item="data">
                <v-list-item @click="setTableParts(data.item)">{{ data.item.producent }}</v-list-item>
              </template>
              <template slot="prepend">
                <img src="@/assets/img/global/search.png" width="16" height="16" alt class="mt-1" />
              </template>
            </v-autocomplete>
          </v-col>
        </v-col>
        <v-data-table
          :headers="headers"
          :items="getPartsItems"
          height="190"
          style="width: 100%;"
          sort-by="name"
          class="pb-5"
          fixed-header
          hide-default-footer
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="300px" style="z-index: 999">
              <v-card>
                <v-card-title>
                  <span class="headline">Informacje</span>
                </v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <div class="input-effect price">
                        <span class="mt-1 mb-1">Cena</span>
                        <money
                          style="text-align: left; font-size: 14px; width: 110px; border-bottom: 1px solid #949494; color: #272727; "
                          v-model="editInfo.price"
                          v-bind="money"
                        ></money>
                        <span class="focus-border"></span>
                      </div>
                      <!-- <v-text-field v-model="editInfo.quantity" label="Ilość"></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editInfo.quantity" label="Ilość"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="buttons mr-3" @click="close">Anuluj</v-btn>

                  <v-btn dark class="buttons buttons--add" @click="saveInfo">Zapisz</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- {{item.model}} -->
          <!-- <template v-slot:item.model="{ item }">{{item.model.model}}</template> -->
          <template v-slot:item.total="{ item }">
            <span
              v-if="item.quantity && item.price"
            >{{ (item.quantity * item.price).toFixed(2) }} PLN</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.price="{ item }">
            <span v-if="item.price">
              <money
                v-if="item.price"
                style="text-align: left; width: 100px; font-size: 14px;color: #272727; "
                v-model="item.price"
                v-bind="money"
              ></money>
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="removeTablePart(item.producent)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <v-col cols="12" md="12" class="pa-5">
          <Separator :text="'Rozliczenie części'" />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            dense
            v-model="getServicePartsModal.invoiceNumber"
            label="Nr faktury sprzedanych części"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            dense
            label="Uwagi"
            v-model="getServicePartsModal.description"
            no-resize
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                v-model="getServicePartsModal.inside.date"
                label="Data rozliczenia wewnętrznego"
                prepend-icon="mdi-calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="getServicePartsModal.inside.date"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="getServicePartsModal.inside.number"
            dense
            label="Numer dokumentu wewn."
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                v-model="getServicePartsModal.outside.date"
                label="Data rozliczenia zewnętrznego"
                prepend-icon="mdi-calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="getServicePartsModal.outside.date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- {{getServicePartsModal.inside}} -->
        <v-col cols="12" md="3">
          <v-text-field
            v-model="getServicePartsModal.outside.number"
            dense
            label="Numer dokumentu zewn."
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import debounce from "lodash.debounce";
import { Money } from "v-money";
export default {
  components: {
    Separator
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    date2: new Date().toISOString().substr(0, 10),
    menu2: false,
    money: {
      decimal: ".",
      thousands: ",",
      suffix: " PLN",
      precision: 2,
      masked: false
    },
    menu3: false,
    dialog: false,
    items: ["Tak", "Nie"],
    model: ["Lexmark", "Sharp"],
    headers: [
      {
        text: "Index",
        align: "left",
        sortable: false,
        value: "producent",
        width: 80
      },
      { text: "Nazwa", value: "name", width: 130 },
      { text: "Zamiennik", value: "replacement", width: 110 },
      { text: "Cena", value: "price", width: 80 },
      { text: "Ilość", value: "quantity", width: 80 },
      { text: "Razem", value: "total", width: 90 },
      { text: "Akcja", value: "action", sortable: false, width: 80 }
    ],
    editedIndex: -1,
    editInfo: {
      id: "",
      quantity: "",
      price: ""
    }
  }),

  computed: {
    ...mapGetters([
      "getSearchedPartsItems",
      "getPartsItems",
      "getServicePartsModal"
    ])
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["searchPart"]),
    ...mapMutations(["setTableParts", "removeTablePart", "setPartInfo"]),

    searchPartModal: debounce(function(e) {
      this.searchPart(e.target.value);
    }, 500),

    editItem(item) {
      this.editInfo.id = item._id;
      this.editInfo.price = item.price;
      this.editInfo.quantity = item.quantity;
      this.dialog = true;
    },

    saveInfo() {
      this.setPartInfo(this.editInfo);
      this.dialog = false;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editInfo.id = "";
        this.editInfo.quantity = "";
        this.editInfo.price = "";
        this.editedIndex = -1;
      }, 300);
    }
  }
};
</script>
