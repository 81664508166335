<template>
    <div class="AE__wrapper">
        <v-row>
            <v-col cols="12" md="12">
                <v-select
                    :items="getWarehouseCompanyOptions"
                    v-model="getWarehouseFields.company"
                    dense
                    :label="getWarehouseFieldsNames.company"
                ></v-select>
            </v-col>
            <Separator text="Rotacja i pojazd" />
            <v-col cols="12" md="6">
                <v-select
                    :items="getWarehouseRotationOptions"
                    v-model="getWarehouseFields.rotationType"
                    dense
                    :label="getWarehouseFieldsNames.rotationType"
                    @change="
                        evalRotationFields(getWarehouseFields.rotationType)
                    "
                    :error-messages="getWarehouseErrors.rotationType"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="getWarehouseCarOptions"
                    v-model="getWarehouseFields.carOwnership"
                    dense
                    :label="getWarehouseFieldsNames.carOwnership"
                    @change="
                        evalCarOwnershipFields(getWarehouseFields.carOwnership)
                    "
                ></v-select>
            </v-col>

            <v-col cols="12" md="6">
                <v-autocomplete
                    :items="getDropdown.items.constructions"
                    v-if="getWarehouseFields.rotationType == 'Wydanie'"
                    dense
                    :loading="getDropdown.loading"
                    :hide-no-data="getDropdown.hideNoData"
                    :label="getWarehouseFieldsNames.constructionId"
                    v-model="getWarehouseFields.constructionId"
                    item-value="_id"
                    item-text="name"
                ></v-autocomplete>
                <v-autocomplete
                    v-if="getWarehouseFields.rotationType == 'Dostawa'"
                    dense
                    :items="getDropdown.items.contacts"
                    :loading="getDropdown.loading"
                    :hide-no-data="getDropdown.hideNoData"
                    :label="getWarehouseFieldsNames.contactId"
                    v-model="getWarehouseFields.contactId"
                    item-value="_id"
                    item-text="name"
                ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
                <v-text-field
                    v-if="getWarehouseFields.carOwnership == 'Obcy'"
                    v-model="getWarehouseFields.carPlate"
                    dense
                    :label="getWarehouseFieldsNames.carPlate"
                ></v-text-field>

                <v-autocomplete
                    dense
                    v-if="getWarehouseFields.carOwnership == 'Własny'"
                    :label="getWarehouseFieldsNames.carId"
                    item-value="_id"
                    v-model="getWarehouseFields.carId"
                    :items="getDropdown.items.cars"
                    @change="setDriver()"
                    :filter="filter"
                >
                    <template v-slot:selection="data">
                        {{ data.item.model + " " + data.item.plate }}
                    </template>
                    <template v-slot:item="data">
                        <div>{{ data.item.model + " " + data.item.plate }}</div>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
                <v-autocomplete
                    v-if="getWarehouseFields.carOwnership == 'Własny'"
                    :items="test"
                    v-model="getWarehouseFields.carDriver"
                    dense
                    :label="getWarehouseFieldsNames.carDriver"
                    return-object
                ></v-autocomplete>

                <v-text-field
                    v-else
                    v-model="getWarehouseFields.carDriver"
                    dense
                    :label="getWarehouseFieldsNames.carDriver"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="getWarehouseFields.wzDate"
                            dense
                            :label="getWarehouseFieldsNames.wzDate"
                            prepend-icon="mdi-calendar"
                            v-on="on"
                            readonly
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getWarehouseFields.wzDate"
                        @input="menu = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <Separator />

            <v-col cols="12" md="6">
                <v-text-field
                    v-model="getWarehouseFields.tareWeight"
                    dense
                    :label="getWarehouseFieldsNames.tareWeight"
                    :error-messages="getWarehouseErrors.tareWeight"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="getWarehouseFields.grossWeight"
                    dense
                    :label="getWarehouseFieldsNames.grossWeight"
                    :error-messages="getWarehouseErrors.grossWeight"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="getWarehouseFields.weighingDate"
                            dense
                            :label="getWarehouseFieldsNames.weighingDate"
                            prepend-icon="mdi-calendar"
                            v-on="on"
                            readonly
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getWarehouseFields.weighingDate"
                        @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="6">
                <v-autocomplete
                    :items="getDropdown.items.materials"
                    v-model="getWarehouseFields.materialType"
                    :label="getWarehouseFieldsNames.materialType"
                    item-value="_id"
                    item-text="name"
                    :error-messages="getWarehouseErrors.materialType"
                >
                    <template v-slot:item="{ item }">
                        <span>
                            {{
                                `${
                                    item.name && item.name != null
                                        ? item.name + " - "
                                        : ""
                                }` +
                                ` ilość: ${
                                    item.currentVolume
                                        ? Number(item.currentVolume).toFixed(2)
                                        : "0"
                                } [${item.unit}]`
                            }}
                        </span>
                    </template>
                    <template v-slot:selection="{ item }">
                        <span>{{ item.name }}</span>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="getWarehouseFields.weighingDone"
                    :label="getWarehouseFieldsNames.weighingDone"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md="12">
                <v-text-field
                    dense
                    v-model="getWarehouseFields.description"
                    :label="getWarehouseFieldsNames.description"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        menu: false,
        menu2: false,
        money: {
            decimal: ".",
            thousands: ".",
            suffix: "",
            precision: 2,
            masked: false,
        },
    }),
    components: { Separator },

    computed: {
        ...mapGetters([
            "getWarehouseFieldsNames",
            "getWarehouseFields",
            "getDropdown",
            "getWarehouseCompanyOptions",
            "getWarehouseRotationOptions",
            "getWarehouseCarOptions",
            "getWarehouseErrors",
        ]),
        test() {
            let data = [];
            this.getDropdown.items.users.forEach((item) => {
                data.push(`${item.name} ${item.lastname}`);
            });
            return data;
        },
    },
    methods: {
        ...mapActions(["fetchItems"]),
        evalRotationFields(rotationType) {
            if (rotationType == "Wydanie") {
                this.getWarehouseFields.contactId = null;
            } else if (rotationType == "Dostawa") {
                this.getWarehouseFields.constructionId = null;
            }
        },
        evalCarOwnershipFields(carOwnership) {
            if (carOwnership == "Własny") {
                this.getWarehouseFields.carPlate = "";
                this.getWarehouseFields.carDriver = "";
            } else if (carOwnership == "Obcy") {
                this.getWarehouseFields.carDriver = "";
                this.getWarehouseFields.carId = null;
            }
        },
        setDriver() {
            let car = this.getDropdown.items.cars.filter((element) => {
                return element._id == this.getWarehouseFields.carId;
            });
            this.getWarehouseFields.tareWeight = Number(car[0].weight);
            if (car[0].driver) {
                this.getWarehouseFields.carDriver =
                    car[0].driver.name + " " + car[0].driver.lastname;
                // this.getWarehouseFields.carDriver = {};
                // this.getWarehouseFields.carDriver.name = car[0].driver.name;
                // this.getWarehouseFields.carDriver._id = car[0].driver._id;
                // this.getWarehouseFields.carDriver.lastname =
                // car[0].driver.lastname;
                // this.getWarehouseFields.carDriver.fullName = `${car[0].driver.name} ${car[0].driver.lastname}`;
            } else this.getWarehouseFields.carDriver = "";
            this.getWarehouseFields.carPlate = car[0].plate;
        },
        filter(item, queryText, itemText) {
            return (
                item.model
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.plate
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
    },
    beforeMount() {
        this.getWarehouseFields.wzDate = new Date().toISOString().substr(0, 10);
        this.getWarehouseFields.weighingDate = new Date()
            .toISOString()
            .substr(0, 10);
        // this.getDropdown.items.users = this.getDropdown.items.users.filter(
        //     (e) => e.job == "Kierowca"
        // );
        this.getWarehouseFields.company = this.getWarehouseCompanyOptions[0];
        this.getWarehouseFields.rotationType = "Wydanie";
        this.getWarehouseFields.carOwnership = "Własny";
    },
};
</script>
