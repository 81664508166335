<template>
    <v-row justify="center">
        <v-dialog v-model="getQRModal.open" width="450">
            <v-card>
                <div class="AE__header">
                    <p class="font-weight-light AE__header__title py-3 pl-5">QR Scanner</p>
                    <v-btn
                        @click="getQRModal.open = false"
                        class="AE__header__close"
                        color="white"
                        small
                        icon
                    >
                        <v-icon color="#a1a1a1" size="15">mdi-close</v-icon>
                    </v-btn>
                </div>
                <div class="d-flex justify-center align-center" style="height: 300px">
                    <div v-if="!getQRModal.SN" class="qrScanerView">
                        <qrcode-stream
                            :camera="getQRModal.camera"
                            @decode="onDecode"
                            @init="onInit"
                        />
                    </div>
                    <div class="d-flex flex-column flex-wrap" v-else>
                        <v-icon
                            class="ma-5"
                            :color="getQRModal.Message.color"
                            size="75"
                        >{{getQRModal.Message.mdi}}</v-icon>
                        <p
                            class="decode-result"
                            :style="{ color: getQRModal.Message.color}"
                        >{{getQRModal.Message.text}}</p>
                    </div>
                </div>
                <v-footer class="AE__footer" color="#d9d9d9">
                    <v-btn @click="getQRModal.open = false" class="buttons mr-3">Anuluj</v-btn>
                </v-footer>
            </v-card>
        </v-dialog>
    </v-row>
</template>


<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    components: { QrcodeStream },

    data() {
        return {
            dialog: true,
            result: "",
            isValid: undefined,
            error: ""
        };
    },
    computed: {
        ...mapGetters(["getQRModal"]),
        validationPending() {
            return this.isValid === undefined && this.camera === "off";
        },

        validationSuccess() {
            return this.isValid === true;
        },

        validationFailure() {
            return this.isValid === false;
        }
    },

    methods: {
        ...mapActions(["goToDevices"]),
        ...mapMutations(["clearModal"]),

        onDecode(result) {
            this.getQRModal.SN = result;
            this.goToDevices(result);
        },

        async onInit(promise) {
            try {
                await promise;
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    this.error =
                        "ERROR: you need to grant camera access permisson";
                } else if (error.name === "NotFoundError") {
                    this.error = "Błąd: Twoje urządzenie nie posiada kamery";
                } else if (error.name === "NotSupportedError") {
                    this.error =
                        "ERROR: secure context required (HTTPS, localhost)";
                } else if (error.name === "NotReadableError") {
                    this.error = "ERROR: is the camera already in use?";
                } else if (error.name === "OverconstrainedError") {
                    this.error = "ERROR: installed cameras are not suitable";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error =
                        "ERROR: Stream API is not supported in this browser";
                }
            }
        }
    }
};
</script>

<style scoped>
.error {
    font-weight: bold;
    color: red;
}
</style>
