<template>
    <div class="central__actions">
        <v-tooltip left v-if="getPermission.level > 1">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab small dark class="mb-0 buttons--add" @click="openEdit">
                    <v-icon>mdi-file-document-edit</v-icon>
                </v-btn>
            </template>
            <span>Edytuj kontakt</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"])
    },
    methods: {
        ...mapActions(["openModal"]),
        openEdit() {
            // this.$store.commit("resetModal", "contacts");
            this.$store.commit("setModal", {
                headLine: "global.edit",
                open: true,
                width: 600,
                type: "edit",
                route: "contacts",
                tab: "contacts_information"
            });
        }
    }
};
</script>