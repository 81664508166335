<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === PEŁNA NAZWA === === -->
            <td>{{item.name}}</td>

            <!-- === === NIP === === -->
            <td>{{item.NIP}}</td>

            <!-- === === MIASTO === === -->
            <td>{{item.city}}</td>

            <!-- === === ULICA === === -->
            <td>{{item.street}}</td>

            <!-- === === NUMER TELEFONU === === -->
            <td>{{item.phoneNumber}}</td>

            <!-- === === ADRES WWW === === -->
            <td v-if="getLayoutWSD.wsd == true">
                <a target="_blank" :href="item.website">{{item.website}}</a>
            </td>
            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/contacts/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    methods: {
        website(url) {
            return url ? `<a href="${url}"> ${url}</a>` : "";
        }
    },
    computed: {
        ...mapGetters(["getLayoutWSD"])
    }
};
</script>