var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-speed-dial',{staticClass:"mr-2",attrs:{"direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g({staticClass:"buttons--add ml-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"color":"buttons--add","dark":"","fab":""},model:{value:(_vm.filtersTooltip),callback:function ($$v) {_vm.filtersTooltip=$$v},expression:"filtersTooltip"}},on),[(_vm.filtersTooltip)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter-plus")])],1)]},proxy:true}],null,true),model:{value:(_vm.filtersIconTooltip),callback:function ($$v) {_vm.filtersIconTooltip=$$v},expression:"filtersIconTooltip"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"fab":"","dark":"","small":"","color":"#0db6ff"},on:{"click":function($event){return _vm.applyFilter()}}},on),[_c('span',[_vm._v("W")])])]}}],null,true)},[_c('span',[_vm._v("Wszystko")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"fab":"","dark":"","small":"","color":"#18edaa"},on:{"click":function($event){return _vm.applyFilter('Dostawa')}}},on),[_c('span',[_vm._v("P")])])]}}],null,true)},[_c('span',[_vm._v("Przyjęcia")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"fab":"","dark":"","small":"","color":"#e8392c"},on:{"click":function($event){return _vm.applyFilter('Wydanie')}}},on),[_c('span',[_vm._v("W")])])]}}],null,true)},[_c('span',[_vm._v("Wydania")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"fab":"","dark":"","small":"","color":"#baed45"},on:{"click":function($event){return _vm.applyFilter('Do ważenia')}}},on),[_c('span',[_vm._v("DW")])])]}}],null,true)},[_c('span',[_vm._v("Do ważenia")])])],1)]}}])},[_c('span',[_vm._v("Filtry")])]),(_vm.getPermission.level > 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.createWarehouse()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,2131189317)},[_c('span',[_vm._v("Dodaj nowy wpis do magazynu")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }