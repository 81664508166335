<template>
	<v-container>
		Dedykowane szablony dla modułu: {{ getRegistryData.name }}
		<v-stepper v-model="stepper">
			<v-stepper-header>
				<v-stepper-step editable step="1">Wybierz moduł</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step step="2">Wybierz szablon</v-stepper-step>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content step="1">
					<v-list>
						<v-list-item-group color="primary">
							<v-list-item
								v-for="item in getUserRegistries"
								:key="item._id"
							>
								<v-list-item-content
									@click="nextStep(item._id)"
									v-text="item.name"
								></v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-stepper-content>
				<v-stepper-content step="2">
					<v-row>
						<v-col
							v-for="item in notDeleted"
							:key="item._id"
							cols="3"
						>
							<div class="text-center">
								<v-btn
									@click="
										createFromTemplate({
											registryID: $route.params.id,
											registryName: $route.name,
											templateRegistryID: currentRegistry,
											attachmentID: item._id,
										})
									"
									display="block"
									class="ma-2"
									outlined
									x-large
									:color="color"
								>
									<v-icon large>mdi-file</v-icon>
								</v-btn>
								<p>{{ item.realFileName }}</p>
							</div>
						</v-col>
					</v-row>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
	data: () => ({
		modaldata: false,
		color: 'grey',
		items: ['rej1', 'rej2', 'rej3', 'rej4'],
		stepper: 2,
		currentRegistry: '',
	}),
	methods: {
		...mapActions([
			'fetchFolderAttachments',
			'registryDetailsByUrl',
			'fetchData',
			'createFromTemplate',
			'registryDetails',
		]),
		...mapMutations(['clearTemplatesItems']),
		addAttchamentFromTemplate() {},
		nextStep(id) {
			this.clearTemplatesItems();
			this.stepper = 2;
			this.fetchFolderAttachments({
				registryName: 'templates_details',
				registryID: id,
				templates: true,
			});
			this.registryDetails({ id: id });
			this.currentRegistry = id;
		},
	},
	computed: {
		...mapGetters([
			'getRegistryData',
			'getTemplatesItems',
			'getTable',
			'getUserRegistries',
		]),
		notDeleted: function() {
			return this.getTemplatesItems[0]
				? this.getTemplatesItems[0].attachments.filter(
						(el) => el.isDeleted == false,
				  )
				: '';
		},
	},
	beforeMount() {
		this.registryDetailsByUrl({
			url: this.$route.path.substring(
				1,
				this.$route.path.indexOf('/', 2),
			),
			next: async () => {
				this.fetchFolderAttachments({
					registryName: 'templates_details',
					registryID: this.getRegistryData._id,
					templates: true,
				});
				this.currentRegistry = this.getRegistryData._id;
				// await this.fetchData({
				// 	page: 1,
				// 	search: '',
				// 	sortBy: '',
				// 	sortDesc: '',
				// 	routeName: 'registries',
				// });
			},
		});
	},
};
</script>
