<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="5" class="pa-0">
        <v-col cols="12" md="12" class="py-0 p-relative pb-3">
          <v-col cols="6" md="6" class="py-0">
            <v-autocomplete
              :disabled="getModal.type === `edit`"
              dense
              label="Wyszukaj urządzenie"
              @keyup="searchDevices($event)"
              return-object
              :items="getSearchedDevicesItems"
              v-model="getServiceData.device"
              hint="Zacznij pisać..."
              class="my-5"
              hide-no-data
              item-value="_id"
              item-name="SN"
              :filter="filterObject"
            >
              <template slot="prepend">
                <v-icon color="#19aa8d" class="mt-3" size="18"
                  >mdi-printer</v-icon
                >
              </template>
              <template v-slot:item="data">{{ data.item.SN }}</template>
              <template v-slot:selection="data">{{ data.item.SN }}</template>
            </v-autocomplete>
          </v-col>
          <v-textarea
            v-model="getServiceData.service.temporaryInformation.devices"
            label="Tymczasowe dane urządzenia"
            auto-grow
            :disabled="getServiceData.device.isExist"
            rows="1"
          ></v-textarea>
          <v-textarea
            v-model="getServiceData.service.temporaryInformation.client"
            label="Tymczasowe dane klienta"
            auto-grow
            :disabled="getServiceData.device.isExist"
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="mt-5 pb-0">
          <Separator class="mt-5" :text="'Więcej o urządzeniu:'" />
          <div class="d-flex service__information">
            <span class="service__details__left">Producent</span>
            <span class="service__details__right">
              {{
                getServiceData.device.modelDevices
                  ? getServiceData.device.modelDevices.producer
                  : "Brak"
              }}
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Model</span>
            <span class="service__details__right">
              {{
                getServiceData.device.modelDevices
                  ? getServiceData.device.modelDevices.model
                  : "Brak"
              }}
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Rodzaj urządzenia</span>
            <span class="service__details__right">
              {{
                getServiceData.device.modelDevices
                  ? getServiceData.device.modelDevices.deviceType
                  : "Brak"
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left">Priorytet (czas reakcji)</span>
            <span class="service__details__right">{{
              getServiceData.device.priority
            }}</span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left">Pakiet usług</span>
            <span class="service__details__right">
              {{
                getServiceData.device.modelDevices
                  ? getServiceData.device.modelDevices.price.priceName
                  : "Brak"
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left">Ceny usług</span>
            <span class="service__details__right">
              <span
                v-if="
                  getServiceData.device.dedicatedPrice
                    ? getServiceData.device.dedicatedPrice.checkbox
                    : ''
                "
              >
                {{
                  getServiceData.device.dedicatedPrice.survey
                    ? getServiceData.device.dedicatedPrice.survey
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.dedicatedPrice.repair
                    ? getServiceData.device.dedicatedPrice.repair
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.dedicatedPrice.installation
                    ? getServiceData.device.dedicatedPrice.installation
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.dedicatedPrice.diagnostics
                    ? getServiceData.device.dedicatedPrice.diagnostics
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.dedicatedPrice.itService
                    ? getServiceData.device.dedicatedPrice.itService
                    : "Brak"
                }}PLN
              </span>
              <span v-else>
                {{
                  getServiceData.device.modelDevices
                    ? getServiceData.device.modelDevices.price.survey.price
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.modelDevices
                    ? getServiceData.device.modelDevices.price.repair.price
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.modelDevices
                    ? getServiceData.device.modelDevices.price.installation
                        .price
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.modelDevices
                    ? getServiceData.device.modelDevices.price.diagnostics.price
                    : "Brak"
                }}
                PLN /
                {{
                  getServiceData.device.modelDevices
                    ? getServiceData.device.modelDevices.price.itService.price
                    : "Brak"
                }}
                PLN
              </span>
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left pb-5">Podstawa serwisu</span>
            <span class="service__details__right pb-5">{{
              getServiceData.device.serviceBase
            }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Pełna lokalizacja</span>

            <span
              style="border-left: 1px solid red"
              class="service__details__right"
            >
              <!-- {{this.getBranchAddress.city ? `${this.getBranchAddress ? this.getBranchAddress.city : ""} ${this.getBranchAddress ? this.getBranchAddress.postCode : ""} ${this.getBranchAddress ? this.getBranchAddress.address : ''} `
                            : "Brak"}}-->
              {{
                getServiceData.device.address
                  ? getServiceData.device.address.name
                  : ""
              }}
              {{ checkAddress() }}
              <br />
              {{
                getServiceData.device.address
                  ? getServiceData.device.address.postCode
                  : ""
              }}
              {{
                getServiceData.device.address
                  ? getServiceData.device.address.city
                  : ""
              }}
              <br />
              {{
                getServiceData.device.floor
                  ? `Piętro: ` + getServiceData.device.floor
                  : ""
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left pb-5">Klient</span>
            <span class="service__details__right pb-5">
              {{
                getServiceData.device.client
                  ? getServiceData.device.client.name
                  : ""
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left"
              >Data ostatniej interwencji</span
            >
            <span class="service__details__right">
              {{
                getLastServiceDetails &&
                getLastServiceDetails.implementation &&
                getLastServiceDetails.implementation.service &&
                getLastServiceDetails.implementation.service.dates &&
                getLastServiceDetails.implementation.service.dates.end
                  ? $moment(
                      getLastServiceDetails.implementation.service.dates.end
                    ).format("YYYY-MM-DD")
                  : "Brak"
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left">Technik</span>
            <span class="service__details__right">
              {{
                getLastServiceDetails &&
                getLastServiceDetails.implementation &&
                getLastServiceDetails.implementation.technic.name
                  ? `${getLastServiceDetails.implementation.technic.name} ${getLastServiceDetails.implementation.technic.lastname}`
                  : "Brak"
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left"
              >Licznik mono ostatniej interwencji</span
            >
            <span class="service__details__right">
              {{
                getLastServiceDetails &&
                getLastServiceDetails.implementation &&
                getLastServiceDetails.implementation.meter.mono
                  ? `P: ${getLastServiceDetails.implementation.meter.mono.start}, K: ${getLastServiceDetails.implementation.meter.mono.end}`
                  : "Brak"
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left"
              >Licznik kolor ostatniej interwencji</span
            >
            <span class="service__details__right">
              {{
                getLastServiceDetails &&
                getLastServiceDetails.implementation &&
                getLastServiceDetails.implementation.meter.color
                  ? `P: ${getLastServiceDetails.implementation.meter.color.start}, K: ${getLastServiceDetails.implementation.meter.color.end}`
                  : "Brak"
              }}
            </span>
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left"
              >Uwagi z ostatniej interwencji</span
            >
            <span class="service__details__right">
              {{
                getLastServiceDetails &&
                getLastServiceDetails.implementation &&
                getLastServiceDetails.implementation.description
                  .nextIntervention
                  ? getLastServiceDetails.implementation.description
                      .nextIntervention
                  : "Brak"
              }}
            </span>
          </div>
        </v-col>
      </v-col>

      <v-col cols="12" md="12" lg="7" class="d-flex flex-wrap py-0 ma-0">
        <v-col cols="12" md="12" lg="12" class="pa-0 ma-0">
          <Separator :text="'Opis interwencji'" />
        </v-col>
        <v-col cols="12" md="12" lg="12" class="pa-0">
          <v-textarea
            v-model="
              getServiceData.service.descriptionIntervention.descriptionClient
            "
            :error-messages="getModalServiceError.description"
            dense
            label="Opis usterki wg. Klienta"
            auto-grow
            rows="1"
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="12" class="pa-0">
          <v-textarea
            dense
            v-model="
              getServiceData.service.descriptionIntervention.descriptionLogistic
            "
            label="Opis logistyka"
            auto-grow
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="9" class="pa-0 pr-3">
          <v-select
            label="Osoba zlecająca (imię, nazwisko, tel, email)"
            :items="
              getServiceData.device.client
                ? getServiceData.device.client.representatives
                : []
            "
            return-object
            item-value="_id"
            item-text="_id"
            v-model="
              getServiceData.service.descriptionIntervention.representative
            "
          >
            <template v-slot:selection="clients">
              {{ clients.item.name }}
              {{ clients.item.lastname }} -- {{ clients.item.phoneNumber }} --
              {{ clients.item.email }}
            </template>
            <template v-slot:item="clients">
              <template>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ clients.item.name }}
                    {{ clients.item.lastname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon color="#19aa8d" size="13">mdi-phone</v-icon>
                    {{ clients.item.phoneNumber }} --
                    <v-icon color="light-grey" size="13">mdi-email</v-icon>
                    {{ clients.item.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="3" class="pa-0 pr-3">
          <v-select
            v-model="
              getServiceData.service.descriptionIntervention.intervention
            "
            label="Wybierz wyjściową interwencję"
            :items="getLastInterventions"
            item-text="index"
            return-object
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.index }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="data.item.service">
                  {{
                    data.item.service.descriptionIntervention.technicPerforming
                      .name
                  }}
                  {{
                    data.item.service.descriptionIntervention.technicPerforming
                      .lastname
                  }}
                  <br />
                  <span>
                    {{
                      $moment(
                        data.item.implementation.service.dates.start
                      ).format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="
                    data.item.implementation &&
                    data.item.implementation.service.dates.start
                  "
                >
                  {{
                    $moment(
                      data.item.implementation.service.dates.start
                    ).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-wrap pa-0">
          <v-col cols="12" md="3" lg="3" class="pa-0 pr-3">
            <v-autocomplete
              label="Preferowany technik"
              v-model="getServiceData.service.descriptionIntervention.technic"
              item-value="_id"
              return-object
              item-text="name"
              :items="getTechnicsItems"
              clearable
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
                {{ data.item.lastname }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
                {{ data.item.lastname }}
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" class="pa-0" sm="6" md="3" lg="3">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="getServiceData.service.descriptionIntervention.data"
                  dense
                  style="padding-top: 18px"
                  label="Oczekiwana data usługi"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="getServiceData.service.descriptionIntervention.data"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="2" lg="2" class="pa-0 pl-3">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  v-model="getServiceData.service.descriptionIntervention.time"
                  label="Czas"
                  style="padding-top: 18px"
                  prepend-icon="mdi-clock-outline"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                @click:minute="$refs.menu.save(time)"
                v-model="getServiceData.service.descriptionIntervention.time"
                format="24hr"
                full-width
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-wrap pa-0">
          <v-col cols="12" md="3" lg="3" class="pa-0 pr-3">
            <v-autocomplete
              label="Technik realizujący usługę"
              v-model="
                getServiceData.service.descriptionIntervention.technicPerforming
              "
              item-value="_id"
              return-object
              item-text="name"
              :items="getTechnicsItems"
              clearable
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
                {{ data.item.lastname }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
                {{ data.item.lastname }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="pa-0" sm="6" md="3" lg="3">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="
                    getServiceData.service.descriptionIntervention.finishDate
                  "
                  dense
                  style="padding-top: 18px"
                  label="Zaplanowana data usługi"
                  v-on="on"
                >
                  <template slot="prepend">
                    <v-icon color="#19aa8d">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="
                  getServiceData.service.descriptionIntervention.finishDate
                "
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="2" lg="2" class="pa-0 pl-3">
            <v-menu
              ref="menu"
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  v-model="
                    getServiceData.service.descriptionIntervention.finishTime
                  "
                  label="Czas"
                  style="padding-top: 18px"
                  v-on="on"
                >
                  <template slot="prepend">
                    <v-icon color="#19aa8d">mdi-clock-outline</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-time-picker
                v-if="menu4"
                @click:minute="$refs.menu.save(time)"
                v-model="
                  getServiceData.service.descriptionIntervention.finishTime
                "
                format="24hr"
                full-width
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" class="px-0 mt-5">
          <Separator :text="'Realizacja interwencji'" />
        </v-col>
        <v-col
          cols="12"
          md="12"
          sm="12"
          class="pa-0 d-flex flex-wrap"
          style="min-height: 180px"
        >
          <v-col cols="12" lg="4" sm="6" md="6" class="py-0">
            <span>Usługi do wykonania:</span>
            <v-checkbox
              v-model="getServiceData.service.realisation.survey"
              dense
              label="Przegląd okresowy"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="getServiceData.service.realisation.repair"
              dense
              label="Naprawa"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="getServiceData.service.realisation.installation"
              dense
              label="Wdrożenie"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="getServiceData.service.realisation.diagnostics"
              dense
              label="Diagnostyka"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="getServiceData.service.realisation.itService"
              dense
              label="Usługa IT"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            sm="6"
            md="6"
            class="py-0"
            style="margin-top: 22px"
          >
            <v-checkbox
              v-model="getServiceData.service.realisation.firstInstallation"
              dense
              label="Pierwsza instalacja"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="getServiceData.service.realisation.repairWorkshop"
              dense
              label="Naprawa warsztatowa"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="getServiceData.service.realisation.remoteService"
              dense
              label="Usługa zdalna"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            sm="6"
            md="6"
            class="py-0"
            style="margin-top: 22px"
          >
            <v-checkbox
              v-model="getServiceData.service.realisation.continuation"
              dense
              label="Kontynuacja"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="getServiceData.service.realisation.complaint"
              dense
              label="Reklamacja"
              color="#19aa8d"
              class="mt-2 check__servis"
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="getServiceData.service.realisation.urgent"
              dense
              label="Pilne"
              class="mt-2 check__servis"
              color="red"
              error
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="getServiceData.service.realisation.costInformation"
              dense
              style="margin-top: 44px"
              class="check__servis"
              label="Informacje o kosztach IT "
              color="#19aa8d"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import debounce from "lodash.debounce";
import store from "../../../../store/index";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    search: "",
    test: true,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu1: false,
    temporaryInput: true,
    menu2: false,
    time: null,
    test: ["1", "2"],
    modal: false,
    menu3: false,
    menu4: false,
  }),
  components: {
    Separator,
  },
  methods: {
    ...mapMutations(["ResetSearch"]),
    ...mapActions(["searchDevice", "lastInterventions"]),

    searchDevices: debounce(function (e) {
      if (e.target.value.length > 3) {
        this.searchDevice({ SN: e.target.value });
      }
    }, 500),
    searchDevicesInDevices: debounce(function (e) {
      if (e.length > 3) {
        this.searchDevice({ SN: e, reg: "devices" });
      }
    }, 500),
    checkAddress() {
      if (
        !this.getServiceData.device.address ||
        this.getServiceData.device.address.address === ""
      ) {
        return "Brak";
      } else {
        return this.getServiceData.device.address.address;
      }
    },
    filterObject(item, queryText, itemText) {
      return (
        item.SN.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.modelDevices.model
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.client.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.client.city
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.client.street
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.client._id
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
  computed: {
    ...mapGetters([
      "getServiceData",
      "getBranchAddress",
      "getSearchedDevicesItems",
      "getClientItems",
      "getTechnicsItems",
      "getModal",
      "getModalServiceError",
      "getServiceDetails",
      "getLastInterventions",
      "getLastServiceDetails",
    ]),

    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    computedDateFormattedDatefns() {
      return this.date ? format(this.date, "dddd, MMMM Do YYYY") : "";
    },
  },
  beforeMount() {
    // this.serviceInDevices();
    if (this.getModal.SN) {
      this.searchDevicesInDevices(this.getModal.SN);
    }
  },
  watch: {
    "getServiceData.device.SN"(newVal, oldVal) {
      this.lastInterventions(newVal);
    },
  },
};
</script>
