<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="p-relative" md="12">
        <v-data-table
          class="central__table"
          hide-default-footer
          :mobile-breakpoint="1100"
          :headers="getNotification.header"
          :items="getTable.items"
          :items-per-page="11"
        >
          <template v-slot:item.to="{ item }">
            <v-menu open-on-hover>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-list-item-title>
                    <span
                      style="font-size: 14px !important"
                      v-if="item.to && item.to.users.length > 0"
                    >
                      {{ item.to.users[0].name }}
                      {{
                      item.to.users[0].lastname
                      }}
                    </span>
                    <span
                      v-else-if="
												item.to && item.to.departments.length > 0
											"
                    >
                      {{
                      item.to.departments[0].fullName
                      }}
                    </span>
                    <span v-else>Brak</span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="grey--text lighten-1"
                    v-if="
											item.to && item.to.users.length +
												item.to && item.to.departments.length >
												1
										"
                    v-on="on"
                  >
                    +
                    {{
                    item.to.users.length +
                    item.to.departments.length -
                    1
                    }}
                    <span
                      v-if="
												item.to && item.to.users.length +
													item.to && item.to.departments.length -
													1 ==
													1
											"
                    >inny</span>
                    <span v-else>innych</span>
                  </v-list-item-subtitle>
                </div>
              </template>
              <v-card class="pa-3">
                <div v-if="item.to && item.to.users.length > 0">
                  <p class="mb-0">Pracownicy:</p>
                  <ul>
                    <li
                      v-for="user in item.to.users"
                      :key="user._id"
                    >{{ user.name }} {{ user.lastname }}</li>
                  </ul>
                </div>
                <div v-if="item.to && item.to.departments.length > 0">
                  <p class="mb-0">Departamenty:</p>
                  <ul>
                    <li
                      v-for="department in item.to
												.departments"
                      :key="department._id"
                    >{{ department.fullName }}</li>
                  </ul>
                </div>
              </v-card>
            </v-menu>
          </template>

          <template v-slot:item.dueDate="{ item }">
            <v-list-item-title style="font-size: 14px !important">
              {{
              $moment(item.dueDate).format('YYYY-MM-DD')
              }}
            </v-list-item-title>
            <v-list-item-subtitle class="grey--text lighten-1">
              {{
              item.dueTime
              }}
            </v-list-item-subtitle>
          </template>
          <template v-slot:item.createdBy="{ item }">
            {{ item.createdBy.name }}
            {{ item.createdBy.lastname }}
          </template>

          <template v-slot:item.priority="{ item }">
            <v-chip
              :color="item.priority.background"
              :style="`color: ${item.priority.color}`"
              label
              v-if="item.priority"
              small
            >{{ item.priority.priorityName }}</v-chip>
            <v-chip dark v-else label small>Brak</v-chip>
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn @click="notificationsDevices(item._id)" icon>
              <v-icon small>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- === === PAGINATION === === -->
        <div class="text-md-center pt-2">
          <v-pagination
            circle
            v-model="table.page"
            :length="getTable.pages"
            @input="fetchTableItems"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Buttons from "@/components/Global/Details/Notifications/ButtonsAE.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Buttons
  },
  data: () => ({
    options: {},
    table: {
      page: 1
    }
  }),

  computed: {
    ...mapGetters(["getNotification", "getTable"])
  },
  methods: {
    ...mapActions(["openModal", "fetchData", "alertDetails"]),
    fetchTableItems() {
      const { sortBy, sortDesc } = this.options;
      const { page } = this.table;

      this.fetchData({
        page: this.table.page,
        pageSize: 11,
        search: "",
        sortBy: sortBy || [],
        sortDesc: sortDesc || [],
        routeName: "alert_tab",
        registryName: this.$route.name,
        registryID: this.$route.params.id
      });
    },
    notificationsDevices(NID) {
      this.alertDetails({ id: NID, registryName: this.$route.name });
      this.openModal({
        headLine: "global.create",
        open: true,
        type: "edit",
        width: 600,
        route: "notifications",
        global: "notifications",
        tab: "notification"
      });
    }
  }
  // beforeMount() {
  //     this.fetchTableItems();
  // }
};
</script>
