<template>
  <!-- === === WSD APP WSD === === -->
  <v-app>
    <div v-if="getLayoutWSD.wsd == true">
      <!-- === === TOP TOOLBAR === === -->
      <v-app-bar app clipped-right>
        <v-app-bar-nav-icon
          class="dn-mobile"
          style="position: absolute; left: 0"
          @click.stop="mini = !mini"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <img
          v-if="!mini"
          style="
            min-height: 40px;
            padding: 10px;
            padding-right: 0;
            max-height: 70px;
            width: 130px;
            margin-left: -3px;
          "
          src="@/assets/img/global/entelli_logo.png"
          alt="Logo"
        />

        <div
          class="d-flex justify-center align-center"
          style="
            position: absolute;
            left: 120px;
            color: grey;
            font-size: 13px;
            cursor: pointer;
          "
        >
          <!-- === PERSON === -->
          <router-link tag="div" to="/profile">
            <transition name="menu">
              <div v-if="!mini">
                <p class="ma-0 white--grey">
                  Zalogowany: {{ getUser.name }}
                  {{ getUser.lastname }}
                </p>
              </div>
            </transition>
          </router-link>
        </div>

        <v-btn
          text
          icon
          color="grey lighten-1"
          style="position: absolute; left: 20px; margin-top: 0px"
          router
          to="/dashboard"
          @click="getNav.stepper = 1"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          color="grey lighten-1 "
          style="position: absolute; left: 70px"
          @click="logout"
        >
          <v-icon>mdi-power</v-icon>
        </v-btn>
      </v-app-bar>

      <!-- === === NAVIGATION ON THE LEFT === === -->
      <v-navigation-drawer
        mini-variant-width="65"
        app
        :mini-variant="mini"
        touchless
        permanent
        src="@/assets/img/global/menu-background.jpg"
        :class="getLayoutWSD.wsd === true ? 'navigation-WSD' : 'navigation'"
      >
        <!-- === LOGO === -->
        <div class="navigation__logo d-flex justify-center pa-3">
          <img
            style="width: 90%"
            src="@/assets/img/wsd/logowsd_white.png"
            alt="Logo WSD"
          />
        </div>

        <!-- === LIST OF MENU === -->
        <v-stepper
          v-model="getNav.stepper"
          style="background: transparent; box-shadow: none; margin-top: 40px"
        >
          <v-stepper-items style="overflow: auto; min-height: 68vh">
            <v-stepper-content step="1" class="pa-0 wsd_active">
              <v-list dense dark nav flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in correctNavItems"
                  :key="item.id"
                  style="min-height: 27px; font-weight: 500"
                  :to="item.link"
                  class="menu-wsd"
                  @click="goToStepper(item.step)"
                >
                  <div
                    class="menu-wsd"
                    style="font-size: 0.75rem; line-height: 1.4"
                  >
                    {{ item.name }}
                  </div>
                </v-list-item>
              </v-list>
            </v-stepper-content>

            <v-stepper-content
              step="2"
              class="pa-0 wsd_active"
              style="overflow: auto; min-height: 78vh"
            >
              <div
                class="pa-2"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="white--text">mdi-arrow-left</v-icon>
                <span
                  v-if="!mini"
                  class="white--text title ml-2 font-weight-light"
                  >Rejestry</span
                >
              </div>
              <v-list
                dense
                nav
                dark
                flat
                class="elevation-0 active-menu"
                style="height: 78vh; overflow: auto"
              >
                <v-list-item
                  v-for="item in getCorrectRegistriesWSD"
                  :key="item.name"
                  router
                  :to="`${item.link}`"
                  color="#19aa8d"
                  style="font-size: 1rem !important; min-height: 27px"
                  class="color hover"
                >
                  <v-list-item-title
                    style="
                      font-size: 0.75rem;
                      color: white;
                      font-weight: 500 !important;
                    "
                    class="font-weight-light"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>

            <v-stepper-content step="3" class="pa-0 wsd_active">
              <div
                class="pa-2"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="white--text">mdi-arrow-left</v-icon>
                <span
                  v-if="!mini"
                  class="white--text title ml-2 font-weight-light"
                  >Osobiste</span
                >
              </div>
              <v-list dense nav flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in nav.PersonalItems"
                  :key="item.name"
                  router
                  style="min-height: 27px"
                  :to="item.link"
                  color="#19aa8d"
                  class="color hover"
                >
                  <v-list-item-title
                    style="
                      font-size: 0.75rem;
                      color: white;
                      font-weight: 500 !important;
                    "
                    class="font-weight-light"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <v-stepper-content step="4" class="pa-0 wsd_active">
              <div
                class="pa-2 white--text"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="white--text">mdi-arrow-left</v-icon>
                <span v-if="!mini" class="title ml-2 font-weight-light"
                  >Admin</span
                >
              </div>
              <v-list dense nav flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in nav.AdminItems"
                  :key="item.name"
                  router
                  style="min-height: 27px"
                  :to="item.link"
                  color="#19aa8d"
                  class="color hover"
                >
                  <v-list-item-title
                    style="
                      font-size: 0.75rem;
                      color: white;
                      font-weight: 500 !important;
                    "
                    class="font-weight-light"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <v-stepper-content step="5" class="pa-0">
              <div
                class="pa-2 white--text"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="white--text">mdi-arrow-left</v-icon>
                <span v-if="!mini" class="title ml-2 font-weight-light"
                  >SUPER ADMIN</span
                >
              </div>
              <!-- <v-switch
                class="white--text menu__switch"
                v-model="getLayoutWSD.wsd"
                color="#19aa8d"
                @change="switchLayout()"
                label="WSD"
                            ></v-switch>-->
              <v-switch
                class="white--text menu__switch"
                style="color: white; font-weight: 500 !important"
                v-model="getLayoutWSD.wsd"
                color="#19aa8d"
                @change="switchLayout(getLayoutWSD.wsd)"
                label="WSD"
              ></v-switch>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <transition name="data">
          <Data v-if="!mini" />
          <DateMobile v-if="mini" />
        </transition>
      </v-navigation-drawer>

      <!-- === === CONTENT === === -->
      <v-content>
        <v-slide-x-transition mode="out-in">
          <router-view :route_name="$route.name" :key="$route.fullPath" />
        </v-slide-x-transition>
      </v-content>

      <v-overlay
        absolute
        z-index="6"
        :value="$store.state.central.modal.open"
      ></v-overlay>
      <Modal />

      <RightBox />
      <SecondModal />
      <Snackbars />
      <QRModal />
      <SignatureModal />
      <OfficePreview />
    </div>
    <!-- === === END WSD APP === === -->
    <div v-else>
      <!-- === === TOP TOOLBAR === === -->
      <v-app-bar app clipped-right color="white">
        <v-app-bar-nav-icon
          class="dn-mobile"
          @click.stop="mini = !mini"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>

        <!-- <v-tooltip v-if="getSession.timer.minutes() > 1" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="refreshSessionTime()" text color="grey lighten-1">
                            {{
                            timeCorrection(getSession.timer.minutes()) +
                            ':' +
                            timeCorrection(getSession.timer.seconds())
                            }}
                        </v-btn>
                    </template>
                    <span>Czas sesji</span>
                </v-tooltip>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="refreshSessionTime()" text color="#FF5252">
                            {{
                            timeCorrection(getSession.timer.minutes()) +
                            ':' +
                            timeCorrection(getSession.timer.seconds())
                            }}
                        </v-btn>
                    </template>
                    <span>Czas sesji</span>
                </v-tooltip>-->
        <v-btn @click="openQRScaner" text icon color="grey lighten-1">
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn>
        <!-- {{getRouterViewKey}} -->
        <v-btn
          @click="getNav.stepper = 1"
          text
          icon
          color="grey lighten-1"
          router
          to="/dashboard"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn text icon color="grey lighten-1" @click="logout">
          <v-icon>mdi-power</v-icon>
        </v-btn>
      </v-app-bar>

      <!-- === === NAVIGATION ON THE LEFT === === -->
      <v-navigation-drawer
        mini-variant-width="65"
        app
        :mini-variant="mini"
        touchless
        permanent
        src="@/assets/img/global/menu-background.jpg"
        class="navigation"
      >
        <!-- === LOGO === -->
        <div class="navigation__logo d-flex justify-center">
          <img
            v-if="!mini"
            style="
              min-height: 40px;
              padding: 15px;
              max-height: 80px;
              width: 220px;
              margin-left: -3px;
            "
            src="@/assets/img/global/logo_entelli3.png"
            alt="Logo"
          />
          <img
            v-if="mini"
            style="
              min-height: 40px;
              padding: 15px;
              max-height: 60px;
              width: 70px;
              margin-left: -3px;
              margin-top: 5px;
            "
            src="@/assets/img/global/logos_e.png"
            alt="Logo"
          />
        </div>

        <!-- === PERSON === -->
        <router-link tag="div" class="person" to="/profile">
          <v-avatar
            color="secondary"
            :size="mini ? '30' : '50'"
            class="transition__menu"
          >
            <span
              v-if="!getUser.picture"
              :style="
                mini
                  ? 'font-size: 12px !important'
                  : 'font-size:20px !important'
              "
              class="white--text body-1 mx-auto"
              >{{ avatar }}</span
            >
            <img v-else :src="showProfilePicture" alt="avatar" />
          </v-avatar>
          <transition name="menu">
            <div v-if="!mini">
              <p class="pa-0 ma-0 mt-1 white--text person__name">
                {{ getUser.name }} {{ getUser.lastname }}
              </p>

              <p class="pa-0 ma-0 white--text person__job">{{ getUser.job }}</p>
            </div>
          </transition>
        </router-link>

        <!-- === LIST OF MENU === -->
        <v-stepper
          v-model="getNav.stepper"
          class="mobile__menu desktop"
          style="
            background: transparent;
            box-shadow: none;
            overflow: auto;
            max-height: 55vh;
          "
        >
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <v-list dense nav dark flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in correctNavItems"
                  :key="item.name"
                  :to="item.link"
                  @click="goToStepper(item.step)"
                >
                  <v-list-item-icon class="icons__menu">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    class="font-weight-light"
                    style="font-size: 0.85rem"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <div
                class="pa-2"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="secondary--text">mdi-arrow-left</v-icon>
                <span
                  v-if="!mini"
                  class="secondary--text title ml-2 font-weight-light"
                  >Rejestry</span
                >
              </div>
              <v-list dense nav dark flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in getCorrectRegistries"
                  :key="item.name"
                  router
                  :to="`/${item.url}`"
                  color="#19aa8d"
                  style="font-size: 1rem !important"
                  class="color hover"
                >
                  <v-list-item-icon class="icons__menu">
                    <v-icon>mdi-{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    style="font-size: 0.85rem"
                    class="font-weight-light"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>

            <v-stepper-content step="3" class="pa-0">
              <div
                class="pa-2"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="secondary--text">mdi-arrow-left</v-icon>
                <span
                  v-if="!mini"
                  class="secondary--text title ml-2 font-weight-light"
                  >Osobiste</span
                >
              </div>
              <v-list dense nav flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in nav.PersonalItems"
                  :key="item.name"
                  router
                  :to="item.link"
                  color="#19aa8d"
                  class="color hover"
                >
                  <v-badge
                    class="badgeRB"
                    color="#ffcb00"
                    v-if="
                      (item.name == 'Alerty' && alertsCount > 0) ||
                        (item.name == 'Zadania' && tasksCount > 0)
                    "
                    :content="
                      item.name == 'Alerty'
                        ? alertsCount
                        : item.name == 'Zadania'
                        ? tasksCount
                        : ''
                    "
                    offset-x="-4"
                    offset-y="-4"
                    overlap
                  ></v-badge>
                  <v-list-item-icon class="icons__menu">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    style="font-size: 0.85rem"
                    class="font-weight-light"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>

            <v-stepper-content step="4" class="pa-0">
              <div
                class="pa-2"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="secondary--text">mdi-arrow-left</v-icon>
                <span
                  v-if="!mini"
                  class="secondary--text title ml-2 font-weight-light"
                  >Admin</span
                >
              </div>
              <v-list dense nav flat class="elevation-0 active-menu">
                <v-list-item
                  v-for="item in nav.AdminItems"
                  :key="item.name"
                  router
                  :to="item.link"
                  color="#19aa8d"
                  class="color hover"
                >
                  <v-list-item-icon class="icons__menu">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    style="font-size: 0.85rem"
                    class="font-weight-light"
                    >{{ item.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <v-stepper-content step="5" class="pa-0">
              <div
                class="pa-2 white--text"
                @click="getNav.stepper = 1"
                style="cursor: pointer; display: flex"
              >
                <v-icon class="secondary--text">mdi-arrow-left</v-icon>
                <span
                  v-if="!mini"
                  class="secondary--text title ml-2 font-weight-light"
                  >SUPER ADMIN</span
                >
              </div>
              <v-switch
                class="white--text menu__switch"
                v-model="getLayoutWSD.wsd"
                color="#19aa8d"
                @change="switchLayout(getLayoutWSD.wsd)"
                label="WSD"
              ></v-switch>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <transition name="data">
          <Data v-if="!mini" />
          <DateMobile v-if="mini" />
        </transition>
      </v-navigation-drawer>

      <!-- === === CONTENT === === -->
      <v-content>
        <v-slide-x-transition mode="out-in">
          <!-- getRouterViewKey -->
          <router-view :route_name="$route.name" :key="$route.fullPath" />
        </v-slide-x-transition>
      </v-content>

      <v-overlay
        absolute
        z-index="6"
        :value="$store.state.central.modal.open"
      ></v-overlay>
      <Modal />
      <!-- <v-snackbar v-model="test" bottom right color="success">Test</v-snackbar> -->
      <RightBox />
      <SecondModal />

      <Snackbars />
      <QRModal />
      <SignatureModal />
      <OfficePreview />
      <!-- <Preloader v-if="getPreloader.active == true" /> -->
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
import Modal from '@/components/Global/Modal/Modal.vue';
import SecondModal from '@/components/Global/SecondModal/Modal.vue';

import SignatureModal from '@/components/Registry/Service/ImplementationService/Signature.vue';
import RightBox from '@/components/Global/RightBox/RightBox.vue';
import Snackbars from '@/components/Global/Snackbars/Snackbars.vue';
import Data from '@/components/Menu/Date.vue';
import DateMobile from '@/components/Menu/DateMobile.vue';
import QRModal from '@/components/Global/QRCode/QRCodeScaner.vue';
import OfficePreview from '@/components/Global/OfficePreview/OfficePreview.vue';

export default {
  components: {
    Data,
    DateMobile,
    RightBox,
    Modal,
    SignatureModal,
    Snackbars,
    SecondModal,
    QrcodeStream,
    QrcodeDropZone,
    QRModal,
    QrcodeCapture,
    OfficePreview,
  },

  data: function() {
    return {
      mini: false,
      size: 50,
      show: true,
      switch2: true,
      nav: {
        stepper: 1,
        items: [
          {
            icon: 'mdi-account',
            name: 'Osobiste',
            step: '3',
          },
          {
            icon: 'mdi-book',
            name: 'Rejestry',
            step: '2',
          },
          {
            icon: 'mdi-comment-account',
            name: 'Kontakty',
            link: '/contacts',
            step: '1',
          },

          {
            icon: 'mdi-desktop-mac',
            name: 'Admin',
            step: '4',
          },
          {
            icon: 'mdi-shield-key',
            name: 'Super Admin',
            step: '5',
          },
        ],
        itemsWSD: [
          //   WSD
          {
            name: 'INFORMACJE BIEŻĄCE',
            link: '/current-informations',
            step: '1',
          },
          {
            name: 'ZARZĄDZENIA DYREKTORA',
            link: '/director-orders',
            step: '1',
          },
          {
            name: 'KORONAWIRUS',
            link: '/coronavirus',
            step: '1',
          },
          {
            name: 'DYŻURY',
            link: '/duty',
            step: '1',
          },
          {
            name: 'WARTOŚĆ PUNKTOWA ŚWIADCZEŃ',
            link: '/benefits-value',
            step: '1',
          },
          {
            name: 'AKREDYTACJE',
            link: '/accreditation',
            step: '1',
          },
          {
            name: 'PROCEDURY JAKOŚCI',
            link: '/iso',
            step: '1',
          },
          {
            name: 'FARMAKOTERAPIA',
            link: '/pharmacotherapy',
            step: '1',
          },
          {
            name: 'DIETY/ODŻYWIANIE',
            link: '/diet-nutrition',
            step: '1',
          },
          {
            name: 'DRUKI MEDYCZNE WEWNĘTRZNE',
            link: '/internal-prints',
            step: '1',
          },
          {
            name: 'DRUKI MEDYCZNE ZEWNĘTRZNE',
            link: '/external-prints',
            step: '1',
          },
          {
            name: 'ZGODY PACJENTA',
            link: '/patients-consent',
            step: '1',
          },
          {
            name: 'DRUKI NIEMEDYCZNE',
            link: '/non-medical-print',
            step: '1',
          },
          {
            name: 'ZEBRANIA SZKOLENIA WSD',
            link: '/training',
            step: '1',
          },
          {
            name: 'ZDARZENIA NADZWYCZAJNE',
            link: '/extraordinary-occurence',
            step: '1',
          },
          {
            name: 'POLITYKA BEZPIECZEŃSTWA INFORMACJI',
            link: '/information-security-policy',
            step: '1',
          },
          {
            name: 'PROCEDURY BHP',
            link: '/bhp',
            step: '1',
          },
          {
            name: 'WYNIKI ANALIZ ZESPOŁÓW WSD',
            link: '/team-analysis-result',
            step: '1',
          },
          {
            name: 'WIFI – HASŁO',
            link: '/wifi',
            step: '1',
          },
          //   END WSD
          {
            icon: 'mdi-comment-account',
            name: 'Kontakty zewnętrzne',
            link: '/contacts',
            step: '1',
          },
          {
            icon: 'mdi-comment-account',
            name: 'Kontakty wewnętrzne',
            link: '/internal-contacts',
            step: '1',
          },

          {
            icon: 'mdi-account',
            name: 'Osobiste',
            step: '3',
          },
          {
            icon: 'mdi-book',
            name: 'Rejestry',
            step: '2',
          },
          {
            icon: 'mdi-desktop-mac',
            name: 'Admin',
            step: '4',
          },
          {
            icon: 'mdi-shield-key',
            name: 'Super Admin',
            step: '5',
          },
        ],

        WSDStepTwo: [
          {
            name: 'PRACOWNICY',
            icon: 'mdi-account-search',
            link: '/workers',
          },
          {
            name: 'DEPARTAMENTY',
            icon: 'mdi-note-multiple-outline',
            link: '/departments',
          },
        ],

        PersonalItems: [
          {
            name: 'Profil',
            icon: 'mdi-account-search',
            link: '/profile',
          },
          {
            name: 'Zadania',
            icon: 'mdi-note-multiple-outline',
            link: '/tasks',
          },
          {
            name: 'Alerty',
            icon: 'mdi-bell-ring-outline',
            link: '/alerts',
          },
          {
            icon: 'mdi-safe-square-outline',
            name: 'Schowek',
            link: '/storage',
            step: '1',
          },
          // {
          //     title: "Powiadomienia",
          //     icon: "mdi-bell-ring-outline",
          //     link: "/reminders"
          // }
        ],
        AdminItems: [
          {
            name: 'Rejestry',
            icon: 'mdi-book-variant',
            link: '/registries',
          },
          {
            name: 'Grupy uprawnień',
            icon: 'mdi-account-group',
            link: '/groups',
          },
          {
            name: 'Słowniki',
            icon: 'mdi-book-alphabet',
            link: '/dictionaries',
          },
          {
            name: 'Logowania',
            icon: 'mdi-account-convert',
            link: '/session_logs',
          },
          {
            name: 'Szablony',
            icon: 'mdi-file-table-box-multiple-outline',
            link: '/templates',
          },
          {
            name: 'Zasoby współdzielone',
            icon: 'mdi-animation',
            link: '/resources',
          },
        ],
      },
    };
  },

  methods: {
    ...mapActions([
      'stopSessionTimer',
      'fetchData',
      'openSecondModal',
      'changeLayout',
    ]),
    ...mapMutations(['changeRouterViewKey']),
    switchLayout(item) {
      this.changeLayout({
        wsd: item,
      });
    },
    timeCorrection(time) {
      if (time < 10) return (time = '0' + time);
      else return time;
    },
    async refreshSessionTime() {
      await Vue.axios({ method: 'GET', url: '/refreshSessionTime' }).then(
        (response) => {
          this.getSession.cookie = response.data.session.cookie;
        }
      );
    },
    openQRScaner() {
      this.getQRModal.open = true;
      this.getQRModal.camera = 'auto';
      this.getQRModal.Message.text = '';
      this.getQRModal.Message.color = '';
      this.getQRModal.SN = '';
    },
    goToStepper(step) {
      this.getNav.stepper = step;
    },
    // === === LOG OUT FUNCTION === === /
    logout() {
      Vue.axios({
        method: 'POST',
        url: '/logout',
      }).then((response) => {
        if (response.data.done) {
          this.$router.push('/login');
          this.stopSessionTimer();
        }
      });
    },
    showMiniDrawer() {
      window.innerWidth > 1200 ? (this.mini = false) : (this.mini = true);
    },
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getGlobalPaths',
      'getUserRegistries',
      'getQRModal',
      'getLayoutWSD',
      'getSession',
      'getNav',
      'getPreloader',
      'getRBNotifications',
      'getRightBoxTasks',
    ]),
    tasksCount: {
      get() {
        return this.getRightBoxTasks.filter((el) => el.isDone == false).length;
      },
    },
    alertsCount: {
      get() {
        const moment = require('moment');
        const today = moment().startOf('day');

        let arr = this.getRBNotifications.filter(
          (el) =>
            new Date(el.dueDate) <=
            moment(today)
              .endOf('day')
              .toDate()
        ).length;
        return arr;
      },
    },
    ...mapState({
      sessionVoid: (state) => state['sessionTimer'].session.done,
      timer: (state) => state['sessionTimer'].session.timer,
      openSessionModal: (state) =>
        state['sessionTimer'].session.openSecondModal,
    }),
    getCorrectRegistries() {
      return this.getUserRegistries.filter((item) => {
        return item.name != 'Kontakty' ? true : false;
      });
    },

    getCorrectRegistriesWSD() {
      const arr1 = this.nav.WSDStepTwo;
      return arr1;
    },
    avatar() {
      return `${this.getUser.name[0]}${this.getUser.lastname[0]}`;
    },
    showProfilePicture() {
      return this.getGlobalPaths.profilePic + this.getUser.picture;
    },

    correctNavItems() {
      // OUR MENU
      const arr3 = [];
      if (this.getLayoutWSD.wsd == false) {
        const arr1 = this.nav.items.filter((item) => {
          // UKRYWANIE ZAKŁADKI REJESTRU DLA OSÓB PONIŻEJ ADMINA
          if (this.getUser.isSuperAdmin) {
            return true;
          } else {
            if (item.name === 'Admin' && !this.getUser.isAdmin) return false;
            else if (item.name === 'Super Admin' && !this.getUser.isSuperAdmin)
              return false;
            else return true;
          }
        });
        return arr1;
      }
      //   MENU WSD
      if (this.getLayoutWSD.wsd == true) {
        const arr2 = this.nav.itemsWSD.filter((item) => {
          // UKRYWANIE ZAKŁADKI REJESTRU DLA OSÓB PONIŻEJ ADMINA
          if (
            this.getLayoutWSD.wsd == true &&
            !this.getUser.isAdmin &&
            !this.getUser.isSuperAdmin
          ) {
            if (item.name === 'Rejestry') return false;
          }
          if (this.getUser.isSuperAdmin) {
            return true;
          } else {
            if (item.name === 'Admin' && !this.getUser.isAdmin) return false;
            else if (item.name === 'Super Admin' && !this.getUser.isSuperAdmin)
              return false;
            else return true;
          }
        });
        return arr2;
      }
    },
  },
  watch: {
    sessionVoid: function(newval, oldval) {
      if (newval) this.logout();
    },
    openSessionModal: function(newval, oldval) {
      if (newval)
        this.openSecondModal({
          width: 500,
          open: true,
          type: 'sessionEnds',
        });
    },
  },
  beforeMount() {
    this.showMiniDrawer();
  },
};
</script>
