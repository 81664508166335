import Vue from 'vue';
import i18n from '@/i18n.js';
import findIndex from 'lodash.findindex';

const state = {
	template: {
		title: 'Szablony',
		header: [
			{
				text: 'Nazwa rejestru',
				align: 'left',
				value: 'name',
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			name: '',
		},
		errors: {
			name: '',
		},
	},
};

const getters = {
	getTemplate: (state) => state.template,
	getTemplateData: (state) => state.modal.fields,
	getTemplateErrors: (state) => state.modal.errors,
};

const actions = {};

const mutations = {};

export default {
	state,
	getters,
	actions,
	mutations,
};
