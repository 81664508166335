import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
    modalNonMedicalPrint: false,
    nonMedicalPrint: {
        title: 'DRUKI NIEMEDYCZNE',
        header: [{
            text: 'Nazwa',
            value: 'name',
            class: 'central__table__header',
            width: '70%',
        },
        {
            text: 'Menager',
            value: 'menager',
            class: 'central__table__header',
            width: '10%',
        },
        // {
        //     text: 'Status',
        //     value: 'status',
        //     class: 'central__table__header',
        //     width: '10%',
        // },
        {
            text: 'Akcja',
            align: 'right',
            value: 'action',
            width: '10%',
            sortable: false,
            class: 'central__table__header',
        },
        ],
    },
    modal: {
        fields: {
            title: '',
            archiveDate: '',
            information: '',
            version: '',
        },
        errors: {
            title: '',
            archiveDate: '',
            information: '',
            version: '',
        },
        details: {
            title: '',
            archiveDate: '',
            information: '',
            version: '',
        },
    },
    fieldsNames: {
        title: 'Nazwa druku, formularza',
        archiveDate: 'Data archiwizacji',
        information: 'Informacja',
        nonMedicalIndex: 'Index',
        version: 'Wersja',
    },
};

const getters = {
    getNonMedicalPrint: (state) => state.nonMedicalPrint,
    getNonMedicalPrintModal: (state) => state.modal.fields,
    getNonMedicalPrintError: (state) => state.modal.errors,
    getNonMedicalPrintDetails: (state) => state.modal.details,
    getNonMedicalPrintFieldsNames: (state) => state.fieldsNames,
};

const actions = {
    createNonMedicalPrint({
        dispatch,
        state,
        commit
    }, payload) {
        if (state.modal.fields.title === '') {
            state.modal.errors.title = 'To pole jest wymagane';
        } else {
            Vue.axios({
                method: 'POST',
                url: '/non-medical-print',
                data: {
                    ...state.modal.fields,
                    ...payload,
                },
            })
                .then((response) => {
                    dispatch('fetchData', {
                        page: 1,
                        pageSize: 11,
                        search: '',
                        sortBy: [],
                        sortDesc: [],
                        routeName: 'non-medical-print',
                    });
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie dodano nowy druk',
                    });
                })
                .catch((error) => {
                    commit('setErrors', {
                        route: 'nonMedicalPrint',
                        err: error.response.data.error,
                    });
                });
        }
    },

    nonMedicalPrintsDeatils({
        commit
    }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/non-medical-print/${payload.id}`,
        })
            .then((response) => {
                if (payload.type == 'modal') {
                    commit('setNonMedicalPrintModal', response.data.item);
                } else {
                    commit('setDetailsMenager', response.data.item.menager);
                    commit('setDetailsStatus', response.data.item.status);
                    commit('setDetailsStatuses', response.data.statuses);
                    commit('setNonMedicalPrintDeatils', response.data.item);
                    commit('setDetailsIndex', response.data.item.index);
                    if (payload.next) {
                        payload.next();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                commit('setErrors', {
                    route: 'nonMedicalPrint',
                    err: error.response.data.error,
                });
            });
    },

    updateNonMedicalPrints({
        state,
        commit
    }, payload) {
        if (state.modal.fields.title === '') {
            state.modal.errors.title = 'To pole jest wymagane';
        } else {
            Vue.axios({
                method: 'PUT',
                url: `/non-medical-print/edit/${payload}`,
                data: {
                    ...state.modal.fields,
                },
            })
                .then((response) => {
                    commit('setNonMedicalPrintDeatils', response.data.item);
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie edytowano informacje',
                    });
                })
                .catch((error) => {
                    console.log(error);
                    commit('setErrors', {
                        route: 'nonMedicalPrint',
                        err: error.response.data.error,
                    });
                });
        }
    },
};

const mutations = {
    setNonMedicalPrintDeatils(state, payload) {
        state.modal.details = payload;
        state.modal.fields.archiveDate = payload.archiveDate ?
            moment(payload.archiveDate).format('YYYY-MM-DD') :
            '';
    },
    setNonMedicalPrintModal(state, payload) {
        state.modal.fields = payload;
        state.modal.fields.archiveDate = payload.archiveDate ?
            moment(payload.archiveDate).format('YYYY-MM-DD') :
            '';
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};