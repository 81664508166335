<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="newOrder">
                <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj nowe zarządzenie</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"]),
    },
    methods: {
        ...mapActions(["openModal", "fetchItems"]),
        newOrder() {
            this.fetchItems("users");
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "create",
                width: 600,
                route: "directorOrders",
                tab: "director-orders",
            });
        },
    },
};
</script>