<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            class="no-pointer"
            readonly
            v-model="getOffersDetails.titleOffers"
            dense
            :label="getOffersFieldsNames.titleOffers"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="9">
          <v-text-field
            class="no-pointer"
            readonly
            :value="getOffersDetails.contractorOffers ? getOffersDetails.contractorOffers.name : 'Brak'"
            dense
            :label="getOffersFieldsNames.contractorOffers"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            class="no-pointer"
            readonly
            :value="getOffersDetails.responsibleOffers ? showcorrectResponsible(getOffersDetails.responsibleOffers.name, getOffersDetails.responsibleOffers.lastname, getOffersDetails.responsibleOffers.phoneNumber, getOffersDetails.responsibleOffers.email) : 'Brak'"
            dense
            :label="getOffersFieldsNames.responsibleOffers"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="no-pointer"
            readonly
            v-model="getOffersDetails.typeOffers"
            dense
            :label="getOffersFieldsNames.typeOffers"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="no-pointer"
            readonly
            v-model="getOffersDetails.typeContractor"
            dense
            :label="getOffersFieldsNames.typeContractor"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-text-field
            class="no-pointer"
            readonly
            :value="getOffersDetails.dataimportanceOffers ? correctData(getOffersDetails.dataimportanceOffers) : 'Brak'"
            dense
            :label="getOffersFieldsNames.dataimportanceOffers"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="12">
          <v-textarea
            :label="getOffersFieldsNames.commentOffers"
            v-model="getOffersDetails.commentOffers"
            class="no-pointer"
            readonly
            rows="2"
            auto-grow
          ></v-textarea>
        </v-col>

      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Separator
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    showcorrectResponsible(name, lastname, phoneNumber, email){
      let Responsible;
      if (name != undefined && name !='') {
        Responsible = name;
      }
      if (lastname != undefined && lastname !='') {
        Responsible = Responsible +' '+ lastname;
      }
      if (email != undefined && email !='') {
         Responsible = Responsible +' • '+ email;
      }
      if (phoneNumber != undefined && phoneNumber !='') {
         Responsible = Responsible +' • '+ phoneNumber;
      }
      return `${Responsible}`;
    }
    
  },
  computed: {
    ...mapGetters(["getOffersDetails", "getOffersFieldsNames"])
  },

};
</script>