<template>
  <!-- :width="getModal.width" -->
  <!-- PRZESUWANIE MODALA  -->

  <v-dialog
    v-model="getModal.open"
    :width="getModal.width"
    style="z-index: 150; position: absolute"
    @input="(v) => v || clearModal()"
    class="elevation-0"
    :content-class="getLayoutWSD.wsd == true ? 'drag-modal' : ''"
  >
    <v-card
      class="p-relative elevation-0"
      :class="getLayoutWSD.wsd == true ? 'drag-content' : ''"
      elevation="0"
      :width="getModal.width"
      v-dragged="getLayoutWSD.wsd == true ? onDragged : empty"
    >
      <ModalHeader :headLine="getModal.headLine" />
      <v-card-text class="AE">
        <TaskContent
          v-if="$route.name === 'tasks' || getModal.tab === 'tasks_devices'"
        ></TaskContent>
        <OrderTodayContent
          v-if="$route.name === 'service' && getModal.tab === 'orderToday'"
        ></OrderTodayContent>
        <GroupsContent v-if="$route.name === 'groups'" />
        <DepartmentContent v-if="$route.name === 'departments'" />

        <!-- TABS -->
        <UsersContent
          v-if="$route.name === 'users' || getModal.tab === 'information_users'"
        />
        <StaffContent v-if="getModal.tab === 'staff_users'" />
        <DevicesContent v-if="getModal.tab === 'info_devices'" />
        <ImplementationContent
          v-if="getModal.tab === 'implementation_devices'"
        />
        <ServiceContent v-if="getModal.tab === 'new_service'" />
        <!-- <NewService v-if="getModal.tab === 'new_service'" /> -->
        <ImplementationService
          v-if="getModal.tab === 'implementation_service'"
        />
        <PartsService v-if="getModal.tab === 'parts_service'" />

        <!-- GLOBAL -->
        <NotificationContent v-if="getModal.tab === 'notification'" />
        <NoteContent v-if="getModal.tab === 'note'" />
        <RegistryContent v-if="$route.name === 'registries'" />
        <ResourcesContent v-if="getModal.tab === 'resources'" />
        <!-- zakomentowane są właściwe -->
        <AttachmentContent v-if="getModal.tab === 'attachment'" />
        <!-- <AttachmentsFolderContent v-if="getModal.tab === 'attachment'" /> -->
        <PartsContent v-if="getModal.tab === 'parts'" />
        <CarsContent v-if="getModal.tab === 'cars'" />
        <PrintersContent v-if="getModal.tab === 'printers'" />
        <ContactContent
          v-if="
            $route.name === 'contacts' ||
              getModal.tab === 'contacts_information'
          "
        />
        <TemplatesContent v-if="getModal.tab === 'templates'" />
        <Leasing v-if="getModal.tab === 'leasing'" />
        <LeasingaddIT v-if="getModal.tab === 'leasingaddIT'" />
        <LeasingaddAuto v-if="getModal.tab === 'leasingaddAuto'" />
        <Tenders v-if="getModal.tab === 'tenders'" />
        <TendersRap v-if="getModal.tab === 'tendersrap'" />

        <!-- WSD -->

        <DirectorOrdersContent v-if="getModal.tab === 'director-orders'" />
        <CoronavirusContent v-if="getModal.tab === 'coronavirus'" />
        <ISO v-if="getModal.tab === 'iso'" />
        <CurrentInformations v-if="getModal.tab === 'current-informations'" />
        <DutyInformations v-if="getModal.tab === 'duty'" />
        <AccreditationInformations v-if="getModal.tab === 'accreditation'" />
        <PharmacotherapyContent v-if="getModal.tab === 'pharmacotherapy'" />
        <Agreement v-if="getModal.tab === 'agreement'" />
        <Offers v-if="getModal.tab === 'offers'" />
        <OffersImplementation v-if="getModal.tab === 'offersImplementation'" />

        <BenefitsValueContent v-if="getModal.tab === 'benefits-value'" />
        <NonMedicalPrintsContent v-if="getModal.tab === 'non-medical-print'" />
        <PatientsConsentContent v-if="getModal.tab === 'patients-consent'" />

        <ExternalPrintsContent v-if="getModal.tab === 'external-prints'" />
        <InternalPrintsContent v-if="getModal.tab === 'internal-prints'" />
        <TrainingContent v-if="getModal.tab === 'training'" />
        <ExtraordinaryOccurenceContent
          v-if="getModal.tab === 'extraordinary-occurence'"
        />
        <BhpContent v-if="getModal.tab === 'bhp'" />
        <TeamAnalysisResultContent
          v-if="getModal.tab === 'team-analysis-result'"
        />
        <SecurityPolicyContent
          v-if="getModal.tab === 'information-security-policy'"
        />
        <InternalContactsContent v-if="getModal.tab === 'internal-contacts'" />

        <RepresentativesContent
          v-if="getModal.tab === 'contacts_representatives'"
        />
        <BranchesContent v-if="getModal.tab === 'contacts_branches'" />
        <SessionLogsModalContent v-if="getModal.tab === 'session_logs'" />
        <ModelContent v-if="getModal.tab === 'models'" />
        <QRCode v-if="getModal.tab === 'qrCode'" />
        <DietNutritionContent v-if="getModal.tab === 'diet-nutrition'" />
        <WifiContent v-if="getModal.tab === 'wifi'" />
        <ShareContent v-if="getModal.tab === 'shares'" />
        <StorageSendContent v-if="getModal.tab === 'storage'" />
        <StorageAttachmentsContent
          v-if="getModal.tab === 'attachmentFromStorage'"
        ></StorageAttachmentsContent>
        <ConstructionsContent v-if="getModal.tab === 'constructions'" />
        <WarehouseContent v-if="getModal.tab === 'warehouse'" />
        <MaterialsContent v-if="getModal.tab === 'materials'" />
      </v-card-text>
      <ModalFooter />
    </v-card>
  </v-dialog>
</template>

<script>
// === === GLOBAL === === //
import Vue from 'vue';
import { mapGetters } from 'vuex';

// === === MODAL PIECES === === //
import ModalHeader from '@/components/Global/Modal/ModalHeader.vue';
import ModalFooter from '@/components/Global/Modal/ModalFooter.vue';

// === CONTENTS === === //
import RegistryContent from '@/components/Admin/Registry/AEContent.vue';
import GroupsContent from '@/components/Admin/Groups/AEContent.vue';
import DepartmentContent from '@/components/Registry/Department/AEContent.vue';
import TaskContent from '@/components/Personal/Tasks/AEContent.vue';
import UsersContent from '@/components/Registry/Users/Information/AEContent.vue';
import ModelContent from '@/components/Registry/Models/Information/AEContent.vue';
import DevicesContent from '@/components/Registry/Devices/Information/AEContent.vue';
import AttachmentsContent from '@/components/Global/Details/Attachments/AttachmentDetails.vue';
import StaffContent from '@/components/Registry/Users/Staff/AEContent.vue';
import ImplementationContent from '@/components/Registry/Devices/Implementation/AEContent.vue';
import NotificationContent from '@/components/Global/Details/Notifications/AEContent.vue';
import NoteContent from '@/components/Global/Details/Note/AEContent.vue';
import AttachmentContent from '@/components/Global/Details/Attachments/AEContent.vue';
import PartsContent from '@/components/Registry/Parts/Information/AEContent.vue';
import PrintersContent from '@/components/Registry/Printers/Information/AEContent.vue';
//import RpintersContent from "@/components/Registry/Printers/Information/AEContent.vue";
//import AttachmentsFolderContent from "@/components/Global/Details/AttachmentsIso/ModalFolder.vue";
import CarsContent from '@/components/Registry/Cars/Information/AEContent.vue';
import ServiceContent from '@/components/Registry/Service/Information/AEContent.vue';
import ImplementationService from '@/components/Registry/Service/ImplementationService/AEContent.vue';
import PartsService from '@/components/Registry/Service/PartsService/AEContent.vue';
import ContactContent from '@/components/Contact/Information/AEContent.vue';
import RepresentativesContent from '@/components/Contact/Representatives/AEContent.vue';
import BranchesContent from '@/components/Contact/Branches/AEContent.vue';
import QRCode from '@/components/Global/QRCode/QRCode.vue';
import OrderTodayContent from '@/components/Registry/Service/OrderToday/Information.vue';
import SessionLogsModalContent from '../../Admin/SessionLogs/AEContent.vue';
import ResourcesContent from '@/components/Admin/Resources/AEContent.vue';
import TemplatesContent from '../../../components/Admin/Templates/AEContent.vue';
import ShareContent from '../../Admin/Resources/AEShare';
import StorageSendContent from '../../../components/Personal/Storage/AEContent.vue';
import StorageAttachmentsContent from '../../../components/Personal/Storage/ContentForAttachments.vue';
import ConstructionsContent from '../../../components/Registry/Constructions/AEContent.vue';
import WarehouseContent from '../../../components/Registry/Warehouse/AEContent.vue';

// <======= WSD ======>
import DirectorOrdersContent from '@/components/Registry/DirectorOrders/Information/AEContent.vue';
import CoronavirusContent from '@/components/Registry/Coronavirus/Information/AEContent.vue';
import ISO from '@/components/Registry/ISO/Information/AEContent.vue';
import CurrentInformations from '@/components/Registry/CurrentInformations/Information/AEContent.vue';
import DutyInformations from '@/components/Registry/Duty/Information/AEContent.vue';
import AccreditationInformations from '@/components/Registry/Accreditation/Information/AEContent.vue';
import PharmacotherapyContent from '@/components/Registry/Pharmacotherapy/Information/AEContent.vue';
import Agreement from '@/components/Registry/Agreement/Information/AEContent.vue';
import Offers from '@/components/Registry/Offers/Information/AEContent.vue';
import OffersImplementation from '@/components/Registry/Offers/Implementation/AEContent.vue';
import Leasing from '@/components/Registry/Leasing/Information/AEContent.vue';
import LeasingaddIT from '@/components/Registry/Leasing/Information/AEContentAddIT.vue';
import LeasingaddAuto from '@/components/Registry/Leasing/Information/AEContentAddAuto.vue';
import Tenders from '@/components/Registry/Tenders/Information/AEContent.vue';
import TendersRap from '@/components/Registry/Tenders/Information/AEContentRap.vue';
import BenefitsValueContent from '../../../components/Registry/BenefitsValue/AEContent.vue';
import PatientsConsentContent from '@/components/Registry/PatientsConsent/Information/AEContent.vue';
import ExternalPrintsContent from '@/components/Registry/ExternalPrints/Information/AEContent.vue';
import InternalPrintsContent from '@/components/Registry/InternalPrints/Information/AEContent.vue';
import TrainingContent from '@/components/Registry/Training/Information/AEContent.vue';
import NonMedicalPrintsContent from '@/components/Registry/NonMedicalPrints/Information/AEContent.vue';
import ExtraordinaryOccurenceContent from '@/components/Registry/ExtraordinaryOccurance/Information/AEContent.vue';
import BhpContent from '@/components/Registry/Bhp/Information/AEContent.vue';
import TeamAnalysisResultContent from '@/components/Registry/TeamAnalysisResult/Information/AEContent.vue';
import SecurityPolicyContent from '@/components/Registry/SecurityPolicy/Information/AEContent.vue';
import DietNutritionContent from '../../../components/Registry/DietNutrition/AEContent.vue';
import WifiContent from '../../../components/Registry/Wifi/AEContent.vue';
import InternalContactsContent from '@/components/Registry/InternalContacts/Information/AEContent.vue';
import MaterialsContent from '../../../components/Registry/Materials/AEContent.vue';
import VDragged from 'v-dragged';
Vue.use(VDragged);
export default {
  components: {
    VDragged,
    ModalHeader,
    ModalFooter,
    // AttachmentsFolderContent,
    RegistryContent,
    GroupsContent,
    DepartmentContent,
    TaskContent,
    DevicesContent,
    StaffContent,
    ImplementationContent,
    UsersContent,
    NotificationContent,
    NoteContent,
    AttachmentContent,
    PartsContent,
    PrintersContent,
    CarsContent,
    ServiceContent,
    ContactContent,
    ImplementationService,
    PartsService,
    RepresentativesContent,
    BranchesContent,
    ModelContent,
    QRCode,
    OrderTodayContent,
    SessionLogsModalContent,
    DirectorOrdersContent,
    CoronavirusContent,
    Agreement,
    Offers,
    CurrentInformations,
    DutyInformations,
    AccreditationInformations,
    PharmacotherapyContent,
    ISO,
    BenefitsValueContent,
    NonMedicalPrintsContent,
    ExternalPrintsContent,
    InternalPrintsContent,
    PatientsConsentContent,
    TrainingContent,
    ExtraordinaryOccurenceContent,
    BhpContent,
    TeamAnalysisResultContent,
    SecurityPolicyContent,
    DietNutritionContent,
    WifiContent,
    InternalContactsContent,
    Leasing,
    LeasingaddIT,
    LeasingaddAuto,
    Tenders,
    TemplatesContent,
    ResourcesContent,
    ShareContent,
    StorageSendContent,
    StorageAttachmentsContent,
    ConstructionsContent,
    WarehouseContent,
    MaterialsContent,
    OffersImplementation,
  },
  data: () => ({}),
  methods: {
    clearModal() {
      this.$store.commit('setModal', {
        headLine: '',
        open: false,
        width: 0,
        type: '',
      });
    },
    empty() {},

    onDragged({
      el,
      deltaX,
      deltaY,
      offsetX,
      offsetY,
      clientX,
      clientY,
      first,
      last,
    }) {
      if (first) {
        this.isDragging = true;

        return;
      }
      if (last) {
        this.isDragging = false;
        return;
      }

      var l = +window.getComputedStyle(el)['left'].slice(0, -2) || 0;
      var t = +window.getComputedStyle(el)['top'].slice(0, -2) || 0;
      el.style.left = l + deltaX + 'px';
      el.style.top = t + deltaY + 'px';
    },
  },
  computed: {
    ...mapGetters(['getModal', 'getLayoutWSD']),
  },
};
</script>
