<template>
  <v-container class="central">
    <v-row class="pa-4">
      <!-- === === MAIN HEADER AND OPTIONS === === -->
      <div class="d-flex justify-space-between" style="width: 100%">
        <div class="central__title">
          <!-- === MAIN HEADER ===  -->
          <h1
            style="font-size: 1.1em"
            class="font-weight-light ml-4 pointer"
            @click="resetTable"
          >
            {{ $t(title) }}
          </h1>
          <!-- === OPTIONS NEXT TO HEADER === -->
          <v-breadcrumbs
            :items="topOptions"
            divider="|"
            class="pb-0"
          ></v-breadcrumbs>
        </div>

        <!-- === RAPORT TENDERS === -->
        <div
          class="d-flex px-7 py-0 align-center"
          v-if="$route.name == 'tenders'"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                style="font-size: 14px; color: rgba(0, 0, 0, 0.87); line-height: 2;"
                class="font-weight-light ml-4 pointer"
                >{{ 'Raport za ' }} {{ getTable.countYear }}{{ ': ' }}
                {{ getTable.countTotal }}{{ '/' }}{{ getTable.countWin }}{{ '/'
                }}{{ getTable.countLose }}</span
              >
            </template>
            <span>Wszystkie przetargi / Wygrane / Przegrane</span>
          </v-tooltip>
          <ShowReport />
          <!--
        <v-menu
        :close-on-content-click="false"
        max-width="500"
        v-model="getURole.open"
        @input="v => v || clearURole()"
      >
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="setURoleType('create')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <p
            class="display-1 py-3 text-center ma-0 font-weight-light"
          >{{'Raport'}}</p>
          <v-divider></v-divider>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12">
                <v-text-field

                  label="Nazwa"
                ></v-text-field>
              </v-col>
              <v-col md="3" offset-md="8">


              </v-col>
            </v-row>
          </v-container>
        </v-card>
                    </v-menu>-->
        </div>

        <div class="d-flex px-3 py-0 align-center" v-if="showDefaultMenager()">
          <div class="d-flex">
            <v-avatar
              class="pointer"
              :color="!getDefaultMenager.picture ? 'teal' : 'white'"
              size="38"
            >
              <span
                class="white--text body-1"
                v-if="!getDefaultMenager.picture"
              >
                {{ getDefaultMenager.name[0]
                }}{{ getDefaultMenager.lastname[0] }}
              </span>

              <img
                v-else
                style="object-fit: cover;"
                :src="
                  `${getGlobalPaths.profilePic}${getDefaultMenager.picture}`
                "
                alt="avatar"
              />
            </v-avatar>
          </div>
          <div class="d-flex flex-column px-1">
            <span style="font-size: 12px">Menager</span>
            <span
              style="font-size: 14px; color: rgba(0, 0, 0, 0.87); line-height: 1;"
              >{{ getDefaultMenager.name }}
              {{ getDefaultMenager.lastname }}</span
            >
          </div>
        </div>
      </div>

      <v-col cols="12" md="12">
        <v-card class="central__card" height="calc(100vh - 185px)">
          <v-container>
            <v-row>
              <!-- === === SEARCH INPUT === === -->
              <v-col
                md="5"
                lg="3"
                offset-lg="9"
                offset-md="7"
                class="searchMarginTablet"
              >
                <v-text-field
                  :label="$t('global.search') + '...'"
                  v-model="table.search"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  @input="search"
                ></v-text-field>
              </v-col>

              <!-- === === TABLE THINGS === === -->
              <v-col cols="12" md="12" style="z-index: 0;">
                <!-- === TABLE === -->
                <v-data-table
                  :headers="table.header"
                  :items="getTable.items"
                  fixed-header
                  height="calc(100vh - 365px)"
                  class="mt-4 central__table"
                  hide-default-footer
                  :items-per-page="rows_per_page"
                  :options.sync="options"
                  :loading="getTable.loading"
                >
                  <!-- === TABLE BODY === -->
                  <!-- <template v-slot:header.status>
                    <span
                      v-if="getPossibleMenagersItems.some(e=>e._id == getUser.id) || getPossibleMenagersItems <= 0"
                    >Status</span>
                    <span v-else></span>
                                    </template>-->

                  <template v-slot:body="{ items }">
                    <AlertColumns
                      :items="items"
                      v-if="$route.name == 'alerts'"
                    />
                    <TaskColumns :items="items" v-if="$route.name == 'tasks'" />
                    <PartsColumns
                      :items="items"
                      v-if="$route.name == 'parts'"
                    />
                    <DevicesColumns
                      :items="items"
                      v-if="$route.name == 'devices'"
                    />
                    <ModelsColumns
                      :items="items"
                      v-if="$route.name == 'models'"
                    />
                    <DepartmentsColumns
                      :items="items"
                      v-if="$route.name == 'departments'"
                    />
                    <UsersColumns
                      :items="items"
                      v-if="$route.name == 'users'"
                    />
                    <CarsColumns :items="items" v-if="$route.name == 'cars'" />
                    <ServiceColumns
                      :items="items"
                      v-if="$route.name == 'service'"
                    />
                    <RegistryColumns
                      :items="items"
                      v-if="$route.name == 'registries'"
                    />
                    <SessionLogsColumns
                      :items="items"
                      v-if="$route.name == 'session_logs'"
                    />
                    <GroupsColumns
                      :items="items"
                      v-if="$route.name == 'groups'"
                    />
                    <ContactsColumns
                      :items="items"
                      v-if="$route.name == 'contacts'"
                    />
                    <DutysColumns :items="items" v-if="$route.name == 'duty'" />
                    <AccreditationColumns
                      :items="items"
                      v-if="$route.name == 'accreditation'"
                    />
                    <NonMedicalPrintColumns
                      :items="items"
                      v-if="$route.name == 'non-medical-print'"
                    />
                    <InternalPrintsColumns
                      :items="items"
                      v-if="$route.name == 'internal-prints'"
                    />
                    <ExternalPrintsColumns
                      :items="items"
                      v-if="$route.name == 'external-prints'"
                    />
                    <LeasingColumns
                      :items="items"
                      v-if="$route.name == 'leasing'"
                    />
                    <TendersColumns
                      :items="items"
                      v-if="$route.name == 'tenders'"
                    />
                    <PatientsConsentColumns
                      :items="items"
                      v-if="$route.name == 'patients-consent'"
                    />
                    <ExtraordinaryOccuranceColumns
                      :items="items"
                      v-if="$route.name == 'extraordinary-occurence'"
                    />
                    <TrainingColumns
                      :items="items"
                      v-if="$route.name == 'training'"
                    />
                    <DietNutritionColumns
                      :items="items"
                      v-if="$route.name == 'diet-nutrition'"
                    ></DietNutritionColumns>

                    <WifiColumns
                      :items="items"
                      v-if="$route.name == 'wifi'"
                    ></WifiColumns>
                    <TeamAnalysisResultColumns
                      :items="items"
                      v-if="$route.name == 'team-analysis-result'"
                    />
                    <BhpColumns :items="items" v-if="$route.name == 'bhp'" />
                    <SecurityPolicyColumns
                      :items="items"
                      v-if="$route.name == 'information-security-policy'"
                    />
                    <InternalContactsColumns
                      :items="items"
                      v-if="$route.name == 'internal-contacts'"
                    />
                    <CurrentInformationsColumns
                      :items="items"
                      v-if="$route.name == 'current-informations'"
                    />
                    <PharmacotherapyColumns
                      :items="items"
                      v-if="$route.name == 'pharmacotherapy'"
                    />
                    <ISOColumns :items="items" v-if="$route.name == 'iso'" />
                    <AgreementColumns
                      :items="items"
                      v-if="$route.name == 'agreement'"
                    />
                    <BenefitsValueColumns
                      :items="items"
                      v-if="$route.name == 'benefits-value'"
                    />
                    <DirectorOrdersColumns
                      :items="items"
                      v-if="$route.name == 'director-orders'"
                    />
                    <CoronavirusColumns
                      :items="items"
                      v-if="$route.name == 'coronavirus'"
                    />

                    <OffersColumns
                      :items="items"
                      v-if="$route.name == 'offers'"
                    />
                    <ResourcesColumns
                      :items="items"
                      v-if="$route.name == 'resources'"
                    />
                    <ConstructionsColumns
                      :items="items"
                      v-if="$route.name == 'constructions'"
                    />
                    <MaterialsColumns
                      :items="items"
                      v-if="$route.name == 'materials'"
                    />
                    <WarehouseColumns
                      :items="items"
                      v-if="$route.name == 'warehouse'"
                    />
                    <TemplatesColumns
                      :items="items"
                      v-if="$route.name == 'templates'"
                    />
                  </template>
                </v-data-table>
                <!-- === === PAGINATION === === -->
                <div class="text-md-center pt-2 pagination__mobile">
                  <v-pagination
                    circle
                    v-model="table.page"
                    :total-visible="visiblePage"
                    :length="getTable.pages"
                    @input="refreshData"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- === === ACTION BUTTONS IN RIGHT BOTTOM CORNER === === -->
          <div class="central__actions">
            <!-- === USERS BUTTONS === -->
            <ButtonsUsers v-if="route_name == 'users'" />
            <ButtonsRegistry v-if="route_name == 'registries'" />
            <ButtonsGroups v-if="route_name == 'groups'" />
            <ButtonsTasks v-if="route_name == 'tasks'" />
            <ButtonsDepartment v-if="route_name == 'departments'" />
            <ButtonsDevices v-if="route_name == 'devices'" />
            <ButtonsParts v-if="route_name == 'parts'" />
            <ButtonsPrinters v-if="route_name == 'printers'" />
            <ButtonsCars v-if="route_name == 'cars'" />
            <ButtonsService v-if="route_name == 'service'" />
            <ButtonsContact v-if="route_name == 'contacts'" />
            <ButtonsAlerts v-if="route_name == 'alerts'" />
            <ButtonsModels v-if="route_name == 'models'" />
            <ButtonsISO v-if="route_name == 'iso'" />
            <ButtonsAgreement v-if="route_name == 'agreement'" />
            <ButtonsOffers v-if="route_name == 'offers'" />
            <ButtonsSessionLogs v-if="route_name == 'session_logs'" />
            <ButtonsDirectorOrders v-if="route_name == 'director-orders'" />
            <ButtonsCoronavirus v-if="route_name == 'coronavirus'" />
            <ButtonsCurrentInformations
              v-if="route_name == 'current-informations'"
            />
            <ButtonsAccreditation v-if="route_name == 'accreditation'" />
            <ButtonsPharmacotherapy v-if="route_name == 'pharmacotherapy'" />
            <ButtonsDuty v-if="route_name == 'duty'" />
            <!-- <ButtonsDiet v-if="route_name == 'diet'" /> -->
            <ButtonsBenefitsValue v-if="route_name == 'benefits-value'" />
            <ButtonsNonMedical v-if="route_name == 'non-medical-print'" />
            <ButtonsPatientsConsent v-if="route_name == 'patients-consent'" />
            <ButtonsExternalPrints v-if="route_name == 'external-prints'" />
            <ButtonsInternalPrints v-if="route_name == 'internal-prints'" />
            <ButtonsTraining v-if="route_name == 'training'" />
            <ButtonsExtraordinaryOccurence
              v-if="route_name == 'extraordinary-occurence'"
            />
            <ButtonsInformationSecurityPolicy
              v-if="route_name == 'information-security-policy'"
            />
            <ButtonsBhp v-if="route_name == 'bhp'" />
            <ButtonsTeamAnalysisResult
              v-if="route_name == 'team-analysis-result'"
            />
            <ButtonsDietNutrition
              v-if="route_name == 'diet-nutrition'"
            ></ButtonsDietNutrition>
            <ButtonsWifi v-if="route_name == 'wifi'" />
            <ButtonsInternalContacts v-if="route_name == 'internal-contacts'" />
            <ButtonsLeasing v-if="route_name == 'leasing'" />
            <ButtonsTenders v-if="route_name == 'tenders'" />
            <ButtonsResources v-if="route_name == 'resources'" />
            <ButtonsConstructions
              v-if="route_name == 'constructions'"
              @filter="applyFilter($event)"
            />
            <ButtonsWarehouse
              v-if="route_name == 'warehouse'"
              @filter="applyFilter($event)"
            />
            <ButtonsMaterials
              v-if="route_name == 'materials'"
              @filter="applyFilter($event)"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// === === GLOBAL IMPORTS === === //
import Vue from 'vue';

// === === VUEX MAPPING === === //
import { mapGetters, mapActions, mapMutations } from 'vuex';

// === === LODASH === === //
import debounce from 'lodash.debounce';
import truncate from 'lodash.truncate';

// === BUTTONS SESSION LOGS === //
import ButtonsSessionLogs from '../components/Admin/SessionLogs/Buttons.vue';
// === BUTTONS TASKS === //
import ButtonsTasks from '@/components/Personal/Tasks/Buttons.vue';
// === BUTTONS USERS === //
import ButtonsUsers from '@/components/Registry/Users/Buttons.vue';
// === BUTTONS DEPARTMENT === //
import ButtonsDepartment from '@/components/Registry/Department/Buttons.vue';
// === BUTTONS REGISTRY === //
import ButtonsRegistry from '@/components/Admin/Registry/Buttons.vue';
// === BUTTONS GROUPS === //
import ButtonsGroups from '@/components/Admin/Groups/Buttons.vue';
// === BUTTONS DEVICES === === //
import ButtonsDevices from '@/components/Registry/Devices/Buttons.vue';
// === BUTTONS PARTS === === //
import ButtonsParts from '@/components/Registry/Parts/Buttons.vue';
// === BUTTONS PRINTERS === === //
import ButtonsPrinters from '@/components/Registry/Printers/Buttons.vue';
// === BUTTONS CARS === === //
import ButtonsCars from '@/components/Registry/Cars/Buttons.vue';
// === BUTTONS ISO === === //
import ButtonsISO from '@/components/Registry/ISO/Buttons.vue';
// === BUTTONS DIRECTOR ORDERS === //
import ButtonsDirectorOrders from '@/components/Registry/DirectorOrders/Buttons.vue';
// === BUTTONS CORONAVIRUS === //
import ButtonsCoronavirus from '@/components/Registry/Coronavirus/Buttons.vue';
// === BUTTONS Agreement === === //
import ButtonsAgreement from '@/components/Registry/Agreement/Buttons.vue';
// === BUTTONS Offers === === //
import ButtonsOffers from '@/components/Registry/Offers/Buttons.vue';
// === BUTTONS Leasing === === //
import ButtonsLeasing from '@/components/Registry/Leasing/Buttons.vue';
// === BUTTONS Tenders === === //
import ButtonsTenders from '@/components/Registry/Tenders/Buttons.vue';
// === BUTTONS CURENT INFORMATIONS === === //
import ButtonsCurrentInformations from '@/components/Registry/CurrentInformations/Buttons.vue';
// === BUTTONS DUTY === === //
import ButtonsDuty from '@/components/Registry/Duty/Buttons.vue';
// === BUTTONS PHARMACOTHERAPY === === //
import ButtonsPharmacotherapy from '@/components/Registry/Pharmacotherapy/Buttons.vue';
// === BUTTONS ACCREDITATIONS === === //
import ButtonsAccreditation from '@/components/Registry/Accreditation/Buttons.vue';
// === BUTTONS NON MEDICAL PRINT === === //
import ButtonsNonMedical from '@/components/Registry/NonMedicalPrints/Buttons.vue';
// === BUTTONS PROVISION VALUE === === //
// === BUTTONS BENEFITS VALUE === //
import ButtonsBenefitsValue from '../components/Registry/BenefitsValue/Buttons.vue';
// === BUTTONS PATIENTS CONSENTC === === //
import ButtonsPatientsConsent from '@/components/Registry/PatientsConsent/Buttons.vue';
// === BUTTONS EXTERNAL PRINTS === === //
import ButtonsExternalPrints from '@/components/Registry/ExternalPrints/Buttons.vue';
// === BUTTONS INTERNAL PRINTS === === //
import ButtonsInternalPrints from '@/components/Registry/InternalPrints/Buttons.vue';
// === BUTTONS INTERNAL PRINTS === === //
import ButtonsTraining from '@/components/Registry/Training/Buttons.vue';
// === BUTTONS INTERNAL PRINTS === === //
import ButtonsExtraordinaryOccurence from '@/components/Registry/ExtraordinaryOccurance/Buttons.vue';
// === BUTTONS INTERNAL PRINTS === === //
import ButtonsInformationSecurityPolicy from '@/components/Registry/SecurityPolicy/Buttons.vue';
// === BUTTONS INTERNAL PRINTS === === //
import ButtonsBhp from '@/components/Registry/Bhp/Buttons.vue';
// === BUTTONS INTERNAL PRINTS === === //
import ButtonsTeamAnalysisResult from '@/components/Registry/TeamAnalysisResult/Buttons.vue';
// === BUTTONS INTERNAL CONTACTS === === //
import ButtonsInternalContacts from '@/components/Registry/InternalContacts/Buttons.vue';
// === BUTTONS MATERIALS === === //
import ButtonsMaterials from '../components/Registry/Materials/Buttons.vue';

// === BUTTONS DIET / NUTRITION === //
import ButtonsDietNutrition from '../components/Registry/DietNutrition/Buttons.vue';
// === BUTTONS EXTRAORDINARY EVENTS ===//
import ButtonsWifi from '../components/Registry/Wifi/Buttons.vue';
// === BUTTONS SERVICE === === //
import ButtonsService from '@/components/Registry/Service/Buttons.vue';
// === BUTTONS Contact === === //
import ButtonsContact from '@/components/Contact/Buttons.vue';
// === BUTTONS ALERTS === === //
import ButtonsAlerts from '@/components/Personal/Alerts/Buttons.vue';
// === BUTTONS ALERTS === === //
import ButtonsModels from '@/components/Registry/Models/Buttons.vue';

// === BUTTONS Resources === === //
import ButtonsResources from '@/components/Admin/Resources/Buttons.vue';
// === BUTTONS CONSTRUCTIONS === //
import ButtonsConstructions from '../components/Registry/Constructions/Buttons.vue';
// === BUTTONS WAREHOUSE === //
import ButtonsWarehouse from '../components/Registry/Warehouse/Buttons';

// === PARTS COLUMNS === //
import PartsColumns from '@/components/Registry/Parts/Colums.vue';
// === DEVICES COLUMNS === //
import DevicesColumns from '@/components/Registry/Devices/Colums.vue';
// === MODELS COLUMNS === //
import ModelsColumns from '@/components/Registry/Models/Colums.vue';
// === DEPARTMENTS COLUMNS === //
import DepartmentsColumns from '@/components/Registry/Department/Colums.vue';
// === USERS COLUMNS === //
import UsersColumns from '@/components/Registry/Users/Colums.vue';
// === CARS COLUMNS === //
import CarsColumns from '@/components/Registry/Cars/Colums.vue';
// === SERVICE COLUMNS === //
import ServiceColumns from '@/components/Registry/Service/Colums.vue';
// === REGISTRY COLUMNS === //
import RegistryColumns from '@/components/Admin/Registry/Colums.vue';
// === REGISTRY COLUMNS === //
import GroupsColumns from '@/components/Admin/Groups/Colums.vue';
// === CONTACTS COLUMNS === //
import ContactsColumns from '@/components/Contact/Colums.vue';
// === ALERTS COLUMNS === //
import AlertColumns from '@/components/Personal/Alerts/Colums.vue';
// === TASKS COLUMNS === //
import TaskColumns from '@/components/Personal/Tasks/Colums.vue';
// === SESSION LOGS COLUMNS === //
import SessionLogsColumns from '@/components/Admin/SessionLogs/Colums.vue';
// === ISO COLUMNS === //
import ISOColumns from '@/components/Registry/ISO/Colums.vue';
// === PHARMACOTHERAPY COLUMNS === //
import PharmacotherapyColumns from '@/components/Registry/Pharmacotherapy/Colums.vue';
// === Agreement COLUMNS === //
import AgreementColumns from '@/components/Registry/Agreement/Colums.vue';
// === Offers COLUMNS === //
import OffersColumns from '@/components/Registry/Offers/Colums.vue';
// === Leasing COLUMNS === //
import LeasingColumns from '@/components/Registry/Leasing/Colums.vue';
// === Tenders COLUMNS === //
import TendersColumns from '@/components/Registry/Tenders/Colums.vue';
// === Tenders Raport === //
import ShowReport from '@/components/Registry/Tenders/Information/AEContentRap.vue';
import ChangePassword from '@/components/Personal/Profile/ChangePassword.vue';
// === DIRECTOR ORDERS COLUMNS === //
import DirectorOrdersColumns from '@/components/Registry/DirectorOrders/Colums.vue';
// === CORONAVIRUS COLUMNS === //
import CoronavirusColumns from '@/components/Registry/Coronavirus/Colums.vue';
// === NON MEDICAL PRINTS COLUMNS === //
import NonMedicalPrintColumns from '@/components/Registry/NonMedicalPrints/Colums.vue';
// === CURENT INFORMATIONS COLUMNS === //
import CurrentInformationsColumns from '@/components/Registry/CurrentInformations/Colums.vue';
// === DUTY COLUMNS === //
import DutysColumns from '@/components/Registry/Duty/Colums.vue';
// === ACCREDITATION COLUMNS === //
import AccreditationColumns from '@/components/Registry/Accreditation/Colums.vue';
// === BENEFITS VALUE COLUMNS === //
import BenefitsValueColumns from '../components/Registry/BenefitsValue/Columns.vue';
// === BENEFITS PATIENTS CONSENTC === //
import PatientsConsentColumns from '../components/Registry/PatientsConsent/Colums.vue';
// === INTERNAL PRINTS === //
import InternalPrintsColumns from '../components/Registry/InternalPrints/Colums.vue';
// === EXTERNAL PRINTS === //
import ExternalPrintsColumns from '../components/Registry/ExternalPrints/Colums.vue';
// === TRAINING === //
import TrainingColumns from '../components/Registry/Training/Colums.vue';
// === EXTRAORDINARY OCCURANCE === //
import ExtraordinaryOccuranceColumns from '../components/Registry/ExtraordinaryOccurance/Colums.vue';
// === INFORMATION-SECURITY-POLICY === //
import SecurityPolicyColumns from '../components/Registry/SecurityPolicy/Colums.vue';
// === BHP === //
import BhpColumns from '../components/Registry/Bhp/Colums.vue';
// === TEAM ANALYSIS RESULT === //
import TeamAnalysisResultColumns from '../components/Registry/TeamAnalysisResult/Colums.vue';
// === DIET / NUTRITION COLUMNS === //
import DietNutritionColumns from '../components/Registry/DietNutrition/Columns.vue';
// === EXTRAORDINARY EVENTS COLUMNS === //
import WifiColumns from '../components/Registry/Wifi/Columns.vue';
// === INTERNAL CONTACTS === //
import InternalContactsColumns from '../components/Registry/InternalContacts/Colums.vue';
// === TEMPLATES === //
import TemplatesColumns from '../components/Admin/Templates/Columns.vue';
// === RESOURCES === //
import ResourcesColumns from '../components/Admin/Resources/Colums';
// === CONSTRUCTIONS === //
import ConstructionsColumns from '../components/Registry/Constructions/Columns.vue';
// === MATERIALS === //
import MaterialsColumns from '../components/Registry/Materials/Columns.vue';
// == WAREHOUSE === //
import WarehouseColumns from '../components/Registry/Warehouse/Columns.vue';

export default {
  components: {
    // === === BUTTONS === === //
    ButtonsUsers,
    ButtonsRegistry,
    ButtonsGroups,
    ButtonsTasks,
    ButtonsDepartment,
    ButtonsDevices,
    ButtonsParts,
    ButtonsDirectorOrders,
    ButtonsPrinters,
    ButtonsCars,
    ButtonsService,
    ButtonsContact,
    ButtonsAlerts,
    ButtonsModels,
    ButtonsISO,
    ButtonsAgreement,
    ButtonsOffers,
    ButtonsSessionLogs,
    ButtonsCurrentInformations,
    ButtonsPharmacotherapy,
    ButtonsTraining,
    ButtonsTraining,
    ButtonsDuty,
    ButtonsAccreditation,
    ButtonsBenefitsValue,
    ButtonsNonMedical,
    ButtonsPatientsConsent,
    ButtonsExternalPrints,
    ButtonsInternalPrints,
    ButtonsExtraordinaryOccurence,
    ButtonsTraining,
    ButtonsInformationSecurityPolicy,
    ButtonsBhp,
    ButtonsTeamAnalysisResult,
    ButtonsDietNutrition,
    ButtonsWifi,
    ButtonsInternalContacts,
    ButtonsLeasing,
    ButtonsTenders,
    ButtonsResources,
    ButtonsConstructions,
    ButtonsWarehouse,
    ButtonsMaterials,
    ButtonsCoronavirus,

    // === === === === === === //

    // === === COLUMNS === === //
    PartsColumns,
    ModelsColumns,
    DevicesColumns,
    DepartmentsColumns,
    UsersColumns,
    CarsColumns,
    ServiceColumns,
    RegistryColumns,
    GroupsColumns,
    ContactsColumns,
    AlertColumns,
    TaskColumns,
    SessionLogsColumns,
    ISOColumns,
    DirectorOrdersColumns,
    CoronavirusColumns,
    CurrentInformationsColumns,
    DutysColumns,
    AccreditationColumns,
    PharmacotherapyColumns,
    AgreementColumns,
    OffersColumns,
    BenefitsValueColumns,
    NonMedicalPrintColumns,
    ExternalPrintsColumns,
    InternalPrintsColumns,
    PatientsConsentColumns,
    ExtraordinaryOccuranceColumns,
    TrainingColumns,
    SecurityPolicyColumns,
    BhpColumns,
    TeamAnalysisResultColumns,
    DietNutritionColumns,
    WifiColumns,
    InternalContactsColumns,
    TemplatesColumns,
    LeasingColumns,
    TendersColumns,
    ResourcesColumns,
    ShowReport,
    ChangePassword,
    ConstructionsColumns,
    MaterialsColumns,
    WarehouseColumns,
    // === === === === === === //
  },
  props: ['route_name'],
  data: () => ({
    // === PAGINATION === //
    rows_per_page: 11,
    visiblePage: 5,
    options: {},
    windowSize: '',
    // ===  MAIN TITLE === //
    title: '',
    // ===  TABLE THINGS === //
    table: {
      search: '',
      page: 1,
      header: [],
      items: [],
    },

    filter: '',

    // computed: {
    //   // === === GETTING DATA FROM VUEX === === //
    //   ...mapGetters([
    //     'getUser',
    //     'getPossibleMenagersItems',
    //     'getUsers',
    //     'getTasks',
    //     'getRegistry',
    //     'getGroups',
    //     'getDepartment',
    //     'getTable',
    //     'getDevices',
    //     'getParts',
    //     'getService',
    //     'getCars',
    //     'getContacts',
    //     'getNotification',
    //     'getModels',
    //     'getIso',
    //     'getDirectorOrders',
    //     'getSessionLogs',
    //     'getPrinters',
    //     'getAgreement',
    //     'getGlobalPaths',
    //     'getSessionLogs',
    //     'getPrinters',
    //     'getCurrentInformation',
    //     'getAccreditation',
    //     'getDuty',
    //     'getPharmacotherapy',
    //     'getExtraordinaryOccurence',
    //     'getTraining',
    //     'getBenefitsValue',
    //     'getNonMedicalPrint',
    //     'getExternalPrints',
    //     'getInternalPrints',
    //     'getPatientsConsent',
    //     'getSecurityPolicy',
    //     'getTeamAnalysisResult',
    //     'getBhp',
    //     'getDietNutrition',
    //     'getCoronavirus',
    //     'getWifi',
    //     'getInternalContacts',
    //     'getLeasing',
    //     'getTenders',
    //     'getDefaultMenager',
    //     'getRegistryHeader',
    //     'getLayoutWSD',
    //   ]),
    // },

    //  methods: {
    // === === VUEX MAPPING === === //
    //  ...mapActions(["fetchData", "getPossibleMenagers", "openModal"]),
    //  ...mapMutations(["resetExtras", "changePassword"]),
    //  ...mapActions([
    //      "fetchData",
    //      "getPossibleMenagers",
    //      "fetchIsServiceLogistic"
    //  ]),
    //   ...mapMutations(["resetExtras"]),

    // ===  OPTIONS NEXT TO MAIN HEADER === //
    topOptions: [],
  }),
  watch: {
    options(newVal, oldVal) {
      if (
        newVal.sortDesc !== oldVal.sortDesc ||
        newVal.sortBy !== oldVal.sortBy
      ) {
        this.refreshData();
      }
    },
  },
  computed: {
    // === === GETTING DATA FROM VUEX === === //
    ...mapGetters([
      'getUser',
      'getPossibleMenagersItems',
      'getUsers',
      'getTasks',
      'getRegistry',
      'getGroups',
      'getDepartment',
      'getTable',
      'getDevices',
      'getParts',
      'getService',
      'getCars',
      'getContacts',
      'getNotification',
      'getModels',
      'getIso',
      'getDirectorOrders',
      'getCoronavirus',
      'getSessionLogs',
      'getPrinters',
      'getAgreement',
      'getOffers',
      'getGlobalPaths',
      'getSessionLogs',
      'getPrinters',
      'getCurrentInformation',
      'getAccreditation',
      'getDuty',
      'getPharmacotherapy',
      'getExtraordinaryOccurence',
      'getTraining',
      'getBenefitsValue',
      'getNonMedicalPrint',
      'getExternalPrints',
      'getInternalPrints',
      'getPatientsConsent',
      'getSecurityPolicy',
      'getTeamAnalysisResult',
      'getBhp',
      'getDietNutrition',
      'getWifi',
      'getInternalContacts',
      'getLeasing',
      'getTenders',
      'getDefaultMenager',
      'getRegistryHeader',
      'getLayoutWSD',
      'getTemplate',
      'getResources',
      'getConstructions',
      'getWarehouse',
      'getMaterial',
    ]),
  },
  methods: {
    // === === VUEX MAPPING === === //
    ...mapActions(['fetchData', 'getPossibleMenagers', 'openModal']),
    ...mapMutations(['resetExtras', 'changePassword']),

    // === === RESET ALL FILTERS (DEFAULT VIEW) === === //
    resetTable() {
      this.resetExtras();
      this.table.page = 1;
      this.table.search = '';
      this.filter = '';
      this.options = {
        sortBy: [],
        sortDesc: [],
      };
    },
    // === === MODAL SHOW RAPORT TENDERS === === //
    showReport() {
      this.openModal({
        headLine: 'global.create',
        width: 600,
        open: true,
        type: 'create',
        route: 'Tenders',
        tab: 'tendersrap',
      });
    },

    // === === SETTING TABLE DATA AND REFRESH ON ACTIONS LIKE SORT, SEARCH AND PAGINATION === === //
    refreshData() {
      const { sortBy, sortDesc } = this.options;
      const { page, search } = this.table;
      let routeName = this.$route.name;
      if (this.$route.name == 'templates') routeName = 'registries';
      this.fetchData({
        page,
        search,
        sortBy,
        sortDesc,
        routeName: routeName,
        pageSize: this.rows_per_page,
        filter: this.filter,
      });
    },
    showDefaultMenager() {
      switch (this.route_name) {
        case 'internal-contacts':
          return true;
          break;
        case 'diet-nutrition':
          return true;
          break;
        case 'benefits-value':
          return true;
          break;
        case 'information-security-policy':
          return true;
          break;
        case 'bhp':
          return true;
          break;
      }
    },

    applyFilter(val) {
      this.resetExtras();
      this.table.page = 1;
      this.table.search = '';
      this.options = {
        sortBy: [],
        sortDesc: [],
      };
      this.filter = val;
    },

    // === === SETTING TITLE AND HEADERS === === //

    async setTitleAndHeaders() {
      let tableHeader;
      switch (this.route_name) {
        case 'users':
          this.title = this.getUsers.title;
          tableHeader = this.getUsers.header;
          break;
        case 'tasks':
          this.title = this.getTasks.title;
          tableHeader = this.getTasks.header;
          break;
        case 'registries':
          this.title = this.getRegistry.title;
          tableHeader = this.getRegistry.header;
          break;
        case 'groups':
          this.title = this.getGroups.title;
          tableHeader = this.getGroups.header;
          break;
        case 'departments':
          this.title = this.getDepartment.title;
          tableHeader = this.getDepartment.header;
          break;
        case 'devices':
          this.title = this.getDevices.title;
          tableHeader = this.getDevices.header;
          break;
        case 'parts':
          this.title = this.getParts.title;
          tableHeader = this.getParts.header;
          break;
        case 'service':
          this.title = this.getService.title;
          tableHeader = this.getService.header;
          break;
        case 'cars':
          this.title = this.getCars.title;
          tableHeader = this.getCars.header;
          break;
        case 'contacts':
          this.title = this.getLayoutWSD.wsd
            ? 'KONTAKTY ZEWNĘTRZNE'
            : this.getContacts.title;
          tableHeader = this.getContacts.header;
          this.hiddenColumnOurVersion(tableHeader, 'website');
          break;
        case 'alerts':
          this.title = this.getNotification.title;
          tableHeader = this.getNotification.header;
          break;
        case 'models':
          this.title = this.getModels.title;
          tableHeader = this.getModels.header;
          break;
        case 'iso':
          this.title = this.getIso.title;
          tableHeader = this.getIso.header;
          break;
        case 'director-orders':
          this.title = this.getDirectorOrders.title;
          tableHeader = this.getDirectorOrders.header;
          this.checkColumnPermission(tableHeader, 'status');
          break;
        case 'coronavirus':
          this.title = this.getCoronavirus.title;
          tableHeader = this.getCoronavirus.header;
          this.checkColumnPermission(tableHeader, 'status');
          break;
        case 'agreement':
          this.title = this.getAgreement.title;
          tableHeader = this.getAgreement.header;
          break;
        case 'offers':
          this.title = this.getOffers.title;
          tableHeader = this.getOffers.header;
          break;
        case 'session_logs':
          this.title = this.getSessionLogs.title;
          tableHeader = this.getSessionLogs.header;
          break;
        case 'printers':
          this.title = this.getPrinters.title;
          tableHeader = this.getPrinters.header;
          break;
        case 'current-informations':
          this.title =
            this.getLayoutWSD.wsd === true
              ? this.getCurrentInformation.title
              : 'Informacje';

          tableHeader = this.getCurrentInformation.header;
          if (this.getLayoutWSD.wsd === true) {
            this.checkColumnPermission(tableHeader, 'action');
            this.checkColumnPermission(tableHeader, 'status');
          }
          break;
        case 'duty':
          this.title = this.getDuty.title;
          tableHeader = this.getDuty.header;
          break;
        case 'accreditation':
          this.title = this.getAccreditation.title;
          tableHeader = this.getAccreditation.header;
          break;
        case 'pharmacotherapy':
          this.title = this.getPharmacotherapy.title;
          tableHeader = this.getPharmacotherapy.header;
          this.checkColumnPermission(tableHeader, 'status');
          break;
        case 'benefits-value':
          this.title = this.getBenefitsValue.title;
          tableHeader = this.getBenefitsValue.header;
          break;
        case 'non-medical-print':
          this.title = this.getNonMedicalPrint.title;
          tableHeader = this.getNonMedicalPrint.header;
          break;
        case 'internal-prints':
          this.title = this.getInternalPrints.title;
          tableHeader = this.getInternalPrints.header;
          break;
        case 'external-prints':
          this.title = this.getExternalPrints.title;
          tableHeader = this.getExternalPrints.header;
          break;
        case 'patients-consent':
          this.title = this.getPatientsConsent.title;
          tableHeader = this.getPatientsConsent.header;
          break;
        case 'extraordinary-occurence':
          this.title = this.getExtraordinaryOccurence.title;
          tableHeader = this.getExtraordinaryOccurence.header;
          break;
        case 'training':
          this.title = this.getTraining.title;
          tableHeader = this.getTraining.header;
          break;
        case 'information-security-policy':
          this.title = this.getSecurityPolicy.title;
          tableHeader = this.getSecurityPolicy.header;
          break;
        case 'bhp':
          this.title = this.getBhp.title;
          tableHeader = this.getBhp.header;
          break;
        case 'team-analysis-result':
          this.title = this.getTeamAnalysisResult.title;
          tableHeader = this.getTeamAnalysisResult.header;
          break;
        case 'diet-nutrition':
          this.title = this.getDietNutrition.title;
          tableHeader = this.getDietNutrition.header;
          break;
        case 'wifi':
          this.title = this.getWifi.title;
          tableHeader = this.getWifi.header;
          this.checkColumnPermission(tableHeader, 'action');
          break;
        case 'templates':
          this.title = this.getTemplate.title;
          tableHeader = this.getTemplate.header;
          break;
        case 'internal-contacts':
          this.title = this.getInternalContacts.title;
          tableHeader = this.getInternalContacts.header;
          this.checkColumnPermission(tableHeader, 'action');
          this.checkColumnPermission(tableHeader, 'updatedAt');
          break;
        case 'leasing':
          this.title = this.getLeasing.title;
          tableHeader = this.getLeasing.header;
          break;
        case 'tenders':
          this.title = this.getTenders.title;
          tableHeader = this.getTenders.header;
          break;
        case 'templates':
          this.title = this.getTemplate.title;
          break;
        case 'resources':
          this.title = this.getResources.title;
          tableHeader = this.getResources.header;
          break;
        case 'constructions':
          this.title = this.getConstructions.title;
          tableHeader = this.getConstructions.header;
          break;
        case 'warehouse':
          this.title = this.getWarehouse.title;
          tableHeader = this.getWarehouse.header;
          break;
        case 'materials':
          this.title = this.getMaterial.title;
          tableHeader = this.getMaterial.header;
          break;
      }

      this.table.header = tableHeader;
    },
    // ++++++++++++++++++++++++++++++
    // HIDDEN COLUMN FOR OUR VERSION (dodać pobieranie menagera w router!)
    hiddenColumnOurVersion(tableHeader, columnName) {
      if (this.getLayoutWSD.wsd === true) {
        tableHeader.forEach((e) => {
          if (e.value == columnName) {
            e.class = 'text-start';
          }
        });
      } else if (this.getLayoutWSD.wsd === false) {
        tableHeader.forEach((e) => {
          if (e.value == columnName) {
            e.class = 'display-column';
          }
        });
      }
    },
    // ++++++++++++++++++++++++++++++
    // HIDDEN COLUMN FOR REGISTRY (dodać pobieranie menagera w router!)
    checkColumnPermission(tableHeader, columnName) {
      if (
        !this.getPossibleMenagersItems.some((e) => e._id == this.getUser.id) &&
        this.getUser.isSuperAdmin == false
      ) {
        tableHeader.forEach((e) => {
          if (e.value == columnName) {
            e.class = 'display-column';
          }
        });
        this.getRegistryHeader[columnName] = false;
      } else if (
        this.getPossibleMenagersItems.some((e) => e._id == this.getUser.id)
      ) {
        tableHeader.forEach((e) => {
          if (e.value == columnName) {
            e.class = 'text-right';
          }
        });
        this.getRegistryHeader[columnName] = true;
      }
    },

    // === === SEARCH INPUT FUNCTION === === //
    search: debounce(function() {
      this.table.page = 1;
      this.refreshData();
    }, 500),

    // === === CHECK WINDOW SIZE === ===
    checkSize() {
      return (this.windowSize = window.innerWidth);
    },
  },
  created() {
    // === === GET DATA BEFORE COMPONENT LOAD === === //
    this.setTitleAndHeaders();
    this.resetExtras();
    this.checkSize();
  },
};
</script>
