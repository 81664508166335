var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
            _vm.$route.name != 'resources_details' && _vm.getFolderNameValue !== ''
        )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.getPermission.level >= 2)?_c('v-btn',_vm._g({staticClass:"buttons--add ml-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.addFromResources()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-animation")])],1):_vm._e()]}}],null,false,4291509489)},[_c('span',[_vm._v("Dodaj z zasobów")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(_vm.getPermission.level >= 2)?_c('v-btn',_vm._g({staticClass:"buttons--add ml-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.addFromStorage()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-safe-square-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Dodaj ze schowka")])]),(
            _vm.$route.name != 'templates_details' && _vm.getFolderNameValue !== ''
        )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.getPermission.level >= 2)?_c('v-btn',_vm._g({staticClass:"buttons--add ml-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.addFromTemplate()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-table-box-multiple")])],1):_vm._e()]}}],null,false,3776914046)},[_c('span',[_vm._v("Dodaj z szablonu")])]):_vm._e(),(_vm.$route.name != 'templates_details')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(_vm.getPermission.level >= 2)?_c('v-btn',_vm._g({staticClass:"buttons--add ml-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){_vm.addAttachment('false'), _vm.clear()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-folder-plus")])],1):_vm._e()]}}],null,false,627662451)},[_c('span',[_vm._v("Dodaj nowy folder")])]):_vm._e(),(_vm.getPermission.level >= 2 && _vm.getFolderNameValue !== '')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add ml-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.addAttachment('true')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")])],1)]}}],null,false,4092511596)},[_c('span',[_vm._v("Dodaj nowy załącznik")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }