<template>
  <div class="AE__wrapper">
    <Separator :text="'Dane departamentu'" />
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          :label="getDepartmentFieldsNames.fullName"
          v-model="getDepartmentFields.fullName"
          :error-messages="getDepartmentErrors.fullName"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          :label="getDepartmentFieldsNames.shortName"
          v-model="getDepartmentFields.shortName"
          :error-messages="getDepartmentErrors.shortName"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          dense
          :items="getDetailsStatuses"
          v-model="getDepartmentFields.status"
          :label="getDepartmentFieldsNames.status"
          :error-messages="getDepartmentErrors.status"
          item-text="name"
          item-value="_id"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          dense
          v-model="getDepartmentFields.location"
          :label="getDepartmentFieldsNames.location"
          :error-messages="getDepartmentErrors.location"
          item-text="name"
          item-value="_id"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          dense
          :items="getWSDCategoryDepartments"
          v-model="getDepartmentFields.category"
          :label="getDepartmentFieldsNames.category"
          :error-messages="getDepartmentErrors.category"
          item-text="name"
          item-value="_id"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          dense
          :label="getDepartmentFieldsNames.comment"
          v-model="getDepartmentFields.comment"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <Separator :text="'Osoby odpowiedzialne'" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          :label="getDepartmentFieldsNames.superiorDepartment"
          single
          dense
          v-model="getDepartmentFields.superiorDepartment"
          :items="getDropdown.items.departments"
          item-value="_id"
          item-text="fullName"
          :loading="getDropdown.loading"
          :hide-no-data="getDropdown.hideNoData"
        >
          <template v-slot:selection="data">
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              close
              @click:close="removeSuperior(data.item._id)"
            >
              <v-avatar left color="secondary" class="mr-2">
                <span class="white--text caption">{{data.item.shortName}}</span>
              </v-avatar>
              {{ data.item.fullName }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-avatar color="secondary">
                <span class="white--text headline">{{data.item.shortName}}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{data.item.fullName}}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-autocomplete
          :label="getDepartmentFieldsNames.leader"
          single
          dense
          v-model="getDepartmentFields.leader"
          :items="leaderArray"
          item-value="_id"
          :filter="filter"
          no-data-text="Brak pracowników departamentu"
        >
          <template v-slot:selection="data">
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              close
              @click:close="removeLeader(data.item._id)"
            >
              <v-avatar left color="secondary" class="mr-2">
                <span
                  v-if="!data.item.picture"
                  class="white--text caption"
                >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
              {{ data.item.lastname }} {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-avatar color="secondary">
                <span
                  v-if="!data.item.picture"
                  class="white--text headline"
                >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{data.item.lastname}} {{data.item.name}}</v-list-item-title>
              <v-list-item-subtitle>{{data.item.job}}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-autocomplete
          :label="getDepartmentFieldsNames.users"
          multiple
          v-model="getDepartmentFields.users"
          :items="getDropdown.items.users"
          item-value="_id"
          :filter="filter"
          :loading="getDropdown.loading"
          :hide-no-data="getDropdown.hideNoData"
        >
          <template v-slot:selection="data">
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              close
              @click:close="removeWorker(data.item._id)"
            >
              <v-avatar left color="secondary" class="mr-2">
                <span
                  v-if="!data.item.picture"
                  class="white--text caption"
                >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
              {{ data.item.lastname }} {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-avatar color="secondary">
                <span
                  v-if="!data.item.picture"
                  class="white--text headline"
                >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{data.item.lastname}} {{data.item.name}}</v-list-item-title>
              <v-list-item-subtitle>{{data.item.job}}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import findIndex from "lodash.findindex";

export default {
  components: {
    Separator
  },
  data: () => ({
    leaderArray: []
  }),
  computed: {
    ...mapGetters([
      "getDepartmentErrors",
      "getDepartmentFields",
      "getDropdown",
      "getDepartmentFieldsNames",
      "getGlobalPaths",
      "getDetailsStatuses",
      "getWSDCategoryDepartments"
    ]),
    users() {
      return this.getDepartmentFields.users;
    }
  },
  watch: {
    users(newArray) {
      let arr = this.getDropdown.items.users;
      this.leaderArray = [];
      arr.filter(e => {
        if (newArray.includes(e._id)) {
          this.leaderArray.push(e);
        }
      });
    }
  },
  methods: {
    ...mapActions(["fetchItems"]),
    ...mapMutations(["removeSuperior", "removeLeader", "removeWorker"]),
    avatarNick(name, lastname) {
      if (name && lastname) {
        let nick = name[0] + lastname[0];
        return nick.toUpperCase();
      } else {
        return "";
      }
    },

    filter(item, queryText, itemText) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.lastname.toLowerCase().includes(queryText.toLowerCase())
      );
    }
  }
};
</script>