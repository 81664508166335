<template>
  <v-tooltip left v-if="getPermission.level > 1">
    <template v-slot:activator="{ on }">
      <v-btn fab dark v-on="on" small class="buttons--add" @click="createUser">
        <v-icon small>mdi-account-plus</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('users.tooltips.createUser') }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({}),
  methods: {
    ...mapMutations(["selectDefaultAdmin"]),
    ...mapActions(["openModal", "fetchJobDictionary", "fetchRoleDictionary"]),
    createUser() {
      this.fetchJobDictionary();
      this.fetchRoleDictionary();
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 600,
        type: "create",
        route: this.$route.name
      });
      this.selectDefaultAdmin();
    }
  },
  computed: {
    ...mapGetters(["getPermission"])
  }
};
</script>
