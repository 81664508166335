<template>
  <tbody>
    <!-- v-if='item.status.possibleStatus.name != "Draft" || getPermission.level >= 6' -->

    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="
        item.status &&
        item.status.possibleStatus &&
        item.status.possibleStatus.resolved
          ? 'color: #9e9e9e'
          : ''
      "
    >
      <!-- === === DATA === === -->
      <!-- <td
        style="font-size: 12px"
            >{{item.createdAt ? $moment(item.createdAt).format('YYYY-MM-DD HH:mm') : 'Brak'}}</td>-->

      <!-- === === NAZWA === === -->
      <td>{{ item.title ? item.title : 'Brak' }}</td>

      <!-- === === MIESIĄC === === -->

      <!-- <td style="font-size: 14px">{{item.month}}</td>-->

      <!-- === === STATUS === === -->

      <!-- <td>
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>-->

      <!-- === === INFORMACJE === === -->
      <!-- <td style="text-align: right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              v-on="on"
              dark
              @click="showFirstAttachments({
                registryID: item._id,
					      registryName: $route.name + '_details',
              })"
              class="mx-2"
              style="width: 25px; height: 25px"
              color="#19aa8d"
            >
              <v-icon size="12" color="white">mdi-file-search</v-icon>
            </v-btn>
          </template>
          <span>Podgląd</span>
        </v-tooltip>
            </td>-->

      <!-- === === AKCJA === === -->
      <td style="text-align: right">
        <v-btn
          icon
          small
          @click="$router.push({ path: `/coronavirus/${item._id}` })"
        >
          <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
export default {
  props: ['items'],
  data: () => ({}),
  computed: { ...mapGetters(['getPermission', 'getUser']) },
  methods: {
    ...mapActions(['showFirstAttachments']),
  },
};
</script>
