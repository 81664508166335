<template>
  <v-tooltip left v-if="getPermission.level > 1">
    <template v-slot:activator="{ on }">
      <v-btn fab dark v-on="on" small class="buttons--add" @click="updateTenders">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <span>Edytuj przetarg</span>
  </v-tooltip>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({}),
  methods: {
    ...mapActions(["openModal", "tendersDeatils", "fetchItems"]),

    updateTenders() {
      this.tendersDeatils({
        id: this.$route.params.id,
        type: "modal",
        route: this.$route.name
      });
      this.fetchItems("users");
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 900,
        type: "edit",
        route: "Tenders",
        tab: "tenders"
      });
    }
  },

  computed: {
    ...mapGetters(["getPermission"])
  }
};
</script>