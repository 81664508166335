var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"p-relative",attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"central__table",attrs:{"headers":_vm.getTasks.header,"items":_vm.getTable.items,"mobile-breakpoint":1100,"hide-default-footer":"","options":_vm.options,"items-per-page":11},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"small":""}},[_vm._v(_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.lastname))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',{staticStyle:{"font-size":"14px !important"},style:(item.isDone === true ? 'color: #9e9e9e' : '')},[_vm._v(_vm._s(item.name))]),(item.description)?_c('v-list-item-subtitle',{staticClass:"grey--text lighten-1"},[_vm._v(_vm._s(_vm.shortDescription(item.description, 100)))]):_c('v-list-item-subtitle',{staticClass:"grey--text lighten-1"},[_vm._v("Brak")])]}},{key:"item.termDate",fn:function(ref){
var item = ref.item;
return [(item.termDate)?_c('div',[(item.termTime)?_c('div',[_c('v-list-item-title',{staticStyle:{"font-size":"14px !important"},style:(item.isDone === true ? 'color: #9e9e9e' : '')},[_vm._v(_vm._s(_vm.dataCorrect(item.termDate)))]),_c('v-list-item-subtitle',{staticClass:"grey--text lighten-1"},[_vm._v(_vm._s(item.termTime))])],1):(!item.termTime)?_c('div',[_c('v-list-item-title',{style:(item.isDone === true ? 'color: #9e9e9e' : '')},[_vm._v(_vm._s(_vm.dataCorrect(item.termDate)))])],1):_c('span',{style:(item.isDone === true ? 'color: #9e9e9e' : '')},[_vm._v("Brak")])]):_c('div',{style:(item.isDone === true ? 'color: #9e9e9e' : '')},[_vm._v("Brak")])]}},{key:"item.priorityTasks",fn:function(ref){
var item = ref.item;
return [(item.priority)?_c('v-chip',{style:(("color: " + (item.priority.color))),attrs:{"color":item.priority.background,"label":"","small":""}},[_vm._v(_vm._s(item.priority.priorityName))]):_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v("Brak")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category)?_c('v-chip',{style:(("color: " + (item.category.color))),attrs:{"color":item.category.background,"label":"","small":""}},[_vm._v(_vm._s(item.category.name))]):_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v("Brak")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDetails(item._id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-thick")])],1)]}}])}),_c('div',{staticClass:"text-md-center pt-2"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.getTable.pages},on:{"input":_vm.fetchTableItems},model:{value:(_vm.table.page),callback:function ($$v) {_vm.$set(_vm.table, "page", $$v)},expression:"table.page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }