<template>
  <v-container fill-height>
    <v-row>
      <v-col cols="12" md="12" lg="8" class="p-relative py-0 d-flex flex-wrap">
        <v-col cols="12" md="12" lg="12" class="py-0 d-flex align-center">
          <!-- <v-avatar tile size="100" color="grey">
                        <img
                            width="550px"
                            src="https://www.pd.com.pl/wp-content/uploads/2018/06/Lexmark-XM1145.jpg"
                            alt
                        />
          </v-avatar>-->

          <v-col cols="12" md="6" lg="4">
            <v-text-field
              dense
              class="no-pointer"
              readonly
              v-model="getDevicesDetails.SN"
              label="Numer seryjny"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-barcode</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" lg="7" class="d-flex flex-wrap">
          <v-col cols="12" class="py-0">
            <Separator text="Urządzenie" />
          </v-col>
          <v-col cols="12" md="6" lg="3" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              readonly
              v-model="getDevicesDetails.modelDevices.model"
              label="Model urządzenia"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-printer</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              readonly
              v-model="getDevicesDetails.modelDevices.producer"
              label="Producent urządzenia"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-domain</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="5" class="py-0">
            <v-text-field
              dense
              v-model="getDevicesDetails.modelDevices.deviceType"
              class="font-devices no-pointer"
              readonly
              label="Rodzaj urządzenia"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-printer-check</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="12" lg="12" class="py-0">
            <v-text-field
              dense
              readonly
              v-model="getDevicesDetails.extrComments"
              class="font-devices no-pointer"
              label="Opis modelu"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-text-subject</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="12" class="d-flex flex-wrap pa-0">
            <v-col cols="12" md="6" lg="4" class="py-0">
              <v-text-field
                dense
                v-model="getDevicesDetails.serviceBase"
                readonly
                class="font-devices no-pointer"
                label="Podstawa serwisu"
              >
                <template slot="prepend">
                  <v-icon color="#b3b3b3" size="18">mdi-cogs</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="py-0">
              <v-text-field
                dense
                readonly
                :value="
                  getDevicesDetails.priority
                    ? getDevicesDetails.priority
                    : 'Brak'
                "
                class="font-devices no-pointer"
                label="Priorytet"
              >
                <template slot="prepend">
                  <v-icon color="#b3b3b3" size="18">mdi-clock-fast</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-col>
          <!-- NOT YET -->
          <!-- <v-col cols="12" md="6" lg="4" class="py-0">
                        <v-text-field
                            dense
                            readonly
                            class="font-devices no-pointer"
                            v-model="getDevicesDetails.servicePackage"
                            label="Pakiet usług"
                        >
                            <template slot="prepend">
                                <v-icon color="#b3b3b3" size="18">mdi-clock-fast</v-icon>
                            </template>
                        </v-text-field>
          </v-col>-->

          <v-col cols="12" md="6" lg="4" class="py-0">
            <v-text-field
              dense
              readonly
              :value="
                getDevicesDetails.datePurchase
                  ? correctData(getDevicesDetails.datePurchase)
                  : 'Brak'
              "
              class="font-devices no-pointer"
              label="Data zakupu"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="py-0">
            <v-text-field
              dense
              readonly
              :value="
                getDevicesDetails.warrantyFrom
                  ? correctData(getDevicesDetails.warrantyFrom)
                  : 'Brak'
              "
              class="font-devices no-pointer"
              label="Gwarancja OD"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="py-0">
            <v-text-field
              dense
              readonly
              :value="
                getDevicesDetails.warrantyTo
                  ? correctData(getDevicesDetails.warrantyTo)
                  : 'Brak'
              "
              class="font-devices no-pointer"
              label="Gwarancja DO"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="py-0">
            <v-text-field
              dense
              readonly
              :value="
                getDevicesDetails.warrantyToParts
                  ? correctData(getDevicesDetails.warrantyToParts)
                  : 'Brak'
              "
              class="font-devices no-pointer"
              label="Gwarancja części"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" lg="5" class="d-flex flex-wrap">
          <v-col cols="12" md="12" lg="12" class="pb-0 py-0">
            <Separator text="Klient" />
          </v-col>
          <v-col cols="12" md="12" lg="12" class="py-0">
            <v-text-field
              dense
              :value="
                getDevicesDetails.client && getDevicesDetails.client.name
                  ? getDevicesDetails.client.name
                  : ''
              "
              readonly
              class="font-devices no-pointer"
              label="Nazwa klienta"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-account</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="12" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              readonly
              label="Miejscowość / ulica / nr"
              :value="`${
                getDevicesDetails.address
                  ? getDevicesDetails.address.name + ':'
                  : ''
              } ${
                getDevicesDetails.address
                  ? getDevicesDetails.address.city
                  : 'BRAK TO POLE JEST WYMAGANE'
              }, ${
                getDevicesDetails.address
                  ? getDevicesDetails.address.address
                  : ''
              } `"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-home-city-outline</v-icon>
              </template>
              <template slot="append">
                <v-dialog v-model="dialog" width="800">
                  <template v-slot:activator="{}">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            icon
                            small
                            @click="dialog = true"
                            v-on="on"
                            :disabled="!getDevicesDetails.address.location"
                          >
                            <v-icon color="red" small>mdi-map-marker</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{
                        getDevicesDetails.address &&
                        getDevicesDetails.address.location
                          ? "Lokalizacja"
                          : "Brak danych GPS"
                      }}</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <GmapMap
                      ref="mymap"
                      :center="
                        getDevicesDetails.address &&
                        getDevicesDetails.address.location
                          ? getDevicesDetails.address.location
                          : ''
                      "
                      :zoom="16"
                      map-type-id="terrain"
                      style="width: 800px; height: 500px"
                    >
                      <GmapMarker
                        ref="myMarker"
                        :position="
                          getDevicesDetails.address &&
                          getDevicesDetails.address.location
                            ? getDevicesDetails.address.location
                            : ''
                        "
                      />
                    </GmapMap>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false"
                        >Zamknij</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="12" lg="12" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              readonly
              label="Piętro / pokój / inne"
              :value="
                getDevicesDetails.floor ? getDevicesDetails.floor : 'Brak'
              "
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-home-floor-1</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              :value="
                getDevicesDetails.contactPerson
                  ? `${getDevicesDetails.contactPerson.name} ${getDevicesDetails.contactPerson.lastname} • ${getDevicesDetails.contactPerson.phoneNumber} • ${getDevicesDetails.contactPerson.email}`
                  : 'Brak'
              "
              readonly
              label="Osoba kontaktowa  "
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-phone</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" lg="7" class="d-flex flex-wrap">
          <v-col cols="12" md="12" lg="12" class="pb-0 py-0">
            <Separator text="INNE" />
            <!-- <span>Lokalizacja urządzenia:</span> -->
          </v-col>

          <v-col cols="12" md="6" lg="6" class="py-0">
            <v-text-field
              dense
              readonly
              class="font-devices no-pointer"
              label="Dostawca"
              v-model="delivery"
            >
              <!-- v-model="getDevicesDetails.delivery.name" -->
              {{ delivery }}
              <template slot="prepend">
                <v-icon color="#b3b3b3" readonly size="18">mdi-truck</v-icon>
              </template>
              <template slot="append"></template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              v-model="getDevicesDetails.asset"
              readonly
              label="Środek trwały"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18">mdi-fridge</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              v-model="getDevicesDetails.owner"
              readonly
              label="Właściciel sprzętu"
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" size="18"
                  >mdi-account-arrow-left</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-col>

        <v-col
          cols="12"
          md="12"
          lg="5"
          class="d-flex flex-wrap align-start"
          style="align-content: baseline"
        >
          <v-col cols="12" md="12" lg="12" class="py-0">
            <Separator text="Opcje" />
            <!-- <span>Lokalizacja urządzenia:</span> -->
          </v-col>
          <v-col cols="12" md="12" lg="12" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              label="Opcje"
              v-model="getDevicesDetails.options"
              readonly
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" readonly size="18">mdi-settings</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="12" lg="12" class="py-0">
            <v-text-field
              dense
              class="font-devices no-pointer"
              label="Aktualna umowa OSP"
              v-model="getDevicesDetails.OSP"
              readonly
            >
              <template slot="prepend">
                <v-icon color="#b3b3b3" readonly size="18"
                  >mdi-file-outline</v-icon
                >
              </template>
            </v-text-field>
          </v-col>
        </v-col>

        <v-col cols="12" class="py-1 mt-1">
          <v-textarea
            height="50"
            class="correct-devices font-devices no-pointer"
            readonly
            no-resize
            v-model="getDevicesDetails.comment"
            label="Uwagi"
          >
            <template slot="prepend">
              <v-icon color="#f57c7c" size="18">mdi-alert-octagon</v-icon>
            </template>
          </v-textarea>
        </v-col>

        <v-divider
          class="p-absolute"
          style="top: 0; right: -12px"
          vertical
        ></v-divider>
      </v-col>

      <v-col cols="12" md="12" lg="4" class="py-0">
        <div
          class="mx-auto py-5 px-5 p-relative"
          elevation="0"
          max-width="600"
          min-height="520px"
        >
          <Separator text="Więcej o urządzeniu" />
          <!-- <p class>Więcej o urządzeniu:</p> -->
          <div class="d-flex">
            <div style="margin-top: 50px">
              <div class="d-flex">
                <span class="devices__information__left">Pakiet usług</span>
                <span class="devices__information__right">{{
                  getDevicesDetails.modelDevices.price.priceName
                }}</span>
              </div>

              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left"
                  >Ustalono ceny dedykowane</span
                >
                <span class="devices__information__right">
                  <v-checkbox
                    class="pa-0 ma-0 devices__information__right__checkboxs"
                    color="red darken-3"
                    readonly
                    v-model="getDevicesDetails.dedicatedPrice.checkbox"
                    hide-details
                  ></v-checkbox>
                </span>
              </div>

              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left">Ceny w pakiecie</span>
                <span class="devices__information__right">standardowa</span>
                <span
                  v-if="getDevicesDetails.dedicatedPrice.checkbox"
                  class="devices__information__right"
                  >dedykowana</span
                >
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left">Koszt przeglądu</span>
                <span class="devices__information__right"
                  >{{
                    getDevicesDetails.modelDevices.price.survey.price
                  }}
                  PLN</span
                >
                <span
                  v-if="getDevicesDetails.dedicatedPrice.checkbox"
                  class="devices__information__right"
                  >{{ getDevicesDetails.dedicatedPrice.survey }} PLN</span
                >
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left">Koszt naprawy</span>
                <span class="devices__information__right"
                  >{{
                    getDevicesDetails.modelDevices.price.repair.price
                  }}
                  PLN</span
                >
                <span
                  v-if="getDevicesDetails.dedicatedPrice.checkbox"
                  class="devices__information__right"
                  >{{ getDevicesDetails.dedicatedPrice.repair }} PLN</span
                >
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left">Koszt wdrożenia</span>
                <span class="devices__information__right"
                  >{{
                    getDevicesDetails.modelDevices.price.installation.price
                  }}
                  PLN</span
                >
                <span
                  v-if="getDevicesDetails.dedicatedPrice.checkbox"
                  class="devices__information__right"
                  >{{ getDevicesDetails.dedicatedPrice.installation }} PLN</span
                >
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left"
                  >Koszt diagnostyki</span
                >
                <span class="devices__information__right"
                  >{{
                    getDevicesDetails.modelDevices.price.diagnostics.price
                  }}
                  PLN</span
                >
                <span
                  v-if="getDevicesDetails.dedicatedPrice.checkbox"
                  class="devices__information__right"
                  >{{ getDevicesDetails.dedicatedPrice.diagnostics }} PLN</span
                >
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left">Usługa IT</span>
                <span class="devices__information__right"
                  >{{
                    getDevicesDetails.modelDevices.price.itService.price
                  }}
                  PLN</span
                >
                <span
                  v-if="getDevicesDetails.dedicatedPrice.checkbox"
                  class="devices__information__right"
                  >{{ getDevicesDetails.dedicatedPrice.itService }} PLN</span
                >
              </div>
              <!-- <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Aktualna umowa OSP</span>
                                <span
                                    class="devices__information__right"
                                >Lorem ipsum dolor sit amet consectetur.</span>
              </div>-->

              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left"
                  >Data pierwszej instalacji</span
                >
                <span class="devices__information__right">b/d</span>
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left">Poprzedni Klient</span>
                <span class="devices__information__right">b/d</span>
              </div>
              <div class="d-flex" style="margin-top: -2px">
                <span class="devices__information__left"
                  >Poprzednia lokalizacja</span
                >
                <span class="devices__information__right">b/d</span>
              </div>
              <!-- <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Opcje</span>
                                <span class="devices__information__right">Lorem ipsum</span>
              </div>-->
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
 <script>
import Separator from "@/components/Global/Separator.vue";
// import ButtonsAE from "@/components/Registry/Devices/Information/ButtonsAE.vue";

import { mapGetters } from "vuex";
export default {
  data: () => ({
    testowe: ["testowa opcja"],
    dialog: false,
  }),
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    ...mapGetters(["getDevicesDetails"]),
    delivery() {
      let delivery = "";
      this.getDevicesDetails.delivery
        ? (delivery = this.getDevicesDetails.delivery.name)
        : (delivery = "Brak");
      return delivery;
    },
  },
  components: {
    Separator,
  },
};
</script>