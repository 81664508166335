<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="createPrinters">
                <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj nową drukarkę</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["openModal", 
        //"fetchModels", 'fetchSeries'
        ]),
        createPrinters() {

            //this.fetchModels();
            //this.fetchSeries()
            this.openModal({
                headLine: "global.create",
                headTitle: "Dodaj nową drukarkę",
                open: true,
                width: 600,
                type: "create",
                route: "parts",
                tab: "printers"
            }); 
        }
    }
};
</script>
