<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="pt-0">
        <Separator :text="'Dane użytkownika'" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          label="Imię"
          dense
          :error-messages="getUsersErrors.name"
          v-model="getUsersData.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          dense
          label="Nazwisko"
          v-model="getUsersData.lastname"
          :error-messages="getUsersErrors.lastname"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          nudge-top="180"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="getUsersData.birthday"
              label="Data urodzenia"
              readonly
              v-on="on"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="getUsersData.birthday"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
            locale="pl-PL"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          dense
          label="ID"
          :error-messages="getUsersErrors.userIndex"
          v-model="getUsersData.userIndex"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="py-1">
        <v-select
          dense
          label="Stanowisko"
          :items="getUserJobsList"
          v-model="getUsersData.job"
          item-value="jobName"
          item-text="jobName"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="py-1">
        <v-select
          dense
          label="Rola"
          :items="getUserRoleList"
          v-model="getUsersData.roleName"
          item-value="roleName"
          item-text="roleName"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          dense
          label="E-mail firmowy"
          :error-messages="getUsersErrors.mail"
          v-model="getUsersData.mail"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field dense label="E-mail prywatny" v-model="getUsersData.privateMail"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field dense label="Telefon komórkowy" v-model="getUsersData.phone"></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field dense label="Telefon stacjonarny" v-model="getUsersData.privatePhone"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          label="Uwagi"
          v-if="getModal.type === 'edit'"
          v-model="getUsersData.comment"
          auto-grow
          rows="1"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="py-1 pt-5 mt-5">
        <Separator :text="`Dane konta` " />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-1">
        <v-text-field
          dense
          label="Login"
          :class="getModal.type === 'edit' ?'no-pointer' : ''"
          :readonly="getModal.type === 'edit'"
          :error-messages="getUsersErrors.login"
          v-model="getUsersData.login"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" class="py-1">
        <v-select
          dense
          label="Admin"
          :items="selectItems"
          item-text="text"
          item-value="value"
          v-model="getUsersData.isAdmin"
          v-if="getUser.isAdmin || getUser.isSuperAdmin"
        ></v-select>
      </v-col>
      <v-col cols="12" md="5" class="py-1">
        <v-text-field
          dense
          label="Hasło"
          v-model="getUsersData.password"
          :append-icon="showP ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showP ? 'text' : 'password'"
          @click:append="showP = !showP"
          :error-messages="getUsersErrors.password"
          v-if="getUser.isAdmin || getUser.isSuperAdmin"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="5" class="py-1">
        <v-text-field
          dense
          label="Powtórz hasło"
          v-model="getUsersData.passwordAgain"
          :append-icon="showPA ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPA ? 'text' : 'password'"
          @click:append="showPA = !showPA"
          :error-messages="getUsersErrors.password"
          v-if="getUser.isAdmin || getUser.isSuperAdmin"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    menu: false,
    showPA: false,
    showP: false,
    selectItems: [
      { text: "Tak", value: true },
      { text: "Nie", value: false }
    ]
  }),
  components: {
    Separator
  },
  computed: {
    ...mapGetters([
      "getUsersData",
      "getUsersErrors",
      "getModal",
      "getUserJobsList",
      "getUserRoleList",
      "getUser"
    ])
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  methods: {
    save(birthday) {
      this.$refs.menu.save(birthday);
    }
  }
  // beforeMount() {
  //     this.getUsersData.isAdmin = false;
  // }
};
</script>
