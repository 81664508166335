<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-autocomplete
          label="Dla kogo?"
          dense
          v-model="getNotificationData.to[ACPeople]"
          item-value="_id"
          :items="getDropdown.items[ACPeople]"
          multiple
          :filter="filter"
          :class="getModal.type === 'details' ? 'no-pointer' : ''"
          :error-messages="getNotificationError.to"
          :messages="
            `${getNotificationData.to.users.length} użytkowników, ${getNotificationData.to.departments.length} departamentów`
          "
          :item-disabled="
            getModal.type === 'details' ? [] : getDropdown.items[ACPeople]
          "
        >
          <template slot="append-outer" v-if="getModal.type !== 'details'">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon @click="setAllPeople" v-on="on"
                  >mdi-account-group</v-icon
                >
              </template>
              <span>Wybierz wszystkich</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="data">
            <!-- === === CHIPS === === -->

            <!-- === USERS === -->
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              :close="getModal.type !== 'details'"
              v-if="ACPeople === 'users'"
              @click:close="
                removeNACPeople({
                  id: data.item._id,
                  section: ACPeople,
                })
              "
            >
              <v-avatar left color="secondary" class="mr-2">
                <span class="white--text caption" v-if="!data.item.picture">
                  {{ avatarNick(data.item.name, data.item.lastname) }}
                </span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
              {{ data.item.lastname }} {{ data.item.name }}
            </v-chip>
            <!-- === DEPARTMENTS === -->
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              :close="getModal.type !== 'details'"
              @click:close="
                removeNACPeople({
                  id: data.item._id,
                  section: ACPeople,
                })
              "
              v-else
            >
              <v-avatar left color="secondary" class="mr-2">
                <span class="white--text caption">
                  {{ data.item.shortName.toUpperCase() }}
                </span>
              </v-avatar>
              {{ data.item.fullName }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <!-- === === AVATARS === ===  -->
            <!-- === USERS ===  -->
            <v-list-item-avatar v-if="ACPeople === 'users'">
              <v-avatar size="35" color="secondary">
                <span
                  v-if="!data.item.picture"
                  class="white--text central__avatarFont"
                >
                  {{ avatarNick(data.item.name, data.item.lastname) }}
                </span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
            </v-list-item-avatar>
            <!-- === DEPARTMENTS === -->
            <v-list-item-avatar v-else>
              <v-avatar size="35" color="#445D75">
                <span class="white--text central__avatarFont">
                  {{ data.item.shortName.toUpperCase() }}
                </span>
              </v-avatar>
            </v-list-item-avatar>

            <!-- === === CONTENT === === -->
            <!-- === USERS ===  -->
            <v-list-item-content
              v-if="ACPeople === 'users'"
              :disabled="getModal.type !== 'details'"
            >
              <v-list-item-title>
                {{ data.item.lastname }} {{ data.item.name }}
                <v-chip
                  v-if="data.item.disabled"
                  label
                  x-small
                  color="grey darken-3"
                  class="ml-4"
                >
                  <span class="white--text">Zajęte</span>
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ data.item.job }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- === DEPARTMENTS === -->
            <v-list-item-content v-else>
              <v-list-item-title>
                {{ data.item.fullName }}
                <v-chip
                  v-if="data.item.disabled"
                  label
                  x-small
                  color="grey darken-3"
                  class="ml-4"
                >
                  <span class="white--text">Zajęte</span>
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-tabs
            slot="prepend-item"
            centered
            style="position: sticky; top: 0; z-index: 2; background: white"
            class="elevation-1"
          >
            <v-tab @click="ACPeople = 'users'">Pracownicy</v-tab>
            <v-tab @click="ACPeople = 'departments'">Departamenty</v-tab>
          </v-tabs>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          v-model="datePicker"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Termin alertu"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
              v-model="getNotificationData.dueDate"
              :error-messages="getNotificationError.dueDate"
              :class="getModal.type === 'details' ? 'no-pointer' : ''"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            @input="datePicker = false"
            :min="new Date().toISOString().substr(0, 10)"
            v-model="getNotificationData.dueDate"
            :readonly="getModal.type === 'details'"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-menu
          ref="timer"
          v-model="timePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          :return-value.sync="getNotificationData.dueTime"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getNotificationData.dueTime"
              label="Czas alertu"
              prepend-icon="mdi-clock-outline"
              readonly
              v-on="on"
              :disabled="!getNotificationData.dueDate"
              :class="getModal.type === 'details' ? 'no-pointer' : ''"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timePicker"
            v-model="getNotificationData.dueTime"
            full-width
            format="24hr"
            :readonly="getModal.type === 'details'"
            @click:minute="$refs.timer.save(getNotificationData.dueTime)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          label="Priorytet"
          :items="getTaskDictionary.priority"
          v-model="getNotificationData.priority"
          return-object
          item-value="priorityName"
          :readonly="getModal.type === 'details'"
          :class="getModal.type === 'details' ? 'no-pointer' : ''"
        >
          <template slot="append-outer" v-if="getModal.type !== 'details'">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon @click="clearNPriority" v-on="on"
                  >mdi-window-close</v-icon
                >
              </template>
              <span>Wyczyść</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="data">
            <v-chip
              label
              small
              :color="data.item.background"
              :style="`color: ${data.item.color}`"
              >{{ data.item.priorityName }}</v-chip
            >
          </template>
          <template v-slot:item="data">
            <v-chip
              label
              small
              :color="data.item.background"
              :style="`color: ${data.item.color}`"
              >{{ data.item.priorityName }}</v-chip
            >
            <span class="ml-3">{{ data.item.priorityName }}</span>
          </template>
        </v-select>
      </v-col>
      <!-- <v-col cols="6" class="d-flex align-center py-0">
                <v-checkbox
                    label="Powiadom w terminie"
                    v-model="getNotificationData.alertThisDate"
                    hide-details
                    class="shrink mr-2 mt-0"
                ></v-checkbox>
            </v-col>
            <v-col cols="6" class="d-flex align-center py-0">
                <v-checkbox
                    v-model="getNotificationData.cyclicalNotificationCheckbox"
                    hide-details
                    @change="checkboxNotification()"
                    class="shrink mr-2 mt-0"
                ></v-checkbox>
                <v-select
                    :disabled="!getNotificationData.cyclicalNotificationCheckbox"
                    v-model="getNotificationData.cyclicalNotification"
                    :items="itemsNotyfication"
                    label="Powiadamiaj cyklicznie"
                ></v-select>
            </v-col>
            <v-col cols="6" class="d-flex align-center py-0">
                <v-checkbox
                    v-model="getNotificationData.alertDateCheckbox"
                    hide-details
                    @change="checkbox()"
                    class="shrink mr-2 mt-0"
                ></v-checkbox>
                <v-select
                    :disabled="!getNotificationData.alertDateCheckbox"
                    v-model="getNotificationData.alertDate"
                    :items="itemsDate"
                    label="Powiadom przed terminem"
                ></v-select>
            </v-col>-->

      <v-col cols="12" class="pt-0">
        <v-textarea
          label="Treść"
          rows="1"
          auto-grow
          v-model="getNotificationData.description"
          :error-messages="getNotificationError.description"
          :class="getModal.type === 'details' ? 'no-pointer' : ''"
          :readonly="getModal.type === 'details'"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data: () => ({
    ACPeople: 'users',
    timePicker: false,
    datePicker: false,
    itemsDate: [
      '15 minut',
      '30 minut',
      '1 godzinę',
      '2 godziny',
      '3 godziny',
      '1 dzień wcześniej',
    ],
    itemsNotyfication: ['codziennie', 'co tydzień', 'co miesiąc', 'co rok'],
  }),

  methods: {
    ...mapActions(['fetchItems', 'fetchTCategory', 'fetchTPriority']),
    ...mapMutations([
      'removeNACPeople',
      'setAllUsers',
      'clearTDCategory',
      'clearNPriority',
      'setAllNUsers',
    ]),

    checkbox() {
      this.getNotificationData.alertDateCheckbox == true
        ? this.getNotificationData.alertDate
        : (this.getNotificationData.alertDate = '');
    },
    checkboxNotification() {
      this.getNotificationData.cyclicalNotificationCheckbox == true
        ? this.getNotificationData.cyclicalNotification
        : (this.getNotificationData.cyclicalNotification = '');
    },
    avatarNick(name, lastname) {
      let nick = name[0] + lastname[0];
      return nick.toUpperCase();
    },
    setAllPeople() {
      this.ACPeople = 'users';
      this.setAllNUsers(this.getDropdown.items.users);
    },
    // sprawdzić po podpięciu backendu
    filter(item, queryText, itemText) {
      if (item.lastname)
        return (
          item.name.toLowerCase().includes(queryText.toLowerCase()) ||
          item.lastname.toLowerCase().includes(queryText.toLowerCase())
        );
      else if (item.fullName) {
        return item.fullName.toLowerCase().includes(queryText.toLowerCase());
      } else return item.name.toLowerCase().includes(queryText.toLowerCase());
    },
  },
  computed: {
    ...mapGetters([
      'getDropdown',
      'getTaskDictionary',
      'getTaskErrors',
      'getModal',
      'getNotificationData',
      'getNotificationError',
      'getModal',
      'getGlobalPaths',
    ]),
  },
  mounted() {
    this.fetchItems('users');
    this.fetchItems('departments');
    this.fetchTCategory();
    this.fetchTPriority();
  },
};
</script>
