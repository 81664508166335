<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn
                fab
                dark
                v-on="on"
                small
                class="buttons--add"
                @click="updateImplementationOffers"
            >
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj wdrożenie</span>
    </v-tooltip>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions([
            "openModal",
            "offersDeatils",
            "fetchItems",
            "getOffersImplementation",
        ]),

        updateImplementationOffers() {
            this.getOffersImplementation({
                id: this.$route.params.id,
                type: "modal",
            });
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 600,
                type: "create",
                route: "Offers",
                global: "offersImplementation",
                tab: "offersImplementation",
            });
        },
    },

    computed: {
        ...mapGetters(["getPermission"]),
    },
};
</script>