import Vue from 'vue';
import findIndex from 'lodash.findindex';
const state = {
	contract: {
		open: false,
		contractName: '',
	},
	type: '',
	items: [],
	errors: {
		contractName: '',
	},
};

const getters = {
	getUContract: state => state.contract,
	getUContractType: state => state.type,
	getUContractErrors: state => state.errors,
	getUContractItems: state => state.items,
};

const actions = {
	createUContract({ state, commit }) {
		state.errors.contractName = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/users/contract',
			data: {
				...state.contract,
			},
		})
			.then(response => {
				commit('closeUContract');
				commit('setUContractItem', response.data.item);
				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.error === 'Exists')
					state.errors.contractName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.contractName.kind ===
					'required'
				) {
					state.errors.contractName = 'Pole jest wymagane';
				}
			});
	},
	fetchUContract({ state, commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/users/contract',
		})
			.then(response => {
				commit('setUContractItems', response.data.items);
			})
			.catch(error => {
				console.log(error);
			});
	},
	deleteUContract({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/users/contract/delete/${payload}`,
		})
			.then(response => {
				commit('deleteUCItem', payload);
				// Vue.swal('Usunięto!', 'Pomyślnie usunięto wpis', 'success');
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(error => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},
	UContractDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/users/contract/${payload}`,
		})
			.then(response => {
				
				commit('setUContractDetails', response.data.item);
			})
			.catch(error => console.log(error));
	},
	UContractUpdate({ commit }) {
		state.errors.contractName = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/users/contract/update/${state.contract._id}`,
			data: {
				...state.contract,
			},
		})
			.then(response => {
				commit('closeUContract');
				
				commit('updateUCItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.error === 'Exists')
					state.errors.contractName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.contractName.kind ===
					'required'
				) {
					state.errors.contractName = 'Pole jest wymagane';
				}
			});
	},
};

const mutations = {
	closeUContract(state) {
		state.contract.open = false;
		state.contract.contractName = '';
	},
	setUContractType(state, payload) {
		state.type = payload;
	},
	clearUContract(state) {
		state.contract.contractName = '';
		state.errors.contractName = '';
	},
	setUContractItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	setUContractItems(state, payload) {
		state.items = payload;
	},
	deleteUCItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setUContractDetails(state, payload) {
		state.contract.contractName = payload.contractName;
		state.contract._id = payload._id;
		state.contract.open = true;
	},
	updateUCItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
