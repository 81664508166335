<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="createGroup">
                <v-icon small>mdi-account-multiple-plus</v-icon>
            </v-btn>
        </template>
        <span>{{$t('groups.tooltips.createGroup')}}</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["openModal", "fetchItems"]),
        createGroup() {
            this.fetchItems("users");
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 700,
                type: "create",
                route: this.$route.name,
            });
        },
    },
};
</script>

