<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            readonly
            v-model="getAgreementDetails.nrAgreement"
            dense
            :label="getAgreementFieldsNames.nrAgreement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-checkbox
            v-model="getAgreementDetails.annexAgreement"
            dense
            label="Czy umowa jest aneksem?"
            color="#19aa8d"
            class="mt-2 check__servis"
            disabled
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6" md="3" style="background-color: rgb(246, 247, 230);">
          <v-text-field
            readonly
            :append:size="12"
            :value="getAgreementDetails.databeginningAgreement ? correctData(getAgreementDetails.databeginningAgreement) : 'Brak'"
            dense
            :label="getAgreementFieldsNames.databeginningAgreement"
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" x-small icon @click="newAlert()">
                    <v-icon size="15">mdi-cog</v-icon>
                  </v-btn>
                </template>
                Dodaj alert
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="9">
          <v-text-field
            readonly
            v-model="getAgreementDetails.titleAgreement"
            dense
            :label="getAgreementFieldsNames.titleAgreement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" style="background-color: rgb(246, 247, 230);">
          <v-text-field
            readonly
            :value="getAgreementDetails.dataendAgreement ? correctData(getAgreementDetails.dataendAgreement) : 'Brak'"
            dense
            :label="getAgreementFieldsNames.dataendAgreement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            readonly
            :value="getAgreementDetails.contractorAgreement ? getAgreementDetails.contractorAgreement.name : 'Brak'"
            dense
            :label="getAgreementFieldsNames.contractorAgreement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            readonly
            :value="getAgreementDetails.responsibleAgreement ? showcorrectResponsible(getAgreementDetails.responsibleAgreement.name, getAgreementDetails.responsibleAgreement.lastname, getAgreementDetails.responsibleAgreement.phoneNumber, getAgreementDetails.responsibleAgreement.email) : 'Brak'"
            dense
            :label="getAgreementFieldsNames.responsibleAgreement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            readonly
            v-model="getAgreementDetails.typeAgreement"
            dense
            :label="getAgreementFieldsNames.typeAgreement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="12">
          <v-textarea
            :label="getAgreementFieldsNames.commentAgreement"
            v-model="getAgreementDetails.commentAgreement"
            readonly
            rows="2"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Separator
  },
  methods: {
    ...mapActions(["openModal"]),

    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    newAlert() {
      this.openModal({
        headLine: "global.create",
        open: true,
        type: "create",
        width: 600,
        route: "notifications",
        tab: "notification",
        global: "notifications"
      });
    },
    showcorrectResponsible(name, lastname, phoneNumber, email) {
      let Responsible;
      if (name != undefined && name != "") {
        Responsible = name;
      }
      if (lastname != undefined && lastname != "") {
        Responsible = Responsible + " " + lastname;
      }
      if (email != undefined && email != "") {
        Responsible = Responsible + " • " + email;
      }
      if (phoneNumber != undefined && phoneNumber != "") {
        Responsible = Responsible + " • " + phoneNumber;
      }
      return `${Responsible}`;
    },
    newAlert() {
      this.openModal({
        headLine: "global.create",
        open: true,
        type: "create",
        width: 600,
        route: "notifications",
        tab: "notification",
        global: "notifications"
      });
    }
  },
  computed: {
    ...mapGetters(["getAgreementDetails", "getAgreementFieldsNames"])
  }
};
</script>