<template>
	<tbody>
		<tr
			v-for="(item, index) in items"
			:key="index"
			:style="
				item.status &&
				item.status.possibleStatus &&
				item.status.possibleStatus.resolved
					? 'color: #9e9e9e'
					: ''
			"
		>
			<td>{{ item.index }}</td>
			<td>{{ $moment(item.createdAt).format('YYYY-MM-DD') }}</td>
			<td>{{ item.name }}</td>
			<td>{{ item.investor ? item.investor.name : '' }}</td>
			<td v-if="getRegistryHeader.status">
				<v-chip
					:hidden="!item.status.possibleStatus"
					:color="
						item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.color &&
						item.status.possibleStatus.color.background
							? item.status.possibleStatus.color.background
							: '#555555'
					"
					:style="
						`color: ${
							item.status &&
							item.status.possibleStatus &&
							item.status.possibleStatus.color &&
							item.status.possibleStatus.color.font
								? item.status.possibleStatus.color.font
								: '#FFFFFF'
						}`
					"
					dark
					label
					small
					>{{
						item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.name
							? item.status.possibleStatus.name
							: 'Brak'
					}}</v-chip
				>
			</td>
			<td v-if="getRegistryHeader.action" style="text-align: right">
				<v-btn
					icon
					small
					@click="$router.push({ path: `/constructions/${item._id}` })"
				>
					<v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
				</v-btn>
			</td>
		</tr>
	</tbody>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
export default {
	props: ['items', 'headers'],
	data: () => ({}),
	computed: {
		...mapGetters(['getPermission', 'getUser', 'getRegistryHeader']),
	},
	methods: {
		...mapActions(['showFirstAttachments']),
	},
};
</script>
