<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="editInformation()">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj informacje</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission", "getWarehouseDetails"]),
    },
    methods: {
        ...mapActions(["openModal", "warehouseDetails", "fetchItems"]),
        editInformation() {
            // this.fetchItems("contacts");
            // this.fetchItems("cars");
            // this.fetchItems("constructions");
            // this.fetchItems("materials");

            this.warehouseDetails({
                id: this.$route.params.id,
                type: "modal",
                route: this.$route.name,
            });

            this.openModal({
                headLine: "global.edit",
                open: true,
                type: "edit",
                width: 600,
                route: "warehouse",
                tab: "warehouse",
            });
        },
    },
};
</script>