import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';
const state = {
	cars: {
		title: 'Samochody',
		header: [
			{
				text: 'Marka i model',
				value: 'model',
				width: 300,
				class: 'central__table__header',
			},
			{
				text: 'Numer rejestracyjny pojazdu',
				value: 'plate',
				width: 250,
				class: 'central__table__header',
			},
			{
				text: 'Użytkownik',
				value: 'driver',
				width: 250,
				class: 'central__table__header',
			},
			{
				text: 'Data następnego przeglądu',
				value: 'survey',
				width: 220,
				class: 'central__table__header',
			},
			{
				text: 'Ubezpieczenie do',
				width: 150,
				value: 'inSuranceExpiration',
			},
			{
				text: 'Rok produkcji',
				width: 150,
				value: 'year',
			},
			{
				text: 'Status',
				width: 150,
				value: 'status',
			},
			{
				text: 'Akcja',
				align: 'right',
				width: 50,
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			classes: '',
			menager: {
				name: '',
				lastname: '',
				mail: '',
				phone: '',
				picture: '',
			},
			status: '',
			index: '',
			plate: '',
			model: '',
			rejdat: '',
			type: '',
			evidence: '',
			survey: '',
			vin: '',
			year: '',
			tires: '',
			warranty: '',
			guarantor: '',
			property: '',
			driver: {
				name: '',
				lastname: '',
			},
			description: '',
			inSuranceExpiration: '',
		},
		details: {
			classes: '',
			menager: {
				name: '',
				lastname: '',
				mail: '',
				phone: '',
				picture: '',
			},
			status: '',
			plate: '',
			model: '',
			rejdat: '',
			type: '',
			evidence: '',
			survey: '',
			vin: '',
			year: '',
			tires: '',
			warranty: '',
			guarantor: '',
			property: '',
			driver: {
				name: '',
				lastname: '',
			},
			description: '',
			weight: '',
		},
		errors: {
			plate: '',
			model: '',
			rejdat: '',
			evidence: '',
			survey: '',
			vin: '',
			year: '',
			warranty: '',
			guarantor: '',
			property: '',
		},
	},
	fieldsNames: {
		classes: 'Rodzaj pojazdu',
		menager: 'Menadżer',
		status: 'Status',
		plate: 'Nr rejestracyjny',
		model: 'Model pojazdu',
		rejdat: 'Data pierwszej rej.',
		type: 'Rodzaj pojazdu',
		evidence: 'Numer dowodu rejestracyjnego',
		survey: 'Data następnego przeglądu',
		vin: 'VIN',
		year: 'Rok produkcji',
		tires: 'Rozmiar opon',
		warranty: 'Gwarancja do',
		guarantor: 'Gwarant',
		property: 'Własność',
		driver: 'Kierowca',
		description: 'Opis',
		inSuranceExpiration: 'Ubezpieczenie DO',
		weight: 'Waga pojazdu z ostatniego ważenia [Tona]',
	},
	carsDeliveries: [],
};

const getters = {
	getCars: (state) => state.cars,
	getCarsFields: (state) => state.modal.fields,
	getCarsDetails: (state) => state.modal.details,
	getCarsErrors: (state) => state.modal.errors,
	getCarsFieldsNames: (state) => state.fieldsNames,
	getCarsDeliveries: (state) => state.carsDeliveries,
};

const actions = {
	createCar({ commit, dispatch }, payload) {
		commit('resetErrors', 'cars');
		Vue.axios({
			method: 'POST',
			url: '/cars',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: payload.page,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'cars',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano samochód',
				});
			})

			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'cars',
					err: error.response.data.error,
				});
			});
	},

	carsDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/cars/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'cars') {
					commit('setCars', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);

					if (payload.next) {
						payload.next();
					}
				} else {
					commit('updateCars', response.data.item);
					commit('setClientItems', [response.data.item.guarantor]);
				}
			})
			.catch((error) => {
				if (error.response.status == '404') router.push('/NotFound');
			});
	},

	carsEdit({ state, commit }, payload) {
		commit('resetErrors', 'cars');
		Vue.axios({
			method: 'PUT',
			url: `/cars/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setCars', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano samochód',
				});
			})
			.catch((error) => {
				console.log(error);

				commit('setErrors', {
					route: 'cars',
					err: error.response.data.error,
				});
			});
	},

	async carsDeliveries({ commit, dispatch }, payload) {
		await Vue.axios({ method: 'GET', url: `/warehouse/car/${payload.id}` })
			.then((response) => {
				commit('setCarsDeliveries', response.data.item);
			})
			.catch((error) => {
				console.error(error);
			});
	},
};

const mutations = {
	updateCars(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.survey = moment(payload.survey).format('YYYY-MM-DD');
		state.modal.fields.rejdat = moment(payload.rejdat).format('YYYY-MM-DD');
		state.modal.fields.year = moment(payload.year).format('YYYY-MM-DD');
		if (payload.inSuranceExpiration) {
			state.modal.fields.inSuranceExpiration = moment(
				payload.inSuranceExpiration,
			).format('YYYY-MM-DD');
		}
	},
	setCars(state, payload) {
		state.modal.details = payload;
		state.modal.details.survey = moment(payload.survey).format('YYYY-MM-DD');
		state.modal.details.rejdat = moment(payload.rejdat).format('YYYY-MM-DD');
		state.modal.details.year = moment(payload.year).format('YYYY-MM-DD');
		if (payload.inSuranceExpiration) {
			state.modal.details.inSuranceExpiration = moment(
				payload.inSuranceExpiration,
			).format('YYYY-MM-DD');
		}
	},
	setCarsDeliveries(state, payload) {
		state.carsDeliveries = payload;
	},
};

export default {
	state,
	actions,
	getters,
	mutations,
};
