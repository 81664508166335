<template>
    <v-col cols="12">
        <v-textarea
            :error-messages="getNoteErrors.note"
            label="Nowa notatka:"
            auto-grow
            rows="6"
            v-model="getNoteFields.note"
        ></v-textarea>
    </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["getNoteFields", "getNoteErrors"])
    }
};
</script>


