<template>
    <p
        :class="getLayoutWSD.wsd === true ?  'navigation-WSD__calendar' : 'navigation__calendar'"
    >{{text + weekday +", " + day + "/" + month + "/" + year}}</p>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data: () => ({
        today: "",
        text: "",
        day: "",
        month: "",
        year: "",
        weekday: "",
        dy: "",
        number: ""
    }),
    methods: {
        getData() {
            this.today = new Date();
            this.text = "Dzisiaj jest ";
            this.day = this.today.getDate();
            this.month = this.today.getMonth() + 1;
            this.year = this.today.getFullYear();
            this.weekday = new Date().toLocaleString("pl-PL", {
                weekday: "long"
            });

            if (this.month < 10) {
                this.month = "0" + this.month;
            }

            if (this.day < 10) {
                this.day = "0" + this.day;
            }
        }
    },
    beforeMount() {
        this.getData();
    },
    computed: {
        ...mapGetters(["getLayoutWSD"])
    }
};
</script>