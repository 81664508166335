import Vue from 'vue';
import i18n from '@/i18n.js';

const state = {
	session_logs: {
		title: 'Logowania użytkowników',
		header: [
			{
				text: 'Imię i nazwisko pracownika',
				value: 'userId',
				class: 'central__table__header',
			},
			{
				text: 'Data logowania',
				value: 'login',
				class: 'central__table__header',
			},
			{
				text: 'Data wylogowania',
				value: 'logout',
				class: 'central__table__header',
			},
			{
				text: 'Czas sesji',
				value: 'sessionTime',
				class: 'central__table__header',
			},
		],
		modal: {
			headers: [
				{
					text: 'Imię i nazwisko pracownika',
					value: 'name',
					class: 'central__table__header',
				},
				{
					text: 'Próby logowań',
					value: 'loginTries',
					class: 'central__table__header',
				},
				{
					text: 'Zablokowany',
					value: 'isBanned',
					class: 'central__table__header',
				},
				{
					text: 'Data ostatniej próby logowania',
					value: 'lastLoginTry',
					class: 'central__table__header',
				},
				{
					text: 'Akcje',
					value: 'actions',
					sortable: false,
					class: 'central__table__header',
				},
				{
					value: 'unknownLogin',
				},
			],
			table: {},
		},
	},
	logs: [],
};

const getters = {
	getSessionLogs: (state) => state.session_logs,
	getThreeMonthLogs: (state) => state.logs,
	getSessionLogsModal: (state) => state.session_logs.modal,
	getSessionLogsModalTable: (state) => state.session_logs.modal.table,
};

const actions = {
	fetchSessionLogs({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/global/session-logs`,
		})
			.then((response) => {
				commit('setLogs', response.data.item);

				if (payload.next) {
					payload.next();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	},

	async fetchUsersInfo({ commit }, payload) {
		await Vue.axios({
			method: 'GET',
			url: `/global/find/usersLogInfo`,
			params: {
				page: payload.page,
				search: payload.search,
				sortBy: payload.sortBy[0],
				sortDesc: payload.sortDesc[0],
				pageSize: payload.pageSize,
				// registryName: payload.registryName || '',
				// registryID: payload.registryID || '',
			},
		})
			.then((response) => {
				response.data.items.forEach((element) => {
					element.name = element.name + ' ' + element.lastname;
				});
				state.session_logs.modal.table = response.data;
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	},
	async blockUser({ commit }, payload) {
		await Vue.axios({
			method: 'PUT',
			url: `/users/block/${payload.id}`,
		}).then((response) => {
			state.session_logs.modal.table.items.find((element) => {
				return element._id == response.data._id;
			}).isBanned = response.data.isBanned;
		});
	},
	async unblockUser({ commit }, payload) {
		await Vue.axios({
			method: 'PUT',
			url: `/users/unblock/${payload.id}`,
		}).then((response) => {
			let user = state.session_logs.modal.table.items.find((element) => {
				return element._id == response.data._id;
			});
			user.isBanned = response.data.isBanned;
			user.loginTries = response.data.loginTries;
		});
	},
	async resetTries({ commit }, payload) {
		await Vue.axios({
			method: 'PUT',
			url: `/users/resetTries/${payload.id}`,
		}).then((response) => {
			state.session_logs.modal.table.items.find((element) => {
				return element._id == response.data._id;
			}).loginTries = response.data.loginTries;
		});
	},
};

const mutations = {
	setLogs(state, payload) {
		state.logs = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
