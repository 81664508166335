var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-5 p-relative",attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"pb-0 pt-1",attrs:{"headers":_vm.getArchive.header,"items":_vm.getArchive.items,"height":"615","mobile-breakpoint":1100,"hide-default-footer":"","items-per-page":11,"disable-sort":""},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.client ? item.client.name : 'Brak'))]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$moment(item.createdAt).format('YYYY-MM-DD HH:mm')))]}},{key:"item.technic",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.showCorrectTechnic(item.implementation)))]}},{key:"item.nextIntervention",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.showCorrectDescInt(item.implementation)))]}},{key:"item.doneJob",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.showCorrectDescDone(item.implementation)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"router":"","to":("/service/" + (item._id)),"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-thick")])],1)]}}])}),_c('div',{staticClass:"text-md-center pt-2"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.getArchive.pages},on:{"input":_vm.refreshData},model:{value:(_vm.table.page),callback:function ($$v) {_vm.$set(_vm.table, "page", $$v)},expression:"table.page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }