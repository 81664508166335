<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="4" class="pt-0">
                <v-text-field
                    label="Nazwa zadania"
                    dense
                    v-model="getTaskData.name"
                    :class="getModal.type === 'details' ? 'no-pointer' : ''"
                    :readonly="getModal.type === 'details'"
                    :error-messages="getTaskErrors.name"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="8" class="pt-0">
                <v-textarea
                    v-model="getTaskData.description"
                    :class="getModal.type === 'details' ? 'no-pointer' : ''"
                    :readonly="getModal.type === 'details'"
                    dense
                    class="textarea"
                    label="Opis zadania"
                    auto-grow
                    rows="1"
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
                <Separator :text="'Informacje'" />

                <div v-if="getModal.type !== 'details'">
                    <v-select
                        v-if="$route.name != 'tasks'"
                        v-model="getTaskData.attachments"
                        :items="attachments"
                        label="Dodaj plik z załączników do zadania"
                        item-text="realFileName"
                        item-value="_id"
                        multiple
                        prepend-icon="mdi-paperclip"
                    ></v-select>
                    <v-file-input
                        color="#445D75"
                        counter
                        multiple
                        placeholder="Dodaj załącznik z komputera"
                        prepend-icon="mdi-paperclip"
                        :show-size="1000"
                        v-model="getTaskData.files"
                        v-if="getModal.type == 'create'"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*"
                    >
                        <template v-slot:selection="{ index, text }">
                            <v-chip v-if="index < 2" color="#445D75" dark label small>{{ text }}</v-chip>

                            <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                            >
                                +{{
                                getTaskData.files.length - 2
                                }}
                            </span>
                        </template>
                    </v-file-input>
                </div>

                <div
                    v-else-if="getTaskData.attachments && getTaskData.attachments.length > 0 || getTaskData.files.length > 0"
                >
                    <span>Załączniki:</span>
                    <span v-for="attachment in getTaskData.attachments" :key="attachment._id">
                        <br />
                        <span v-if="attachment.link">
                            <a
                                :href="attachment.link"
                                target="_BLANK"
                                class="font-weight-regular body-2"
                            >{{ attachment.realFileName }}</a>
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        @click="copyToStorage(attachment._id, getTaskData._id, getTaskData.registryName, getTaskData.registryID)"
                                        v-on="on"
                                        small
                                    >mdi-arrow-top-right-thick</v-icon>
                                </template>
                                <span>Dodaj do schowka</span>
                            </v-tooltip>
                        </span>
                        <span v-else>
                            <a
                                :href="
								`${getGlobalPaths.attachments}${attachment.fakeFileName}`
							"
                                class="font-weight-regular body-2"
                                target="_BLANK"
                            >{{attachment.realFileName}}</a>
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        @click="copyToStorage(attachment._id, getTaskData._id, getTaskData.registryName, getTaskData.registryID)"
                                        v-on="on"
                                        small
                                    >mdi-arrow-top-right-thick</v-icon>
                                </template>
                                <span>Dodaj do schowka</span>
                            </v-tooltip>
                        </span>
                    </span>
                    <span v-for="file in getTaskData.files" :key="file._id">
                        <br />
                        <a
                            :href="
								`${getGlobalPaths.attachments}${file.fakeFileName}`
							"
                            class="font-weight-regular body-2"
                            target="_BLANK"
                        >{{file.realFileName}}</a>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    @click="copyToStorage(file._id, getTaskData._id, getTaskData.registryName, getTaskData.registryID)"
                                    v-on="on"
                                    small
                                >mdi-arrow-top-right-thick</v-icon>
                            </template>
                            <span>Dodaj do schowka</span>
                        </v-tooltip>
                    </span>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="px-0 d-flex flex-wrap">
                <v-col cols="12" md="6">
                    <v-menu
                        v-model="datepicker"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                label="Data"
                                v-model="getTaskData.termDate"
                                readonly
                                :class="
                                    getModal.type === 'details'
                                        ? 'no-pointer'
                                        : ''
                                "
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            v-model="getTaskData.termDate"
                            :min="new Date().toISOString().substr(0, 10)"
                            @input="datepicker = false"
                            :readonly="getModal.type === 'details'"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                    <v-menu
                        ref="menu"
                        v-model="timepicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="getTaskData.termTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                v-model="getTaskData.termTime"
                                :disabled="!getTaskData.termDate"
                                :class="
                                    getModal.type === 'details'
                                        ? 'no-pointer'
                                        : ''
                                "
                                label="Czas "
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-if="timepicker"
                            v-model="getTaskData.termTime"
                            format="24hr"
                            :readonly="getModal.type === 'details'"
                            @click:minute="
                                $refs.menu.save(getTaskData.termTime)
                            "
                        ></v-time-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        label="Kategoria zadania"
                        v-model="getTaskData.category"
                        :items="getTaskDictionary.category"
                        return-object
                        dense
                        item-value="name"
                        :readonly="getModal.type === 'details'"
                        :class="getModal.type === 'details' ? 'no-pointer' : ''"
                    >
                        <template slot="append-outer" v-if="getModal.type !== 'details'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="clearTDCategory" v-on="on">mdi-window-close</v-icon>
                                </template>
                                <span>Wyczyść</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:selection="data">
                            <v-chip
                                label
                                small
                                :color="data.item.background"
                                :style="`color: ${data.item.color}`"
                            >{{ data.item.name }}</v-chip>
                        </template>
                        <template v-slot:item="data">
                            <v-chip
                                label
                                small
                                :color="data.item.background"
                                :style="`color: ${data.item.color}`"
                            >{{ data.item.name }}</v-chip>
                            <span class="ml-3">{{ data.item.name }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        dense
                        label="Priorytet"
                        v-model="getTaskData.priority"
                        :items="getTaskDictionary.priority"
                        :class="getModal.type === 'details' ? 'no-pointer' : ''"
                        return-object
                        item-value="priorityName"
                        :readonly="getModal.type === 'details'"
                    >
                        <template slot="append-outer" v-if="getModal.type !== 'details'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="clearTDPriority" v-on="on">mdi-window-close</v-icon>
                                </template>
                                <span>Wyczyść</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:selection="data">
                            <v-chip
                                label
                                small
                                :color="data.item.background"
                                :style="`color: ${data.item.color}`"
                            >{{ data.item.priorityName }}</v-chip>
                        </template>
                        <template v-slot:item="data">
                            <v-chip
                                label
                                small
                                :color="data.item.background"
                                :style="`color: ${data.item.color}`"
                            >{{ data.item.priorityName }}</v-chip>
                            <span class="ml-3">
                                {{
                                data.item.priorityName
                                }}
                            </span>
                        </template>
                    </v-select>
                </v-col>
            </v-col>

            <v-col cols="12" md="6">
                <v-textarea
                    class="textarea"
                    label="Komentarz"
                    v-model="getTaskData.comment"
                    v-if="getModal.type === 'details'"
                    auto-grow
                    rows="1"
                ></v-textarea>
            </v-col>

            <v-col cols="12" md="12">
                <Separator :text="'Przypisz zadanie'" />
            </v-col>

            <v-col cols="12" md="12">
                <v-autocomplete
                    dense
                    label="Osoby"
                    v-model="getTaskData.people[ACPeople]"
                    item-value="_id"
                    :items="getDropdown.items[ACPeople]"
                    multiple
                    :filter="filter"
                    :class="getModal.type === 'details' ? 'no-pointer' : ''"
                    :messages="
                        `${getTaskData.people.users.length} użytkowników, ${getTaskData.people.departments.length} departamentów`
                    "
                    :item-disabled="
                        getModal.type === 'details'
                            ? []
                            : getDropdown.items[ACPeople]
                    "
                >
                    <template slot="prepend" v-if="getModal.type !== 'details'">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon @click="setOnlyMe" v-on="on">mdi-account</v-icon>
                            </template>
                            <span>Dla mnie</span>
                        </v-tooltip>
                    </template>

                    <template slot="append-outer" v-if="getModal.type !== 'details'">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon @click="setAllPeople" v-on="on">mdi-account-group</v-icon>
                            </template>
                            <span>Wybierz wszystkich</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:selection="data">
                        <!-- === === CHIPS === === -->

                        <!-- === USERS === -->
                        <v-chip
                            pill
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            @click="data.select"
                            :close="getModal.type !== 'details'"
                            v-if="ACPeople === 'users'"
                            @click:close="
                                removePerson({
                                    id: data.item._id,
                                    section: ACPeople,
                                })
                            "
                        >
                            <v-avatar left color="secondary" class="mr-2">
                                <span class="white--text caption" v-if="!data.item.picture">
                                    {{
                                    avatarNick(
                                    data.item.name,
                                    data.item.lastname
                                    )
                                    }}
                                </span>
                                <img
                                    :src="
                                        `${getGlobalPaths.profilePic}${data.item.picture}`
                                    "
                                    v-else
                                    style="object-fit: cover"
                                />
                            </v-avatar>
                            {{ data.item.lastname }} {{ data.item.name }}
                        </v-chip>
                        <!-- === DEPARTMENTS === -->
                        <v-chip
                            pill
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            @click="data.select"
                            :close="getModal.type !== 'details'"
                            @click:close="
                                removePerson({
                                    id: data.item._id,
                                    section: ACPeople,
                                })
                            "
                            v-else
                        >
                            <v-avatar left color="secondary" class="mr-2">
                                <span class="white--text caption">
                                    {{
                                    data.item.shortName.toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                            {{ data.item.fullName }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <!-- === === AVATARS === ===  -->
                        <!-- === USERS ===  -->
                        <v-list-item-avatar v-if="ACPeople === 'users'">
                            <v-avatar size="35" color="secondary">
                                <span
                                    v-if="!data.item.picture"
                                    class="white--text central__avatarFont"
                                >
                                    {{
                                    avatarNick(
                                    data.item.name,
                                    data.item.lastname
                                    )
                                    }}
                                </span>
                                <img
                                    :src="
                                        `${getGlobalPaths.profilePic}${data.item.picture}`
                                    "
                                    v-else
                                    style="object-fit: cover"
                                />
                            </v-avatar>
                        </v-list-item-avatar>
                        <!-- === DEPARTMENTS === -->
                        <v-list-item-avatar v-else>
                            <v-avatar size="35" color="#445D75">
                                <span class="white--text central__avatarFont">
                                    {{
                                    data.item.shortName.toUpperCase()
                                    }}
                                </span>
                            </v-avatar>
                        </v-list-item-avatar>

                        <!-- === === CONTENT === === -->
                        <!-- === USERS ===  -->
                        <v-list-item-content
                            v-if="ACPeople === 'users'"
                            :disabled="getModal.type !== 'details'"
                        >
                            <v-list-item-title>
                                {{ data.item.lastname }} {{ data.item.name }}
                                <v-chip
                                    v-if="data.item.disabled"
                                    label
                                    x-small
                                    color="grey darken-3"
                                    class="ml-4"
                                >
                                    <span class="white--text">Zajęte</span>
                                </v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{
                                data.item.job
                                }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- === DEPARTMENTS === -->
                        <v-list-item-content v-else>
                            <v-list-item-title>
                                {{ data.item.fullName }}
                                <v-chip
                                    v-if="data.item.disabled"
                                    label
                                    x-small
                                    color="grey darken-3"
                                    class="ml-4"
                                >
                                    <span class="white--text">Zajęte</span>
                                </v-chip>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-tabs
                        slot="prepend-item"
                        centered
                        style="position: sticky; top: 0; z-index: 2; background: white"
                        class="elevation-1"
                    >
                        <v-tab @click="ACPeople = 'users'">Pracownicy</v-tab>
                        <v-tab @click="ACPeople = 'departments'">Departamenty</v-tab>
                    </v-tabs>
                </v-autocomplete>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import Separator from "@/components/Global/Separator.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    data: () => ({
        datepicker: false,
        timepicker: false,
        time: null,
        ACPeople: "users",
        items: [
            {
                text: "Ważne",
                background: "red",
                color: "black",
            },
            {
                text: "Bardzo ważne",
                background: "pink",
                color: "yellow",
            },
        ],
        attachments: [],
        attachment: "",
        selectFile: "",
    }),
    components: {
        Separator,
    },
    methods: {
        ...mapActions([
            "fetchItems",
            "fetchTCategory",
            "fetchTPriority",
            "getAttachmentsData",
            "openModal",
            "copyAttachmentToStorage",
        ]),
        ...mapMutations([
            "removePerson",
            "setAllUsers",
            "clearTDCategory",
            "clearTDPriority",
            "setOnlyOneUser",
        ]),
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                );
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
            } else
                return item.name
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
        },
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0];
            return nick.toUpperCase();
        },
        setAllPeople() {
            this.ACPeople = "users";
            this.setAllUsers(this.getDropdown.items.users);
        },
        setOnlyMe() {
            this.setOnlyOneUser(this.getUser.id);
        },
        createAttachment() {
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "create",
                width: 600,
                route: "attachments",
                tab: "attachment",
                global: "attachments",
            });
        },
        copyToStorage(selectFile, taskID, registryName, registryID) {
            this.copyAttachmentToStorage({
                attachmentID: selectFile,
                registryName: "users",
                registryID: this.getUser.id,
                fromRegistryID: registryID ? registryID : this.$route.params.id,
                fromRegistryName: registryName
                    ? registryName
                    : this.$route.name,
                taskID: taskID,
            });
            this.selectFile = "";
        },
    },
    computed: {
        ...mapGetters([
            "getTaskData",
            "getDropdown",
            "getTaskDictionary",
            "getTaskErrors",
            "getModal",
            "getUser",
            "getGlobalPaths",
            "getAttachmentsFields",
            "getModal",
            "getAttachmentsError",
        ]),
    },
    mounted() {
        Vue.axios({
            methods: "GET",
            url: `/attachments/findAll/${this.$route.name}/${this.$route.params.id}`,
        }).then((response) => {
            this.attachments = response.data.attachments;
        });

        this.fetchItems("users");
        this.fetchItems("departments");

        this.fetchTCategory();
        this.fetchTPriority();
    },
};
</script>

