<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="editOrder()">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj wpis</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"]),
    },
    methods: {
        ...mapActions(["openModal", "directorOrdersDeatils", "fetchItems"]),
        editOrder() {
            this.directorOrdersDeatils({
                id: this.$route.params.id,
                type: "modal",
                route: this.$route.name,
            });
            this.fetchItems("users");
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "edit",
                width: 600,
                route: "directorOrders",
                tab: "director-orders",
            });
        },
    },
};
</script>