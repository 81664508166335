<template>
    <v-container class="grey lighten-5">
        <v-row no-gutters class>
            <v-col cols="12 my-3">
                <div class="reminders">
                    <div class="headline font-weight-light reminders__title">ALERTY</div>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn class="reminders__title__arrow" v-on="on" icon :to="tasksRoute">
                                <v-icon color="black">mdi-arrow-right-thick</v-icon>
                            </v-btn>
                        </template>
                        <span>Przejdź do wszystkich</span>
                    </v-tooltip>-->
                </div>
            </v-col>
            <v-col cols="12" class="reminders__card">
                <!-- <v-alert
                    :icon="notification.icon"
                    v-for="notification in notifications"
                    :key="notification.id"
                    :type="notification.type"
                    class
                    text
                    prominent
                    border="left"
                >
                    {{shortDescription(notification.text, 53)}}
                    <v-btn
                        :to="tasksRoute"
                        style="width: 100%; height: 100%; top: 0; left: 0; opacity:0; position: absolute;"
                    ></v-btn>
                </v-alert>-->
                <v-alert
                    border="left"
                    style="margin-right: 10px"
                    colored-border
                    :color="notification.priority ? notification.priority.background : 'grey lighten-1'"
                    v-for="notification in getRBNotifications"
                    :key="notification._id"
                    elevation="2"
                    class="task pa-5"
                >
                    <div class="tasks__content" style="padding: 10px 0;">
                        <p class="ma-0">{{shortDescription(notification.description, 120)}}</p>
                    </div>

                    <v-menu :close-on-content-click="false" nudge-left="280" max-width="450">
                        <template v-slot:activator="{ on }">
                            <div class="tasks__from">
                                <span v-on="on" style=" margin-top: 10px">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <span
                                                v-on="on"
                                                style="font-size: 11px !important; margin-top: 10px"
                                            >
                                                <v-icon size="11">mdi-account</v-icon>
                                                {{notification.createdBy.name}} {{notification.createdBy.lastname}}
                                            </span>
                                        </template>
                                        <span>Autor</span>
                                    </v-tooltip>
                                </span>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" v-if="notification.dueDate">
                                            <v-icon size="11">mdi-calendar-clock</v-icon>
                                            {{$moment(notification.dueDate).format('YYYY-MM-DD')}} {{notification.dueTime}}
                                        </span>
                                    </template>
                                    <span>Termin</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <v-card
                            class="pa-4"
                            style="display: flex;flex-direction: column; justify-content: flex-start; color: #757575; font-size: 14px"
                        >
                            <div style="display: flex">
                                <v-avatar style="cursor: pointer;" v-on="on" color="teal" size="40">
                                    <span
                                        class="white--text headline"
                                    >{{notification.createdBy.name.charAt(0)}}</span>
                                </v-avatar>
                                <div
                                    style="display: flex; flex-direction: column; margin-left: 15px"
                                >
                                    <span
                                        style="font-size: 15px; color: black"
                                    >{{notification.createdBy.name}} {{notification.createdBy.lastname}}</span>
                                    <span>{{notification.createdBy.job}}</span>
                                </div>
                            </div>
                            <div style="display: flex; flex-direction: column; ">
                                <v-divider class="my-3"></v-divider>
                                <p class="ma-0">
                                    <b>Email:</b>
                                    <span
                                        v-html="mailTo(notification.createdBy.mail)"
                                        v-if="notification.createdBy.mail"
                                    ></span>
                                    <span v-else>Brak</span>
                                </p>
                                <p class="ma-0">
                                    <b>Kontakt:</b>
                                    <span
                                        v-if="notification.createdBy.phone"
                                    >{{notification.createdBy.phone}}</span>
                                    <span v-else>Brak</span>
                                </p>
                            </div>
                        </v-card>
                    </v-menu>

                    <div class="tasks__buttons">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    fab
                                    v-on="on"
                                    dark
                                    style="width: 25px; height: 25px"
                                    :to="tasksRoute"
                                    color="#2f445e"
                                >
                                    <v-icon size="15" color="white">mdi-arrow-right-thick</v-icon>
                                </v-btn>
                            </template>
                            <span>Przejdź do szczegółów</span>
                        </v-tooltip>
                    </div>
                </v-alert>
                <v-alert
                    border="left"
                    style="margin-right: 10px"
                    colored-border
                    color="grey lighten-1"
                    elevation="2"
                    class="task pa-5"
                    v-if="getRBNotifications.length == 0"
                >
                    <div
                        style="padding: 0; font-size: 18px; color: #595959"
                        class="tasks__title"
                    >Brak alertów</div>
                    <span
                        style="height: 1px; width: 100%; background-color: lightgrey; display: block; margin-bottom: 5px;"
                    ></span>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import truncate from "lodash.truncate";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            tasksRoute: "/alerts",
            on: "",
            notifications: [
                {
                    id: "1",
                    text: "Masz jeszcze tydzień na wykonanie zadania",
                    icon: "mdi-calendar",
                    type: "success"
                },
                {
                    id: "2",
                    text: "Termin zadania upłynał!",
                    icon: "mdi-alert-circle",
                    type: "error"
                },
                {
                    id: "3",
                    text: "Zadanie na dzisiaj!",
                    icon: "mdi-bell-alert",
                    type: "warning"
                },
                {
                    id: "4",
                    text: "Zadanie bez określonego terminu ",
                    icon: "mdi-book-information-variant",
                    type: "info"
                }
            ]
        };
    },

    methods: {
        shortDescription(description, length) {
            return truncate(description, { length });
        },
        mailTo(mail) {
            return mail
                ? `<a href="mailto: ${mail}"> ${mail}</a>`
                : "E-mail: Brak";
        }
    },
    computed: {
        ...mapGetters(["getRBNotifications"])
    }
};
</script>


