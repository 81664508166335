<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="8" class="p-relative py-0">
                <v-row>
                    <!-- {{getDevicesList}} -->
                    <v-col cols="3" class="py-0 pt-5">
                        <!-- {{getModalDevices.SN}} -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{}">
                                <v-combobox
                                    v-on:keyup="debounceInput"
                                    dense
                                    validate-on-blur
                                    hide-selected
                                    v-model="getModalDevices.SN"
                                    :items="getDevicesList"
                                    hint="Zacznij pisać..."
                                    item-value="_id"
                                    item-text="SN"
                                    label="Wprowadź S/N"
                                    :error-messages="getContactsError.SN"
                                    @change="changeRouter"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-barcode</v-icon>
                                    </template>
                                </v-combobox>
                                <!-- <v-autocomplete dense class="font-devices">
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-barcode</v-icon>
                                    </template>
                                    <template slot="append">
                                        <v-icon color="red" size="18">mdi-check-bold</v-icon>
                                    </template>
                                </v-autocomplete>-->
                                <!-- {{getModalDevices}} -->
                            </template>
                            <span>Wprowadź minimum 4 znaki</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <div class="d-flex">
                    <v-col cols="7" class="pl-0" style="padding-right: 35px">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <Separator text="Urządzenie" />
                                <!-- <span>Lokalizacja urządzenia:</span> -->
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-autocomplete
                                    dense
                                    v-model="getModalDevices.modelDevices.model"
                                    :items="getDevicesListModel"
                                    :error-messages="
                                        getContactsError.modelDevices
                                    "
                                    hide-no-data
                                    hint="Zacznij pisać..."
                                    return-object
                                    label="Model urządzenia"
                                    @keyup="debounceModel($event)"
                                    item-text="model"
                                    @input="autocomplete(getModalDevices.modelDevices.model)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" class="py-0">
                                <v-text-field
                                    dense
                                    class="font-devices no-pointer"
                                    readonly
                                    v-model="
                                        getModalDevices.modelDevices.producer
                                    "
                                    label="Producent urządzenia"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-domain</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-text-field
                                    dense
                                    class="font-devices no-pointer"
                                    v-model="
                                        getModalDevices.modelDevices.deviceType
                                    "
                                    value="Urządzenie wielofunkcyjne"
                                    readonly
                                    label="Rodzaj urządzenia"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-printer-check</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    dense
                                    class="font-devices mt-3"
                                    label="Opis dodatkowy"
                                    v-model="getModalDevices.extrComments"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-text-subject</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="py-0">
                                <!-- <v-text-field
                                    dense
                                    class="font-devices"
                                    label="Podstawa serwisu"
                                    v-model="getModalDevices.serviceBase"
                                    :error-messages="getContactsError.serviceBase"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-cogs</v-icon>
                                    </template>
                                    <template slot="append">
                                        <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                                    </template>
                                </v-text-field>-->

                                <v-autocomplete
                                    dense
                                    class="font-devices"
                                    label="Podstawa serwisu"
                                    :items="getServiceBase"
                                    v-model="getModalDevices.serviceBase"
                                    item-value="serviceCoreName"
                                    item-text="serviceCoreName"
                                    :error-messages="
                                        getContactsError.serviceBase
                                    "
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-cogs</v-icon>
                                    </template>
                                    <template slot="append">
                                        <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-select
                                    dense
                                    class="font-devices"
                                    label="Priorytet"
                                    v-model="getModalDevices.priority"
                                    :items="getTaskDictionary.priority"
                                    item-value="priorityName"
                                >
                                    <template v-slot:selection="data">
                                        <v-chip
                                            label
                                            small
                                            :color="data.item.background"
                                            :style="`color: ${data.item.color}`"
                                        >
                                            {{
                                            data.item.priorityName
                                            }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="data">
                                        <v-chip
                                            label
                                            small
                                            :color="data.item.background"
                                            :style="`color: ${data.item.color}`"
                                        >
                                            {{
                                            data.item.priorityName
                                            }}
                                        </v-chip>
                                        <span class="ml-3">
                                            {{
                                            data.item.priorityName
                                            }}
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="py-0">
                                <v-menu
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            class="font-devices"
                                            v-model="
                                                getModalDevices.datePurchase
                                            "
                                            v-on="on"
                                            label="Data zakupu"
                                            readonly
                                            clearable
                                        >
                                            <template slot="prepend">
                                                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>

                                    <v-date-picker
                                        first-day-of-week="1"
                                        v-model="getModalDevices.datePurchase"
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-menu
                                    v-model="modal2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            class="font-devices"
                                            v-model="
                                                getModalDevices.warrantyFrom
                                            "
                                            v-on="on"
                                            label="Gwarancja OD"
                                            clearable
                                            readonly
                                        >
                                            <template slot="prepend">
                                                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        first-day-of-week="1"
                                        v-model="getModalDevices.warrantyFrom"
                                        @input="modal2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-menu
                                    v-model="modal3"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            class="font-devices"
                                            v-model="getModalDevices.warrantyTo"
                                            v-on="on"
                                            readonly
                                            label="Gwarancja DO"
                                            clearable
                                        >
                                            <template slot="prepend">
                                                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        first-day-of-week="1"
                                        :min="getModalDevices.warrantyFrom"
                                        v-model="getModalDevices.warrantyTo"
                                        @input="modal3 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-menu
                                    v-model="modal4"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            class="font-devices"
                                            v-model="
                                                getModalDevices.warrantyToParts
                                            "
                                            v-on="on"
                                            readonly
                                            label="Gwarancja części"
                                            clearable
                                        >
                                            <template slot="prepend">
                                                <v-icon color="#b3b3b3" size="18">mdi-calendar-today</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        first-day-of-week="1"
                                        v-model="
                                            getModalDevices.warrantyToParts
                                        "
                                        @input="modal4 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="5" class="pr-0" style="padding-left: 35px">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <Separator text="Klient" />
                                <!-- <span>Lokalizacja urządzenia:</span> -->
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                    v-model="getModalDevices.client"
                                    :error-messages="getContactsError.client"
                                    dense
                                    class="font-devices"
                                    label="Nazwa klienta"
                                    :hide-no-data="true"
                                    hint="Zacznij pisać..."
                                    @keyup="findContacts($event)"
                                    :items="getClientItems"
                                    item-text="name"
                                    return-object
                                    @input="setClientThings($event)"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-account</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <v-combobox
                                    dense
                                    v-model="getModalDevices.address"
                                    :error-messages="getContactsError.address"
                                    class="font-devices mt-3"
                                    label="Miejscowość / ulica / nr"
                                    item-text="name"
                                    item-value="_id"
                                    :items="getClientBranches"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-home-city-outline</v-icon>
                                    </template>
                                    <template v-slot:selection="data">
                                        <span>
                                            {{ data.item.name }}:
                                            {{ data.item.city }},
                                            {{ data.item.address }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <span>
                                            {{ data.item.name }}
                                            {{ data.item.city }},
                                            {{ data.item.address }}
                                        </span>
                                    </template>

                                    <template slot="append">
                                        <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                                    </template>
                                </v-combobox>
                            </v-col>
                            <!-- <v-col cols="3" class="py-0 pointer">
                                <v-text-field
                                    v-model="getModalDevices.GPS"
                                    dense
                                    class="font-devices mt-3"
                                    disabled
                                    label="Dane GPS"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-map-marker</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>-->
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <v-textarea
                                    v-model="getModalDevices.floor"
                                    dense
                                    rows="2"
                                    no-resize
                                    class="font-devices"
                                    label="Piętro / pokój / inne"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-home-floor-1</v-icon>
                                    </template>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                    v-model="getModalDevices.contactPerson"
                                    :error-messages="
                                        getContactsError.contactPerson
                                    "
                                    dense
                                    class="font-devices"
                                    label="Osoba kontaktowa"
                                    item-value="_id"
                                    item-text="name"
                                    :items="getClientRepresentatives"
                                >
                                    <template v-slot:selection="data">
                                        <p class="pa-0 ma-0">
                                            {{ data.item.lastname }}
                                            <span
                                                v-if="data.item.phoneNumber"
                                            >
                                                •
                                                {{
                                                data.item.phoneNumber
                                                }}
                                            </span>
                                            <span v-if="data.item.email">
                                                &nbsp;&nbsp;•
                                                {{ data.item.email }}
                                            </span>
                                        </p>
                                    </template>
                                    <template v-slot:item="data">
                                        <p class="pa-0 ma-0">
                                            {{ data.item.name }}
                                            {{ data.item.lastname }}
                                            <span
                                                v-if="data.item.phoneNumber"
                                            >
                                                •
                                                {{
                                                data.item.phoneNumber
                                                }}
                                            </span>
                                            <span v-if="data.item.email">
                                                &nbsp;&nbsp;•
                                                {{ data.item.email }}
                                            </span>
                                        </p>
                                    </template>
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-phone</v-icon>
                                    </template>
                                    <template slot="append">
                                        <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                                        <!-- <v-icon color="#19aa8d" size="18">mdi-plus</v-icon> -->
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>

                <div class="d-flex">
                    <v-col cols="7" class="pl-0" style="padding-right: 35px">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <Separator text="INNE" />
                                <!-- <span>Lokalizacja urządzenia:</span> -->
                            </v-col>

                            <v-col cols="5" class="py-0">
                                <v-autocomplete
                                    v-model="getModalDevices.delivery"
                                    dense
                                    :items="getContactsItems"
                                    return-object
                                    item-value="_id"
                                    item-text="name"
                                    class="font-devices"
                                    label="Dostawca"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-truck</v-icon>
                                    </template>
                                    <template
                                        v-if="getModalDevices.delivery.name"
                                        slot="append-outer"
                                    >
                                        <!-- v-if="getModalDevices.delivery.name" -->
                                        <v-menu nudge-left nudge-width="300">
                                            <template
                                                v-slot:activator="{ on: menu }"
                                                style="positon: absolute; right: 0"
                                            >
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on: tooltip,
                                                        }"
                                                    >
                                                        <v-btn
                                                            class="pointer"
                                                            small
                                                            icon
                                                            v-on="{
                                                                ...tooltip,
                                                                ...menu,
                                                            }"
                                                        >
                                                            <v-icon
                                                                color="#19aa8d"
                                                                size="18"
                                                            >mdi-information-variant</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Wiecej informacji</span>
                                                </v-tooltip>
                                            </template>
                                            <v-card
                                                class="pa-4 flex-column"
                                                style=" color: #757575; font-size: 14px;"
                                            >
                                                <div class="d-flex">
                                                    <v-avatar
                                                        class="pointer white--text"
                                                        v-on="on"
                                                        color="teal"
                                                        size="40"
                                                    >
                                                        {{
                                                        getModalDevices
                                                        .delivery
                                                        .name[0]
                                                        }}
                                                    </v-avatar>
                                                    <div class="d-flex flex-column ml-3">
                                                        <span style="font-size: 15px; color: black"></span>
                                                        <span>
                                                            {{
                                                            getModalDevices
                                                            .delivery.name
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <v-divider class="my-3"></v-divider>
                                                    <p class="ma-0">
                                                        <b>
                                                            Adres:
                                                            {{
                                                            getModalDevices
                                                            .delivery
                                                            .street
                                                            }}
                                                            {{
                                                            getModalDevices
                                                            .delivery
                                                            .houseNumber
                                                            }}
                                                            <span
                                                                v-if="
                                                                    getModalDevices
                                                                        .delivery
                                                                        .apartmentNumber
                                                                "
                                                            >
                                                                /
                                                                {{
                                                                getModalDevices
                                                                .delivery
                                                                .apartmentNumber
                                                                }}
                                                            </span>
                                                            {{
                                                            getModalDevices
                                                            .delivery
                                                            .postCode
                                                            }}
                                                            {{
                                                            getModalDevices
                                                            .delivery
                                                            .city
                                                            }}
                                                        </b>
                                                    </p>
                                                    <p class="ma-0">
                                                        <b>
                                                            Email:
                                                            {{
                                                            getModalDevices
                                                            .delivery
                                                            .email
                                                            }}
                                                        </b>
                                                    </p>
                                                    <p class="ma-0">
                                                        <b>
                                                            Kontakt:
                                                            {{
                                                            getModalDevices
                                                            .delivery
                                                            .city
                                                            }}
                                                        </b>
                                                    </p>
                                                </div>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                    <template v-slot:selection="data">
                                        {{
                                        data.item.name
                                        }}
                                    </template>
                                    <template v-slot:item="data">
                                        {{
                                        data.item.name
                                        }}
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="3" class="py-0">
                                <v-text-field
                                    v-model="getModalDevices.asset"
                                    dense
                                    class="font-devices"
                                    label="Środek trwały"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-fridge</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4" class="py-0">
                                <v-select
                                    dense
                                    v-model="getModalDevices.owner"
                                    :items="itemsOwner"
                                    label="Właściciel sprzętu"
                                >
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-account-arrow-left</v-icon>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                        lg="5"
                        class="d-flex flex-wrap align-start"
                        style="align-content: baseline;"
                    >
                        <v-col cols="12" md="12" lg="12" class="py-2">
                            <Separator text="Opcje" />
                            <!-- <span>Lokalizacja urządzenia:</span> -->
                        </v-col>
                        <v-col cols="12" md="12" lg="6" class="py-0">
                            <v-autocomplete
                                dense
                                class="font-devices"
                                label="Opcje"
                                item-value="options"
                                item-text="options"
                                :items="getModalDevices.modelDevices.options"
                                multiple
                                v-model="getModalDevices.options"
                            >
                                <template slot="prepend">
                                    <v-icon color="#b3b3b3" readonly size="18">mdi-settings</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                    {{
                                    data.item + `, `
                                    }}
                                </template>
                                <template v-slot:item="data">
                                    {{
                                    data.item
                                    }}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="12" lg="6" class="py-0">
                            <v-text-field
                                dense
                                class="font-devices"
                                label="Aktualna umowa OSP"
                                v-model="getModalDevices.OSP"
                            >
                                <template slot="prepend">
                                    <v-icon color="#b3b3b3" readonly size="18">mdi-file-outline</v-icon>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-col>
                </div>

                <v-row></v-row>

                <v-row>
                    <v-col cols="12" class="py-1 mt-2">
                        <v-textarea
                            dense
                            height="50"
                            class="font-devices mt-5 correct-devices"
                            no-resize
                            v-on="on"
                            v-model="getModalDevices.comment"
                            label="Uwagi"
                        >
                            <template slot="prepend">
                                <v-icon color="#f57c7c" size="18">mdi-alert-octagon</v-icon>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-divider class="p-absolute" style="top: 0; right: -15px;" vertical></v-divider>
            </v-col>

            <v-col cols="12" md="4" class="py-0">
                <v-card
                    class="mx-auto py-0 px-5 p-relative"
                    elevation="0"
                    max-width="565"
                    min-height="520px"
                >
                    <Separator text="Więcej o urządzeniu" />
                    <!-- <p class>Więcej o urządzeniu:</p> -->
                    <div class="d-flex">
                        <!-- <v-avatar tile size="140" color="grey" style="margin-top: 25px">
                            <img
                                width="450px"
                                src="https://www.pd.com.pl/wp-content/uploads/2018/06/Lexmark-XM1145.jpg"
                                alt
                            />
                        </v-avatar>-->
                        <div style="margin-top: 50px;">
                            <div class="d-flex">
                                <span class="devices__information__left">Producent</span>
                                <span class="devices__information__right">
                                    {{
                                    getModalDevices.modelDevices.producer
                                    }}
                                </span>
                            </div>
                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Model</span>
                                <span class="devices__information__right">
                                    {{
                                    getModalDevices.modelDevices.model
                                    }}
                                </span>
                            </div>
                            <div class="d-flex" style="margin-top: -2px; height: 60px">
                                <span class="devices__information__left">Rodzaj urządzenia</span>
                                <span class="devices__information__right">
                                    {{
                                    getModalDevices.modelDevices.deviceType
                                    }}
                                </span>
                            </div>

                            <div class="d-flex" style="margin-top: -2px; ">
                                <span class="devices__information__left">Pakiet cenowy</span>
                                <span class="devices__information__right">
                                    {{
                                    getModalDevices.modelDevices.price.priceName
                                    }}
                                </span>
                            </div>
                            <div class="d-flex" style="margin-top: -2px; height: 60px">
                                <span class="devices__information__left">Ustalono ceny dedykowane</span>
                                <span class="devices__information__right">
                                    <v-checkbox
                                        v-model="
                                            getModalDevices.dedicatedPrice
                                                .checkbox
                                        "
                                        color="red darken-3"
                                        hide-details
                                        class="pa-0 ma-0 devices__information__right__checkboxs"
                                    ></v-checkbox>
                                </span>
                            </div>
                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Ceny w pakiecie</span>
                                <span class="devices__information__right">standardowa</span>
                                <span
                                    class="devices__information__right devices__prices__left devices__prices__margin"
                                >dedykowana</span>
                            </div>

                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Przegląd okresowy</span>
                                <!-- <v-text-field
                                    :value="price.standard.survey"
                                    ref="getModalDevices.modelDevices.price.survey.price"
                                    class="devices__information__center prices__left"
                                    v-model="getModalDevices.modelDevices.price.survey.price"
                                    dense
                                    disabled
                                ></v-text-field>-->
                                <span class="devices__information__center prices__left">
                                    {{
                                    getModalDevices.modelDevices.price
                                    .survey.price
                                    }}
                                    PLN
                                </span>
                                <div class="py-0 ma-2">
                                    <div class="input-effect price">
                                        <money
                                            class="effect-17 devicePrice"
                                            v-model="
                                                getModalDevices.dedicatedPrice
                                                    .survey
                                            "
                                            :disabled="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                            "
                                            :style="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                                    ? `color: rgba(0, 0, 0, 0.38) `
                                                    : `color: #2d2c2c`
                                            "
                                            v-bind="money"
                                        ></money>
                                        <span class="focus-border"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Naprawa</span>
                                <!-- <v-text-field
                                    :value="price.standard.repair"
                                    v-model="getModalDevices.modelDevices.price.repair.price"
                                    dense
                                    disabled
                                    class="devices__information__center prices__left"
                                ></v-text-field>-->
                                <span class="devices__information__center prices__left">
                                    {{
                                    getModalDevices.modelDevices.price
                                    .repair.price
                                    }}
                                    PLN
                                </span>
                                <div class="py-0 ma-2">
                                    <div class="input-effect price">
                                        <money
                                            class="effect-17 devicePrice"
                                            v-model="
                                                getModalDevices.dedicatedPrice
                                                    .repair
                                            "
                                            :disabled="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                            "
                                            :style="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                                    ? `color: rgba(0, 0, 0, 0.38) `
                                                    : `color: #2d2c2c`
                                            "
                                            v-bind="money"
                                        ></money>
                                        <span class="focus-border"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Wdrożenie</span>
                                <!-- <v-text-field
                                    v-model="getModalDevices.modelDevices.price.installation.price"
                                    :value="price.standard.installation"
                                    dense
                                    disabled
                                    class="devices__information__center prices__left"
                                ></v-text-field>-->
                                <span class="devices__information__center prices__left">
                                    {{
                                    getModalDevices.modelDevices.price
                                    .installation.price
                                    }}
                                    PLN
                                </span>
                                <!-- <v-text-field
                                    dense
                                    v-model="getModalDevices.dedicatedPrice.installation"
                                    :error-messages="getPriceError.dedicatedPrice.installation"
                                    :disabled="!getModalDevices.dedicatedPrice.checkbox"
                                    class="devices__information__right devices__prices__left pa-0 px-2 ma-0"
                                ></v-text-field>-->
                                <div class="py-0 ma-2">
                                    <div class="input-effect price">
                                        <money
                                            class="effect-17 devicePrice"
                                            v-model="
                                                getModalDevices.dedicatedPrice
                                                    .installation
                                            "
                                            :disabled="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                            "
                                            :style="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                                    ? `color: rgba(0, 0, 0, 0.38) `
                                                    : `color: #2d2c2c`
                                            "
                                            v-bind="money"
                                        ></money>
                                        <span class="focus-border"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Diagnostyka</span>
                                <!-- <v-text-field
                                    dense
                                    disabled
                                    v-model="getModalDevices.modelDevices.price.diagnostics.price"
                                    :value="price.standard.diagnostic"
                                    class="devices__information__right prices__left"
                                ></v-text-field>-->
                                <span class="devices__information__center prices__left">
                                    {{
                                    getModalDevices.modelDevices.price
                                    .diagnostics.price
                                    }}
                                    PLN
                                </span>
                                <!-- <v-text-field
                                    dense
                                    v-model="getModalDevices.dedicatedPrice.diagnostics"
                                    :error-messages="getPriceError.dedicatedPrice.diagnostics"
                                    :disabled="!getModalDevices.dedicatedPrice.checkbox"
                                    class="devices__information__right devices__prices__left pa-0 px-2 ma-0"
                                ></v-text-field>-->
                                <div class="py-0 ma-2">
                                    <div class="input-effect price">
                                        <money
                                            class="effect-17 devicePrice"
                                            v-model="
                                                getModalDevices.dedicatedPrice
                                                    .diagnostics
                                            "
                                            :disabled="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                            "
                                            :style="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                                    ? `color: rgba(0, 0, 0, 0.38) `
                                                    : `color: #2d2c2c`
                                            "
                                            v-bind="money"
                                        ></money>
                                        <span class="focus-border"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex" style="margin-top: -2px">
                                <span class="devices__information__left">Usługa IT</span>

                                <!-- <v-text-field
                                    dense
                                    v-model="getModalDevices.modelDevices.price.itService.price"
                                    :value="price.standard.itService"
                                    disabled
                                    class="devices__information__right prices__left"
                                ></v-text-field>-->
                                <span class="devices__information__center prices__left">
                                    {{
                                    getModalDevices.modelDevices.price
                                    .itService.price
                                    }}
                                    PLN
                                </span>
                                <!-- <v-text-field
                                    dense
                                    v-model="getModalDevices.dedicatedPrice.itService"
                                    :error-messages="getPriceError.dedicatedPrice.itService"
                                    :disabled="!getModalDevices.dedicatedPrice.checkbox"
                                    class="devices__information__right devices__prices__left pa-0 px-2 ma-0"
                                ></v-text-field>-->

                                <div class="py-0 ma-2">
                                    <div class="input-effect price">
                                        <money
                                            class="effect-17 devicePrice"
                                            v-model="
                                                getModalDevices.dedicatedPrice
                                                    .itService
                                            "
                                            :disabled="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                            "
                                            :style="
                                                !getModalDevices.dedicatedPrice
                                                    .checkbox
                                                    ? `color: rgba(0, 0, 0, 0.38) `
                                                    : `color: #2d2c2c`
                                            "
                                            v-bind="money"
                                        ></money>
                                        <span class="focus-border"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";

export default {
    data: () => ({
        enabled: false,
        valid: true,
        itemsOwner: [
            "Klienta",
            "Leasing Klienta",
            `${process.env.VUE_APP_FIRM}`,
            "Dzierżawa Sharp",
            "Leasing",
            "Inny",
        ],
        money: {
            decimal: ",",
            thousands: "",
            suffix: " PLN",
            precision: 2,
            masked: false,
        },
        header: "Baza urządzeń",
        date: new Date().toISOString().substr(0, 10),
        date2: new Date().toISOString().substr(0, 10),
        date3: new Date().toISOString().substr(0, 10),
        menu: false,
        rules: { required: (value) => !!value || "To pole jest wymagane" },
        modal: false,
        lengthText: "",
        modal2: false,
        modal3: false,
        modal4: false,
        on: false,
        components: ["test"],
        addressDeliver: null,
        emailDeliver: null,
        phoneDeliver: null,
        companyName: null,
        price: {
            standard: {
                diagnostic: "",
                installation: "",
                itService: "",
                repair: "",
                survey: "",
            },
        },
        model: {
            producent: "",
            type: "",
            name: "",
        },
        options: [],
    }),

    computed: {
        ...mapGetters([
            "getModalDevices",
            "getServiceBase",
            "getDevicesList",
            "getModelItems",
            "getContactsItems",
            "getDevicesListModel",
            "getTaskDictionary",
            "getDevicesOptions",
            "getClientItems",
            "getClientBranches",
            "getClientRepresentatives",
            "getContactsError",
            "getPriceError",
        ]),
    },

    methods: {
        ...mapActions(["fetchDevice", "fetchModelDevice", "fetchContacts"]),
        ...mapMutations(["closeModal", "setClientThings"]),
        debounceInput: debounce(function (e) {
            // this.getModalDevices.SN = e.target.value;
            if (e.target.value.length >= 4) {
                this.fetchDevice(e.target.value);
            }
            this.getModalDevices.SN = e.target.value;
        }, 500),

        submit() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;

                this.$refs[f].validate(true);
            });
        },

        debounceModel: debounce(function (e) {
            if (e.target.value.length >= 3) {
                this.fetchModelDevice({ search: e.target.value });
            }
        }, 500),

        changeRouter() {
            if (this.getModalDevices.SN._id) {
                this.$router.push(`/devices/${this.getModalDevices.SN._id}`);
                this.closeModal();
            }
        },
        findContacts: debounce(function (e) {
            this.fetchContacts(e.target.value);
        }, 500),

        autocomplete(e) {
            this.getModalDevices.modelDevices._id = e._id;
            this.getModalDevices.modelDevices.price.priceName =
                e.price.priceName;
            this.getModalDevices.modelDevices.model = e.model;
            this.getModalDevices.modelDevices.producer = e.producer;
            this.getModalDevices.modelDevices.deviceType = e.deviceType;
            this.getModalDevices.extrComments = e.description;
            this.getModalDevices.modelDevices.price.diagnostics.price =
                e.price.diagnostics.price;
            this.getModalDevices.modelDevices.price.installation.price =
                e.price.installation.price;
            this.getModalDevices.modelDevices.price.itService.price =
                e.price.itService.price;
            this.getModalDevices.modelDevices.price.repair.price =
                e.price.repair.price;
            this.getModalDevices.modelDevices.price.survey.price =
                e.price.survey.price;
            this.getModalDevices.modelDevices.options = e.options;
            this.options = e.options;
        },
    },

    components: {
        Separator,
    },
};
</script>
