import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';
const state = {
	devices: {
		title: 'Baza urządzeń',
		header: [{
				text: 'Urządzenie',
				align: 'left',
				value: 'device',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'S/N',
				width: '15%',
				value: 'SN',
				class: 'central__table__header',
			},
			{
				text: 'Podstawa Serwisu',
				width: '15%',
				value: 'serviceBase',
				class: 'central__table__header',
			},
			{
				text: 'Klient',
				value: 'client',
				width: '15%',
				class: 'central__table__header',
			},
			{
				text: 'Pakiet usług',
				value: 'modelDevices.price.priceName',
				width: '15%',
				class: 'central__table__header',
			},

			{
				text: 'Status',
				value: 'status',
				width: '5%',
				class: 'central__table__header',
			},
			{
				text: 'Akcja',
				align: 'right',
				width: '5%',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	items: [],
	itemsModel: [],
	serviceBase: [],
	contactItems: [],
	deviceItems: [],
	clientItems: [],
	clientBranches: [],
	clientRepresentative: [],
	// error massage only front
	errors: {
		dedicatedPrice: {
			diagnostics: '',
			installation: '',
			itService: '',
			priceName: '',
			repair: '',
			survey: '',
		},
	},
	modal: {
		fields: {
			SN: null,
			qrCodeIsPrint: false,
			serviceBase: '',
			menager: '',
			status: '',
			dedicatedPrice: {
				checkbox: null,
				diagnostics: null,
				installation: null,
				itService: null,
				priceName: null,
				repair: null,
				survey: null,
			},
			extrComments: null,
			producent: null,
			modelDevices: {
				model: null,
				series: null,
				producer: null,
				deviceType: null,

				price: {
					diagnostics: {
						price: '',
					},
					installation: {
						price: '',
					},
					itService: {
						price: '',
					},
					priceName: '',
					repair: {
						price: '',
					},
					survey: {
						price: '',
					},
				},
			},
			priority: null,
			datePurchase: null,
			warrantyFrom: null,
			warrantyTo: null,
			warrantyToParts: null,
			client: null,
			address: null,
			options: [],
			OSP: null,
			GPS: null,
			floor: null,
			contactPerson: null,
			delivery: {
				_id: '',
				name: null,
				street: null,
				apartmentNumber: null,
				postCode: null,
				city: null,
				email: null,
				houseNumber: null,
			},
			asset: null,
			owner: null,
			comment: null,
			implementationData: {
				precertifying: '',
				settings: '',
			},
		},
		errors: {
			SN: '',
			extrComments: null,
			serviceBase: '',
			client: null,
			producent: null,
			modelDevices: {
				model: null,
				series: null,
				producer: null,
				deviceType: null,
				price: {
					diagnostics: {
						price: '',
					},
					installation: {
						price: '',
					},
					itService: {
						price: '',
					},
					priceName: '',
					repair: {
						price: '',
					},
					survey: {
						price: '',
					},
				},
			},
			price: null,

			priority: null,
			datePurchase: null,
			warrantyFrom: null,
			warrantyTo: null,
			warrantyToParts: null,

			address: null,
			options: [],
			OSP: null,
			GPS: null,
			floor: null,
			contactPerson: null,
			delivery: null,
			asset: null,
			owner: null,
			comment: null,
			implementationData: {
				precertifying: '',
				settings: '',
			},
		},
	},
	details: {
		SN: null,
		dedicatedPrice: {
			checkbox: null,
			diagnostics: null,
			installation: null,
			itService: null,
			priceName: null,
			repair: null,
			survey: null,
		},
		extrComments: null,
		producent: null,
		modelDevices: {
			model: null,
			series: null,
			producer: null,
			deviceType: null,
			price: {
				diagnostics: {
					price: '',
				},
				installation: {
					price: '',
				},
				itService: {
					price: '',
				},
				priceName: '',
				repair: {
					price: '',
				},
				survey: {
					price: '',
				},
			},
		},
		price: null,

		priority: null,
		datePurchase: null,
		warrantyFrom: null,
		warrantyTo: null,
		warrantyToParts: null,
		client: null,
		address: null,
		options: [],
		OSP: null,
		GPS: null,
		floor: null,
		contactPerson: null,
		delivery: {
			_id: null,
			name: null,
			street: null,
			apartmentNumber: null,
			postCode: null,
			city: null,
			email: null,
			houseNumber: null,
		},
		asset: null,
		owner: null,
		comment: null,
		implementationData: {
			precertifying: '',
			settings: '',
		},
	},
	fieldsNames: {
		SN: 'Wprowadź S/N',
		index: '',
		delivery: 'Dostawca',
		menager: '',
		status: '',
		checkbox: 'Ustalono ceny dedykowane',
		diagnostics: 'Diagnostyka',
		installation: 'Wdrożenie',
		itService: 'Usługa IT',
		priceName: 'Pakiet cenowy',
		repair: 'Naprawa',
		survey: 'Przegląd okresowy',
		extrComments: 'Opis dodatkowy',
		model: 'Model urządzenia',
		series: 'Seria urządzenia',
		producer: 'Producent urządzenia',
		deviceType: 'Rodzaj urządzenia',
		priority: 'Priorytet',
		serviceBase: 'Podstawa serwisu',
		options: 'Opcje',
		datePurchase: 'Data zakupu',
		warrantyFrom: 'Gwarancja OD',
		warrantyTo: 'Gwarancja DO',
		warrantyToParts: 'Gwarancja części',
		client: 'Nazwa klienta',
		address: 'Miejscowość / ulica / nr',
		OSP: 'Aktualna umowa OSP',
		GPS: 'Dane GPS',
		floor: 'Piętro / pokój / inne',
		contactPerson: 'Osoba kontaktowa',
		asset: 'Środek trwały',
		owner: 'Właściciel sprzętu',
		comment: 'Uwagi',
		precertifying: 'Poświadczenia',
		settings: 'Ustawienia',
	},
};

const getters = {
	getDevices: (state) => state.devices,
	getModalDevices: (state) => state.modal.fields,
	getContactsItems: (state) => state.contactItems,
	getContactsError: (state) => state.modal.errors,
	getPriceError: (state) => state.errors,
	getClientItems: (state) => state.clientItems,
	getClientBranches: (state) => state.clientBranches,
	getClientRepresentatives: (state) => state.clientRepresentative,
	getDevicesList: (state) => state.items,
	getDevicesListModel: (state) => state.itemsModel,
	getDevicesDetails: (state) => state.details,
	getServiceBase: (state) => state.serviceBase,
};

const actions = {
	editImplementation({
		commit,
		dispatch
	}, payload) {
		Vue.axios({
				method: 'PUT',
				url: `/devices/implementation/${payload}`,
				data: {
					...state.modal.fields.implementationData,
				},
			})
			.then((response) => {
				dispatch('devicesDeatils', {
					id: payload,
					type: 'details',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dane wdrożeniowe',
				});
			})
			.catch((error) => console.log(error));
	},
	fetchModelDevice({
		state,
		commit
	}, payload) {
		// commit('resetErrors', 'cars');
		Vue.axios({
			method: 'POST',
			url: '/devices/searchModel',
			data: {
				modelDevices: payload,
			},
		}).then((response) => {
			commit('setDevicesModel', response.data.items);
		});
	},
	qrCode({
		state,
		commit
	}, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/devices/edit/qrCode/${payload}`,
		}).then((response) => {
			commit('setQrCodeIsPrints', response.data.item);
		});
	},

	fetchServiceBase({
		commit
	}) {
		Vue.axios({
				method: 'GET',
				url: '/dictionary/service/core',
			})
			.then((response) => {
				commit('setServiceBaseItems', response.data.items);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	fetchContactsDeliver({
		commit
	}) {
		Vue.axios({
				method: 'GET',
				url: `/getContacts`,
			})
			.then((response) => {
				commit('setContactsItems', response.data.items);
			})
			.catch((error) => {
				if (error.response.status == '404') router.push('/NotFound');
			});
	},

	fetchDevice({
		state,
		commit
	}, payload) {
		// commit('resetErrors', 'cars');
		Vue.axios({
			method: 'POST',
			url: '/devices/search',
			data: {
				SN: payload,
			},
		}).then((response) => {
			commit('setDevices', response.data.items);
		});
	},

	fetchContacts({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/contacts/search/${payload}`,
			})
			.then((response) => {
				commit('setClientItems', response.data.items);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	createDevice({
		state,
		commit,
		dispatch
	}, payload) {
		commit('resetErrors', 'devices');

		if (state.modal.fields.dedicatedPrice.checkbox === true) {
			// let price = state.modal.fields.dedicatedPrice;
			let t = 1;
			// Object.keys(price).forEach(key => {
			// 	if (!price[key] && key != 'checkbox') {
			// 		state.errors.dedicatedPrice[key] = 'Proszę uzupełnić pola';
			// 		t++;
			// 	}
			// });
			if (t == 1) {
				Vue.axios({
						method: 'POST',
						url: '/devices',
						data: {
							...state.modal.fields,
							...(state.modal.fields.qrCodeIsPrint = false),
							...payload,
						},
					})
					.then((response) => {
						dispatch('fetchData', {
							page: payload.page,
							pageSize: 11,
							search: '',
							sortBy: [],
							sortDesc: [],
							routeName: 'devices',
						});

						commit('closeModal');
						commit('setSnackbars', {
							type: 'success',
							text: 'Pomyślnie dodano urządzenie',
						});
					})
					.catch((error) => {
						if (error.response.data.error == 'Exists') {
							state.modal.errors.SN =
								'Taki numer seryjny już istnieje';
						}

						commit('setErrors', {
							route: 'devices',
							err: error.response.data.error,
						});
					});
			}
		} else {
			Vue.axios({
					method: 'POST',
					url: '/devices',
					data: {
						...state.modal.fields,
						...(state.modal.fields.qrCodeIsPrint = false),
						...payload,
					},
				})
				.then((response) => {
					dispatch('fetchData', {
						page: payload.page,
						pageSize: 11,
						search: '',
						sortBy: [],
						sortDesc: [],
						routeName: 'devices',
					});
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie dodano urządzenie',
					});
				})
				.catch((error) => {
					if (error.response.data.error == 'Exists') {
						state.modal.errors.SN =
							'Taki numer seryjny już istnieje';
					}

					commit('setErrors', {
						route: 'devices',
						err: error.response.data.error,
					});
				});
		}
	},

	devicesDeatils({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/devices/${payload.id}`,
			})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setDevicesDetails', response.data.item);
				} else {
					commit(
						'setQrCodeIsPrints',
						response.data.item.qrCodeIsPrint,
					);
					commit('setDetailsIndex', response.data.item.index);
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDevicesModal', response.data.item);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				if (error.response.status == '404') router.push('/NotFound');
			});
	},

	updateDevices({
		commit
	}, payload) {
		commit('resetErrors', 'devices');

		if (state.modal.fields.dedicatedPrice.checkbox === true) {
			// let price = state.modal.fields.dedicatedPrice;
			let t = 1;
			// Object.keys(price).forEach(key => {
			// 	if (!price[key] === 0 && key != 'checkbox') {
			// 		state.errors.dedicatedPrice[key] = 'Proszę uzupełnić pola';
			// 		t++;
			// 	}
			// });
			if (t == 1) {
				Vue.axios({
						method: 'PUT',
						url: `/devices/edit/${payload}`,
						data: {
							...state.modal.fields,
						},
					})
					.then((response) => {
						commit('closeModal');
						commit('refreshDetails', response.data.item);
						commit('setSnackbars', {
							type: 'success',
							text: 'Pomyślnie edytowano model urządzenia',
						});
					})

					// if (error.response.data.error == 'Exists') {
					// 	state.modal.errors.SN = "Taki numer seryjny już istnieje"
					// };
					.catch((error) => {
						console.log(error);
						if (error.response.data.error == 'Exists') {
							state.modal.errors.SN =
								'Taki numer seryjny już istnieje';
						} else {
							commit('setErrors', {
								route: 'devices',
								err: error.response.data.error,
							});
						}
					});
			}
		} else {
			Vue.axios({
					method: 'PUT',
					url: `/devices/edit/${payload}`,
					data: {
						...state.modal.fields,
					},
				})
				.then((response) => {
					commit('closeModal');
					commit('refreshDetails', response.data.item);
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano model urządzenia',
					});
				})
				.catch((error) => {
					if (error.response.data.msg == 'Exists') {
						state.modal.errors.SN =
							'Taki numer seryjny już istnieje';
					} else {
						commit('setErrors', {
							route: 'models',
							err: error.response.data.error,
						});
					}
				});
		}
	},
};

const mutations = {
	setServiceBaseItems(state, payload) {
		state.serviceBase = payload;
	},

	resetDeviceItems() {
		state.items = [];
		state.itemsModel = [];
		state.clientBranches = [];
		state.clientItems = [];
		state.clientRepresentative = [];
	},
	setDevices(state, payload) {
		state.items = payload;
	},
	setDevicesModel(state, payload) {
		state.itemsModel = payload;
	},
	setContactsItems(state, payload) {
		state.contactItems = payload;
	},
	setQrCodeIsPrints(state, payload) {
		state.modal.fields.qrCodeIsPrint = payload;
	},

	setClientItems(state, payload) {
		if (payload[0] != null) {
			state.clientItems = payload;
		} else {
			state.clientItems = [];
		}
	},

	setDevicesModal(state, payload) {
		state.details = payload;

		if (!payload.implementationData) {
			state.details.implementationData = {
				precertifying: '',
				settings: '',
			};
		}
	},

	setDevicesDetails(state, payload) {
		state.itemsModel = [payload.modelDevices];
		state.clientItems = [payload.client];
		state.clientBranches = payload.client.branches;
		state.clientRepresentative = payload.client.representatives;
		state.modal.fields = payload;
		state.modal.fields.datePurchase = payload.datePurchase ?
			moment(payload.datePurchase).format('YYYY-MM-DD') :
			'';
		state.modal.fields.warrantyTo = payload.warrantyTo ?
			moment(payload.warrantyTo).format('YYYY-MM-DD') :
			'';
		state.modal.fields.warrantyFrom = payload.warrantyFrom ?
			moment(payload.warrantyFrom).format('YYYY-MM-DD') :
			'';
		state.modal.fields.warrantyToParts = payload.warrantyToParts ?
			moment(payload.warrantyToParts).format('YYYY-MM-DD') :
			'';

		if (!payload.implementationData) {
			state.modal.fields.implementationData = {
				precertifying: '',
				settings: '',
			};
		}

		if (!payload.delivery) {
			state.modal.fields.delivery = {
				_id: '',
				name: '',
				street: '',
				apartmentNumber: '',
				postCode: '',
				city: '',
				email: '',
				houseNumber: '',
			};
		}
	},

	refreshDetails(state, payload) {
		state.details = payload;
	},
	setClientThings(state, payload) {
		state.clientBranches = payload.branches;
		state.clientRepresentative = payload.representatives;
		state.modal.fields.address = payload.branches[0];
	},
};

export default {
	state,
	mutations,
	getters,
	actions,
};