<template>
    <v-container>
        <v-row>
            <v-col cols="6" class="d-flex flex-wrap">
                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        v-model="getWarehouseDetails.company"
                        dense
                        :label="getWarehouseFieldsNames.company"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6"></v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        :value="$moment(getWarehouseDetails.createdAt).format('YYYY-MM-DD') + ' ' + getWarehouseDetails.rotationType"
                        dense
                        :label="'Data i ' + getWarehouseFieldsNames.rotationType"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        v-model="getWarehouseDetails.carOwnership"
                        dense
                        :label="getWarehouseFieldsNames.carOwnership"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        v-if="getWarehouseDetails.rotationType == 'Wydanie'"
                        dense
                        :label="getWarehouseFieldsNames.constructionId"
                        :value="getWarehouseDetails.constructionId ? getWarehouseDetails.constructionId.name : ''"
                        item-value="_id"
                        item-text="name"
                    ></v-text-field>
                    <v-text-field
                        readonly
                        v-if="getWarehouseDetails.rotationType == 'Dostawa'"
                        dense
                        :label="getWarehouseFieldsNames.contactId"
                        :value="getWarehouseDetails.contactId ? getWarehouseDetails.contactId.name : ''"
                        item-value="_id"
                        item-text="name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        v-if="getWarehouseDetails.carOwnership == 'Obcy' "
                        :value="getWarehouseDetails.carPlate"
                        dense
                        :label="getWarehouseFieldsNames.carPlate"
                    ></v-text-field>

                    <v-text-field
                        dense
                        readonly
                        v-if="getWarehouseDetails.carOwnership == 'Własny' "
                        :label="getWarehouseFieldsNames.carPlate"
                        :value="getWarehouseDetails.carId ? getWarehouseDetails.carId.model + ' ' + getWarehouseDetails.carId.plate : getWarehouseDetails.carId.plate"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        :value="getWarehouseDetails.createdBy.name + ' ' + getWarehouseDetails.createdBy.lastname"
                        dense
                        :label="getWarehouseFieldsNames.createdBy"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        v-model="getWarehouseDetails.carDriver"
                        dense
                        :label="getWarehouseFieldsNames.carDriver"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-text-field
                        readonly
                        v-model="getWarehouseDetails.wzDate"
                        :label="getWarehouseFieldsNames.wzDate"
                        prepend-icon="mdi-calendar"
                    ></v-text-field>
                </v-col>
            </v-col>
            <v-col cols="6" class="d-flex flex-wrap" style="border-left:1px solid black">
                <v-col cols="6" md="6">
                    <v-text-field
                        readonly
                        v-model="getWarehouseDetails.weighingDate"
                        dense
                        :label="getWarehouseFieldsNames.weighingDate"
                        prepend-icon="mdi-calendar"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="6"></v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        :value="getWarehouseDetails.materialType ? getWarehouseDetails.materialType.name : ''"
                        :label="getWarehouseFieldsNames.materialType + ' [' + getWarehouseDetails.materialType.unit+']'"
                        item-value="_id"
                        item-text="name"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="6"></v-col>

                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        :value="Number(getWarehouseDetails.tareWeight).toFixed(2)"
                        dense
                        :label="getWarehouseFieldsNames.tareWeight + ' [' + getWarehouseDetails.materialType.unit+']'"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        :value="correctWeight(getWarehouseDetails.grossWeight, getWarehouseDetails.tareWeight)"
                        dense
                        :label="'Waga netto' + ' [' + getWarehouseDetails.materialType.unit+']'"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        :value="Number(getWarehouseDetails.grossWeight).toFixed(2)"
                        dense
                        :label="getWarehouseFieldsNames.grossWeight + ' [' + getWarehouseDetails.materialType.unit+']'"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-checkbox
                        readonly
                        v-model="getWarehouseDetails.weighingDone"
                        :label="getWarehouseFieldsNames.weighingDone"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                        dense
                        readonly
                        v-model="getWarehouseDetails.description"
                        :label="getWarehouseFieldsNames.description"
                        auto-grow
                    ></v-textarea>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getWarehouseFieldsNames",
            "getWarehouseDetails",
            "getDropdown",
        ]),
    },
    methods: {
        ...mapActions(["fetchItems"]),
        correctWeight(gross, tare) {
            return Number(Number(gross) - Number(tare)).toFixed(2);
        },
    },
};
</script>