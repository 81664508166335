<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="createTask">
                <v-icon small>mdi-clipboard-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj zadanie</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["openModal"]),
        createTask() {
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 1000,
                type: "create",
                route: this.$route.name,
                tab: "tasks_devices"
            });
        }
    }
};
</script>

