<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="editInformation()">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj informacje</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"])
    },
    methods: {
        ...mapActions([
            "openModal",
            "nonMedicalPrintsDeatils",
            "fetchPositionItems"
        ]),
        editInformation() {
            this.fetchPositionItems(this.$route.name);
            this.nonMedicalPrintsDeatils({
                id: this.$route.params.id,
                type: "modal",
                route: this.$route.name
            });
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "edit",
                width: 600,
                route: "nonMedicalPrint",
                tab: "non-medical-print"
            });
        }
    }
};
</script>