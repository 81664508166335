import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';
import findIndex from 'lodash.findindex';

const state = {
	modalWifi: false,
	Wifi: {
		title: 'WIFI',
		header: [
			// {
			// 	text: 'Data utworzenia',
			// 	align: 'left',
			// 	value: 'createdAt',
			// 	class: 'central__table__header',
			// 	width: '20%',
			// },
			{
				text: 'Nazwa',
				value: 'title',
				class: 'central__table__header',
				width: '45%',
			},
			// {
			// 	text: 'Status',
			// 	value: 'status',
			// 	class: 'central__table__header',
			// 	width: '15%',
			// },
			{
				text: 'Data ostatniej modyfikacji',
				align: 'left',
				value: 'lastModified',
				class: 'central__table__header',
				width: '25%',
			},
			{
				text: 'Menager',
				value: 'menager',
				class: 'central__table__header',
				width: '20%',
			},
			{
				text: 'Załącznik',
				value: 'information',
				class: 'central__table__header',
				width: '8%',
				align: 'center'
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
				width: '10%',
			},
		],
	},
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
		},
	},
	fieldsNames: {
		title: 'Nazwa',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
	},
};

const getters = {
	getWifi: (state) => state.Wifi,
	getWifiModal: (state) => state.modal.fields,
	getWifiError: (state) => state.modal.errors,
	getWifiDetails: (state) => state.modal.details,
	getWifiFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	createWifi({
		dispatch,
		state,
		commit
	}, payload) {

		Vue.axios({
			method: 'POST',
			url: '/wifi',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then(() => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'wifi',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano wpis',
				});
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response.data);
				commit('setErrors', {
					route: 'Wifi',
					err: error.response.data.error,
				});
			});

	},

	updateWifi({
		state,
		commit
	}, payload) {
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		} else {
			Vue.axios({
				method: 'PUT',
				url: `/wifi/${payload}`,
				data: {
					...state.modal.fields,
				},
			})
				.then((response) => {
					commit('setWifiDeatils', response.data.item);
					commit('closeModal');
					commit('setWifiModal', {});
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano wpis',
					});
				})
				.catch((error) => {
					console.log(error);
					commit('setErrors', {
						route: 'Wifi',
						err: error.response.data.error,
					});
				});
		}
	},

	wifiDetails({
		state,
		commit
	}, payload) {
		Vue.axios({
			method: 'GET',
			url: `/wifi/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setWifiModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setWifiDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'Wifi',
					err: error.response.data.error,
				});
			});
	},
};

const mutations = {
	setWifiDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate ?
			moment(payload.archiveDate).format('YYYY-MM-DD') :
			'';
	},
	setWifiModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate ?
			moment(payload.archiveDate).format('YYYY-MM-DD') :
			'';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};