import Vue from 'vue';
import router from '@/router.js';

const state = {
    modalPrints: false,
    printers: {
        title: 'Drukarki',
        header: [
            {
                text: 'Data utworzenia',
                value: 'createdAt',
                width: 180,
                class: 'central__table__header '
            },
            {
                text: 'Wprowadzający',
                width: 250,
                value: 'created',
                class: 'central__table__header'
            },
            {
                text: 'Klient',
                value: 'client',
                class: 'central__table__header'
            },
            {
                text: 'Nazwa',
                width: 250,
                value: 'name',
                class: 'central__table__header'
            },
            {
                text: 'Model',
                width: 250,
                value: 'model',
                class: 'central__table__header'
            },

            {
                text: 'Akcja',
                align: 'right',
                value: 'action',
                sortable: false,
                class: 'central__table__header'
            }
        ]
    },
    modal: {
        fields: {
            name: null,
            model: null,
            comment: null
        }
    }
};

const getters = {
    getPrinters: state => state.printers,
    getPrintersPrints: state => state.modal.fields

    // getPrinters: state => state.printers,
    // getPrintersFields: state => state.modal.fields
};

const actions = {
    createPrinters({ dispatch, commit }) {
        commit('resetErrors', 'models');
        Vue.axios({
            method: 'POST',
            url: '/printers',
            data: {
                ...state.modal.fields
            }
        })
            .then(response => {
                dispatch('fetchData', {
                    page: 1,
                    pageSize: 11,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    routeName: 'printers'
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie dodano drukarkę'
                });
            })
            .catch(error => {
                if (error.response.data.msg === 'Exists') {
                    state.modal.errors.model = 'Taka grukarka już istnieje';
                } else {
                    commit('setErrors', {
                        route: 'printers',
                        err: error.response.data.error
                    });
                }
            });
    }
};

export default {
    state,
    getters,
    actions
};
