
<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="editParts">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj informacje</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["openModal", "partsDeatils"]),
        ...mapMutations(["clearParts"]),
        editParts() {
            this.openModal({
                headLine: "global.create",
                width: 600,
                open: true,
                type: "edit",
                route: "parts",
                tab: "parts"
            });
            this.partsDeatils({
                id: this.$route.params.id,
                type: "modal",
                route: this.$route.name
            });
            this.clearParts();
        }
    },
    computed: {
        ...mapGetters(["getPermission"])
    }
};
</script>
