<template>
  <v-row>
    <div class="d-flex ma-2 mt-2 p-absolute">
      <v-btn @click="orderToday({ day: 1 })" class="ma-1" small>Tył</v-btn>
      <v-btn @click="orderToday({ day: 0 })" class="ma-1" small>Dzisiaj</v-btn>
      <v-btn @click="orderToday({ day: -1 })" class="ma-1" small>Przód</v-btn>
    </div>
    <v-col cols="12" class="d-flex align-center justify-center pa-0">
      <p class="ma-2" style="font-size: 18px; color: green; font-weight: 700">
        {{ getOrdersDay.day ? getOrdersDay.day : '' }}
      </p>
    </v-col>
    <v-col cols="12" md="12" xl="12">
      <v-row>
        <v-col
          v-for="(item, index) in getOrders"
          :key="index"
          cols="2"
          class="pa-1"
        >
          <div class="pa-2" style="background-color: #f2f2f2; height: 600px">
            <div class="d-flex align-center justify-center my-3">
              <p class="d-flex">
                <v-avatar v-if="item.technic.picture" size="40" class="mx-2">
                  <img
                    :src="`${getGlobalPaths.profilePic}${item.technic.picture}`"
                    alt
                  />
                </v-avatar>
                <v-avatar color="#19aa8d" size="40" v-else>
                  <span style="color: white">
                    {{ item.technic.name[0] }}{{ item.technic.lastname[0] }}
                  </span>
                </v-avatar>
                <span class="mx-2"
                  >{{ item.technic.name }} {{ item.technic.lastname }}</span
                >
              </p>
            </div>

            <div style="overflow: auto; max-height: 670px">
              <v-alert
                v-for="(item, index) in item.orders"
                :key="index"
                border="left"
                class="py-1 px-2"
                colored-border
                :color="item.status.possibleStatus.color.background"
                elevation="2"
              >
                <!--  $router.push(`/service/${item._id}`) -->

                <div
                  class="d-flex"
                  style="flex-direction: column; cursor: pointer"
                  @click="closeModal(), $router.push(`/service/${item._id}`)"
                >
                  <span style="font-size: 12px; font-weight: 700">{{
                    item.index
                  }}</span>
                  <span style="font-size: 12px; font-weight: 700">
                    {{ item.device.modelDevices.producer }}
                    {{
                      item.device.modelDevices.model
                        ? item.device.modelDevices.model
                        : 'Nie wprowadzono jeszcze danych'
                    }}
                  </span>
                  <span style="font-size: 12px">{{
                    item.device.client ? item.device.client.name : 'Brak'
                  }}</span>
                </div>
              </v-alert>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getOrders', 'getGlobalPaths', 'getOrdersDay']),
  },
  methods: {
    ...mapActions(['orderToday']),
    ...mapMutations(['closeModal']),
  },
};
</script>
