<template>
    <v-snackbar
        :timeout="2500"
        v-model="getSnackbars.snackbar"
        :color="getSnackbars.type"
        right
        top
    >
        <v-icon v-if="getSnackbars.type === 'error'" color="white">mdi-close-circle</v-icon>
        <v-icon v-if="getSnackbars.type === 'success'" color="white">mdi-check-circle</v-icon>
        <v-icon v-if="getSnackbars.type === 'warning'" color="white">mdi-alert-circle</v-icon>
        <div style="width: 100%">
            <span style="margin-left: 10px">{{ getSnackbars.text }}</span>
        </div>
        <v-btn
            style="position: absolute; top: -5px; right: 5px"
            dark
            icon
            text
            @click="closeSnackbars()"
        >
            <v-icon size="15">mdi-close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
// import { timeout } from "q";
export default {
    props: ["text", "timeout"],
    data: () => ({}),
    methods: {
        ...mapMutations(["showSnackbars", "closeSnackbars"])
    },
    computed: {
        ...mapGetters(["getSnackbars"])
    }
};
</script>