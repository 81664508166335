import Vue from 'vue';
import moment from 'moment-timezone';
import store from '../../index';
moment.tz.setDefault('Europe/Warsaw');

const state = {
	logs: {
		header: [
			{
				text: 'Data',
				value: 'updatedAt',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Nazwa pola | Akcja',
				value: 'fieldName',
				class: 'central__table__header',
				width: '20%',
			},
			{
				text: 'Treść pola | Akcja',
				value: 'fieldContents',
				class: 'central__table__header',
				width: '50%',
			},
			{
				text: 'Pracownik',
				value: 'worker',
				class: 'central__table__header',
				width: '15%',
			},
		],
		items: [
			{
				date: '10.03.2019',
				name: 'Stanowisko',
				description:
					'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum nisi distinctio laborum odio? Sint, sunt!',
				worker: 'Arkadiusz Balcer',
			},
		],
	},
	modal: {
		fields: {
			name: null,
			description: null,
			users: [],
			registries_reader: null,
			registries_user: null,
			permission: [],
		},
		errors: {
			name: '',
			description: '',
		},
	},
	table: {},
};

const getters = {
	getLogs: (state) => state.logs,
	getLogsTable: (state) => state.table,
};

const actions = {
	async fetchLogs({ commit }, payload) {
		await Vue.axios({
			method: 'GET',
			url: `/global/find/logs`,
			params: {
				page: payload.page,
				search: payload.search,
				sortBy: payload.sortBy,
				sortDesc: payload.sortDesc,
				pageSize: 11,
				itemId: payload.itemId,
			},
		}).then((response) => {
			if (payload.route == 'information-security-policy')
				payload.route = 'securityPolicy';
			for (const [key, value] of Object.entries(store.state)) {
				if (
					key.toUpperCase() ===
						payload.route
							.split('-')
							.join('')
							.toUpperCase() &&
					'fieldsNames' in value
				) {
					response.data.items.forEach((element) => {
						element.fieldName = Object.byString(
							value.fieldsNames,
							element.fieldName,
						)
							? Object.byString(
									value.fieldsNames,
									element.fieldName,
							  )
							: element.fieldName;

						element.updatedAt = moment(
							new Date(element.updatedAt),
						).format('YYYY-MM-DD HH:mm');
					});
					break;
				}
			}

			state.table = response.data;
		});
	},
};

export default {
	state,
	getters,
	// mutations,
	actions,
};

async function asyncForEach(array, callback) {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
}

Object.byString = function(o, s) {
	s = s.replace(/\[(\w+)\]/g, '.$1');
	s = s.replace(/^\./, '');
	var a = s.split('.');
	for (var i = 0, n = a.length; i < n; ++i) {
		var k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
};
