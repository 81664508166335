import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	constructions: {
		title: 'Budowy',
		header: [
			{
				text: 'Index',
				value: 'index',
				class: 'central__table__header',
				width: '8%',
			},
			{
				text: 'Data utworzenia',
				value: 'createdAt',
				class: 'central__table__header',
				width: '12%',
			},
			{
				text: 'Nazwa budowy',
				value: 'name',
				class: 'central__table__header',
				width: '30%',
			},
			{
				text: 'Inwestor',
				value: 'investor.name',
				class: 'central__table__header',
				width: '35%',
			},
			{
				text: 'Status',
				value: 'status',
				width: '8%',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				width: '7%',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			name: '',
			postCode: '',
			city: '',
			street: '',
			description: '',
			responsiblePerson: '',
			investor: '',
		},
		errors: {
			name: null,
		},
	},
	fieldsNames: {
		name: 'Nazwa budowy',

		postCode: 'Kod pocztowy',
		city: 'Miejscowość',
		street: 'Ulica i numer',

		description: 'Opis',
		responsiblePerson: 'Osoba odpowiedzialna',
		investor: 'Inwestor',
	},
	details: {},
	constructionDeliveries: [],
	groupedTable: {},
};

const getters = {
	getConstructions: (state) => state.constructions,
	getConstructionsFieldsNames: (state) => state.fieldsNames,
	getConstructionsFields: (state) => state.modal.fields,
	getConstructionsDetails: (state) => state.details,
	getConstructionsErrors: (state) => state.modal.errors,
	getConstructionsDeliveries: (state) => state.constructionDeliveries,
	getConstructionsGroupedTable: (state) => state.groupedTable,
};

const actions = {
	async createConstruction({ commit, dispatch }, payload) {
		if (
			state.modal.fields.responsiblePerson &&
			state.modal.fields.responsiblePerson._id
		) {
			state.modal.fields.responsiblePerson =
				state.modal.fields.responsiblePerson._id;
		}
		Vue.axios({
			method: 'POST',
			url: '/constructions',
			data: { ...state.modal.fields },
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'constructions',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie utworzono budowę',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'constructions',
					err: error.response.data,
				});
			});
	},

	async constructionsDetails({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/constructions/${payload.id}`,
		}).then((response) => {
			if (payload.type == 'modal') {
				commit('setConstructionsFields', response.data.item);
			} else {
				commit('setDetailsMenager', response.data.item.menager);
				commit('setDetailsStatus', response.data.item.status);
				commit('setDetailsStatuses', response.data.statuses);

				commit('setConstructionsDetails', response.data.item);
				commit('setDetailsIndex', response.data.item.index);

				if (payload.next) {
					dispatch('fetchItems', 'contacts');
					payload.next();
				}
			}
		});
	},

	async constructionUpdate({ commit, dispatch }, payload) {
		if (
			state.modal.fields.responsiblePerson &&
			state.modal.fields.responsiblePerson._id
		) {
			state.modal.fields.responsiblePerson =
				state.modal.fields.responsiblePerson._id;
		}

		Vue.axios({
			method: 'PUT',
			url: `/constructions/${payload.id}`,
			data: { ...state.modal.fields },
		})
			.then((response) => {
				dispatch('constructionsDetails', { id: payload.id });
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano wpis',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'constructions',
					err: error.response.data.error,
				});
			});
	},

	async constructionsDeliveries({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/warehouse/construction/${payload.id}`,
		})
			.then((response) => {
				commit('setConstructionDeliveries', response.data.item);
			})
			.catch((error) => {
				console.error(error);
			});
	},
};

const mutations = {
	setConstructionsFields(state, payload) {
		state.modal.fields = payload;
	},
	setConstructionsDetails(state, payload) {
		state.details = payload;
	},
	setConstructionDeliveries(state, payload) {
		state.constructionDeliveries = payload;
		const ourCount = {};
		state.constructionDeliveries.forEach((entry) => {
			if (!ourCount[entry.materialType.name]) {
				ourCount[entry.materialType.name] = 0;
			}
			ourCount[entry.materialType.name] +=
				entry.grossWeight - entry.tareWeight;
		});
		state.groupedTable = ourCount;
	},
};

export default {
	state,
	actions,
	getters,
	mutations,
};
