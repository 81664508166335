<template>
    <v-container>
        <v-row class="worker__wrapper">
            <v-col cols="12" class="py-1 d-flex flex-wrap" md="12">
                <v-col cols="12" md="12" lg="6" style="height: 650px" class="p-relative">
                    <div class="d-flex">
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Podstawa zatrudnienia:"
                                :value="getUserStaff.contract ? getUserStaff.contract : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Wymiar etatu:"
                                :value="getUserStaff.dayJob ? getUserStaff.dayJob : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div class="d-flex">
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Początek zatrudnienia:"
                                :value="getUserStaff.employmentStart ? getUserStaff.employmentStart : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Koniec zatrudnienia:"
                                :value="getUserStaff.employmentEnd ? getUserStaff.employmentEnd : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div class="d-flex">
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Ostatnie badanie okresowe:"
                                :value="getUserStaff.lastExamination ? getUserStaff.lastExamination : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Ważność badania okresowego:"
                                :value="getUserStaff.endExamination ? getUserStaff.endExamination : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div class="d-flex">
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Data szkolenia BHP:"
                                :value="getUserStaff.startBhp ? getUserStaff.startBhp : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Ważność szkolenia BHP:"
                                :value=" getUserStaff.endBhp ? getUserStaff.endBhp : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div class="d-flex">
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Data urodzenia:"
                                :value="getUserDetails.birthday"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field
                                disabled
                                label="Miejsce urodzenia:"
                                :value="getUserStaff.placeOfBirth ? getUserStaff.placeOfBirth : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>
                    <div class="d-flex">
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea
                                height="150"
                                no-resize
                                disabled
                                label="Wymagania dodatkowe:"
                                :value="getUserStaff.needments ? getUserStaff.needments : 'Brak'"
                            ></v-textarea>
                        </v-col>
                    </div>
                    <v-divider vertical class="worker__wrapper__divider"></v-divider>
                </v-col>

                <v-col cols="12" md="12" lg="6" class="py-1">
                    <v-col cols="12" sm="12" md="12" class="py-2">
                        <v-textarea
                            no-resize
                            disabled
                            label="Uwagi:"
                            :value="getUserStaff.comment ? getUserStaff.comment : 'Brak'"
                        ></v-textarea>
                    </v-col>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>





<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getUserDetails", "getUserStaff"])
    },
    methods: {
        ...mapActions(["fetchStaff"])
    },
    beforeMount() {
        this.fetchStaff(this.$route.params.id);
    }
};
</script>
