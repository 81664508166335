import Vue from 'vue';
import i18n from '@/i18n.js';
import findIndex from 'lodash.findindex';
import axios from 'axios';

const state = {
    branch: {
        title: '',
        header: [
            {
                text: i18n.t('branches.header.name'),
                value: 'name',
                class: 'central__table__header',
            },
            {
                text: i18n.t('branches.header.address'),
                value: 'address',
                class: 'central__table__header',
            },
            {
                text: i18n.t('branches.header.city'),
                value: 'city',
                class: 'central__table__header',
            },
            {
                text: i18n.t('branches.header.phoneNumber'),
                value: 'phoneNumber',
                class: 'central__table__header',
            },
            {
                text: i18n.t('branches.header.email'),
                value: 'email',
                class: 'central__table__header',
            },
            {
                text: i18n.t('global.actions'),
                align: 'right',
                value: 'action',
                sortable: false,
                class: 'central__table__header',
            },
        ],
    },
    modal: {
        fields: {
            name: null,
            postCode: null,
            city: null,
            address: null,
            phoneNumber: null,
            email: null,
            contactId: null,
        },
        errors: {
            name: null,
            postCode: null,
            city: null,
            address: null,
        },
    },
    table: {},
};

const getters = {
    getBranches: (state) => state.branch,
    getBranchesData: (state) => state.modal.fields,
    getBranchesErrors: (state) => state.modal.errors,
    getBranchesTable: (state) => state.table,
};

const actions = {
    async createBranch({ commit, dispatch }, payload) {
        await dispatch('getLatLng', {
            address:
                state.modal.fields.city +
                ' ' +
                state.modal.fields.postCode +
                ' ' +
                state.modal.fields.address,
        });

        commit('resetErrors', 'branches');
        Vue.axios({
            method: 'POST',
            url: '/branches',
            data: {
                ...state.modal.fields,
                contactId: payload.contactId,
            },
        })
            .then(() => {
                dispatch('getBranchesByContact', {
                    page: 1,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    route: payload.contactId,
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie stworzono nowy oddział',
                });
            })
            .catch((error) => {
                commit('setErrors', {
                    route: 'branches',
                    err: error.response.data,
                });
            });
    },
    async updateBranch({ commit, dispatch }, payload) {
        await dispatch('getLatLng', {
            address:
                state.modal.fields.city +
                ' ' +
                state.modal.fields.postCode +
                ' ' +
                state.modal.fields.address,
        });

        await Vue.axios({
            method: 'PUT',
            url: `/branches/${state.modal.fields._id}`,
            data: {
                ...state.modal.fields,
            },
        })
            .then((response) => {
                let arr = state.table.items;
                let index = findIndex(arr, {
                    _id: response.data._id,
                });
                arr.splice(index, 1, response.data);
                commit('closeModal');
                // Vue.swal(
                // 	'Edytowano!',
                // 	'Pomyślnie edytowano oddział',
                // 	'success',
                // );
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano oddział',
                });
            })
            .catch((error) => {
                commit('setErrors', {
                    route: 'branches',
                    err: error.response.data,
                });
            });
    },
    async branchDetails({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/branches/${payload}`,
        }).then((resposne) => {
            commit('setBranchesFields', resposne.data.doc);
        });
    },
    async getBranchesByContact({ commit }, payload) {
        await Vue.axios({
            method: 'GET',
            url: `/global/find/branches`,
            params: {
                page: payload.page,
                search: payload.search,
                sortBy: payload.sortBy,
                sortDesc: payload.sortDesc,
                pageSize: 11,
                contactId: payload.route,
            },
        }).then((response) => {
            state.table = response.data;
        });
    },
    async getLatLng({ commit, dispatch }, payload) {
        await axios
            .get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: payload.address,
                    key: process.env.VUE_APP_GOOGLE_MAP_API,
                },
                withCredentials: false,
            })
            .then((response) => {
                if (response.data.results[0] == undefined) {
                } else {
                    state.modal.fields.location =
                        response.data.results[0].geometry.location;
                }
            });
    },
};

const mutations = {
    setBranchesFields(state, payload) {
        state.modal.fields = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
