<template>
    <tbody>
        <tr v-for="item in items" :key="item._id">
            <!-- === === NAZWA === === -->
            <td>{{item.name}}</td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({path:`/templates/${item._id}`})">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    methods: {
        ...mapActions(["registryDetails", "fetchItems"]),
        details(id) {
            this.fetchItems("departments");
            this.fetchItems("groups");
        }
    }
};
</script>