<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="14" md="12">
				<v-text-field
					dense
					v-model="getModalOffers.titleOffers"
					:label="getOffersFieldsNames.titleOffers"
					:error-messages="getOffersErrors.titleOffers"
				></v-text-field>
			</v-col>

			<v-col cols="12" sm="14" md="12">
				<v-autocomplete
					v-model="getModalOffers.contractorOffers"
					dense
					class="font-devices contractor-offers"
					:label="getOffersFieldsNames.contractorOffers"
					:hide-no-data="true"
					hint="Zacznij pisać..."
					@keyup="findContacts($event)"
					:items="getOffersItems"
					item-text="name"
					return-object
					@input="setClientThings($event)"
				>
					<template slot="prepend">
						<v-icon color="#b3b3b3" size="18">mdi-account</v-icon>
					</template>
				</v-autocomplete>
			</v-col>

			<v-col cols="12" class="py-0">
				<v-tabs centered>
					<v-tab ripple>reprezentant</v-tab>
					<v-tab ripple>dodaj nowego reprezentanta</v-tab>
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-autocomplete
									v-model="getModalOffers.responsibleOffers"
									dense
									class="font-devices"
									:label="getOffersFieldsNames.responsibleOffers"
									item-value="_id"
									item-text="name"
									:items="getOffersRepresentatives"
								>
									<template v-slot:selection="data">
										<p class="pa-0 ma-0">
											{{ data.item.name }} {{ data.item.lastname }}
											<span v-if="data.item.phoneNumber"
												>• {{ data.item.phoneNumber }}</span
											>
											<span v-if="data.item.email"
												>&nbsp;&nbsp;• {{ data.item.email }}</span
											>
										</p>
									</template>
									<template v-slot:item="data">
										<p class="pa-0 ma-0">
											{{ data.item.name }} {{ data.item.lastname }}
											<span v-if="data.item.phoneNumber"
												>• {{ data.item.phoneNumber }}</span
											>
											<span v-if="data.item.email"
												>&nbsp;&nbsp;• {{ data.item.email }}</span
											>
										</p>
									</template>
									<template slot="prepend">
										<v-icon color="#b3b3b3" size="18">mdi-phone</v-icon>
									</template>
									<template slot="append">
										<v-icon color="#445D75" size="18">mdi-menu</v-icon>
									</template>
								</v-autocomplete>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="getOffersFieldsNames.responsiblenewnameOffers"
											required
											v-model="getModalOffers.responsiblenewnameOffers"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="getOffersFieldsNames.responsiblenewlastOffers"
											required
											v-model="getModalOffers.responsiblenewlastOffers"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="getOffersFieldsNames.responsiblenewemailOffers"
											required
											v-model="getModalOffers.responsiblenewemailOffers"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											:label="getOffersFieldsNames.responsiblenewephoneOffers"
											required
											v-model="getModalOffers.responsiblenewephoneOffers"
										></v-text-field>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs>
			</v-col>
			<v-col cols="12" md="6" class="pt-0">
				<v-select
					dense
					:label="getOffersFieldsNames.typeOffers"
					v-model="getModalOffers.typeOffers"
					class="mt-5"
					:items="typeItems"
				></v-select>
			</v-col>
			<v-col cols="12" md="6" class="pt-0">
				<v-select
					dense
					:label="getOffersFieldsNames.typeContractor"
					v-model="getModalOffers.typeContractor"
					class="mt-5"
					:items="typeContractor"
				></v-select>
			</v-col>
			<v-col cols="12" md="6" class="pt-0">
				<v-menu
					v-model="beginningdata"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							v-model="getModalOffers.dataimportanceOffers"
							dense
							:label="getOffersFieldsNames.dataimportanceOffers"
							prepend-icon="mdi-calendar"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						first-day-of-week="1"
						v-model="getModalOffers.dataimportanceOffers"
						@input="beginningdata = false"
					></v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="12" md="12">
				<v-textarea
					rows="2"
					:label="getOffersFieldsNames.commentOffers"
					v-model="getModalOffers.commentOffers"
				></v-textarea>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Separator from '@/components/Global/Separator.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';

export default {
	data() {
		return {
			responsibleOffers: 'not_accepted',
		};
	},
	data: () => ({
		typeItems: [
			'OSPsuite+',
			'OSPsource',
			'OSPcare',
			'OSPrental',
			'Komputery',
			'Serwis IT',
			'Sprzedaż sejfów',
			'Sprzedaż urządzeń drukujących',
			'Sprzedaż niszczarek',
			'Edukacja',
			'Sprzedaż ekspoatacji',
			'Serwis',
			'Inne',
		],
		typeContractor: ['Kontaktujący się', 'Działania własne', 'Stały'],
		beginningdata: false,
		enddata: false,
	}),
	computed: {
		...mapGetters([
			'getModalOffers',
			'getOffersFieldsNames',
			'getOffersItems',
			'getOffersRepresentatives',
			'getOffersErrors',
		]),
	},
	methods: {
		...mapActions(['fetchContacts']),
		...mapMutations(['closeModal', 'setClientThings', 'setClientItems']),
		findContacts: debounce(function(e) {
			this.fetchContacts(e.target.value);
		}, 500),
	},
};
</script>
