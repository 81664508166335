<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" lg="6" class="d-flex flex-wrap">
                <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Nazwa drukarki"
                        v-model="getPrintersDetails.model"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Model drukarki"
                        v-model="getPrintersDetails.series"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-textarea
                        readonly
                        class="no-pointer"
                        dense
                        auto-grow
                        label="Opis dodatkowy"
                        rows="1"
                        v-model="getPrintersDetails.description"
                    ></v-textarea>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["getPrintersDetails"]),
        correctOptions() {
            let string = "";
            for (let i = 0; i < this.getPrintersDetails.options.length; i++) {
                if (i == 0) string += `${this.getPrintersDetails.options[i]},`;
                else string += ` ${this.getPrintersDetails.options[i]},`;
            }
            return string;
        }
    }
};
</script>