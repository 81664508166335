import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	modalLeasing: false,
	Leasing: {
		title: 'Leasingi',
		header: [
			{
				text: 'Index',
				align: 'left',
				value: 'index',
				class: 'central__table__header',
				width: '125px',
			},
			{
				text: 'Numer umowy',
				value: 'nrLeasing',
				class: 'central__table__header',
				width: '155px',
			},
			{
				text: 'Leasingodawca',
				value: 'contractorLeasing',
				class: 'central__table__header',
				width: '500px',
			},
			{
				text: 'Data zawarcia',
				value: 'databeginningLeasing',
				class: 'central__table__header',
				width: '175px',
			},
			{
				text: 'Data wygaśnięcia',
				value: 'dataendLeasingt',
				class: 'central__table__header',
				width: '175px',
			},
			{
				text: 'Wartość miesięczna netto',
				value: 'valueAgreement',
				class: 'central__table__header',
				width: '220px',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '125px',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},

	LeasingCar: {
		header: [
			{
				text: 'VIN',
				align: 'left',
				value: 'index',
				class: 'central__table__header',
				width: '7%',
			},
			{
				text: 'Model',
				align: 'left',
				value: 'index',
				class: 'central__table__header',
				width: '7%',
			},
		],
	},

	clientItems: [],
	clientRepresentative: [],
	modal: {
		fields: {
			nrLeasing: '',
			titleLeasing: '',
			contractorLeasing: '',
			commentLeasingt: '',
			databeginningLeasing: '',
			dataendLeasing: '',
			valuenettoLeasing: '',
			valuemonthlyLeasing: '',
			installmentLeasing: '',
			repurchaseLeasing: '',
			responsibleLeasing: '',
		},
		errors: {
			nrLeasing: null,
			titleLeasing: null,
		},
	},
	details: {
		nrLeasing: '',
		titleLeasing: '',
		contractorLeasing: '',
		commentLeasingt: '',
		databeginningLeasing: '',
		dataendLeasing: '',
		valuenettoLeasing: '',
		valuemonthlyLeasing: '',
		installmentLeasing: '',
		repurchaseLeasing: '',
	},
	fieldsNames: {
		nrLeasing: 'Numer umowy leasingowej',
		titleLeasing: 'Tytuł',
		contractorLeasing: 'Kontrahent',
		responsibleLeasing: 'Osoba odpowiedzialna po stronie Kontrahenta',
		subjectLeasing: 'Przedmiot leasingu',
		databeginningLeasing: 'Data rozpoczęcia',
		dataendLeasing: 'Data zakończenia',
		valuenettoLeasing: 'Wartość netto przedmiotów leasingu',
		valuemonthlyLeasing: 'Wartość miesięczna netto',
		installmentLeasing: 'Rata leasingowa netto',
		repurchaseLeasing: 'Wykup netto',
		commentLeasingt: 'Opis / Uwagi',
		responsiblenewnameLeasing: 'Imię reprezentanta',
		responsiblenewlastLeasing: 'Nazwisko reprezentanta',
		responsiblenewephoneLeasing: 'Numet telefonu',
		responsiblenewemailLeasing: 'E-mail',
	},
};

const getters = {
	getLeasingCar: (state) => state.Caritems,
	getLeasingCarH: (state) => state.LeasingCar.header,
	getLeasing: (state) => state.Leasing,
	getModalLeasing: (state) => state.modal.fields,
	getLeasingDetails: (state) => state.details,
	getLeasingFieldsNames: (state) => state.fieldsNames,
	getLeasingItems: (state) => state.clientItems,
	getLeasingRepresentatives: (state) => state.clientRepresentative,
	getLeasingErrors: (state) => state.modal.errors,

	//getCarsDictionaryItems: (state) => state.Cars,
};

const actions = {
	createLeasing({ dispatch, commit }, payload) {
		// commit('resetErrors', 'leasing');
		Vue.axios({
			method: 'POST',
			url: '/leasing',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'leasing',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano nowy leasing',
				});
			})
			.catch((error) => {
				console.log(error.response.data);
				commit('setErrors', {
					route: 'Leasing',
					err: error.response.data,
				});
			});
	},

	leasingDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/leasing/${payload.id}`,
		}).then((response) => {
			if (payload.type == 'modal') {
				commit('setLeasingModal', response.data.item);
			} else {
				commit('setLeasingDeatils', response.data.item);
				commit('setDetailsIndex', response.data.item.index);
				commit('setDetailsMenager', response.data.item.menager);
				commit('setDetailsStatus', response.data.item.status);
				commit('setDetailsStatuses', response.data.statuses);
				if (payload.next) {
					payload.next();
				}
			}
		});
	},

	fetchContacts({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/contacts/search/${payload}`,
		})
			.then((response) => {
				commit('setClientItems', response.data.items);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	updateLeasing({ state, commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/leasing/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setLeasingDeatils', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie zmodyfikowano leasing',
				});
			})
			.catch((error) => {
				if (error.response.data.error.errors.nrLeasing['kind'] == 'required') {
					state.modal.errors.nrLeasing = 'Pole wymagane';
				}
				if (
					error.response.data.error.errors.titleLeasing['kind'] == 'required'
				) {
					state.modal.errors.titleLeasing = 'Pole wymagane';
				}
			});
	},
	leasingCarsDeatils({ commit }) {
		Vue.axios({
			method: 'GET',
			url: `/leasingc`,
		}).then((response) => {
			commit('setLeasingCars', response.data.items);
		});
	},
};

const mutations = {
	resetLeasingItems() {
		state.clientItems = [];
		state.clientRepresentative = [];
		state.LeasingCar = [];
	},
	setLeasingDeatils(state, payload) {
		state.details = payload;
	},
	setLeasingModal(state, payload) {
		state.modal.fields = payload;
		state.clientItems = [payload.contractorLeasing];
		state.clientRepresentative = payload.contractorLeasing.representatives;
	},

	setClientItems(state, payload) {
		if (payload[0] != null) {
			state.clientItems = payload;
		} else {
			state.clientItems = [];
		}
	},

	setClientThings(state, payload) {
		state.clientRepresentative = payload.representatives;
	},

	setLeasingCars(state, payload) {
		state.Caritems = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
