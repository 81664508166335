<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" class="pt-0">
        <v-text-field
          dense
          v-model="getPatientsConsentModal.title"
          :label="getPatientsConsentFieldsNames.title"
          :error-messages="getPatientsConsentError.title"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <v-text-field
          dense
          v-model="getPatientsConsentModal.patientsConsentIndex"
          :label="getPatientsConsentFieldsNames.patientsConsentIndex"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <v-text-field
          dense
          v-model="getPatientsConsentModal.version"
          :label="getPatientsConsentFieldsNames.version"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4" class="pt-0">
        <v-menu
          v-model="modaldata"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getPatientsConsentModal.archiveDate"
              dense
              clearable
              :label="getPatientsConsentFieldsNames.archiveDate"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="getPatientsConsentModal.archiveDate"
            @input="modaldata = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <v-select
          v-if="getModal.type == 'edit'"
          dense
          :items="getPositionItems"
          item-text="title"
          item-value="position"
          label="Ustaw za"
          v-model="getPatientsConsentModal.changePosition"
        ></v-select>
      </v-col>

      <v-col cols="12" md="12">
        <v-textarea
          :label="getPatientsConsentFieldsNames.information"
          v-model="getPatientsConsentModal.information"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    modaldata: false,
    ACReader: "users",
  }),
  computed: {
    ...mapGetters([
      "getPatientsConsentError",
      "getPatientsConsentModal",
      "getPatientsConsentFieldsNames",
      "getPositionItems",
      "getModal",
    ]),
  },
  methods: {
    ...mapActions([""]),
  },
};
</script>
