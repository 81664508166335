<template>
    <v-container>
        <div>
            <div class="details__title">
                <h1 class="font-weight-light">Lista kursów pojazdu:</h1>
            </div>
            <v-row>
                <v-col cols="12" md="6">
                    <v-data-table
                        disable-sort
                        fixed-header
                        height="calc(100vh - 585px)"
                        class="central__table"
                        disable-pagination
                        hide-default-footer
                        :items="getCarsDeliveries"
                        :headers="headers"
                    >
                        <template v-slot:item="{item}">
                            <tr>
                                <td>{{item.rotationType}}</td>
                                <td>{{item.constructionId ? item.constructionId.name : item.contactId ? item.contactId.name : ''}}</td>
                                <td>{{Number(correctWeight(item.grossWeight, item.tareWeight)) + ` [${item.materialType.unit}]`}}</td>
                                <td>{{item.weighingDate ? $moment(item.weighingDate).format('YYYY-MM-DD') : ''}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        headers: [
            {
                text: "Rotacja",
                value: "rotationType",
                class: "central__table__header",
            },
            {
                text: "Budowa | Dostawca",
                value: "constructionId",
                class: "central__table__header",
            },
            {
                text: "Waga netto",
                value: "netWeight",
                class: "central__table__header",
            },
            {
                text: "Data ważenia",
                value: "weighingDate",
                class: "central__table__header",
            },
        ],
    }),
    computed: {
        ...mapGetters(["getCarsDeliveries"]),
    },
    methods: {
        ...mapActions(["carsDeliveries"]),
        correctWeight(gross, tare) {
            return (
                Math.round(
                    (Number(gross) - Number(tare) + Number.EPSILON) * 100
                ) / 100
            );
        },
    },
    beforeMount() {
        this.carsDeliveries({ id: this.$route.params.id });
    },
    components: {
        Separator,
    },
};
</script>