<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === MODEL === === -->
            <td>{{ item.model }}</td>

            <!-- === === TABLICA REJESTRACYJNA === === -->
            <td>{{ item.plate }}</td>

            <!-- === === UŻYTKOWNIK === === -->
            <td>
                <span v-if="item.driver">
                    {{ item.driver.name }}
                    {{ item.driver.lastname }}
                </span>
                <span v-else>Brak</span>
            </td>

            <!-- === === DATA NASTĘPNEGO PRZEGLĄDU ===  -->
            <td>{{ item.survey ? $moment(item.survey).format('YYYY-MM-DD') : 'Brak' }}</td>

            <!-- === === UBEZPIECZENIE DO === ===  -->
            <td>{{item.inSuranceExpiration ? $moment(item.inSuranceExpiration).format('YYYY-MM-DD') : 'Brak'}}</td>

            <!-- === === ROK PRODUKCJI === === -->
            <td>{{ item.year ? $moment(item.year).format('YYYY-MM-DD') : 'Brak'}}</td>
            <!-- === === STATUS=== === -->
            <td>
                <v-chip
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/cars/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: ["items"],
    data: () => ({})
};
</script>
