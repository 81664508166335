<template>
    <v-container class="grey lighten-5" style="padding-right: 7px">
        <v-row no-gutters>
            <v-col class="my-2" cols="12">
                <div style="display: flex; align-items: center">
                    <div
                        class="headline font-weight-light"
                        style="margin-left: 15px; margin-top: -3px;"
                    >POWIADOMIENIA</div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                style="position: absolute; right: 30px;"
                                v-on="on"
                                icon
                                :to="tasksRoute"
                            >
                                <v-icon color="black">mdi-arrow-right-thick</v-icon>
                            </v-btn>
                        </template>
                        <span>Przejdź do wszystkich</span>
                    </v-tooltip>
                </div>
            </v-col>
            <v-col cols="12" style="max-height: 820px; overflow-y: auto; margin-right: -5px">
                <v-alert
                    :color="task.color"
                    border="left"
                    v-for="task in tasks"
                    :key="task.id"
                    elevation="2"
                    colored-border
                    v-show="closed"
                    class="notifications__card"
                >
                    <div style="display: flex; align-items: center">
                        <v-icon
                            size="18"
                            :color="task.color"
                            style="margin-right: 8px; margin-top: -5px"
                        >{{task.icon}}</v-icon>
                        {{task.text}}
                    </div>
                    <v-btn
                        class="mx-2"
                        style="width:15px; height: 15px; position: absolute; top:10px; right: 5px"
                        fab
                        dark
                        x-small
                        @click="closed = false"
                        :color="task.color"
                    >
                        <v-icon size="10" dark>mdi-check</v-icon>
                    </v-btn>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import truncate from "lodash.truncate";

export default {
    data() {
        return {
            tasksRoute: "/dashboard",
            remove: false,
            closed: true,
            fav: true,
            fab: false,
            transition: "slide-y-reverse-transition",
            on: "",
            tasks: []
        };
    },

    methods: {
        shortDescription(description, length) {
            return truncate(description, { length });
        },
        hiddenAlert() {
            this.closed = !this.closed;
        }
    }
};
</script>
