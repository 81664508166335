<template>
    <v-container>
        <v-row>
            <v-checkbox
                v-model="getImplementationOffers.turnedOn"
                class="mx-2"
                label="Rozpocznij proces wdrożenia"
            ></v-checkbox>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    v-model="datePicker"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            label="Data wdrożenia"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            clearable
                            v-model="getImplementationOffers.implementationDate"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        @input="datePicker = false"
                        v-model="getImplementationOffers.implementationDate"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="6">
                <v-menu
                    ref="menu"
                    v-model="timepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="getImplementationOffers.implementationTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="getImplementationOffers.implementationTime"
                            :disabled="!getImplementationOffers.implementationDate"
                            label="Czas "
                            readonly
                            clearable
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="timepicker"
                        v-model="getImplementationOffers.implementationTime"
                        format="24hr"
                        @click:minute="
                                $refs.menu.save(getImplementationOffers.implementationTime)
                            "
                    ></v-time-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-select
                    label="Podstawa wdrożenia"
                    :items="['Umowa OSP', 'Program DEMO', 'Sprzedaż', 'Użyczenie', 'Urządzenie zastępcze']"
                    v-model="getImplementationOffers.basic"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea label="Opis" v-model="getImplementationOffers.description" no-resize></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: () => ({
        datePicker: false,
        timepicker: false,
    }),
    computed: {
        ...mapGetters(["getImplementationOffers"]),
    },
};
</script>