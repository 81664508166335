import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
    modalBhp: false,
    bhp: {
        title: 'PROCEDURY BHP',
        header: [{
            text: 'Nazwa',
            value: 'name',
            class: 'central__table__header',
            width: '70%',
        },
        {
            text: 'Menager',
            value: 'menager',
            class: 'central__table__header',
            width: '10%',
        },
        // {
        //     text: 'Status',
        //     value: 'status',
        //     class: 'central__table__header',
        //     width: '10%',
        // },
        {
            text: 'Akcja',
            align: 'right',
            value: 'action',
            width: '10%',
            sortable: false,
            class: 'central__table__header',
        },
        ],
    },
    modal: {
        fields: {
            title: '',
            archiveDate: '',
            information: '',
            version: '',
            externalIndex: ''
        },
        errors: {
            title: '',
            archiveDate: '',
            information: '',
            version: '',
        },
        details: {
            title: '',
            archiveDate: '',
            information: '',
            version: '',
        },

    },
    fieldsNames: {
        title: 'Nazwa dokumentu',
        archiveDate: 'Data archiwizacji',
        information: "Informacja",
        bhpIndex: "Index",
        version: "Wersja",
    },
};


const getters = {
    getBhp: state => state.bhp,
    getBhpModal: state => state.modal.fields,
    getBhpError: state => state.modal.errors,
    getBhpDetails: state => state.modal.details,
    getBhpFieldsNames: state => state.fieldsNames,


};


const actions = {

    createBhp({
        dispatch,
        state,
        commit
    }, payload) {

        Vue.axios({
            method: 'POST',
            url: '/bhp',
            data: {
                ...state.modal.fields,
                ...payload,
            },
        })
            .then(response => {
                dispatch('fetchData', {
                    page: 1,
                    pageSize: 11,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    routeName: 'bhp',
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie dodano nowy druk',
                });
            })
            .catch(error => {
                commit('setErrors', {
                    route: 'bhp',
                    err: error.response.data.error,
                });
            });


    },

    bhpDeatils({
        commit
    }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/bhp/${payload.id}`,
        })
            .then(response => {
                if (payload.type == 'modal') {
                    commit('setBhpModal', response.data.item);
                } else {
                    commit('setDetailsMenager', response.data.item.menager);
                    commit('setDetailsStatus', response.data.item.status);
                    commit('setDetailsStatuses', response.data.statuses);
                    commit('setBhpDeatils', response.data.item)
                    commit('setDetailsIndex', response.data.item.index);
                    if (payload.next) {
                        payload.next();
                    }
                }
            })
            .catch(error => {
                console.log(error);
                commit('setErrors', {
                    route: 'bhp',
                    err: error.response.data.error,
                });
            });
    },

    updateBhp({
        state,
        commit
    }, payload) {

        Vue.axios({
            method: 'PUT',
            url: `/bhp/edit/${payload}`,
            data: {
                ...state.modal.fields,
            },
        })
            .then(response => {
                commit('setBhpDeatils', response.data.item);
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano informacje',
                });
            })
            .catch(error => {
                console.log(error);
                commit('setErrors', {
                    route: 'bhp',
                    err: error.response.data.error,
                });
            });

    },
}

const mutations = {
    setBhpDeatils(state, payload) {
        state.modal.details = payload;
        state.modal.fields.archiveDate = payload.archiveDate ?
            moment(payload.archiveDate).format('YYYY-MM-DD') :
            '';
    },
    setBhpModal(state, payload) {
        state.modal.fields = payload;
        state.modal.fields.archiveDate = payload.archiveDate ?
            moment(payload.archiveDate).format('YYYY-MM-DD') :
            '';
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
};