<template>
    <v-container class="py-0">
        <v-row>
            <v-col cols="12" class="py-1 p-relative" md="12">
                <v-data-table
                    :headers="getArchive.header"
                    :items="getArchive.items"
                    :mobile-breakpoint="1100"
                    height="615"
                    class="pb-0"
                    hide-default-footer
                    :items-per-page="11"
                    disable-sort
                >
                    <template
                        v-slot:item.client="{ item }"
                    >{{item.client ? item.client.name : 'Brak'}}</template>
                    <template
                        v-slot:item.createdAt="{ item }"
                    >{{$moment(item.createdAt).format('YYYY-MM-DD HH:mm')}}</template>
                    <template
                        v-slot:item.technic="{ item }"
                    >{{showCorrectTechnic(item.implementation)}}</template>
                    <template
                        v-slot:item.nextIntervention="{ item }"
                    >{{showCorrectDescInt(item.implementation)}}</template>
                    <template
                        v-slot:item.doneJob="{ item }"
                    >{{showCorrectDescDone(item.implementation)}}</template>
                    <template v-slot:item.action="{ item }">
                        <!-- === GO TO DETAILS BUTTON === -->
                        <v-btn router :to="`/service/${item._id}`" icon small>
                            <v-icon small>mdi-arrow-right-thick</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <!-- === === PAGINATION === === -->
                <div class="text-md-center pt-2">
                    <v-pagination
                        circle
                        v-model="table.page"
                        :length="getArchive.pages"
                        @input="refreshData"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    data: () => ({
        table: {
            page: 1
        }
    }),
    computed: {
        ...mapGetters(["getArchive", "getTable"])
    },
    methods: {
        ...mapActions(["deviceArchiveTab"]),
        showCorrectTechnic(data) {
            return data && data.technic
                ? `${data.technic.name} ${data.technic.lastname}`
                : "Brak";
        },
        showCorrectDescInt(data) {
            return data && data.description.nextIntervention
                ? `${data.description.nextIntervention}`
                : "Brak";
        },
        showCorrectDescDone(data) {
            return data && data.description.doneJob
                ? `${data.description.doneJob}`
                : "Brak";
        },
        refreshData() {
            let { page, search } = this.table;
            this.deviceArchiveTab({
                page,
                deviceID: this.$route.params.id,
                isService: false
            });
        }
    }
};
</script>