<template>
    <v-col cols="12" md="12" class="pa-2">
        <v-col cols="12" md="12">
            <Separator :text="'Dane grupy'" />
        </v-col>

        <v-col cols="12" md="12">
            <v-text-field
                label="Nazwa grupy"
                v-model="getGroupsData.name"
                :error-messages="getGroupsErrors.name"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
            <v-textarea
                label="Opis grupy"
                v-model="getGroupsData.description"
                :error-messages="getGroupsErrors.description"
            ></v-textarea>
        </v-col>
        <v-col cols="12" md="12">
            <Separator :text="'Ustaw członków grupy'" />
        </v-col>

        <v-col cols="12" md="12">
            <v-autocomplete
                v-model="getGroupsData.users"
                label="Pracownicy*"
                chips
                :items="getDropdown.items.users"
                multiple
                item-value="_id"
                :filter="filter"
                :loading="getDropdown.loading"
                :hide-no-data="getDropdown.hideNoData"
            >
                <template v-slot:selection="data">
                    <v-chip
                        pill
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        close
                        @click:close="removePerson(data.item._id)"
                    >
                        <v-avatar left color="secondary" class="mr-2">
                            <span v-if="!data.item.picture" class="white--text caption">
                                {{
                                avatarNick(data.item.name, data.item.lastname)
                                }}
                            </span>
                            <img
                                :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                v-else
                                style="object-fit: cover"
                            />
                        </v-avatar>
                        {{ data.item.lastname }} {{ data.item.name }}
                    </v-chip>
                </template>
                <template v-slot:item="data">
                    <v-list-item-avatar>
                        <v-avatar color="secondary">
                            <span v-if="!data.item.picture" class="white--text headline">
                                {{
                                avatarNick(data.item.name, data.item.lastname)
                                }}
                            </span>
                            <img
                                :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                v-else
                                style="object-fit: cover"
                            />
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ data.item.lastname }} {{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.job }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </v-col>
    </v-col>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    data: () => ({}),
    components: {
        Separator
    },
    computed: {
        ...mapGetters([
            "getGroupsData",
            "getGroupsErrors",
            "getDropdown",
            "getGlobalPaths"
        ])
    },
    methods: {
        ...mapMutations(["resetMenager", "removeGroupUser"]),
        filter(item, queryText, itemText) {
            return (
                item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                item.lastname.toLowerCase().includes(queryText.toLowerCase())
            );
        },
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0];
            return nick.toUpperCase();
        },
        removePerson(payload) {
            this.removeGroupUser(payload);
        }
    }
};
</script>
