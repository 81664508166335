<template>
  <div class="d-flex flex-row-reverse pa-0 buttons__mobile">
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn fab dark v-on="on" small class="buttons--add" @click="updateUser">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edytuj</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn class="buttons--add mr-3" @click="sendConfirmMail" fab dark v-on="on" small>
          <v-icon small>mdi-email-send</v-icon>
        </v-btn>
      </template>
      <span>Wyślij potwierdzenie założenia konta w Entelli</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["getPermission"])
  },
  methods: {
    ...mapActions([
      "openModal",
      "userDeatilsModal",
      "fetchJobDictionary",
      "fetchRoleDictionary",
      "sendConfirmMail"
    ]),
    ...mapMutations(["test"]),
    updateUser() {
      this.fetchJobDictionary();
      this.fetchRoleDictionary();
      this.openModal({
        headLine: "global.edit",
        open: true,
        width: 700,
        type: "edit",
        route: "users",
        tab: "information_users"
      });
      this.userDeatilsModal(this.$route.params.id);
    }
  }
};
</script>
