<template>
    <div>
        <v-col md="5" lg="3" offset-lg="9" offset-md="7">
            <v-text-field
                :label="$t('global.search')+'...'"
                v-model="table.search"
                append-icon="mdi-magnify"
                single-line
                hide-details
                @input="search"
            ></v-text-field>
        </v-col>
        <v-data-table
            :options.sync="options"
            height="calc(100vh - 465px)"
            :items-per-page="rows_per_page"
            :headers="getSessionLogsModal.headers"
            hide-default-footer
            :items="getSessionLogsModalTable.items"
        >
            <template v-slot:item.isBanned="{ item }">
                <v-chip small v-if="item.isBanned" label text-color="white" color="red accent-3">Tak</v-chip>
                <v-chip small v-else label color="green accent-3" text-color="white">Nie</v-chip>
            </template>
            <template v-slot:item.loginTries="{ item }">
                <span v-if="!item.loginTries">0</span>
                <span v-else>{{item.loginTries}}</span>
            </template>
            <template v-slot:item.lastLoginTry="{ item }">
                <span v-if="!item.lastLoginTry">Brak danych</span>
                <span v-else>{{correctDate(item.lastLoginTry)}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="item.isBanned" left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="unblockUser({id:item._id})"
                            v-on="on"
                            icon
                            color="green accent-3"
                        >
                            <v-icon small>mdi-account-key</v-icon>
                        </v-btn>
                    </template>
                    <span>Odblokuj użytkownika</span>
                </v-tooltip>

                <v-tooltip v-else left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="blockUser({id:item._id})"
                            v-on="on"
                            icon
                            color="red accent-3"
                        >
                            <v-icon small>mdi-account-lock</v-icon>
                        </v-btn>
                    </template>
                    <span>Zablokuj użytkownika</span>
                </v-tooltip>

                <v-tooltip v-if="item.loginTries > 0" left>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="resetTries({id:item._id})" v-on="on" icon>
                            <v-icon small>mdi-lock-reset</v-icon>
                        </v-btn>
                    </template>
                    <span>Zresetuj ilość prób</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-pagination
            circle
            v-model="table.page"
            :total-visible="visiblePage"
            :length="getSessionLogsModalTable.pages"
            @input="refreshData"
        ></v-pagination>
    </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import debounce from "lodash.debounce";
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            table: {
                search: "",
                page: 1,
                header: [],
                items: []
            },
            item: {},
            visiblePage: 5,
            options: {},
            rows_per_page: 9
        };
    },
    computed: {
        ...mapGetters(["getSessionLogsModalTable", "getSessionLogsModal"])
    },
    methods: {
        ...mapActions([
            "fetchUsersInfo",
            "blockUser",
            "unblockUser",
            "resetTries"
        ]),
        correctDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        refreshData() {
            const { sortBy, sortDesc } = this.options;
            const { page, search } = this.table;
            this.fetchUsersInfo({
                page: page,
                search: search,
                sortBy: sortBy,
                sortDesc: sortDesc,
                routeName: this.$route.name,
                pageSize: this.rows_per_page
            });
        },
        search: debounce(function() {
            this.refreshData();
        }, 500)
    },
    beforeMount() {
        this.fetchUsersInfo({
            page: 1,
            search: "",
            sortBy: "",
            sortDesc: "",
            pageSize: this.rows_per_page
        });
    },
    watch: {
        options: {
            handler() {
                this.refreshData();
                return;
            },
            deep: true
        }
    }
};
</script>