var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,style:(item.status &&
				item.status.possibleStatus &&
				item.status.possibleStatus.resolved
					? 'color: #9e9e9e'
					: '')},[_c('td',[_vm._v(_vm._s(item.indexOrdinance ? item.indexOrdinance : 'Brak'))]),_c('td',[_vm._v(_vm._s(item.title ? item.title : 'Brak'))]),_c('td',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.menager ? ((item.menager.name) + " " + (item.menager.lastname)) : 'Brak')+" ")]),(_vm.getRegistryHeader.status)?_c('td',[_c('v-chip',{style:(("color: " + (item.status &&
							item.status.possibleStatus &&
							item.status.possibleStatus.color &&
							item.status.possibleStatus.color.font
								? item.status.possibleStatus.color.font
								: ''))),attrs:{"color":item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.color &&
						item.status.possibleStatus.color.background
							? item.status.possibleStatus.color.background
							: '',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak')+" ")])],1):_vm._e(),_c('td',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ path: ("/director-orders/" + (item._id)) })}}},[_c('v-icon',{attrs:{"color":"#19aa8d","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }