import Vue from 'vue';
import router from '@/router.js';

import moment from 'moment-timezone';

const state = {
	modalTenders: false,
	Tenders: {
		title: 'Przetargi',
		header: [
			{
				text: 'Index',
				align: 'left',
				value: 'index',
				class: 'central__table__header',
				width: '110px',
			},
			{
				text: 'Kontakt',
				value: 'contractorTenders',
				class: 'central__table__header',
				width: '463px',
			},
			{
				text: 'Nr postępowania',
				value: 'nrTenders',
				class: 'central__table__header',
				width: '275px',
			},
			{
				text: 'Odpowiedzialny',
				value: 'responsibleTenders',
				class: 'central__table__header',
				width: '250px',
			},
			{
				text: 'Termin składania ofert',
				value: 'dataTenders',
				class: 'central__table__header',
				width: '207px',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '102px',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},

	clientItems: [],
	clientRepresentative: [],
	modal: {
		fields: {
			nrTenders: '',
			dataTenders: '',
			timeTenders: '',
			linkTenders: '',
			commentTenders: '',
		},
		errors: {
			nrTenders: null,
			contractorTenders: null,
			commentTenders: null,
		},
	},
	details: {
		nrTenders: '',
		dataTenders: '',
		timeTenders: '',
		linkTenders: '',
		contractorTenders: '',
		commentTenders: '',
		repurchaseTenders: '',
	},
	fieldsNames: {
		nrTenders: 'Numer postępowania',
		dataTenders: 'Termin składania ofert',
		timeTenders: 'Czas',
		linkTenders: 'Link do postępowania',
		contractorTenders: 'Kontrahent',
		responsibleTenders: 'Osoba odpowiedzialna po stronie Kontrahenta',
		commentTenders: 'Opis / Uwagi',
		responsiblenewnameTenders: 'Imię reprezentanta',
		responsiblenewlastTenders: 'Nazwisko reprezentanta',
		responsiblenewephoneTenders: 'Numet telefonu',
		responsiblenewemailTenders: 'E-mail',
	},
};

const getters = {
	getTenders: (state) => state.Tenders,
	getModalTenders: (state) => state.modal.fields,
	getTendersDetails: (state) => state.details,
	getTendersFieldsNames: (state) => state.fieldsNames,
	getTendersItems: (state) => state.clientItems,
	getTendersRepresentatives: (state) => state.clientRepresentative,
	getTendersErrors: (state) => state.modal.errors,

	//getCarsDictionaryItems: (state) => state.Cars,
};

const actions = {
	createTenders({ dispatch, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/tenders',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'tenders',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano nowy przetarg',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'tenders',
					err: error.response.data,
				});
			});
	},

	tendersDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/tenders/${payload.id}`,
		}).then((response) => {
			if (payload.type == 'modal') {
				commit('setTendersModal', response.data.item);
			} else {
				commit('setTendersDeatils', response.data.item);
				commit('setDetailsIndex', response.data.item.index);
				commit('setDetailsMenager', response.data.item.menager);
				commit('setDetailsStatus', response.data.item.status);
				commit('setDetailsStatuses', response.data.statuses);
				if (payload.next) {
					payload.next();
				}
			}
		});
	},

	fetchContacts({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/contacts/search/${payload}`,
		})
			.then((response) => {
				commit('setClientItems', response.data.items);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	updateTenders({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/tenders/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setTendersDeatils', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie zmodyfikowano przetarg',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
};

const mutations = {
	resetTendersItems() {
		state.clientItems = [];
		state.clientRepresentative = [];
	},
	setTendersDeatils(state, payload) {
		state.details = payload;
	},
	setTendersModal(state, payload) {
		state.modal.fields = payload;
		state.clientItems = [payload.contractorTenders];
		state.clientRepresentative = payload.contractorTenders.representatives;
	},
	setClientItems(state, payload) {
		if (payload[0] != null) {
			state.clientItems = payload;
		} else {
			state.clientItems = [];
		}
	},
	setClientThings(state, payload) {
		state.clientRepresentative = payload.representatives;
	},

	changePassword(state) {
		state.password = true;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
