<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="createContact">
                <v-icon small>mdi-account-plus</v-icon>
            </v-btn>
        </template>
        <span>Utwórz nowy kontakt</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"])
    },
    methods: {
        ...mapActions(["openModal"]),
        createContact() {
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 600,
                type: "create",
                route: "contacts",
                tab: ""
            });
        }
    }
};
</script>

