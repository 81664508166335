import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
	serviceCore: {
		serviceCoreName: '',
		dictionary: 'ServiceCore',
		open: false,
	},
	type: '',
	errors: {
		serviceCoreName: '',
	},
	items: [],
};

const getters = {
	getServiceCore: state => state.serviceCore,
	getServiceCoreType: state => state.type,
	getServiceCoreErrors: state => state.errors,
	getServiceCoreItems: state => state.items,
};

const actions = {
	createServiceCore({ commit }) {
		state.errors.serviceCoreName = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/service/core',
			data: {
				...state.serviceCore,
			},
		})
			.then(response => {
				
				commit('clearServiceCore');
				commit('setServiceCoreItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano dany wpis',
				});
			})
			.catch(error => {
				console.log(error.response.data);
				if (error.response.data.msg === 'Exists') {
					state.errors.serviceCoreName = 'Nazwa jest już używana';
				} else {
					if (
						error.response.data.error.errors.serviceCoreName &&
						error.response.data.error.errors.serviceCoreName
							.kind === 'required'
					)
						state.errors.serviceCoreName = 'Pole wymagane';
				}
			});
	},

	fetchServiceCore({ commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/service/core',
		}).then(response => {
			commit('setServiceCoreItems', response.data.items);
		});
	},

	ServiceCoreDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/service/core/${payload}`,
		}).then(response => {
			commit('setServiceCoreDetails', response.data.item);
		});
	},

	deleteServiceCore({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/service/core/delete/${payload}`,
		})
			.then(() => {
				commit('deleteItem', payload);
				commit('closeSecondModal');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	ServiceCoreUpdate({ commit }) {
		state.errors.serviceCoreName = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/service/core/update/${state.serviceCore._id}`,
			data: {
				...state.serviceCore,
			},
		})
			.then(response => {
				commit('clearServiceCore');
				commit('updateServiceCoreItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.error === 'Exists')
					state.errors.serviceCoreName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.serviceCoreName = 'Pole jest wymagane';
				}
			});
	},
};
const mutations = {
	setServiceCoreItems(state, payload) {
		state.items = payload;
	},
	setServiceCoreItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	deleteItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setServiceCoreDetails(state, payload) {
		state.serviceCore.serviceCoreName = payload.serviceCoreName;
		state.serviceCore._id = payload._id;
		state.serviceCore.open = true;
	},
	clearServiceCore(state) {
		state.serviceCore.open = false;
		state.serviceCore.serviceCoreName = '';
		state.errors.serviceCoreName = '';
	},
	setServiceCoreType(state, payload) {
		state.type = payload;
	},
	updateServiceCoreItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
