<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === NAZWA SKRÓCONA === ===  -->
            <td>{{item.shortName}}</td>

            <!-- === === PEŁNA NAZWA === ===  -->
            <td>{{item.fullName}}</td>

            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn v-if="getPermission.level > 1" icon small @click="details(item._id)">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    methods: {
        ...mapActions(["fetchItems", "departmentDetails"]),
        details(id) {
            this.fetchItems("departments");
            this.fetchItems("users");
            this.departmentDetails(id);
            this.$store.dispatch("openModal", {
                headLine: "global.edit",
                open: true,
                type: "edit",
                width: 800,
                route: this.$route.name,
            });
        },
    },
    computed: {
        ...mapGetters(["getPermission"]),
    },
};
</script>