import Vue from "vue";
import findIndex from "lodash.findindex";
import moment from "moment-timezone";
const state = {
  service: {
    title: "Serwis",
    header: [{
        text: "Index",
        align: "left",
        width: "7%",
        value: "index",
        class: "central__table__header",
      },
      {
        text: "Data utworzenia",
        value: "createdAt",
        width: "10%",
        class: "central__table__header hidedMobileColumns",
      },
      {
        text: "Klient",
        value: "clientName",
        width: "25%",
        class: "central__table__header",
      },
      {
        text: "Urządzenie",
        width: "15%",
        value: "modelDevices",
        class: "central__table__header",
      },
      {
        text: "Technik",
        value: "technicPerforming",
        width: "12%",
        class: "central__table__header hidedMobileColumns",
      },
      {
        text: "Status",
        value: "status",
        width: "6%",
        class: "central__table__header",
      },
      {
        text: "Terminy",
        value: "startDate",
        width: "10%",
        class: "central__table__header",
      },
      {
        value: "invoice",
        width: "8%",
        class: "central__table__header",
      },
      {
        text: "Akcja",
        align: "right",
        width: "5%",
        value: "action",
        sortable: false,
        class: "central__table__header",
      },
    ],
  },
  signature: {
    modal: false,
    img: null,
  },
  serviceParts: [],
  claim: {
    number: "",
  },
  invoice: {
    number: "",
    date: "",
  },
  orderType: {},
  ordersDay: {
    day: "",
  },
  modal: {
    technicItems: [],
    orders: [],
    isTechnics: null,
    isLogistics: null,
    lastInterventions: [],
    fields: {
      device: {
        _id: "",
        client: {
          representatives: [],
          _id: "",
          houseNumber: "",
          apartmentNumber: "",
          email: "",
          postCode: "",
          phoneNumber: "",
          city: "",
          floor: "",
          street: "",
          name: "",
        },

        address: {
          address: "",
          postCode: "",
          email: "",
          city: "",
          location: "",
          name: "",
          phoneNumber: "",
        },
        floor: "",
        dedicatedPrice: {
          priceName: "",
          survey: {
            price: "",
          },
          repair: {
            price: "",
          },
          installation: {
            price: "",
          },
          diagnostics: {
            price: "",
          },
          itService: {
            price: "",
          },
        },
        serviceBase: "",
        modelDevices: {
          model: null,
          series: null,
          producer: null,
          id: null,
          deviceType: null,
          deviceType: "",
          price: {
            priceName: "",
            survey: {
              price: "",
            },
            repair: {
              price: "",
            },
            installation: {
              price: "",
            },
            diagnostics: {
              price: "",
            },
            itService: {
              price: "",
            },
          },
        },
        priority: "",
        isExist: "",
        warrantyTo: "",
        warrantyToParts: "",
        SN: "",
      },

      index: "",
      service: {
        lastServiceDetails: null,
        temporaryInformation: {
          devices: "",
          client: "",
        },
        descriptionIntervention: {
          descriptionClient: "",
          descriptionLogistic: "",
          representatives: [],
          intervention: "",
          technic: {
            name: "",
            _id: "",
            lastname: "",
          },
          technicPerforming: {
            name: "",
            _id: "",
            lastname: "",
          },
          data: null,
          time: null,
          finishDate: null,
          finishTime: null,
        },
        realisation: {
          survey: false,
          repair: false,
          installation: false,
          diagnostics: false,
          itService: false,
          firstInstallation: false,
          repairWorkshop: false,
          remoteService: false,
          continuation: false,
          complaint: false,
          continuationIssued: false,
          urgent: false,
          costInformation: false,
        },
      },
      status: null,
    },
    searchedDevices: [],
    branchAddress: {},
    errors: {
      name: "",
      description: "",
    },
    searchedParts: [],
    partsItems: [],
    parts: {
      invoiceNumber: "",
      description: "",
      inside: {
        date: "",
        number: "",
      },
      outside: {
        date: "",
        number: "",
      },
    },
    partsDetails: {
      partsItems: [],
      invoiceNumber: "",
      description: "",
      totalSum: 0,
      inside: {
        date: "",
        number: "",
      },
      outside: {
        date: "",
        number: "",
      },
    },
    implementation: {
      cars: [],
      users: [],
      arrangements: {
        continuation: false,
        itService: false,
        repairWorkshop: false,
        remoteService: false,
      },
      service: {
        price: {
          dedicated: {},
          normal: {},
        },
        dates: {
          start: "",
          end: "",
        },
      },
      fields: {
        service: {
          dates: {
            start: "",
            end: "",
          },
          times: {
            start: "",
            end: "",
          },
        },
        technic: {
          name: "",
          lastname: "",
          _id: "",
        },
        plate: "",
        survey: false,
        job: {
          dedicated: false,
          repair: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          installation: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          diagnostics: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          itService: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          survey: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
        },
        cost: {
          normal: 0,
          special: 0,
        },
        meter: {
          mono: {
            start: 0,
            end: 0,
          },
          scan: 0,
          color: {
            start: 0,
            end: 0,
          },
          car: {
            start: 0,
            end: 0,
            cost: 0,
            sum: 0,
          },
        },
        clearance: {
          documentNumber: "",
          partValue: "",
          technic: {
            name: "",
            lastname: "",
            _id: "",
          },
          inside: {
            date: "",
            number: "",
          },
          outside: {
            date: "",
            number: "",
          },
        },
        requirements: {
          continuation: false,
          itService: false,
          claim: {
            checkbox: false,
          },
          valuation: {
            checkbox: false,
          },
          replacement: false,
          invoice: {
            checkbox: false,
          },
        },
        comment: {
          repairWorkshop: false,
          remoteService: false,
          irrational: false,
          machineWorks: false,
          vat: false,
        },
        description: {
          doneJob: "",
          nextIntervention: "",
        },
      },
      details: {
        technic: {
          name: "",
          lastname: "",
          _id: "",
        },
        plate: "",
        survey: false,
        job: {
          dedicated: false,
          repair: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          installation: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          diagnostics: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          itService: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          survey: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
        },
        cost: {
          normal: 0,
          special: 0,
        },
        meter: {
          mono: {
            start: 0,
            end: 0,
          },
          scan: 0,
          color: {
            start: 0,
            end: 0,
          },
          car: {
            start: 0,
            end: 0,
            sum: 0,
            cost: 0,
          },
        },
        clearance: {
          documentNumber: "",
          partValue: "",
          technic: {
            name: "",
            lastname: "",
            _id: "",
          },
          inside: {
            date: "",
            number: "",
          },
          outside: {
            date: "",
            number: "",
          },
        },
        requirements: {
          continuation: false,
          itService: false,
          claim: {
            checkbox: false,
          },
          replacement: false,
          invoice: {
            checkbox: false,
          },
          valuation: {
            checkbox: false,
          },
        },
        comment: {
          repairWorkshop: false,
          remoteService: false,
          irrational: false,
          machineWorks: false,
          vat: false,
        },
        description: {
          doneJob: "",
          nextIntervention: "",
        },
      },
      errors: {
        mono: "",
        color: "",
        car: "",
      },
    },
  },
  details: {
    modal: {
      fields: {
        device: {
          _id: "",
          client: {
            representatives: [],
            houseNumber: "",
            apartmentNumber: "",
            email: "",
            postCode: "",
            phoneNumber: "",
            city: "",
            floor: "",
            street: "",
            name: "",
          },
          serviceBase: "",
          dedicatedPrice: {
            checkbox: null,
            diagnostics: null,
            installation: null,
            itService: null,
            priceName: null,
            repair: null,
            survey: null,
          },
          modelDevices: {
            model: null,
            series: null,
            producer: null,
            deviceType: null,
            deviceType: "",
            price: {
              priceName: "",
              survey: {
                price: "",
              },
              repair: {
                price: "",
              },
              installation: {
                price: "",
              },
              diagnostics: {
                price: "",
              },
              itService: {
                price: "",
              },
            },
          },
          priority: "",
          isExist: "",
          warrantyTo: "",
          warrantyToParts: "",
        },
        index: "",
        service: {
          temporaryInformation: {
            devices: "",
            client: "",
          },
          descriptionIntervention: {
            representatives: {
              _id: "",
              name: "",
              lastname: "",
              phoneNumber: "",
              email: "",
            },
            descriptionClient: "",
            descriptionLogistic: "",
            intervention: "",
            technic: "",
            technicPerforming: "",
            plate: "",
            data: null,
            time: null,
          },
          realisation: {
            survey: false,
            repair: false,
            installation: false,
            diagnostics: false,
            itService: false,
            firstInstallation: false,
            repairWorkshop: false,
            remoteService: false,
            continuation: false,
            warranty: false,
            continuationIssued: false,
            urgent: false,
            costInformation: false,
          },
        },
        status: null,
        menager: "",
        index: "",
      },
      errors: {
        service: {
          descriptionIntervention: {
            descriptionClient: "",
          },
        },
      },
    },
    implementation: {
      service: {
        dates: {},
      },
    },
  },
};

const getters = {
  getClaim: (state) => state.claim,
  getInvoice: (state) => state.invoice,
  // getLastService: (state) => state.lastService,
  getModalServiceError: (state) => state.modal.errors,
  getIsLogistic: (state) => state.isLogistics,
  getServiceOrderType: (state) => state.orderType,
  getSignature: (state) => state.signature,
  getIsTechnics: (state) => state.isTechnics,
  getOrders: (state) => state.modal.orders,
  getOrdersDay: (state) => state.ordersDay,
  getServiceData: (state) => state.modal.fields,
  getSearchedDevicesItems: (state) => state.modal.searchedDevices,
  getBranchAddress: (state) => state.modal.branchAddress,
  getService: (state) => state.service,
  getSearchedPartsItems: (state) => state.modal.searchedParts,
  getPartsItems: (state) => state.modal.partsItems,
  getServicePartsModal: (state) => state.modal.parts,
  getserviceParts: (state) => state.serviceParts,
  getServicePartsDetails: (state) => state.modal.partsDetails,
  getServiceImplement: (state) => state.modal.implementation,
  getServiceDetails: (state) => state.details,
  getTechnicsItems: (state) => state.modal.technicItems,
  getImplementationErrors: (state) => state.modal.implementation.errors,
  getImplementationArrangements: (state) =>
    state.modal.implementation.arrangements,
  getLastServiceDetails: (state) =>
    state.modal.fields.service.lastServiceDetails,
  getLastInterventions: (state) => state.modal.lastInterventions,
};

const actions = {
  findModel({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/findModel/${payload.model}`,
      })
      .then((response) => {
        commit("setIdModel", response.data.items._id);

        // let routeData = payload.router.resolve({
        // 	name: 'models',
        // });
        // window.open(
        // 	routeData.href + '/' + response.data.items._id,
        // 	'_blank',
        // );
      })
      .catch((error) => {
        console.log(error);
      });
  },

  serviceSN({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/order/${payload.id}`,
      })
      .then((response) => {
        if (response.data.representatives.length > 0) {
          commit("setSnItems", response.data.item.device);
        }
      })
      .catch((error) => {});
  },

  async searchDevice({
    commit
  }, payload) {
    await Vue.axios({
        method: "POST",
        url: `/service/device/search/${payload.SN}`,
        data: payload,
      })
      .then((response) => {
        if (payload.reg == "devices") {
          commit("setSearchedInDevices", response.data.items);
          commit("setLastServiceDetails", response.data.lastService);
        } else {
          commit("setSearchedDevices", response.data.items);
          commit("setLastServiceDetails", response.data.lastService);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  findTechnics({
    commit
  }) {
    Vue.axios({
        method: "GET",
        url: "/service/implementation/serviceman",
      })
      .then((response) => {
        commit("setTechnicsItems", response.data.items.users);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  searchPart({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/part/search/${payload}`,
      })
      .then((response) => {
        commit("setSearchedParts", response.data.items);
      })
      .catch((error) => {});
  },
  editServiceParts({
    commit
  }, payload) {
    Vue.axios({
        method: "POST",
        url: `/service/parts/edit`,
        data: {
          partsItems: state.modal.partsItems,
          ...state.modal.parts,
          registryID: payload,
        },
      })
      .then((response) => {
        commit("setServicePartsDetails", response.data.item);
        commit("closeModal");
      })
      .catch((error) => {
        console.log(error);
      });
  },

  sendInvoiceStatus({
    commit
  }, payload) {
    Vue.axios({
        method: "POST",
        url: `/service/invoiceStatus/${payload}`,
        data: {
          id: payload,
          ...state.invoice,
        },
      })
      .then((response) => {
        commit("closeSecondModal");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendClaimStatus({
    state,
    commit
  }, payload) {
    Vue.axios({
        method: "POST",
        url: `/service/claimStatus/${payload}`,
        data: {
          id: payload,
          ...state.claim,
        },
      })
      .then((response) => {
        commit("closeSecondModal");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendValuationStatus({
    commit
  }, payload) {
    Vue.axios({
        method: "POST",
        url: `/service/valuationStatus/${payload}`,
        data: {
          id: payload,
        },
      })
      .then((response) => {
        commit("closeSecondModal");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendNewContinuation({
    commit,
    dispatch
  }, payload) {
    Vue.axios({
        method: "POST",
        url: "/service",
        data: {
          ...payload,
        },
      })
      .then((response) => {
        state.details.service.realisation.continuationIssued = true;
        // commit('setServisDetails', response.data.item);
        dispatch("fetchData", {
          pageSize: 11,
          search: "",
          sortBy: [],
          sortDesc: [],
          routeName: "service",
        });
        commit("closeSecondModal");
      })
      .catch((error) => {
        console.log(error);
      });
  },

  editServiceImplementation({
    commit
  }, payload) {
    const mono = state.modal.implementation.fields.meter.mono;
    const color = state.modal.implementation.fields.meter.color;
    const car = state.modal.implementation.fields.meter.car;
    state.modal.implementation.errors = {
      mono: "",
      color: "",
      car: "",
    };
    if (mono.start > 0 && mono.end > 0 && mono.end - mono.start < 0)
      state.modal.implementation.errors.mono = "Złe dane";
    else if (color.start > 0 && color.end > 0 && color.end - color.start < 0) {
      state.modal.implementation.errors.color = "Złe dane";
    } else if (car.start > 0 && car.end > 0 && car.sum < 0) {
      state.modal.implementation.errors.car = "Złe dane";
    } else {
      Vue.axios({
          method: "POST",
          url: "/service/implementation",
          data: {
            ...state.modal.implementation.fields,
            registryID: payload,
          },
        })
        .then((response) => {
          // status wyświetlić

          commit("setServiceImplementationDetails", response.data.item);
          if (response.data.newStatus) {
            commit("setDetailsStatus", response.data.item.status);
            commit("setDetailsStatus", response.data.newStatus);
          }
          // else {
          // commit('setDetailsStatus', response.data.item.status);
          // }
          commit("closeModal");
          commit("setSnackbars", {
            type: "success",
            text: "Pomyślnie edytowano realizacje usługi",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  getServiceImplementation({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/implementation/${payload.id}`,
      })
      .then((response) => {
        if (payload.type == "details") {
          commit("setServiceImplementationDetails", response.data.item);
        }
        if (payload.type == "modal") {
          commit("setServiceImplementationModal", response.data.item);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getServiceParts({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/parts/${payload.id}`,
      })
      .then((response) => {
        if (payload.type == "details") {
          commit("setServicePartsDetails", response.data.item);
        } else if (payload.type == "modal") {
          commit("setServicePartsModal", response.data.item);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  createService({
    commit,
    dispatch
  }, payload) {
    if (!state.modal.fields.service.descriptionIntervention.descriptionClient) {
      state.modal.errors.description = "Proszę uzupełnić pole";
    } else {
      Vue.axios({
          method: "POST",
          url: "/service",
          data: {
            ...state.modal.fields,
            ...payload,
          },
        })
        .then((response) => {
          dispatch("fetchData", {
            page: payload.page,
            pageSize: 11,
            search: "",
            sortBy: [],
            sortDesc: [],
            routeName: "service",
          });
          commit("closeModal");
          commit("setSnackbars", {
            type: "success",
            text: "Pomyślnie utworzono zlecenie",
          });
        })
        .catch((error) => {
          console.log(error);
          commit("setErrors", {
            route: "service",
            err: error.response.data.error,
          });
        });
    }
  },

  technicOrder({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/implementation/serviceman`,
      })
      .then((response) => {
        commit("setTechnicsItems", response.data.items.users);
      })
      .catch((error) => {
        console.log(error);
        commit("setErrors", {
          route: "service",
          err: error.response.data.error,
        });
      });
  },
  orderToday({
    commit
  }, payload) {
    Vue.axios({
      method: "POST",
      url: `/service/orderToday`,
      data: payload,
    }).then((response) => {
      commit("setOrder", response.data.items);
      commit("showDay", response.data.showDay);
    });
    // .catch(error => {
    // 	commit('setErrors', {
    // 		route: 'service',
    // 		err: error.response.data.error,
    // 	});
    // });
  },

  serviceOrderDeatils({
    commit
  }, payload) {
    Vue.axios({
        method: "GET",
        url: `/service/order/${payload.id}`,
      })
      .then((response) => {
        if (payload.type == "modal") {
          // if (response.data.representatives.length === 0) {
          // 	commit('clearSN');
          // }          
          commit("setSnItems", response.data.item.device.SN);
          commit("setServiceModal", response.data.item);
          // commit('setServisDetails', response.data.item);
          commit("setLastServiceDetails", response.data.lastService);
          commit("setRepresentatives", response.data);
        } else if (payload.type == "modal_implementation") {
          commit("setImplementationService", response.data.item);
        } else {
          commit("setDetailsIndex", response.data.item.index);
          commit("setDetailsMenager", response.data.item.menager);
          commit("setServisDetails", response.data.item);
          commit("setDetailsStatus", response.data.item.status);
          commit("setDetailsStatuses", response.data.statuses);
          commit("setDetailsParts", response.data.parts);
          commit("setLastServiceDetails", response.data.lastService);
          if (payload.next) {
            payload.next();
          }
          commit("setCheckPermissionStatus", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        // if (error.response.status == '404') router.push('/NotFound');
      });
  },

  fetchServiceMen({
    commit
  }) {
    Vue.axios({
        method: "GET",
        url: "/service/implementation/serviceman",
      })
      .then((response) => {
        commit("setServicemanAndCard", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  serviceEdit({
    state,
    commit
  }, payload) {
    commit("resetErrors", "service");

    Vue.axios({
        method: "PUT",
        url: `/service/${state.modal.fields._id}`,
        data: {
          ...state.modal.fields,
        },
      })
      .then((response) => {
        commit("setServisDetails", response.data.item);
        commit("setDetailsStatus", response.data.item.status);
        commit("closeModal");
        commit("setSnackbars", {
          type: "success",
          text: "Pomyślnie edytowano wpis",
        });
      })
      .catch((error) => {
        console.log(error);
        commit("setErrors", {
          route: "service",
          err: error.response.data.error,
        });
      });
  },
  lastInterventions({
    state,
    commit
  }, payload) {
    Vue.axios({
      method: "GET",
      url: `/service/interventions/${payload}/3`,
    }).then((response) => {
      commit("setLastInterventions", response.data.items);
    });
  },

  // lastInterventionsToOrder({
  //     state,
  //     commit
  // }, payload) {
  //     Vue.axios({
  //         method: 'GET',
  //         url: `/service/interventionsToOrder/${payload.orderIndex.id}`,
  //         // data: payload
  //     }).then((response) => {
  //         commit('setLastInterventionsToOder', response.data.lastService);
  //     });
  // },

  serviceEditInTable({
    state,
    commit,
    dispatch
  }, payload) {
    let data = {};
    if (payload.technic) {
      data = {
        technic: payload.technic,
      };
    } else if (payload.finishDate) {
      data = {
        finishDate: payload.finishDate,
      };
    } else if (payload.finishTime) {
      data = {
        finishTime: payload.finishTime,
      };
    }
    Vue.axios({
        method: "PUT",
        url: `/service/${payload.id}`,
        data: data,
      })
      .then((response) => {
        dispatch("fetchData", {
          page: payload.page,
          pageSize: 11,
          search: "",
          sortBy: [],
          sortDesc: [],
          routeName: "service",
        });
        commit("setSnackbars", {
          type: "success",
          text: "Pomyślnie zmieniono dane",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addNewSN({
    commit
  }, payload) {
    Vue.axios({
        method: "POST",
        url: `/service/newSN/${payload.SN}`,
        data: payload,
      })
      .then((response) => {
        console.log(response);
        commit("setAssignmentSnOrder", response.data);
        // commit("setLastServiceDetails", response.data.findOrder.lastIntervention);
        // if (response.data.representatives.length > 0) {
        //     commit('setSnItems', response.data.item.device);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

const mutations = {
  setLastServiceDetails(state, payload) {
    state.modal.fields.service.lastServiceDetails = payload;
    console.log(state.modal.fields.service.lastServiceDetails);
  },

  setOrderType(state, payload) {
    state.orderType = payload;
  },
  setDetailsParts(state, payload) {
    state.serviceParts = payload;
  },
  // setLastInterventionsToOder(state, payload) {
  //     state.lastService = [...payload];
  // },
  printOrder(state, payload) {
    state.signature.modal = payload;
  },
  // do poprawy interwencja wyjściowa
  setLastInterventions(state, payload) {
    state.modal.lastInterventions = payload;
  },
  setCheckPermissionStatus(state, payload) {
    state.isTechnics = payload.isTechnics;
    state.isLogistics = payload.isLogistics;
  },
  // representatnives(state, payload) {

  // 	state.modal.fields.device.client.representatives = payload;
  // },
  clearSN(state) {
    state.modal.searchedDevices = [];
  },
  setRepresentatives(state, payload) {
    state.modal.fields.device.client = payload.representatives.client;
  },
  setServiceModal(state, payload) {
    console.log(payload.service.descriptionIntervention.data);
    state.modal.fields = payload;
    state.modal.fields.service.descriptionIntervention.data = payload.service
      .descriptionIntervention.data ?
      moment(payload.service.descriptionIntervention.data).format(
        "YYYY-MM-DD"
      ) :
      "";
    state.modal.fields.service.descriptionIntervention.finishDate = payload
      .service.descriptionIntervention.finishDate ?
      moment(payload.service.descriptionIntervention.finishDate).format(
        "YYYY-MM-DD"
      ) :
      "";
    state.modal.branchAddress = payload.device.address;
  },
  setSnItems(state, payload) {
    state.modal.searchedDevices = [payload];
  },
  setSearchedDevices(state, payload) {
    state.modal.searchedDevices = payload;
    state.modal.branchAddress = payload.address;
  },
  setSearchedInDevices(state, payload) {
    state.modal.searchedDevices = payload;
    state.modal.fields.device.modelDevices = payload[0].modelDevices;
    state.modal.fields.device = payload[0];
  },

  setSearchedParts(state, payload) {
    state.modal.searchedParts = payload.filter(
      ({
        producent: id1
      }) =>
      !state.modal.partsItems.some(({
        producent: id2
      }) => id2 === id1)
    );
  },
  setTableParts(state, payload) {
    let arr = state.modal.searchedParts;
    let index = findIndex(arr, (e) => e.producent == payload.producent);

    arr.splice(index, 1);
    payload.quantity = 1;
    payload.sum = payload.price;
    state.modal.partsItems = [payload, ...state.modal.partsItems];
  },
  removeTablePart(state, payload) {
    let arr = state.modal.partsItems;
    let index = findIndex(arr, (e) => e.producent == payload.producent);
    arr.splice(index, 1);
  },
  setPartInfo(state, payload) {
    let arr = state.modal.partsItems;
    let index = findIndex(arr, (e) => e._id == payload.id);
    arr[index].price = payload.price;
    arr[index].quantity = payload.quantity;
    arr[index].sum = payload.quantity * payload.price;
  },
  setServisDetails(state, payload) {
    state.modal.branchAddress = payload.device.address;
    state.details = payload;
    state.details.service.descriptionIntervention.data = payload.service
      .descriptionIntervention.data ?
      moment(payload.service.descriptionIntervention.data).format(
        "YYYY-MM-DD"
      ) :
      "";
  },

  setServicePartsDetails(state, payload) {
    state.modal.partsDetails = [];
    if (payload) {
      state.modal.partsDetails = payload;
    }
  },
  setServicePartsModal(state, payload) {
    if (payload) {
      if (payload.partsItems) state.modal.partsItems = payload.partsItems;
      else state.modal.partsItems = [];
      state.modal.parts = payload;
      if (state.modal.parts.partsItems) delete state.modal.parts.partsItems;
      if (payload.inside & (payload.inside.date != null)) {
        state.modal.parts.inside.date = moment(payload.inside.date).format(
          "YYYY-MM-DD"
        );
      }
      if (payload.outside && payload.outside.date != null) {
        state.modal.parts.outside.date = moment(payload.outside.date).format(
          "YYYY-MM-DD"
        );
      }
    } else {
      state.modal.parts.inside.date = "";
      state.modal.parts.outside.date = "";
      state.modal.partsItems = [];
      state.modal.searchedParts = [];
    }
  },
  resetServiceParts(state, payload) {
    state.modal.parts.inside.date = "";
    state.modal.parts.outside.date = "";
    state.modal.partsItems = [];
    state.modal.searchedParts = [];
  },
  setServicemanAndCard(state, payload) {
    state.modal.implementation.cars = payload.cars;
    state.modal.implementation.users = payload.items.users;
  },
  setPlate(state, payload) {
    let found = state.modal.implementation.cars.find(
      (x) => x.driver == payload._id
    );

    if (found && found.plate)
      state.modal.implementation.fields.plate = found.plate;
  },
  setImplementationService(state, payload) {
    let realisation = payload.service.realisation;
    state.modal.implementation.service.price.dedicated =
      payload.device.dedicatedPrice;
    state.modal.implementation.service.price.normal =
      payload.device.modelDevices.price;

    state.modal.implementation.arrangements.continuation =
      realisation.continuation;
    state.modal.implementation.arrangements.itService = realisation.itService;
    state.modal.implementation.arrangements.repairWorkshop =
      realisation.repairWorkshop;
    state.modal.implementation.arrangements.remoteService =
      realisation.remoteService;

    state.modal.implementation.fields.job.dedicated =
      payload.device.dedicatedPrice.checkbox;

    if (
      payload.service.descriptionIntervention.technicPerforming &&
      state.modal.implementation.fields.technic.name == ""
    ) {
      state.modal.implementation.fields.technic =
        payload.service.descriptionIntervention.technicPerforming;
    }
  },
  jobDec(state, payload) {
    let number = 0.5;
    state.modal.implementation.fields.job[payload].time -= number;
    if (state.modal.implementation.fields.job[payload].time <= 0) {
      state.modal.implementation.fields.job[payload].price.normal = 0;
      state.modal.implementation.fields.job[payload].price.special = 0;
      state.modal.implementation.fields.job[payload].time = 0;
    } else {
      let costNormal =
        parseFloat(state.modal.implementation.fields.job[payload].time) *
        parseFloat(
          state.modal.implementation.service.price.normal[payload].price
        );
      state.modal.implementation.fields.job[payload].price.normal =
        Math.round((costNormal + Number.EPSILON) * 100) / 100;

      if (state.modal.implementation.service.price.dedicated) {
        let costDedicated =
          parseFloat(state.modal.implementation.fields.job[payload].time) *
          parseFloat(
            state.modal.implementation.service.price.dedicated[payload]
          );
        state.modal.implementation.fields.job[payload].price.special =
          Math.round((costDedicated + Number.EPSILON) * 100) / 100;
      } else {
        state.modal.implementation.fields.job[payload].price.special = 0;
      }
      state.modal.implementation.fields.job[payload].time = Number(
        state.modal.implementation.fields.job[payload].time.toFixed(1)
      );
    }
  },
  jobInc(state, payload) {
    let number = 0.5;
    state.modal.implementation.fields.job[payload].time += number;

    let costNormal =
      parseFloat(state.modal.implementation.fields.job[payload].time) *
      parseFloat(
        state.modal.implementation.service.price.normal[payload].price
      );
    state.modal.implementation.fields.job[payload].price.normal =
      Math.round((costNormal + Number.EPSILON) * 100) / 100;

    if (state.modal.implementation.service.price.dedicated) {
      let costDedicated =
        parseFloat(state.modal.implementation.fields.job[payload].time) *
        parseFloat(state.modal.implementation.service.price.dedicated[payload]);
      state.modal.implementation.fields.job[payload].price.special =
        Math.round((costDedicated + Number.EPSILON) * 100) / 100;
    } else {
      state.modal.implementation.fields.job[payload].price.special = 0;
    }

    return (state.modal.implementation.fields.job[payload].time = Number(
      state.modal.implementation.fields.job[payload].time.toFixed(1)
    ));
  },
  setNormalCost(state) {
    let cost = 0;
    const job = state.modal.implementation.fields.job;
    const price = state.modal.implementation.service.price.normal;
    cost =
      job.survey.time * price.survey.price +
      job.repair.time * price.repair.price +
      job.installation.time * price.installation.price +
      job.diagnostics.time * price.diagnostics.price +
      job.itService.time * price.itService.price;
    state.modal.implementation.fields.cost.normal =
      Math.round((cost + Number.EPSILON) * 100) / 100;
  },

  setSpecialCost(state) {
    let cost = 0;
    const job = state.modal.implementation.fields.job;
    const price = state.modal.implementation.service.price.dedicated;
    if (price.checkbox) {
      cost =
        job.survey.time * parseFloat(price.survey) +
        job.repair.time * parseFloat(price.repair) +
        job.installation.time * parseFloat(price.installation) +
        job.diagnostics.time * parseFloat(price.diagnostics) +
        job.itService.time * parseFloat(price.itService);

      state.modal.implementation.fields.cost.special =
        Math.round((cost + Number.EPSILON) * 100) / 100;
    }
  },

  setCarSum(state, payload) {
    const car = state.modal.implementation.fields.meter.car;
    if (car.start > 0 && car.end > 0 && car.end - car.start >= 0)
      car.sum = car.end - car.start;
    car.cost = car.sum * 1.1;
  },

  setServiceImplementationDetails(state, payload) {
    if (payload) {
      state.modal.implementation.details = payload;
      // state.details.modal.fields.service.descriptionIntervention.plate = payload.plate
      // state.details.implementation.service.dates = payload.service.dates
    } else {
      state.modal.implementation.details = {
        technic: {
          name: "",
          lastname: "",
          _id: "",
        },
        plate: "",
        survey: false,
        job: {
          dedicated: false,
          repair: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          installation: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          diagnostics: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          itService: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          survey: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
        },
        cost: {
          normal: 0,
          special: 0,
        },
        meter: {
          mono: {
            start: 0,
            end: 0,
          },
          scan: 0,
          color: {
            start: 0,
            end: 0,
          },
          car: {
            start: 0,
            end: 0,
            cost: 0,
            sum: 0,
          },
        },
        clearance: {
          documentNumber: "",
          partValue: "",
          technic: {
            name: "",
            lastname: "",
            _id: "",
          },
          inside: {
            date: "",
            number: "",
          },
          outside: {
            date: "",
            number: "",
          },
        },
        requirements: {
          continuation: false,
          itService: false,
          claim: {
            checkbox: false,
          },
          valuation: {
            checkbox: false,
          },
          replacement: false,
          invoice: {
            checkbox: false,
          },
        },
        comment: {
          repairWorkshop: false,
          remoteService: false,
          irrational: false,
          machineWorks: false,
          vat: false,
        },
        description: {
          doneJob: "",
          nextIntervention: "",
        },
      };
    }
  },
  ResetSearch(state) {
    state.modal.searchedDevices = [];
    state.modal.fields.device = {};
    state.details = {};
    state.modal.branchAddress = {};
    state.modal.lastInterventions = [];
  },

  setIdModel(state, payload) {
    state.details.device.modelDevices.id = payload;
  },

  setServiceImplementationModal(state, payload) {
    if (payload) {
      state.modal.implementation.fields = payload;
      state.modal.implementation.fields.service.dates.start = payload.service
        .dates.start ?
        moment(payload.service.dates.start).format("YYYY-MM-DD") :
        "";
      state.modal.implementation.fields.service.dates.end = payload.service
        .dates.end ?
        moment(payload.service.dates.end).format("YYYY-MM-DD") :
        "";
      state.modal.implementation.fields = payload;
      state.modal.implementation.fields.clearance.outside.date = payload
        .clearance.outside.date ?
        moment(payload.clearance.outside.date).format("YYYY-MM-DD") :
        "";
      state.modal.implementation.fields.clearance.inside.date = payload
        .clearance.inside.date ?
        moment(payload.clearance.inside.date).format("YYYY-MM-DD") :
        "";
    } else {
      state.modal.implementation.fields = {
        service: {
          dates: {
            start: "",
            end: "",
          },
          times: {
            start: "",
            end: "",
          },
        },
        technic: {
          name: "",
          lastname: "",
          _id: "",
        },
        plate: "",
        survey: false,
        job: {
          dedicated: false,
          repair: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          installation: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          diagnostics: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          itService: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
          survey: {
            time: 0,
            price: {
              special: 0,
              normal: 0,
            },
          },
        },
        cost: {
          normal: 0,
          special: 0,
        },
        meter: {
          mono: {
            start: 0,
            end: 0,
          },
          scan: 0,
          color: {
            start: 0,
            end: 0,
          },
          car: {
            start: 0,
            end: 0,
            cost: 0,
            sum: 0,
          },
        },
        clearance: {
          documentNumber: "",
          partValue: "",
          technic: {
            name: "",
            lastname: "",
            _id: "",
          },
          inside: {
            date: "",
            number: "",
          },
          outside: {
            date: "",
            number: "",
          },
        },
        requirements: {
          continuation: false,
          itService: false,
          claim: {
            checkbox: false,
          },
          valuation: {
            checkbox: false,
          },
          replacement: false,
          invoice: {
            checkbox: false,
          },
        },
        comment: {
          repairWorkshop: false,
          remoteService: false,
          irrational: false,
          machineWorks: false,
          vat: false,
        },
        description: {
          doneJob: "",
          nextIntervention: "",
        },
      };
    }
  },
  setTechnicsItems(state, payload) {
    state.modal.technicItems = payload;
  },
  setOrder(state, payload) {
    state.modal.orders = payload;
  },
  showDay(state, payload) {
    state.ordersDay.day = payload;
  },
  setAssignmentSnOrder(state, payload) {
    console.log(payload);
    state.details.device = payload.findOrder.device;
    state.modal.branchAddress = payload.findOrder.device.address;
    state.modal.fields.service.lastServiceDetails = payload.lastService
    // state.details.service.descriptionIntervention.representative = []
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};