<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="Staff">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj dane</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions([
            "openModal",
            "userDeatilsModal",
            "fetchContract",
            "fetchDayJob"
        ]),
        ...mapMutations(["setStaffModal"]),
        Staff() {
            this.userDeatilsModal(this.$route.params.id);
            this.setStaffModal();
            this.fetchContract();
            this.fetchDayJob();
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 700,
                type: "create",
                route: "staff",
                tab: "staff_users"
            });
        }
    },
    computed: {
        ...mapGetters(["getPermission"])
    }
};
</script>
