<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
    >
      <!-- === === URZĄDZENIE === ===  -->
      <td>
        <v-list-item-title style="font-size: 14px !important">
          {{ item.modelDevices ? item.modelDevices.producer : 'Brak' }}
          {{ item.modelDevices ? item.modelDevices.model : 'Brak' }}
        </v-list-item-title>
        <v-list-item-subtitle
          style="font-size: 14px !important"
          class="grey--text lighten-1"
        >{{ item.modelDevices ? item.modelDevices.deviceType : 'Brak' }}</v-list-item-subtitle>
      </td>

      <!-- === === S/N === === -->
      <td style="font-size: 14px !important">{{item.SN}}</td>

      <!-- === === PODSTAWA SERWISU === === -->
      <td>
        <v-list-item-title style="font-size: 14px !important">{{item.serviceBase}}</v-list-item-title>
        <v-list-item-subtitle
          style="font-size: 14px !important"
          class="grey--text lighten-1"
        >{{item.OSP}}</v-list-item-subtitle>
      </td>

      <!-- === === KLIENT === === -->
      <td>
        <v-list-item-title
          style="font-size: 14px !important ; max-width: 460px;"
          @click="$router.push(`/contacts/${item.client._id}`)"
        >{{ item.client ? item.client.name : 'Brak'}}</v-list-item-title>

        <v-list-item-subtitle
          style="font-size: 14px !important; max-width: 460px;"
          class="grey--text lighten-1"
          @click="$router.push(`/contacts/${item.client._id}`)"
        >
          {{ item.address && item.address[0] && item.address[0].postCode ? item.address[0].postCode : ''}}
          {{ item.address && item.address[0] && item.address[0].city ? item.address[0].city : '' }}
          {{ item.address && item.address[0] && item.address[0].address ? item.address[0].address : ''}}
        </v-list-item-subtitle>
      </td>

      <!-- === === PAKIET USŁUG === === -->
      <td style="font-size: 14px !important">{{item.modelDevices.price.priceName}}</td>

      <!-- === === STATUS === === -->
      <td>
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
      </td>

      <!-- === === AKCJA === === -->
      <td style="text-align: right">
        <v-btn icon small @click="$router.push({ path: `/devices/${item._id}` })">
          <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: ["items"],
  data: () => ({}),
};
</script>