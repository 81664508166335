<template>
  <v-tooltip left v-if="getPermission.level > 1">
    <template v-slot:activator="{ on }">
      <v-btn fab dark v-on="on" small class="buttons--add" @click="editInformation()">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <span>Edytuj informacje</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["getPermission"])
  },
  methods: {
    ...mapActions(["openModal", "currentInformationsDeatils", "fetchItems"]),
    editInformation() {
      this.currentInformationsDeatils({
        id: this.$route.params.id,
        type: "modal",
        route: this.$route.name
      });
      // this.fetchItems("users");
      this.openModal({
        headLine: "global.create",
        open: true,
        type: "edit",
        width: 600,
        route: "currentInformations",
        tab: "current-informations"
      });
    }
  }
};
</script>