<template>
  <v-col cols="12" md="12" class="d-flex flex-wrap pa-0">
    <v-col cols="12" md="12">
      <Separator :text="'Dane reprezentanta'" />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Imię"
        v-model="getRepresentativesData.name"
        :error-messages="getRepresentativesErrors.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Nazwisko"
        v-model="getRepresentativesData.lastname"
        :error-messages="getRepresentativesErrors.lastname"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field
        dense
        label="Stanowisko"
        v-model="getRepresentativesData.position"
        :error-messages="getRepresentativesErrors.position"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <Separator :text="'Informacje dodatkowe'" />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Numer telefonu"
        v-model="getRepresentativesData.phoneNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Email"
        v-model="getRepresentativesData.email"
      ></v-text-field>
    </v-col>
    <v-col sm12 cols="12" md="6">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            dense
            v-model="getRepresentativesData.birthday"
            label="Urodziny"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          first-day-of-week="1"
          ref="picker"
          v-model="getRepresentativesData.birthday"
          :max="new Date().toISOString().substr(0, 10)"
          min="1950-01-01"
          @change="save"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" md="6">
      <v-menu
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            dense
            v-model="getRepresentativesData.nameDay"
            label="Imieniny"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          first-day-of-week="1"
          v-model="getRepresentativesData.nameDay"
          min="1950-01-01"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" md="12">
      <Separator :text="'Uwagi'" />
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field dense label="Uwagi"></v-text-field>
    </v-col>
  </v-col>
</template>
<script>
import Separator from '@/components/Global/Separator.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  data: () => ({
    menu: false,
  }),
  components: {
    Separator,
  },
  computed: {
    ...mapGetters(['getRepresentativesData', 'getRepresentativesErrors']),
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>
