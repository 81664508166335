

<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="d-flex flex-wrap">
                <v-col cols="12" md="12" lg="6">
                    <v-textarea
                        auto-grow
                        rows="10"
                        filled
                        class="no-pointers"
                        readonly
                        label="Poświadczenia:"
                        v-model="getDevicesDetails.implementationData.precertifying"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" lg="6">
                    <v-textarea
                        rows="10"
                        auto-grow
                        filled
                        class="no-pointers"
                        readonly
                        label="Ustawienia:"
                        v-model="getDevicesDetails.implementationData.settings"
                    ></v-textarea>
                </v-col>
            </v-col>
            <!-- === === ACTION BUTTONS IN RIGHT BOTTOM CORNER === === -->
        </v-row>
    </v-container>
</template>

 <script>
import { mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getDevicesDetails"])
    },
    methods: {},
    components: {}
};
</script>