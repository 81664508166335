c<template>
  <v-col cols="12" md="12">
    <v-data-table
      :headers="table.header"
      :items="getRepresentativesTable.items"
      :mobile-breakpoint="1100"
      class="mt-4 central__table"
      hide-default-footer
      :options.sync="options"
      :items-per-page="11"
    >
      <template v-slot:item.action="{ item }">
        <!-- === GO TO DETAILS BUTTON === -->
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on" @click="openEdit(item._id)">
              <v-icon small>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-md-center pt-2">
      <v-pagination
        circle
        v-model="table.page"
        :length="getRepresentativesTable.pages"
        @input="refreshData"
      ></v-pagination>
    </div>
    <!-- <div class="central__actions">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab small dark class="buttons--add" @click="openModal">
                        <v-icon>mdi-account-plus</v-icon>
                    </v-btn>
                </template>
                <span>Dodaj reprezentanta</span>
            </v-tooltip>
    </div>-->
  </v-col>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    table: {
      search: "",
      page: 1,
      header: [],
      items: []
    },
    options: {}
  }),
  computed: {
    ...mapGetters(["getRepresentativesTable", "getRepresentatives"])
  },
  watch: {
    options: {
      handler() {
        this.refreshData();
        return;
      },
      deep: true
    }
  },
  methods: {
    setHeaders() {
      this.table.header = this.getRepresentatives.header;
    },
    openEdit(id) {
      this.$store.commit("resetErrors", "representatives");
      this.representativeDetails(id);
      this.$store.commit("setModal", {
        headLine: "global.edit",
        open: true,
        width: 600,
        type: "edit",
        route: "representatives",
        tab: "contacts_representatives"
      });
    },
    async refreshData() {
      const { sortBy, sortDesc } = this.options;
      const { page, search } = this.table;

      this.getRepresentativesByContact({
        page,
        search,
        sortBy,
        sortDesc,
        route: this.$route.params.id
      });
    },
    ...mapActions([
      "fetchData",
      "getRepresentativesByContact",
      "representativeDetails"
    ]),
    openModal() {
      this.$store.commit("resetModal", "representatives");
      this.$store.commit("setModal", {
        headLine: "global.create",
        open: true,
        width: 600,
        type: "create",
        route: "representatives"
      });
    }
  },
  beforeMount() {
    this.setHeaders();
  }
};
</script>
