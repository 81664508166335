import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
	serviceType: {
		name: '',
		dictionary: 'ServiceType',
		open: false,
	},
	type: '',
	errors: {
		name: '',
	},
	items: [],
};

const getters = {
	getServiceType: state => state.serviceType,
	getServiceTypeItems: state => state.items,
	getServiceTypeErrors: state => state.errors,
	getServiceTypeType: state => state.type,
};

const actions = {
	createServiceType({
		state,
		commit
	}) {
		state.errors.name = '';

		Vue.axios({
				method: 'POST',
				url: '/dictionary/service/type',
				data: {
					...state.serviceType,
				},
			})
			.then(response => {

				commit('closeServiceType');
				commit('setServiceTypeItem', response.data.item);
				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				if (error.response.data.error === 'Exists')
					state.errors.name = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.name = 'Pole jest wymagane';
				}
			});
	},

	fetchServiceType({
		commit
	}) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/service/type',
		}).then(response => {
			commit('setServiceTypeItems', response.data.items);
		});
	},

	ServiceTypeDetails({
		commit
	}, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/service/type/${payload}`,
		}).then(response => {
			commit('setServiceTypeDetails', response.data.item);
		});
	},

	deleteServiceType({
		commit
	}, payload) {
		Vue.axios({
				method: 'PUT',
				url: `/dictionary/service/type/delete/${payload}`,
			})
			.then(() => {
				commit('deleteItem', payload);
				commit('closeSecondModal');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	ServiceTypeUpdate({
		commit
	}) {
		state.errors.name = '';
		Vue.axios({
				method: 'PUT',
				url: `/dictionary/service/type/update/${state.serviceType._id}`,
				data: {
					...state.serviceType,
				},
			})
			.then(response => {
				commit('closeServiceType');
				commit('updateServiceTypeItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.error === 'Exists')
					state.errors.name = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.name = 'Pole jest wymagane';
				}
			});
	},
};

const mutations = {
	closeServiceType(state) {
		state.serviceType.open = false;
	},
	setServiceTypeItems(state, payload) {
		state.items = payload;
	},
	setServiceTypeItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	deleteItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setServiceTypeDetails(state, payload) {
		state.serviceType.name = payload.name;
		state.serviceType._id = payload._id;
		state.serviceType.open = true;
	},
	clearServiceType(state) {
		state.serviceType.name = '';
		state.errors.name = '';
	},
	setServiceTypeType(state, payload) {
		state.type = payload;
	},
	updateServiceTypeItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};