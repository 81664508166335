
<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn
        :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${window.width < 1200 ? '50px' : '40px'}; background: ${getDetailsStatus.name == 'Zamknięty' && getIsTechnics ? 'gray' : ''} !important; cursor: ${getDetailsStatus.name == 'Zamknięty' && getIsTechnics ? 'default' : 'pointer'}`"
        fab
        dark
        v-on="on"
        small
        class="buttons--add"
        @click="editParts"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <span>Edytuj informacje</span>
    <!-- {{this.$route.params.id}} -->
  </v-tooltip>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({
    window: {
      width: 0,
      height: 0
    }
  }),
  computed: {
    ...mapGetters(["getDetailsStatus", "getIsTechnics"])
  },
  methods: {
    ...mapActions(["openModal", "getServiceParts"]),
    ...mapMutations(["resetServiceParts"]),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    editParts() {
      if (this.getDetailsStatus.name == "Zamknięty") {
        if (!this.getIsTechnics) {
          this.resetServiceParts();
          this.getServiceParts({
            id: this.$route.params.id,
            type: "modal"
          });
          this.openModal({
            headLine: "global.create",
            width: 895,
            open: true,
            type: "edit",
            route: "devices",
            tab: "parts_service"
          });
        }
      } else {
        this.resetServiceParts();
        this.getServiceParts({
          id: this.$route.params.id,
          type: "modal"
        });
        this.openModal({
          headLine: "global.create",
          width: 895,
          open: true,
          type: "edit",
          route: "devices",
          tab: "parts_service"
        });
      }
    }
  },
  created() {
    this.handleResize();
  }
};
</script>
