<template>
    <v-container @paste="addPrintScreen">
        <VueContext ref="menu" class="contextMenu">
            <div>
                <v-btn
                    @click="setFile(selectFile), (action = 'copy')"
                    small
                    text
                    >Kopiuj</v-btn
                >
                <br />
                <v-btn
                    @click="setFile(selectFile), (action = 'move')"
                    small
                    text
                    >Przenieś</v-btn
                >
                <br />
                <v-btn
                    @click="
                        copyToStorage(selectFile), (action = 'copyToStorage')
                    "
                    small
                    text
                    >Wstaw do schowka</v-btn
                >
            </div>
        </VueContext>
        <VueContext ref="menu2" class="contextMenu2">
            <div>
                <v-btn
                    @click="copy()"
                    v-if="selectedFile && action == 'copy'"
                    small
                    text
                    >Wklej</v-btn
                >
                <v-btn
                    @click="move()"
                    v-if="selectedFile && action == 'move'"
                    small
                    text
                    >Wklej</v-btn
                >
            </div>
        </VueContext>
        <v-row>
            <v-dialog v-model="folderNameModal" max-width="500px">
                <v-card>
                    <v-card-title class="AE__header">
                        <span class="font-weight-light AE__header__title"
                            >Nazwa folderu</span
                        >
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="
                                            getAttachmentsFields.folderName
                                        "
                                        label="Nazwa folderu"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                        rows="2"
                                        dense
                                        no-resize
                                        v-model="getAttachmentsFields.comments"
                                        label="Uwagi"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions style="background-color: #d9d9d9">
                        <v-spacer></v-spacer>

                        <v-btn @click="closeFolderModal()" class="buttons mr-3"
                            >Anuluj</v-btn
                        >
                        <v-btn
                            class="buttons mr-3 buttons--add"
                            @click="updateFolder('editFolder')"
                            >Zapisz</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- DELETE MODAL -->
            <v-dialog v-model="getDeleteModal.open" max-width="500px">
                <v-card class="pa-5">
                    <div class="d-flex justify-center py-5">
                        <img
                            width="100"
                            height="100"
                            src="@/assets/img/global/warning.svg"
                            alt="warning"
                        />
                    </div>

                    <v-card-title
                        v-if="getDeleteModal.deleteAction == 'folder'"
                        class="d-flex justify-center pa-4"
                        style="font-size: 25px; text-align: center; color: #595959 "
                    >
                        Czy na pewno chcesz usunąć folder
                        <br />
                        {{ getAttachmentsFields.transferredFolderName }}?
                    </v-card-title>

                    <v-card-title
                        v-if="getDeleteModal.deleteAction == 'item'"
                        class="d-flex justify-center pa-4"
                        style="font-size: 25px; text-align: center; color: #595959 "
                        >Czy na pewno chcesz usunąć plik?</v-card-title
                    >
                    <!-- <v-card-text
            class="d-flex justify-center pa-2"
            style="font-size: 20px; color: #545454"
                    >Można to później przywrócić</v-card-text>-->

                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            color="rgb(117, 117, 117)"
                            width="110"
                            height="40"
                            class="white--text"
                            @click="setcloseDeleteModal()"
                            >Anuluj</v-btn
                        >

                        <v-btn
                            v-if="getDeleteModal.deleteAction == 'folder'"
                            width="110"
                            height="40"
                            color="#445d75"
                            class="white--text"
                            @click="updateFolder('deleteFolder')"
                            >Usuń</v-btn
                        >
                        <v-btn
                            v-if="getDeleteModal.deleteAction == 'item'"
                            width="110"
                            height="40"
                            color="#445d75"
                            class="white--text"
                            @click="confirmeDeleteItem('deleteFolder'), clear()"
                            >Usuń</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-col
                cols="12"
                class="p-relative pr-0"
                lg="3"
                md="5"
                sm="12"
                v-if="desserts !== []"
            >
                <v-divider
                    style="position: absolute; right: -6px; z-index: 10; o"
                    vertical
                ></v-divider>
                <div>
                    <div class="folderAttachments">
                        <span></span>
                        <span>Nazwa folderu</span>
                        <span>Akcja</span>
                    </div>
                    <v-list
                        class="pa-0"
                        style="max-height: 600px; overflow-y: auto; overflow-x: hidden"
                    >
                        <v-list-item-group v-model="getAttachmentsFields.hover">
                            <draggable
                                v-model="myList"
                                :disabled="
                                    getPermission.level >= 2 ? false : true
                                "
                                style="cursor: pointer"
                            >
                                <v-list-item
                                    @contextmenu.prevent="$refs.menu2.open"
                                    class="folderAttachments__item"
                                    v-for="item in getFolderItems"
                                    :key="item.name"
                                    @contextmenu="selectedFolder = item._id"
                                >
                                    <v-list-item-icon
                                        class="ml-0"
                                        style="z-index: 10; margin-right: 7px"
                                    >
                                        <v-icon size="18">mdi-folder</v-icon>
                                    </v-list-item-icon>
                                    <span
                                        @click="showFolderAttachments(item)"
                                        style="    cursor: pointer; width: 100%; display: flex; height: 48px; text-align: left; z-index: 10; align-items: center; padding-right: 5px; max-width: 286px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                                    >
                                        <v-tooltip
                                            bottom
                                            v-if="item.folderName.length > 30"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <span
                                                    class="truncate-text"
                                                    v-on="on"
                                                    >{{
                                                        truncate(
                                                            item.folderName,
                                                            30
                                                        )
                                                    }}</span
                                                >
                                            </template>
                                            <span>{{ item.folderName }}</span>
                                        </v-tooltip>
                                        <span
                                            v-if="item.folderName.length <= 30"
                                            >{{ item.folderName }}</span
                                        >
                                    </span>

                                    <v-icon
                                        color="#ababab"
                                        small
                                        style="z-index: 10"
                                        class="mr-2"
                                        v-if="getPermission.level >= 2"
                                        @click="editFolderName(item)"
                                        >mdi-pencil</v-icon
                                    >
                                    <v-icon
                                        style="z-index: 10"
                                        color="red"
                                        v-if="getPermission.level >= 2"
                                        small
                                        @click="deleteFolder(item.folderName)"
                                        >mdi-delete</v-icon
                                    >
                                </v-list-item>
                            </draggable>
                        </v-list-item-group>
                    </v-list>
                </div>
            </v-col>
            <v-col cols="12" md="7" lg="9" sm="12" class="pl-0 ml-0">
                <v-data-table
                    :headers="getAttachmentsHeaders"
                    :items="attachmentsItem"
                    fixed-header
                    height="calc(100vh - 365px)"
                    class="central__table scroll"
                    sort-by="description"
                    disable-pagination
                    :mobile-breakpoint="1100"
                    hide-default-footer
                >
                    <template v-slot:item="{ item }">
                        <tr
                            @contextmenu.prevent="$refs.menu.open"
                            @contextmenu="selectFile = item._id"
                            class="attachmentsTr"
                        >
                            <td>
                                <v-icon
                                    size="18"
                                    class="mb-1 mr-1"
                                    v-if="item.prefix == 'ms-word:ofe|u|'"
                                    >mdi-file-word</v-icon
                                >

                                <v-icon
                                    size="18"
                                    class="mb-1"
                                    v-if="item.prefix == 'ms-powerpoint:ofe|u|'"
                                    >mdi-file-powerpoint</v-icon
                                >
                                <v-icon
                                    size="18"
                                    class="mb-1 mr-1"
                                    v-if="item.prefix == 'ms-excel:ofe|u|'"
                                    >mdi-file-excel</v-icon
                                >
                                <a
                                    :href="item.link"
                                    v-if="item.link"
                                    target="_BLANK"
                                    class="font-weight-regular body-2"
                                    >{{ item.realFileName }}</a
                                >
                                <a
                                    :href="
                                        `${item.prefix}${getGlobalPaths.webdav}${item.fakeFileName}`
                                    "
                                    download
                                    class="font-weight-regular body-2"
                                    @click="
                                        createLog(
                                            item.realFileName ||
                                                item.resource.realFileName,
                                            'download'
                                        )
                                    "
                                    v-else-if="
                                        item.prefix && getPermission.level > 1
                                    "
                                    >{{ item.realFileName }}</a
                                >
                                <a
                                    @click="
                                        openOfficePreview({
                                            url: `${getGlobalPaths.webdav}${item.fakeFileName}`,
                                        }),
                                            createLog(
                                                item.realFileName,
                                                'editablePreview'
                                            )
                                    "
                                    class="font-weight-regular body-2"
                                    v-else-if="
                                        item.prefix && getPermission.level == 1
                                    "
                                    >{{ item.realFileName }}</a
                                >

                                <a
                                    :href="
                                        `${getGlobalPaths.attachments}${item.fakeFileName}`
                                    "
                                    @click="
                                        createLog(item.realFileName, 'preview')
                                    "
                                    target="_BLANK"
                                    class="font-weight-regular body-2"
                                    v-else
                                    >{{ item.realFileName }}</a
                                >
                                <a
                                    v-if="
                                        item.resource &&
                                            item.resource.fakeFileName &&
                                            [
                                                'doc',
                                                'docx',
                                                'xls',
                                                'xlsx',
                                                'ppt',
                                                'pptx',
                                            ].includes(
                                                item.resource.fakeFileName
                                                    .split('.')
                                                    .pop()
                                            )
                                    "
                                    @click="
                                        openOfficePreview({
                                            url: `${getGlobalPaths.webdav}${item.resource.fakeFileName}`,
                                        }),
                                            createLog(
                                                item.resource.realFileName,
                                                'editablePreview'
                                            )
                                    "
                                    class="font-weight-regular body-2"
                                    style="text-decoration: underline"
                                    >{{ item.resource.realFileName }}</a
                                >
                                <a
                                    :href="
                                        `${getGlobalPaths.attachments}${item.resource.fakeFileName}`
                                    "
                                    @click="
                                        createLog(
                                            item.resource.realFileName,
                                            'preview'
                                        )
                                    "
                                    target="_BLANK"
                                    v-else-if="item.resource"
                                    class="font-weight-regular body-2"
                                    >{{ item.resource.realFileName }}</a
                                >

                                <v-tooltip
                                    top
                                    v-if="$route.name === 'resources_details'"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            style="font-size: 12px; margin: 0; color: #9e9e9e; width: 200px"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            Występuje w rejestrach:
                                            {{
                                                item.registries
                                                    ? item.registries.length
                                                    : 0
                                            }}
                                        </p>
                                    </template>
                                    <span>{{
                                        item.registries
                                            ? showResourcesTooltip(
                                                  item.registries
                                              )
                                            : 'Brak'
                                    }}</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <span>{{
                                    item.resource
                                        ? item.resource.description
                                        : item.description
                                }}</span>
                            </td>
                            <td>
                                {{
                                    item.resource
                                        ? item.resource.status
                                        : item.status
                                }}
                            </td>
                            <td align="right">
                                <span v-if="loadingAttachments(item._id)">
                                    <!-- <v-icon
                                        @click="createPDF(item)"
                                        small
                                        color="#ababab"
                                        class="mr-2"
                                        v-if="item.prefix == 'ms-word:ofe|u|' || item.prefix == 'ms-excel:ofe|u|' || item.prefix == 'ms-powerpoint:ofe|u|'"
                                    >mdi-file-pdf</v-icon>-->
                                    <v-icon
                                        size="18"
                                        class="mr-2"
                                        @click="
                                            openOfficePreview({
                                                url: `${getGlobalPaths.webdav}${item.resource.fakeFileName}`,
                                            })
                                        "
                                        v-if="
                                            item.resource &&
                                                item.resource.fakeFileName &&
                                                [
                                                    'doc',
                                                    'docx',
                                                    'xls',
                                                    'xlsx',
                                                    'ppt',
                                                    'pptx',
                                                ].includes(
                                                    item.resource.fakeFileName
                                                        .split('.')
                                                        .pop()
                                                )
                                        "
                                        >mdi-magnify</v-icon
                                    >

                                    <v-icon
                                        size="18"
                                        class="mr-2"
                                        @click="
                                            openOfficePreview({
                                                url: `${getGlobalPaths.webdav}${item.fakeFileName}`,
                                            }),
                                                createLog(
                                                    item.realFileName,
                                                    'editablePreview'
                                                )
                                        "
                                        v-if="item.prefix"
                                        >mdi-magnify</v-icon
                                    >
                                    <v-icon
                                        v-if="
                                            getPermission.level >= 2 &&
                                                !item.resource
                                        "
                                        small
                                        color="#ababab"
                                        class="mr-2"
                                        @click="editAttachmentModal(item)"
                                        >mdi-pencil</v-icon
                                    >
                                    <v-tooltip
                                        top
                                        v-else-if="
                                            getPermission.level >= 2 &&
                                                item.resource
                                        "
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                v-on="on"
                                                color="#ababab"
                                                class="mr-2"
                                                >mdi-pencil</v-icon
                                            >
                                        </template>
                                        <span
                                            >Zasób współdzielony: brak dostępu
                                            do edycji</span
                                        >
                                    </v-tooltip>

                                    <v-icon
                                        small
                                        color="#ababab"
                                        class="mr-2"
                                        @click="
                                            download({
                                                url: item.prefix
                                                    ? `${getGlobalPaths.webdav}${item.fakeFileName}`
                                                    : `${getGlobalPaths.attachments}${item.fakeFileName}`,
                                                fileName: item.fakeFileName,
                                                webdav: item.prefix
                                                    ? true
                                                    : false,
                                                realFileName: item.realFileName,
                                            })
                                        "
                                        v-if="
                                            !item.link &&
                                                getPermission.level >= 1 &&
                                                !item.resource
                                        "
                                        >mdi-download</v-icon
                                    >
                                    <v-icon
                                        small
                                        color="#ababab"
                                        class="mr-2"
                                        @click="
                                            download({
                                                url: item.prefix
                                                    ? `${getGlobalPaths.webdav}${item.resource.fakeFileName}`
                                                    : `${getGlobalPaths.attachments}${item.resource.fakeFileName}`,
                                                fileName:
                                                    item.resource.fakeFileName,
                                                webdav: item.prefix
                                                    ? true
                                                    : false,
                                                realFileName:
                                                    item.resource.realFileName,
                                            })
                                        "
                                        v-else
                                        >mdi-download</v-icon
                                    >

                                    <v-icon
                                        small
                                        color="red"
                                        style="margin-right: -5px"
                                        v-if="getPermission.level >= 2"
                                        @click="
                                            deleteItem({
                                                fakeName: item.fakeFileName,
                                                registryName: $route.name,
                                                registryID: $route.params.id,
                                            })
                                        "
                                        >mdi-delete</v-icon
                                    >
                                </span>
                                <v-progress-circular
                                    :size="24"
                                    v-else
                                    :width="2"
                                    color="#ababab"
                                    indeterminate
                                ></v-progress-circular>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-col cols="3" class="py-0">
                    <v-text-field
                        dense
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Szukaj"
                        single-line
                        hide-details
                        style="margin-top: 50px; position: absolute; bottom: 10px"
                        class="pt-5"
                        @input="searchFile(search)"
                    ></v-text-field>
                </v-col>
            </v-col>
            <canvas
                style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); transition: 0.5s; width: 600px; height: 400px; display: none"
                id="mycanvas"
            ></canvas>
        </v-row>
    </v-container>
</template>

<script>
import { VueContext } from 'vue-context'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Buttons from '@/components/Global/Details/Attachments/Buttons.vue'
import Vue from 'vue'
import axios from 'axios'
import html2canvas from 'html2canvas'
import draggable from 'vuedraggable'
import store from '../../../../store/'
export default {
    components: {
        Buttons,
        draggable,
        VueContext,
    },
    data: () => ({
        search: '',
        // DRAG AND DROP
        dragging: false,
        // END
        table: {
            page: 1,
        },
        singleSelect: true,
        active: false,
        deleteFolderModal: false,
        isSelected: true,
        folderNameModal: false,
        headers: [
            ,
            {
                text: '',
                align: 'left',
                sortable: false,
                value: 'folder',
                width: 20,
            },
            {
                text: 'Nazwa folderu',
                align: 'left',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Akcja',
                value: 'action',
                sortable: false,
                align: 'right',
                width: 80,
            },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            name: '',
        },
        defaultItem: {
            name: '',
        },
        selectFile: '',
        selectedFile: '',
        selectedFolder: '',
        action: '',
    }),
    computed: {
        ...mapGetters([
            'getAttachmentsHeaders',
            'getAttachmentsItems',
            'getGlobalPaths',
            'getFolderNameValue',
            'getDeleteModal',
            'getAttachmentsFields',
            'getUser',
            'getPermission',
            'getFolderItems',
            'getAttachmentsLoading',
        ]),
        attachmentsItem: {
            get() {
                if (this.getAttachmentsItems) {
                    if (this.getPermission.level >= 3) {
                        let DraftStatus = this.getAttachmentsItems.map(
                            (item) => {
                                item.resource && item.resource.description
                                    ? (item.description =
                                          item.resource.description)
                                    : ''

                                return item
                            }
                        )
                        return DraftStatus
                    } else if (this.getPermission.level == 1) {
                        let orderStatus = this.getAttachmentsItems
                            .map((item) => {
                                if (item.resource && item.resource.status) {
                                    item.status = item.resource.status
                                }
                                return item
                            })
                            .filter((v) => v.status == 'Aktualny')
                            .map((item) => {
                                item.resource && item.resource.description
                                    ? (item.description =
                                          item.resource.description)
                                    : ''

                                return item
                            })
                        return orderStatus
                    } else {
                        let orderStatus = this.getAttachmentsItems
                            .map((item) => {
                                if (item.resource && item.resource.status) {
                                    item.status = item.resource.status
                                }
                                return item
                            })
                            .filter(
                                (v) =>
                                    v.createdBy == this.getUser.id ||
                                    v.status != 'Draft'
                            )
                            .map((item) => {
                                item.resource && item.resource.description
                                    ? (item.description =
                                          item.resource.description)
                                    : ''

                                return item
                            })
                        return orderStatus
                    }
                }
            },
        },

        myList: {
            get() {
                return this.getFolderItems
            },
            set(value) {
                this.updateListDragAndDrop(value)
                this.updateFolderOrder({
                    registryName: this.$route.name,
                    registryID: this.$route.params.id,
                })
            },
        },
    },

    methods: {
        ...mapActions([
            'fetchData',
            'openModal',
            'updateFolderAttachment',
            'attachmentDetails',
            'deleteItemAttachment',
            'fetchFolderAttachments',
            'fetchItemFolderAttachments',
            'openSecondModal',
            'AddNewFolder',
            'clearFolder',
            'updateFolderOrder',
            'deleteItem',
            'moveAttachment',
            'copyAttachment',
            'convertAttachmentToPdf',
            'copyAttachmentToStorage',
        ]),

        truncate(source, size) {
            return source.length > size
                ? source.slice(0, size - 1) + '…'
                : source
        },

        loadingAttachments(id) {
            return !this.getAttachmentsLoading.includes(id)
        },

        sendGetRequest() {
            var MrChecker = new XMLHttpRequest(),
                CheckThisUrl = 'hasdpl'

            // Opens the file and specifies the method (get)
            // Asynchronous is true
            MrChecker.open('get', CheckThisUrl, true)

            //check each time the ready state changes
            //to see if the object is ready
            MrChecker.onreadystatechange = checkReadyState

            function checkReadyState() {
                if (MrChecker.readyState === 4) {
                    //check to see whether request for the file failed or succeeded
                    if (MrChecker.status == 200 || MrChecker.status == 0) {
                        alert(CheckThisUrl + ' page is exixts')
                    } else {
                        alert(CheckThisUrl + ' not exists')
                        return
                    }
                }
            }
            MrChecker.send(null)
        },

        addPrintScreen(e) {
            if (e.clipboardData == false) {
                if (typeof callback == 'function') {
                    callback(undefined)
                }
            }

            var items = e.clipboardData.items
            if (items == undefined) {
                if (typeof callback == 'function') {
                    callback(undefined)
                }
            }

            for (var i = 0; i < items.length; i++) {
                // Skip content if not image
                if (items[i].type.indexOf('image') == -1) continue
                // Retrieve image on clipboard as blob
                var blob = items[i].getAsFile()

                if (typeof callback == 'function') {
                    callback(blob)
                }
            }
            e.registryName = this.$route.name
            e.registryID = this.$route.params.id
            e.folderNameValue = this.getFolderNameValue

            // If there's an image, display it in the canvas
            if (items.length == 1) {
                var canvas = document.getElementById('mycanvas')
                canvas.style.display = 'block'
                var ctx = canvas.getContext('2d')

                // Create an image to render the blob on the canvas
                var img = new Image()

                // Once the image loads, render the img on the canvas
                img.onload = function() {
                    // Update dimensions of the canvas with the dimensions of the image
                    canvas.width = this.width
                    canvas.height = this.height

                    // Draw the image
                    ctx.drawImage(img, 0, 0)
                }

                // Crossbrowser support for URL
                var URLObj = window.URL || window.webkitURL

                // Creates a DOMString containing a URL representing the object given in the parameter
                // namely the original Blob
                img.src = URLObj.createObjectURL(blob)
                canvas.toDataURL('image/jpeg', 1.0)

                setTimeout(() => {
                    canvas.style.opacity = 0
                }, 1000)
                setTimeout(() => {
                    ctx.clearRect(0, 0, (canvas.width = 0), (canvas.height = 0))
                    canvas.style.opacity = 1
                }, 1500)

                let nowDate = this.$moment(new Date()).format(
                    'YYYY_MM_DD_HH:MM:ss'
                )

                const image = document.getElementById('mycanvas')
                image.toBlob(function(imageBlob) {})
                const formData = new FormData()
                formData.append('registryID', e.registryID)
                formData.append('folderNameValue', e.folderNameValue)
                formData.append('folderName', '')
                formData.append('registryName', e.registryName)
                formData.append(
                    'files',
                    blob,
                    'printscreen_' + nowDate + '.jpg'
                )

                Vue.axios({
                    method: 'POST',
                    url: `/attachments/${e.registryName}`,
                    data: formData,
                }).then((response) => {
                    this.$store.commit(
                        'setAddFolderItems',
                        response.data.folderItems
                    )
                    this.$store.commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie dodano screen',
                    })
                })
            }
        },
        clear() {
            this.setclearFolderName()
        },
        rowClick: function(item, row) {
            row.select(true)
        },
        log(data) {
            console.log(data)
        },
        ...mapMutations([
            'setcloseDeleteModal',
            'setclearFolderName',
            'updateListDragAndDrop',
            'openOfficePreview',
        ]),
        setFile(data) {
            this.selectedFile = this.selectFile
            this.selectFile = ''
        },
        copy() {
            this.copyAttachment({
                attachmentID: this.selectedFile,
                folderID: this.selectedFolder,
                registryName: this.$route.name,
                registryID: this.$route.params.id,
            })
            this.selectedFolder = ''
            this.selectedFile = ''
        },
        copyToStorage(selectFile) {
            this.copyAttachmentToStorage({
                attachmentID: selectFile,
                fromRegistryName: this.$route.name,
                fromRegistryID: this.$route.params.id,
                registryID: undefined,
                registryName: 'users',
            })
            this.selectedFolder = ''
            this.selectedFile = ''
        },
        move() {
            this.moveAttachment({
                attachmentID: this.selectedFile,
                folderID: this.selectedFolder,
                registryName: this.$route.name,
                registryID: this.$route.params.id,
            })
            this.selectedFolder = ''
            this.selectedFile = ''
        },
        createPDF(item) {
            this.convertAttachmentToPdf({
                attachmentID: item._id,
                fakeFileName: item.fakeFileName,
                registryName: this.$route.name,
                registryID: this.$route.params.id,
            })
        },
        download(payload) {
            if (payload.webdav) {
                let fileLink = document.createElement('a')
                fileLink.href = payload.url
                fileLink.setAttribute('download', payload.fileName)
                document.body.appendChild(fileLink)
                fileLink.click()
            } else {
                Vue.axios({
                    method: 'GET',
                    url: payload.url,
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    let fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    fileLink.setAttribute('download', payload.fileName)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                })
            }
            this.createLog(payload.realFileName, 'download')
        },
        createLog(fileName, type) {
            let msg
            switch (type) {
                case 'download':
                    msg = 'Załączniki / Pobrano załącznik'
                    break
                case 'editablePreview':
                    msg = 'Załączniki / Podgląd załącznika edyt.'
                    break
                case 'preview':
                    msg = 'Załączniki / Podgląd załącznika'
                    break
            }
            let itemId = this.$route.params.id
            let folderName = this.getFolderNameValue

            Vue.axios({
                method: 'POST',
                url: '/attachmentLogs',
                data: {
                    fileName: fileName,
                    itemId: itemId,
                    folderName: folderName,
                    msg: msg,
                },
            })
        },

        showFolderAttachments(item) {
            this.getAttachmentsFields.transferredFolderName = item.folderName
            this.fetchItemFolderAttachments({
                registryName: this.$route.name,
                registryID: this.$route.params.id,
                folderName: item.folderName,
            })
        },
        editAttachmentModal(item) {
            this.attachmentDetails({
                fakeFileName: item.fakeFileName,
                registryName: this.$route.name,
                registryID: this.$route.params.id,
                folderName: this.getFolderNameValue,
            })
            this.openModal({
                headLine: 'global.edit',
                open: true,
                type: 'edit',
                width: 600,
                route: 'attachments',
                tab: 'attachment',
                global: 'attachments',
            })
        },

        ...mapMutations([
            'setcloseDeleteModal',
            'updateListDragAndDrop',
            'openOfficePreview',
            'setAddFolderItems',
        ]),

        editFolderName(item) {
            this.getAttachmentsFields.folderName = item.folderName
            this.getAttachmentsFields.transferredFolderName = item.folderName
            this.getAttachmentsFields.comments = item.comments
            this.folderNameModal = true
        },

        deleteFolder(item) {
            this.getDeleteModal.open = true
            this.getDeleteModal.deleteAction = 'folder'
            this.getAttachmentsFields.transferredFolderName = item
        },

        deleteItem(item) {
            this.getDeleteModal.open = true
            this.getDeleteModal.deleteAction = 'item'
            this.getAttachmentsFields.fakeFileName = item.fakeName
        },

        confirmeDeleteItem() {
            if (!this.getAttachmentsFields.transferredFolderName) {
                this.getAttachmentsFields.transferredFolderName = this.getFolderItems[0].folderName
            }
            this.deleteItemAttachment({
                registryName: this.$route.name,
                registryID: this.$route.params.id,
                transferredFolderName: this.getAttachmentsFields
                    .transferredFolderName,
                fakeFileName: this.getAttachmentsFields.fakeFileName,
            })
            this.getDeleteModal.open = false
        },

        closeFolderModal() {
            this.folderNameModal = false
            this.getAttachmentsFields.folderName = ''
        },

        updateFolder(item) {
            this.updateFolderAttachment({
                registryName: this.$route.name,
                registryID: this.$route.params.id,
                transferredFolderName: this.getAttachmentsFields
                    .transferredFolderName,
                action: item,
            })
            if (item == 'editFolder') {
                this.closeFolderModal()
            } else if (item == 'deleteFolder') {
                this.getDeleteModal.open = false
            }
        },

        showResourcesTooltip(registries) {
            let text = ''
            console.log(registries)
            if (registries && registries.length > 0) {
                registries.forEach((item, index) => {
                    text += ` ${item.realRegistryName.toUpperCase()} ${
                        index < registries.length - 1 ? '|' : ''
                    }`
                })
            } else {
                text = 'Brak'
            }
            return text
        },

        searchFile(text) {
            let rows = document.querySelectorAll('.attachmentsTr')
            let filter = text.toUpperCase()
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].textContent.toUpperCase().indexOf(filter) > -1)
                    rows[i].style.display = ''
                else rows[i].style.display = 'none'
            }
        },
    },
}
</script>

<style>
tr.v-data-table__selected {
    background: #ecffea !important;
}

@import '~vue-context/dist/css/vue-context.css';

.contextMenu2 div {
    padding-left: 20px;
}

.contextMenu {
    opacity: 0.9;
}

.contextMenu div {
    padding-right: 23px;
}
</style>
