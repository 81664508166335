<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" lg="12" class="mt-5">
                <Separator :text="'Informacje podstawowe'" />
            </v-col>
            <v-col cols="12" md="4" class="pt-0">
                <v-autocomplete
                    dense
                    item-value="carModelname"
                    :label="getCarsFieldsNames.model"
                    v-model="getCarsFields.model"
                    :items="getCarsDictionaryItems"
                    :error-messages="getCarsErrors.model"
                    item-text="carModelname"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
                <v-select
                    dense
                    item-value="_id"
                    :label="getCarsFieldsNames.type"
                    v-model="getCarsFields.classes"
                    :items="type"
                ></v-select>
            </v-col>

            <v-col cols="12" md="3" class="pt-0">
                <v-text-field
                    dense
                    v-model="getCarsFields.plate"
                    :error-messages="getCarsErrors.plate"
                    :label="getCarsFieldsNames.plate"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12" class="mt-5">
                <Separator :text="'Informacje dodatkowe'" />
            </v-col>
            <v-col cols="12" md="12" class="d-flex pa-0">
                <v-col cols="12" md="3" class="mt-5">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                v-model="getCarsFields.rejdat"
                                :error-messages="getCarsErrors.rejdat"
                                :label="getCarsFieldsNames.rejdat"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            v-model="getCarsFields.rejdat"
                            @input="menu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- <v-col cols="12" md="4" class="mt-5">
                <v-autocomplete
                    dense
                    v-model="getCarsFields.type"
                    :label="Rodzaj pojazdu"
                    :items="type"
                ></v-autocomplete>
                </v-col>-->
            </v-col>
            <v-col cols="12" md="12" class="d-flex pa-0">
                <v-col cols="12" md="4" class="mt-5">
                    <v-text-field
                        dense
                        v-model="getCarsFields.evidence"
                        :error-messages="getCarsErrors.evidence"
                        :label="getCarsFieldsNames.evidence"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-5">
                    <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                v-model="getCarsFields.inSuranceExpiration"
                                :label="getCarsFieldsNames.inSuranceExpiration"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            first-day-of-week="1"
                            v-model="getCarsFields.inSuranceExpiration"
                            @input="menu4 = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-col>
            <v-col cols="12" md="3">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            v-model="getCarsFields.survey"
                            :error-messages="getCarsErrors.survey"
                            :label="getCarsFieldsNames.survey"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getCarsFields.survey"
                        @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field
                    dense
                    v-model="getCarsFields.vin"
                    :error-messages="getCarsErrors.vin"
                    :label="getCarsFieldsNames.vin"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-menu
                    v-model="data2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            v-model="getCarsFields.year"
                            :error-messages="getCarsErrors.year"
                            :label="getCarsFieldsNames.year"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getCarsFields.year"
                        @input="data2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field v-model="getCarsFields.tires" dense :label="getCarsFieldsNames.tires"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-menu
                    v-model="data3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            v-model="getCarsFields.warranty"
                            :error-messages="getCarsErrors.warranty"
                            :label="getCarsFieldsNames.warranty"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getCarsFields.warranty"
                        @input="data3 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <!-- === === GWARANT === === -->

            <!-- <v-col cols="12" md="4">
                <v-autocomplete
                    dense
                    :label="getCarsFieldsNames.guarantor"
                    v-model="getCarsFields.guarantor"
                    :error-messages="getCarsErrors.guarantor"
                    @keyup="guarantorDebounce($event)"
                    :items="getClientItems"
                    item-text="name"
                    item-value="_id"
                    class="mt-5"
                    hint="Zacznij pisać..."
                    :hide-no-data="clientValue.length < 3"
                ></v-autocomplete>
            </v-col>-->
            <v-col cols="12" md="4">
                <v-select
                    dense
                    :label="getCarsFieldsNames.property"
                    v-model="getCarsFields.property"
                    :error-messages="getCarsErrors.property"
                    class="mt-5"
                    :items="propertyItems"
                ></v-select>
            </v-col>

            <v-col cols="12" md="4">
                <v-autocomplete
                    dense
                    :label="getCarsFieldsNames.driver"
                    v-model="getCarsFields.driver"
                    :items="getDropdown.items.users"
                    item-value="_id"
                    class="mt-5"
                >
                    <template v-slot:selection="data">{{ data.item.lastname }} {{ data.item.name }}</template>
                    <template v-slot:item="data">{{ data.item.lastname }} {{ data.item.name }}</template>
                </v-autocomplete>
                <!-- <v-autocomplete dense :label="Kierowca" v-model="getCarsFields.driver" class="mt-5"></v-autocomplete> -->
            </v-col>

            <v-col cols="12" md="12">
                <v-textarea
                    :label="getCarsFieldsNames.description"
                    v-model="getCarsFields.description"
                    rows="1"
                    auto-grow
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="12"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";
import debounce from "lodash.debounce";

export default {
    data: () => ({
        propertyItems: ["Własny", "Leasing", "Kredyt", "Najem", "Wypożyczenie"],
        menu2: null,
        data2: null,
        data3: null,
        menu4: false,
        menu: null,
        date: null,
        clientValue: "",
        showData: "",
        type: [
            "Osobowy",
            "Dostawczy pow. 3,5t",
            "Dostawczy do 3,5t",
            "TIR",
            "Inny",
        ],
        classes: ["Klasa E", "Klasa P", "Klasa E"],
        driver: ["Jan Kowalski", "Tomasz Nowak"],
        model: ["Skoda Citygo", "Fiat Panda", "Ford Transit"],
    }),
    components: {
        Separator,
    },
    computed: {
        ...mapGetters([
            "getCarsFields",
            "getCarsErrors",
            "getCarsDictionaryItems",
            "getDropdown",
            "getCarsFieldsNames",
            "getClientItems",
        ]),
        // testowanie: {
        //     test() {
        //         return (this.showData = true);
        //     },
        // },
    },
    methods: {
        ...mapActions(["fetchContacts"]),
        guarantorDebounce: debounce(function (e) {
            this.clientValue = e.target.value;
            if (e.target.value.length >= 3) {
                this.fetchContacts(e.target.value);
            }
        }, 500),
    },
};
</script>
