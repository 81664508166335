import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/i18n.js';

import store from './store/';
import Menu from './views/Menu';
import Central from './views/Central.vue';
import Details from './views/Details.vue';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Menu',
            component: Menu,
            redirect: 'dashboard',
            meta: {
                requiresAuth: true,
            },
            // checked layoutu
            beforeEnter: (to, from, next) => {
                store.dispatch('checkLayout');
                next();
            },

            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard" */
                            './views/Dashboard.vue'
                        ),
                },
                {
                    path: 'profile',
                    name: 'profile',
                    component: () =>
                        import(
                            /* webpackChunkName: "profile" */
                            './views/Profile.vue'
                        ),
                },
                {
                    path: 'workers',
                    name: 'users',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'tasks',
                    name: 'tasks',
                    component: Central,
                    // meta: {
                    // 	permission: 'reader',
                    // },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'registries',
                    name: 'registries',
                    component: Central,
                    meta: {
                        permission: 'admin',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'dictionaries',
                    name: 'dictionaries',
                    component: () =>
                        import(
                            /* webpackChunkName: "dictionaries" */
                            './views/Admin/Dictionaries.vue'
                        ),
                    meta: {
                        permission: 'admin',
                    },
                },

                {
                    path: 'groups',
                    name: 'groups',
                    component: Central,
                    meta: {
                        permission: 'admin',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },

                {
                    path: 'storage',
                    name: 'storage',
                    component: () =>
                        import('./components/Personal/Storage/Storage.vue'),
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getStorage', {
                            next,
                        });
                    },
                },
                {
                    path: 'resources',
                    name: 'resources',
                    component: Central,
                    meta: {
                        permission: 'admin',
                    },
                },
                {
                    path: 'resources/:id',
                    name: 'resources_details',
                    component: Details,
                    meta: {
                        permission: 'admin',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('resourceDetails', {
                            id: to.params.id,
                            next,
                            type: 'details'
                        });
                    },
                },
                {
                    path: 'session_logs',
                    name: 'session_logs',
                    component: Central,
                    meta: {
                        permission: 'admin',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'templates',
                    name: 'templates',
                    component: Central,
                    meta: {
                        permission: 'admin',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: 'registries',
                            pageSize: 11,
                            next,
                        });
                    },
                },

                {
                    path: 'templates/:id',
                    name: 'templates_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('registryDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },

                {
                    path: 'departments',
                    name: 'departments',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'workers/:id',
                    name: 'workers_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('userDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                {
                    path: 'contacts',
                    name: 'contacts',
                    component: Central,
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'contacts/:id',
                    name: 'contacts_details',
                    component: Details,
                    beforeEnter: (to, from, next) => {
                        store.dispatch('contactDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                {
                    path: 'devices',
                    name: 'devices',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'devices/:id',
                    name: 'devices_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },

                    beforeEnter: (to, from, next) => {
                        store.dispatch('devicesDeatils', {
                            id: to.params.id,
                            type: 'devices',
                            next,
                        });
                    },
                },
                {
                    path: 'service',
                    name: 'service',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'service/:id',
                    name: 'service_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('serviceOrderDeatils', {
                            id: to.params.id,
                            type: 'service',
                            next,
                        });
                    },
                },
                {
                    path: 'parts',
                    name: 'parts',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'parts/:id',
                    name: 'parts_details',
                    component: Details,

                    beforeEnter: (to, from, next) => {
                        store.dispatch('partsDeatils', {
                            id: to.params.id,
                            next,
                            type: 'details',
                        });
                    },
                },
                {
                    path: 'printers',
                    name: 'printers',
                    component: Central,
                },
                {
                    path: 'printers/:id',
                    name: 'printers_details',
                    component: Details,
                },

                {
                    path: 'cars',
                    name: 'cars',
                    component: Central,
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'cars/:id',
                    name: 'cars_details',
                    component: Details,
                    beforeEnter: (to, from, next) => {
                        store.dispatch('carsDeatils', {
                            id: to.params.id,
                            next,
                            type: 'cars',
                        });
                    },
                },
                {
                    path: 'alerts',
                    name: 'alerts',
                    component: Central,
                    // meta: {
                    // 	permission: 'reader',
                    // },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'models',
                    name: 'models',
                    component: Central,
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'models/:id',
                    name: 'models_details',
                    component: Details,

                    beforeEnter: (to, from, next) => {
                        store.dispatch('modelDetails', {
                            id: to.params.id,
                            next,
                            type: 'details',
                        });
                    },
                },
                // <====== WSD REGISTRY ======>
                {
                    path: 'diet-nutrition',
                    name: 'diet-nutrition',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },

                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'diet-nutrition/:id',
                    name: 'diet-nutrition_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('dietNutritionDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                {
                    path: 'wifi',
                    name: 'wifi',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                    },
                },
                {
                    path: 'wifi/:id',
                    name: 'wifi_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('wifiDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // DYŻURY
                {
                    path: 'duty',
                    name: 'duty',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                    },
                },
                {
                    path: 'duty/:id',
                    name: 'duty_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('dutyDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // AKREDYTACJA
                {
                    path: 'accreditation',
                    name: 'accreditation',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'accreditation/:id',
                    name: 'accreditation_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('accreditationDeatils', {
                            id: to.params.id,
                            next,
                        });
                        store.dispatch('getPossibleMenagers', 'accreditation');
                    },
                },
                // DRUKI NIEMEDYCZNE
                {
                    path: 'non-medical-print',
                    name: 'non-medical-print',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'non-medical-print/:id',
                    name: 'non-medical-print_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('nonMedicalPrintsDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // DRUKI MEDYCZNE WEWNĘTRZNE
                {
                    path: 'internal-prints',
                    name: 'internal-prints',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'internal-prints/:id',
                    name: 'internal-prints_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('internalPrintsDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // DRUKI MEDYCZNE ZEWNĘTRZNE
                {
                    path: 'external-prints',
                    name: 'external-prints',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'external-prints/:id',
                    name: 'external-prints_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('externalPrintsDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // ZGODY PACJENTA
                {
                    path: 'patients-consent',
                    name: 'patients-consent',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'patients-consent/:id',
                    name: 'patients-consent_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('patientsConsentsDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // FARMAKOTERAPIA
                {
                    path: 'pharmacotherapy',
                    name: 'pharmacotherapy',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'pharmacotherapy/:id',
                    name: 'pharmacotherapy_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('PharmacotherapyDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // INFORMACJE BIEŻĄCE
                {
                    path: 'current-informations',
                    name: 'current-informations',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        // store.dispatch('checkAttachment')
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '2',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                    },
                },
                {
                    path: 'current-informations/:id',
                    name: 'current-informations_details',
                    component: Details,
                    meta: {
                        permission: 'user',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('currentInformationsDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // ZARZĄDZENIA DYREKTORA
                {
                    path: 'director-orders',
                    name: 'director-orders',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'director-orders/:id',
                    name: 'director-orders_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch(
                            'getPossibleMenagers',
                            'director-orders'
                        );
                        store.dispatch('directorOrdersDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                     // KORONAWIRUS
                {
                    path: 'coronavirus',
                    name: 'coronavirus',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });                        
                    },
                },
                {
                    path: 'coronavirus/:id',
                    name: 'coronavirus_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch(
                            'getPossibleMenagers',
                            'coronavirus'
                        );
                        store.dispatch('coronavirusDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // ZEBRANIA SZKOLENIA WSD
                {
                    path: 'training',
                    name: 'training',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                        // store.dispatch('getPossibleMenagers', to.name);
                    },
                },
                {
                    path: 'training/:id',
                    name: 'training_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('trainingDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // ZDARZENIA NADZWYCZAJNE
                {
                    path: 'extraordinary-occurence',
                    name: 'extraordinary-occurence',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                        // store.dispatch('getPossibleMenagers', to.name);
                    },
                },
                {
                    path: 'extraordinary-occurence/:id',
                    name: 'extraordinary-occurence_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('extraordinaryOccurenceDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // PROCEDURY JAKOŚCI
                {
                    path: 'iso',
                    name: 'iso',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'iso/:id',
                    name: 'iso_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', 'iso');
                        store.dispatch('isoDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // WYNIKI ANALIZ ZESPOŁÓW WSD
                {
                    path: 'team-analysis-result',
                    name: 'team-analysis-result',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'team-analysis-result/:id',
                    name: 'team-analysis-result_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('TeamAnalysisResultDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // PROCEDURY BHP
                {
                    path: 'bhp',
                    name: 'bhp',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'bhp/:id',
                    name: 'bhp_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('bhpDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // POLITYKA BEZPIECZEŃSTWA INFORMACJI
                {
                    path: 'information-security-policy',
                    name: 'information-security-policy',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'information-security-policy/:id',
                    name: 'information-security-policy_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('SecurityPolicyDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },

                // END WSD

                //UMOWY
                {
                    path: 'agreement',
                    name: 'agreement',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'agreement/:id',
                    name: 'agreement_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('agreementDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },

                //OFERTY
                {
                    path: 'offers',
                    name: 'offers',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'offers/:id',
                    name: 'offers_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('offersDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                //LEASINGI
                {
                    path: 'leasing',
                    name: 'leasing',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'leasing/:id',
                    name: 'leasing_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('leasingDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                //PRZETARGI
                {
                    path: 'tenders',
                    name: 'tenders',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'tenders/:id',
                    name: 'tenders_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('tendersDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // WARTOŚĆ PUNKTOWA ŚWIADCZEŃ
                {
                    path: 'benefits-value',
                    name: 'benefits-value',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },

                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'benefits-value/:id',
                    name: 'benefits-value_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('getPossibleMenagers', 'benefits-value');
                        store.dispatch('benefitsValueDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // KONTAKTY WEWNĘTRZNE
                {
                    path: 'internal-contacts',
                    name: 'internal-contacts',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'internal-contacts/:id',
                    name: 'internal-contacts_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('internalContactsDeatils', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
                // === DROG-BET === //

                //BUDOWY
                {
                    path: 'constructions',
                    name: 'constructions',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'constructions/:id',
                    name: 'constructions_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('constructionsDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },

                // MAGAZYN
                {
                    path: 'warehouse',
                    name: 'warehouse',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'warehouse/:id',
                    name: 'warehouse_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchItems', 'contacts');
                        store.dispatch('fetchItems', 'cars');
                        store.dispatch('fetchItems', 'constructions');
                        store.dispatch('fetchItems', 'materials');
                        store.dispatch('fetchItems', 'users');
                        store.dispatch('warehouseDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },

                // MATERIAŁY
                {
                    path: 'materials',
                    name: 'materials',
                    component: Central,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('fetchDefaultMenager', {
                            routeName: to.name,
                        });
                        store.dispatch('getPossibleMenagers', to.name);
                        store.dispatch('fetchData', {
                            page: 1,
                            search: '',
                            sortBy: '',
                            sortDesc: '',
                            routeName: to.name,
                            pageSize: 11,
                            next,
                        });
                    },
                },
                {
                    path: 'materials/:id',
                    name: 'materials_details',
                    component: Details,
                    meta: {
                        permission: 'reader',
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch('materialRotation', {
                            id: to.params.id,
                        });
                        store.dispatch('materialDetails', {
                            id: to.params.id,
                            next,
                        });
                    },
                },
            ],
        },
        {
            path: '/logs-details',
            name: 'logs-details',
            meta: {
                requiresAuth: true,
            },
            component: () =>
                import('./components/Admin/SessionLogs/LogsDetails.vue'),
            beforeEnter: (to, from, next) => {
                store.dispatch('fetchDepartments');
                store.dispatch('fetchSessionLogs', {
                    next,
                });
            },
        },
        {
            path: '/login',
            name: 'Login',
            meta: {
                requiresAuth: false,
            },
            component: () => import('./views/Login.vue'),
            // checked layout
            beforeEnter: (to, from, next) => {
                store.dispatch('checkLayout');
                next();
            },
        },
        {
            path: '/print',
            name: 'print',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./views/Print.vue'),
            beforeEnter: (to, from, next) => {
                store.commit('setOrderType', {
                    type: to.query.type,
                });
                // store.dispatch('lastInterventionsToOrder', {
                //     orderIndex: to.query,
                // });
                store.dispatch('serviceOrderDeatils', {
                    id: to.query.id,
                    type: 'service',
                    next,
                });
            },
        },
        {
            path: '/printWZ/:id',
            name: 'printWZ',
            meta: {
                requiresAuth: true,
            },
            component: () =>
                import('../src/components/Registry/Warehouse/PrintWZ.vue'),
            beforeEnter: (to, from, next) => {
                store.dispatch('warehouseDetails', {
                    id: to.params.id,
                    next,
                });
            },
        },
        {
            path: '/printPZ/:id',
            name: 'printPZ',
            meta: {
                requiresAuth: true,
            },
            component: () =>
                import('../src/components/Registry/Warehouse/PrintPZ.vue'),
            beforeEnter: (to, from, next) => {
                store.dispatch('warehouseDetails', {
                    id: to.params.id,
                    next,
                });
            },
        },
        {
            path: '/printInternalContacts',
            name: 'printInternalContacts',
            meta: {
                requiresAuth: true,
            },
            component: () =>
                import(
                    './components/Registry/InternalContacts/PrintContacts.vue'
                ),
            beforeEnter: (to, from, next) => {
                store.dispatch('fetchInternalContacts', {
                    id: to.query,
                    next,
                });
            },
        },

        {
            path: '/NotFound',
            name: 'NotFound',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./views/404.vue'),
        },
        {
            path: '*',
            redirect: '/NotFound',
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        Vue.axios({
            method: 'POST',
            url: '/session',
            data: {
                url: to.name,
            },
        }).then((response) => {
            if (!response.data.logged) {
                next({
                    path: '/login',
                });
            } else {
                store.commit('setSession', {
                    cookie: response.data.session.cookie,
                });
                if (!store.state['sessionTimer'].session.interval) {
                    store.dispatch('startSessionTimer', {
                        cookie: response.data.session.cookie,
                    });
                }
                store.commit('setUserRegistries', response.data.registries);
                store.commit('setUser', response.data.user);
                i18n.locale = response.data.user.language;
                store.dispatch('RBNotifications');
                store.dispatch('RBTasks');

                if (response.data.user.isSuperAdmin) {
                    store.commit('setPermission', {
                        route: to.name,
                        level: 5,
                    });
                    next();
                } else if (response.data.user.isAdmin) {
                    store.commit('setPermission', {
                        route: to.name,
                        level: 4,
                    });
                    next();
                } else {
                    store.commit('setPermission', {
                        route: to.name,
                        level: response.data.permission,
                    });
                    switch (to.meta.permission) {
                        case 'admin':
                            if (response.data.user.isAdmin) next();
                            else next('/NotFound');
                            store.commit('setPermission', {
                                route: to.name,
                                level: 4,
                            });
                            break;
                        case 'user':
                            if (
                                response.data.user.isAdmin ||
                                response.data.permission > 1
                            )
                                next();
                            else next('/NotFound');
                            break;
                        case 'reader':
                            if (
                                response.data.user.isAdmin ||
                                response.data.permission > 0
                            )
                                next();
                            else next('/NotFound');
                            break;
                        default:
                            next();
                            break;
                    }
                }
            }
        });
    } else {
        Vue.axios({
            method: 'POST',
            url: '/session',
            data: {
                url: to.name,
            },
        }).then((response) => {
            if (response.data.logged) {
                next({
                    path: from.fullPath,
                });
            } else {
                next();
            }
        });
    }
});

export default router;
