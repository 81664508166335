<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            readonly
            v-model="getCurrentInformationDetails.title"
            :label="getCurrentInformationFieldsNames.title"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            readonly
            :value="
              getCurrentInformationDetails.archiveDate
                ? correctData(getCurrentInformationDetails.archiveDate)
                : 'Brak'
            "
            :label="getCurrentInformationFieldsNames.archiveDate"
          ></v-text-field>
        </v-col>
        <v-col v-if="getLayoutWSD.wsd == true" cols="12" sm="6" md="3">
          <v-text-field
            readonly
            :value="
              getCurrentInformationDetails.startDate
                ? correctData(getCurrentInformationDetails.startDate)
                : 'Brak'
            "
            :label="getCurrentInformationFieldsNames.startDate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="12">
          <v-textarea
            readonly
            v-model="getCurrentInformationDetails.information"
            :label="getCurrentInformationFieldsNames.information"
            no-resize
            rows="6"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6" md="12" v-if="getLayoutWSD.wsd == false">
          <v-textarea
            label="Informacja dla:"
            readonly
            :value="informationForUsers()"
            no-resize
            rows="3"
            auto-grow
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6" md="7">
          <v-checkbox
            v-model="getCurrentInformationDetails.homePage"
            dense
            :label="getCurrentInformationFieldsNames.homePage"
            color="#19aa8d"
            disabled
            class="mt-2 check__servis"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col cols="12" md="5">
          <v-text-field
            v-model="getCurrentInformationDetails.priority.priorityName"
            dense
            :label="getCurrentInformationFieldsNames.priority"
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    menu2: false,
    person: [],
  }),
  computed: {
    ...mapGetters([
      "getCurrentInformationDetails",
      "getLayoutWSD",
      "getCurrentInformationFieldsNames",
    ]),
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    informationForUsers() {
      let usersArr = [];
      if (
        this.getCurrentInformationDetails.to &&
        this.getCurrentInformationDetails.to.users &&
        this.getCurrentInformationDetails.to.users.length > 0
      ) {
        this.getCurrentInformationDetails.to.users.forEach((element) => {
          usersArr.push(` ${element.name + " " + element.lastname + " "}`);
        });
      }
      if (this.getCurrentInformationDetails.to.departments.length > 0) {
        this.getCurrentInformationDetails.to.departments.forEach((element) => {
          element.users.forEach((element) => {
            usersArr.push(` ${element.name + " " + element.lastname}`);
          });
        });
      }
      return usersArr;
    },
  },
  components: {},
};
</script>