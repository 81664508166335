<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.isDone === true ? 'color: #BFBFBF' : ''"
        >
            <!-- === === OD === === -->
            <td>
                <v-list-item-title class="tabeleFont">{{ item.createdBy ? `${item.createdBy.name} ${item.createdBy.lastname}` : 'Brak'}}</v-list-item-title>
                <v-list-item-subtitle
                    class="grey--text tabeleFont"
                >{{ item.createdBy._id.toString() == getUser.id.toString() ? `Własne ${taskFor(item)}` : '' }}</v-list-item-subtitle>
            </td>

            <!-- === === NAZWA === ===  -->
            <td>
                <v-list-item-title class="tabeleFont">{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle class="tabeleFont"
                    v-if="item.description"
                >{{ shortDescription(item.description, 100) }}</v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text tabeleFont" v-else>Brak</v-list-item-subtitle>
            </td>

            <!-- === === TERMIN === === -->
            <td>
                <v-list-item-title class="tabeleFont">{{ item.termDate ? $moment(item.termDate).format('YYYY-MM-DD') : 'Brak'}}</v-list-item-title>
                <v-list-item-subtitle class="grey--text tabeleFont">{{ item.termTime }}</v-list-item-subtitle>
            </td>

            <!-- === === KATEGORIA === === -->
            <td>
                <v-chip
                    :color="item.isDone === true ? '#BFBFBF' : item.category.background"
                    :style="`color: ${item.category.color}`"
                    label
                    small
                    v-if="item.category"
                >{{ item.category.name }}</v-chip>
                <v-chip dark label small :color="item.isDone === true ? '#BFBFBF' : ''" v-else>Brak</v-chip>
            </td>

            <!-- === === PRIORYTETY === === -->
            <td>
                <v-chip
                    :color="item.isDone === true ? '#BFBFBF' : item.priority.background"
                    :style="`color: ${item.priority.color}`"
                    label
                    v-if="item.priority"
                    small
                >{{ item.priority.priorityName }}</v-chip>
                <v-chip dark v-else label small :color="item.isDone === true ? '#BFBFBF' : ''">Brak</v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="details(item._id)">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import truncate from "lodash.truncate";
import { mapActions, mapGetters } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters(["getUser", "getDropdown"])
    },
    methods: {
        ...mapActions(["taskDetails", "fetchItems"]),
        shortDescription(description, length) {
            return truncate(description, { length });
        },
        details(id) {
            this.taskDetails({
                id,
                registryName: this.$route.name
            });
            this.$store.dispatch("openModal", {
                type: "details",
                headLine: "global.details",
                open: true,
                width: 1000,
                route: this.$route.name,
                type: "details"
            });
        },
        taskFor(item) {
            let returnData = "";
            if (
                item.people.departments.length > 0 ||
                item.people.users.length > 1
            ) {
                returnData = "dla wielu";
            } else {
                let user = this.getDropdown.items.users.filter(
                    user => user._id == item.people.users[0]
                )[0];

                returnData = user ? `${user.name} ${user.lastname}` : "";
            }

            return returnData;
        }
    },
    beforeMount() {
        this.fetchItems("users");
    }
};
</script>