import Vue from 'vue';
import router from '@/router.js';

const state = {
	models: {
		title: 'Baza modeli',
		header: [
			{
				text: 'Producent',
				value: 'producer',
				class: 'central__table__header',
			},
			{
				text: 'Model',
				align: 'left',
				value: 'model',
				class: 'central__table__header',
			},
			{
				text: 'Rodzaj urządzenia',
				value: 'deviceType',
				class: 'central__table__header',
			},
			{
				text: 'Pakiet cenowy',
				value: 'price',
				class: 'central__table__header',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			status: '',
			menager: '',
			model: null,
			series: null,
			producer: null,
			deviceType: null,
			price: null,
			difficulty: null,
			options: [],
			description: null,
		},
		errors: {
			model: '',
			producer: '',
			deviceType: '',
			price: '',
		},
	},
	details: {
		model: null,
		series: null,
		producer: null,
		menager: '',
		deviceType: null,
		price: null,
		difficulty: null,
		options: null,
		description: null,
	},
	items: [],
	fieldsNames: {
		status: 'Status',
		menager: 'Menager',
		model: 'Model',
		series: 'Seria modeli',
		producer: 'Producent',
		deviceType: 'Rodzaj urządzenia',
		price: 'Pakiet cenowy',
		difficulty: 'Wskaźnik kompetencji',
		options: 'Opcje',
		description: 'Opis dodatkowy',
	},
};

const getters = {
	getModels: state => state.models,
	getModelsFields: state => state.modal.fields,
	getModelsErrors: state => state.modal.errors,
	getModelDetails: state => state.details,
	getModelItems: state => state.items,
	getModelFieldsNames: state => state.getFieldsNames,
};

const actions = {
	createModel({ dispatch, commit }) {
		commit('resetErrors', 'models');
		Vue.axios({
			method: 'POST',
			url: '/models',
			data: {
				...state.modal.fields,
			},
		})
			.then(response => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'models',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano model',
				});
			})
			.catch(error => {
				if (error.response.data.msg === 'Exists') {
					state.modal.errors.model = 'Taki model już istnieje';
				} else {
					commit('setErrors', {
						route: 'models',
						err: error.response.data.error,
					});
				}
			});
	},

	searchPartsModel({ state, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/search/ModelParts',
			data: {
				model: payload.search,
			},
		})
			.then(response => {
				commit('setPartsModel', response.data.items);
			})
			.catch(error => {
				console.log(error);
			});
	},

	modelDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/models/${payload.id}`,
		})
			.then(response => {
				if (payload.type == 'details') {
					commit('setDetailsIndex', response.data.item.index);
					commit('setDetailsMenager', {
						...response.data.item.menager,
						update: false,
					});
					commit('setModelDetails', response.data.item);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					if (payload.next) {
						payload.next();
					}
				} else {
					commit('setModelModal', response.data.item);
				}
			})
			.catch(error => {
				console.log(error);
				if (error.response.status == '404') router.push('/NotFound');
			});
	},

	updateModel({ commit }, payload) {
		commit('resetErrors', 'models');
		Vue.axios({
			method: 'PUT',
			url: `/models/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then(response => {
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano model',
				});

				commit('refreshDetails', response.data.item);
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.msg === 'Exists') {
					state.modal.errors.model = 'Taki model już istnieje';
				} else {
					commit('setErrors', {
						route: 'models',
						err: error.response.data.error,
					});
				}
			});
	},
};

const mutations = {
	clearModel(state) {
		state.items = [];
	},
	setPartsModel(state, payload) {
		state.items = payload;
	},

	setModelDetails(state, payload) {
		state.details = payload;
	},
	setModelModal(state, payload) {
		state.modal.fields = payload;
	},
	refreshDetails(state, payload) {
		state.details = payload;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
