import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
	manufacturerBrand: {
		name: '',
		dictionary: 'ManufacturerBrand',
		open: false,
	},
	type: '',
	errors: {
		name: '',
	},
	items: [],
};
const getters = {
	getManufacturerBrand: state => state.manufacturerBrand,
	getManufacturerBrandItems: state => state.items,
	getManufacturerBrandErrors: state => state.errors,
	getManufacturerBrandType: state => state.type,
};

const actions = {
	async createManufacturerBrand({
		state,
		commit
	}) {
		state.errors.name = '';
		await Vue.axios({
				method: 'POST',
				url: '/dictionary/service/manufacturerBrand',
				data: {
					...state.manufacturerBrand,
				},
			})
			.then(response => {

				commit('closeManufacturerBrand');
				commit('setManufacturerBrandItem', response.data.item);
				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				console.log(error);
				if (error.response.data.error === 'Exists')
					state.errors.name = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.name = 'Pole jest wymagane';
				}
			});
	},

	fetchManufacturerBrand({
		commit
	}) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/service/manufacturerBrand',
		}).then(response => {
			commit('setManufacturerBrandItems', response.data.items);
		});
	},

	ManufacturerBrandDetails({
		commit
	}, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/service/manufacturerBrand/${payload}`,
		}).then(response => {
			commit('setManufacturerBrandDetails', response.data.item);
		});
	},

	deleteManufacturerBrand({
		commit
	}, payload) {
		Vue.axios({
				method: 'PUT',
				url: `/dictionary/service/manufacturerBrand/delete/${payload}`,
			})
			.then(() => {
				commit('deleteItem', payload);
				commit('closeSecondModal');
				// Vue.swal('Usunięto!', 'Pomyślnie usunięto wpis', 'success');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	ManufacturerBrandUpdate({
		commit
	}) {
		state.errors.name = '';
		Vue.axios({
				method: 'PUT',
				url: `/dictionary/service/manufacturerBrand/update/${state.manufacturerBrand._id}`,
				data: {
					...state.manufacturerBrand,
				},
			})
			.then(response => {
				commit('closeManufacturerBrand');
				commit('updateManufacturerBrandItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				console.log(error.response.data);
				if (error.response.data.error === 'Exists') {
					state.errors.name = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.name = 'Pole jest wymagane';
				}
			});
	},
};

const mutations = {
	closeManufacturerBrand(state) {
		state.manufacturerBrand.open = false;
	},
	setManufacturerBrandItems(state, payload) {
		state.items = payload;
	},
	setManufacturerBrandItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	deleteItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setManufacturerBrandDetails(state, payload) {
		state.manufacturerBrand.name = payload.name;
		state.manufacturerBrand._id = payload._id;
		state.manufacturerBrand.open = true;
	},
	clearManufacturerBrand(state) {
		state.manufacturerBrand.name = '';
		state.errors.name = '';
	},
	setManufacturerBrandType(state, payload) {
		state.type = payload;
	},
	updateManufacturerBrandItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};