<template>
	<v-tooltip left v-if="getPermission.level > 1">
		<template v-slot:activator="{ on }">
			<v-btn
				fab
				dark
				v-on="on"
				small
				class="buttons--add"
				@click="implementationDevices"
			>
				<v-icon small>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<span>Edytuj informacje</span>
	</v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	data: () => ({}),
	methods: {
		...mapActions(['openModal', 'devicesDeatils']),
		implementationDevices() {
			this.devicesDeatils({
				id: this.$route.params.id,
				type: 'modal',
			}).then(() => {
				this.openModal({
					headLine: 'global.create',
					open: true,
					width: 1000,
					type: 'edit',
					route: 'devices',
					tab: 'implementation_devices',
				});
			});
		},
	},
	computed: {
		...mapGetters(['getPermission']),
	},
};
</script>
