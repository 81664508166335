<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="7" class="pa-0">
        <v-col cols="12" md="12" class="d-flex flex-wrap py-0">
          <v-col
            cols="12"
            md="12"
            class="d-flex pa-0 mb-5 flex-wrap"
            style="background-color: rgb(246, 247, 230)"
          >
            <v-col cols="6" md="3" class="pt-4 pb-0">
              <v-menu
                v-model="menu4"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="getServiceImplement.fields.service.dates.start"
                    clearable
                    label="Data rozpoczęcia usługi:"
                    readonly
                    v-on="on"
                    @click:clear="date4 = null"
                  >
                    <template slot="prepend">
                      <v-icon
                        @click="presentTime('start')"
                        color="#19aa8d"
                        size="30"
                        >mdi-play-circle</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="getServiceImplement.fields.service.dates.start"
                  @change="menu4 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" md="2" class="pt-4 pb-0">
              <v-menu
                ref="startServiceTime"
                v-model="menu6"
                :close-on-content-click="false"
                :return-value.sync="
                  getServiceImplement.fields.service.times.start
                "
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="getServiceImplement.fields.service.times.start"
                    label="Czas rozpoczęcia usługi:"
                    readonly
                    v-on="on"
                    :disabled="!getServiceImplement.fields.service.dates.start"
                  ></v-text-field>
                </template>

                <v-time-picker
                  v-if="menu6"
                  v-model="getServiceImplement.fields.service.times.start"
                  full-width
                  format="24hr"
                  @click:minute="
                    $refs.startServiceTime.save(
                      getServiceImplement.fields.service.times.start
                    )
                  "
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3" class="pt-4 pb-0">
              <v-menu
                v-model="menu5"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="getServiceImplement.fields.service.dates.end"
                    clearable
                    label="Data zakończenia usługi:"
                    readonly
                    v-on="on"
                    @click:clear="date5 = null"
                  >
                    <template slot="prepend">
                      <v-icon
                        @click="presentTime('end'), serviceTime()"
                        color="red"
                        size="30"
                        >mdi-stop-circle</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="getServiceImplement.fields.service.dates.end"
                  @change="menu5 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="2" class="pt-4 pb-0">
              <v-menu
                ref="endServiceTime"
                v-model="menu7"
                :close-on-content-click="false"
                :return-value.sync="
                  getServiceImplement.fields.service.times.end
                "
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="getServiceImplement.fields.service.times.end"
                    label="Czas zakończenia usługi:"
                    readonly
                    v-on="on"
                    :disabled="!getServiceImplement.fields.service.dates.end"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu7"
                  v-model="getServiceImplement.fields.service.times.end"
                  full-width
                  format="24hr"
                  @click:minute="
                    $refs.endServiceTime.save(
                      getServiceImplement.fields.service.times.end
                    )
                  "
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="pt-1 pb-0 d-flex flex-column"
              style="background: rgb(234, 236, 209)"
            >
              <span style="display: block; font-size: 11px"
                >Rozliczanie czasu usługi:</span
              >
              <span style="display: block; font-size: 15px">{{
                finishComparingResult
              }}</span>
            </v-col>
          </v-col>

          <v-col cols="12" md="12" class="py-0">
            <Separator :text="'Rozliczenie czasu, kosztów wykonanej usługi'" />
          </v-col>
          <v-col cols="12" md="12" class="d-flex flex-wrap pt-2">
            <v-col cols="12" md="6" class="pa-0">
              <v-select
                dense
                class
                label="Imię i nazwisko technika"
                :items="getServiceImplement.users"
                return-object
                item-value="_id"
                v-model="getServiceImplement.fields.technic"
                @change="setUserPlate($event)"
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                  {{ data.item.lastname }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.name }}
                  {{ data.item.lastname }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                :items="correctCarItems"
                v-model="getServiceImplement.fields.plate"
                dense
                class
                item-text="plate"
                item-value="plate"
                label="Nr rej samochodu"
              >
                <template v-slot:item="data">
                  {{ data.item.plate }} -
                  {{ data.item.model }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="d-flex flex-wrap">
              <div class="pa-0 d-flex align-center">
                <v-btn
                  class="mt-5"
                  icon
                  @click="jobDec('survey'), setNormalCost(), setSpecialCost()"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div class="input-effect price" style="text-align: center">
                  <span>Przegląd okresowy</span>
                  <money
                    style="text-align: center"
                    class="effect-16"
                    v-model="getServiceImplement.fields.job.survey.time"
                    v-bind="money"
                    disabled
                  ></money>
                  <span class="focus-border"></span>
                </div>
                <v-btn
                  class="mt-5"
                  icon
                  @click="jobInc('survey'), setNormalCost(), setSpecialCost()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div
                class="pa-0 d-flex align-center"
                style="min-width: 83px !important"
              >
                <v-btn
                  class="mt-5"
                  icon
                  @click="jobDec('repair'), setNormalCost(), setSpecialCost()"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div class="input-effect price" style="text-align: center">
                  <span>Naprawa</span>
                  <money
                    style="text-align: center"
                    class="effect-16"
                    v-model="getServiceImplement.fields.job.repair.time"
                    v-bind="money"
                    disabled
                  ></money>
                  <span class="focus-border"></span>
                </div>
                <v-btn
                  class="mt-5"
                  icon
                  @click="jobInc('repair'), setNormalCost(), setSpecialCost()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div
                class="pa-0 d-flex align-center"
                style="min-width: 83px !important"
              >
                <v-btn
                  class="mt-5"
                  icon
                  @click="
                    jobDec('installation'), setNormalCost(), setSpecialCost()
                  "
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div class="input-effect price" style="text-align: center">
                  <span>Wdrożenie</span>
                  <money
                    style="text-align: center"
                    class="effect-16"
                    v-model="getServiceImplement.fields.job.installation.time"
                    v-bind="money"
                    disabled
                  ></money>
                  <span class="focus-border"></span>
                </div>
                <v-btn
                  class="mt-5"
                  icon
                  @click="
                    jobInc('installation'), setNormalCost(), setSpecialCost()
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div
                class="pa-0 d-flex align-center"
                style="min-width: 83px !important"
              >
                <v-btn
                  class="mt-5"
                  icon
                  @click="
                    jobDec('diagnostics'), setNormalCost(), setSpecialCost()
                  "
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div class="input-effect price" style="text-align: center">
                  <span>Diagnostyka</span>
                  <money
                    style="text-align: center"
                    class="effect-16"
                    v-model="getServiceImplement.fields.job.diagnostics.time"
                    v-bind="money"
                    disabled
                  ></money>
                  <span class="focus-border"></span>
                </div>
                <v-btn
                  class="mt-5"
                  icon
                  @click="
                    jobInc('diagnostics'), setNormalCost(), setSpecialCost()
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <div
                class="pa-0 d-flex align-center"
                style="min-width: 83px !important"
              >
                <v-btn
                  class="mt-5"
                  icon
                  @click="
                    jobDec('itService'), setNormalCost(), setSpecialCost()
                  "
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div class="input-effect price" style="text-align: center">
                  <span>Usługa IT</span>
                  <money
                    style="text-align: center"
                    class="effect-16"
                    v-model="getServiceImplement.fields.job.itService.time"
                    v-bind="money"
                    disabled
                  ></money>
                  <span class="focus-border"></span>
                </div>
                <v-btn
                  class="mt-5"
                  icon
                  @click="
                    jobInc('itService'), setNormalCost(), setSpecialCost()
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="pl-0 mt-1 pt-5 d-flex flex-wrap flex-column"
            >
              <span style="font-size: 11px">
                Koszt netto dla Klienta (cena standardowa):
              </span>
              <money
                class="effect-16"
                style="max-width: 150px"
                v-model="getServiceImplement.fields.cost.normal"
                v-bind="cost"
                disabled
              ></money>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pl-0 mt-1 pt-5 d-flex flex-wrap flex-column"
            >
              <span style="font-size: 11px"
                >Koszt netto dla Klienta (dedykowany):</span
              >
              <money
                class="effect-16"
                style="max-width: 150px"
                v-model="getServiceImplement.fields.cost.special"
                v-bind="cost"
                disabled
              ></money>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pl-0 mt-1 pt-5 d-flex flex-wrap flex-column"
            >
              <span style="font-size: 11px"
                >Koszt netto transportu do klienta:</span
              >
              <money
                class="effect-16"
                style="max-width: 150px"
                v-model="getServiceImplement.fields.meter.car.cost"
                v-bind="cost"
              ></money>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-wrap py-0">
          <v-col cols="12" md="12" class="pt-0">
            <Separator :text="'Liczniki'" />
          </v-col>

          <v-col cols="12" md="3" class="d-flex flex-wrap pa-0">
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Licznik urządzenia mono</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.meter.mono.start"
                :error-messages="getImplementationErrors.mono"
                label="Początkowy"
                pattern="\d*"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.meter.mono.end"
                :error-messages="getImplementationErrors.mono"
                label="Końcowy"
                pattern="\d*"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="company != 'Biuromax-Balcer'">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.meter.scan"
                label="Skany"
                pattern="\d*"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" md="3" class="d-flex flex-wrap pa-0">
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Licznik urządzenia kolor</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.meter.color.start"
                :error-messages="getImplementationErrors.color"
                label="Początkowy"
                pattern="\d*"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.meter.color.end"
                :error-messages="getImplementationErrors.color"
                label="Końcowy"
                pattern="\d*"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" md="5" class="d-flex flex-wrap pa-0">
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Licznik samochodowy (km)</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.meter.car.start"
                :error-messages="getImplementationErrors.car"
                label="Początkowy"
                pattern="\d*"
                @keyup="setCarSum()"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                value="300"
                v-model="getServiceImplement.fields.meter.car.end"
                :error-messages="getImplementationErrors.car"
                label="Końcowy"
                pattern="\d*"
                @keyup="setCarSum()"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                disabled
                dense
                v-model="getServiceImplement.fields.meter.car.sum"
                :error-messages="getImplementationErrors.car"
                label="Suma"
              ></v-text-field>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="12" md="12" class="d-flex flex-wrap py-0 mt-5">
          <v-col cols="12" md="12" class="py-0">
            <Separator :text="'Rozliczenie Interwencji'" />
          </v-col>
          <!-- <v-col cols="12" md="4">
                        <v-text-field
                            dense
                            v-model="getServiceImplement.fields.clearance.documentNumber"
                            label="Nr dokumentu"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            dense
                            label="Wartość części do prowizji"
                            v-model="getServiceImplement.fields.clearance.partValue"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            dense
                            v-model="getServiceImplement.fields.clearance.technic"
                            :items="getServiceImplement.users"
                            return-object
                            item-value="_id"
                            label="Dotyczy technika"
                        >
                            <template
                                v-slot:selection="data"
                            >{{data.item.name}} {{data.item.lastname}}</template>
                            <template v-slot:item="data">{{data.item.name}} {{data.item.lastname}}</template>
                        </v-select>
          </v-col>-->
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-wrap pa-0"
            v-if="getModal.tab === 'implementation_service'"
          >
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Rozliczenie wewnętrzne</span>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    readonly
                    clearable
                    v-model="getServiceImplement.fields.clearance.inside.date"
                    label="Data"
                    prepend-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="getServiceImplement.fields.clearance.inside.date"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.clearance.inside.number"
                label="Numer dok."
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col
            v-if="getModal.tab === 'implementation_service'"
            cols="12"
            md="6"
            class="d-flex flex-wrap pa-0"
          >
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Rozliczenie zewnętrzne</span>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    readonly
                    clearable
                    v-model="getServiceImplement.fields.clearance.outside.date"
                    label="Data"
                    prepend-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="getServiceImplement.fields.clearance.outside.date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                dense
                v-model="getServiceImplement.fields.clearance.outside.number"
                label="Numer dok."
              ></v-text-field>
            </v-col>
          </v-col>
        </v-col>
        <!-- <v-divider style="position: absolute; top: 10px; right: 0px" vertical></v-divider> -->
      </v-col>
      <v-col cols="12" md="12" lg="5" class="d-flex flex-wrap">
        <v-col cols="12" md="5" class="d-flex align-center py-1 flex-wrap">
          <span>Wymagana:</span>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.requirements.continuation"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Kontynuacja</span>
            <v-tooltip top v-if="getImplementationArrangements.continuation">
              <template v-slot:activator="{ on }">
                <v-icon size="14" class="ml-2" color="red" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span>Ustalenia logistyka</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="
                getServiceImplement.fields.requirements.valuation.checkbox
              "
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Wycena</span>
            <!-- <v-tooltip top v-if="getImplementationArrangements.valuation.checkbox">
              <template v-slot:activator="{ on }">
                <v-icon size="14" class="ml-2" color="red" v-on="on">mdi-information</v-icon>
              </template>
              <span>Wycena</span>
            </v-tooltip>-->
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.requirements.itService"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Usługa IT</span>
            <v-tooltip top v-if="getImplementationArrangements.itService">
              <template v-slot:activator="{ on }">
                <v-icon size="14" class="ml-2" color="red" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span>Ustalenia logistyka</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.requirements.claim.checkbox"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Claim</span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.requirements.replacement"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Urządzenie zastępcze</span
            >
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0 mt-5">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.requirements.invoice.checkbox"
              hide-details
              class="mt-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Faktura</span>
          </v-col>
        </v-col>
        <v-col
          cols="12"
          md="7"
          style="max-height: 170px"
          class="d-flex align-center py-1 flex-wrap"
        >
          <span>Uwagi:</span>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.comment.repairWorkshop"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Naprawa warsztatowa</span
            >
            <v-tooltip top v-if="getImplementationArrangements.repairWorkshop">
              <template v-slot:activator="{ on }">
                <v-icon size="14" class="ml-2" color="red" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span>Ustalenia logistyka</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.comment.remoteService"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Usługa zdalna</span>
            <v-tooltip top v-if="getImplementationArrangements.remoteService">
              <template v-slot:activator="{ on }">
                <v-icon size="14" class="ml-2" color="red" v-on="on"
                  >mdi-information</v-icon
                >
              </template>
              <span>Ustalenia logistyka</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.comment.irrational"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Usługa nieuzasadniona</span
            >
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.comment.machineWorks"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">
              Klient stwierdza poprawność pracy urzadzenia po wykonanej usłudze
            </span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              color="#19aa8d"
              v-model="getServiceImplement.fields.comment.vat"
              hide-details
              class="ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Klient został poinformowany o kosztach</span
            >
          </v-col>
        </v-col>

        <v-col cols="12" md="12" style="margin-top: 120px">
          <v-textarea
            label="Zakres wykonanej interwencji"
            class
            v-model="getServiceImplement.fields.description.doneJob"
            no-resize
            rows="3"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            label="Uwagi technika do kolejnej interwencji"
            value="Wymiana rolek"
            class="mt-4"
            rows="3"
            no-resize
            v-model="getServiceImplement.fields.description.nextIntervention"
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Separator from "@/components/Global/Separator.vue";
import moment from "moment";
import { Money } from "v-money";
export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    date2: new Date().toISOString().substr(0, 10),
    date4: new Date().toISOString().substr(0, 10),
    date5: new Date().toISOString().substr(0, 10),
    finishComparingResult: "",
    money: {
      decimal: ".",
      thousands: ",",
      suffix: " h",
      precision: 2,
      masked: false,
    },
    cost: {
      decimal: ",",
      thousands: ",",
      suffix: " PLN",
      precision: 2,
      masked: false,
    },
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    menu7: false,
  }),
  components: {
    Separator,
  },
  methods: {
    ...mapMutations([
      "setPlate",
      "jobDec",
      "jobInc",
      "setNormalCost",
      "setCarSum",
      "setSpecialCost",
    ]),
    serviceTime() {
      let dateStart = this.getServiceImplement.fields.service.dates.start;
      let timeStart = this.getServiceImplement.fields.service.times.start;
      let dateEnd = this.getServiceImplement.fields.service.dates.end;
      let timeEnd = this.getServiceImplement.fields.service.times.end;

      let startDate = dateStart + " " + timeStart + ":00";
      let endDate = dateEnd + " " + timeEnd + ":00";

      let compareDate = moment(endDate, "YYYY-MM-DD HH:mm:ss").diff(
        moment(startDate, "YYYY-MM-DD HH:mm:ss")
      );
      let comparingResult = moment.duration(compareDate);
      let finishComparingResult = `D:${
        comparingResult.days() ? comparingResult.days() : "0"
      } G:${comparingResult.hours() ? comparingResult.hours() : "0"} M:${
        comparingResult.minutes() ? comparingResult.minutes() : "0"
      }`;
      this.finishComparingResult = finishComparingResult;
    },

    setUserPlate(e) {
      this.setPlate(e);
    },
    onlyNumber($event) {
      if ($event.target.value[0] == 0)
        $event.target.value = $event.target.value.slice(1);

      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    presentTime(model) {
      let today = new Date();
      let min = today.getMinutes();
      let day = today.getDate();
      let hour = today.getHours();
      let mounth = today.getMonth() + 1;
      let Year = today.getFullYear();
      if (min < 10) {
        min = "0" + today.getMinutes();
      }
      if (hour < 10) {
        hour = "0" + today.getHours();
      }

      if (day < 10) {
        day = "0" + today.getDate();
      }
      if (mounth < 10) {
        mounth = "0" + mounth;
      }
      let dataToday = Year + "-" + mounth + "-" + day;
      let dateTime = hour + ":" + min;

      if (model === "start") {
        return (
          (this.getServiceImplement.fields.service.times.start = dateTime),
          (this.getServiceImplement.fields.service.dates.start = dataToday)
        );
      } else if (model === "end") {
        return (
          (this.getServiceImplement.fields.service.times.end = dateTime),
          (this.getServiceImplement.fields.service.dates.end = dataToday)
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "getModal",
      "getServiceImplement",
      "getImplementationErrors",
      "getImplementationArrangements",
    ]),
    correctCarItems() {
      let carsPlates = [];
      this.getServiceImplement.cars.forEach((item) => {
        carsPlates.push({ plate: item.plate, model: item.model });
      });
      return carsPlates;
    },
  },
  beforeMount() {
    this.serviceTime();
  },
  watch: {
    "getServiceImplement.fields.technic"(newVal, oldVal) {
      if (this.getServiceImplement.fields.plate == "") this.setPlate(newVal);
    },
    "getServiceImplement.fields.service.dates.end"() {
      if (this.getServiceImplement.fields.service.dates.end != "")
        this.serviceTime();
    },
    "getServiceImplement.fields.service.times.end"() {
      if (this.getServiceImplement.fields.service.times.end != "")
        this.serviceTime();
    },
    "getServiceImplement.fields.service.times.start"() {
      if (this.getServiceImplement.fields.service.times.start != "")
        this.serviceTime();
    },
    "getServiceImplement.fields.service.dates.start"() {
      if (this.getServiceImplement.fields.service.dates.start != "")
        this.serviceTime();
    },
  },
};
</script>
