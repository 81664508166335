import Vue from 'vue';
import findIndex from 'lodash.findindex';
import store from '../index';
// import devices from './Registry/devices';
import router from '../../router';

const state = {
	global: {
		paths: {
			profilePic: `${process.env.VUE_APP_SERVER}/secured/profile/`,
			attachments: `${process.env.VUE_APP_SERVER}/secured/attachments/`,
			webdav: `${process.env.VUE_APP_SERVER}/${process.env.VUE_APP_WEBDAV}/`,
		},
	},
	getRegistryHeader: {
		action: true,
		updatedAt: true,
		status: true,
	},
	layout: {
		wsd: false,
	},
	preloader: {
		active: false,
	},
	nav: {
		stepper: 1,
	},
	snackbars: {
		snackbar: false,
		type: '',
		text: '',
	},
	modal: {
		open: false,
		headLine: '',
		width: '',
		type: '',
		tab: '',
		global: '',
	},
	secondModal: {
		open: false,
		width: '',
		type: '',
		persistent: true,
	},
	openQRModal: {
		open: false,
		SN: null,
		camera: 'auto',
		Message: {
			text: '',
			color: '',
			mdi: '',
		},
	},
	officePreview: {
		opern: false,
		url: '',
	},
	dropdown: {
		items: {
			users: [],
			departments: [],
			groups: [],
		},
		loading: false,
		hideNoData: true,
	},
	table: {
		items: [],
		length: 0,
		pages: 0,
		loading: false,
		statuses: [],
	},
	currentInformation: {
		items: [],
		length: 0,
		pages: 0,
		loading: false,
	},
	permission: {
		route: null,
		level: null,
	},
	sessionTimedOut: false,
	possibleMenagers: [],
	defaultMenager: '',
	fetchExtras: {},
	iconStatus: {},
	detailsStatuses: [],
	detailsStatus: {},
	detailsIndex: '',
	detailsMenager: {},
	positionItems: [],
	buttons: {
		save: false,
		edit: false,
	},
};
const getters = {
	getLayoutWSD: (state) => state.layout,
	getPreloader: (state) => state.preloader,
	getButtons: (state) => state.buttons,
	getNav: (state) => state.nav,
	getModal: (state) => state.modal,
	getSecondModal: (state) => state.secondModal,
	getSnackbars: (state) => state.snackbars,
	getDropdown: (state) => state.dropdown,
	getTable: (state) => state.table,
	getGlobalPaths: (state) => state.global.paths,
	getQRModal: (state) => state.openQRModal,
	getPermission: (state) => state.permission,
	getPossibleMenagersItems: (state) => state.possibleMenagers,
	getDefaultMenager: (state) => state.defaultMenager,
	getDetailsStatuses: (state) => state.detailsStatuses,
	getDetailsStatus: (state) => state.detailsStatus,
	getDetailsIndex: (state) => state.detailsIndex,
	getDetailsMenager: (state) => state.detailsMenager,
	getSessionTimedOut: (state) => state.sessionTimedOut,
	getOfficePreview: (state) => state.officePreview,
	getRegistryHeader: (state) => state.getRegistryHeader,
	getPositionItems: (state) => state.positionItems,
};

const actions = {
	async fetchItems({
		commit
	}, payload) {
		commit('setDDLoading', true);
		commit('setDDNoData', true);

		await Vue.axios({
			method: 'GET',
			url: `/global/find/${payload}`,
			params: {
				page: 1,
				search: '',
				sortBy: [],
				sortDesc: [],
				pageSize: null,
				isElementItems: true,
			},
		}).then((response) => {
			commit('setDDLoading', false);
			commit('setDDNoData', false);
			commit('setDDItems', {
				items: response.data.items,
				registry: payload,
			});
		});
	},
	async changeLayout({
		commit
	}, payload) {
		await Vue.axios({
			method: 'POST',
			url: `/layout/wsd`,
			data: payload,
		}).then((response) => {
			commit('setWSD', response.data.item.wsd);
		});
	},

	async checkLayout({
		commit
	}) {
		await Vue.axios({
			method: 'GET',
			url: `/checkLayout/wsd`,
		}).then((response) => {
			commit('setWSD', response.data.item.wsd);
		});
	},

	async fetchData({
		commit,
		state
	}, payload) {	
		state.table.items = [];
		state.table.loading = true;
		state.table.pages = 0;		
		await Vue.axios({
				method: 'GET',
				url: `/global/find/${payload.routeName}`,
				params: {
					page: payload.page,
					search: payload.search,
					sortBy: payload.sortBy[0],
					sortDesc: payload.sortDesc[0],
					pageSize: payload.pageSize,
					registryName: payload.registryName || '',
					registryID: payload.registryID || '',
					extras: state.fetchExtras,
					iconStatus: state.iconStatus,
					filter: payload.filter,
				},
			})
			.then((response) => {
				commit('setTable', response.data);
				state.table.loading = false;
				if (payload.next) payload.next();
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response.data);
			});
	},

	async fetchDefaultMenager({
		state,
		commit
	}, payload) {
		await Vue.axios({
				method: 'GET',
				url: `/global/defaultMenager/${payload.routeName}`,
			})
			.then((response) => {
				commit('setDefaultMenager', response.data.item.defaultMenager);
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response.data);
			});
	},

	async sendMail({
		state,
		commit
	}, payload) {
		if (payload.receivers.length > 0) {
			for await (let user of payload.receivers) {
				if (user.mail) {
					await Vue.axios({
						method: 'POST',
						url: '/users/mailService',
						data: {
							mail: user.mail,
							name: user.name,
							lastname: user.lastname,
							login: user.login,
							case: payload.case,
							info: payload.info,
						},
					});
				}
			}
		}
	},

	async changeLayout({
		commit
	}, payload) {
		await Vue.axios({
			method: 'POST',
			url: `/layout/wsd`,
			data: payload,
		}).then((response) => {
			commit('setWSD', response.data.item.wsd);
		});
	},

	async checkLayout({
		commit
	}) {
		await Vue.axios({
			method: 'GET',
			url: `/checkLayout/wsd`,
		}).then((response) => {
			commit('setWSD', response.data.item.wsd);
		});
	},

	openModal({
		commit
	}, payload) {
		commit('setModal', payload);
		if (payload.type != 'qrcodescaner') {
			commit('resetModal', payload.route);
			commit('resetErrors', payload.route);
		}
	},

	goToDevices({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/goToDevices/${payload}`,
			})
			.then((response) => {
				commit('QRMessage', {
					text: `Znaleziono numer seryjny ${payload}`,
					color: 'green',
					mdi: 'mdi-checkbox-marked-circle-outline',
				});
				setTimeout(() => {
					state.openQRModal.camera = 'off';
					router.push(`/devices/${response.data.item._id}`);
					state.openQRModal.open = false;
				}, 1500);
			})
			.catch((error) => {
				console.log(error.response);

				if (error) {
					commit('QRMessage', {
						text: `Niestety ${payload} nie istnieje!`,
						color: 'red',
						mdi: 'mdi-alert-circle-outline',
					});
					setTimeout(() => {
						state.openQRModal.camera = 'off';
						state.openQRModal.open = false;
					}, 2000);
				}
			});
	},

	openSecondModal({
		commit
	}, payload) {
		commit('setSecondModal', payload);
	},

	setStatus({
		commit,
		dispatch
	}, payload) {
		Vue.axios({
				method: 'PUT',
				url: `/global/status`,
				data: {
					...payload,
				},
			})
			.then(() => {
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie zmieniono status',
				});

				if (payload.registryName == 'current-informations_details') {
					if (payload.status.name == 'Aktualne') {
						dispatch('currentInformationsDeatils', {
							id: payload.registryID,
							sendEmail: true,
						});
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setSnackbars', {
					type: 'error',
					text: 'Wystąpił problem',
				});
			});
	},

	async getPossibleMenagers({
		state
	}, payload) {
		
		await Vue.axios({
				method: 'GET',
				url: `/registries/menagers/${payload}`,
			})
			.then((response) => {
				state.possibleMenagers = response.data.items[0].menager;
			})
			.catch((error) => {
				console.log(error);
			});
	},

	fetchPositionItems({}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/global/position/${payload}`,
			})
			.then((response) => {
				state.positionItems = [];
				state.positionItems = response.data.items;
			})
			.catch((error) => console.log(error));
	},
};

const mutations = {
	QRMessage(state, payload) {
		state.openQRModal.Message.text = payload.text;
		state.openQRModal.Message.color = payload.color;
		state.openQRModal.Message.mdi = payload.mdi;
	},

	setSnackbars(state, payload) {
		state.snackbars.snackbar = true;
		state.snackbars.type = payload.type;
		state.snackbars.text = payload.text;
	},
	closeSnackbars(state) {
		state.snackbars.snackbar = false;
	},
	setSecondModal(state, payload) {
		state.secondModal = payload;
	},
	closeSecondModal() {
		state.secondModal.open = false;
		state.secondModal.data = {};
		state.secondModal.type = '';
		state.secondModal.persistent = false;
	},
	openQRModal(state) {
		state.openQRModal.open = false;
	},
	setModal(state, payload) {
		state.modal.tab = '';
		state.modal = payload;
	},
	closeModal(state) {
		
		state.modal.headLine = '';
		state.modal.open = false;
		state.modal.width = 500;
		state.modal.type = '';
		state.modal.tab = '';
	},
	setDDLoading(state, payload) {
		state.dropdown.loading = payload;
	},
	setDDItems(state, payload) {
		if (payload.registry == 'users') {
			state.dropdown.items.users = payload.items;
		} else if (payload.registry == 'departments')
			state.dropdown.items.departments = payload.items;
		else if (payload.registry == 'groups')
			state.dropdown.items.groups = payload.items;
		else if (payload.registry == 'contacts')
			state.dropdown.items.contacts = payload.items;
		else if (payload.registry.includes('representatives'))
			state.dropdown.items.representatives = payload.items;
		else if (payload.registry == 'cars')
			state.dropdown.items.cars = payload.items;
		else if (payload.registry == 'constructions')
			state.dropdown.items.constructions = payload.items;
		else if (payload.registry == 'materials')
			state.dropdown.items.materials = payload.items;
	},
	setDDNoData(state, payload) {
		state.dropdown.hideNoData = payload;
	},
	setTable(state, payload) {
		state.table.items = [];
		state.table = payload;
	},

	updateItem(state, payload) {
		let arr = state.table.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
	resetModal(state, payload) {
		if (payload == 'resources') {
			store.state.resources.resources.sharedResources.items = [];
			store.state.resources.modal.fields.files = [];
			store.state.resources.modal.fields.description = '';
		} else if (payload == 'attachmentFromStorage') {
			store.state.storage.storage.attachment.file = null;
		} else if (store.state[payload]) {
			let arr = store.state[payload].modal.fields;

			Object.keys(arr).forEach((key) => {
				if (arr[key] && arr[key].constructor.name == 'Array') arr[key] = [];
				else if (payload === 'registries' && key === 'user') {
					arr[key] = {
						users: [],
						groups: [],
						departments: [],
					};
				} else if (payload === 'registries' && key === 'reader') {
					arr[key] = {
						users: [],
						groups: [],
						departments: [],
					};
				} else if (payload === 'tasks' && key === 'people') {
					arr[key] = {
						users: [],
						departments: [],
					};
				} else if (payload === 'tasks' && key === 'isDone') {
					arr[key] = false;
				} else if (payload === 'notifications' && key === 'to') {
					arr[key] = {
						users: [],
						departments: [],
					};
				} else if (payload === 'currentInformations' && key === 'to') {
					arr[key] = {
						users: [],
						departments: [],
					};
				} else if (payload === 'devices' && key === 'implementationData') {
					arr[key] = {
						precertifying: '',
						settings: '',
					};
				} else if (payload == 'devices' && key == 'modelDevices') {
					arr[key] = {
						model: null,
						series: null,
						producer: null,
						deviceType: null,

						price: {
							diagnostics: {
								price: null,
							},
							installation: {
								price: null,
							},
							itService: {
								price: null,
							},
							priceName: null,
							repair: {
								price: null,
							},
							survey: {
								price: null,
							},
						},
					};
				} else if (payload == 'devices' && key == 'delivery') {
					arr[key] = {
						delivery: {
							apartmentNumber: '',
							city: '',
							email: '',
							houseNumber: '',
							name: '',
							postCode: '',
							street: '',
						},
					};
				} else if (payload == 'devices' && key == 'dedicatedPrice') {
					arr[key] = {
						diagnostics: '',
						installation: '',
						itService: '',
						priceName: '',
						repair: '',
						survey: '',
					};
				} else if (payload == 'service' && key == 'device') {
					arr[key] = {
						descriptionIntervention: {
							descriptionClient: '',
							descriptionLogistic: '',
							representatives: [],
							technicItems: [],
							intervention: '',
							technic: '',
							technicPerforming: '',
							data: null,
							time: null,
						},

						modelDevices: {
							deviceType: null,
							price: {
								priceName: null,
								diagnostics: {
									price: null,
								},
								installation: {
									price: null,
								},
								itService: {
									price: null,
								},
								repair: {
									price: null,
								},
								survey: {
									price: null,
								},
							},
						},
						dedicatedPrice: {
							checkbox: false,
							diagnostics: '',
							installation: '',
							itService: '',
							priceName: '',
							repair: '',
							survey: '',
						},
						client: {
							representatives: [],
							houseNumber: '',
							apartmentNumber: '',
							email: '',
							postCode: '',
							phoneNumber: '',
							city: '',
							floor: '',
							street: '',
							name: '',
						},
						address: {
							postCode: '',
							phoneNumber: '',
							name: '',
							location: '',
							email: '',
							city: '',
							address: '',
						},
						floor: '',

					};
				} else if (payload == 'service' && key == 'service') {
					arr[key] = {
						temporaryInformation: {
							devices: '',
							client: '',
						},

						modelDevices: {
							model: null,
							series: null,
							producer: null,
							deviceType: null,
						},

						descriptionIntervention: {
							descriptionClient: '',
							descriptionLogistic: '',
							representative: {
								name: '',
								lastname: '',
								phoneNumber: '',
								email: '',
							},
							intervention: '',
							technic: '',
							data: null,
							time: null,
						},
						realisation: {
							survey: false,
							repair: false,
							installation: false,
							diagnostics: false,
							itService: false,
							firstInstallation: false,
							repairWorkshop: false,
							remoteService: false,
							continuation: false,
							warranty: false,
							complaint: false,
							urgent: false,
							costInformation: false,
						},
						lastServiceDetails: {},
					};
				} else {
					arr[key] = '';
				}
			});
		}
	},

	resetErrors(state, payload) {
		let arr = store.state[payload].modal.errors;
		Object.keys(arr).forEach((key) => {
			arr[key] = '';
		});
		if (payload === 'devices') {
			let price = store.state[payload].errors.dedicatedPrice;
			Object.keys(price).forEach((key) => {
				price[key] = null;
			});
		}
	},
	setButtonsDisabled(state, payload) {
		state.buttons.save = true;
	},
	setButtonsEnabled(state, payload) {
		state.buttons.save = false;
	},

	setErrors(state, payload) {
		for (let error in payload.err.errors) {
			let kind = payload.err.errors[error]['kind'];
			let element = payload.err.errors[error]['path'];

			if (kind == 'required' || kind == 'ObjectID') {
				store.state[payload.route].modal.errors[element] = 'Pole wymagane';
			} else if (kind == 'unique') {
				store.state[payload.route].modal.errors[element] = 'Już istnieje';
			} else if (kind == 'maxlength') {
				store.state[payload.route].modal.errors[element] = 'Za długie';
			} else if (kind == 'passwordMatch') {
				store.state[payload.route].modal.errors[element] =
					'Hasła się nie zgadzają';
			}
		}
	},
	setDefaultMenager(state, payload) {
		state.defaultMenager = payload;
	},
	setDetails(state, payload) {
		store.state[payload.route].modal.fields = payload.items;
	},
	setPermission(state, payload) {
		state.permission = payload;
	},
	setExtras(state, payload) {
		state.fetchExtras = payload;
	},
	resetExtras(state) {
		state.fetchExtras = {};
	},
	setDetailsStatuses(state, payload) {
		state.detailsStatuses = payload;
	},
	setDetailsStatus(state, payload) {
		state.detailsStatus = payload;
	},
	setDetailsIndex(state, payload) {
		state.detailsIndex = payload;
	},
	setDetailsMenager(state, payload) {
		if (payload.update == true) {
			store.dispatch('sendMail', {
				case: 'menagerNotification',
				receivers: [{
					mail: payload.mail,
					name: payload.name,
					lastname: payload.lastname,
				}, ],
				info: {},
			});
		}
		state.detailsMenager = payload;
	},
	setSessionTimedOut(state, payload) {
		state.sessionTimedOut = payload;
	},
	// trzeba doodać na sztywno aby ć WSD
	setWSD(state, payload) {
		// localStorage.setItem('isChecked', payload);
		state.layout.wsd = payload;
		// alert(payload)
	},
	openOfficePreview(state, payload) {
		let r = Math.random()
			.toString(36)
			.substring(2);

		let url = `${payload.url}?${r}`;

		state.officePreview.url = url;
		state.officePreview.open = true;
	},
	closeOfficePreview(state) {
		state.officePreview.url = 'payload.url';
		state.officePreview.open = false;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};