var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12 my-3"}},[_c('div',{staticClass:"reminders"},[_c('div',{staticClass:"headline font-weight-light reminders__title"},[_vm._v("ALERTY")])])]),_c('v-col',{staticClass:"reminders__card",attrs:{"cols":"12"}},[_vm._l((_vm.getRBNotifications),function(notification){return _c('v-alert',{key:notification._id,staticClass:"task pa-5",staticStyle:{"margin-right":"10px"},attrs:{"border":"left","colored-border":"","color":notification.priority ? notification.priority.background : 'grey lighten-1',"elevation":"2"}},[_c('div',{staticClass:"tasks__content",staticStyle:{"padding":"10px 0"}},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.shortDescription(notification.description, 120)))])]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":"280","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"tasks__from"},[_c('span',_vm._g({staticStyle:{"margin-top":"10px"}},on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"font-size":"11px !important","margin-top":"10px"}},on),[_c('v-icon',{attrs:{"size":"11"}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(notification.createdBy.name)+" "+_vm._s(notification.createdBy.lastname)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Autor")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(notification.dueDate)?_c('span',_vm._g({},on),[_c('v-icon',{attrs:{"size":"11"}},[_vm._v("mdi-calendar-clock")]),_vm._v(" "+_vm._s(_vm.$moment(notification.dueDate).format('YYYY-MM-DD'))+" "+_vm._s(notification.dueTime)+" ")],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Termin")])])],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-4",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-start","color":"#757575","font-size":"14px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-avatar',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"color":"teal","size":"40"}},_vm.on),[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(notification.createdBy.name.charAt(0)))])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-left":"15px"}},[_c('span',{staticStyle:{"font-size":"15px","color":"black"}},[_vm._v(_vm._s(notification.createdBy.name)+" "+_vm._s(notification.createdBy.lastname))]),_c('span',[_vm._v(_vm._s(notification.createdBy.job))])])],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('v-divider',{staticClass:"my-3"}),_c('p',{staticClass:"ma-0"},[_c('b',[_vm._v("Email:")]),(notification.createdBy.mail)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.mailTo(notification.createdBy.mail))}}):_c('span',[_vm._v("Brak")])]),_c('p',{staticClass:"ma-0"},[_c('b',[_vm._v("Kontakt:")]),(notification.createdBy.phone)?_c('span',[_vm._v(_vm._s(notification.createdBy.phone))]):_c('span',[_vm._v("Brak")])])],1)])],1),_c('div',{staticClass:"tasks__buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"25px","height":"25px"},attrs:{"fab":"","dark":"","to":_vm.tasksRoute,"color":"#2f445e"}},on),[_c('v-icon',{attrs:{"size":"15","color":"white"}},[_vm._v("mdi-arrow-right-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Przejdź do szczegółów")])])],1)],1)}),(_vm.getRBNotifications.length == 0)?_c('v-alert',{staticClass:"task pa-5",staticStyle:{"margin-right":"10px"},attrs:{"border":"left","colored-border":"","color":"grey lighten-1","elevation":"2"}},[_c('div',{staticClass:"tasks__title",staticStyle:{"padding":"0","font-size":"18px","color":"#595959"}},[_vm._v("Brak alertów")]),_c('span',{staticStyle:{"height":"1px","width":"100%","background-color":"lightgrey","display":"block","margin-bottom":"5px"}})]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }