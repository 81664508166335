<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="pt-0">
                <v-select
                    :items="getDepartmentsItems"
                    dense
                    v-model="getInternalContactsModal.unit"
                    :label="getInternalContactsFieldsNames.unit"
                    :error-messages="getInternalContactsError.unit"
                    return-object
                    item-value="fullName"
                    item-text="fullName"
                    @change="addLocation()"
                ></v-select>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
                <!-- {{getInternalContactsModal.unit}} -->
                <v-select
                    :items="departmentsWorkers"
                    dense
                    return-object
                    item-value="_id"
                    item-text="_id"
                    v-model="getInternalContactsModal.worker"
                    :label="getInternalContactsFieldsNames.worker"
                    @change="workerItems()"
                >
                    <template
                        v-slot:selection="users"
                    >{{ users.item.name }} {{ users.item.lastname }}</template>
                    <template v-slot:item="users">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ users.item.name }}
                                    {{ users.item.lastname }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-select>
            </v-col>

            <v-col cols="12" md="12" class="pt-0">
                <v-select
                    :items="organizationalUnit"
                    dense
                    v-model="getInternalContactsModal.organizationalUnit"
                    :label="getInternalContactsFieldsNames.organizationalUnit"
                    item-value="jobName"
                    item-text="jobName"
                ></v-select>
                <v-select
                    v-if="getModal.type == 'edit'"
                    dense
                    :items="correctUnitForPosition"
                    item-text="unit"
                    item-value="position"
                    label="Ustaw za"
                    v-model="getInternalContactsModal.changePosition"
                ></v-select>
            </v-col>
            <v-col cols="12" md="12">
                <div class="my-4">
                    Lokalizacja:
                    <span
                        style="font-weight: 700"
                    >{{getInternalContactsModal.unit.location ? getInternalContactsModal.unit.location : ""}}</span>
                </div>
                <div class="d-flex">
                    Telefon komórkowy:
                    <v-text-field
                        class="ma-0 contact__internal"
                        v-model="getInternalContactsModal.phone"
                        dense
                    ></v-text-field>
                </div>
                <div class="d-flex">
                    Telefon stacjonarny:
                    <v-text-field
                        class="ma-0 contact__internal"
                        v-model="getInternalContactsModal.landlinePhone"
                        dense
                    ></v-text-field>
                </div>
                <div class="d-flex">
                    Email:
                    <v-text-field
                        class="ma-0 contact__internal"
                        v-model="getInternalContactsModal.email"
                        dense
                    ></v-text-field>
                </div>
            </v-col>
            <v-col cols="12" md="12">
                <v-textarea
                    :label="getInternalContactsFieldsNames.comments"
                    v-model="getInternalContactsModal.comments"
                    rows="1"
                    no-resize
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    data: () => ({
        modaldata: false,
        ACReader: "users",
    }),
    computed: {
        ...mapGetters([
            "getInternalContactsError",
            "getInternalContactsModal",
            "getInternalContactsFieldsNames",
            "getDepartmentsItems",
            "getUserJobsList",
            "getPositionItems",
            "getModal",
        ]),

        correctUnitForPosition() {
            this.getPositionItems.forEach((position) => {
                if (position.unit && position.unit.fullName)
                    position.unit = position.unit.fullName;
                if (position.title) position.unit = position.title;
            });
            return this.getPositionItems;
        },

        checkWorkers() {
            this.getInternalContactsModal.worker
                ? this.getInternalContactsModal.worker
                : "";
        },

        departmentsWorkers() {
            let workers = this.getInternalContactsModal.unit
                ? this.getInternalContactsModal.unit.users
                : [];
            return workers;
        },
        organizationalUnit() {
            let organization = this.getUserJobsList ? this.getUserJobsList : [];
            return organization;
        },
    },
    methods: {
        ...mapActions([""]),

        workerItems() {
            this.getInternalContactsModal.phone = this.getInternalContactsModal
                .worker.phone
                ? this.getInternalContactsModal.worker.phone
                : "";
            this.getInternalContactsModal.landlinePhone = this
                .getInternalContactsModal.worker.privatePhone
                ? this.getInternalContactsModal.worker.privatePhone
                : "";
            this.getInternalContactsModal.email = this.getInternalContactsModal
                .worker.mail
                ? this.getInternalContactsModal.worker.mail
                : "";
        },

        addLocation() {
            this.getInternalContactsModal.worker = {};

            this.getInternalContactsModal.location = this
                .getInternalContactsModal.unit.location
                ? this.getInternalContactsModal.unit.location
                : " ";

            this.getInternalContactsModal.phone = this.getInternalContactsModal
                .unit.phone
                ? this.getInternalContactsModal.unit.phone
                : " ";

            this.getInternalContactsModal.landlinePhone = this
                .getInternalContactsModal.unit.privatePhone
                ? this.getInternalContactsModal.unit.privatePhone
                : " ";

            this.getInternalContactsModal.email = this.getInternalContactsModal
                .unit.mail
                ? this.getInternalContactsModal.unit.mail
                : " ";
        },
    },
};
</script>
