<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="
        item.status &&
        item.status.possibleStatus &&
        item.status.possibleStatus.resolved
          ? 'color: #9e9e9e'
          : ''
      "
    >
      <!-- === === INDEX === === -->
      <td class="serviceMobileView">{{ item.index }}</td>

      <!-- === === DATA UTWORZENIA === === -->
      <td class="hidedMobileColumns" style="font-size: 12px">
        {{
          item.createdAt ? $moment(item.createdAt).format('YYYY-MM-DD') : 'Brak'
        }}
        <br />
        {{ item.createdAt ? $moment(item.createdAt).format('HH:mm') : 'Brak' }}
      </td>

      <!-- === === KLIENT === === -->
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <td
            v-on="on"
            class="service-text-14"
            :class="
              item.device.client && item.device.client._id ? 'pointer' : ''
            "
            @click="goToContacts(item.device.client._id)"
          >
            {{
              item.device && item.device.client && item.device.client._id
                ? item.device.client.name
                : item.service.temporaryInformation.client
                ? item.service.temporaryInformation.client
                : 'Brak'
            }}
          </td>
        </template>
        <span
          >ID:
          {{
            item.device && item.device.client && item.device.client.contactId
              ? item.device.client.contactId
              : 'Brak'
          }}</span
        >
      </v-tooltip>
      <!-- === === URZĄDZENIE === === -->
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <td class="serviceMobileView" v-on="on">
            <v-list-item-title class="tabeleFont">
              <span v-if="item.device.modelDevices.producer" class="pa-0">
                {{ item.device.modelDevices.producer }}
                {{ item.device.modelDevices.model }}
              </span>
              <span v-else class="pa-0">{{
                item.service.temporaryInformation.devices
                  ? item.service.temporaryInformation.devices
                  : 'Brak'
              }}</span>
            </v-list-item-title>
            <v-list-item-subtitle
              style="font-size: 12px !important"
              class="grey--text lighten-1"
            >
              {{
                item.device.modelDevices.deviceType
                  ? item.device.modelDevices.deviceType
                  : ''
              }}
            </v-list-item-subtitle>
          </td>
        </template>
        <span>S/N: {{ item.device.SN }}</span>
      </v-tooltip>

      <!-- === === TECHNIK === ===  -->
      <td class="hidedMobileColumns serviceMobileView">
        <v-menu
          :disabled="item.status === 'Usunięty' || getPermission.level < 2"
        >
          <template v-slot:activator="{ on: menu }">
            <span v-on="menu" @click="getServiceman()" class="pointer">
              {{
                item.service.descriptionIntervention.technicPerforming
                  ? `${item.service.descriptionIntervention.technicPerforming.name} ${item.service.descriptionIntervention.technicPerforming.lastname}`
                  : 'Brak'
              }}
            </span>
          </template>
          <v-list>
            <v-list-item
              style="min-height: 36px,"
              v-for="(servicemen, index) in serviceman"
              :key="index"
              @click="
                serviceEditInTable({
                  id: item._id,
                  technic: servicemen,
                })
              "
            >
              <v-list-item-title>
                {{ servicemen.name }}
                {{ servicemen.lastname }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                serviceEditInTable({
                  id: item._id,
                  technic: 'Brak',
                })
              "
              >Brak</v-list-item
            >
          </v-list>
        </v-menu>
      </td>

      <!-- === === STATUS === === -->
      <td>
        <v-menu :disabled="!isLogistic">
          <template v-slot:activator="{ on: menu }">
            <v-chip
              @click="getStatuses"
              class="serviceMobileView"
              :color="
                item.status &&
                item.status.possibleStatus &&
                item.status.possibleStatus.color &&
                item.status.possibleStatus.color.background
                  ? item.status.possibleStatus.color.background
                  : '#555555'
              "
              :style="
                `color: ${
                  item.status &&
                  item.status.possibleStatus &&
                  item.status.possibleStatus.color &&
                  item.status.possibleStatus.color.font
                    ? item.status.possibleStatus.color.font
                    : '#FFFFFF'
                }`
              "
              dark
              label
              small
              v-on="menu"
            >
              {{
                item.status &&
                item.status.possibleStatus &&
                item.status.possibleStatus.name
                  ? item.status.possibleStatus.name
                  : 'Brak'
              }}
            </v-chip>
          </template>
          <v-list>
            <v-list-item
              v-for="status in statuses"
              :key="status._id"
              @click="
                setStatus({
                  registryName: 'service_details',
                  registryID: item._id,
                  status: status,
                }).then(() => (item.status.possibleStatus = status))
              "
              >{{ status.name }}</v-list-item
            >
          </v-list>
        </v-menu>
      </td>

      <!-- === === TERMINY === === -->

      <td>
        <v-list-item-title class="title--smaller tabeleFont">
          <!-- <v-icon color="#19aa8d" size="15" class="mx-1">mdi-play-circle</v-icon> -->
          <v-avatar
            color="orange"
            size="12"
            style="margin-left: 5px; margin-right: 6px"
          >
            <span class="white--text" style="font-size: 11px; margin-top: 2px"
              >P</span
            >
          </v-avatar>
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            :disabled="getPermission.level < 2"
          >
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                class="pointer"
                @click="
                  showCorrecrDateTerm(
                    item.service.descriptionIntervention.finishDate
                  )
                "
              >
                {{
                  item.service.descriptionIntervention.finishDate
                    ? $moment(
                        item.service.descriptionIntervention.finishDate
                      ).format('YYYY-MM-DD')
                    : '------'
                }}
              </span>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="termsDate"
              @input="
                serviceEditInTable({
                  id: item._id,
                  finishDate: termsDate,
                })
              "
            ></v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            :disabled="getPermission.level < 2"
          >
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                class="ml-1 pointer"
                @click="
                  setTermsTime(item.service.descriptionIntervention.finishTime)
                "
              >
                {{
                  item.service.descriptionIntervention.finishDate &&
                  !item.service.descriptionIntervention.finishTime
                    ? '--'
                    : item.service.descriptionIntervention.finishTime
                }}
              </span>
            </template>
            <v-time-picker
              v-model="termsTime"
              full-width
              format="24hr"
              @click:minute="
                serviceEditInTable({
                  id: item._id,
                  finishTime: termsTime,
                })
              "
            ></v-time-picker>
          </v-menu>
        </v-list-item-title>
        <v-list-item-title
          class="title--smaller tabeleFont"
          v-if="
            item.implementation &&
              item.implementation.service &&
              item.implementation.service.dates &&
              item.implementation.service.dates.end
          "
        >
          <v-icon color="red" size="15" class="mx-1">mdi-stop-circle</v-icon>
          {{
            $moment(item.implementation.service.dates.end).format('YYYY-MM-DD')
          }}
          {{
            item.implementation.service &&
            item.implementation.service.times &&
            item.implementation.service.times.end
              ? item.implementation.service.times.end
              : 'Brak'
          }}
        </v-list-item-title>
        <v-list-item-title class="title--smaller" v-else>
          <v-icon color="red" size="15" class="mx-1">mdi-stop-circle</v-icon
          >------
        </v-list-item-title>
      </td>

      <!-- === === IKONKI === === -->
      <td>
        <div class="d-flex">
          <div class="d-flex" v-if="item.implementation">
            <!-- === FAKTURA === -->
            <v-tooltip
              top
              v-if="item.implementation.requirements.invoice.checkbox"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :style="
                    `background-color: ${
                      item.implementation.requirements.invoice.issue
                    };  width: ${
                      window.width < 1200 ? '20px' : '28px'
                    } ; height: ${window.width < 1200 ? '20px' : '28px'}`
                  "
                  @click="changeStatus(item._id, 'invoice_status')"
                  icon
                  :disabled="
                    `${item.implementation.requirements.invoice.issue}` ===
                    'grey'
                      ? true
                      : false
                  "
                  class="mr-1"
                >
                  <span style="color: white">F</span>
                </v-btn>
              </template>
              <span>Faktura</span>
            </v-tooltip>

            <!-- === CLAIM === -->
            <v-tooltip
              top
              v-if="item.implementation.requirements.claim.checkbox"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  v-on="on"
                  :style="
                    `background-color: ${
                      item.implementation.requirements.claim.issue
                    }; width: ${
                      window.width < 1200 ? '20px' : '28px'
                    } ; height: ${window.width < 1200 ? '20px' : '28px'};
                                        cursor: ${
                                          item.implementation.requirements.claim
                                            .issue == 'grey'
                                            ? 'default'
                                            : 'pointer'
                                        }`
                  "
                  @click="
                    changeStatus(
                      item._id,
                      'claim_status',
                      item.implementation.requirements.claim.issue
                    )
                  "
                  icon
                  class="mr-1"
                >
                  <span style="color: white">C</span>
                </v-btn>
              </template>
              <span>
                Claim
                <span
                  v-if="
                    item.implementation.requirements.claim &&
                      item.implementation.requirements.claim.number
                  "
                  >nr: {{ item.implementation.requirements.claim.number }}</span
                >
              </span>
            </v-tooltip>
            <!-- === WYCENA === -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="changeStatus(item._id, 'valuation_status')"
                  small
                  :style="
                    `background-color: ${
                      item.implementation.requirements.valuation.issue
                    }; width: ${
                      window.width < 1200 ? '20px' : '28px'
                    } ; height: ${window.width < 1200 ? '20px' : '28px'}`
                  "
                  v-if="
                    item.implementation.requirements.valuation &&
                      item.implementation.requirements.valuation.checkbox
                  "
                  icon
                  :disabled="
                    `${item.implementation.requirements.valuation.issue}` ===
                    'grey'
                      ? true
                      : false
                  "
                  class="mr-1"
                >
                  <span style="color: white">W</span>
                </v-btn>
              </template>
              <span>Wycena</span>
            </v-tooltip>

            <!-- === KONTYNUACJA ===  -->
            <v-tooltip top v-if="item.implementation.requirements.continuation">
              <template v-slot:activator="{ on }">
                <v-avatar
                  v-on="on"
                  color="orange"
                  :size="window.width > 1200 ? '28' : '20'"
                  class="mr-1"
                >
                  <v-btn
                    class="white--text"
                    @click="newContinuation(item)"
                    :disabled="
                      item.service.realisation.continuationIssued == true
                        ? true
                        : false
                    "
                    :style="
                      `background: ${
                        item.service.realisation.continuationIssued == true
                          ? 'grey !important'
                          : 'orange !important'
                      }; `
                    "
                  >
                    <span class="white--text">K</span>
                  </v-btn>
                </v-avatar>
              </template>
              <span>Kontynuacja</span>
            </v-tooltip>
          </div>
          <!-- === KONTYNUACJA === -->
          <v-tooltip top v-if="item.service.realisation.continuation">
            <template v-slot:activator="{ on }">
              <v-avatar
                v-on="on"
                :color="
                  item.status.possibleStatus.name == 'Zamknięty'
                    ? '#808080'
                    : 'green'
                "
                :size="window.width > 1200 ? '28' : '20'"
                class="mr-1"
              >
                <span class="white--text">K</span>
              </v-avatar>
            </template>
            <span>Kontynuacja | {{ item.lastIndex }}</span>
          </v-tooltip>

          <!-- === PILNE === -->
          <v-tooltip top v-if="item.service.realisation.urgent">
            <template v-slot:activator="{ on }">
              <v-avatar
                v-on="on"
                color="red"
                :size="window.width > 1200 ? '28' : '20'"
                class="mr-1"
              >
                <span class="white--text">P</span>
              </v-avatar>
            </template>
            <span>Pilne</span>
          </v-tooltip>

          <!-- === GWARANCJA ===  -->
          <v-tooltip top v-if="item.service.realisation.warranty">
            <template v-slot:activator="{ on }">
              <v-avatar
                v-on="on"
                color="#964B84"
                :size="window.width > 1200 ? '28' : '20'"
                class="mr-1"
              >
                <span class="white--text">G</span>
              </v-avatar>
            </template>
            <span>Gwarancja</span>
          </v-tooltip>
        </div>
      </td>
      <!-- :style=" `width: ${window.width < 1200 ? '20px' : '28px'} ; height: ${window.width < 1200 ? '20px' : '28px'}`" -->
      <!-- === === AKCJA === === -->
      <td style="text-align: right">
        <v-btn
          icon
          small
          @click="$router.push({ path: `/service/${item._id}` })"
        >
          <v-icon
            color="#19aa8d"
            :style="`font-size: ${window.width < 1200 ? '22px' : '16px'}`"
            small
            >mdi-arrow-right-thick</v-icon
          >
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['items'],
  data: () => ({
    serviceman: [],
    termsDate: null,
    termsTime: null,
    isLogistic: false,

    statuses: [],

    // === === CHECK WINDOW SIZE === ===
    window: {
      width: 0,
      height: 0,
    },
  }),
  created() {
    // === === CHECK WINDOW SIZE === ===
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    // === === CHECK WINDOW SIZE === ===
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount() {
    this.fetchIsLogistic();
  },
  computed: {
    ...mapGetters(['getPermission']),
  },
  methods: {
    // === === CHECK WINDOW SIZE === ===
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    ...mapActions(['serviceEditInTable', 'openSecondModal', 'setStatus']),
    // === REDIRECT TO CONTACTS ===
    goToContacts(id) {
      if (id) this.$router.push(`/contacts/${id}`);
    },
    destroyed() {
      // === === CHECK WINDOW SIZE === ===
      window.removeEventListener('resize', this.handleResize);
    },
    // === FETCH ALL SERVICEMAN ===
    getServiceman() {
      Vue.axios({
        method: 'GET',
        url: '/service/implementation/serviceman',
      }).then((response) => {
        this.serviceman = response.data.items.users;
      });
    },

    // === GET STATUSES NEEDED FOR CHANGE ===
    getStatuses() {
      Vue.axios({
        method: 'GET',
        url: '/service/statuses',
      }).then((response) => {
        this.statuses = response.data.statuses;
      });
    },

    // === SHOW CORRECT DATE IN DATEPICKER ===
    showCorrecrDateTerm(date) {
      if (date) {
        this.termsDate = this.$moment(date).format('YYYY-MM-DD');
      } else {
        this.termsDate = '';
      }
    },

    // === TIME IN TIMEPICKER ===
    setTermsTime(time) {
      this.termsTime = time;
    },

    // === CHANGE SERVICE STATUS ===

    changeStatus(id, type, color) {
      if (color != 'grey') {
        this.openSecondModal({
          width: 500,
          open: true,
          id: id,
          type: type,
        });
      }
    },
    newContinuation(item) {
      this.openSecondModal({
        width: 500,
        open: true,
        registryName: 'service',
        id: item.id,
        item: item,
        type: 'new_continuation',
      });
    },

    // === CHECK IF IS LOGISTIC === //
    fetchIsLogistic() {
      Vue.axios({
        method: 'GET',
        url: '/service/isServiceLogistic',
      }).then((response) => {
        this.isLogistic = response.data.isLogistics;
      });
    },
  },
};
</script>
