import Vue from 'vue';
import i18n from '@/i18n.js';
import findIndex from 'lodash.findindex';
import moment from 'moment-timezone';
const state = {
	details: false,
	// === === TASKS === === //
	tasks: {
		title: 'tasks.title',
		header: [
			{
				text: i18n.t('tasks.header.from'),
				align: 'left',
				value: 'createdBy',
				width: '175',
				class: 'central__table__header',
			},
			{
				text: i18n.t('tasks.header.title'),
				value: 'name',
				class: 'central__table__header',
			},
			{
				text: i18n.t('tasks.header.date'),
				value: 'termDate',
				width: '150',
				class: 'central__table__header',
			},
			{
				text: i18n.t('tasks.header.category'),
				value: 'category',
				align: 'center',
				width: '150',
				class: 'central__table__header',
			},
			{
				text: i18n.t('tasks.header.priority'),
				value: 'priorityTasks',
				align: 'center',
				width: '150',
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'center',
				width: '100',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
		count: '',
	},
	modal: {
		dictionaries: {
			category: [],
			priority: [],
		},
		fields: {
			name: '',
			description: '',
			category: '',
			termDate: '',
			termTime: '',
			priority: '',
			people: {
				users: [],
				departments: [],
			},
			comment: '',
			registryID: '',
			registryName: '',
			isDone: false,
			attachments: [],
			files: [],
		},
		errors: {
			name: '',
		},
	},
	RightBox: [],
};

const getters = {
	getTasks: (state) => state.tasks,
	getTaskData: (state) => state.modal.fields,
	getTaskDictionary: (state) => state.modal.dictionaries,
	getTaskErrors: (state) => state.modal.errors,
	getRightBoxTasks: (state) => state.RightBox,
	getTasksCount: (state) => state.tasks.count,
};

const actions = {
	async createTask({ commit, state, dispatch }, payload) {
		let formData = new FormData();
		formData.append('name', state.modal.fields.name);
		formData.append('description', state.modal.fields.description);
		formData.append(
			'category',
			JSON.stringify(state.modal.fields.category),
		);
		formData.append('termDate', state.modal.fields.termDate);
		formData.append('termTime', state.modal.fields.termTime);
		formData.append(
			'priority',
			JSON.stringify(state.modal.fields.priority),
		);
		formData.append('people', JSON.stringify(state.modal.fields.people));
		formData.append('comment', state.modal.fields.comment);
		formData.append('registryID', state.modal.fields.registryID);
		formData.append('registryName', state.modal.fields.registryName);
		formData.append('isDone', state.modal.fields.isDone);
		state.modal.fields.attachments.forEach((attachment) => {
			formData.append('attachments', attachment);
		});
		state.modal.fields.files.forEach((item) => {
			formData.append('files', item);
		});

		await Vue.axios({
			method: 'POST',
			url: `/tasks/${payload.registryName}`,
			data: formData,
		})
			.then((response) => {
				console.log(response.data);

				let users = [];
				response.data.people.departments.forEach((department) => {
					users.push(...department.users);
				});
				users.push(...response.data.people.users);
				dispatch('sendMail', {
					case: 'newTask',
					receivers: users,
					info: {
						description: response.data.description,
						name: response.data.name,
						term: `${
							response.data.termDate
								? moment(response.data.termDate).format(
										'YYYY-MM-DD',
								  ) +
								  ' ' +
								  response.data.termTime
								: ''
						}`,
						createdBy:
							response.data.createdBy.name +
							' ' +
							response.data.createdBy.lastname,
					},
				});
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'tasks',
				});
				dispatch('tasksCount', {
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'tasks',
					err: error.response.data.error,
				});
			});
	},
	async fetchTCategory({ commit }, payload) {
		await Vue.axios({
			method: 'GET',
			url: '/dictionary/tasks/category',
		}).then((response) => {
			commit('setTDCategory', response.data.items);
		});
	},
	async fetchTPriority({ commit }, payload) {
		await Vue.axios({
			method: 'GET',
			url: '/dictionary/tasks/priority',
		}).then((response) => {
			commit('setTDPriority', response.data.items);
		});
	},

	async taskDetails({ commit }, payload) {
		await Vue.axios({
			method: 'GET',
			url: `/tasks/${payload.id}/${payload.registryName}`,
		})
			.then(async (response) => {
				commit('setTasksDetails', response.data.item);
				await Vue.axios({
					methods: 'GET',
					url: `/attachments/findAll/${state.modal.fields.registryName}/${state.modal.fields.registryID}`,
				}).then((response) => {
					let arr = [];
					state.modal.fields.attachments.filter((attachment) => {
						response.data.attachments.forEach((el) => {
							if (el._id === attachment) {
								arr.push(el);
							}
						});
					});
					state.modal.fields.attachments = arr.slice();
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},

	async doneTask({ state, dispatch, commit }, payload) {
		await Vue.axios({
			method: 'PUT',
			url: `/tasks/done/${state.modal.fields._id}/${payload.registryName}`,
			data: {
				comment: state.modal.fields.comment,
			},
		})
			.then(() => {
				let route = '';
				if (payload.registryName == 'tasks') route = 'tasks';
				else route = 'tasks_tab';
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: route,
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie zakończono zadanie',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	async saveTComment({ state, commit }, payload) {
		await Vue.axios({
			method: 'PUT',
			url: `/tasks/editComment/${state.modal.fields._id}/${payload.registryName}`,
			data: {
				comment: state.modal.fields.comment,
			},
		})
			.then(() => {
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano zadanie',
				});
			})
			.catch((error) => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},
	async createTaskGlobal({ state, commit, dispatch }, payload) {
		let formData = new FormData();
		formData.append('name', state.modal.fields.name);
		formData.append('description', state.modal.fields.description);
		formData.append(
			'category',
			JSON.stringify(state.modal.fields.category),
		);
		formData.append('termDate', state.modal.fields.termDate);
		formData.append('termTime', state.modal.fields.termTime);
		formData.append(
			'priority',
			JSON.stringify(state.modal.fields.priority),
		);
		formData.append('people', JSON.stringify(state.modal.fields.people));
		formData.append('comment', state.modal.fields.comment);
		formData.append('registryID', payload.registryID);
		formData.append('registryName', payload.registryName);
		formData.append('isDone', state.modal.fields.isDone);
		state.modal.fields.attachments.forEach((attachment) => {
			formData.append('attachments', attachment);
		});
		state.modal.fields.files.forEach((item) => {
			formData.append('files', item);
		});

		await Vue.axios({
			method: 'POST',
			url: `/global/task/create`,
			data: formData,
		})
			.then((response) => {
				let users = [];
				response.data.item.people.departments.forEach((department) => {
					users.push(...department.users);
				});
				users.push(...response.data.item.people.users);
				dispatch('sendMail', {
					case: 'newTask',
					receivers: users,
					info: {
						description: response.data.item.description,
						name: response.data.item.name,
					},
				});
				dispatch('tasksCount', {
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'tasks_tab',
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch((error) => {
				console.log(error.response);
				commit('setErrors', {
					route: 'tasks',
					err: error.response.data.error,
				});
			});
	},
	RBTasks({ commit }, payload) {
		if (!payload) {
			Vue.axios({
				method: 'GET',
				url: '/rightbox/tasks',
			})
				.then((response) => {
					commit('setRightBoxItems', response.data.items);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},
	tasksCount({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/global/find/count/tasks`,
			params: payload,
		}).then((response) => {
			state.tasks.count = response.data;
		});
	},
};

const mutations = {
	removePerson(state, payload) {
		let arr = state.modal.fields.people[payload.section];
		let index = findIndex(arr, (e) => e == payload.id);
		arr.splice(index, 1);
	},
	setAllUsers(state, payload) {
		state.modal.fields.people.users = [];
		payload.forEach((item) => {
			state.modal.fields.people.users = [
				...state.modal.fields.people.users,
				item._id,
			];
		});
	},
	setTDCategory(state, payload) {
		state.modal.dictionaries.category = payload;
	},
	setTDPriority(state, payload) {
		state.modal.dictionaries.priority = payload;
	},
	clearTDCategory(state) {
		state.modal.fields.category = null;
	},
	clearTDPriority(state) {
		state.modal.fields.priority = null;
	},
	setTasksDetails(state, payload) {
		state.modal.fields = payload;

		if (payload.termDate === null) {
			state.modal.fields.termDate = '';
		} else {
			state.modal.fields.termDate = moment(payload.termDate).format(
				'YYYY-MM-DD',
			);
		}
	},
	setTabTasksItems(state, payload) {
		state.tab.items = payload;
	},
	setOnlyOneUser(state, payload) {
		state.modal.fields.people.users = [];
		state.modal.fields.people.users.push(payload);
	},
	setRightBoxItems(state, payload) {
		state.RightBox = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
