
<template>
  <div class="buttons__mobile">
    <v-menu
      v-if="
        getServiceDetails.service.temporaryInformation.client != '' ||
        getServiceDetails.service.temporaryInformation.devices !== ''
      "
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{}">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              dark
              @click="menu = true"
              class="buttons--add mx-2"
              fab
              small
              >S/N</v-btn
            >
          </template>
          <span>Przypisz do S/N</span>
        </v-tooltip>
      </template>

      <v-card class="pa-4" height="110">
        <v-text-field dense label="Numer seryjny urządzenia" v-model="newSN">
          <template slot="prepend">
            <v-avatar color="#19aa8d" class="mt-1" size="20">
              <span class="white--text" style="font-size: 10px">S/N</span>
            </v-avatar>
          </template>
        </v-text-field>
        <v-btn
          @click="attributionSN({ id: getServiceDetails._id, SN: newSN })"
          dark
          class="buttons buttons--add no-print"
          style="position: absolute; right: 30px"
          >Zapisz</v-btn
        >
      </v-card>
    </v-menu>
    <!-- === KONTYNUACJA ===  -->
    <v-tooltip left top v-if="checkContinuation">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          target="_BLANK"
          @click="newContinuation(getServiceDetails)"
          small
          :disabled="
            getServiceDetails.service.realisation.continuationIssued == true
              ? true
              : false
          "
          :style="`background: ${
            getServiceDetails.service.realisation.continuationIssued == true
              ? 'grey !important'
              : '#19aa8d !important'
          }; width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }`"
          class="buttons--add mr-3"
          >K</v-btn
        >
      </template>
      <span>Kontynuacja</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          target="_BLANK"
          @click="print('client')"
          :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }`"
          small
          class="buttons--add"
        >
          <v-icon small>mdi-note-multiple-outline</v-icon>
        </v-btn>
      </template>
      <span>Podgląd zlecenia</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          target="_BLANK"
          @click="print('logistic')"
          small
          :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }`"
          class="buttons--add mx-3"
        >
          <v-icon small>mdi-file-outline</v-icon>
        </v-btn>
      </template>
      <span>Podgląd karty obiegowej</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }; background: ${
            getDetailsStatus.name == 'Zamknięty' && getIsTechnics ? 'gray' : ''
          } !important; cursor: ${
            getDetailsStatus.name == 'Zamknięty' && getIsTechnics
              ? 'default'
              : 'pointer'
          }`"
          small
          class="buttons--add"
          @click="serviceEdit"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edytuj informacje</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    newSN: "",
    menu: "",
    window: {
      width: 0,
      height: 0,
    },
  }),
  computed: {
    ...mapGetters([
      "getServiceDetails",
      "getSearchedDevicesItems",
      "getPermission",
      "getServiceOrderType",
      "getDetailsStatus",
      "getIsTechnics",
    ]),
    checkContinuation() {
      if (
        this.getServiceDetails.implementation &&
        this.getServiceDetails.implementation.requirements &&
        this.getServiceDetails.implementation.requirements.continuation
      ) {
        if (
          this.getServiceDetails.implementation.requirements.continuation ===
          true
        ) {
          return true;
        }
        return false;
      }
    },
  },
  // === === CHECK WINDOW SIZE === ===
  created() {
    this.handleResize();
  },
  methods: {
    attributionSN(e) {
      this.addNewSN(e);
      // this.menu = false;
    },
    ...mapActions([
      "openModal",
      "addNewSN",
      "serviceOrderDeatils",
      "representativesEditItems",
      "fetchService",
      "findTechnics",
      "openSecondModal",
      "serviceSN",
    ]),
    ...mapMutations(["setOrderType"]),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    serviceEdit() {
      if (this.getDetailsStatus.name == "Zamknięty") {
        if (!this.getIsTechnics) {
          this.findTechnics();
          this.serviceSN({
            id: this.$route.params.id,
          });
          this.serviceOrderDeatils({
            id: this.$route.params.id,
            type: "modal",
          }).then(() => {
            this.openModal({
              headLine: "global.create",
              width: 1500,
              open: true,
              type: "edit",
              route: "service",
              tab: "new_service",
            });
          });
        }
      } else {
        this.findTechnics();
        this.serviceSN({
          id: this.$route.params.id,
        });
        this.serviceOrderDeatils({
          id: this.$route.params.id,
          type: "modal",
        }).then(() => {
          this.openModal({
            headLine: "global.create",
            width: 1500,
            open: true,
            type: "edit",
            route: "service",
            tab: "new_service",
          });
        });
      }
    },
    print(type) {
      // this.$router.push("/print");
      let routeData = this.$router.resolve({
        name: "print",
        query: { id: this.$route.params.id, type: type },
      });
      window.open(routeData.href, "_blank");
    },
    fetchSN() {
      this.newSN = this.getServiceDetails.device.SN
        ? this.getServiceDetails.device.SN
        : "";
    },
    newContinuation(item) {
      this.openSecondModal({
        width: 500,
        open: true,
        registryName: "service",
        id: item.id,
        item: item,
        type: "new_continuation",
      });
    },
  },
  beforeMount() {
    this.fetchSN();
  },
};
</script>
