<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="createModel">
                <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj nowy model</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions([
            "openModal",
            "fetchManufacturerBrand",
            "fetchServiceType",
            "fetchOptionName",
            "fetchSPrice"
        ]),
        createModel() {
            this.fetchSPrice();
            this.fetchManufacturerBrand();
            this.fetchOptionName();
            this.fetchServiceType();
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "create",
                width: 600,
                route: "models",
                tab: "models"
            });
        }
    },
    computed: {
        ...mapGetters(["getPermission"])
    }
};
</script>