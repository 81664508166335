import Vue from 'vue';
import i18n from '@/i18n.js';
import router from '@/router.js';

import moment from 'moment-timezone';

const state = {
	modalAgreement: false,
	Agreement: {
		title: 'Umowy',
		header: [
			{
				text: 'Index',
				align: 'left',
				value: 'index',
				class: 'central__table__header',
				width: '120px',
			},
			{
				text: 'Kontakt',
				value: 'contractorAgreement',
				class: 'central__table__header',
				width: '400px',
			},
			{
				text: 'Nr umowy',
				value: 'nrAgreement',
				class: 'central__table__header',
				width: '170px',
			},
			{
				text: 'Tytuł umowy',
				value: 'titleAgreement',
				class: 'central__table__header',
				width: '235px',
			},
			{
				text: 'Początek umowy',
				value: 'databeginningAgreement',
				class: 'central__table__header',
				width: '155px',
			},
			{
				text: 'Koniec umowy',
				value: 'dataendAgreement',
				class: 'central__table__header',
				width: '140px',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '125px',
			},
			{
				value: 'invoice',
				width: '20px',
				class: 'central__table__header',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},

	clientItems: [],
	clientRepresentative: [],
	modal: {
		fields: {
			nrAgreement: '',
			annexAgreement: '',
			titleAgreement: '',
		},
		errors: {
			nrAgreement: null,
			annexAgreement: null,
			titleAgreement: null,
		},
	},
	details: {
		nrAgreement: '',
		annexAgreement: '',
		titleAgreement: '',
		contractorAgreement: '',
		databeginningAgreement: '',
		dataendAgreement: '',
		typeAgreement: '',
		commentAgreement: '',
	},
	fieldsNames: {
		nrAgreement: 'Numer własny umowy',
		titleAgreement: 'Tytuł',
		contractorAgreement: 'Kontrahent',
		databeginningAgreement: 'Data rozpoczęcia',
		dataendAgreement: 'Data zakończenia',
		responsibleAgreement: 'Osoba odpowiedzialna po stronie Klienta',
		typeAgreement: 'Rodzaj umowy',
		commentAgreement: 'Opis / Uwagi',
		responsiblenewnameAgreement: 'Imię reprezentanta',
		responsiblenewlastAgreement: 'Nazwisko reprezentanta',
		responsiblenewephoneAgreement: 'Numet telefonu',
		responsiblenewemailAgreement: 'E-mail',
	},
};

const getters = {
	getAgreement: (state) => state.Agreement,
	getModalAgreement: (state) => state.modal.fields,
	getAgreementDetails: (state) => state.details,
	getAgreementFieldsNames: (state) => state.fieldsNames,
	getAgreementErrors: (state) => state.modal.errors,
	getAgreementItems: (state) => state.clientItems,
	getAgreementRepresentatives: (state) => state.clientRepresentative,
};

const actions = {
	createAgreement({ dispatch, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/agreement',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'agreement',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano nową umowe',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'Agreement',
					err: error.response.data,
				});
			});
	},

	agreementDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/agreement/${payload.id}`,
		}).then((response) => {
			if (payload.type == 'modal') {
				commit('setAgreementModal', response.data.item);
			} else {
				commit('setAgreementDeatils', response.data.item);
				commit('setDetailsIndex', response.data.item.index);
				commit('setDetailsMenager', response.data.item.menager);
				commit('setDetailsStatus', response.data.item.status);
				commit('setDetailsStatuses', response.data.statuses);
				if (payload.next) {
					payload.next();
				}
			}
		});
	},

	fetchContacts({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/contacts/search/${payload}`,
		})
			.then((response) => {
				commit('setClientItems', response.data.items);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	updateAgreement({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/agreement/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setAgreementDeatils', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie zmodyfikowano umowę',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
};

const mutations = {
	resetAgreementItems() {
		state.clientItems = [];
		state.clientRepresentative = [];
	},
	setAgreementDeatils(state, payload) {
		state.details = payload;
	},
	setAgreementModal(state, payload) {
		state.modal.fields = payload;
		state.clientItems = [payload.contractorAgreement];
		state.clientRepresentative = payload.contractorAgreement.representatives;
	},

	setClientItems(state, payload) {
		if (payload[0] != null) {
			state.clientItems = payload;
		} else {
			state.clientItems = [];
		}
	},

	setClientThings(state, payload) {
		state.clientRepresentative = payload.representatives;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
