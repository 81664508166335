import Vue from 'vue';
import findIndex from 'lodash.findindex';
const state = {
	job: {
		open: false,
		jobName: '',
	},
	type: '',
	items: [],
	errors: {
		jobName: '',
	},
};
const getters = {
	getUJob: (state) => state.job,
	getUJobType: (state) => state.type,
	getUJobItems: (state) => state.items,
	getUJobErrors: (state) => state.errors,
};
const actions = {
	createUJob({ state, commit }) {
		state.errors.jobName = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/users/job',
			data: {
				...state.job,
			},
		})
			.then((response) => {
				commit('clearUJob');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
				commit('setUJobItem', response.data.item);
			})
			.catch((error) => {
				if (error.response.data.error === 'Exists') {
					state.errors.jobName = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.jobName.kind === 'required'
				) {
					state.errors.jobName = 'Pole jest wymagane';
				}
			});
	},

	deleteUJob({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/users/job/delete/${payload}`,
		})
			.then(() => {
				commit('deleteUJItem', payload);
				// Vue.swal('Usunięto!', 'Pomyślnie usunięto wpis', 'success');
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	fetchUJob({ state, commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/users/job',
		}).then((response) => {
			commit('setUJobItems', response.data.items);
		});
	},

	UJobDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/users/job/${payload}`,
		})
			.then((response) => {
				commit('setUJobDetails', response.data.item);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	uJobUpdate({ commit }) {
		state.errors.jobName = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/users/job/update/${state.job._id}`,
			data: {
				...state.job,
			},
		})
			.then((response) => {
				commit('clearUJob');
				commit('updateUJItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch((error) => {
				console.log(error.response.data);
				if (error.response.data.error === 'Exists') {
					state.errors.jobName = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.jobName.kind === 'required'
				) {
					state.errors.jobName = 'Pole jest wymagane';
				}
			});
	},
};
const mutations = {
	setUJobType(state, payload) {
		state.type = payload;
	},
	clearUJob(state) {
		state.job.open = false;
		state.job.jobName = '';
		state.errors.jobName = '';
	},
	setUJobItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	setUJobItems(state, payload) {
		state.items = payload;
	},
	setUJobDetails(state, payload) {
		state.job.jobName = payload.jobName;
		state.job._id = payload._id;
		state.job.open = true;
	},
	updateUJItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
	deleteUJItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, (e) => e._id == payload);
		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
