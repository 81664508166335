var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"dense":"","label":"Podstawa zatrudnienia:","items":_vm.getContractDictionary,"item-text":"contractName","item-value":"contractName"},model:{value:(_vm.getStaffModal.contract),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "contract", $$v)},expression:"getStaffModal.contract"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"dense":"","label":"Wymiar etatu:","items":_vm.getDayJobDictionary,"item-text":"dayJobName","item-value":"dayJobName"},model:{value:(_vm.getStaffModal.dayJob),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "dayJob", $$v)},expression:"getStaffModal.dayJob"}})],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"startEmployment",attrs:{"close-on-content-click":false,"return-value":_vm.getStaffModal.employmentStart,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getStaffModal, "employmentStart", $event)},"update:return-value":function($event){return _vm.$set(_vm.getStaffModal, "employmentStart", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":"Początek zatrudnienia:","readonly":""},model:{value:(_vm.getStaffModal.employmentStart),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "employmentStart", $$v)},expression:"getStaffModal.employmentStart"}},on))]}}]),model:{value:(_vm.menu.employmentStart),callback:function ($$v) {_vm.$set(_vm.menu, "employmentStart", $$v)},expression:"menu.employmentStart"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.startEmployment.save(
                            _vm.getStaffModal.employmentStart
                        ),
                            (_vm.menu.employmentStart = false)}},model:{value:(_vm.getStaffModal.employmentStart),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "employmentStart", $$v)},expression:"getStaffModal.employmentStart"}})],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"endEmployment",attrs:{"close-on-content-click":false,"return-value":_vm.getStaffModal.employmentEnd,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getStaffModal, "employmentEnd", $event)},"update:return-value":function($event){return _vm.$set(_vm.getStaffModal, "employmentEnd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":"Koniec zatrudnienia:","readonly":""},model:{value:(_vm.getStaffModal.employmentEnd),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "employmentEnd", $$v)},expression:"getStaffModal.employmentEnd"}},on))]}}]),model:{value:(_vm.menu.employmentEnd),callback:function ($$v) {_vm.$set(_vm.menu, "employmentEnd", $$v)},expression:"menu.employmentEnd"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.endEmployment.save(
                            _vm.getStaffModal.employmentEnd
                        ),
                            (_vm.menu.employmentEnd = false)}},model:{value:(_vm.getStaffModal.employmentEnd),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "employmentEnd", $$v)},expression:"getStaffModal.employmentEnd"}})],1)],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"startExamination",attrs:{"close-on-content-click":false,"return-value":_vm.getStaffModal.lastExamination,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getStaffModal, "lastExamination", $event)},"update:return-value":function($event){return _vm.$set(_vm.getStaffModal, "lastExamination", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":"Ostatnie badanie okresowe:","readonly":""},model:{value:(_vm.getStaffModal.lastExamination),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "lastExamination", $$v)},expression:"getStaffModal.lastExamination"}},on))]}}]),model:{value:(_vm.menu.lastExamination),callback:function ($$v) {_vm.$set(_vm.menu, "lastExamination", $$v)},expression:"menu.lastExamination"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.startExamination.save(
                            _vm.getStaffModal.lastExamination
                        ),
                            (_vm.menu.lastExamination = false)}},model:{value:(_vm.getStaffModal.lastExamination),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "lastExamination", $$v)},expression:"getStaffModal.lastExamination"}})],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"endExamination",attrs:{"close-on-content-click":false,"return-value":_vm.getStaffModal.endExamination,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getStaffModal, "endExamination", $event)},"update:return-value":function($event){return _vm.$set(_vm.getStaffModal, "endExamination", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":"Ważność badania okresowego:","readonly":""},model:{value:(_vm.getStaffModal.endExamination),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "endExamination", $$v)},expression:"getStaffModal.endExamination"}},on))]}}]),model:{value:(_vm.menu.endExamination),callback:function ($$v) {_vm.$set(_vm.menu, "endExamination", $$v)},expression:"menu.endExamination"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.endExamination.save(
                            _vm.getStaffModal.endExamination
                        ),
                            (_vm.menu.endExamination = false)}},model:{value:(_vm.getStaffModal.endExamination),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "endExamination", $$v)},expression:"getStaffModal.endExamination"}})],1)],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"startBhp",attrs:{"close-on-content-click":false,"return-value":_vm.getStaffModal.startBhp,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getStaffModal, "startBhp", $event)},"update:return-value":function($event){return _vm.$set(_vm.getStaffModal, "startBhp", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":"Data szkolenia BHP:","readonly":""},model:{value:(_vm.getStaffModal.startBhp),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "startBhp", $$v)},expression:"getStaffModal.startBhp"}},on))]}}]),model:{value:(_vm.menu.startBhp),callback:function ($$v) {_vm.$set(_vm.menu, "startBhp", $$v)},expression:"menu.startBhp"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.startBhp.save(_vm.getStaffModal.startBhp),
                            (_vm.menu.startBhp = false)}},model:{value:(_vm.getStaffModal.startBhp),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "startBhp", $$v)},expression:"getStaffModal.startBhp"}})],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"endBhp",attrs:{"close-on-content-click":false,"return-value":_vm.getStaffModal.endBhp,"transition":"scale-transition","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.getStaffModal, "endBhp", $event)},"update:return-value":function($event){return _vm.$set(_vm.getStaffModal, "endBhp", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":"Ważność szkolenia BHP:","readonly":""},model:{value:(_vm.getStaffModal.endBhp),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "endBhp", $$v)},expression:"getStaffModal.endBhp"}},on))]}}]),model:{value:(_vm.menu.endBhp),callback:function ($$v) {_vm.$set(_vm.menu, "endBhp", $$v)},expression:"menu.endBhp"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":"","scrollable":""},on:{"input":function($event){_vm.$refs.endBhp.save(_vm.getStaffModal.endBhp),
                            (_vm.menu.endBhp = false)}},model:{value:(_vm.getStaffModal.endBhp),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "endBhp", $$v)},expression:"getStaffModal.endBhp"}})],1)],1)],1),_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"dense":"","label":"Data urodzenia:","readonly":"","value":_vm.getUserDetails.birthday}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Miejsce urodzenia:"},model:{value:(_vm.getStaffModal.placeOfBirth),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "placeOfBirth", $$v)},expression:"getStaffModal.placeOfBirth"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"dense":"","no-resize":"","label":"Wymagania dodatkowe:","value":"Brak"},model:{value:(_vm.getStaffModal.needments),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "needments", $$v)},expression:"getStaffModal.needments"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"dense":"","no-resize":"","label":"Uwagi:","value":"Brak"},model:{value:(_vm.getStaffModal.comment),callback:function ($$v) {_vm.$set(_vm.getStaffModal, "comment", $$v)},expression:"getStaffModal.comment"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }