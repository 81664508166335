<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === PRODUCENT === === -->
            <td>{{item.producer}}</td>

            <!-- === === MODEL === === -->
            <td>{{ item.model }}</td>

            <!-- === === RODZAJ URZĄDZENIA === === -->
            <td>{{item.deviceType}}</td>

            <!-- === === PAKIET CENOWY === === -->
            <td>{{ item.price && item.price.priceName ? item.price.priceName : 'Brak' }}</td>

            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/models/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
export default {
    props: ["items"],
    data: () => ({})
};
</script>