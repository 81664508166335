import Vue from 'vue';
import findIndex from 'lodash.findindex';
const state = {
	category: {
		name: '',
		background: '#000000',
		color: '#000000',
		dictionary: 'TaskCategory',
		open: false,
	},
	type: '',
	errors: {
		name: '',
	},
	items: [],
};
const getters = {
	getTCategory: state => state.category,
	getTCategoryItems: state => state.items,
	getTCategoryErrors: state => state.errors,
	getTCategoryType: state => state.type,
};
const actions = {
	createTCategory({ state, commit }) {
		state.errors.name = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/tasks/category',
			data: {
				...state.category,
			},
		})
			.then(response => {
				commit('closeTCategory');
				commit('clearTCategory');
				commit('setTCategoryNItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				if (error.response.data.error === 'Exists')
					state.errors.name = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.name = 'Pole jest wymagane';
				}
			});
	},

	fetchTCategory({ commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/tasks/category',
		}).then(response => {
			commit('setTCategoryItems', response.data.items);
		});
	},

	deleteTCategory({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/tasks/category/delete/${payload}`,
		})
			.then(() => {
				commit('deleteItem', payload);
				commit('closeSecondModal');
				// Vue.swal('Usunięto!', 'Pomyślnie usunięto wpis', 'success');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},
	TCategoryDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/tasks/category/${payload}`,
		}).then(response => {
			commit('setTCategoryDetails', response.data.item);
		});
	},
	TCategoryUpdate({ commit }) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/tasks/category/update/${state.category._id}`,
			data: {
				...state.category,
			},
		})
			.then(response => {
				commit('closeTCategory');
				commit('clearTCategory');
				commit('updateCTItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
				// Vue.swal(
				// 	'Edytowano!',
				// 	'Pomyślnie edytowano dany wpis',
				// 	'success',
				// );
			})
			.catch(error => {
				if (error.response.data.error === 'Exists')
					state.errors.name = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.name.kind === 'required'
				) {
					state.errors.name = 'Pole jest wymagane';
				}
			});
	},
};
const mutations = {
	closeTCategory(state) {
		state.category.open = false;
	},
	setTCategoryItems(state, payload) {
		state.items = payload;
	},
	setTCategoryNItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	deleteItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setTCategoryDetails(state, payload) {
		state.category.name = payload.name;
		state.category.color = payload.color;
		state.category.background = payload.background;
		state.category._id = payload._id;
		state.category.open = true;
	},
	clearTCategory(state) {
		state.category.name = '';
		state.category.background = '#000000';
		state.category.color = '#000000';
		state.errors.name = '';
	},
	setTCategoryType(state, payload) {
		state.type = payload;
	},
	updateCTItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
