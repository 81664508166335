<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="
                item.status &&
                item.status.possibleStatus &&
                item.status.possibleStatus.resolved
                    ? 'color: #9e9e9e'
                    : ''
            "
        >
            <!-- === === DATA === === -->
            <!-- <td>{{item.createdAt ? $moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'Brak'}}</td> -->

            <!-- === === NAZWA === === -->
            <td>{{ item.title ? item.title : 'Brak' }}</td>

            <!-- === === STATUS === === -->
            <!-- <td>
                <v-chip
                    :hidden="!item.status.possibleStatus"
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>-->
            <!-- === === DATA OSTATNIEJ MODYFIKACJI === === -->

            <!-- {{item.menager ? item.menager.name : ''}} -->
            <td>
                {{
                    item.updatedAt
                        ? $moment(item.updatedAt).format('YYYY-MM-DD HH:mm')
                        : 'Brak'
                }}
            </td>
            <!-- === === MENAGER === === -->
            <td style="font-size: 12px">
                {{ item.menager ? item.menager.name : '' }}
                {{ item.menager ? item.menager.lastname : '' }}
            </td>
            <!-- === === INFORMACJE === === -->

            <td style="text-align: center">
                <v-tooltip bottom v-if="item.folders">
                    <template v-slot:activator="{ on }">
                        <!-- @click="showFirstAttachments({
                            registryID: item._id,
                        registryName: $route.name + '_details',})"-->

                        <v-btn
                            fab
                            v-on="on"
                            dark
                            class="mx-2"
                            style="width: 25px; height: 25px"
                            color="#19aa8d"
                            target="_blank"
                            :href="setUrl(item.folders.fakeFileName)"
                        >
                            <v-icon size="12" color="white"
                                >mdi-file-search</v-icon
                            >
                        </v-btn>
                    </template>
                    <span>Podgląd</span>
                </v-tooltip>
            </td>

            <!-- === === AKCJA === === -->
            <td v-if="getRegistryHeader.action" style="text-align: right">
                <v-btn
                    icon
                    small
                    @click="$router.push({ path: `/wifi/${item._id}` })"
                >
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
    props: ['items'],
    data: () => ({
        paths: {
            attachments: process.env.VUE_APP_WEBDAV
                ? `${process.env.VUE_APP_SERVER}/${process.env.VUE_APP_WEBDAV}/`
                : `${process.env.VUE_APP_SERVER}/secured/attachments/`,
        },
    }),
    computed: {
        ...mapGetters(['getPermission', 'getRegistryHeader']),
    },
    methods: {
        ...mapActions(['showFirstAttachments']),
        setUrl(file) {
            let ext = file.split('.').pop();
            if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(ext)) {
                return process.env.VUE_APP_WEBDAV
                    ? `${process.env.VUE_APP_SERVER}/${process.env.VUE_APP_WEBDAV}/${file}`
                    : `${process.env.VUE_APP_SERVER}/secured/attachments/${file}`;
            } else {
                return `${process.env.VUE_APP_SERVER}/secured/attachments/${file}`;
            }
        },
    },
};
</script>
