<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === IMIĘ I NAZWISKO PRACOWNIKA === === -->
            <td>{{item.userId ? `${item.userId.name} ${item.userId.lastname}` : `Nieznane logowanie: ${item.unknownLogin}`}}</td>

            <!-- === === DATA LOGOWANIA === === -->
            <td>{{$moment(item.login).format("YYYY-MM-DD HH:mm:ss")}}</td>

            <!-- === === DATA WYLOGOWANIA === === -->
            <td>
                <span v-if="item.logout">{{ $moment(item.logout).format("YYYY-MM-DD HH:mm:ss") }}</span>
                <span v-else-if="!item.logout && item.comment">{{ item.comment }}</span>
                <span v-else-if="!item.logout && !item.comment">Online</span>
            </td>

            <!-- === === CZAS SESJI === === -->
            <td>
                <span v-if="item.closed == true && item.logout">{{ correctTimeDuration(item.login, item.logout)}}</span>
                <span v-else-if="item.closed == true && item.sessionTime">~{{correctTime(item.sessionTime)}}</span>
                <span v-else-if="item.closed == true">Brak danych</span>
                <span v-else>W trakcie</span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    methods: {
        correctTimeDuration(login, logout) {
            login = new Date(login);
            logout = new Date(logout);
            return this.$moment
                .utc(Math.abs(logout - login))
                .format("HH:mm:ss");
        },
        correctTime(secs){
            let minutes = Math.floor(secs / 60);
            secs = secs%60;
            let hours = Math.floor(minutes/60)
            minutes = minutes%60;
            return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(secs)}`;
        },
        pad(num) {
            return ("0"+num).slice(-2);
        }
    }
};
</script>