import Vue from 'vue';

const state = {
	notes: {
		title: 'title.notes',
		headers: [
			{
				text: 'Data',
				align: 'left',
				value: 'createdAt',
				width: 200,
			},
			{
				text: 'Wprowadził',
				value: 'createdBy',
				width: 200,
			},
			{
				text: 'Notatka',
				value: 'note',
			},

			{
				text: 'Akcje',
				value: 'action',
				sortable: false,
				align: 'right',
				width: 80,
			},
		],
		count: '',
	},
	modal: {
		fields: {
			note: '',
		},
		errors: {
			note: '',
		},
	},
};

const getters = {
	getNoteFields: (state) => state.modal.fields,
	getNoteHeaders: (state) => state.notes.headers,
	getNoteErrors: (state) => state.modal.errors,
	getNotesCount: (state) => state.notes.count,
};

const actions = {
	createNote({ state, commit, dispatch }, payload) {
		commit('resetErrors', 'note');
		Vue.axios({
			method: 'POST',
			url: '/notes',
			data: {
				note: state.modal.fields.note,
				registryID: payload.registryID,
				registryName: payload.registryName,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: payload.page,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'note_tab',
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				dispatch('notesCount', {
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nową notatkę',
				});
			})

			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'note',
					err: error.response.data.error,
				});
			});
	},
	deleteNote({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/notes/delate/${payload.id}/${payload.registryName}`,
		})
			.then(() => {
				dispatch('fetchData', {
					page: payload.page,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'note_tab',
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				dispatch('notesCount', {
					registryID: payload.registryID,
					registryName: payload.registryName,
				});
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto notatkę',
				});
			})
			.catch(() => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	//Pobieranie danych do edycji
	noteDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/notes/${payload.id}/${payload.registryName}`,
		}).then((response) => {
			commit('setNodeDetails', response.data.item);
		});
	},

	noteEdit({ state, commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/notes/edit/${state.modal.fields._id}`,
			data: {
				note: state.modal.fields.note,
				registryName: payload.registryName,
				registryID: payload.registryID,
			},
		})
			.then((response) => {
				commit('updateItem', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano notatkę',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'note',
					err: error.response.data.error,
				});
			});
	},

	notesCount({ state, commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/global/find/count/notes?isDeleted=false`,
			params: payload,
		}).then((response) => {
			state.notes.count = response.data;
		});
	},
};

const mutations = {
	setNodeDetails(state, payload) {
		state.modal.fields = payload;
	},
};

export default {
	actions,
	state,
	getters,
	mutations,
};
