<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            readonly
            v-model="getDirectorOrdersDetails.indexOrdinance"
            label="Nr zarządzenia"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="9">
          <v-text-field readonly v-model="getDirectorOrdersDetails.title" label="Tytuł"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            readonly
            :value="getDirectorOrdersDetails.archiveDate ? correctData(getDirectorOrdersDetails.archiveDate) : 'Brak'"
            label="Data archiwizacji"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="12">
          <v-textarea
            readonly
            v-model="getDirectorOrdersDetails.information"
            label="Informacja"
            no-resize
            rows="6"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field readonly :value="responsiblePerson()" label="Osoby odpowiedzialne"></v-text-field>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    menu2: false,
    person: []
  }),
  computed: {
    ...mapGetters(["getDirectorOrdersDetails"])
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },

    responsiblePerson() {
      let person = "";
      for (
        let i = 0;
        i < this.getDirectorOrdersDetails.responsiblePerson.length;
        i++
      ) {
        person += `${this.getDirectorOrdersDetails.responsiblePerson[i].name} ${this.getDirectorOrdersDetails.responsiblePerson[i].lastname}, `;
      }
      return person;
    }
  },
  components: {}
};
</script>