<template>
  <v-container>
    <v-row>
      <v-col cols="8" md="6">
        <v-col cols="12" md="12" class="pt-0">
          <v-text-field
            dense
            :value="getInternalContactsDetails.unit.fullName"
            :label="getInternalContactsFieldsNames.unit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="pt-0">
          <v-text-field
            dense
            :value="`${getInternalContactsDetails.worker ? getInternalContactsDetails.worker.name + getInternalContactsDetails.worker.lastname : ''}`"
            :label="getInternalContactsFieldsNames.worker"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="pt-0">
          <v-text-field
            dense
            v-model="getInternalContactsDetails.organizationalUnit"
            :label="getInternalContactsFieldsNames.organizationalUnit"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <div>
            <span>Lokalizacja: {{getInternalContactsDetails.location ? getInternalContactsDetails.location : ""}}</span>
          </div>
          <div>
            <span>Telefon stacjonarny: {{getInternalContactsDetails.landlinePhone ? getInternalContactsDetails.landlinePhone : ''}}</span>
          </div>
          <div>
            <span>Telefon komórkowy: {{getInternalContactsDetails.phone ? getInternalContactsDetails.phone : ""}}</span>
          </div>
          <div>
            <span>Email: {{getInternalContactsDetails.email ? getInternalContactsDetails.email : ''}}</span>
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            :label="getInternalContactsFieldsNames.comments"
            v-model="getInternalContactsDetails.comments"
            rows="1"
            no-resize
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    menu2: false,
    person: []
  }),
  computed: {
    ...mapGetters([
      "getInternalContactsFieldsNames",
      "getInternalContactsDetails"
    ])
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    }
  },
  components: {}
};
</script>