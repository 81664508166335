<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" class="pt-0">
                <v-text-field
                    dense
                    v-model="getModalParts.producent"
                    :error-messages="getModalPartsError.producent"
                    :label="getPartsFieldsNames.producent"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
                <v-autocomplete
                    v-model="getModalParts.manufacturer"
                    @keyup="debounceProducer"
                    hint="Zacznij pisać..."
                    hide-no-data
                    return-object
                    dense
                    :items="getItems.manufacturer"
                    :label="getPartsFieldsNames.manufacturer"
                    item-value="_id"
                    item-text="name"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
                <v-text-field v-model="getModalParts.name" dense :label="getPartsFieldsNames.name"></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="4">
                <v-select
                    dense
                    v-model="getModalParts.replacement"
                    :items="select"
                    :error-messages="getModalPartsError.replacement"
                    :label="getPartsFieldsNames.replacement"
                ></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="getModalParts.stateParts"
                    :error-messages="getModalPartsError.stateParts"
                    dense
                    :label="getPartsFieldsNames.stateParts"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <!-- <v-text-field v-model="getModalParts.price" dense :label="getPartsFieldsNames.price"></v-text-field> -->
                <div class="input-effect price" style="margin-top: -22px">
                    <span>{{getPartsFieldsNames.price}}</span>
                    <money
                        style="text-align: left; font-size: 14px; width: 110px; border-bottom: 1px solid #949494; color: #272727; "
                        v-model="getModalParts.price"
                        v-bind="money"
                        :error-messages="getModalPartsError.price"
                    ></money>
                    <span class="focus-border"></span>

                    <span v-if="getModalParts.price < 0" class="errorPrice">Cena nie może być ujemna</span>
                </div>
            </v-col>

            <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="getModalParts.series"
                    @keyup="debounceSeriesModel"
                    hint="Zacznij pisać..."
                    hide-no-data
                    dense
                    :items="getItems.series"
                    :label="getPartsFieldsNames.series"
                    :error-messages="getModalPartsError.series"
                    item-text="seriesName"
                    item-value="_id"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
                <!-- :items="getModelItems" -->

                <v-autocomplete
                    v-model="getModalParts.model"
                    @keyup="debounceModel"
                    hint="Zacznij pisać..."
                    hide-no-data
                    return-object
                    dense
                    :label="getPartsFieldsNames.model"
                    :items="getItems.model"
                    :error-messages="getModalPartsError.model"
                    item-value="_id"
                    item-text="model"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{ data.item.model }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
                <v-textarea
                    v-model="getModalParts.comment"
                    dense
                    auto-grow
                    :label="getPartsFieldsNames.comment"
                    rows="1"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";
import { Money } from "v-money";

export default {
    data: () => ({
        select: ["Tak", "Nie"],
        money: {
            decimal: ",",
            thousands: ".",
            suffix: " PLN",
            precision: 2,
            masked: false,
        },
    }),
    methods: {
        ...mapActions([
            "searchPartsSeries",
            "searchModelParts",
            "searchProducer",
        ]),

        debounceSeriesModel: debounce(function (e) {
            if (e.target.value.length >= 3) {
                this.searchPartsSeries(e.target.value);
            }
        }, 500),
        debounceProducer: debounce(function (e) {
            if (e.target.value.length >= 3) {
                this.searchProducer(e.target.value);
            }
        }, 500),
        debounceModel: debounce(function (e) {
            if (e.target.value.length >= 3) {
                this.searchModelParts({
                    search: e.target.value,
                });
            }
        }, 500),
    },
    computed: {
        ...mapGetters([
            "getModalParts",
            "getModalPartsError",
            "getItems",
            "getSeriesItems",
            "getPartsFieldsNames",
        ]),
    },
};
</script>
