import Vue from 'vue';
import findIndex from 'lodash.findindex';
import VueAxios from 'vue-axios';

const state = {
	modal: {
		fields: {
			optionName: '',
		},
		open: false,
	},
	type: '',
	items: [],
	errors: {
		optionName: '',
	},
};

const getters = {
	getOptionName: state => state.modal.fields,
	getOptionNameModal: state => state.modal,
	getOptionErrors: state => state.errors,
	getOptionNameItems: state => state.items,
	getOptionNameType: state => state.type,
};

const actions = {
	createOptionName({
		state,
		commit
	}) {
		state.errors.optionName = '';
		Vue.axios({
				method: 'POST',
				url: '/dictionary/options/name',
				data: {
					...state.modal.fields,
				},
			})
			.then(response => {
				// commit('closeCreateOptionName');
				commit('setCreateOptionNamesItem', response.data.item);

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
				commit('clearOptionName');
			})
			.catch(error => {
				if (error.response.data.error === 'Exists')
					state.errors.optionName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.optionName.kind ===
					'required'
				) {
					state.errors.optionName = 'Pole jest wymagane';
				}
			});
	},

	fetchOptionName({
		commit
	}) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/options/name',
		}).then(response => {
			commit('setCreateOptionNameItems', response.data.items);
		});
	},

	deleteOptionName({
		commit
	}, payload) {
		Vue.axios({
				method: 'PUT',
				url: `/dictionary/options/name/${payload}`,
			})
			.then(() => {
				commit('deleteItem', payload);
				commit('closeSecondModal');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	OptionNameDeatils({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/dictionary/options/name/${payload}`,
			})
			.then(response => {
				commit('setOptionNameDictionary', response.data.item);
			})
			.catch(errors => {
				console.log(errors);
			});
	},

	OptionNameUpdate({
		commit
	}) {
		state.errors.optionName = '';
		Vue.axios({
				method: 'PUT',
				url: `/dictionary/options/name/update/${state.modal._id}`,
				data: {
					...state.modal.fields,
				},
			})
			.then(response => {
				commit('clearOptionName');
				commit('updateOptionName', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				if (error.response.data.error === 'Exists')
					state.errors.optionName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.optionName.kind ===
					'required'
				) {
					state.errors.optionName = 'Pole jest wymagane';
				}
			});
	},
};

const mutations = {
	closeCreateOptionName(state) {
		state.modal.open = false;
	},
	setCreateOptionName(state, payload) {
		state.type = payload;

	},
	setCreateOptionNamesItem(state, payload) {
		state.items = [payload, ...state.items];
	},

	deleteItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},

	setOptionNameDictionary(state, payload) {

		state.modal.fields.optionName = payload.optionName;
		state.modal._id = payload._id;
		state.modal.open = true;
	},

	clearOptionName(state) {
		state.modal.fields.optionName = '';
		state.modal.open = false;
		state.errors.optionName = '';
	},

	setCreateOptionNameItems(state, payload) {
		state.items = payload;
	},

	updateOptionName(state, payload) {

		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};