import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	warehouse: {
		title: 'Magazyn',
		header: [
			{
				text: 'Index',
				value: 'index',
				width: '8%',
				class: 'central__table__header',
			},
			{
				text: 'Data rotacji | Rodzaj',
				value: 'createdAt',
				class: 'central__table__header',
				width: '12%',
			},
			{
				text: 'Nazwa budowy | Dostawcy',
				value: 'construction.name',
				class: 'central__table__header',
				width: '30%',
				sortable: false,
			},
			{
				text: 'Pojazd',
				value: 'car',
				class: 'central__table__header',
				width: '19%',
				sortable: false,
			},
			{
				text: 'Waga netto materiału',
				value: 'netWeight',
				class: 'central__table__header',
				width: '16%',
				sortable: false,
			},
			{
				text: 'Status',
				width: '7%',
				value: 'status',
			},
			{
				text: 'Akcja',
				align: 'right',
				width: '8%',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			company: '',
			rotationType: '',
			wzDate: '',
			carOwnership: '',
			carPlate: '',
			carDriver: '',
			tareWeight: '',
			grossWeight: '',
			weighingDone: false,
			weighingDate: '',
			materialType: null,
			description: '',
			carId: null,
			constructionId: null,
			contactId: null,
		},
		errors: {
			company: null,
			rotationType: null,
			tareWeight: null,
			grossWeight: null,
			materialType: null,
		},
	},
	details: {},
	fieldsNames: {
		company: 'Firma',
		rotationType: 'Rodzaj rotacji',
		wzDate: 'Data wydruku WZ/PZ',
		carOwnership: 'Własność pojazdu',
		carPlate: 'Numer rejestracyjny',
		carDriver: 'Imię i nazwisko kierowcy',
		tareWeight: 'Waga tara',
		grossWeight: 'Waga brutto',
		weighingDone: 'Ważenie zakończone',
		weighingDate: 'Data ważenia',
		materialType: 'Rodzaj materiału',
		description: 'Opis',
		carId: 'Marka i numer rejestracyjny',
		constructionId: 'Budowa',
		contactId: 'Nazwa dostawcy',
		createdBy: 'Osoba obsługująca rotację',
	},
	companyOptions: ['Drog-Bet', 'Kowalski Budownictwo'],
	rotationOptions: ['Dostawa', 'Wydanie'],
	carOptions: ['Własny', 'Obcy'],
};

const getters = {
	getWarehouse: (state) => state.warehouse,
	getWarehouseFields: (state) => state.modal.fields,
	getWarehouseDetails: (state) => state.details,
	getWarehouseFieldsNames: (state) => state.fieldsNames,
	getWarehouseCompanyOptions: (state) => state.companyOptions,
	getWarehouseRotationOptions: (state) => state.rotationOptions,
	getWarehouseCarOptions: (state) => state.carOptions,
	getWarehouseErrors: (state) => state.modal.errors,
};

const actions = {
	async createWarehouse({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/warehouse',
			data: { ...state.modal.fields },
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'warehouse',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano wpis do magazynu',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'warehouse',
					err: error.response.data,
				});
			});
	},
	async warehouseDetails({ commit, dispatch }, payload) {
		Vue.axios({ method: 'GET', url: `/warehouse/${payload.id}` }).then(
			(response) => {
				if (payload.type == 'modal') {
					commit('setWarehouseFields', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);

					commit('setWarehouseDetails', response.data.item);
					commit('setDetailsIndex', response.data.item.index);

					if (payload.next) {
						payload.next();
					}
				}
			},
		);
	},
	async warehouseUpdate({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/warehouse/${payload.id}`,
			data: { ...state.modal.fields },
		})
			.then((response) => {
				dispatch('warehouseDetails', { id: payload.id });
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano wpis',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'warehouse',
					err: error.response.data.error,
				});
			});
	},
};

const mutations = {
	setWarehouseFields(state, payload) {
		payload.tareWeight = Number(payload.tareWeight);
		payload.grossWeight = Number(payload.grossWeight);
		payload.netWeight = Number(payload.netWeight);
		payload.weighingDate = payload.weighingDate
			? moment(payload.weighingDate).format('YYYY-MM-DD')
			: '';
		payload.wzDate = payload.wzDate
			? moment(payload.wzDate).format('YYYY-MM-DD')
			: '';
		state.modal.fields = payload;
	},
	setWarehouseDetails(state, payload) {
		payload.weighingDate = payload.weighingDate
			? moment(payload.weighingDate).format('YYYY-MM-DD')
			: '';
		payload.wzDate = payload.wzDate
			? moment(payload.wzDate).format('YYYY-MM-DD')
			: '';
		state.details = payload;
	},
};

export default {
	state,
	actions,
	getters,
	mutations,
};
