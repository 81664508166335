<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="9">
          <v-text-field readonly v-model="getDutyDetails.title" :label="getDutyFieldsNames.title"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            readonly
            :value="getDutyDetails.archiveDate ? correctData(getDutyDetails.archiveDate) : 'Brak'"
            :label="getDutyFieldsNames.archiveDate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field readonly v-model="getDutyDetails.month" :label="getDutyFieldsNames.month"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="12">
          <v-textarea
            readonly
            v-model="getDutyDetails.information"
            :label="getDutyFieldsNames.information"
            no-resize
            rows="6"
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    menu2: false,
    person: []
  }),
  computed: {
    ...mapGetters(["getDutyDetails", "getDutyFieldsNames"])
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    }
  },
  components: {}
};
</script>