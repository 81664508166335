<template>
    <div>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    small
                    class="buttons--add mx-2"
                    @click="createResource"
                >
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Dodaj plik</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    data: () => ({}),
    methods: {
        ...mapActions(['openModal']),
        ...mapMutations(['clearResources']),
        createResource() {
            this.clearResources();
            this.openModal({
                headLine: 'global.create',
                open: true,
                width: 600,
                type: 'create',
                route: this.$route.name,
                tab: this.$route.name,
            });
        },
    },
};
</script>
