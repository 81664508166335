import i18n from '@/i18n.js';
import Vue from 'vue';

const state = {
	archive: {
		header: [{
				text: 'Index',
				width: 30,
				value: 'index',
				class: 'central__table__header',
			},
			{
				text: 'Data utworzenia',
				width: 170,
				value: 'createdAt',
				class: 'central__table__header',
			},
			{
				text: 'Technik',
				value: 'technic',
				class: 'central__table__header',
			},
			{
				text: 'Kontakt',
				width: 170,
				value: 'device.client.phoneNumber',
				class: 'central__table__header',
			},
			{
				text: 'Zakres usługi',
				value: 'doneJob',
				class: 'central__table__header',
			},
			{
				text: 'Zalecenia',
				value: 'nextIntervention',
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
		items: [],
		pages: 0,
	},
	modal: {
		fields: {
			name: null,
			description: null,
			users: [],
			registries_reader: null,
			registries_user: null,
			permission: [],
		},
		errors: {
			name: '',
			description: '',
		},
	},
};

const getters = {
	getArchive: state => state.archive,
};

const actions = {
	deviceArchiveTab({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/global/find/device_archive`,
				params: {
					deviceID: payload.deviceID,
					SN: payload.SN,
					page: payload.page || 1,
					pageSize: 11,
					isService: payload.isService || false,
					registryID: payload.registryID || '',
				},
			})
			.then(response => {
				// console.log(response.data.items);

				commit('setArchiveItems', response.data.items);
				commit('setArchivePages', response.data.pages);

			})
			.catch(error => {
				console.log(error);
			});
	},
};

const mutations = {
	setArchiveItems(state, payload) {
		state.archive.items = payload;
	},
	setArchivePages(state, payload) {
		state.archive.pages = payload;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};