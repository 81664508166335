var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"AE__footer",attrs:{"color":"#d9d9d9"}},[(
      _vm.getTaskData.isDone &&
        (_vm.getModal.tab == 'tasks_devices' || _vm.$route.name == 'tasks')
    )?_c('v-alert',{staticClass:"ma-0 mr-3",attrs:{"dense":"","type":"success"}},[_vm._v("Zadanie zrealizowane")]):_vm._e(),(
      _vm.getModal.type == 'details' &&
        (_vm.$route.name == 'tasks' || _vm.getModal.tab === 'tasks_devices') &&
        !_vm.getTaskData.isDone
    )?_c('v-btn',{staticClass:"buttons buttons--add mr-3",attrs:{"dark":""},on:{"click":function($event){return _vm.doneTask({
        registryName: _vm.$route.name,
        registryID: _vm.$route.params.id,
      })}}},[_vm._v("Zakończ")]):_vm._e(),(
      _vm.getModal.type == 'details' &&
        _vm.$route.name == 'tasks' &&
        _vm.getTaskData.registryName &&
        _vm.getTaskData.registryID
    )?_c('v-btn',{staticClass:"buttons mr-3 mt-0",attrs:{"router":"","to":("/" + (_vm.getTaskData.registryName.split('_')[0]) + "/" + (_vm.getTaskData.registryID))},on:{"click":_vm.closeModal}},[_vm._v("Więcej")]):_vm._e(),_c('v-spacer'),_c('div',[(
        _vm.getModal.type === 'create' &&
          !['resources', 'resources_details'].includes(_vm.$route.name)
      )?_c('v-btn',{staticClass:"mx-3 buttons",staticStyle:{"position":"absolute","left":"20px","background-color":"white"},on:{"click":_vm.clear}},[_vm._v(_vm._s(_vm.$t('global.clear')))]):_vm._e(),(_vm.getModal.tab === 'start_intervention')?_c('v-btn',{staticClass:"buttons__intervention mr-3",staticStyle:{"position":"absolute","left":"20px"}},[_vm._v("Zakończ interwencje")]):_vm._e(),(_vm.getModal.tab === 'start_intervention')?_c('v-btn',{staticClass:"buttons__intervention buttons__intervention__start mr-3",staticStyle:{"position":"absolute","left":"200px"}},[_vm._v("Rozpocznij interwencje")]):_vm._e(),(_vm.$route.name != 'storage')?_c('v-btn',{staticClass:"buttons mr-3",on:{"click":_vm.clearModal}},[_vm._v("Anuluj")]):_vm._e(),(_vm.getAttachmentLoader)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"24","width":"2"}}):(_vm.getModal.type == 'create')?_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"dark":"","disabled":_vm.getButtons.save},on:{"click":_vm.save}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])]):_vm._e(),(_vm.getModal.type == 'qrcode')?_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"dark":""},on:{"click":function($event){return _vm.QrPrint('printQR')}}},[_vm._v("Drukuj")]):_vm._e(),(_vm.getModal.type == 'edit' && _vm.$route.name != 'alerts')?_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"dark":""},on:{"click":_vm.edit}},[_vm._v(_vm._s(_vm.$t('global.save')))]):_vm._e(),(
        _vm.getModal.type == 'details' &&
          (_vm.$route.name == 'tasks' || _vm.getModal.tab === 'tasks_devices') &&
          !_vm.getTaskData.isDone
      )?_c('v-btn',{staticClass:"buttons mr-3 buttons--add",on:{"click":function($event){return _vm.saveTComment({ registryName: _vm.$route.name })}}},[_vm._v("Zapisz")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }