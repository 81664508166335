<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="
        item.status &&
        item.status.possibleStatus &&
        item.status.possibleStatus.resolved
          ? 'color: #9e9e9e'
          : ''
      "
    >
      <!-- === === DATA === === -->
      <td style="font-size: 12px !important">
        {{
          item.startDate
            ? $moment(item.startDate).format('YYYY-MM-DD HH:mm')
            : 'Brak'
        }}
      </td>

      <!-- === === NAZWA === === -->
      <td style="font-size: 14px !important">
        {{ item.title ? item.title : 'Brak' }}
      </td>

      <!-- === === STATUS === === -->
      <!-- {{getPermission.level}} -->
      <td v-if="getRegistryHeader.status">
        <v-chip
          :hidden="item.status && !item.status.possibleStatus"
          :color="
            item.status &&
            item.status.possibleStatus &&
            item.status.possibleStatus.color &&
            item.status.possibleStatus.color.background
              ? item.status.possibleStatus.color.background
              : '#555555'
          "
          :style="
            `color: ${
              item.status &&
              item.status.possibleStatus &&
              item.status.possibleStatus.color &&
              item.status.possibleStatus.color.font
                ? item.status.possibleStatus.color.font
                : '#FFFFFF'
            }`
          "
          dark
          label
          small
          >{{
            item.status &&
            item.status.possibleStatus &&
            item.status.possibleStatus.name
              ? item.status.possibleStatus.name
              : 'Brak'
          }}</v-chip
        >
      </td>

      <!-- === === DODAŁ === === -->

      <td style="font-size: 12px">
        {{ item.createdBy.name }} {{ item.createdBy.lastname }}
      </td>

      <!-- === === INFORMACJE === === -->
      <td style="text-align: right">
        <v-tooltip bottom v-if="item.folders">
          <template v-slot:activator="{ on }">
            <!-- @click="showFirstAttachments({
                            registryID: item._id,
                        registryName: $route.name + '_details',})"-->
            <v-btn
              fab
              v-on="on"
              dark
              class="mx-2"
              style="width: 25px; height: 25px"
              color="#19aa8d"
              target="_blank"
              @click="checkFile(item)"
            >
              <v-icon size="12" color="white">mdi-file-search</v-icon>
            </v-btn>
          </template>
          <span>Podgląd</span>
        </v-tooltip>
      </td>
      <!-- === === AKCJA === === -->
      <!-- {{item.attachments}} -->
      <td v-if="getRegistryHeader.action" style="text-align: right">
        <v-btn
          icon
          small
          @click="$router.push({ path: `/current-informations/${item._id}` })"
        >
          <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment-timezone';
export default {
  props: ['items', 'headers'],
  data: () => ({
    paths: {
      attachments: `${process.env.VUE_APP_SERVER}/secured/attachments/`,
    },
  }),
  computed: {
    ...mapGetters([
      'getPermission',
      'getUser',
      'getRegistryHeader',
      'getGlobalPaths',
    ]),
  },
  methods: {
    ...mapActions(['showFirstAttachments']),
    ...mapMutations(['openOfficePreview']),
    checkFile(item) {
      let enlargement = item.folders.fakeFileName.substr(
        item.folders.fakeFileName.indexOf('.')
      );

      if (
        enlargement == '.docx' ||
        enlargement == '.doc' ||
        enlargement == '.xls' ||
        enlargement == '.xlsx'
      ) {
        this.openOfficePreview({
          url: `${this.getGlobalPaths.webdav}${item.folders.fakeFileName}`,
        });
      } else {
        window.open(
          this.paths.attachments + item.folders.fakeFileName,
          '_blank'
        );
      }
    },
  },
};
</script>
