import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	modalInternalContacts: false,
	internalContacts: {
		title: 'KONTAKTY WEWNĘTRZNE',
		header: [
			{
				text: 'JEDNOSTKA ORGANIZACYJNA WSD',
				value: 'name',
				class: 'central__table__header',
				width: '13%',
			},
			{
				text: 'Funkcja lub komórka organizacyjna',
				value: 'function',
				class: 'central__table__header',
				width: '12%',
			},
			{
				text: 'Nazwisko i imię pracownika funkcyjnego',
				value: 'worker',
				class: 'central__table__header',
				width: '13%',
			},
			{
				text: 'Tel stacjonarny',
				value: 'linePhone',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Tel komórkowy',
				value: 'phone',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'E-mail',
				value: 'mail',
				class: 'central__table__header',
				width: '9%',
			},
			{
				text: 'Lokalizacja komórki organizacyjnej',
				value: 'localization',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Data ostatniej modyfikacji',
				value: 'updatedAt',
				class: 'central__table__header',
				width: '9%',
			},
			// {
			//     text: 'Manager',
			//     value: 'menager',
			//     class: 'central__table__header',
			//     width: '8%',
			// },

			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				width: '5%',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	departments: [],
	contacts: [],
	modal: {
		fields: {
			unit: '',
			worker: '',
			organizationalUnit: '',
			comments: '',
			worker: '',
			phone: '',
			landlinePhone: '',
			email: '',
			location: '',
		},
		errors: {
			unit: '',
			archiveDate: '',
			information: '',
			version: '',
		},
		details: {
			unit: '',
			worker: '',
			organizationalUnit: '',
			comments: '',
			worker: '',
			phone: '',
			landlinePhone: '',
			email: '',
			location: '',
		},
	},
	fieldsNames: {
		unit: 'Wybierz jednostkę organizacyjną WSD',
		worker: 'Wybierz pracownika funkcyjnego',
		organizationalUnit: 'Funkcja lub komórka organizacyjna',
		comments: 'Uwagi',
		email: 'Email',
		phone: 'Numer telefonu',
	},
};

const getters = {
	getInternalContacts: (state) => state.internalContacts,
	getPrintContacts: (state) => state.contacts,
	getDepartmentsItems: (state) => state.departments,
	getInternalContactsModal: (state) => state.modal.fields,
	getInternalContactsError: (state) => state.modal.errors,
	getInternalContactsDetails: (state) => state.modal.details,
	getInternalContactsFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	fetchInternalContacts({ state, commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: '/fetchInternalContacts',
		})
			.then((response) => {
				commit('setContacts', response.data.contacts);
				if (payload.next) {
					payload.next();
				}
			})
			.catch((error) => {});
	},

	fetchDepartments({ state, commit }) {
		Vue.axios({
			method: 'GET',
			url: '/fetchDepartments',
		})
			.then((response) => {
				commit('setDepartments', response.data.departments);
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'internal-contacts',
					err: error.response.data.error,
				});
			});
	},

	createInternalContacts({ dispatch, state, commit }, payload) {
		if (state.modal.fields.unit === '') {
			state.modal.errors.unit = 'To pole jest wymagane';
		} else {
			state.modal.fields;
			Vue.axios({
				method: 'POST',
				url: '/internal-contacts',
				data: {
					...state.modal.fields,
					...payload,
				},
			})
				.then((response) => {
					dispatch('fetchData', {
						page: 1,
						pageSize: 11,
						search: '',
						sortBy: [],
						sortDesc: [],
						routeName: 'internal-contacts',
					});
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie dodano nowy kontakt',
					});
				})
				.catch((error) => {
					commit('setErrors', {
						route: 'internal-contacts',
						err: error.response.data.error,
					});
				});
		}
	},

	internalContactsDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/internal-contacts/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setInternalContactsModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setInternalContactsDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'InternalContactss',
					err: error.response.data.error,
				});
			});
	},

	updateInternalContacts({ state, commit }, payload) {
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		} else {
			Vue.axios({
				method: 'PUT',
				url: `/internal-contacts/edit/${payload}`,
				data: {
					...state.modal.fields,
				},
			})
				.then((response) => {
					commit('setInternalContactsDeatils', response.data.item);
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano informacje',
					});
				})
				.catch((error) => {
					console.log(error);
					commit('setErrors', {
						route: 'internal-contacts',
						err: error.response.data.error,
					});
				});
		}
	},
};

const mutations = {
	setDepartments(state, payload) {
		state.departments = payload;
	},
	setContacts(state, payload) {
		state.contacts = payload;
	},
	setInternalContactsDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setInternalContactsModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.worker = payload.worker ? payload.worker : '';
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
