<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" style="z-index: 0;">
                <v-data-table
                    :headers="table.header"
                    fixed-header
                    height="calc(100vh - 365px)"
                    class="mt-4 central__table"
                    hide-default-footer
                    :items-per-page="rows_per_page"
                    :options.sync="options"
                    :loading="getTable.loading"
                >
                    <tbody>
                        <tr>
                            <td>ffffff</td>
                            <td class="text-right"></td>
                        </tr>
                    </tbody>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    data: () => ({
        table: {
            // search: "",
            header: [],
            items: [],
        },
        computed: {
            ...mapGetters(["getLeasingCar", "getLeasingCarH"]),
        },
    }),
    computed: {
        ...mapGetters(["getLeasingCar", "getLeasingCarH"]),
    },
    methods: {
        async setHeaderAndItems() {
            this.table.header = this.getLeasingCarH;
        },
    },
    created() {
        this.setHeaderAndItems();
    },
};
</script>