<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="pt-0">
                Użytkownicy:
                <v-select
                    v-model="person"
                    :items="getDropdown.items.users"
                    item-value="_id"
                    :menu-props="{ maxHeight: '400' }"
                >
                    <template v-slot:item="data">{{ data.item.lastname }} {{ data.item.name }}</template>
                    <template v-slot:selection="data">
                        <span
                            style="padding-right: 10px"
                        >{{ data.item.lastname+" " + data.item.name+", " }}</span>
                    </template>
                </v-select>
                <v-btn @click="send" outlined absolute right color="success">Wyślij</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    data: () => ({ person: "" }),
    computed: {
        ...mapGetters(["getDropdown", "getStorageAttachment", "getUser"]),
    },
    methods: {
        ...mapMutations(["closeModal"]),
        ...mapActions(["fetchItems", "copyAttachmentToStorage"]),
        async send() {
            if (this.person) {
                await this.copyAttachmentToStorage({
                    attachmentID: this.getStorageAttachment,
                    fromRegistryName: "users",
                    fromRegistryID: this.getUser.id,
                    registryID: this.person,
                    registryName: "users",
                });
                this.closeModal();
            }
        },
    },
    beforeMount() {
        this.fetchItems("users");
    },
};
</script>