<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="pt-0">
                <v-text-field
                    dense
                    label="Nazwa drukarki"
                    v-model="getPrintersPrints.name"
           
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
                <v-text-field
                    dense
                    label="Model drukarki"
                    v-model="getPrintersPrints.model"
           
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
                <v-textarea label="Opis dodatkowy" v-model="getPrintersPrints.comment"></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {

    computed: {
        ...mapGetters([
            "getPrintersPrints"
           // "getPrintersPartsError",
           // "getPrintersItems",
           // "getSeriesItems"
        ])
    }
}; 
</script>