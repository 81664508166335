import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	modalCoronavirus: false,
	coronavirus: {
		title: 'KORONAWIRUS',
		header: [
			// {
			//     text: 'Data, godz',
			//     align: 'left',
			//     value: 'dataArchive',
			//     class: 'central__table__header',
			//     width: '115px',
			// },
			{
				text: 'Nazwa',
				value: 'title',
				class: 'central__table__header',
				width: '55%',
			},
			// {
			//     text: 'Miesiąc',
			//     value: 'month',
			//     class: 'central__table__header',
			//     width: '10%',
			// },
			// {
			//     text: 'Status',
			//     value: 'status',
			//     class: 'central__table__header',
			//     width: '20%',
			// },
			// {
			//     text: 'Informacje',
			//     align: 'right',
			//     value: 'information',
			//     sortable: false,
			//     class: 'central__table__header',
			// },
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
			month: '',
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
			month: '',
		},
	},
	fieldsNames: {
		title: 'Tytuł',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		month: 'Miesiąc',
	},
};

const getters = {
	getCoronavirus: (state) => state.coronavirus,
	getCoronavirusModal: (state) => state.modal.fields,
	getCoronavirusError: (state) => state.modal.errors,
	getCoronavirusDetails: (state) => state.modal.details,
	getCoronavirusFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	createCoronavirus({ dispatch, state, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/coronavirus',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'coronavirus',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano nowy dyżur',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'coronavirus',
					err: error.response.data.error,
				});
			});
	},

	coronavirusDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/coronavirus/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setCoronavirusModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setCoronavirusDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'coronavirus',
					err: error.response.data.error,
				});
			});
	},

	updateCoronavirus({ state, commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/coronavirus/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setCoronavirusDeatils', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano informacje',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'coronavirus',
					err: error.response.data.error,
				});
			});
	},
};

const mutations = {
	setCoronavirusDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setCoronavirusModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
