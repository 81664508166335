import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
	dayJob: {
		open: false,
		dayJobName: '',
	},
	type: '',
	items: [],
	errors: {
		dayJobName: '',
	},
};
const getters = {
	getUDayJob: state => state.dayJob,
	getUDayJobType: state => state.type,
	getUDayJobErrors: state => state.errors,
	getUDayJobItems: state => state.items,
};
const actions = {
	createDayJob({ commit, state }) {
		state.errors.dayJobName = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionaries/users/dayJob',
			data: {
				...state.dayJob,
			},
		})
			.then(response => {
				commit('closeUDayJob');
				
				commit('setDayJobItem', response.data.item);
				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				if (error.response.data.error === 'Exists')
					state.errors.dayJobName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.dayJobName.kind ===
					'required'
				) {
					state.errors.dayJobName = 'Pole jest wymagane';
				}
			});
	},

	fetchUDayJob({ commit, state }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionaries/users/dayJob',
		})
			.then(response => {
				
				commit('setDayJobItems', response.data.items);
			})
			.catch(error => {
				console.log(error);
			});
	},

	removeUDayJob({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionaries/users/dayJob/delete/${payload}`,
		})
			.then(() => {
				commit('deleteUDJItem', payload);
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(error => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},
	UDJDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionaries/users/dayJob/${payload}`,
		})
			.then(response => {
				
				commit('setUDJDetails', response.data.item);
			})
			.catch(error => {
				console.log(error);
			});
	},

	UDJUpdate({ commit, state }, payload) {
		state.errors.dayJobName = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionaries/users/dayJob/update/${state.dayJob._id}`,
			data: {
				...state.dayJob,
			},
		})
			.then(response => {
				
				commit('closeUDayJob');
				commit('updateUDJItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				console.log(error.response.data);
				if (error.response.data.error === 'Exists')
					state.errors.dayJobName = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.dayJobName.kind ===
					'required'
				) {
					state.errors.dayJobName = 'Pole jest wymagane';
				}
			});
	},
};
const mutations = {
	closeUDayJob(state) {
		state.dayJob.open = false;
		state.dayJob.dayJobName = '';
	},
	clearUDJ(state) {
		state.dayJob.dayJobName = '';
		state.errors.dayJobName = '';
	},
	setUDayJobType(state, payload) {
		state.type = payload;
	},
	setDayJobItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	setDayJobItems(state, payload) {
		state.items = payload;
	},
	deleteUDJItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setUDJDetails(state, payload) {
		state.dayJob.dayJobName = payload.dayJobName;
		state.dayJob._id = payload._id;
		state.dayJob.open = true;
	},
	updateUDJItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
