<template>
  <div class="AE__header">
    <p class="font-weight-light AE__header__title py-3 pl-5">
      {{ $t(headLine) }}
      <!-- <span v-if="getFolderNameValue">ZARZĄDZANIE PLIKIEM</span> -->
    </p>
    <!-- {{ getPermission }} -->
    <v-btn
      class="AE__header__close"
      color="white"
      small
      icon
      @click="clearModal"
    >
      <v-icon color="#a1a1a1" size="15" medium>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
export default {
  props: ['headLine'],
  computed: {
    ...mapGetters([
      'getModal',
      'getPermission',
      'getFolderNameValue',
      'getLayoutWSD',
    ]),
  },
  methods: {
    ...mapMutations(['clearModal']),
    clearModal() {
      if (this.getLayoutWSD.wsd == true) {
        let modal = document.querySelector('.v-card');
        modal.style.top = '50%';
        modal.style.left = '50%';
        modal.style.transform = 'translate(-50%, -50%)';
      }
      this.$store.commit('setModal', {
        headLine: '',
        open: false,
        width: 0,
        type: '',
      });
    },
  },
};
</script>
