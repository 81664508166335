var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add mr-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":""},on:{"click":_vm.goToLocation}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker")])],1)]}}])},[_c('span',[_vm._v("Przejdź do mapy z lokalizacją")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-speed-dial',{staticClass:"mr-2",attrs:{"direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g({style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"color":"buttons--add","dark":"","fab":""},model:{value:(_vm.filtersTooltip),callback:function ($$v) {_vm.filtersTooltip=$$v},expression:"filtersTooltip"}},on),[(_vm.filtersTooltip)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter-plus")])],1)]},proxy:true}],null,true),model:{value:(_vm.filtersIconTooltip),callback:function ($$v) {_vm.filtersIconTooltip=$$v},expression:"filtersIconTooltip"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#0665ca"},on:{"click":function($event){return _vm.filtrStatus('Wycena')}}},on),[_c('span',[_vm._v("W")])])]}}],null,true)},[_c('span',[_vm._v("Wycena")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#e8c70c"},on:{"click":function($event){return _vm.filtrStatus('Claim')}}},on),[_c('span',[_vm._v("C")])])]}}],null,true)},[_c('span',[_vm._v("Claim")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#009fff"},on:{"click":function($event){return _vm.filtrStatus('Faktura')}}},on),[_c('span',[_vm._v("F")])])]}}],null,true)},[_c('span',[_vm._v("Faktura")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#009fff"},on:{"click":function($event){return _vm.filtrStatus('Gwarancja')}}},on),[_c('span',[_vm._v("G")])])]}}],null,true)},[_c('span',[_vm._v("Gwarancja")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#F44336"},on:{"click":function($event){return _vm.filtrStatus('Pilne')}}},on),[_c('span',[_vm._v("P")])])]}}],null,true)},[_c('span',[_vm._v("Pilne")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#4CAF50"},on:{"click":function($event){return _vm.filtrStatus('Kontynuacja')}}},on),[_c('span',[_vm._v("K")])])]}}],null,true)},[_c('span',[_vm._v("Kontynuacja")])])],1)]}}])},[_c('span',[_vm._v("Filtry (ikony)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-speed-dial',{staticClass:"mr-2",attrs:{"direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g({style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"color":"buttons--add","dark":"","fab":""},model:{value:(_vm.filtersTooltip),callback:function ($$v) {_vm.filtersTooltip=$$v},expression:"filtersTooltip"}},on),[(_vm.filtersTooltip)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")])],1)]},proxy:true}],null,true),model:{value:(_vm.filtersTooltip),callback:function ($$v) {_vm.filtersTooltip=$$v},expression:"filtersTooltip"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add mr-2",attrs:{"fab":"","dark":"","small":""},on:{"click":_vm.showAllService}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-wrench")])],1)]}}],null,true)},[_c('span',[_vm._v("Wszystkie")])]),_vm._l((_vm.getTable.statuses),function(status){return _c('v-tooltip',{key:status._id,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":status.color.background},on:{"click":function($event){return _vm.filtrStatus(status.name)}}},on),[_c('span',[_vm._v(_vm._s(status.name[0]))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(status.name))])])}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"#ff9800"},on:{"click":function($event){return _vm.filtrStatus('Kontynuacja')}}},on),[_c('span',[_vm._v("K")])])]}}],null,true)},[_c('span',[_vm._v("Kontynuacja")])])],2)]}}])},[_c('span',[_vm._v("Filtry")])]),(_vm.getPermission.level > 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add mr-2",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.showOrder({ day: 0 })}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-checks")])],1)]}}],null,false,3178727932)},[_c('span',[_vm._v("Stan na dziś")])]):_vm._e(),(_vm.getPermission.level > 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":""},on:{"click":_vm.newService}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-plus")])],1)]}}],null,false,1473852073)},[_c('span',[_vm._v("Dodaj nowe zlecenie")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }