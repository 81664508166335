import Vue from 'vue';
import router from '@/router.js';
import findIndex from 'lodash.findindex';
import moment from 'moment-timezone';
const state = {
	modalDirectorOrders: false,
	directorOrders: {
		title: 'ZARZĄDZENIA DYREKTORA',
		header: [
			{
				text: 'Nr zarządzenia',
				align: 'left',
				width: 100,
				width: '15%',
				value: 'indexOrdinance',
				class: 'central__table__header',
			},
			{
				text: 'Nazwa',
				width: 500,
				width: '55%',
				value: 'title',
				class: 'central__table__header',
			},
			{
				text: 'Menager',
				width: 500,
				value: 'menager',
				width: '10%',
				class: 'central__table__header',
			},
			{
				text: 'Status',
				value: 'status',
				width: '10%',
				width: 100,
				class: 'central__table__header',
			},
			// {
			// 	text: 'Informacje',
			// 	align: 'center',
			// 	value: 'informations',
			// 	width: '6%',
			// 	sortable: false,
			// 	class: 'central__table__header',
			// },
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				width: '5%',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			index: '',
			indexOrdinance: '',
			title: '',
			user: {
				users: [],
			},
			archiveDate: '',
			information: '',
			responsiblePerson: [],
		},
		details: {
			index: '',
			indexOrdinance: '',
			title: '',
			user: {
				users: [],
			},
			archiveDate: '',
			information: '',
			responsiblePerson: [],
		},
		errors: {
			index: '',
			indexOrdinance: '',
			title: '',
			archiveDate: '',
			information: '',
		},
	},
	fieldsNames: {
		title: 'Tytuł',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		indexOrdinance: 'Numer zarządzenia',
		responsiblePerson: 'Osoby odpowiedzialne',
	},
};

const getters = {
	getDirectorOrders: (state) => state.directorOrders,
	getDirectorOrdersModel: (state) => state.modal.fields,
	getDirectorOrdersDetails: (state) => state.modal.details,
	getDirectorOrdersErrors: (state) => state.modal.errors,
};

const actions = {
	createDirectorOrders({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/director-orders',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: payload.page,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'director-orders',
				});
				commit('closeModal');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano nowe zarządzenie',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'directorOrders',
					err: error.response.data.error,
				});
			});
	},

	directorOrdersDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/director-orders/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setDirectorOrdersModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setDirectorOrdersDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'directorOrders',
					err: error.response.data.error,
				});
			});
	},

	updateDirectorOrder({ state, commit }, payload) {
		// commit('resetErrors', 'directorOrders');
		if (state.modal.fields.title === '') {
			state.modal.errors.title = 'To pole jest wymagane';
		} else {
			Vue.axios({
				method: 'PUT',
				url: `/director-orders/edit/${payload}`,
				data: {
					...state.modal.fields,
				},
			})
				.then((response) => {
					commit('setOrderDirector', response.data.item);
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano informacje',
					});
				})
				.catch((error) => {
					console.log(error);

					commit('setErrors', {
						route: 'directorOrders',
						err: error.response.data.error,
					});
				});
		}
	},
};

const mutations = {
	setOrderDirector(state, payload) {
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
		state.modal.details = payload;
	},
	setDirectorOrdersDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setDirectorOrdersModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},

	removeUserDirectorOrder(state, payload) {
		let arr = state.modal.fields.responsiblePerson;
		let index = findIndex(arr, (e) => e == payload);
		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
