<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn
                fab
                dark
                v-on="on"
                small
                class="buttons--add"
                @click="openEdit"
            >
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    methods: {
        ...mapActions(['openModal', 'resourceDetails']),
        ...mapMutations(['clearResources']),
        openEdit() {
            this.clearResources();
            this.resourceDetails({ id: this.$route.params.id, type: 'modal' });
            this.openModal({
                headLine: 'global.create',
                open: true,
                width: 600,
                type: 'edit',
                route: 'resources',
                tab: 'resources',
            });
        },
    },
};
</script>
