var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('Separator',{attrs:{"text":'Informacje podstawowe'}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","item-value":"carModelname","label":_vm.getCarsFieldsNames.model,"items":_vm.getCarsDictionaryItems,"error-messages":_vm.getCarsErrors.model,"item-text":"carModelname"},model:{value:(_vm.getCarsFields.model),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "model", $$v)},expression:"getCarsFields.model"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"dense":"","item-value":"_id","label":_vm.getCarsFieldsNames.type,"items":_vm.type},model:{value:(_vm.getCarsFields.classes),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "classes", $$v)},expression:"getCarsFields.classes"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"dense":"","error-messages":_vm.getCarsErrors.plate,"label":_vm.getCarsFieldsNames.plate},model:{value:(_vm.getCarsFields.plate),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "plate", $$v)},expression:"getCarsFields.plate"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('Separator',{attrs:{"text":'Informacje dodatkowe'}})],1),_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","error-messages":_vm.getCarsErrors.rejdat,"label":_vm.getCarsFieldsNames.rejdat,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.getCarsFields.rejdat),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "rejdat", $$v)},expression:"getCarsFields.rejdat"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.getCarsFields.rejdat),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "rejdat", $$v)},expression:"getCarsFields.rejdat"}})],1)],1)],1),_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","error-messages":_vm.getCarsErrors.evidence,"label":_vm.getCarsFieldsNames.evidence},model:{value:(_vm.getCarsFields.evidence),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "evidence", $$v)},expression:"getCarsFields.evidence"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","label":_vm.getCarsFieldsNames.inSuranceExpiration,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.getCarsFields.inSuranceExpiration),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "inSuranceExpiration", $$v)},expression:"getCarsFields.inSuranceExpiration"}},on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.getCarsFields.inSuranceExpiration),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "inSuranceExpiration", $$v)},expression:"getCarsFields.inSuranceExpiration"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","error-messages":_vm.getCarsErrors.survey,"label":_vm.getCarsFieldsNames.survey,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.getCarsFields.survey),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "survey", $$v)},expression:"getCarsFields.survey"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.getCarsFields.survey),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "survey", $$v)},expression:"getCarsFields.survey"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"dense":"","error-messages":_vm.getCarsErrors.vin,"label":_vm.getCarsFieldsNames.vin},model:{value:(_vm.getCarsFields.vin),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "vin", $$v)},expression:"getCarsFields.vin"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","error-messages":_vm.getCarsErrors.year,"label":_vm.getCarsFieldsNames.year,"readonly":""},model:{value:(_vm.getCarsFields.year),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "year", $$v)},expression:"getCarsFields.year"}},on))]}}]),model:{value:(_vm.data2),callback:function ($$v) {_vm.data2=$$v},expression:"data2"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.data2 = false}},model:{value:(_vm.getCarsFields.year),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "year", $$v)},expression:"getCarsFields.year"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.getCarsFieldsNames.tires},model:{value:(_vm.getCarsFields.tires),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "tires", $$v)},expression:"getCarsFields.tires"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","error-messages":_vm.getCarsErrors.warranty,"label":_vm.getCarsFieldsNames.warranty,"readonly":""},model:{value:(_vm.getCarsFields.warranty),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "warranty", $$v)},expression:"getCarsFields.warranty"}},on))]}}]),model:{value:(_vm.data3),callback:function ($$v) {_vm.data3=$$v},expression:"data3"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.data3 = false}},model:{value:(_vm.getCarsFields.warranty),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "warranty", $$v)},expression:"getCarsFields.warranty"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"mt-5",attrs:{"dense":"","label":_vm.getCarsFieldsNames.property,"error-messages":_vm.getCarsErrors.property,"items":_vm.propertyItems},model:{value:(_vm.getCarsFields.property),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "property", $$v)},expression:"getCarsFields.property"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"mt-5",attrs:{"dense":"","label":_vm.getCarsFieldsNames.driver,"items":_vm.getDropdown.items.users,"item-value":"_id"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(_vm._s(data.item.lastname)+" "+_vm._s(data.item.name))]}},{key:"item",fn:function(data){return [_vm._v(_vm._s(data.item.lastname)+" "+_vm._s(data.item.name))]}}]),model:{value:(_vm.getCarsFields.driver),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "driver", $$v)},expression:"getCarsFields.driver"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":_vm.getCarsFieldsNames.description,"rows":"1","auto-grow":""},model:{value:(_vm.getCarsFields.description),callback:function ($$v) {_vm.$set(_vm.getCarsFields, "description", $$v)},expression:"getCarsFields.description"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }