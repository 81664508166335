const state = {
    // === === USERS === === //
    modal: {
        fields: {
            name: null,
            lastname: null,
            birthday: null,
            userIndex: null,
            login: null,
            job: null,
            password: null,
            passwordAgain: null,
            mail: null,
            phone: null,
            isAdmin: null,
        },
        errors: {
            name: null,
            lastname: null,
            login: null,
            password: null,
            mail: null,
            userIndex: null,
        },
    },
};



export default {
    state,



};