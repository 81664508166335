import Vue from 'vue';
import i18n from '@/i18n.js';
import findIndex from 'lodash.findindex';

const state = {
	registry: {
		title: 'Rejestry',
		header: [
			{
				text: 'Nazwa',
				align: 'left',
				value: 'name',

				class: 'central__table__header',
			},
			{
				text: 'Index',
				value: 'index',
				width: 200,
				class: 'central__table__header',
			},
			{
				text: 'Domyślny menager',
				value: 'defaultMenager',
				width: 200,
				class: 'central__table__header',
			},
			{
				text: 'Data wprowadzenia',
				value: 'createdAt',
				width: 250,
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			name: '',
			index: '',
			url: '',
			icon: '',
			status: '',
			menager: [],
			defaultMenager: '',
			user: {
				users: [],
				groups: [],
				departments: [],
			},
			reader: {
				users: [],
				groups: [],
				departments: [],
			},
			possibleStatus: [],
			defaultStatus: '',
		},
		errors: {
			name: '',
			index: '',
			url: '',
			icon: '',
			status: '',
			menager: '',
			defaultMenager: '',
			defaultStatus: '',
		},
	},
};
const getters = {
	getRegistry: (state) => state.registry,
	getRegistryData: (state) => state.modal.fields,
	getRegistryErrors: (state) => state.modal.errors,
	getPossibleStatuses: (state) =>
		state.modal.fields.possibleStatus.filter((item) => {
			return item.isDeleted == false;
		}),
};

const actions = {
	async createRegistry({ state, commit, dispatch }) {
		commit('resetErrors', 'registries');
		await Vue.axios({
			method: 'POST',
			url: '/registries',
			data: {
				...state.modal.fields,
			},
		})
			.then(() => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'registries',
				});
				commit('closeModal');
				commit('resetStatus');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'registries',
					err: error.response.data.error,
				});
			});
	},
	registryDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/registries/${payload.id}`,
		}).then((response) => {
			commit('setRegistryDetails', response.data.registry);
			if (payload.next) payload.next();
		});
	},

	updateRegistry({ commit }) {
		Vue.axios({
			method: 'PUT',
			url: `/registries/${state.modal.fields._id}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('updateItem', response.data.registry);
				commit('closeModal');
				commit('resetStatus');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'registries',
					err: error.response.data.error,
				});
			});
	},

	registryDetailsByUrl({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/registryByUrl/${payload.url}`,
		}).then((response) => {
			commit('setRegistryDetails', response.data.registry);
			if (payload.next) payload.next();
		});
	},
};
const mutations = {
	removeDefaultMenager(state) {
		state.modal.fields.defaultMenager = '';
	},
	removeMenager(state, payload) {
		let arr = state.modal.fields.menager;
		let index = findIndex(arr, (e) => e == payload);
		arr.splice(index, 1);
		if (payload == state.modal.fields.defaultMenager) {
			state.modal.fields.defaultMenager = '';
		}
	},
	removeUser(state, payload) {
		let arr = state.modal.fields.user[payload.section];
		let index = findIndex(arr, (e) => e == payload.id);
		arr.splice(index, 1);
	},
	removeReader(state, payload) {
		let arr = state.modal.fields.reader[payload.section];
		let index = findIndex(arr, (e) => e == payload.id);
		arr.splice(index, 1);
	},
	setRegistryDetails(state, payload) {
		state.modal.fields = payload;
	},
	addToPossibleStatus(state, payload) {
		let index = state.modal.fields.possibleStatus.findIndex(
			(item) =>
				item.name.trim() == payload.name && item.isDeleted == false,
		);

		if (index < 0) state.modal.fields.possibleStatus.push(payload);
	},
	resetStatus(state) {
		state.modal.fields.possibleStatus = [];
		state.modal.fields.defaultStatus = null;
	},
	updatePossibleStatus(state, payload) {
		let arr = state.modal.fields.possibleStatus.filter((item) => {
			return item.isDeleted == false;
		});
		arr[payload.index] = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
