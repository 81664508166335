<template>
    <v-container fill-height>
        <v-row>
            <v-col cols="12" class="py-1 px-0 p-relative" md="12" lg="6">
                <div class="d-flex">
                    <v-col cols="12" md="12" class="worker__wrapper__image">
                        <v-avatar size="100" color="secondary">
                            <img
                                :src="`${getGlobalPaths.profilePic}${getUserDetails.picture}`"
                                alt="avatar"
                                v-if="getUserDetails.picture"
                            />
                            <span v-else class="white--text display-1">{{showAvatar}}</span>
                        </v-avatar>
                        <div class="mx-4">
                            <span
                                class="title text-wrap center"
                            >{{getUserDetails.name}} {{getUserDetails.lastname}}</span>
                            <span class="grey--text">{{getUserDetails.job}}</span>
                        </div>
                    </v-col>
                </div>

                <div class="mt-4 mb-0 p-relative">
                    <div class="d-flex">
                        <v-col cols="12" class="py-1" md="6">
                            <v-text-field
                                dense
                                class="pa-1"
                                disabled
                                label="ID"
                                :value="getUserDetails.userIndex ? getUserDetails.userIndex : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-1" md="6">
                            <v-text-field
                                dense
                                class="pa-1"
                                disabled
                                label="Login"
                                :value="getUserDetails.login"
                            ></v-text-field>
                        </v-col>
                    </div>

                    <div class="d-flex">
                        <v-col cols="12" class="py-1" md="6">
                            <v-text-field
                                dense
                                class="pa-1"
                                disabled
                                label="Tel. komórkowy"
                                :value="getUserDetails.phone ? getUserDetails.phone : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-1" md="6">
                            <v-text-field
                                dense
                                class="pa-1"
                                disabled
                                label="Tel. stacjonarny"
                                :value="getUserDetails.privatePhone ? getUserDetails.privatePhone : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>

                    <div class="d-flex">
                        <v-col cols="12" class="py-1" md="6">
                            <v-text-field
                                dense
                                class="pa-1"
                                disabled
                                label="Adres email"
                                :value="getUserDetails.mail ? getUserDetails.mail : 'Brak'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-1" md="6">
                            <v-text-field
                                dense
                                class="pa-1"
                                disabled
                                label="Adres email prywatny"
                                :value="getUserDetails.privateMail ? getUserDetails.privateMail : 'Brak'"
                            ></v-text-field>
                        </v-col>
                    </div>

                    <v-col cols="12" md="12">
                        <v-textarea
                            class="worker__wrapper__comments"
                            disabled
                            dense
                            no-resize
                            height="100"
                            label="Uwagi"
                            :value="getUserDetails.comment"
                        ></v-textarea>
                    </v-col>

                    <div class="d-flex">
                        <v-col cols="12" class="py-1" md="6">
                            <v-list dense class="worker__wrapper__permission">
                                <v-subheader class="pa-0 grey--text">Twoje uprawnienia:</v-subheader>
                                <v-list-item-group class="worker__wrapper__permission__tabele">
                                    <v-list-item
                                        class="pa-0"
                                        color="grey"
                                        v-for="item in registriesItems"
                                        :key="item.name"
                                    >
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-title class="grey--text">{{item.name}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="12" class="py-1" md="6" style="z-index: 0">
                            <v-list dense class="worker__wrapper__permission">
                                <v-subheader class="pa-0 grey--text">Pracownik przypisany do:</v-subheader>
                                <v-list-item-group class="worker__wrapper__permission__tabele">
                                    <v-list-item
                                        class="pa-0"
                                        color="grey"
                                        v-for="item in departmentsItems"
                                        :key="item.fullName"
                                    >
                                        <v-list-item-content class="pa-0">
                                            <v-list-item-title class="grey--text">{{item.fullName}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </div>
                </div>
                <v-divider vertical class="worker__wrapper__divider"></v-divider>
            </v-col>

            <v-col cols="12" md="6" lg="5" class="py-1" v-if="false">
                <v-card-title class="pa-0 ma-0">Nieobecności</v-card-title>

                <div class="row mt-2">
                    <v-col cols="12" md="4">
                        <span>Nieobecności za bieżący rok</span>

                        <v-divider class="pa-2"></v-divider>

                        <v-text-field dense value="0" disabled label="Dni chorobowych:"></v-text-field>
                        <v-text-field dense value="0" disabled label="Dni urlopu:"></v-text-field>
                        <v-text-field dense value="0" disabled label="Dni urlopu na żądanie:"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <span>Nieobecności za poprzedni rok</span>
                        <v-divider class="pa-2"></v-divider>

                        <v-text-field dense value="0" disabled label="Dni chorobowych:"></v-text-field>
                        <v-text-field dense value="0" disabled label="Dni urlopu:"></v-text-field>
                        <v-text-field dense value="0" disabled label="Dni urlopu na żądanie:"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="p-relative">
                        <v-divider class="worker__wrapper__divider__presence" vertical></v-divider>
                        <span>Informacje bieżące</span>
                        <v-divider class="pa-2"></v-divider>

                        <v-text-field dense value="0" disabled label="Dni urlopu do wykorzystania"></v-text-field>
                        <v-text-field dense value="0" disabled label="Na urlopie?"></v-text-field>
                        <v-text-field dense value="0" disabled label="Na zwolnieniu chorobowym?"></v-text-field>
                    </v-col>
                </div>
                <div class="row">
                    <v-col cols="12" md="12" class="py-0 mt-3">
                        <p>Wnioski urlopowe</p>

                        <v-data-table
                            :headers="headers"
                            fixed-header
                            :items="desserts"
                            class="py-0 grey--text"
                            height="190px"
                        ></v-data-table>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>




      <script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        headers: [
            {
                text: "Numer",
                align: "left",
                value: "name",
                width: "150",
                class: "central__table__header__profil",
            },
            {
                text: "Stan",
                value: "status",
                width: "120",
                class: "central__table__header__profil",
            },
            {
                text: "Przełożony",
                value: "supervisor",
                class: "central__table__header__profil",
            },
            {
                text: "Początek urlopu:",
                value: "start",
                class: "central__table__header__profil",
            },
            {
                text: "Koniec urlopu włącznie:",
                value: "end",
                class: "central__table__header__profil",
            },
            {
                text: "Pracownik zastępujący",
                value: "proxy",
                class: "central__table__header__profil",
            },
        ],
        desserts: [],
    }),
    computed: {
        ...mapGetters([
            "getUserDetails",
            "getGlobalPaths",
            "getUserDepartments",
            "getUserRegistriesList",
        ]),
        showAvatar() {
            if (this.getUserDetails.name && this.getUserDetails.lastname)
                return `${this.getUserDetails.name[0].toUpperCase()}${this.getUserDetails.lastname[0].toUpperCase()}`;
        },
        departmentsItems() {
            if (this.getUserDepartments.length > 0)
                return this.getUserDepartments;
            else return [{ fullName: "Brak" }];
        },
        registriesItems() {
            if (this.getUserRegistriesList.length > 0)
                return this.getUserRegistriesList;
            else return [{ name: "Brak" }];
        },
    },
};
</script>
