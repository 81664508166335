<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="notificationsDevices">
                <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj nowy alert</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["openModal"]),
        notificationsDevices() {
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "create",
                width: 600,
                route: "notifications",
                tab: "notification"
            });
        }
    }
};
</script>
