<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" lg="6" class="d-flex flex-wrap">
        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            readonly
            class="no-pointer"
            v-model="getPartsDetails.producent"
            dense
            :label="getPartsFieldsNames.producent"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            dense
            class="no-pointer"
            :value="getPartsDetails.manufacturer ? getPartsDetails.manufacturer.name : ''"
            :label="getPartsFieldsNames.manufacturer"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="pt-0">
          <v-text-field
            readonly
            class="no-pointer"
            v-model="getPartsDetails.name"
            dense
            :label="getPartsFieldsNames.name"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-text-field
            readonly
            class="no-pointer"
            v-model="getPartsDetails.replacement"
            dense
            :label="getPartsFieldsNames.replacement"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            readonly
            class="no-pointer"
            v-model="getPartsDetails.stateParts"
            dense
            :label="getPartsFieldsNames.stateParts"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <!-- <v-text-field
            readonly
            class="no-pointer"
            :value="changePrice()"
            dense
            :label="getPartsFieldsNames.price"
          ></v-text-field>-->
          <div class="input-effect price" style="margin-top: -18px">
            <span>{{getPartsFieldsNames.price}}</span>
            <money
              style="text-align: left; font-size: 14px; width: 110px; border-bottom: 1px solid #949494; color: #272727; "
              disabled
              v-model="getPartsDetails.price"
              v-bind="money"
            ></money>
            <span class="focus-border"></span>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            :value="getPartsDetails.series ? getPartsDetails.series.seriesName : ''"
            class="no-pointer"
            dense
            :label="getPartsFieldsNames.series"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            readonly
            :value="getPartsDetails.model ? getPartsDetails.model.model : ''"
            class="no-pointer"
            dense
            :label="getPartsFieldsNames.model"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            readonly
            v-model="getPartsDetails.comment"
            class="no-pointer"
            dense
            auto-grow
            :label="getPartsFieldsNames.comment"
            rows="1"
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Buttons from "@/components/Global/Details/Notifications/ButtonsAE.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      suffix: " PLN",
      precision: 2,
      masked: false
    }
  }),
  components: {
    Buttons
  },
  computed: {
    ...mapGetters(["getPartsDetails", "getPartsFieldsNames"])
  },
  methods: {
    ...mapActions(["openModal", "partsDetails"]),
    parts() {
      this.openModal({
        headLine: "global.create",
        open: true,
        type: "create",
        width: 600,
        route: "devices",
        tab: "notification"
      });
    },
    changePrice() {
      let price = this.getPartsDetails.price;
      let number = Number(price).toFixed(2);
      return number;
    }
  }
};
</script>
