<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="6" class="d-flex flex-wrap py-0 ma-0">
        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            dense
            v-model="getModalLeasing.nrLeasing"
            :label="getLeasingFieldsNames.nrLeasing"
            :error-messages="getLeasingErrors.nrLeasing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <v-text-field
            dense
            v-model="getModalLeasing.titleLeasing"
            :label="getLeasingFieldsNames.titleLeasing"
            :error-messages="getLeasingErrors.titleLeasing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="py-4">
          <v-autocomplete
            v-model="getModalLeasing.contractorLeasing"
            dense
            class="font-devices"
            :label="getLeasingFieldsNames.contractorLeasing"
            :hide-no-data="true"
            hint="Zacznij pisać..."
            @keyup="findContacts($event)"
            :items="getLeasingItems"
            item-text="name"
            return-object
            @input="setClientThings($event)"
          >
            <template slot="prepend">
              <v-icon color="#b3b3b3" size="18">mdi-account</v-icon>
            </template>
          </v-autocomplete>
          <v-tabs centered>
            <v-tab ripple>reprezentant</v-tab>
            <v-tab ripple>dodaj nowego reprezentanta</v-tab>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-autocomplete
                    v-model="
                                            getModalLeasing.responsibleLeasing
                                        "
                    dense
                    class="font-devices"
                    :label="
                                            getLeasingFieldsNames.responsibleLeasing
                                        "
                    item-value="_id"
                    item-text="name"
                    :items="getLeasingRepresentatives"
                  >
                    <template v-slot:selection="data">
                      <p class="pa-0 ma-0">
                        {{ data.item.name }}
                        {{ data.item.lastname }}
                        <span
                          v-if="data.item.phoneNumber"
                        >
                          •
                          {{
                          data.item.phoneNumber
                          }}
                        </span>
                        <span v-if="data.item.email">
                          &nbsp;&nbsp;•
                          {{ data.item.email }}
                        </span>
                      </p>
                    </template>
                    <template v-slot:item="data">
                      <p class="pa-0 ma-0">
                        {{ data.item.name }}
                        {{ data.item.lastname }}
                        <span
                          v-if="data.item.phoneNumber"
                        >
                          •
                          {{
                          data.item.phoneNumber
                          }}
                        </span>
                        <span v-if="data.item.email">
                          &nbsp;&nbsp;•
                          {{ data.item.email }}
                        </span>
                      </p>
                    </template>
                    <template slot="prepend">
                      <v-icon color="#b3b3b3" size="18">mdi-phone</v-icon>
                    </template>
                    <template slot="append">
                      <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                    </template>
                  </v-autocomplete>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :label="
                                                    getLeasingFieldsNames.responsiblenewnameLeasing
                                                "
                        required
                        v-model="
                                                    getModalLeasing.responsiblenewnameLeasing
                                                "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :label="
                                                    getLeasingFieldsNames.responsiblenewlastLeasing
                                                "
                        required
                        v-model="
                                                    getModalLeasing.responsiblenewlastLeasing
                                                "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :label="
                                                    getLeasingFieldsNames.responsiblenewemailLeasing
                                                "
                        required
                        v-model="
                                                    getModalLeasing.responsiblenewemailLeasing
                                                "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        :label="
                                                    getLeasingFieldsNames.responsiblenewephoneLeasing
                                                "
                        required
                        v-model="
                                                    getModalLeasing.responsiblenewephoneLeasing
                                                "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-select
            dense
            :label="getLeasingFieldsNames.subjectLeasing"
            v-model="getModalLeasing.subjectLeasing"
            class="mt-5"
            :items="subjectItems"
          ></v-select>
        </v-col>
      </v-col>
      <v-col cols="12" md="12" lg="6" class="d-flex flex-wrap py-0 ma-0">
        <v-col cols="6" md="6" class="py-0">
          <v-menu
            v-model="beginningdata"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="getModalLeasing.databeginningLeasing"
                dense
                :label="getLeasingFieldsNames.databeginningLeasing"
                prepend-icon="mdi-calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="getModalLeasing.databeginningLeasing"
              @input="beginningdata = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="enddata"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="getModalLeasing.dataendLeasing"
                dense
                :label="getLeasingFieldsNames.dataendLeasing"
                prepend-icon="mdi-calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="getModalLeasing.dataendLeasing"
              @input="enddata = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" md="6" class="py-0">
          <div class="input-effect price" style="margin-top: -15px; margin-bottom: 20px">
            <span class="mt-0 mb-0">{{getLeasingFieldsNames.valuenettoLeasing}}</span>
            <money
              style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
              v-bind="money"
              v-model="getModalLeasing.valuenettoLeasing"
            ></money>
            <span class="focus-border"></span>
          </div>
          <div class="input-effect price" style="margin-top: -15px; margin-bottom: 20px">
            <span class="mt-0 mb-0">{{getLeasingFieldsNames.valuemonthlyLeasing}}</span>
            <money
              style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
              v-bind="money"
              v-model="getModalLeasing.valuemonthlyLeasing"
            ></money>
            <span class="focus-border"></span>
          </div>
          <div class="input-effect price" style="margin-top: -15px; margin-bottom: 20px">
            <span class="mt-0 mb-0">{{getLeasingFieldsNames.installmentLeasing}}</span>
            <money
              style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
              v-bind="money"
              v-model="getModalLeasing.installmentLeasing"
            ></money>
            <span class="focus-border"></span>
          </div>

          <div class="input-effect price" style="margin-top: -15px; margin-bottom: 20px">
            <span class="mt-0 mb-0">{{getLeasingFieldsNames.repurchaseLeasing}}</span>
            <money
              style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
              v-bind="money"
              v-model="getModalLeasing.repurchaseLeasing"
            ></money>
            <span class="focus-border"></span>
          </div>
        </v-col>
        <v-col cols="6" md="12" class="py-0">
          <v-textarea
            rows="2"
            :label="getLeasingFieldsNames.commentLeasingt"
            v-model="getModalLeasing.commentLeasingt"
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";
import { money } from "v-money";
export default {
  data: () => ({
    subjectItems: ["Pojazd", "Urządzenie dokumentowe", "Inne"],
    beginningdata: false,
    enddata: false,
    money: {
      decimal: ",",
      thousands: ".",
      suffix: " PLN",
      precision: 2,
      masked: false,
    },
  }),
  computed: {
    ...mapGetters([
      "getModalLeasing",
      "getLeasingFieldsNames",
      "getLeasingItems",
      "getLeasingRepresentatives",
      "getLeasingErrors",
    ]),
  },
  methods: {
    ...mapActions(["fetchContacts"]),
    ...mapMutations(["closeModal", "setClientThings", "setClientItems"]),
    findContacts: debounce(function (e) {
      this.fetchContacts(e.target.value);
    }, 500),
  },
};
</script>
