import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	modalPatientsConsent: false,
	patientsConsent: {
		title: 'ZGODY PACJENTA',
		header: [
			{
				text: 'Nazwa',
				value: 'title',
				class: 'central__table__header',
				width: '70%',
			},
			{
				text: 'Menager',
				value: 'menager',
				class: 'central__table__header',
				width: '10%',
			},
			// {
			//     text: 'Status',
			//     value: 'status',
			//     class: 'central__table__header',
			//     width: '10%',
			// },
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				width: '10%',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
			version: '',
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
			version: '',
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
			version: '',
		},
	},
	fieldsNames: {
		title: 'Nazwa druku, formularza',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		patientsConsentIndex: 'Index',
		version: 'Wersja',
	},
};

const getters = {
	getPatientsConsent: (state) => state.patientsConsent,
	getPatientsConsentModal: (state) => state.modal.fields,
	getPatientsConsentError: (state) => state.modal.errors,
	getPatientsConsentDetails: (state) => state.modal.details,
	getPatientsConsentFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	createPatientsConsent({ dispatch, state, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/patients-consent',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'patients-consent',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano nowy druk',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'patientsConsent',
					err: error.response.data.error,
				});
			});
	},

	patientsConsentsDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/patients-consent/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setPatientsConsentModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setPatientsConsentDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'patientsConsent',
					err: error.response.data.error,
				});
			});
	},

	updatePatientsConsents({ state, commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/patients-consent/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setPatientsConsentDeatils', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano informacje',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'patientsConsent',
					err: error.response.data.error,
				});
			});
	},
};

const mutations = {
	setPatientsConsentDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setPatientsConsentModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
