<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === DATA === === -->
            <!-- <td>{{item.startDate ? $moment(item.startDate).format('YYYY-MM-DD HH:mm:ss') : 'Brak'}}</td> -->

            <!-- === === NAZWA === === -->
            <td>{{item.title ? item.title : 'Brak'}}</td>

            <!-- === === STATUS === === -->
            <!-- <td>
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>-->

            <!-- === === DODAŁ === === -->

            <td style="font-size: 12px">{{item.menager.name}} {{item.menager.lastname}}</td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({path:`/non-medical-print/${item._id}`})">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
    props: ["items"],
    data: () => ({}),
    computed: { ...mapGetters(["getPermission", "getUser"]) },
    methods: {},
};
</script>