<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.index }}</td>
            <td>
                {{
                item.createdAt
                ? $moment(item.createdAt).format('YYYY-MM-DD') :''
                }}
                <v-tooltip right v-if="item.rotationType == 'Dostawa'">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="#1b803b">mdi-arrow-left-bold</v-icon>
                    </template>
                    <span>Dostawa</span>
                </v-tooltip>
                <v-tooltip right v-else>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="#b82e04">mdi-arrow-right-bold</v-icon>
                    </template>
                    <span>Wydanie</span>
                </v-tooltip>
            </td>
            <td>
                {{
                item.construction
                ? item.construction.name
                : item.contactId
                ? item.contactId.name
                : ''
                }}
            </td>
            <td>
                {{
                item.carId
                ? item.carId.model + ' ' + item.carId.plate
                : item.carPlate
                }}
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            :color="item.carId ? '#1b803b' : '#b82e04'"
                            small
                        >mdi-truck</v-icon>
                    </template>
                    <span>{{ item.carId ? 'Własny' : 'Obcy' }}</span>
                </v-tooltip>
            </td>
            <td>{{correctWeight(item.grossWeight, item.tareWeight)}}</td>
            <td v-if="getRegistryHeader.status">
                <v-chip
                    :hidden="!item.status.possibleStatus"
                    :color="
						item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.color &&
						item.status.possibleStatus.color.background
							? item.status.possibleStatus.color.background
							: '#555555'
					"
                    :style="
						`color: ${
							item.status &&
							item.status.possibleStatus &&
							item.status.possibleStatus.color &&
							item.status.possibleStatus.color.font
								? item.status.possibleStatus.color.font
								: '#FFFFFF'
						}`
					"
                    dark
                    label
                    small
                >
                    {{
                    item.status &&
                    item.status.possibleStatus &&
                    item.status.possibleStatus.name
                    ? item.status.possibleStatus.name
                    : 'Brak'
                    }}
                </v-chip>
            </td>
            <td v-if="getRegistryHeader.action" style="text-align: right">
                <v-btn icon small @click="printDocument(item.rotationType, item._id)">
                    <v-icon small>mdi-printer</v-icon>
                </v-btn>
                <v-btn icon small @click="deleteEntry(item._id)">
                    <v-icon color="#ff334d" small>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon small @click="$router.push({ path: `/warehouse/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getPermission",
            "getDefaultMenager",
            "getRegistryHeader",
        ]),
    },
    methods: {
        ...mapActions(["openSecondModal"]),
        correctWeight(gross, tare) {
            let w =
                Math.round(
                    (Number(gross) - Number(tare) + Number.EPSILON) * 100
                ) / 100;
            if (!w.toString().includes(".")) w = w.toString() + ".";
            while (w.toString().split(".")[1].length < 2)
                w = w.toString() + "0";
            return w;
        },
        printDocument(type, id) {
            let routeData =
                type == "Dostawa"
                    ? this.$router.resolve({
                          name: `printPZ`,
                          params: { id: id },
                      })
                    : this.$router.resolve({
                          name: `printWZ`,
                          params: { id: id },
                      });

            window.open(routeData.href, "_blank");
        },
        deleteEntry(id) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: id,
                type: "deleteWarehouse",
            });
            // this.axios
            //     .delete(`/warehouse/${id}`)
            //     .then((response) => {})
            //     .catch((err) => {
            //         console.error(err);
            //     });
        },
    },
};
</script>
