<template>
    <div style="display: flex">
        <v-tooltip left v-if="getPermission.level > 1">
            <template v-slot:activator="{ on }">
                <v-btn fab dark v-on="on" small class="buttons--add" @click="createConstruction">
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Dodaj nową budowę</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-speed-dial
                    v-model="filtersIconTooltip"
                    direction="top"
                    transition="slide-y-reverse-transition"
                    class="mr-2"
                >
                    <template v-slot:activator>
                        <v-btn
                            class="buttons--add ml-2"
                            v-model="filtersTooltip"
                            v-on="on"
                            color="buttons--add"
                            :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${window.width < 1200 ? '50px' : '40px'}`"
                            dark
                            fab
                        >
                            <v-icon v-if="filtersTooltip" small>mdi-close</v-icon>
                            <v-icon v-else small>mdi-filter-plus</v-icon>
                        </v-btn>
                    </template>

                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ml-2"
                                fab
                                dark
                                small
                                color="#0db6ff"
                                v-on="on"
                                @click="applyFilter('')"
                            >
                                <span>W</span>
                            </v-btn>
                        </template>
                        <span>Wszystko</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ml-2"
                                fab
                                dark
                                small
                                color="#18edaa"
                                v-on="on"
                                @click="applyFilter('Aktualne')"
                            >
                                <span>A</span>
                            </v-btn>
                        </template>
                        <span>Aktualne</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="ml-2"
                                fab
                                dark
                                small
                                color="#e8392c"
                                v-on="on"
                                @click="applyFilter('Zamknięte')"
                            >
                                <span>Z</span>
                            </v-btn>
                        </template>
                        <span>Zamknięte</span>
                    </v-tooltip>
                </v-speed-dial>
            </template>
            <span>Filtry</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    data: () => ({
        filtersTooltip: false,
        filtersIconTooltip: false,
        window: {
            width: 0,
            height: 0,
        },
    }),
    methods: {
        ...mapActions(["openModal", "fetchItems"]),
        ...mapMutations(["setConstructionsFields", "setDDItems"]),
        createConstruction() {
            this.setDDItems({ registry: "representatives", items: [] });
            this.fetchItems("contacts");
            this.setConstructionsFields({});
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 700,
                type: "create",
                route: "constructions",
                tab: "constructions",
            });
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        applyFilter(type) {
            this.$emit("filter", type);
        },
    },

    computed: {
        ...mapGetters(["getPermission"]),
    },
    created() {
        // === === CHECK WINDOW SIZE === ===
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        // === === CHECK WINDOW SIZE === ===
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>
