import Vue from "vue";
import i18n from "@/i18n.js";
import findIndex from "lodash.findindex";

const state = {
  groups: {
    title: "groups.title",
    header: [{
        text: i18n.t("groups.header.name"),
        align: "left",
        value: "name",
        width: 300,
        class: "central__table__header"
      },
      {
        text: i18n.t("groups.header.description"),
        value: "description",
        width: 700,
        class: "central__table__header "
      },
      {
        text: i18n.t("groups.header.createdBy"),
        value: "createdBy",
        width: 200,
        class: "central__table__header"
      },
      {
        text: i18n.t("groups.header.createdAt"),
        value: "createdAt",
        width: 200,
        class: "central__table__header"
      },
      {
        text: i18n.t("global.actions"),
        align: "right",
        value: "action",
        sortable: false,
        class: "central__table__header"
      }
    ]
  },
  modal: {
    fields: {
      name: null,
      description: null,
      users: [],
      registries_reader: null,
      registries_user: null,
      permission: []
    },
    errors: {
      name: "",
      description: ""
    }
  }
};
const getters = {
  getGroups: state => state.groups,
  getGroupsData: state => state.modal.fields,
  getGroupsErrors: state => state.modal.errors
};

const actions = {
  createGroup({
    state,
    commit,
    dispatch
  }) {
    commit("resetErrors", "groups");
    Vue.axios({
        method: "POST",
        url: "/groups",
        data: {
          ...state.modal.fields
        }
      })
      .then(() => {
        dispatch("fetchData", {
          page: 1,
          pageSize: 11,
          search: "",
          sortBy: [],
          sortDesc: [],
          routeName: "groups"
        });
        commit("closeModal");
        commit("setSnackbars", {
          type: "success",
          text: "Pomyślnie stworzono nowy wpis"
        });
      })
      .catch(error => {
        commit("setErrors", {
          route: "groups",
          err: error.response.data.error
        });
      });
  },

  updateGroup({
    commit
  }) {
    commit("resetErrors", "groups");
    Vue.axios({
        method: "PUT",
        url: `/groups/${state.modal.fields._id}`,
        data: {
          ...state.modal.fields
        }
      })
      .then(response => {
        commit("updateItem", response.data.group);
        commit("closeModal");
        // Vue.swal(
        // 	'Edytowano!',
        // 	'Pomyślnie edytowano dany wpis',
        // 	'success',
        // );
        commit("setSnackbars", {
          type: "success",
          text: "Pomyślnie edytowano dany wpis"
        });
      })
      .catch(error => {
        commit("setErrors", {
          route: "groups",
          err: error.response.data.error
        });
        commit("setSnackbars", {
          type: "error",
          text: "Błąd"
        });
      });
  },

  groupDetails({
    commit
  }, payload) {
    Vue.axios({
      method: "GET",
      url: `/groups/${payload}`
    }).then(response => {
      commit("setDetails", {
        route: "groups",
        items: response.data.group
      });
    });
  }
};
const mutations = {
  removeGroupUser(state, payload) {
    let arr = state.modal.fields.users;
    let index = findIndex(arr, e => e == payload);
    arr.splice(index, 1);
  },
  // setPermission(state, payload) {
  //   state.modal.fields.permission = [payload, ...state.modal.fields.permission];
  // }
};

export default {
  state,
  getters,
  actions,
  mutations
};