<template>
    <div class="AE__wrapper">
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    :error-messages="getMaterialErrors.name"
                    dense
                    v-model="getMaterialFields.name"
                    :label="getMaterialFieldsNames.name"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    dense
                    v-model="getMaterialFields.materialIndex"
                    :label="getMaterialFieldsNames.materialIndex"
                    :error-messages="getMaterialErrors.materialIndex"
                ></v-text-field>
            </v-col>
            <Separator />
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="getMaterialFields.minimalLimit"
                    :label="getMaterialFieldsNames.minimalLimit"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="getMaterialUnitOptions"
                    v-model="getMaterialFields.unit"
                    :label="getMaterialFieldsNames.unit"
                ></v-select>
            </v-col>
            <v-col cols="12" md="12">
                <v-text-field
                    dense
                    v-model="getMaterialFields.description"
                    :label="getMaterialFieldsNames.description"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Separator from "@/components/Global/Separator.vue";
export default {
    components: {
        Separator,
    },
    computed: {
        ...mapGetters([
            "getMaterialFields",
            "getMaterialFieldsNames",
            "getMaterialUnitOptions",
            "getMaterialErrors",
        ]),
    },
};
</script>