<template>
  <div id="printQR" class="d-flex align-center flex-column">
    <VueQRCodeComponent
      class="codeImg"
      :size="110"
      :text="getDevicesDetails.SN"
    />
    <div class="qrText">
      <p
        class="mb-0"
        style="
          font-size: 11px;
          margin-top: 5px;
          font-weight: 700;
          text-align: center;
          width: 100%;
        "
      >
        S/N:
      </p>
      <p class="mb-0" style="font-size: 11px; text-align: center">
        {{ getDevicesDetails.SN }}
      </p>
      <p
        class="ma-0"
        style="
          font-size: 10px;
          text-align: center;
          margin-top: 10px;
          text-align: center;
        "
      >
        www.biuromax-balcer.pl
      </p>
    </div>
  </div>
</template>



<script>
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
import { mapGetters } from "vuex";
export default {
  data: () => ({}),

  computed: {
    ...mapGetters(["getDevicesDetails", "getModal"]),
  },
  components: {
    VueQRCodeComponent,
    // ButtonsAE
  },
};
</script>