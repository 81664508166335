<template>
    <v-container>
        <v-row class="d-flex">
            <v-col cols="12" sm="12" md="6" class="py-1">
                <!-- <v-text-field dense label="Podstawa zatrudnienia:" v-model="getStaffModal.contract"></v-text-field> -->
                <v-select
                    dense
                    label="Podstawa zatrudnienia:"
                    v-model="getStaffModal.contract"
                    :items="getContractDictionary"
                    item-text="contractName"
                    item-value="contractName"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-select
                    dense
                    label="Wymiar etatu:"
                    v-model="getStaffModal.dayJob"
                    :items="getDayJobDictionary"
                    item-text="dayJobName"
                    item-value="dayJobName"
                ></v-select>
            </v-col>
        </v-row>
        <v-row class="d-flex">
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-menu
                    ref="startEmployment"
                    v-model="menu.employmentStart"
                    :close-on-content-click="false"
                    :return-value.sync="getStaffModal.employmentStart"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            label="Początek zatrudnienia:"
                            v-model="getStaffModal.employmentStart"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getStaffModal.employmentStart"
                        @input="
                            $refs.startEmployment.save(
                                getStaffModal.employmentStart
                            ),
                                (menu.employmentStart = false)
                        "
                        no-title
                        scrollable
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-menu
                    ref="endEmployment"
                    v-model="menu.employmentEnd"
                    :close-on-content-click="false"
                    :return-value.sync="getStaffModal.employmentEnd"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            label="Koniec zatrudnienia:"
                            v-model="getStaffModal.employmentEnd"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getStaffModal.employmentEnd"
                        @input="
                            $refs.endEmployment.save(
                                getStaffModal.employmentEnd
                            ),
                                (menu.employmentEnd = false)
                        "
                        no-title
                        scrollable
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="d-flex">
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-menu
                    ref="startExamination"
                    v-model="menu.lastExamination"
                    :close-on-content-click="false"
                    :return-value.sync="getStaffModal.lastExamination"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            label="Ostatnie badanie okresowe:"
                            v-model="getStaffModal.lastExamination"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getStaffModal.lastExamination"
                        @input="
                            $refs.startExamination.save(
                                getStaffModal.lastExamination
                            ),
                                (menu.lastExamination = false)
                        "
                        no-title
                        scrollable
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-menu
                    ref="endExamination"
                    v-model="menu.endExamination"
                    :close-on-content-click="false"
                    :return-value.sync="getStaffModal.endExamination"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            label="Ważność badania okresowego:"
                            v-model="getStaffModal.endExamination"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getStaffModal.endExamination"
                        @input="
                            $refs.endExamination.save(
                                getStaffModal.endExamination
                            ),
                                (menu.endExamination = false)
                        "
                        no-title
                        scrollable
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="d-flex">
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-menu
                    ref="startBhp"
                    v-model="menu.startBhp"
                    :close-on-content-click="false"
                    :return-value.sync="getStaffModal.startBhp"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            label="Data szkolenia BHP:"
                            v-model="getStaffModal.startBhp"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getStaffModal.startBhp"
                        @input="
                            $refs.startBhp.save(getStaffModal.startBhp),
                                (menu.startBhp = false)
                        "
                        no-title
                        scrollable
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-menu
                    ref="endBhp"
                    v-model="menu.endBhp"
                    :close-on-content-click="false"
                    :return-value.sync="getStaffModal.endBhp"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            dense
                            label="Ważność szkolenia BHP:"
                            v-model="getStaffModal.endBhp"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getStaffModal.endBhp"
                        @input="
                            $refs.endBhp.save(getStaffModal.endBhp),
                                (menu.endBhp = false)
                        "
                        no-title
                        scrollable
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="d-flex">
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-text-field
                    dense
                    label="Data urodzenia:"
                    class="no-pointer"
                    readonly
                    :value="getUserDetails.birthday"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-1">
                <v-text-field
                    dense
                    label="Miejsce urodzenia:"
                    v-model="getStaffModal.placeOfBirth"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" class="py-1">
                <!-- <p class="mb-1 gl-title">Wymagania dodatkowe:</p> -->
                <v-textarea
                    dense
                    no-resize
                    label="Wymagania dodatkowe:"
                    v-model="getStaffModal.needments"
                    value="Brak"
                ></v-textarea>

                <!-- <quill-editor v-model="getStaffModal.needments"></quill-editor> -->
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" class="py-1">
                <v-textarea
                    dense
                    no-resize
                    label="Uwagi:"
                    v-model="getStaffModal.comment"
                    value="Brak"
                ></v-textarea>
                <!-- <p class="mb-1 gl-title">Uwagi:</p> -->

                <!-- <quill-editor v-model="getStaffModal.comment"></quill-editor> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        menu: {
            employmentStart: false,
            employmentEnd: false,

            lastExamination: false,
            endExamination: false,

            startBhp: false,
            endBhp: false,
        },
    }),
    computed: {
        ...mapGetters([
            'getUserDetails',
            'getStaffModal',
            'getContractDictionary',
            'getDayJobDictionary',
        ]),
    },
};
</script>
