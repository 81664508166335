<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="pt-0">
        <v-col cols="12" md="6" class="px-0">
          <v-text-field
            dense
            v-model="getModalIso.procesName"
            :label="getIsoFieldsNames.procesName"
            :error-messages="getErrorsIso.procesName"
          ></v-text-field>
        </v-col>
      </v-col>
      <v-col cols="12" md="2" class="pt-0">
        <v-text-field dense v-model="getModalIso.isoIndex" :label="getIsoFieldsNames.isoIndex"></v-text-field>
      </v-col>
      <v-col cols="12" md="2" class="pt-0">
        <v-text-field dense v-model="getModalIso.version" :label="getIsoFieldsNames.version"></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
        <v-text-field dense v-model="getModalIso.name" :label="getIsoFieldsNames.name"></v-text-field>
      </v-col>

      <v-col cols="12" md="2" class="pt-0">
        <v-menu
          v-model="modaldata"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getModalIso.dataArchive"
              dense
              readonly
              clearable
              :label="getIsoFieldsNames.dataArchive"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="getModalIso.dataArchive"
            @input="modaldata = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="12">
        <v-textarea :label="getIsoFieldsNames.comment" v-model="getModalIso.comment"></v-textarea>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-autocomplete
          v-model="getModalIso.responsiblePerson"
          label="Osoby odpowiedzialne"
          chips
          :items="getPossibleMenagersItems"
          multiple
          item-value="_id"
          :filter="filter"
          :loading="getPossibleMenagersItems.loading"
          :hide-no-data="getPossibleMenagersItems.hideNoData"
        >
          <template v-slot:selection="data">
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              close
              @click:close="removePerson(data.item._id)"
            >
              <v-avatar left color="secondary" class="mr-2">
                <span v-if="!data.item.picture" class="white--text caption">
                  {{
                  avatarNick(
                  data.item.name,
                  data.item.lastname
                  )
                  }}
                </span>
                <img
                  :src="
                                        `${getGlobalPaths.profilePic}${data.item.picture}`
                                    "
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
              {{ data.item.lastname }} {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-avatar color="secondary">
                <span v-if="!data.item.picture" class="white--text headline">
                  {{
                  avatarNick(
                  data.item.name,
                  data.item.lastname
                  )
                  }}
                </span>
                <img
                  :src="
                                        `${getGlobalPaths.profilePic}${data.item.picture}`
                                    "
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.lastname }}
                {{ data.item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                data.item.job
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    modaldata: false,
    ACReader: "users",
  }),
  computed: {
    ...mapGetters([
      "getModalIso",
      "getIsoFieldsNames",
      "getPossibleMenagersItems",
      "getErrorsIso",
      "getGlobalPaths",
    ]),
  },
  methods: {
    ...mapMutations(["resetMenager", "removeUserISO"]),
    filter(item, queryText, itemText) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.lastname.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    avatarNick(name, lastname) {
      let nick = name[0] + lastname[0];
      return nick.toUpperCase();
    },
    removePerson(payload) {
      this.removeUserISO(payload);
    },
  },
};
</script>
