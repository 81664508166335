<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === NAZWA === === -->
            <td>{{item.name}}</td>

            <!-- === === INDEX === === -->
            <td>{{item.index}}</td>

            <!-- === === DOMYŚLNY MENAGER === === -->
            <td>{{item.defaultMenager ? `${item.defaultMenager.name} ${item.defaultMenager.lastname}` : 'Brak'}}</td>

            <!-- === === DATA WPROWADZENIA === === -->
            <td>{{$moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}}</td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="details(item._id)">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    methods: {
        ...mapActions(["registryDetails", "fetchItems"]),
        details(id) {
            this.fetchItems("departments");
            this.fetchItems("groups");
            this.fetchItems("users").then(() => {
                this.registryDetails({ id: id }).then(() => {
                    this.$store.dispatch("openModal", {
                        headLine: "global.edit",
                        open: true,
                        type: "edit",
                        width: 800,
                        route: this.$route.name
                    });
                });
            });
        }
    }
};
</script>