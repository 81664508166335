<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="p-relative" md="12">
                <v-data-table
                    :headers="getNoteHeaders"
                    :items="getTable.items"
                    :mobile-breakpoint="1100"
                    sort-by="name"
                    item-key="name"
                    class="central__table"
                    hide-default-footer
                    :options.sync="options"
                    :items-per-page="11"
                >
                    <template
                        v-slot:item.createdBy="{item}"
                    >{{item.createdBy.name}} {{item.createdBy.lastname}}</template>
                    <template v-slot:item.createdAt="{item}">{{correctData(item.createdAt)}}</template>
                    <template v-slot:item.action="{ item }">
                        <v-icon small @click="editNote(item._id)" class="mr-2">mdi-pencil</v-icon>
                        <v-icon
                            v-if="getPermission.level > 1"
                            small
                            @click="openRemoveModal(item._id)"
                        >mdi-delete</v-icon>
                    </template>
                </v-data-table>

                <!-- === === PAGINATION === === -->
                <div class="text-md-center pt-2">
                    <v-pagination
                        circle
                        v-model="table.page"
                        :length="getTable.pages"
                        @input="refreshData"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
        <!-- <Modal  -->
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import Buttons from "@/components/Global/Details/Note/ButtonsAE.vue";
import AEContent from "@/components/Global/Details/Note/AEContent.vue";

export default {
    data: () => ({
        options: {},
        rows_per_page: 11,
        table: {
            page: 1
        }
    }),

    methods: {
        ...mapMutations(["openModalNote", "setSecondModal"]),
        ...mapActions([
            "fetchData",
            "openSecondModal",
            "openModal",
            "noteDetails"
        ]),

        editNote(NID) {
            this.openModal({
                headLine: "global.edit",
                open: true,
                width: 600,
                type: "edit",
                route: "note",
                tab: "note",
                global: "note"
            });
            this.noteDetails({
                id: NID,
                registryName: this.$route.name
            });
        },

        openRemoveModal(ND) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: { ND, page: this.table.page },
                type: "note_tab"
            });
        },

        correctData(date) {
            return this.$moment(date).format("YYYY-MM-DD HH:mm");
        },
        refreshData() {
            const { sortBy, sortDesc } = this.options;
            const { page, search } = this.table;
            this.fetchData({
                page,
                search,
                sortBy,
                sortDesc,
                routeName: "note_tab",
                pageSize: this.rows_per_page,
                registryName: this.$route.name,
                registryID: this.$route.params.id
            });
        }
    },
    components: {
        AEContent
    },
    computed: {
        ...mapGetters(["getTable", "getNoteHeaders", "getPermission"])
    },
    watch: {
        options: {
            handler() {
                this.refreshData();
                return;
            },
            deep: true
        }
    }
};
</script>