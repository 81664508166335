import Vue from 'vue';
import moment from 'moment';
import store from '../index';

const state = {
	session: {
		openSecondModal: false,
		cookie: '',
		timer: '',
		interval: null,
		done: false,
	},
};
const getters = {
	getSession: state => state.session,
};
const actions = {
	startSessionTimer({ commit }, payload) {
		state.session.done = false;
		state.session.openSecondModal = false;
		state.session.cookie = payload.cookie;
		let diff = Math.abs(
			new Date(state.session.cookie.expires) - new Date(),
		);
		state.session.timer = moment.duration(diff, 'milliseconds');
		state.session.timer = moment.duration(
			state.session.timer - 1000,
			'milliseconds',
		);
		state.session.interval = setInterval(function() {
			diff = Math.abs(
				new Date(state.session.cookie.expires) - new Date(),
			);
			state.session.timer = moment.duration(diff, 'milliseconds');
			state.session.timer = moment.duration(
				state.session.timer - 1000,
				'milliseconds',
			);
			if (state.session.timer._milliseconds <= 1000 * 60 * 2) {
				state.session.openSecondModal = true;
			} else {
				state.session.openSecondModal = false;
			}
			if (state.session.timer._milliseconds <= 1000) {
				state.session.done = true;
				store.commit('closeSecondModal');
				store.commit('closeModal');
				clearInterval(state.session.interval);
			}
		}, 1000);
	},
	async stopSessionTimer({ commit }, payload) {
		clearInterval(state.session.interval);
	},
};
const mutations = {
	setSessionTimer(state, payload) {
		state.session.timer = payload.timer;
	},
	setSession(state, payload) {
		state.session.cookie = payload.cookie;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
