<template>
    <v-container>
        <v-row>
            <v-col cols="6" class="d-flex flex-wrap">
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        readonly
                        v-model="getMaterialDetails.name"
                        :label="getMaterialFieldsNames.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        readonly
                        v-model="getMaterialDetails.materialIndex"
                        :label="getMaterialFieldsNames.materialIndex"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        :value="Number(getMaterialDetails.minimalLimit).toFixed(2)"
                        :label="getMaterialFieldsNames.minimalLimit"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        :value="Number(getMaterialDetails.currentVolume).toFixed(2) || '0' "
                        :label="getMaterialFieldsNames.currentVolume"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        v-model="getMaterialDetails.unit"
                        :label="getMaterialFieldsNames.unit"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        readonly
                        v-model="getMaterialDetails.description"
                        :label="getMaterialFieldsNames.description"
                    ></v-text-field>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getMaterialFieldsNames",
            "getMaterialDetails",
            "getMaterialRotation",
        ]),
    },
    methods: {
        ...mapActions(["fetchItems"]),

        correctWeight(gross, tare) {
            return Number(gross) - Number(tare);
        },
    },
};
</script>