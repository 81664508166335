var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.index))]),_c('td',[_vm._v(" "+_vm._s(item.createdAt ? _vm.$moment(item.createdAt).format('YYYY-MM-DD') :'')+" "),(item.rotationType == 'Dostawa')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"#1b803b"}},on),[_vm._v("mdi-arrow-left-bold")])]}}],null,true)},[_c('span',[_vm._v("Dostawa")])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"#b82e04"}},on),[_vm._v("mdi-arrow-right-bold")])]}}],null,true)},[_c('span',[_vm._v("Wydanie")])])],1),_c('td',[_vm._v(" "+_vm._s(item.construction ? item.construction.name : item.contactId ? item.contactId.name : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.carId ? item.carId.model + ' ' + item.carId.plate : item.carPlate)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":item.carId ? '#1b803b' : '#b82e04',"small":""}},on),[_vm._v("mdi-truck")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.carId ? 'Własny' : 'Obcy'))])])],1),_c('td',[_vm._v(_vm._s(_vm.correctWeight(item.grossWeight, item.tareWeight)))]),(_vm.getRegistryHeader.status)?_c('td',[_c('v-chip',{style:(("color: " + (item.status &&
							item.status.possibleStatus &&
							item.status.possibleStatus.color &&
							item.status.possibleStatus.color.font
								? item.status.possibleStatus.color.font
								: '#FFFFFF'))),attrs:{"hidden":!item.status.possibleStatus,"color":item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.color &&
						item.status.possibleStatus.color.background
							? item.status.possibleStatus.color.background
							: '#555555',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak')+" ")])],1):_vm._e(),(_vm.getRegistryHeader.action)?_c('td',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.printDocument(item.rotationType, item._id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteEntry(item._id)}}},[_c('v-icon',{attrs:{"color":"#ff334d","small":""}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ path: ("/warehouse/" + (item._id)) })}}},[_c('v-icon',{attrs:{"color":"#19aa8d","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }