<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="
				item.status &&
				item.status.possibleStatus &&
				item.status.possibleStatus.resolved
					? 'color: #9e9e9e'
					: ''
			"
        >
            <!-- === === NR ZARZĄDZENIA === === -->
            <td>{{ item.indexOrdinance ? item.indexOrdinance : 'Brak' }}</td>

            <!-- === === NAZWA === === -->
            <td>{{ item.title ? item.title : 'Brak' }}</td>
            <!-- === === MENAGER === === -->
            <td style="font-size: 12px">
                {{
                item.menager
                ? `${item.menager.name} ${item.menager.lastname}`
                : 'Brak'
                }}
            </td>

            <!-- === === STATUS === === -->
            <td v-if="getRegistryHeader.status">
                <v-chip
                    :color="
						item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.color &&
						item.status.possibleStatus.color.background
							? item.status.possibleStatus.color.background
							: ''
					"
                    :style="
						`color: ${
							item.status &&
							item.status.possibleStatus &&
							item.status.possibleStatus.color &&
							item.status.possibleStatus.color.font
								? item.status.possibleStatus.color.font
								: ''
						}`
					"
                    dark
                    label
                    small
                >
                    {{
                    item.status &&
                    item.status.possibleStatus &&
                    item.status.possibleStatus.name
                    ? item.status.possibleStatus.name
                    : 'Brak'
                    }}
                </v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/director-orders/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
    props: ["items"],

    computed: {
        ...mapGetters(["getRegistryHeader"]),
    },

    methods: {
        ...mapActions(["showFirstAttachments"]),
    },
};
</script>
