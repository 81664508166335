
<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="p-relative" md="12">
                <v-data-table
                    class="central__table"
                    hide-default-footer
                    :mobile-breakpoint="1100"
                    :headers="getLogs.header"
                    :items="getLogsTable.items"
                    :options.sync="options"
                    :items-per-page="11"
                    fixed-header
                >
                    <template
                        v-slot:item.updatedAt="{ item }"
                    >{{item.createdAt ? correctDate(item.createdAt) : 'Brak' }}</template>
                    <template
                        v-slot:item.worker="{ item }"
                    >{{`${item.worker.name} ${item.worker.lastname}`}}</template>
                </v-data-table>

                <!-- === === PAGINATION === === -->
                <div class="text-md-center pt-2 pagination__mobile">
                    <v-pagination
                        circle
                        v-model="table.page"
                        :total-visible="5"
                        :length="getLogsTable.pages"
                        @input="refreshData"
                    ></v-pagination>
                </div>
            </v-col>

            <!-- === === ACTION BUTTONS IN RIGHT BOTTOM CORNER === === -->
            <div class="central__action"></div>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data: () => ({
        table: {
            search: "",
            page: 1,
            header: [],
            items: [],
        },
        options: {},
    }),
    watch: {
        options: {
            handler() {
                this.refreshData();
                return;
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters(["getLogs", "getLogsTable"]),
    },
    methods: {
        ...mapActions(["fetchLogs"]),
        correctDate(date) {
            return this.$moment(date).format("YYYY-MM-DD HH:mm");
        },
        async refreshData() {
            const { sortBy, sortDesc } = this.options;
            const { page, search } = this.table;
            this.fetchLogs({
                page,
                search,
                sortBy,
                sortDesc,
                itemId: this.$route.params.id,
                route: this.$route.path.split("/")[1],
            });
        },
    },
};
</script>
