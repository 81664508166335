<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col cols="12" md="12" class="details">
        <div class="details__title">
          <!-- === MAIN HEADER ===  -->
          <h1
            @click="goToRegistry()"
            class="font-weight-light pl-2 pt-1 pointer"
          >
            {{ $t(title) }}
          </h1>
          <!-- {{getPermission}} -->

          <div
            class="details__title__breadcrumbs p-relative"
            v-if="
              $route.name != 'templates_details' &&
                $route.name != 'resources_details'
            "
          >
            <!-- <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{index}}</span>
                            </template>
                            <span>Index</span>
                        </v-tooltip>-->

            <div v-if="!getLayoutWSD.wsd">
              <span
                v-if="$route.name == 'leasing_details'"
                style="margin-right: 5px"
                >{{ $t(nrLeasing) }}</span
              >
              <span style="color: #797979; margin-right: 5px" v-else>{{
                getDetailsIndex
              }}</span>
            </div>

            <v-divider style="height: 20px" vertical></v-divider>
            <v-menu :disabled="getPermission.level < 2">
              <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <span
                      style="
                                                color: rgba(
                                                    0,
                                                    0,
                                                    0,
                                                    0.87
                                                ) !important;
                                                margin-left: 4px;
                                            "
                      dark
                      v-on="{ ...tooltip, ...menu }"
                    >
                      {{
                        getDetailsStatus && getDetailsStatus.name
                          ? getDetailsStatus.name
                          : 'Brak'
                      }}
                    </span>
                  </template>
                  <span>Status</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  style="min-height: 36px"
                  v-for="(item, index) in setStatusesPermissions"
                  :key="index"
                  :disabled="item.disabled"
                  @click="
                    setStatus({
                      registryName: $route.name,
                      registryID: $route.params.id,
                      status: item,
                    }).then(() => setDetailsStatus(item))
                  "
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <div
            class="d-flex"
            style="margin-left: auto"
            v-if="
              $route.name != 'templates_details' &&
                $route.name != 'resources_details'
            "
          >
            <v-menu
              nudge-left
              nudge-width="300"
              :close-on-content-click="false"
              @input="menagerStep = 1"
            >
              <template
                v-slot:activator="{ on: menu }"
                style="positon: absolute; right: 0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-avatar
                      size="32"
                      dark
                      v-on="{ ...tooltip, ...menu }"
                      class="pointer"
                      color="teal"
                    >
                      <span
                        class="white--text caption"
                        v-if="!getDetailsMenager.picture"
                      >
                        {{ getDetailsMenager.name[0]
                        }}{{ getDetailsMenager.lastname[0] }}
                      </span>
                      <img
                        v-else
                        style="object-fit: cover"
                        :src="
                          `${getGlobalPaths.profilePic}${getDetailsMenager.picture}`
                        "
                        alt="avatar"
                      />
                    </v-avatar>
                  </template>
                  <span>Menager</span>
                </v-tooltip>
              </template>
              <v-stepper v-model="menagerStep">
                <v-stepper-items>
                  <v-stepper-content step="1" class="pa-0">
                    <v-card
                      class="pa-4 flex-column"
                      style="
                                                color: #757575;
                                                font-size: 14px;
                                                position: relative;
                                            "
                    >
                      <v-tooltip left v-if="getPermission.level > 2">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            style="
                                                            position: absolute;
                                                            top: 0;
                                                            right: 0;
                                                        "
                            text
                            small
                            fab
                            v-on="on"
                            @click="possibleMenagers"
                          >
                            <v-icon color="secondary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Zmiana menagera</span>
                      </v-tooltip>
                      <div class="d-flex">
                        <v-avatar
                          class="pointer"
                          v-on="on"
                          color="teal"
                          size="40"
                        >
                          <span
                            class="white--text body-1"
                            v-if="!getDetailsMenager.picture"
                          >
                            {{ getDetailsMenager.name[0]
                            }}{{ getDetailsMenager.lastname[0] }}
                          </span>
                          <img
                            style="
                                                            object-fit: cover;
                                                        "
                            v-else
                            :src="
                              `${getGlobalPaths.profilePic}${getDetailsMenager.picture}`
                            "
                            alt="avatar"
                          />
                        </v-avatar>
                        <div class="d-flex flex-column ml-3">
                          <span
                            style="
                                                            font-size: 15px;
                                                            color: black;
                                                        "
                          >
                            {{ getDetailsMenager.name }}
                            {{ getDetailsMenager.lastname }}
                          </span>
                          <span>Menager</span>
                        </div>
                      </div>
                      <div class="d-flex flex-column">
                        <v-divider class="my-3"></v-divider>
                        <!-- <p class="ma-0">
                                                    <b>Departament:</b>
                                                    {{menager}}
                                                </p>-->
                        <p class="ma-0">
                          <b>Email:</b>
                          {{
                            getDetailsMenager.mail
                              ? getDetailsMenager.mail
                              : 'Brak'
                          }}
                        </p>
                        <p class="ma-0">
                          <b>Telefon:</b>
                          {{
                            getDetailsMenager.phone
                              ? getDetailsMenager.phone
                              : 'Brak'
                          }}
                        </p>
                      </div>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="pa-0">
                    <v-card>
                      <p class="pa-2 ma-0 font-weight-light headline">
                        Zmiana menagera
                      </p>
                      <v-divider></v-divider>
                      <v-autocomplete
                        label="Wybierz menagera"
                        class="mx-2 my-4"
                        dense
                        :items="getPossibleMenagersItems"
                        item-value="_id"
                        item-text="name"
                        v-model="newMenager"
                      >
                        <template v-slot:selection="data">
                          {{ data.item.name }}
                          {{ data.item.lastname }}
                        </template>
                        <template v-slot:item="data">
                          {{ data.item.name }}
                          {{ data.item.lastname }}
                        </template>
                      </v-autocomplete>
                      <v-btn
                        class="mx-2 mb-2"
                        small
                        color="secondary"
                        @click="
                          updateMenager(
                            $route.name,
                            $route.params.id,
                            newMenager
                          )
                        "
                        >Zmień</v-btn
                      >
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-menu>
          </div>
        </div>
        <v-card class="mt-3" style="height: calc(100vh - 185px)">
          <v-tabs
            show-arrows
            height="60"
            centered
            icons-and-text
            v-model="model"
          >
            <div
              style
              class="details__tabs"
              v-if="$route.name != 'templates_details'"
            >
              <!-- ========================FIRST TABS FOR ALL REGISTRY================================ -->
              <v-tab href="#information" @click="getDetails()">
                Informacje
                <v-icon size="18">mdi-book-information-variant</v-icon>
              </v-tab>

              <!-- ========================WORKERS TABS================================ -->

              <v-tab
                href="#staff"
                v-if="$route.name == 'workers_details'"
                value="staff"
              >
                Kadry
                <v-icon size="18">mdi-badge-account-horizontal</v-icon>
              </v-tab>

              <!-- ========================SERVICE TABS================================ -->
              <v-tab
                href="#implementation_service"
                v-if="$route.name == 'service_details'"
                value="implementation_service"
                @click="
                  getServiceImplementation({
                    id: $route.params.id,
                    type: 'details',
                  }),
                    addIdModel()
                "
              >
                Realizacja usług
                <v-icon size="18">mdi-badge-account-horizontal</v-icon>
              </v-tab>
              <v-tab
                href="#parts_service"
                v-if="$route.name == 'service_details'"
                value="parts_service"
                @click="
                  getServiceParts({
                    id: $route.params.id,
                    type: 'details',
                  })
                "
              >
                Części
                <v-icon size="18">mdi-cogs</v-icon>
              </v-tab>
              <v-tab
                href="#archives_service"
                v-if="$route.name == 'service_details'"
                value="archives_service"
                @click="
                  deviceArchiveTab({
                    registryID: $route.params.id,
                    SN: getServiceDetails.device.SN,
                    isService: true,
                  })
                "
              >
                Archiwum
                <v-icon size="18">mdi-archive</v-icon>
              </v-tab>

              <!-- ========================DEVICED TABS================================ -->
              <v-tab
                href="#implementation"
                v-if="$route.name == 'devices_details'"
              >
                Dane wdrożeniowe
                <v-icon size="18">mdi-badge-account-horizontal</v-icon>
              </v-tab>
              <v-tab
                href="#archives"
                v-if="$route.name == 'devices_details'"
                @click="
                  deviceArchiveTab({
                    deviceID: $route.params.id,
                    isService: false,
                  })
                "
              >
                Archiwum
                <v-icon size="18">mdi-archive</v-icon>
              </v-tab>

              <!-- ========================CONTACTS TABS================================ -->

              <v-tab href="#branches" v-if="$route.name == 'contacts_details'">
                Oddziały
                <v-icon size="18">mdi-archive</v-icon>
              </v-tab>
              <v-tab
                href="#representative"
                v-if="$route.name == 'contacts_details'"
              >
                Reprezentanci
                <v-icon size="18">mdi-archive</v-icon>
              </v-tab>

              <!-- ========================CARS TABS================================ -->

              <v-tab href="#deliveries" v-if="$route.name == 'cars_details'">
                Kursy
                <v-icon size="18">mdi-truck-delivery</v-icon>
              </v-tab>

              <!-- ========================CONSTRUCTIONS TABS================================ -->

              <v-tab
                href="#constructionsDeliveries"
                v-if="$route.name == 'constructions_details'"
                @click="
                  constructionsDeliveries({
                    id: $route.params.id,
                  })
                "
              >
                Dostawy
                <v-icon size="18">mdi-truck-delivery</v-icon>
              </v-tab>

              <!-- ========================MATERIALS TABS================================ -->

              <v-tab
                href="#materialRotation"
                v-if="$route.name == 'materials_details'"
                @click="materialRotation({ id: $route.params.id })"
              >
                Rotacja materiału
                <v-icon size="18">mdi-swap-horizontal-bold</v-icon>
              </v-tab>

              <!-- ========================CONTACTS TABS================================ -->
              <v-tab
                href="#offersImplementation"
                v-if="$route.name == 'offers_details'"
                @click="
                  getOffersImplementation({
                    id: $route.params.id,
                    type: 'details',
                  })
                "
              >
                Wdrożenie
                <v-icon size="18">mdi-cube-send</v-icon>
              </v-tab>
              <!-- ========================GLOBAL TABS================================ -->
              <v-tab
                href="#attachments"
                @click="
                  fetchFolderAttachments({
                    registryName: $route.name,
                    registryID: $route.params.id,
                  }),
                    clear()
                "
              >
                Załączniki
                <v-badge
                  class="badge"
                  v-if="getAttachmentsCount != 0"
                  color="#99B2D4"
                  :content="getAttachmentsCount"
                  overlap
                  offset-x="-16"
                  offset-y="-6"
                ></v-badge>
                <v-icon size="18">mdi-paperclip</v-icon>
              </v-tab>

              <v-tab href="#notification" @click="fetchTabItems('alert_tab')">
                Alerty
                <v-badge
                  class="badge"
                  v-if="getNotificationsCount != 0"
                  color="#99B2D4"
                  :content="getNotificationsCount"
                  overlap
                  offset-x="-16"
                  offset-y="-6"
                ></v-badge>
                <v-icon size="18">mdi-bell-ring</v-icon>
              </v-tab>

              <v-tab href="#tasks" @click="fetchTabItems('tasks_tab')">
                Zadania
                <v-badge
                  class="badge"
                  v-if="getTasksCount != 0"
                  color="#99B2D4"
                  :content="getTasksCount"
                  overlap
                  offset-x="-16"
                  offset-y="-6"
                ></v-badge>
                <v-icon size="18">mdi-format-list-checks</v-icon>
              </v-tab>

              <v-tab href="#note" @click="fetchTabItems('note_tab')">
                Notatki
                <v-badge
                  class="badge"
                  v-if="getNotesCount != 0"
                  color="#99B2D4"
                  :content="getNotesCount"
                  overlap
                  offset-x="-16"
                  offset-y="-6"
                ></v-badge>
                <v-icon size="18">mdi-calendar-multiple</v-icon>
              </v-tab>
              <v-tab
                @click="
                  fetchLogs({
                    itemId: $route.params.id,
                    route: $route.path.split('/')[1],
                  })
                "
                href="#logs"
              >
                Logi
                <v-icon size="18">mdi-folder-cog</v-icon>
              </v-tab>
            </div>
            <v-tabs-items v-model="model">
              <!-- ========================INFORMATION CONTENT FOR ALL REGISTRY================================ -->

              <v-tab-item value="information">
                <v-card flat>
                  <!-- ========================WORKERS================================ -->
                  <WorkersDetails v-if="$route.name == 'workers_details'" />
                  <!-- ========================DEVICED================================ -->
                  <DevicesDetails v-if="$route.name == 'devices_details'" />
                  <!-- ========================PARTS================================ -->
                  <PartsDetails v-if="$route.name == 'parts_details'" />
                  <!-- ========================CARS================================ -->
                  <CarsDetails v-if="$route.name == 'cars_details'" />
                  <!-- ========================SERVICED================================ -->
                  <ServiceDetails v-if="$route.name == 'service_details'" />
                  <!-- ========================CONTACTS================================ -->
                  <ContactsDetails v-if="$route.name == 'contacts_details'" />
                  <!-- ========================MODELS================================ -->
                  <ModelsDetails v-if="$route.name == 'models_details'" />
                  <!-- ========================PRINTERS================================ -->
                  <PrintersDetails v-if="$route.name == 'printers_details'" />
                  <!-- ========================ISO================================ -->
                  <IsoDetails v-if="$route.name == 'iso_details'" />
                  <!-- ========================AGREEMENT================================ -->
                  <AgreementDetails v-if="$route.name == 'agreement_details'" />
                  <!-- ========================Leasing================================ -->
                  <LeasingDetails v-if="$route.name == 'leasing_details'" />
                  <!-- ========================Tenders================================ -->
                  <TendersDetails v-if="$route.name == 'tenders_details'" />
                  <!-- ========================DIRECTOR ORDERS================================ -->
                  <DirectorOrdersDetails
                    v-if="$route.name == 'director-orders_details'"
                  />
                  <!-- ========================CURRENT INFORMATIONS================================ -->
                  <CurrentInformationsDetails
                    v-if="$route.name == 'current-informations_details'"
                  />
                  <!-- ========================DUTY INFORMATIONS================================ -->
                  <DutyInformationsDetails
                    v-if="$route.name == 'duty_details'"
                  />
                  <!-- ========================CORONAVIRUS INFORMATIONS================================ -->
                  <CoronavirusInformationsDetails
                    v-if="$route.name == 'coronavirus_details'"
                  />
                  <!-- ========================DUTY INFORMATIONS================================ -->
                  <AccreditationInformationsDetails
                    v-if="$route.name == 'accreditation_details'"
                  />
                  <!-- ========================DUTY INFORMATIONS================================ -->
                  <PharmacotherapyInformationsDetails
                    v-if="$route.name == 'pharmacotherapy_details'"
                  />
                  <!-- ========================BENEFITS VALUE================================ -->
                  <BenefitsValueDetails
                    v-if="$route.name == 'benefits-value_details'"
                  />

                  <!-- ========================DIET NUTRITION================================ -->
                  <DietNutritionDetails
                    v-if="$route.name == 'diet-nutrition_details'"
                  />
                  <!-- ========================WIFI=============================== -->
                  <WifiDetails v-if="$route.name == 'wifi_details'" />

                  <!-- ========================NON MEDICAL PRINTS INFORMATIONS================================ -->
                  <NonMedicalPrintsInformationsDetails
                    v-if="$route.name == 'non-medical-print_details'"
                  />
                  <!-- ========================INTERNAL INFORMATIONS================================ -->
                  <InternalPrintInformationsDetails
                    v-if="$route.name == 'internal-prints_details'"
                  />
                  <!-- ========================EXTERNAL INFORMATIONS================================ -->
                  <ExternalPrintInformationsDetails
                    v-if="$route.name == 'external-prints_details'"
                  />
                  <!-- ========================PATIENTS CONSENT INFORMATIONS================================ -->
                  <PatientsConsentDetails
                    v-if="$route.name == 'patients-consent_details'"
                  />
                  <!-- ========================TRAINING INFORMATIONS================================ -->
                  <TrainingInformationsDetails
                    v-if="$route.name == 'training_details'"
                  />
                  <!-- ========================EXTRAORDINARY OCCURENCE INFORMATIONS================================ -->
                  <ExtraordinaryOccuranceInformationsDetails
                    v-if="$route.name == 'extraordinary-occurence_details'"
                  />
                  <!-- ========================SECURITY POLICY INFORMATIONS================================ -->
                  <SecurityPolicyDetails
                    v-if="$route.name == 'information-security-policy_details'"
                  />
                  <!-- ========================BHP INFORMATIONS================================ -->
                  <BhpDetails v-if="$route.name == 'bhp_details'" />
                  <!-- ========================TEAM ANALYSIS RESULT INFORMATIONS================================ -->
                  <TeamAnalysisResultDetails
                    v-if="$route.name == 'team-analysis-result_details'"
                  />
                  <!-- ========================INTERNAL CONTACTS INFORMATIONS================================ -->
                  <InternalContactsDetails
                    v-if="$route.name == 'internal-contacts_details'"
                  />

                  <!-- ========================OFFERS================================ -->
                  <OffersDetails v-if="$route.name == 'offers_details'" />

                  <ConstructionDetails
                    v-if="$route.name == 'constructions_details'"
                  />
                  <MaterialDetails v-if="$route.name == 'materials_details'" />
                  <WarehouseDetails v-if="$route.name == 'warehouse_details'" />
                  <ResourcesDetails v-if="$route.name == 'resources_details'" />
                </v-card>
              </v-tab-item>

              <!-- ========================WORKERS================================ -->

              <!-- =============++++++++++STAFF+++++++++++++++============= -->
              <v-tab-item value="staff">
                <Staff />
              </v-tab-item>

              <!-- ========================SERVICE================================ -->

              <v-tab-item value="implementation_service">
                <ImplementationServiceDetails />
              </v-tab-item>
              <v-tab-item value="parts_service">
                <PartsServiceDetails />
              </v-tab-item>
              <v-tab-item value="archives_service">
                <ArchivesServiceDetails />
              </v-tab-item>

              <!-- ========================DEVICED================================ -->

              <v-tab-item value="implementation">
                <Implementation />
              </v-tab-item>
              <v-tab-item value="archives">
                <Archives />
              </v-tab-item>
              <!-- ========================CONTACTS================================ -->

              <v-tab-item value="branches">
                <BranchesDetails />

                <!-- <h1>Test</h1> -->
              </v-tab-item>
              <v-tab-item value="representative">
                <RepresentativesDetails />
              </v-tab-item>

              <!-- ========================CARS================================ -->

              <v-tab-item value="deliveries">
                <CarsDeliveries />
              </v-tab-item>

              <!-- ========================CONSTUCTIONS================================ -->

              <v-tab-item value="constructionsDeliveries">
                <ConstructionsDeliveries />
              </v-tab-item>

              <!-- ========================MATERIALS================================ -->

              <v-tab-item value="materialRotation">
                <MaterialRotation />
              </v-tab-item>

              <!-- ========================Offers================================ -->
              <v-tab-item value="offersImplementation">
                <offersImplementationDetails />
              </v-tab-item>

              <!-- ========================GLOBAL CONTENT FOR ALL REGISTRY================================ -->
              <!-- NOTIFICATIONS -->
              <v-tab-item value="notification">
                <Notifications />
              </v-tab-item>
              <!-- TASKS -->
              <v-tab-item value="tasks">
                <Tasks />
              </v-tab-item>
              <!-- NOTE -->
              <v-tab-item value="note">
                <Note />
              </v-tab-item>
              <!-- ATTACHMENTS -->
              <v-tab-item value="attachments">
                <!-- <AttachmentsIsoDetails v-if="$route.name == 'iso_details'" /> -->
                <AttachmentsDetails />
              </v-tab-item>
              <!-- LOGS -->
              <v-tab-item value="logs">
                <Logs />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
          <div class="central__actions d-flex">
            <!-- === GLOBAL INFORMATION BUTTONS === -->
            <ButtonsDevices
              v-if="$route.name == 'devices_details' && model == 'information'"
            />
            <ButtonsUsers
              v-if="$route.name == 'workers_details' && model == 'information'"
            />
            <ButtonsParts
              v-if="$route.name == 'parts_details' && model == 'information'"
            />
            <ButtonsCars
              v-if="$route.name == 'cars_details' && model == 'information'"
            />
            <ButtonsService
              v-if="$route.name == 'service_details' && model == 'information'"
            />
            <ButtonsContacts
              v-if="$route.name == 'contacts_details' && model == 'information'"
            />
            <ButtonsModel
              v-if="$route.name == 'models_details' && model == 'information'"
            />
            <IsoButtons
              v-if="$route.name == 'iso_details' && model == 'information'"
            />
            <DevicesEditButtons
              v-if="$route.name == 'devices_details' && model == 'information'"
            />
            <CurrentInformationsButtons
              v-if="
                $route.name == 'current-informations_details' &&
                  model == 'information'
              "
            />
            <DutyInformationsButtons
              v-if="$route.name == 'duty_details' && model == 'information'"
            />
            <CoronavirusInformationsButtons
              v-if="
                $route.name == 'coronavirus_details' && model == 'information'
              "
            />
            <AccreditationInformationsButtons
              v-if="
                $route.name == 'accreditation_details' && model == 'information'
              "
            />
            <NonMedicalPrintsInformationsButtons
              v-if="
                $route.name == 'non-medical-print_details' &&
                  model == 'information'
              "
            />
            <PharmacotherapyInformationsButtons
              v-if="
                $route.name == 'pharmacotherapy_details' &&
                  model == 'information'
              "
            />
            <ExtraordinaryOccuranceButtons
              v-if="
                $route.name == 'extraordinary-occurence_details' &&
                  model == 'information'
              "
            />
            <BenefitsValueButtons
              v-if="
                $route.name == 'benefits-value_details' &&
                  model == 'information'
              "
            />
            <DietNutritionButtons
              v-if="
                $route.name == 'diet-nutrition_details' &&
                  model == 'information'
              "
            />
            <WifiButtons
              v-if="$route.name == 'wifi_details' && model == 'information'"
            />
            <ButtonsSecurityPolicy
              v-if="
                $route.name == 'information-security-policy_details' &&
                  model == 'information'
              "
            />
            <ButtonsBhp
              v-if="$route.name == 'bhp_details' && model == 'information'"
            />
            <ButtonsTeamAnalysisResultDetails
              v-if="
                $route.name == 'team-analysis-result_details' &&
                  model == 'information'
              "
            />

            <ButtonsImplementation v-if="model == 'implementation'" />
            <ButtonsStaff v-if="model == 'staff'" />

            <!-- === SERVICE BUTTONS === -->
            <ButtonsImplementationService
              v-if="
                $route.name == 'service_details' &&
                  model == 'implementation_service'
              "
            />
            <ButtonsPartsService
              v-if="
                $route.name == 'service_details' && model == 'parts_service'
              "
            />
            <!-- === DIRECTOR ORDERS BUTTONS === -->
            <DirectorOrdersButtons
              v-if="
                $route.name == 'director-orders_details' &&
                  model == 'information'
              "
            />
            <!-- === INTERNAL BUTTONS === -->
            <InternalPrintButtons
              v-if="
                $route.name == 'internal-prints_details' &&
                  model == 'information'
              "
            />
            <!-- === PATIENTS CONSENT BUTTONS === -->
            <PatientsConsentButtons
              v-if="
                $route.name == 'patients-consent_details' &&
                  model == 'information'
              "
            />
            <!-- === EXTERNAL BUTTONS === -->
            <ExternalPrintButtons
              v-if="
                $route.name == 'external-prints_details' &&
                  model == 'information'
              "
            />
            <!-- === TRAINING BUTTONS === -->
            <TrainingButtons
              v-if="$route.name == 'training_details' && model == 'information'"
            />

            <!-- === CONTACTS === -->
            <ButtonsRepresentatives v-if="model == 'representative'" />
            <ButtonsBranches v-if="model == 'branches'" />

            <!-- === AGREEMENT === -->
            <AgreementButtons
              v-if="
                $route.name == 'agreement_details' && model == 'information'
              "
            />

            <!-- === OFFERS === -->
            <OffersButtons
              v-if="$route.name == 'offers_details' && model == 'information'"
            />
            <offersImplementationButtons
              v-if="
                $route.name == 'offers_details' &&
                  model == 'offersImplementation'
              "
            />

            <!-- === INTERNAL CONTACTS === -->
            <ButtonsInternalContactsDetails
              v-if="
                $route.name == 'internal-contacts_details' &&
                  model == 'information'
              "
            />
            <!-- === LEASING === -->
            <LeasingButtons
              v-if="$route.name == 'leasing_details' && model == 'information'"
            />
            <!-- === TENDERS === -->
            <TendersButtons
              v-if="$route.name == 'tenders_details' && model == 'information'"
            />
            <!-- === CONSTRUCTIONS === -->
            <ConstructionDetailsButtons
              v-if="
                $route.name == 'constructions_details' && model == 'information'
              "
            />
            <!-- === MATERIALS === -->
            <MaterialButtons
              v-if="
                $route.name == 'materials_details' && model == 'information'
              "
            />
            <!-- === WAREHOUSE === -->
            <WarehouseButtons
              v-if="
                $route.name == 'warehouse_details' && model == 'information'
              "
            />

            <ResourcesButtons
              v-if="
                $route.name == 'resources_details' && model == 'information'
              "
            />

            <!-- === GLOBAL BUTTONS === -->
            <ButtonsNotification v-if="model == 'notification'" />
            <ButtonsTasks v-if="model == 'tasks'" />
            <ButtonsNote v-if="model == 'note'" />
            <ButtonsAttachment v-if="model == 'attachments'" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import findIndex from 'lodash.findindex';

import ButtonsUsers from '@/components/Registry/Users/Information/ButtonsAE.vue';
import ButtonsStaff from '@/components/Registry/Users/Staff/ButtonsAE.vue';
import ButtonsParts from '@/components/Registry/Parts/Information/ButtonsAE.vue';
import ButtonsCars from '@/components/Registry/Cars/Information/ButtonsAE.vue';
import ButtonsService from '@/components/Registry/Service/Information/ButtonsAE.vue';
import ButtonsContacts from '@/components/Contact/Information/ButtonsAE.vue';

// ================GLOBAL BUTTONS===================

import ButtonsAttachment from '@/components/Global/Details/Attachments/Buttons.vue';
import ButtonsNotification from '@/components/Global/Details/Notifications/ButtonsAE.vue';
import ButtonsTasks from '@/components/Global/Details/Tasks/ButtonsAE.vue';
import ButtonsNote from '@/components/Global/Details/Note/ButtonsAE.vue';

// ================GLOBAL CONTENT===================
import Reminders from '@/components/Global/Details/Reminders/Reminders.vue';
import Notifications from '@/components/Global/Details/Notifications/NotificationsDetails.vue';
import Note from '@/components/Global/Details/Note/Note.vue';
import AttachmentsDetails from '@/components/Global/Details/Attachments/AttachmentDetails.vue';
// import AttachmentsIsoDetails from "@/components/Global/Details/AttachmentsIso/AttachmentsDetails.vue";
import Tasks from '@/components/Global/Details/Tasks/Tasks.vue';
import Logs from '@/components/Global/Details/Logs/LogsDetails.vue';

// ============DEDICATED OVERLAP====================

//++++++++++++++++DEVICES++++++++++++++++++++++++

import DevicesDetails from '@/components/Registry/Devices/Information/DevicesDetails.vue';
import Archives from '@/components/Registry/Devices/Archives/ArchivesDetails.vue';
import Implementation from '@/components/Registry/Devices/Implementation/ImplementationDetails.vue';
import ButtonsDevices from '@/components/Registry/Devices/Buttons.vue';
import DevicesEditButtons from '@/components/Registry/Devices/Information/ButtonsAE.vue';
import ButtonsImplementation from '@/components/Registry/Devices/Implementation/ButtonsAE.vue';

//++++++++++++++++SERVICE++++++++++++++++++++++++
import ServiceDetails from '@/components/Registry/Service/Information/ServiceDetails.vue';
import ImplementationServiceDetails from '@/components/Registry/Service/ImplementationService/ImplementationDetails.vue';
import PartsServiceDetails from '@/components/Registry/Service/PartsService/PartsDetails.vue';
import ArchivesServiceDetails from '@/components/Registry/Service/Archives/ArchivesDetails.vue';

import ButtonsImplementationService from '@/components/Registry/Service/ImplementationService/ButtonsAE.vue';
import ButtonsPartsService from '@/components/Registry/Service/PartsService/ButtonsAE.vue';

//+++++++++++++++++CONTACTS+++++++++++++++++++++++++
import ContactsDetails from '@/components/Contact/Information/ContactsDetails.vue';
import BranchesDetails from '@/components/Contact/Branches/BranchesDetails.vue';
import RepresentativesDetails from '@/components/Contact/Representatives/RepresentativesDetails.vue';
import ButtonsRepresentatives from '@/components/Contact/Representatives/ButtonsAE.vue';
import ButtonsBranches from '@/components/Contact/Branches/ButtonsAE.vue';

//+++++++++++++++++PARTS+++++++++++++++++++++++++
import PartsDetails from '@/components/Registry/Parts/Information/PartsDetails.vue';

//+++++++++++++++++ISO+++++++++++++++++++++++++
import IsoDetails from '@/components/Registry/ISO/Information/IsoDetails.vue';
import IsoButtons from '@/components/Registry/ISO/Information/Buttons.vue';

//+++++++++++++++++AGEEMENT+++++++++++++++++++++++++
import AgreementDetails from '@/components/Registry/Agreement/Information/AgreementDetails.vue';
import AgreementButtons from '@/components/Registry/Agreement/Information/Buttons.vue';

//+++++++++++++++++OFFERS+++++++++++++++++++++++++
import OffersDetails from '@/components/Registry/Offers/Information/OffersDetails.vue';
import OffersButtons from '@/components/Registry/Offers/Information/Buttons.vue';

//+++++++++++++++++LEASING+++++++++++++++++++++++++
import LeasingDetails from '@/components/Registry/Leasing/Information/LeasingDetails.vue';
import LeasingButtons from '@/components/Registry/Leasing/Information/Buttons.vue';

//+++++++++++++++++TENDERS+++++++++++++++++++++++++
import TendersDetails from '@/components/Registry/Tenders/Information/TendersDetails.vue';
import TendersButtons from '@/components/Registry/Tenders/Information/Buttons.vue';

//+++++++++++++++++DIRECTOR ORDERS+++++++++++++++++++++++++
import DirectorOrdersDetails from '@/components/Registry/DirectorOrders/Information/DirectorOrdersDetails.vue';
import DirectorOrdersButtons from '@/components/Registry/DirectorOrders/Information/Buttons.vue';

//+++++++++++++++++CURRENT INFORMATION+++++++++++++++++++++++++
import CurrentInformationsDetails from '@/components/Registry/CurrentInformations/Information/CurrentInformationDetails.vue';
import CurrentInformationsButtons from '@/components/Registry/CurrentInformations/Information/Buttons.vue';

//+++++++++++++++++PATIENTS CONSENT INFORMATION+++++++++++++++++++++++++
import PatientsConsentDetails from '@/components/Registry/PatientsConsent/Information/PatientsConsentDetails.vue';
import PatientsConsentButtons from '@/components/Registry/PatientsConsent/Information/Buttons.vue';

//+++++++++++++++++DUTY INFORMATION+++++++++++++++++++++++++
import DutyInformationsDetails from '@/components/Registry/Duty/Information/DutyDetails.vue';
import DutyInformationsButtons from '@/components/Registry/Duty/Information/Buttons.vue';

//+++++++++++++++++CORONAVIRUS INFORMATION+++++++++++++++++++++++++
import CoronavirusInformationsDetails from '@/components/Registry/Coronavirus/Information/CoronavirusDetails.vue';
import CoronavirusInformationsButtons from '@/components/Registry/Coronavirus/Information/Buttons.vue';

//+++++++++++++++++ACCREDTATION INFORMATION+++++++++++++++++++++++++
import AccreditationInformationsDetails from '@/components/Registry/Accreditation/Information/AccreditationDetails.vue';
import AccreditationInformationsButtons from '@/components/Registry/Accreditation/Information/Buttons.vue';

//+++++++++++++++++NON MEDICAL PRINTS INFORMATION+++++++++++++++++++++++++
import NonMedicalPrintsInformationsDetails from '@/components/Registry/NonMedicalPrints/Information/NonMedicalPrintsDetails.vue';
import NonMedicalPrintsInformationsButtons from '@/components/Registry/NonMedicalPrints/Information/Buttons.vue';
//+++++++++++++++++EXTERNAL PRINTS INFORMATION+++++++++++++++++++++++++
import ExternalPrintInformationsDetails from '@/components/Registry/ExternalPrints/Information/ExternalPrintDetails.vue';
import ExternalPrintButtons from '@/components/Registry/ExternalPrints/Information/Buttons.vue';
//+++++++++++++++++INTERNAL PRINTS INFORMATION+++++++++++++++++++++++++
import InternalPrintInformationsDetails from '@/components/Registry/InternalPrints/Information/InternalPrintDetails.vue';
import InternalPrintButtons from '@/components/Registry/InternalPrints/Information/Buttons.vue';

//+++++++++++++++++INTERNAL PRINTS INFORMATION+++++++++++++++++++++++++
import ExtraordinaryOccuranceInformationsDetails from '@/components/Registry/ExtraordinaryOccurance/Information/ExtraordinaryOccurenceDeatils.vue';
import ExtraordinaryOccuranceButtons from '@/components/Registry/ExtraordinaryOccurance/Information/Buttons.vue';

//+++++++++++++++++INTERNAL PRINTS INFORMATION+++++++++++++++++++++++++
import TrainingInformationsDetails from '@/components/Registry/Training/Information/TrainingDetails.vue';
import TrainingButtons from '@/components/Registry/Training/Information/Buttons.vue';

//+++++++++++++++++PHARMACOTHERAPY INFORMATION+++++++++++++++++++++++++
import PharmacotherapyInformationsDetails from '@/components/Registry/Pharmacotherapy/Information/PharmacotherapyDetails.vue';
import PharmacotherapyInformationsButtons from '@/components/Registry/Pharmacotherapy/Information/Buttons.vue';

//+++++++++++++++++BENEFITS VALUE+++++++++++++++++++++++++
import BenefitsValueDetails from '../components/Registry/BenefitsValue/Information/BenefitsValueDetails.vue';
import BenefitsValueButtons from '../components/Registry/BenefitsValue/Information/Buttons.vue';

//+++++++++++++++++DIET NUTRITION+++++++++++++++++++++++++
import DietNutritionDetails from '../components/Registry/DietNutrition/Information/DietNutritionDetails.vue';
import DietNutritionButtons from '../components/Registry/DietNutrition/Information/Buttons.vue';

//+++++++++++++++++WIFI+++++++++++++++++++++++++
import WifiDetails from '../components/Registry/Wifi/Information/WifiDetails.vue';
import WifiButtons from '../components/Registry/Wifi/Information/Buttons.vue';

//+++++++++++++++++WORKERS+++++++++++++++++++++++++
import Staff from '@/components/Registry/Users/Staff/StaffDetails.vue';
import WorkersDetails from '@/components/Registry/Users/Information/UserDetails.vue';
import CarsDetails from '@/components/Registry/Cars/Information/CarsDetails.vue';

//+++++++++++++++++MODELS+++++++++++++++++++++++++
import ModelsDetails from '@/components/Registry/Models/Information/ModelsDetails.vue';
import ButtonsModel from '@/components/Registry/Models/Information/Buttons.vue';

//+++++++++++++++++SECURITY POLICY+++++++++++++++++++++++++
import SecurityPolicyDetails from '@/components/Registry/SecurityPolicy/Information/SecurityPolicyDetails.vue';
import ButtonsSecurityPolicy from '@/components/Registry/SecurityPolicy/Information/Buttons.vue';

//+++++++++++++++++BHP+++++++++++++++++++++++++
import BhpDetails from '@/components/Registry/Bhp/Information/BhpDetails.vue';
import ButtonsBhp from '@/components/Registry/Bhp/Information/Buttons.vue';

//+++++++++++++++++TEAM ANALISIS RESULT+++++++++++++++++++++++++
import TeamAnalysisResultDetails from '@/components/Registry/TeamAnalysisResult/Information/TeamAnalysisResultDetails.vue';
import ButtonsTeamAnalysisResultDetails from '@/components/Registry/TeamAnalysisResult/Information/Buttons.vue';

//+++++++++++++++++PRINTERS+++++++++++++++++++++++++
import PrintersDetails from '@/components/Registry/Printers/Information/PrintersDetails.vue';
//import ButtonsModel from "@/components/Registry/Models/Information/Buttons.vue";

//+++++++++++++++++INTERNAL CONTACTS+++++++++++++++++++++++++
import InternalContactsDetails from '@/components/Registry/InternalContacts/Information/InternalContactsDetails.vue';
import ButtonsInternalContactsDetails from '@/components/Registry/InternalContacts/Information/Buttons.vue';

// ++++++++++++++++ CONSTRUCTIONS ++++++++++++++++++++++++
import ConstructionDetails from '../components/Registry/Constructions/Information/ConstructionDetails';
import ConstructionDetailsButtons from '../components/Registry/Constructions/Information/Buttons.vue';

// ++++++++++++++++ MATERIALS ++++++++++++++++++++++++
import MaterialDetails from '../components/Registry/Materials/Information/MaterialDetails';
import MaterialButtons from '../components/Registry/Materials/Information/Buttons.vue';

// ++++++++++++++++ WAREHOUSE ++++++++++++++++++++++++
import WarehouseButtons from '../components/Registry/Warehouse/Information/Buttons.vue';
import WarehouseDetails from '../components/Registry/Warehouse/Information/WarehouseDetails.vue';

import CarsDeliveries from '../components/Registry/Cars/CarsDeliveries.vue';
import ConstructionsDeliveries from '../components/Registry/Constructions/ConstructionsDeliveries.vue';
import MaterialRotation from '../components/Registry/Materials/MatarialRotation.vue';

import offersImplementationDetails from '../components/Registry/Offers/Implementation/Details';
import offersImplementationButtons from '../components/Registry/Offers/Implementation/Buttons';

import ResourcesDetails from '../components/Admin/Resources/Information';
import ResourcesButtons from '../components/Admin/Resources/InfoButton';

import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      tasksRoute: '/service',
      title: '',
      nrLeasing: '',
      model: 'information',
      magnify: true,
      on: '',
      checkbox1: true,
      statusItems: [],
      status: '',
      index: '',
      menager: {
        name: '',
        lastname: '',
        mail: '',
        phone: '',
        description: '',
      },
      menagerStep: 1,
      newMenager: '',
    };
  },
  methods: {
    ...mapActions([
      'fetchData',
      'fetchFolderAttachments',
      'setStatus',
      'fetchLogs',
      'getPossibleMenagers',
      'getServiceParts',
      'getServiceImplementation',
      'deviceArchiveTab',
      'serviceOrderDeatils',
      'partsDeatils',
      'modelDetails',
      'devicesDeatils',
      'userDeatils',
      'carsDeatils',
      'directorOrdersDeatils',
      'coronavirusDeatils',
      'isoDetails',
      'contactDetails',
      'agreementDeatils',
      'leasingDeatils',
      'tendersDeatils',
      'offersDeatils',
      'notificationsCount',
      'tasksCount',
      'notesCount',
      'attachmentsCount',
      'constructionsDeliveries',
      'materialRotation',
      'findModel',
      'getOffersImplementation',
    ]),
    ...mapMutations([
      'setDetailsStatus',
      'setclearFolderItems',
      'setclearFolderName',
    ]),
    addIdModel() {
      this.findModel({
        model: this.getServiceDetails.device.modelDevices.model,
        router: this.$router,
      });
    },
    fullPermissions() {
      if (this.getIsLogistic && this.getIsTechnics) {
        return false;
      } else if (this.getIsTechnics) {
        return true;
      }
    },
    technicPermissions() {
      if (this.getIsLogistic && this.getIsTechnics) {
        return false;
      } else if (this.getIsLogistic) {
        return true;
      }
    },
    fetchTabItems(option) {
      this.fetchData({
        page: 1,
        pageSize: 11,
        search: '',
        sortBy: [],
        sortDesc: [],
        routeName: option,
        registryName: this.$route.name,
        registryID: this.$route.params.id,
      });
      this.notificationsCount({
        registryName: this.$route.name,
        registryID: this.$route.params.id,
      });
      this.tasksCount({
        registryName: this.$route.name,
        registryID: this.$route.params.id,
      });
      this.notesCount({
        registryName: this.$route.name,
        registryID: this.$route.params.id,
      });
      this.attachmentsCount({
        registryName: this.$route.name,
        registryID: this.$route.params.id,
      });
    },
    clear() {
      this.setclearFolderName();
    },
    checkClient() {
      if (
        this.getLayoutWSD.wsd == false ||
        this.$route.name == 'workers_details'
      ) {
        this.model = 'information';
      } else if (this.getLayoutWSD.wsd == true) {
        this.model = 'attachments';
      }

      if (this.$route.name == 'templates_details') this.model = 'attachments';
    },
    getDetails() {
      if (this.$route.name == 'service_details') {
        this.serviceOrderDeatils({
          id: this.$route.params.id,
          type: 'details',
        });
      }
    },
    goToRegistry() {
      this.$router.push({ path: `/${this.$route.name.split('_')[0]}` });
    },
    toogle: function() {
      this.magnify = !this.magnify;
    },
    setTitleAndStatus() {
      switch (this.$route.name) {
        case 'tasks_details':
          this.title = this.getTasks.title;
          break;
        case 'registries_details':
          this.title = this.getRegistry.title;
          break;
        case 'groups_details':
          this.title = this.getGroups.title;
          break;
        case 'departments_details':
          this.title = this.getDepartment.title;
          break;
        case 'devices_details':
          this.title = this.getDevices.title;
          this.statusItems = [
            { title: 'Aktualny' },
            { title: 'Nieaktualny' },
            { title: 'Zamknięty' },
          ];
          break;
        case 'workers_details':
          this.title = this.getUsers.title;
          this.statusItems = [
            { title: 'Zatrudniony' },
            { title: 'Niezatrudniony' },
          ];
          break;
        case 'parts_details':
          this.title = this.getParts.title;
          this.statusItems = [
            { title: 'Aktualny' },
            { title: 'Nieaktualny' },
            { title: 'Zamknięty' },
          ];
          break;

        case 'non-medical-print_details':
          this.title = this.getNonMedicalPrint.title;
          break;
        case 'service_details':
          this.title = this.getService.title;
          this.statusItems = [
            {
              title: 'Otwarty',
              disabled: false,
            },
            {
              title: 'Rozpoczęty',
              disabled: this.technicPermissions(),
            },
            { title: 'Claim', disabled: false },
            {
              title: 'Przypisany',
              disabled: this.fullPermissions(),
            },
            {
              title: 'Zakończony',
              disabled: this.technicPermissions(),
            },
            {
              title: 'Zamknięty',
              disabled: this.technicPermissions(),
            },
            {
              title: 'Zatwierdzony',
              disabled: this.fullPermissions(),
            },
            {
              title: 'Usunięty',
              disabled: this.fullPermissions(),
            },
          ];
          break;
        case 'cars_details':
          this.title = this.getCars.title;
          this.statusItems = [{ title: 'Aktualny' }, { title: 'Zamknięty' }];
          break;
        case 'contacts_details':
          this.title = this.getContacts.title;
          this.statusItems = [{ title: 'Aktualny' }, { title: 'Nieaktualny' }];
          break;
        case 'models_details':
          this.title = this.getModels.title;
          this.statusItems = [
            { title: 'Aktualny' },
            { title: 'Nieaktualny' },
            { title: 'Zamknięty' },
          ];
          break;
        case 'iso_details':
          this.title = this.getIso.title;
          break;
        case 'current-informations_details':
          this.title =
            this.getLayoutWSD.wsd === true
              ? this.getCurrentInformation.title
              : 'Informacje';
          break;
        case 'duty_details':
          this.title = this.getDuty.title;
          break;
        case 'accreditation_details':
          this.title = this.getAccreditation.title;
          break;
        case 'pharmacotherapy_details':
          this.title = this.getPharmacotherapy.title;
          break;
        case 'printer_details':
          this.title = this.getPrinters.title;
          break;
        case 'director-orders_details':
          this.title = this.getDirectorOrders.title;
          this.index = this.getDirectorOrders.index;
          this.status = this.getDirectorOrders.status;
          break;
        case 'coronavirus_details':
          this.title = this.getCoronavirus.title;
          this.index = this.getCoronavirus.index;
          this.status = this.getCoronavirus.status;
          break;
        case 'agreement_details':
          this.title = this.getAgreement.title;
          break;
        case 'leasing_details':
          this.title = this.getLeasing.title;
          this.nrLeasing = this.getLeasingDetails.nrLeasing;
          break;
        case 'tenders_details':
          this.title = this.getTenders.title;
          this.nrTenders = this.getTendersDetails.nrTenders;
          break;
        case 'internal-prints_details':
          this.title = this.getInternalPrints.title;
          break;
        case 'external-prints_details':
          this.title = this.getExternalPrints.title;
          break;
        case 'patients-consent_details':
          this.title = this.getPatientsConsent.title;
          break;
        case 'benefits-value_details':
          this.title = this.getBenefitsValue.title;
          break;
        case 'extraordinary-occurence_details':
          this.title = this.getExtraordinaryOccurence.title;
          break;
        case 'training_details':
          this.title = this.getTraining.title;
          break;
        case 'diet-nutrition_details':
          this.title = this.getDietNutrition.title;
          break;
        case 'wifi_details':
          this.title = this.getWifi.title;
          break;
        case 'information-security-policy_details':
          this.title = this.getSecurityPolicy.title;
          break;
        case 'bhp_details':
          this.title = this.getBhp.title;
          break;
        case 'team-analysis-result_details':
          this.title = this.getTeamAnalysisResult.title;
          break;
        case 'internal-contacts_details':
          this.title = this.getInternalContacts.title;
          break;
        case 'templates_details':
          this.title = 'Szablony: ' + this.getRegistryData.name;
          break;
        case 'offers_details':
          this.title = this.getOffers.title;
          break;
        case 'constructions_details':
          this.title = this.getConstructions.title;
          break;
        case 'materials_details':
          this.title = this.getMaterial.title;
          break;
        case 'warehouse_details':
          this.title = this.getWarehouse.title;
          break;
        case 'resources_details':
          this.title = this.getResources.title;
          break;
      }
      if (!this.status) {
        this.status = 'Brak statusu';
      }
    },

    possibleMenagers() {
      this.menagerStep = 2;
      this.getPossibleMenagers(this.$router.currentRoute.path.split('/')[1]);
    },

    updateMenager(registryName, registryID, newMenager) {
      Vue.axios({
        method: 'PUT',
        url: `/global/menager`,
        data: {
          registryName,
          registryID,
          newMenager,
        },
      })
        .then((response) => {
          this.$store.commit('setDetailsMenager', response.data.item.menager);
          this.menagerStep = 1;
          this.newMenager = '';
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    // === === GET DATA BEFORE COMPONENT LOAD === === //
    this.setTitleAndStatus();
    this.checkClient();
  },
  components: {
    WorkersDetails,
    AttachmentsDetails,
    // AttachmentsIsoDetails,
    Staff,
    Logs,
    Notifications,
    DevicesDetails,
    Implementation,
    Archives,
    Reminders,
    Note,
    ButtonsUsers,
    ButtonsNotification,
    ButtonsStaff,
    ButtonsTasks,
    ButtonsNote,
    ButtonsAttachment,
    Tasks,
    ButtonsDevices,
    ButtonsImplementation,
    PartsDetails,
    ButtonsParts,
    ButtonsCars,
    CarsDetails,
    ButtonsService,
    ServiceDetails,
    ButtonsImplementationService,
    ButtonsPartsService,
    ImplementationServiceDetails,
    PartsServiceDetails,
    ArchivesServiceDetails,
    ContactsDetails,
    ButtonsContacts,
    BranchesDetails,
    RepresentativesDetails,
    ButtonsRepresentatives,
    ButtonsBranches,
    ModelsDetails,
    DirectorOrdersDetails,
    DirectorOrdersButtons,
    PrintersDetails,
    IsoDetails,
    IsoButtons,
    DevicesEditButtons,
    ButtonsModel,
    CurrentInformationsDetails,
    DutyInformationsButtons,
    CurrentInformationsButtons,
    DutyInformationsButtons,
    DutyInformationsDetails,
    AccreditationInformationsDetails,
    AccreditationInformationsButtons,
    AgreementDetails,
    AgreementButtons,
    LeasingDetails,
    LeasingButtons,
    TendersDetails,
    TendersButtons,
    PharmacotherapyInformationsDetails,
    PharmacotherapyInformationsButtons,
    BenefitsValueDetails,
    BenefitsValueButtons,
    ExternalPrintButtons,
    ExternalPrintInformationsDetails,
    InternalPrintInformationsDetails,
    InternalPrintButtons,
    PatientsConsentDetails,
    PatientsConsentButtons,
    NonMedicalPrintsInformationsDetails,
    NonMedicalPrintsInformationsButtons,
    ExtraordinaryOccuranceInformationsDetails,
    TrainingInformationsDetails,
    ExtraordinaryOccuranceButtons,
    TrainingButtons,
    DietNutritionDetails,
    DietNutritionButtons,
    WifiDetails,
    WifiButtons,
    SecurityPolicyDetails,
    BhpDetails,
    TeamAnalysisResultDetails,
    ButtonsSecurityPolicy,
    ButtonsBhp,
    ButtonsTeamAnalysisResultDetails,
    InternalContactsDetails,
    ButtonsInternalContactsDetails,
    OffersDetails,
    OffersButtons,
    ConstructionDetails,
    ConstructionDetailsButtons,
    MaterialDetails,
    MaterialButtons,
    WarehouseButtons,
    WarehouseDetails,
    CarsDeliveries,
    ConstructionsDeliveries,
    MaterialRotation,
    offersImplementationDetails,
    offersImplementationButtons,
    ResourcesDetails,
    ResourcesButtons,
    CoronavirusInformationsDetails,
    CoronavirusInformationsButtons,
  },
  computed: {
    // === === GETTING DATA FROM VUEX === === //
    ...mapGetters([
      'getUsers',
      'getTasks',
      'getRegistry',
      'getGroups',
      'getDepartment',
      'getTable',
      'getDevices',
      'getParts',
      'getService',
      'getCars',
      'getContacts',
      'getIso',
      'getIsLogistic',
      'getIsTechnics',
      'getDevicesDetails',
      'getModelDetails',
      'getModalParts',
      'getModels',
      'getUserDetails',
      'getCarsFields',
      'getCarsDetails',
      'getPrinters',
      'getGlobalPaths',
      'getIDDepartments',
      'getPossibleMenagersItems',
      'getPartsDetails',
      'getServiceDetails',
      'getPermission',
      'getCurrentInformation',
      'getContactsDetailsFields',
      'getDetailsStatuses',
      'getDetailsStatus',
      'getDetailsIndex',
      'getDetailsMenager',
      'getLayoutWSD',
      'getDuty',
      'getDirectorOrders',
      'getCoronavirus',
      'getAccreditation',
      'getPharmacotherapy',
      'getAgreement',
      'getLeasing',
      'getLeasingDetails',
      'getTenders',
      'getTendersDetails',
      'getBenefitsValue',
      'getPatientsConsent',
      'getInternalPrints',
      'getExternalPrints',
      'getNonMedicalPrint',
      'getExtraordinaryOccurence',
      'getTraining',
      'getDietNutrition',
      'getWifi',
      'getSecurityPolicy',
      'getBhp',
      'getTeamAnalysisResult',
      'getInternalContacts',
      'getRegistryData',
      'getOffers',
      'getUser',
      'getNotesCount',
      'getTasksCount',
      'getNotificationsCount',
      'getAttachmentsCount',
      'getConstructions',
      'getMaterial',
      'getWarehouse',
      'getResources',
    ]),
    setStatusesPermissions() {
      if (!this.getUser.isAdmin && !this.getUser.isSuperAdmin) {
        if (this.$route.name == 'service_details') {
          this.getDetailsStatuses.forEach((status) => (status.disabled = true));

          if (this.getIsTechnics) {
            let index = this.getDetailsStatuses.findIndex(
              (status) => status.name == 'Zamknięty'
            );
            this.getDetailsStatuses[index].disabled = false;
          }

          if (this.getIsLogistic) {
            let index = this.getDetailsStatuses.findIndex(
              (status) => status.name == 'Zatwierdzony'
            );
            if (index > -1) this.getDetailsStatuses[index].disabled = false;

            let index2 = this.getDetailsStatuses.findIndex(
              (status) => status.name == 'Anulowane'
            );
            if (index2 > -1) this.getDetailsStatuses[index2].disabled = false;
          }
        }
      }

      return this.getDetailsStatuses;
    },
  },

  beforeMount() {
    switch (this.$route.name) {
      case 'service_details':
        this.serviceOrderDeatils({
          id: this.$route.params.id,
          type: 'service',
        });
        break;
      case 'parts_details':
        this.partsDeatils({
          id: this.$route.params.id,
          type: 'details',
        });
        break;
      case 'models_details':
        this.modelDetails({
          id: this.$route.params.id,
          type: 'details',
        });
        break;
      case 'devices_details':
        this.devicesDeatils({
          id: this.$route.params.id,
          type: 'devices',
        });
        break;
      case 'workers_details':
        this.userDeatils({
          id: this.$route.params.id,
        });
        break;
      case 'cars_details':
        this.carsDeatils({
          id: this.$route.params.id,
          type: 'cars',
        });
        break;
      case 'contacts_details':
        this.contactDetails({
          id: this.$route.params.id,
        });
        break;
      case 'director-orders_details':
        this.directorOrdersDeatils({
          id: this.$route.params.id,
        });
        break;
      case 'coronavirus_details':
        this.coronavirusDeatils({
          id: this.$route.params.id,
        });
        break;
      // case "iso_details":
      //   this.isoDetails({
      //     id: this.$route.params.id
      //   });
      //   break;
    }
    this.fetchFolderAttachments({
      registryName: this.$route.name,
      registryID: this.$route.params.id,
    });
    this.notificationsCount({
      registryName: this.$route.name,
      registryID: this.$route.params.id,
    });
    this.tasksCount({
      registryName: this.$route.name,
      registryID: this.$route.params.id,
    });
    this.notesCount({
      registryName: this.$route.name,
      registryID: this.$route.params.id,
    });
    this.attachmentsCount({
      registryName: this.$route.name,
      registryID: this.$route.params.id,
    });
  },
};
</script>
