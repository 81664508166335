

<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="7"
        class="pa-4 pb-0 d-flex flex-wrap"
        style="position: relative"
      >
        <v-col cols="12" md="12" class="py-0 d-flex">
          <Separator
            :text="'Klient / Urządzenie'"
            :clientID="` ${
              getServiceDetails.device.client &&
              getServiceDetails.device.client.contactId
                ? '-' + getServiceDetails.device.client.contactId
                : ''
            }`"
          />
        </v-col>
        <v-col
          cols="12"
          class="py-1"
          md="6"
          v-if="getServiceDetails.device.modelDevices.deviceType"
        >
          <v-text-field
            class="pa-1 no-pointer"
            :value="
              getServiceDetails.device.client
                ? getServiceDetails.device.client.name
                : 'Brak'
            "
            readonly
            dense
            label="Klient"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          class="py-1"
          md="6"
          v-if="getServiceDetails.device.modelDevices.deviceType"
        >
          <v-text-field
            class="pa-1 no-pointer"
            readonly
            dense
            :value="`${this.getBranchAddress.city} ${getBranchAddress.postCode} ${getBranchAddress.address}`"
            label="Lokalizacja"
          >
            <template slot="append">
              <v-dialog v-model="dialog" width="800">
                <template v-slot:activator="{}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="dialog = true" v-on="on">
                        <v-icon color="red" small>mdi-map-marker</v-icon>
                      </v-btn>
                    </template>
                    <span>Lokalizacja</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <GmapMap
                    ref="mymap"
                    :center="getBranchAddress.location"
                    :zoom="16"
                    map-type-id="terrain"
                    style="width: 800px; height: 500px"
                  >
                    <GmapMarker
                      ref="myMarker"
                      :position="getBranchAddress.location"
                    />
                  </GmapMap>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false"
                      >Zamknij</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          class="py-1"
          md="6"
          v-if="getServiceDetails.device.modelDevices.deviceType"
        >
          <v-text-field
            class="pa-1 no-pointer"
            readonly
            dense
            :value="
              getServiceDetails.service.descriptionIntervention
                .representative &&
              getServiceDetails.service.descriptionIntervention.representative
                .name
                ? `${getServiceDetails.service.descriptionIntervention.representative.name} ${getServiceDetails.service.descriptionIntervention.representative.lastname} * ${getServiceDetails.service.descriptionIntervention.representative.phoneNumber} * ${getServiceDetails.service.descriptionIntervention.representative.email}`
                : 'Brak'
            "
            label="Osoba zlecająca (imię, nazwisko, kontakt)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-1" md="6">
          <v-text-field
            class="pa-1 no-pointer"
            readonly
            v-if="getServiceDetails.device.modelDevices.deviceType"
            dense
            :value="
              getServiceDetails.device.floor
                ? `${getServiceDetails.device.floor}`
                : 'Brak'
            "
            label="Lokalizacja - szczegóły"
          ></v-text-field>
        </v-col>
        <!-- //TUTAJ SĄ DANE TYMCZASOWE -->
        <v-col
          cols="12"
          class="py-1"
          md="12"
          v-if="!getServiceDetails.device.modelDevices.deviceType"
        >
          <v-textarea
            readonly
            v-model="getServiceDetails.service.temporaryInformation.devices"
            class="no-pointer"
            label="Tymczasowe dane urządzenia "
            auto-grow
            rows="2"
          ></v-textarea>
          <v-textarea
            readonly
            class="no-pointer"
            label="Tymczasowe dane klient"
            auto-grow
            v-model="getServiceDetails.service.temporaryInformation.client"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <Separator :text="'Opis interwencji'" />
        </v-col>
        <v-col cols="12" class="py-1" md="12">
          <v-textarea
            class="pa-1 no-pointer"
            readonly
            rows="1"
            auto-grow
            dense
            :value="
              getServiceDetails.service.descriptionIntervention
                .descriptionClient
            "
            no-resize
            label="Opis usterki wg. Klienta"
          ></v-textarea>
        </v-col>

        <v-col cols="12" class="py-1" md="12">
          <v-textarea
            class="pa-1 no-pointer"
            readonly
            rows="1"
            auto-grow
            dense
            :value="
              getServiceDetails.service.descriptionIntervention
                .descriptionLogistic
            "
            no-resize
            label="Opis logistyka:"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-1" md="12">
          <v-textarea
            class="pa-1 no-pointer"
            readonly
            rows="1"
            auto-grow
            dense
            :value="
              getLastServiceDetails && getLastServiceDetails.implementation
                ? getLastServiceDetails.implementation.description
                    .nextIntervention
                : 'Brak'
            "
            no-resize
            label="Uwagi z ostatniej interwencji"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="py-0">
          <Separator :text="'Realizacja interwencji'" />
        </v-col>
        <v-col cols="12" class="py-1" md="3">
          <v-text-field
            dense
            class="pa-1 no-pointer"
            :value="`${
              getServiceDetails.service.descriptionIntervention.technic
                ? getServiceDetails.service.descriptionIntervention.technic.name
                : 'Brak'
            } ${
              getServiceDetails.service.descriptionIntervention.technic
                ? getServiceDetails.service.descriptionIntervention.technic
                    .lastname
                : ''
            }`"
            readonly
            label="Preferowany technik"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-1" md="3">
          <v-text-field
            dense
            hide-details
            :value="`${
              getServiceDetails.service.descriptionIntervention.data
                ? getServiceDetails.service.descriptionIntervention.data
                : 'Brak'
            } * ${
              getServiceDetails.service.descriptionIntervention.time
                ? getServiceDetails.service.descriptionIntervention.time
                : ''
            }`"
            class="pa-1 no-pointer"
            readonly
            label="Oczekiwana data usługi"
          ></v-text-field>
        </v-col>
        <v-col md="6"></v-col>
        <v-col cols="12" class="py-1" md="3">
          <!-- {{getServiceDetails}} -->
          <v-text-field
            dense
            class="pa-1 no-pointer"
            :value="`${
              getServiceDetails.service.descriptionIntervention
                .technicPerforming
                ? getServiceDetails.service.descriptionIntervention
                    .technicPerforming.name
                : 'Brak'
            } ${
              getServiceDetails.service.descriptionIntervention
                .technicPerforming
                ? getServiceDetails.service.descriptionIntervention
                    .technicPerforming.lastname
                : ''
            }`"
            readonly
            label="Technik realizujący usługę"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="py-1" md="3">
          <v-text-field
            label="Zaplanowana data usługi"
            dense
            class="pa-1 no-pointer"
            readonly
            hide-details
            :value="
              showcorrectFinishDate(
                getServiceDetails.service.descriptionIntervention.finishDate,
                getServiceDetails.service.descriptionIntervention.finishTime
              )
            "
          ></v-text-field>
        </v-col>

        <!-- {{getServiceDetails.implementation}} -->
        <v-col cols="12" class="py-1" md="3">
          <v-text-field
            dense
            hide-details
            :value="`${changeDateStart()}`"
            class="pa-1 no-pointer"
            readonly
            label="Data rozpoczęcia usługi"
          >
            <template slot="prepend">
              <v-icon color="#19aa8d" class="mt-1" size="20"
                >mdi-play-circle</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="py-1" md="3">
          <v-text-field
            dense
            :value="`${changeDateEnd()}`"
            class="pa-1 no-pointer"
            hide-details
            readonly
            label="Data zakończenia usługi"
          >
            <template slot="prepend">
              <v-icon color="red" class="mt-1" size="20"
                >mdi-stop-circle</v-icon
              >
            </template>
          </v-text-field>
        </v-col>

        <v-divider
          style="position: absolute; top: 10px; right: 0px"
          vertical
        ></v-divider>
      </v-col>
      <v-col cols="12" md="12" lg="5" class="d-flex flex-wrap pa-4">
        <v-col cols="12" md="12" class="py-0">
          <Separator
            :text="`Technik realizujący:`"
            :subtext="`${
              getServiceDetails.service.descriptionIntervention
                .technicPerforming
                ? getServiceDetails.service.descriptionIntervention
                    .technicPerforming.name
                : 'Brak'
            } ${
              getServiceDetails.service.descriptionIntervention
                .technicPerforming
                ? getServiceDetails.service.descriptionIntervention
                    .technicPerforming.lastname
                : ''
            }`"
          />
        </v-col>

        <v-col cols="12" lg="12" md="12" sm="12" class="pa-0 d-flex flex-wrap">
          <v-col cols="12" md="4" sm="6" class="py-0">
            <span class="grey--text">Usługi do wykonania:</span>
            <v-col
              cols="12"
              md="12"
              sm="6"
              class="d-flex align-center py-1 px-0"
            >
              <v-checkbox
                hide-details
                readonly
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.survey"
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox"
                >Przegląd okresowy</span
              >
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="6"
              class="d-flex align-center py-1 px-0"
            >
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.repair"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Naprawa</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="6"
              class="d-flex align-center py-1 px-0"
            >
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.installation"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Wdrożenie</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="6"
              class="d-flex align-center py-1 px-0"
            >
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.diagnostics"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Diagnostyka</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="6"
              class="d-flex align-center py-1 px-0"
            >
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.itService"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Usługa IT</span>
            </v-col>
          </v-col>
          <v-col cols="12" md="4" sm="6" class="py-0" style="margin-top: 24px">
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="
                  getServiceDetails.service.realisation.firstInstallation
                "
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox"
                >Pierwsza instalacja</span
              >
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.repairWorkshop"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox"
                >Naprawa warsztatowa</span
              >
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.remoteService"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox"
                >Usługa zdalna</span
              >
            </v-col>
          </v-col>
          <v-col cols="12" md="4" sm="6" class="py-0" style="margin-top: 24px">
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.continuation"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Kontynuacja</span>
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.complaint"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Reklamacja</span>
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                readonly
                color="red"
                v-model="getServiceDetails.service.realisation.urgent"
                error
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="red--text ml-1 service__checkbox">Pilne</span>
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
              <v-checkbox
                hide-details
                readonly
                color="#964B84"
                v-model="getServiceDetails.service.realisation.warranty"
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox">Gwarancja</span>
            </v-col>
            <v-col cols="12" md="12" class="d-flex align-center mt-2 px-0">
              <v-checkbox
                hide-details
                color="#19aa8d"
                v-model="getServiceDetails.service.realisation.costInformation"
                readonly
                :ripple="ripp"
                class="no-pointer ma-0 pa-0"
              ></v-checkbox>
              <span class="grey--text ml-1 service__checkbox"
                >Informacje o kosztach IT</span
              >
            </v-col>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" class="py-0">
          <Separator :text="'Więcej o urządzeniu'" />
          <div class="d-flex service__information">
            <span class="service__details__left">Producent i model</span>
            <span class="service__details__right black--text"
              >{{ getServiceDetails.device.modelDevices.producer }}
              {{ getServiceDetails.device.modelDevices.model }}</span
            >
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left pb-5">Numer seryjny</span>
            <span
              class="service__details__right pb-5 black--text pointer"
              @click="
                $router.push({
                  path: `/devices/${getServiceDetails.device._id}`,
                })
              "
            >
              {{ getServiceDetails.device.SN }}
              <v-icon style="margin-top: -3px" size="11">mdi-cog</v-icon>
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Rodzaj urządzenia</span>
            <span class="service__details__right">{{
              getServiceDetails.device.modelDevices.deviceType
            }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Priorytet (czas reakcji)</span>
            <span class="service__details__right">{{
              getServiceDetails.device.priority
            }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Pakiet usług</span>

            <span class="service__details__right">{{
              getServiceDetails.device.modelDevices.price.priceName
            }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Ceny usług</span>
            <span
              v-if="getServiceDetails.device.dedicatedPrice.checkbox == false"
              class="service__details__right"
              >{{ changePrice }}</span
            >
            <span v-else class="service__details__right">{{
              changePriceDedicated
            }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left pb-5">Podstawa serwisu</span>

            <span class="service__details__right pb-5">{{
              getServiceDetails.device.serviceBase
                ? getServiceDetails.device.serviceBase
                : (getServiceDetails.device.serviceBase = "Brak")
            }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Pełna lokalizacja</span>
            <span class="service__details__right"
              >{{ this.getBranchAddress.city }}
              {{ this.getBranchAddress.postCode }}
              {{ this.getBranchAddress.address }}</span
            >
          </div>

          <div class="d-flex service__information">
            <span class="service__details__left pb-5">Zlecający</span>
            <span class="service__details__right pb-5">{{ checkName }}</span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left"
              >Data ostatniej interwencji</span
            >

            <span class="service__details__right">
              <!-- {{ getLastServiceDetails.implementation.service }} -->
              <span
                v-if="
                  getLastServiceDetails && getLastServiceDetails.implementation
                "
              >
                {{
                  getLastServiceDetails.implementation.service.dates.start
                    ? $moment(
                        getLastServiceDetails.implementation.service.dates.start
                      ).format("YYYY-MM-DD")
                    : ""
                }}
              </span>
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Technik</span>
            <span class="service__details__right">
              <span
                v-if="
                  getLastServiceDetails && getLastServiceDetails.implementation
                "
                >{{ getLastServiceDetails.implementation.technic.name }}
                {{
                  getLastServiceDetails.implementation.technic.lastname
                }}</span
              >
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left"
              >Licznik mono ostatniej interwencji</span
            >
            <span class="service__details__right">
              <span
                v-if="
                  getLastServiceDetails && getLastServiceDetails.implementation
                "
                >{{ getLastServiceDetails.implementation.meter.mono.end }}</span
              >
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left"
              >Licznik kolor ostatniej interwencji</span
            >
            <span class="service__details__right">
              <span
                v-if="
                  getLastServiceDetails && getLastServiceDetails.implementation
                "
                >{{
                  getLastServiceDetails.implementation.meter.color.end
                }}</span
              >
            </span>
          </div>
          <div class="d-flex service__information">
            <span class="service__details__left">Wyjściowa interwencja</span>
            <span class="service__details__right">{{
              getServiceDetails.service.descriptionIntervention.intervention &&
              getServiceDetails.service.descriptionIntervention.intervention
                .index
                ? getServiceDetails.service.descriptionIntervention.intervention
                    .index
                : "Brak"
            }}</span>
          </div>

          <div
            v-if="getServiceDetails.implementation"
            class="d-flex service__information"
          >
            <span class="service__details__left">Nr claima</span>
            <span class="service__details__right">
              <b class="green--text">{{
                getServiceDetails.implementation &&
                getServiceDetails.implementation.requirements &&
                getServiceDetails.implementation.requirements.claim.number
                  ? getServiceDetails.implementation.requirements.claim.number
                  : "Brak"
              }}</b>
            </span>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    ripp: false,
    dialog: false,
    coords: { lat: 53.1220864, lng: 18.0296382 },
  }),
  components: { Separator },
  computed: {
    ...mapGetters([
      "getServiceDetails",
      "getServiceData",
      "getBranchAddress",
      "getLastServiceDetails",
    ]),
    ...mapMutations(["moment"]),

    changePrice() {
      let survey = "";
      this.getServiceDetails.device.modelDevices.price.survey.price
        ? (survey =
            this.getServiceDetails.device.modelDevices.price.survey.price +
            " PLN")
        : (survey = "Brak");
      let repair = "";
      this.getServiceDetails.device.modelDevices.price.repair.price
        ? (repair =
            this.getServiceDetails.device.modelDevices.price.repair.price +
            " PLN")
        : (repair = "Brak");
      let installation = "";
      this.getServiceDetails.device.modelDevices.price.installation.price
        ? (installation =
            this.getServiceDetails.device.modelDevices.price.installation
              .price + " PLN")
        : (installation = "Brak");
      let diagnostics = "";
      this.getServiceDetails.device.modelDevices.price.diagnostics.price
        ? (diagnostics =
            this.getServiceDetails.device.modelDevices.price.diagnostics.price +
            " PLN")
        : (diagnostics = "Brak");
      let itService = "";
      this.getServiceDetails.device.modelDevices.price.itService.price
        ? (itService =
            this.getServiceDetails.device.modelDevices.price.diagnostics.price +
            " PLN")
        : (itService = "Brak");
      return `${survey} / ${repair} / ${installation} / ${diagnostics} / ${itService}`;
    },

    changePriceDedicated() {
      let survey = "";
      this.getServiceDetails.device.dedicatedPrice.survey
        ? (survey = this.getServiceDetails.device.dedicatedPrice.survey)
        : (survey = "Brak");
      let repair = "";
      this.getServiceDetails.device.dedicatedPrice.repair
        ? (repair = this.getServiceDetails.device.dedicatedPrice.repair)
        : (repair = "Brak");
      let installation = "";
      this.getServiceDetails.device.dedicatedPrice.installation
        ? (installation = this.getServiceDetails.device.dedicatedPrice
            .installation)
        : (installation = "Brak");
      let diagnostics = "";
      this.getServiceDetails.device.dedicatedPrice.diagnostics
        ? (diagnostics = this.getServiceDetails.device.dedicatedPrice
            .diagnostics)
        : (diagnostics = "Brak");
      let itService = "";
      this.getServiceDetails.device.dedicatedPrice.itService
        ? (itService = this.getServiceDetails.device.dedicatedPrice.itService)
        : (itService = "Brak");
      return `${survey} / ${repair} / ${installation} / ${diagnostics} / ${itService}`;
    },

    checkName() {
      let name = "";

      this.getServiceDetails.service.descriptionIntervention.representative &&
      this.getServiceDetails.service.descriptionIntervention.representative.name
        ? (name =
            this.getServiceDetails.service.descriptionIntervention
              .representative.name +
            " " +
            this.getServiceDetails.service.descriptionIntervention
              .representative.lastname +
            " * " +
            this.getServiceDetails.service.descriptionIntervention
              .representative.phoneNumber +
            " * " +
            this.getServiceDetails.service.descriptionIntervention
              .representative.email)
        : (name = "Brak");
      return name;
    },
  },
  methods: {
    navigateToService(id) {
      this.$router.push(`/service/${id}`);
    },
    showcorrectFinishDate(date, time) {
      let cDate = date ? this.$moment(date).format("YYYY-MM-DD") : "Brak";
      let cTime = time ? time : "";
      return `${cDate} ${cTime}`;
    },

    changeDateStart() {
      if (this.getServiceDetails.implementation) {
        if (
          this.getServiceDetails.implementation.service.dates.start === null
        ) {
          return "Brak";
        } else {
          return (
            this.$moment(
              this.getServiceDetails.implementation.service.dates.start
            ).format("YYYY-MM-DD") +
            " " +
            (this.getServiceDetails.implementation.service.times &&
            this.getServiceDetails.implementation.service.times.start
              ? this.getServiceDetails.implementation.service.times.start
              : "")
          );
        }
      } else {
        return "Brak";
      }
    },

    changeDateEnd() {
      if (this.getServiceDetails.implementation) {
        if (this.getServiceDetails.implementation.service.dates.end === null) {
          return "Brak";
        } else {
          return (
            this.$moment(
              this.getServiceDetails.implementation.service.dates.end
            ).format("YYYY-MM-DD") +
            " " +
            (this.getServiceDetails.implementation.service.times &&
            this.getServiceDetails.implementation.service.times.end
              ? this.getServiceDetails.implementation.service.times.end
              : "")
          );
        }
      } else {
        return "Brak";
      }
    },
  },
};
</script>