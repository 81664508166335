<template>
  <form enctype="multipart/form-data">
    <v-container>
      <v-row>
        <!-- {{getAttachmentsFields}} -->
        <v-col cols="12">
          <v-text-field
            label="Wpisz nazwę nowego foldera"
            v-model="getAttachmentsFields.folderName"
            :error-messages="getAttachmentsError.folderName"
            v-if="getModal.type == 'create' && getModal.addFolder == 'false'"
          ></v-text-field
          >Dodaj do folderu:
          <b>{{ getFolderNameValue }}</b>
          <v-file-input
            color="#445D75"
            counter
            multiple
            placeholder="Dodaj załącznik z komputera"
            prepend-icon="mdi-paperclip"
            :show-size="1000"
            v-model="getAttachmentsFields.files"
            v-if="getModal.type == 'create'"
            :error-messages="getAttachmentsError.files"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, , application/pdf, image/*"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="#445D75" dark label small>{{
                text
              }}</v-chip>

              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ getAttachmentsFields.files.length - 2 }}
              </span>
            </template>
          </v-file-input>

          <div class="mt-5">
            <v-text-field
              label="Dodaj link strony www"
              v-model="getAttachmentsFields.link"
              v-if="getModal.type == 'create'"
              :error-messages="getAttachmentsError.link"
            ></v-text-field>
            <v-text-field
              label="Edytuj link strony www"
              v-if="getModal.type == 'edit' && getAttachmentsFields.link"
              v-model="getAttachmentsFields.link"
            ></v-text-field>
            <v-text-field
              label="Edytuj nazwe pliku"
              v-if="getModal.type == 'edit' && !getAttachmentsFields.link"
              v-model="getAttachmentsFields.fileName"
              :error-messages="getAttachmentsError.fileName"
            ></v-text-field>
            <v-text-field
              label="Opis pliku lub linka www"
              v-model="getAttachmentsFields.description"
            ></v-text-field>

            <v-col cols="12" md="12" class="d-flex flex-wrap pa-0">
              <v-col cols="12" md="6" lg="6" class="pa-0 pr-3">
                <v-combobox
                  v-model="getAttachmentsFields.status"
                  :items="items"
                  label="Status załącznika"
                  :error-messages="getAttachmentsError.status"
                ></v-combobox>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  v-model="modaldata"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="getAttachmentsFields.dataArchive"
                      dense
                      readonly
                      label="Data archiwizacji"
                      prepend-icon="mdi-calendar"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="getAttachmentsFields.dataArchive"
                    @input="modaldata = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
          </div>
          <v-col cols="12" sm="6" md="12" class="pa-0">
            <v-textarea
              v-model="getAttachmentsFields.comments"
              label="Uwagi"
              no-resize
              rows="2"
            ></v-textarea>
          </v-col>
        </v-col>
      </v-row>
      <v-col cols="12" sm="12" md="12" class="pa-0" style="font-size: 12px">
        <div v-if="getModal.type == 'edit'">
          <span>
            Plik pierwotny wprowadził:
            {{ getAttachmentsFields.createdBy.name }}
            {{ getAttachmentsFields.createdBy.lastname }}
          </span>
          /
          <span>
            Data wprowadzenia:
            {{ getAttachmentsFields.createdAt }}
          </span>
        </div>
      </v-col>
    </v-container>
  </form>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  data: () => ({
    items: ['Aktualny', 'Archiwalny', 'Draft'],
    modaldata: false,
  }),
  computed: {
    ...mapGetters([
      'getAttachmentsFields',
      'getModal',
      'getAttachmentsError',
      'getFolderNameValue',
      'getFolderItems',
    ]),
  },
};
</script>
