import Vue from 'vue';
import i18n from '@/i18n.js';
import findIndex from 'lodash.findindex';

const state = {
	department: {
		title: 'departments.title',
		header: [{
				text: i18n.t('departments.header.shortName'),
				align: 'left',
				value: 'shortName',
				width: 250,
				class: 'central__table__header',
			},
			{
				text: i18n.t('departments.header.fullName'),
				value: 'fullName',
				class: 'central__table__header',
			},
			{
				text: 'Status',
				value: 'status',
				width: 120,
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'right',
				width: 50,
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	wsdCategoryItems: [
		"Centrala, Ochrona, Portiernia",
		"Izba przyjęć",
		"Blok Operacyjny",
		"Oddziały szpitalne",
		"Ośrodek Rehabilitacji Dziennej",
		"Pracownie diagnostyczne",
		"Apteka Szpitalna",
		"Dział Centralnej Sterylizacji i Dezynfekcji",
		"Samodzielne Stanowiska",
		"Wojewódzka Przychodnia Specjalistyczna Dla Dzieci",
		"Nocna i Świąteczna Opieka Zdrowotna Dla Dzieci",
		"Wojewódzki Ośrodek Rehabilitacji Dla Dzieci",
		"Administracja",
	],
	modal: {
		fields: {
			fullName: null,
			shortName: null,
			comment: null,
			superiorDepartment: null,
			leader: null,
			users: null,
			status: null,
			location: null,
			category: null,
		},
		errors: {
			fullName: null,
			shortName: null,
			leader: null,
			status: null,
			location: null,
		},
	},
	fieldsNames: {
		fullName: 'Nazwa pełna',
		shortName: 'Nazwa skrócona',
		comment: 'Uwagi',
		superiorDepartment: 'Departament nadrzędny',
		leader: 'Kierownik departamentu',
		users: 'Pracownicy departamentu',
		status: 'Status',
		location: 'Lokalizacja',
		category: 'Kategoria',
	},
	id_departments: [],
};
const getters = {
	getDepartment: state => state.department,
	getWSDCategoryDepartments: (state) => state.wsdCategoryItems,
	getDepartmentFields: state => state.modal.fields,
	getDepartmentErrors: state => state.modal.errors,
	getIDDepartments: state => state.id_departments,
	getDepartmentFieldsNames: state => state.fieldsNames,
};

const actions = {
	createDepartment({
		commit,
		dispatch
	}) {
		commit('resetErrors', 'departments');
		Vue.axios({
				method: 'POST',
				url: '/departments',
				data: {
					...state.modal.fields,
				},
			})
			.then(() => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'departments',
				});
				commit('closeModal');
				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				commit('setErrors', {
					route: 'departments',
					err: error.response.data.error,
				});
			});
	},
	updateDepartment({
		commit
	}) {
		commit('resetErrors', 'departments');
		Vue.axios({
				method: 'PUT',
				url: `/departments/${state.modal.fields._id}`,
				data: {
					...state.modal.fields,
				},
			})
			.then(response => {
				commit('updateItem', response.data.department);
				commit('closeModal');

				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				commit('setErrors', {
					route: 'departments',
					err: error.response.data.error,
				});
			});
	},
	departmentDetails({
		commit
	}, payload) {
		Vue.axios({
			method: 'GET',
			url: `/departments/${payload}`,
		}).then(resposne => {
			commit('setDetailsStatuses', resposne.data.statuses);
			commit('setDetails', {
				route: 'departments',
				items: resposne.data.department,
			});
		});
	},
	getDepStatuses({
		commit
	}) {
		Vue.axios({
			method: 'GET',
			url: '/departments/statuses',
		}).then(response => {
			commit('setDetailsStatuses', response.data.statuses);
		});
	},
};
const mutations = {
	removeSuperior(state) {
		state.modal.fields.superiorDepartment = null;
	},
	removeLeader(state) {
		state.modal.fields.leader = null;
	},
	removeWorker(state, payload) {
		let arr = state.modal.fields.users;
		let index = findIndex(arr, e => e == payload);
		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};