<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Nazwa"
                    v-model="getResourcesFields.name"
                    :error-messages="getResourcesErrors.name"
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-textarea
                    label="Opis pliku"
                    v-model="getResourcesFields.description"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['getResourcesFields', 'getResourcesErrors']),
    },
};
</script>
