var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"p-relative",attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"central__table",attrs:{"hide-default-footer":"","mobile-breakpoint":1100,"headers":_vm.getNotification.header,"items":_vm.getTable.items,"items-per-page":11},scopedSlots:_vm._u([{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-list-item-title',[(item.to && item.to.users.length > 0)?_c('span',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(item.to.users[0].name)+" "+_vm._s(item.to.users[0].lastname)+" ")]):(
												item.to && item.to.departments.length > 0
											)?_c('span',[_vm._v(" "+_vm._s(item.to.departments[0].fullName)+" ")]):_c('span',[_vm._v("Brak")])]),(
											item.to && item.to.users.length +
												item.to && item.to.departments.length >
												1
										)?_c('v-list-item-subtitle',_vm._g({staticClass:"grey--text lighten-1"},on),[_vm._v(" + "+_vm._s(item.to.users.length + item.to.departments.length - 1)+" "),(
												item.to && item.to.users.length +
													item.to && item.to.departments.length -
													1 ==
													1
											)?_c('span',[_vm._v("inny")]):_c('span',[_vm._v("innych")])]):_vm._e()],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-3"},[(item.to && item.to.users.length > 0)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("Pracownicy:")]),_c('ul',_vm._l((item.to.users),function(user){return _c('li',{key:user._id},[_vm._v(_vm._s(user.name)+" "+_vm._s(user.lastname))])}),0)]):_vm._e(),(item.to && item.to.departments.length > 0)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("Departamenty:")]),_c('ul',_vm._l((item.to
												.departments),function(department){return _c('li',{key:department._id},[_vm._v(_vm._s(department.fullName))])}),0)]):_vm._e()])],1)]}},{key:"item.dueDate",fn:function(ref){
												var item = ref.item;
return [_c('v-list-item-title',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$moment(item.dueDate).format('YYYY-MM-DD'))+" ")]),_c('v-list-item-subtitle',{staticClass:"grey--text lighten-1"},[_vm._v(" "+_vm._s(item.dueTime)+" ")])]}},{key:"item.createdBy",fn:function(ref){
												var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.lastname)+" ")]}},{key:"item.priority",fn:function(ref){
												var item = ref.item;
return [(item.priority)?_c('v-chip',{style:(("color: " + (item.priority.color))),attrs:{"color":item.priority.background,"label":"","small":""}},[_vm._v(_vm._s(item.priority.priorityName))]):_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v("Brak")])]}},{key:"item.action",fn:function(ref){
												var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.notificationsDevices(item._id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-thick")])],1)]}}])}),_c('div',{staticClass:"text-md-center pt-2"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.getTable.pages},on:{"input":_vm.fetchTableItems},model:{value:(_vm.table.page),callback:function ($$v) {_vm.$set(_vm.table, "page", $$v)},expression:"table.page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }