<template>
    <v-container class="grey lighten-5" style="padding-right: 7px">
        <v-row no-gutters>
            <v-col class="my-2" cols="12">
                <div style="display: flex; align-items: center">
                    <div
                        class="headline font-weight-light"
                        style="margin-left: 15px; margin-top: -3px;"
                    >ZADANIA</div>
                    <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn style="position: absolute; right: 30px;" v-on="on" icon :to="tasksRoute">
                <v-icon color="black">mdi-arrow-right-thick</v-icon>
              </v-btn>
            </template>
            <span>Przejdź do wszystkich</span>
                    </v-tooltip>-->
                </div>
            </v-col>
            <v-col cols="12" style="max-height: 820px; overflow-y: auto; margin-right: -5px">
                <v-alert
                    border="left"
                    style="margin-right: 10px"
                    colored-border
                    :color="task.priority ? task.priority.background : 'grey lighten-1'"
                    v-for="task in getRightBoxTasks"
                    :key="task._id"
                    elevation="2"
                    class="task pa-5"
                >
                    <div
                        style="padding: 0; font-size: 18px; color: #595959"
                        class="tasks__title"
                    >{{shortDescription(task.name, 28)}}</div>
                    <span
                        style="height: 1px; width: 100%; background-color: lightgrey; display: block; margin-bottom: 5px;"
                    ></span>
                    <div class="tasks__content" style="padding: 10px 0;">
                        <p class="ma-0">{{shortDescription(task.description, 120)}}</p>
                    </div>

                    <v-menu :close-on-content-click="false" nudge-left="280" max-width="450">
                        <template v-slot:activator="{ on }">
                            <div class="tasks__from">
                                <span v-on="on" style="font-size: 11px !important">
                                    <v-icon size="11">mdi-account</v-icon>
                                    {{ task.createdBy ? `${task.createdBy.name} ${task.createdBy.lastname}` : `Brak`}}
                                </span>
                                <span v-if="task.termDate">
                                    <v-icon size="11">mdi-calendar-clock</v-icon>
                                    {{$moment(task.termDate).format('YYYY-MM-DD')}} {{task.termTime}}
                                </span>
                            </div>
                        </template>
                        <v-card
                            class="pa-4"
                            style="display: flex;flex-direction: column; justify-content: flex-start; color: #757575; font-size: 14px"
                            v-if="task.createdBy"
                        >
                            <div style="display: flex">
                                <v-avatar style="cursor: pointer;" v-on="on" color="teal" size="40">
                                    <span
                                        class="white--text headline"
                                    >{{task.createdBy.name.charAt(0)}}</span>
                                </v-avatar>
                                <div
                                    style="display: flex; flex-direction: column; margin-left: 15px"
                                >
                                    <span
                                        style="font-size: 15px; color: black"
                                    >{{task.createdBy.name}} {{task.createdBy.lastname}}</span>
                                    <span>{{task.createdBy.job}}</span>
                                </div>
                            </div>
                            <div style="display: flex; flex-direction: column; ">
                                <v-divider class="my-3"></v-divider>
                                <!-- <p class="ma-0">
                  <b>Departament:</b>
                  {{task.departament}}
                                </p>-->
                                <p class="ma-0">
                                    <b>Email:</b>
                                    <span
                                        v-html="mailTo(task.createdBy.mail)"
                                        v-if="task.createdBy.mail"
                                    ></span>
                                    <span v-else>Brak</span>
                                </p>
                                <p class="ma-0">
                                    <b>Kontakt:</b>
                                    <span v-if="task.createdBy.phone">{{task.createdBy.phone}}</span>
                                    <span v-else>Brak</span>
                                </p>
                            </div>
                        </v-card>
                    </v-menu>

                    <div class="tasks__buttons">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    fab
                                    v-on="on"
                                    dark
                                    style="width: 25px; height: 25px"
                                    :to="tasksRoute"
                                    color="#2f445e"
                                >
                                    <v-icon size="15" color="white">mdi-arrow-right-thick</v-icon>
                                </v-btn>
                            </template>
                            <span>Przejdź do szczegółów</span>
                        </v-tooltip>

                        <!-- <v-speed-dial>
              <template v-slot:activator style="transition: 8s !important">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="width: 20px; height: 20px" color="#19aa8d" dark fab>
                      <v-icon size="12">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>Opcje</span>
                </v-tooltip>
              </template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" fab dark style="width: 25px; height: 25px" color="#fb6565">
                    <v-icon size="15">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Usuń zadanie</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    v-on="on"
                    dark
                    style="width: 25px; height: 25px"
                    :to="tasksRoute"
                    color="#2f445e"
                  >
                    <v-icon size="15" color="white">mdi-arrow-right-thick</v-icon>
                  </v-btn>
                </template>
                <span>Przejdź do szczegółów</span>
              </v-tooltip>
                        </v-speed-dial>-->
                    </div>
                </v-alert>
                <v-alert
                    border="left"
                    style="margin-right: 10px"
                    colored-border
                    color="grey lighten-1"
                    elevation="2"
                    class="task pa-5"
                    v-if="getRightBoxTasks.length == 0"
                >
                    <div
                        style="padding: 0; font-size: 18px; color: #595959"
                        class="tasks__title"
                    >Brak niezrealizowanych zadań</div>
                    <span
                        style="height: 1px; width: 100%; background-color: lightgrey; display: block; margin-bottom: 5px;"
                    ></span>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import truncate from "lodash.truncate";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
    data() {
        return {
            tasksRoute: "/tasks",
            remove: false,
            fav: true,
            fab: false,
            transition: "slide-y-reverse-transition",
            on: ""
        };
    },
    computed: {
        ...mapGetters(["getRightBoxTasks"])
    },

    methods: {
        shortDescription(description, length) {
            return truncate(description, { length });
        },
        mailTo(mail) {
            return mail
                ? `<a href="mailto: ${mail}"> ${mail}</a>`
                : "E-mail: Brak";
        }
    }
};
</script>