<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col class="my-2" cols="12">
        <div style="display: flex; align-items: center">
          <div class="headline font-weight-light">KONTAKTY</div>
          <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                style="position: absolute; right: 0px;"
                                v-on="on"
                                icon
                                :to="tasksRoute"
                            >
                                <v-icon color="black">mdi-arrow-right-thick</v-icon>
                            </v-btn>
                        </template>
                        <span>Przejdź do wszystkich</span>
          </v-tooltip>-->
        </div>
      </v-col>
      <v-col cols="12" style="height: 900px ">
        <v-card style="height: 820px;  ">
          <v-text-field
            :label="$t('global.search')+'...'"
            v-on:keyup="debounceInput"
            append-icon="mdi-magnify"
            single-line
            class="px-3"
            hint="Zacznij pisać..."
            persistent-hint
          ></v-text-field>

          <v-list two-line>
            <template style="margin-bottom: 50px !important" v-for="company in getRBContacts">
              <div
                class="pl-5"
                :key="company._id"
                style="background-color:rgb(244, 244, 244); padding:10px 10px 0px 10px;"
              >
                <p class="ma-0 pa-0" style="font-size: 15px; ">{{company.name}}</p>
                <p
                  class="ma-0 pa-0 font-weight-light"
                  style="font-size: 12px; color: "
                >Tel: {{ company.phoneNumber ? company.phoneNumber : 'Brak'}}</p>
                <p
                  class="ma-0 pa-0 font-weight-light"
                  style="font-size: 12px"
                  v-html="mailTo(company.email)"
                ></p>

                <v-divider class="mt-2" :key="company.id"></v-divider>
              </div>

              <v-list-item
                class="ml-5"
                v-for="people in company.representatives"
                :key="people._id"
                @click="$router.push(`/contacts/${company._id}`);"
              >
                <v-list-item-content>
                  <p
                    class="ma-0 pa-0"
                    style="font-size: 14px; color: #757575"
                  >{{people.name}} {{people.lastname}}</p>
                  <p
                    class="ma-0 pa-0 font-weight-light"
                    style="font-size: 12px; color: #acacac"
                  >{{people.position}}</p>
                  <p
                    class="ma-0 pa-0 font-weight-light"
                    style="font-size: 12px; color: #acacac"
                  >{{people.phoneNumber}}</p>
                  <p
                    class="ma-0 pa-0 font-weight-light"
                    style="font-size: 12px; color: #acacac; cursor: pointer; "
                    v-html="mailTo(people.email)"
                  ></p>
                </v-list-item-content>
                <v-list-item-avatar style="position: absolute; top: 0px; right: 5px">
                  <v-icon size="20" color="pink">mdi-account</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import truncate from "lodash.truncate";
import debounce from "lodash.debounce";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      tasksRoute: "/contacts",
      index: "",
      caseSensitive: false,
      company: []
    };
  },
  computed: {
    ...mapGetters(["getRBContacts"])
  },
  methods: {
    ...mapActions(["RBContacts"]),
    debounceInput: debounce(function(e) {
      // this.getModalDevices.SN = e.target.value;
      if (e.target.value.length >= 4) {
        this.RBContacts(e.target.value);
      }
    }, 500),
    mailTo(mail) {
      return mail
        ? `<a href="mailto:${mail}">E-mail: ${mail}</a>`
        : "E-mail: Brak";
    }
  }
};
</script>