import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	material: {
		title: 'Materiały',
		header: [
			{
				text: 'Index',
				value: 'index',
				width: 128,
				class: 'central__table__header',
			},
			{
				text: 'Index materiału',
				value: 'materialIndex',
				class: 'central__table__header',
			},
			{
				text: 'Nazwa materiału',
				value: 'name',
				class: 'central__table__header',
			},
			{
				text: 'Stan aktualny',
				value: 'currentVolume',
				class: 'central__table__header',
			},
			{
				text: 'Limit minimalny',
				value: 'minimalLimit',
				class: 'central__table__header',
			},
			{
				text: 'Status',
				width: 128,
				value: 'status',
			},
			{
				text: 'Akcja',
				align: 'right',
				width: 96,
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			name: '',
			materialIndex: '',
			minimalLimit: '',
			unit: '',
			description: '',
		},
		errors: {
			name: null,
			materialIndex: null,
		},
	},
	fieldsNames: {
		name: 'Nazwa materiału',
		materialIndex: 'Index materiału',
		minimalLimit: 'Limit minimalny',
		unit: 'Jednostka miary',
		description: 'Opis',
		currentVolume: 'Stan aktualny',
	},
	unitOptions: ['Tona', 'Kilogram', 'Litr', 'Metr'],
	details: {},
	materialRotation: [],
};

const getters = {
	getMaterial: (state) => state.material,
	getMaterialFields: (state) => state.modal.fields,
	getMaterialFieldsNames: (state) => state.fieldsNames,
	getMaterialUnitOptions: (state) => state.unitOptions,
	getMaterialErrors: (state) => state.modal.errors,
	getMaterialDetails: (state) => state.details,
	getMaterialRotation: (state) => state.materialRotation,
};

const actions = {
	async createMaterial({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/materials',
			data: { ...state.modal.fields },
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'materials',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano materiał',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'materials',
					err: error.response.data,
				});
			});
	},
	async materialDetails({ commit, dispatch }, payload) {
		Vue.axios({ method: 'GET', url: `/materials/${payload.id}` }).then(
			(response) => {
				if (payload.type == 'modal') {
					commit('setMaterialsFields', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);

					commit('setMaterialsDetails', response.data.item);
					commit('setDetailsIndex', response.data.item.index);

					if (payload.next) {
						payload.next();
					}
				}
			},
		);
	},
	async updateMaterial({ commit, dispatch }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/materials/${payload.id}`,
			data: { ...state.modal.fields },
		})
			.then((response) => {
				dispatch('materialDetails', { id: payload.id });
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano wpis',
				});
			})
			.catch((error) => {
				commit('setErrors', {
					route: 'materials',
					err: error.response.data.error,
				});
			});
	},

	async materialRotation({ commit, dispatch }, payload) {
		await Vue.axios({
			method: 'GET',
			url: `/warehouse/materialType/${payload.id}`,
		})
			.then((response) => {
				commit('setMaterialRotation', response.data.item);
			})
			.catch((error) => {
				console.error(error);
			});
	},
};

const mutations = {
	setMaterialsFields(state, payload) {
		state.modal.fields = payload;
	},
	setMaterialsDetails(state, payload) {
		state.details = payload;
	},
	setMaterialRotation(state, payload) {
		state.materialRotation = payload;
	},
};

export default {
	state,
	actions,
	getters,
	mutations,
};
