import Vue from 'vue';
import findIndex from 'lodash.findindex';
const state = {
	role: {
		open: false,
		roleName: '',
	},
	type: '',
	items: [],
	errors: {
		roleName: '',
	},
};
const getters = {
	getURole: (state) => state.role,
	getURoleType: (state) => state.type,
	getURoleItems: (state) => state.items,
	getURoleErrors: (state) => state.errors,
};
const actions = {
	createURole({ state, commit }) {
		state.errors.RoleName = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/users/role',
			data: {
				...state.role,
			},
		})
			.then((response) => {
				commit('clearURole');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
				commit('setURoleItem', response.data.item);
			})
			.catch((error) => {
				if (error.response.data.error === 'Exists') {
					state.errors.RoleName = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.RoleName.kind ===
					'required'
				) {
					state.errors.RoleName = 'Pole jest wymagane';
				}
			});
	},

	deleteURole({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/users/role/delete/${payload}`,
		})
			.then(() => {
				commit('deleteURItem', payload);
				// Vue.swal('Usunięto!', 'Pomyślnie usunięto wpis', 'success');
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	fetchURole({ state, commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/users/role',
		}).then((response) => {
			commit('setURoleItems', response.data.items);
		});
	},

	URoleDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/users/role/${payload}`,
		})
			.then((response) => {
				commit('setURoleDetails', response.data.item);
			})
			.catch((error) => {
				console.log(error);
			});
	},

	uRoleUpdate({ commit }) {
		state.errors.RoleName = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/users/role/update/${state.role._id}`,
			data: {
				...state.role,
			},
		})
			.then((response) => {
				commit('clearURole');
				commit('updateURItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch((error) => {
				console.log(error.response.data);
				if (error.response.data.error === 'Exists') {
					state.errors.eoleName = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.roleName.kind ===
					'required'
				) {
					state.errors.roleName = 'Pole jest wymagane';
				}
			});
	},
};
const mutations = {
	setURoleType(state, payload) {
		state.type = payload;
	},
	clearURole(state) {
		state.role.open = false;
		state.role.roleName = '';
		state.errors.roleName = '';
	},
	setURoleItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	setURoleItems(state, payload) {
		state.items = payload;
	},
	setURoleDetails(state, payload) {
		state.role.roleName = payload.roleName;
		state.role._id = payload._id;
		state.role.open = true;
	},
	updateURItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
	deleteURItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, (e) => e._id == payload);
		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
