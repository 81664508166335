import Vue from 'vue';
import i18n from '@/i18n.js';
import router from '@/router.js';

const state = {
    // === === USERS === === //
    users: {
        title: 'users.title',
        header: [{
                align: 'left',
                value: 'photos',
                width: '30',
                class: 'central__table__header'
            },
            {
                text: i18n.t('users.header.name'),
                align: 'left',
                value: 'name',
                class: 'central__table__header'
            },
            {
                text: i18n.t('users.header.lastname'),
                value: 'lastname',
                class: 'central__table__header'
            },
            {
                text: i18n.t('users.header.position'),
                value: 'job',
                class: 'central__table__header'
            },
            {
                text: i18n.t('users.header.status'),
                value: 'status',
                class: 'central__table__header'
            },
            {
                text: i18n.t('global.actions'),
                align: 'right',
                value: 'action',
                sortable: false,
                class: 'central__table__header'
            }
        ]
    },
    modal: {
        fields: {
            name: null,
            lastname: null,
            birthday: null,
            userIndex: null,
            login: null,
            job: null,
            roleName: null,
            password: null,
            passwordAgain: null,
            mail: null,
            privateMail: null,
            phone: null,
            privatePhone: null,
            isAdmin: false,
            comment: null
        },
        staff: {
            contract: '',
            dayJob: '',
            employmentStart: '',
            employmentEnd: '',

            lastExamination: '',
            endExamination: '',

            startBhp: '',
            endBhp: '',
            placeOfBirth: '',
            needments: '',
            comment: ''
        },
        errors: {
            name: null,
            lastname: null,
            login: null,
            password: null,
            mail: null,
            userIndex: null
        }
    },
    details: {
        fields: {},
        departments: [],
        registries: [],
        staff: {
            contract: null,
            needments: null,
            comment: null,
            placeOfBirth: null,
            dayJob: null,
            employmentStart: null,
            employmentEnd: null,

            lastExamination: null,
            endExamination: null,

            startBhp: null,
            endBhp: null
        }
    },
    dictionaries: {
        contract: [],
        dayJob: []
    },
    jobs: [],
    roleName: [],
    fieldsNames: {
        status: 'Status',
        name: 'Imię',
        lastname: 'Nazwisko',
        birthday: 'Data urodzenia',
        login: 'Login',
        job: 'Stanowisko',
        mail: 'E-mail firmowy',
        privateMail: 'E-mail prywatny',
        phone: 'Telefon firmowy',
        privatePhone: 'Telefon prywatny',
        comment: 'Uwagi',
        userIndex: 'ID',
        employmentStart: 'Początek zatrudnienia:',
        employmentEnd: 'Koniec zatrudnienia:',
        lastExamination: 'Ostatnie badanie okresowe:',
        endExamination: 'Ważność badania okresowego:',
        startBhp: 'Data szkolenia BHP:',
        endBhp: 'Ważność szkolenia BHP:',
        needments: 'Wymagania dodatkowe',
        contract: 'Podstawa zatrudnienia',
        comment: 'Uwagi',
        dayJob: 'Wymiar etatu',
        placeOfBirth: 'Miejsce urodzenia'
    }
};
const getters = {
    getUsers: state => state.users,
    getUsersData: state => state.modal.fields,
    getUsersRules: state => state.modal.rules,
    getUsersErrors: state => state.modal.errors,
    getUserDetails: state => state.details.fields,
    getUserDepartments: state => state.details.departments,
    getUserRegistriesList: state => state.details.registries,
    getUserJobsList: state => state.jobs,
    getUserRoleList: state => state.roleName,
    getStaffModal: state => state.modal.staff,
    getUserStaff: state => state.details.staff,
    getContractDictionary: state => state.dictionaries.contract,
    getDayJobDictionary: state => state.dictionaries.dayJob
};

const actions = {
    createUser({
        state,
        commit,
        dispatch
    }) {
        commit('resetErrors', 'users');
        Vue.axios({
                method: 'POST',
                url: '/users',
                data: {
                    ...state.modal.fields
                }
            })
            .then(() => {
                dispatch('fetchData', {
                    page: 1,
                    pageSize: 11,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    routeName: 'users'
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie stworzono nowy wpis'
                });
            })
            .catch(error => {
                console.log(error.response.data.error);
                if (error.response.data.error == 'Licence limit') {
                    commit('setSnackbars', {
                        type: 'error',
                        text: 'Przekroczono limit licencji'
                    });
                } else {
                    commit('setErrors', {
                        route: 'users',
                        err: error.response.data.error
                    });
                }
            });
    },

    userDepartmentsProfile({
        state,
        commit
    }, payload) {
        Vue.axios({
                method: 'GET',
                url: `/users/${payload.id}`
            })
            .then(response => {
                commit('setUserDepartmentProfile', response.data.departments);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status == '404') router.push('/NotFound');
            });
    },

    userDeatils({
        state,
        commit
    }, payload) {
        state.details.fields = {};
        state.details.departments = [];
        state.details.registries = [];
        Vue.axios({
                method: 'GET',
                url: `/users/${payload.id}`,
            })
            .then(response => {
                commit('setUserDetails', response.data.user);
                commit('setDetailsIndex', response.data.user.index);
                commit('setDetailsMenager', {
                    ...response.data.user.menager,
                    update: false,
                });
                commit('setUserDepartments', response.data.departments);
                commit('setUserRegistriesList', response.data.registries);
                commit('setDetailsStatus', response.data.user.status);
                commit('setDetailsStatuses', response.data.statuses);
                if (payload.next) {
                    payload.next();
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response.status == '404') router.push('/NotFound');
            });
    },
    userDeatilsModal({
        state,
        commit
    }, payload) {
        Vue.axios({
                method: 'GET',
                url: `/users/${payload}`,
            })
            .then(response => {
                commit('setUserModalData', response.data.user);
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateUser({
        state,
        commit
    }, payload) {


        Vue.axios({
                method: 'PUT',
                url: `/users/${payload}`,
                data: {
                    ...state.modal.fields
                }
            })
            .then(response => {
                commit('setUserDetails', response.data.user);
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano dany wpis'
                });
            })
            .catch(error => {
                commit('setErrors', {
                    route: 'users',
                    err: error.response.data.error
                });
            });
    },

    fetchJobDictionary({
        commit
    }) {
        Vue.axios({
                method: 'GET',
                url: '/dictionary/users/job'
            })
            .then(response => {
                commit('setUserJobsList', response.data.items);
            })
            .catch(error => {
                console.log(error);
            });
    },
    fetchRoleDictionary({
        commit
    }) {
        Vue.axios({
                method: 'GET',
                url: '/dictionary/users/role'
            })
            .then(response => {
                commit('setUserRoleList', response.data.items);
            })
            .catch(error => {
                console.log(error);
            });
    },

    sendConfirmMail({
        state,
        commit
    }) {
        if (state.details.fields.mail) {
            Vue.axios({
                    method: 'POST',
                    url: '/users/mailService',
                    data: {
                        mail: state.details.fields.mail,
                        name: state.details.fields.name,
                        lastname: state.details.fields.lastname,
                        login: state.details.fields.login,
                        case: 'confirmUser'
                    }
                })
                .then(() => {
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Wiadomość została wysłana'
                    });
                })
                .catch(error => {
                    commit('setSnackbars', {
                        type: 'error',
                        text: 'Nie udało się wysłać wiadomości. Spróbuj później'
                    });
                });
        } else {
            commit('setSnackbars', {
                type: 'warning',
                text: 'Nie podano adresu e-mail'
            });
        }
    },

    editStaff({
        state,
        commit
    }, payload) {
        Vue.axios({
                method: 'POST',
                url: `/users/staff/${payload}`,
                data: {
                    ...state.modal.staff
                }
            })
            .then(response => {
                commit('setUserStaff', response.data.item.staff);
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano dany wpis'
                });
            })
            .catch(error => {
                console.log(error);
            });
    },
    fetchStaff({
        commit
    }, payload) {
        Vue.axios({
                method: 'GET',
                url: `/users/staff/${payload}`
            })
            .then(response => {
                commit('setUserStaff', response.data.item.staff);
            })
            .catch(error => {
                console.log(error);
            });
    },
    fetchContract({
        commit
    }) {
        Vue.axios({
                method: 'GET',
                url: '/dictionary/users/contract'
            })
            .then(response => {
                commit('setContractDictionary', response.data.items);
            })
            .catch(error => {
                console.log(error);
            });
    },
    fetchDayJob({
        commit
    }) {
        Vue.axios({
                method: 'GET',
                url: '/dictionaries/users/dayJob'
            })
            .then(response => {
                commit('setDayJobDictionary', response.data.items);
            })
            .catch(error => {
                console.log(error.response.data);
            });
    }
};
const mutations = {
    setUserDepartmentProfile(state, payload) {
        state.details.departments = payload;
    },

    selectDefaultAdmin(state) {
        state.modal.fields.isAdmin = false;
    },

    setUserDetails(state, payload) {
        state.details.fields = payload;
    },
    setUserDepartments(state, payload) {
        state.details.departments = payload;
    },
    setUserRegistriesList(state, payload) {
        state.details.registries = payload;
    },
    setUserModalData(state, payload) {
        state.modal.fields = payload;
    },
    setUserJobsList(state, payload) {
        state.jobs = payload;
    },
    setUserRoleList(state, payload) {
        state.roleName = payload;
    },
    setUserStaff(state, payload) {
        state.details.staff = {
            contract: null,
            needments: null,
            comment: null,
            placeOfBirth: null,
            dayJob: null,
            employment: {
                start: null,
                end: null
            },
            bhp: {
                start: null,
                end: null
            },
            examination: {
                start: null,
                end: null
            }
        };
        if (payload) {
            state.details.staff = payload;
        }
    },
    setStaffModal(state) {
        state.modal.staff = state.details.staff;
    },
    setContractDictionary(state, payload) {
        state.dictionaries.contract = payload;
    },
    setDayJobDictionary(state, payload) {
        state.dictionaries.dayJob = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};