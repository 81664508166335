import i18n from '@/i18n.js';
import Vue from 'vue';
import findIndex from 'lodash.findindex';
import moment from 'moment-timezone';

const state = {
	notification: {
		title: 'Alerty',
		header: [{
				text: 'Od',
				value: 'createdBy',
				width: '15%',
				class: 'central__table__header',
			},
			{
				text: 'Do',
				value: 'to',
				width: '15%',
				sortable: false,
				class: 'central__table__header',
			},
			{
				text: 'Treść',
				value: 'description',
				width: '40%',
				class: 'central__table__header',
			},
			{
				text: 'Kiedy',
				value: 'dueDate',
				width: '10%',
				class: 'central__table__header',
			},
			{
				text: 'Priorytet',
				value: 'priority',
				width: '10%',
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'right',
				width: '8%',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
		count: '',
	},
	modal: {
		fields: {
			to: {
				users: [],
				departments: [],
			},
			dueDate: '',
			cyclicalNotification: '',
			alertDate: '',
			alertThisDate: '',
			dueTime: '',
			cyclicalNotificationCheckbox: '',
			alertDateCheckbox: '',
			priority: {},
			description: '',

		},
		errors: {
			to: '',
			dueDate: '',
			description: '',
		},
	},
	RightBox: [],
};

const getters = {
	getNotification: (state) => state.notification,
	getNotificationData: (state) => state.modal.fields,
	getNotificationError: (state) => state.modal.errors,
	getRBNotifications: (state) => state.RightBox,
	getNotificationsCount: (state) => state.notification.count,
};

const actions = {
	createAlert({
		commit,
		dispatch
	}, payload) {
		commit('resetErrors', 'notifications');
		if (
			state.modal.fields.to.users.length > 0 ||
			state.modal.fields.to.departments.length > 0
		) {
			let dataToSend = {};
			if (payload.registryName === 'alerts') {
				dataToSend = {
					...state.modal.fields,
					registryName: payload.registryName,
				};
			} else {
				dataToSend = {
					...state.modal.fields,
					registryName: payload.registryName,
					registryID: payload.registryID,
				};
			}
			Vue.axios({
					method: 'POST',
					url: `/alerts/${payload.registryName}`,
					data: dataToSend,
				})
				.then((response) => {
					let users = [];
					response.data.to.departments.forEach((department) => {
						users.push(...department.users);
					});
					users.push(...response.data.to.users);
					dispatch('sendMail', {
						case: 'newAlert',
						receivers: users,
						info: {
							description: response.data.description,
							createdBy: `${response.data.createdBy.name +
								' ' +
								response.data.createdBy.lastname}`,
							realisationTime: `${moment(
								response.data.dueDate,
							).format('YYYY-MM-DD') +
								' ' +
								response.data.dueTime}`,
						},
					});
					if (payload.registryName !== 'alerts') {
						dispatch('fetchData', {
							page: 1,
							pageSize: 11,
							search: '',
							sortBy: [],
							sortDesc: [],
							routeName: 'alert_tab',
							registryID: payload.registryID,
							registryName: payload.registryName,
						});
					} else {
						dispatch('fetchData', {
							page: 1,
							pageSize: 11,
							search: '',
							sortBy: [],
							sortDesc: [],
							routeName: 'alerts',
							registryName: payload.registryName,
						});
					}
					dispatch('notificationsCount', {
						registryID: payload.registryID,
						registryName: payload.registryName,
					});
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie stworzono nowy wpis',
					});
				})
				.catch((error) => {
					console.log(error.response.data);
					commit('setErrors', {
						route: 'notifications',
						err: error.response.data.error,
					});
				});
		} else {
			state.modal.errors.to = 'Pole wymagane';
		}
	},

	alertDetails({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/alerts/${payload.id}/${payload.registryName}`,
			})
			.then((response) => {
				console.log(response.data.item);

				commit('setAlertDetails', response.data.item);
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	},

	alertUpdate({
		commit
	}, payload) {
		commit('resetErrors', 'notifications');
		if (
			state.modal.fields.to.users.length > 0 ||
			state.modal.fields.to.departments.length > 0
		) {
			Vue.axios({
					method: 'PUT',
					url: `/alerts/${state.modal.fields._id}/${payload.registryName}`,
					data: {
						...state.modal.fields,
					},
				})
				.then((response) => {
					commit('updateItem', response.data.item);
					commit('closeModal');
					commit('setSnackbars', {
						type: 'success',
						text: 'Pomyślnie edytowano',
					});
				})
				.catch((error) => {
					console.log(error.response.data);
					commit('setErrors', {
						route: 'notifications',
						err: error.response.data.error,
					});
				});
		} else {
			state.modal.errors.to = 'Pole wymagane';
		}
	},
	RBNotifications({
		commit
	}, payload) {
		if (!payload) {
			Vue.axios({
					method: 'GET',
					url: '/rightbox/notifications',
				})
				.then((response) => {
					commit('setRBNotifications', response.data.items);
				})
				.catch((error) => {
					console.log(error.response.data);
				});
		}
	},
	notificationsCount({
		commit
	}, payload) {
		Vue.axios({
			method: 'GET',
			url: `/global/find/count/alerts?isDeleted=false`,
			params: payload,
		}).then((response) => {
			state.notification.count = response.data;
		});
	},
};

const mutations = {
	setAllNUsers(state, payload) {
		state.modal.fields.to.users = [];
		state.modal.fields.to.departments = [];

		payload.forEach((item) => {
			state.modal.fields.to.users = [
				...state.modal.fields.to.users,
				item._id,
			];
		});
	},
	removeNACPeople(state, payload) {
		let arr = state.modal.fields.to[payload.section];
		let index = findIndex(arr, (e) => e == payload.id);
		arr.splice(index, 1);
	},
	clearNPriority(state, payload) {
		state.modal.fields.priority = '';
	},
	setAlertDetails(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.dueDate = moment(payload.dueDate).format(
			'YYYY-MM-DD',
		);
	},
	setRBNotifications(state, payload) {
		state.RightBox = payload;
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};