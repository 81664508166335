
<template>
    <div class="buttons__mobile">
        <v-tooltip left v-if="getPermission.level > 1">
            <template v-slot:activator="{ on }">
                <v-btn
                    :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${window.width < 1200 ? '50px' : '40px'}`"
                    fab
                    dark
                    v-on="on"
                    small
                    class="buttons--add mr-3"
                    @click="goToModel"
                >
                    <v-icon small>mdi-library-shelves</v-icon>
                </v-btn>
            </template>
            <span>Otwórz dane informacje o modelu</span>
        </v-tooltip>
        <v-tooltip left v-if="getServiceImplement.details._id">
            <template v-slot:activator="{ on }">
                <v-btn
                    :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${window.width < 1200 ? '50px' : '40px'}`"
                    fab
                    dark
                    v-on="on"
                    small
                    class="mr-3 buttons--add"
                    @click="getSignature.modal = true"
                >
                    <v-icon size="30">mdi-draw</v-icon>
                </v-btn>
            </template>
            <span>Podpis klienta</span>
        </v-tooltip>
        <v-tooltip left v-if="getPermission.level > 1">
            <template v-slot:activator="{ on }">
                <v-btn
                    :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${window.width < 1200 ? '50px' : '40px'}; background: ${['Zakończony', 'Zamknięty'].includes(getDetailsStatus.name) && getIsTechnics ? 'gray' : ''} !important; cursor: ${['Zakończony', 'Zamknięty'].includes(getDetailsStatus.name) && getIsTechnics ? 'default' : 'pointer'}`"
                    fab
                    dark
                    v-on="on"
                    small
                    class="buttons--add"
                    @click="editImplementation"
                >
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>Edytuj informacje</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        window: {
            width: 0,
            height: 0,
        },
    }),
    computed: {
        ...mapGetters([
            "getPermission",
            "getSignature",
            "getServiceImplement",
            "getServiceDetails",
            "getDetailsStatus",
            "getIsTechnics",
        ]),
    },
    created() {
        this.handleResize();
    },
    methods: {
        ...mapActions([
            "openModal",
            "fetchServiceMen",
            "getServiceImplementation",
            "findModel",
            "serviceOrderDeatils",
        ]),
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        goToModel() {
            window.open(
                `/#/models/${this.getServiceDetails.device.modelDevices.id}`,
                "_blank"
            );
        },
        editImplementation() {
            if (
                ["Zakończony", "Zamknięty"].includes(this.getDetailsStatus.name)
            ) {
                if (!this.getIsTechnics) {
                    this.fetchServiceMen();

                    this.getServiceImplementation({
                        id: this.$route.params.id,
                        type: "modal",
                    });

                    this.serviceOrderDeatils({
                        id: this.$route.params.id,
                        type: "modal_implementation",
                    }).then(() => {
                        this.openModal({
                            headLine: "global.edit",
                            width: 1600,
                            open: true,
                            type: "edit",
                            route: "devices",
                            tab: "implementation_service",
                        });
                    });
                }
            } else {
                this.fetchServiceMen();

                this.getServiceImplementation({
                    id: this.$route.params.id,
                    type: "modal",
                });

                this.serviceOrderDeatils({
                    id: this.$route.params.id,
                    type: "modal_implementation",
                }).then(() => {
                    this.openModal({
                        headLine: "global.edit",
                        width: 1600,
                        open: true,
                        type: "edit",
                        route: "devices",
                        tab: "implementation_service",
                    });
                });
            }
        },
    },
};
</script>
