<template>
	<v-container>
		<div>
			<v-row>
				<v-col cols="12" md="7">
					<div class="pl-1">
						<span class="font-weight-light" style="font-size:18px"
							>Lista dostaw na budowę:</span
						>
					</div>
					<v-data-table
						disable-sort
						fixed-header
						height="calc(100vh - 585px)"
						disable-pagination
						hide-default-footer
						:items="getConstructionsDeliveries"
						:headers="headers"
					>
						<template v-slot:item="{ item }">
							<tr>
								<td>
									{{
										item.wzDate
											? $moment(item.wzDate).format('YYYY-MM-DD')
											: $moment(item.createdAt).format('YYYY-MM-DD')
									}}
								</td>
								<td>{{ item.materialType ? item.materialType.name : '' }}</td>
								<td>
									{{ correctWeight(item.grossWeight, item.tareWeight) }} [{{
										item.materialType.unit
									}}]
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="12" md="5">
					<div class="pl-1">
						<span class="font-weight-light" style="font-size:18px"
							>Razem wg. materiału:</span
						>
					</div>
					<v-data-table
						disable-sort
						fixed-header
						height="calc(100vh - 585px)"
						disable-pagination
						hide-default-footer
						:headers="groupedHeaders"
					>
						<template v-slot:body>
							<tbody>
								<tr
									v-for="(value, name) in getConstructionsGroupedTable"
									:key="name"
								>
									<td>{{ name }}</td>
									<td>{{ Number(value).toFixed(2) }}</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>
<script>
import moment from 'moment-timezone';
import { mapActions, mapGetters } from 'vuex';
export default {
	data: () => ({
		headers: [
			{
				text: 'Data',
				value: 'rotationType',
				class: 'central__table__header',
			},
			{
				text: 'Materiał',
				value: 'materialType.name',
				class: 'central__table__header',
			},
			{
				text: 'Waga',
				value: 'netWeight',
				class: 'central__table__header',
			},
		],
		groupedHeaders: [
			{
				text: 'Materiał',
				class: 'central__table__header',
			},
			{
				text: 'Suma',
				class: 'central__table__header',
			},
		],
	}),
	computed: {
		...mapGetters([
			'getConstructionsDeliveries',
			'getConstructionsGroupedTable',
		]),
	},
	methods: {
		...mapActions(['constructionsDeliveries']),
		correctWeight(gross, tare) {
			return Number(Number(gross) - Number(tare)).toFixed(2);
		},
	},
	beforeMount() {
		this.constructionsDeliveries({ id: this.$route.params.id });
	},
};
</script>
