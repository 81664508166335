<template>
    <p class="navigation__calendar__mobile">
        <span>{{weekday}}</span>
        <span>{{day}} {{month}}</span>
    </p>
</template>
<script>
export default {
    data: () => ({
        today: "",
        day: "",
        month: "",

        weekday: "",
        dy: "",
        number: ""
    }),
    methods: {
        getData() {
            this.today = new Date();
            this.day = this.today.getDate();
            this.month = new Date()
                .toLocaleString("pl-PL", {
                    month: "short"
                })
                .toUpperCase();

            this.weekday = new Date()
                .toLocaleString("pl-PL", {
                    weekday: "short"
                })
                .toUpperCase()
                .slice(0, -1);

            if (this.month < 10) {
                this.month = "0" + this.month;
            }

            if (this.day < 10) {
                this.day = "0" + this.day;
            }
        }
    },
    beforeMount() {
        this.getData();
    }
};
</script>