import Vue from 'vue';
import router from '@/router.js';

import moment from 'moment';

const state = {
    modalOffers: false,
    Offers: {
        title: 'Oferty',
        header: [
            {
                text: 'Index',
                align: 'left',
                value: 'index',
                class: 'central__table__header',
                width: '125px',
            },
            {
                text: 'Kontakt',
                value: 'contractorOffers',
                class: 'central__table__header',
                width: '430px',
            },
            {
                text: 'Twórca',
                value: 'createdBy',
                class: 'central__table__header',
                width: '180px',
            },
            {
                text: 'Dotyczy',
                value: 'titleOffers',
                class: 'central__table__header',
                width: '250px',
            },
            {
                text: 'Data utworzenia',
                value: 'databeginningOffers',
                class: 'central__table__header',
                width: '180px',
            },
            {
                text: 'Termin',
                value: 'dataendOffers',
                class: 'central__table__header',
                width: '125px',
            },

            {
                text: 'Status',
                value: 'status',
                class: 'central__table__header',
                width: '125px',
            },
            //  {
            //    value: 'invoice',
            //     width: '5%',
            //     class: 'central__table__header',
            // },
            {
                text: 'Akcja',
                align: 'right',
                value: 'action',
                sortable: false,
                class: 'central__table__header',
            },
        ],
    },

    clientItems: [],
    clientRepresentative: [],
    modal: {
        fields: {
            titleOffers: '',
        },
        errors: {
            titleOffers: null,
        },
    },
    details: {
        titleOffers: '',
        contractorOffers: '',
        databeginningOffers: '',
        dataendOffers: '',
        typeOffers: '',
        typeContractor: '',
        commentOffers: '',
    },
    fieldsNames: {
        titleOffers: 'Tytuł',
        contractorOffers: 'Kontrahent',
        dataimportanceOffers: 'Data ważności',
        responsibleOffers: 'Osoba odpowiedzialna po stronie Klienta',
        typeOffers: 'Rodzaj oferty',
        typeContractor: 'Rodzaj Klienta',
        commentOffers: 'Opis / Uwagi',
        responsiblenewnameOffers: 'Imię reprezentanta',
        responsiblenewlastOffers: 'Nazwisko reprezentanta',
        responsiblenewephoneOffers: 'Numet telefonu',
        responsiblenewemailOffers: 'E-mail',
    },
    implementation: {
        details: {},
        turnedOn: false,
        implementationDate: null,
        implementationTime: null,
        basic: null,
        description: '',
    },
};

const getters = {
    getOffers: (state) => state.Offers,
    getModalOffers: (state) => state.modal.fields,
    getOffersDetails: (state) => state.details,
    getOffersFieldsNames: (state) => state.fieldsNames,
    getOffersErrors: (state) => state.modal.errors,
    getOffersItems: (state) => state.clientItems,
    getOffersRepresentatives: (state) => state.clientRepresentative,
    getImplementationOffers: (state) => state.implementation,
    getImplementationDetails: (state) => state.implementation.details,
};

const actions = {
    createOffers({ dispatch, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: '/offers',
            data: {
                ...state.modal.fields,
                ...payload,
            },
        })
            .then((response) => {
                dispatch('fetchData', {
                    page: 1,
                    pageSize: 11,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    routeName: 'offers',
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie dodano nową ofertę',
                });
            })
            .catch((error) => {
                console.log(error.response.data);
                commit('setErrors', {
                    route: 'Offers',
                    err: error.response.data,
                });
            });
    },

    offersDeatils({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/offers/${payload.id}`,
        }).then((response) => {
            if (payload.type == 'modal') {
                commit('setOffersModal', response.data.item);
            } else {
                commit('setOffersDeatils', response.data.item);
                commit('setDetailsIndex', response.data.item.index);
                commit('setDetailsMenager', response.data.item.menager);
                commit('setDetailsStatus', response.data.item.status);
                commit('setDetailsStatuses', response.data.statuses);
                if (payload.next) {
                    payload.next();
                }
            }
        });
    },

    fetchContacts({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/contacts/search/${payload}`,
        })
            .then((response) => {
                commit('setClientItems', response.data.items);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    updateOffers({ commit }, payload) {
        Vue.axios({
            method: 'PUT',
            url: `/offers/edit/${payload}`,
            data: {
                ...state.modal.fields,
            },
        })
            .then((response) => {
                commit('setOffersDeatils', response.data.item);
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie zmodyfikowano ofertę',
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateImplementationOffers({ commit, dispatch }, payload) {
        Vue.axios({
            method: 'PUT',
            url: `/offers/${payload}/implementation`,
            data: state.implementation,
        })
            .then((response) => {
                // commit('setOffersImplementation', response.data.item);
                dispatch('getOffersImplementation', {
                    id: payload,
                    type: 'details',
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie zmodyfikowano wdrożenie',
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    getOffersImplementation({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/offers/${payload.id}/implementation`,
        })
            .then((response) => {
                if (payload.type == 'details') {
                    state.implementation.details = {};
                    commit('setOffersImplementation', response.data);
                }
                if (payload.type == 'modal') {
                    commit('setOffersImplementationModal', response.data);
                }
            })
            .catch((error) => console.log(error));
    },
};

const mutations = {
    resetOffersItems() {
        state.clientItems = [];
        state.clientRepresentative = [];
    },
    setOffersDeatils(state, payload) {
        state.details = payload;
    },
    setOffersModal(state, payload) {
        state.modal.fields = payload;
        state.clientItems = [payload.contractorOffers];
        state.clientRepresentative = payload.contractorOffers.representatives;
    },

    setClientItems(state, payload) {
        if (payload[0] != null) {
            state.clientItems = payload;
        } else {
            state.clientItems = [];
        }
    },

    setClientThings(state, payload) {
        state.clientRepresentative = payload.representatives;
    },
    setOffersImplementation(state, payload) {
        console.log(payload);
        state.implementation.details = payload;
    },
    setOffersImplementationModal(state, payload) {
        console.log(payload);
        state.implementation.turnedOn = payload.implementation.turnedOn;
        state.implementation.implementationDate = payload.implementation
            .implementationDate
            ? moment(payload.implementation.implementationDate).format(
                  'YYYY-MM-DD'
              )
            : null;
        state.implementation.implementationTime =
            payload.implementation.implementationTime;
        state.implementation.basic = payload.implementation.basic;
        state.implementation.description = payload.implementation.description;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
