<template>
    <div>
        <v-tooltip
            left
            v-if="
                $route.name != 'resources_details' && getFolderNameValue !== ''
            "
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    v-if="getPermission.level >= 2"
                    small
                    :style="
                        ` width: ${
                            window.width < 1200 ? '50px' : '40px'
                        } ; height: ${window.width < 1200 ? '50px' : '40px'}`
                    "
                    class="buttons--add ml-2"
                    @click="addFromResources()"
                >
                    <v-icon small>mdi-animation</v-icon>
                </v-btn>
            </template>
            <span>Dodaj z zasobów</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    v-if="getPermission.level >= 2"
                    small
                    :style="
                        ` width: ${
                            window.width < 1200 ? '50px' : '40px'
                        } ; height: ${window.width < 1200 ? '50px' : '40px'}`
                    "
                    class="buttons--add ml-2"
                    @click="addFromStorage()"
                >
                    <v-icon small>mdi-safe-square-outline</v-icon>
                </v-btn>
            </template>
            <span>Dodaj ze schowka</span>
        </v-tooltip>
        <v-tooltip
            v-if="
                $route.name != 'templates_details' && getFolderNameValue !== ''
            "
            left
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    v-if="getPermission.level >= 2"
                    small
                    :style="
                        ` width: ${
                            window.width < 1200 ? '50px' : '40px'
                        } ; height: ${window.width < 1200 ? '50px' : '40px'}`
                    "
                    class="buttons--add ml-2"
                    @click="addFromTemplate()"
                >
                    <v-icon small>mdi-file-table-box-multiple</v-icon>
                </v-btn>
            </template>
            <span>Dodaj z szablonu</span>
        </v-tooltip>
        <v-tooltip v-if="$route.name != 'templates_details'" left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    :style="
                        ` width: ${
                            window.width < 1200 ? '50px' : '40px'
                        } ; height: ${window.width < 1200 ? '50px' : '40px'}`
                    "
                    dark
                    v-on="on"
                    v-if="getPermission.level >= 2"
                    small
                    class="buttons--add ml-2"
                    @click="addAttachment('false'), clear()"
                >
                    <v-icon small>mdi-folder-plus</v-icon>
                </v-btn>
            </template>
            <span>Dodaj nowy folder</span>
        </v-tooltip>
        <!-- {{getFolderNameValue}} -->
        <v-tooltip
            left
            v-if="getPermission.level >= 2 && getFolderNameValue !== ''"
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    :style="
                        ` width: ${
                            window.width < 1200 ? '50px' : '40px'
                        } ; height: ${window.width < 1200 ? '50px' : '40px'}`
                    "
                    fab
                    dark
                    v-on="on"
                    small
                    class="buttons--add ml-2"
                    @click="addAttachment('true')"
                >
                    <v-icon small>mdi-paperclip</v-icon>
                </v-btn>
                <!-- // resetowanie state -->
            </template>
            <span>Dodaj nowy załącznik</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    data: () => ({
        window: {
            width: 0,
            height: 0,
        },
    }),
    computed: {
        ...mapGetters([
            'getAddFolder',
            'getPermission',
            'getFolderNameValue',
            'getAttachmentsFields',
        ]),
    },
    methods: {
        ...mapActions([
            'openModal',
            'AddNewFolder',
            'fetchResources',
            'getStorage',
        ]),
        ...mapMutations(['setclearFolderName']),
        clear() {
            this.setclearFolderName();
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        addAttachment(item) {
            this.openModal({
                headLine: 'global.create',
                open: true,
                type: 'create',
                width: 600,
                route: 'attachments',
                tab: 'attachment',
                global: 'attachments',
                addFolder: item,
            });

            this.$route.name == 'service_details'
                ? (this.getAttachmentsFields.status = 'Aktualny')
                : (this.getAttachmentsFields.status = 'Draft');
        },
        addFromTemplate() {
            this.openModal({
                headLine: 'global.create',
                open: true,
                width: 600,
                route: 'Templates',
                tab: 'templates',
            });
            this.getAttachmentsFields.status = 'Draft';
        },
        addFromResources() {
            this.fetchResources();
            this.openModal({
                headLine: 'global.create',
                open: true,
                type: 'create',
                width: 600,
                route: 'resources',
                tab: 'shares',
                global: 'shares',
            });
        },
        addFromStorage() {
            this.openModal({
                headLine: 'global.create',
                open: true,
                type: 'create',
                width: 600,
                route: 'resources',
                tab: 'attachmentFromStorage',
                global: 'storage',
            });
        },
    },
    created() {
        this.handleResize();
    },
};
</script>
