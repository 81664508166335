<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" class="pt-0">
                <v-text-field
                    dense
                    label="Model"
                    v-model="getModelsFields.model"
                    :error-messages="getModelsErrors.model"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
                <!-- <v-select
                    :items="getSeriesItems"
                    dense
                    label="Seria modeli"
                    v-model="getModelsFields.seriesName"
                    item-text="seriesName"
                    item-value="seriesName"
                ></v-select>-->
                <!-- {{getSeriesItems}} -->
                <v-autocomplete
                    v-model="getModelsFields.series"
                    @keyup="debounceSeriesModel"
                    hint="Zacznij pisać..."
                    hide-no-data
                    dense
                    :items="getSeriesItems"
                    label="Seria modeli"
                    item-text="seriesName"
                    item-value="_id"
                ></v-autocomplete>
            </v-col>
            <v-col class="d-flex" cols="12" md="6">
                <v-select
                    dense
                    :items="getManufacturerBrandItems"
                    label="Producent"
                    v-model="getModelsFields.producer"
                    :error-messages="getModelsErrors.producer"
                    item-value="name"
                    item-text="name"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.name}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="getServiceTypeItems"
                    dense
                    label="Rodzaj urządzenia"
                    v-model="getModelsFields.deviceType"
                    :error-messages="getModelsErrors.deviceType"
                    item-value="name"
                    item-text="name"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.name}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="getSPriceItems"
                    dense
                    label="Pakiet cenowy"
                    v-model="getModelsFields.price"
                    :error-messages="getModelsErrors.price"
                    item-value="_id"
                    item-text="priceName"
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.priceName}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    dense
                    :items="['1', '2', '3','4','5','6','7','8','9','10']"
                    v-model="getModelsFields.difficulty"
                    label="Wskaźnik kompetencji"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-autocomplete
                    dense
                    :items="getOptionNameItems"
                    v-model="getModelsFields.options"
                    label="Opcje"
                    item-value="optionName"
                    item-text="optionName"
                    multiple
                >
                    <template v-slot:item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{data.item.optionName}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
                <v-textarea label="Opis dodatkowy" v-model="getModelsFields.description"></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";
import debounce from "lodash.debounce";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["searchSeriesModel"]),
        debounceSeriesModel: debounce(function(e) {
            if (e.target.value.length >= 3) {
                this.searchSeriesModel(e.target.value);
            }
        }, 500)
    },

    computed: {
        ...mapGetters([
            "getModelsFields",
            "getModelsErrors",
            "getManufacturerBrandItems",
            "getServiceTypeItems",
            "getOptionNameItems",
            "getSPriceItems",
            "getSeriesItems"
        ])
    }
};
</script>