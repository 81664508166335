
<template>
    <v-dialog width="500"  @click:outside="closeModalRaport()">
        <v-card>
            <div class="AE__header">
                <h1 class="font-weight-light AE__header__title py-3 pl-5">Zmiana hasła</h1>
                <v-btn class="AE__header__close" color="white" small icon >
                    <v-icon medium>mdi-close</v-icon>
                </v-btn>
            </div>
             <v-container class="p-relative">
                <v-row>

                </v-row>
             </v-container>
            <v-footer class="password__footer">
                <v-spacer></v-spacer>
                <div>
                    <v-btn @click="closePassword" class="mr-3 buttons">Anuluj</v-btn>
                    <v-btn
                        dark
                        @click="tryChangePassword"
                        class="buttons buttons--add password__footer__btn"
                    >Zmień hasło</v-btn>
                </div>
            </v-footer>


        </v-card>
    </v-dialog>
</template>


<script>
/////import Separator from "@/components/Global/Separator.vue";
//import { mapGetters, mapActions, mapMutations } from "vuex";
//import debounce from "lodash.debounce";
import Header from "@/components/Global/Modal/ModalHeader.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    components: {
    },
    computed: {
        ...mapGetters([

        ])
    },
    methods: {
        ...mapActions(["fetchContacts"]),
        ...mapMutations(["closeModalRaport"]),

    }
};
</script>