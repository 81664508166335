<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab small dark class="buttons--add" @click="openModal">
                <v-icon size="18">mdi-account-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj reprezentanta</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"])
    },
    methods: {
        openModal() {
            this.$store.commit("resetModal", "representatives");
            this.$store.commit("setModal", {
                headLine: "global.create",
                open: true,
                width: 600,
                type: "create",
                route: "representatives",
                tab: "contacts_representatives"
            });
        }
    }
};
</script>
