<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="9">
          <v-text-field
            readonly
            :value="getAccreditationDetails.title"
            :label="getAccreditationFieldsNames.title"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            readonly
            :value="getAccreditationDetails.archiveDate ? correctData(getAccreditationDetails.archiveDate) : 'Brak'"
            :label="getAccreditationFieldsNames.archiveDate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="12">
          <v-textarea
            readonly
            :value="getAccreditationDetails.information"
            :label="getAccreditationFieldsNames.information"
            no-resize
            rows="6"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field readonly :value="responsiblePerson()" label="Osoby odpowiedzialne"></v-text-field>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    menu2: false,
    person: [],
  }),
  computed: {
    ...mapGetters([
      "getAccreditationDetails",
      "getAccreditationFieldsNames",
      "getAccreditationError",
    ]),
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },

    responsiblePerson() {
      let person = "";
      for (
        let i = 0;
        i < this.getAccreditationDetails.responsiblePerson.length;
        i++
      ) {
        person += `${this.getAccreditationDetails.responsiblePerson[i].name} ${this.getAccreditationDetails.responsiblePerson[i].lastname}, `;
      }
      return person;
    },
  },
  components: {},
};
</script>