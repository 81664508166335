<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="7" class="d-flex flex-wrap p-relative pb-0">
        <v-col cols="12" md="12" class="d-flex flex-wrap py-0">
          <v-col cols="12" md="12" class="pt-0">
            <Separator :text="'Rozliczenie czasu, kosztów wykonanej usługi'" />
          </v-col>
          <v-col cols="12" md="12" class="d-flex flex-wrap py-0">
            <v-col cols="12" md="4" class="pa-0">
              <v-text-field
                dense
                readonly
                :value="`${getServiceImplement.details.technic.name} ${getServiceImplement.details.technic.lastname}`"
                class="no-pointer"
                label="Imię i nazwisko technika"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                dense
                :value="getServiceImplement.details.plate"
                readonly
                class="no-pointer"
                label="Nr rej samochodu"
              ></v-text-field>
            </v-col>
          </v-col>

          <div
            class="d-flex flex-wrap mt-4 mb-3"
            style="background-color: #f6f7e6"
          >
            <v-col
              cols="12"
              md="4"
              lg="2"
              class="d-flex align-center flex-wrap pt-5 pb-1"
            >
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Przegląd okr.</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  label="Czas"
                  :value="getServiceImplement.details.job.survey.time"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="
                    getServiceImplement.details.job.dedicated
                      ? getServiceImplement.details.job.survey.price.special
                      : getServiceImplement.details.job.survey.price.normal
                  "
                  label="PLN"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="2"
              class="d-flex align-center flex-wrap pt-5 pb-1"
            >
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Naprawa</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  label="Czas"
                  :value="getServiceImplement.details.job.repair.time"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="
                    getServiceImplement.details.job.dedicated
                      ? getServiceImplement.details.job.repair.price.special
                      : getServiceImplement.details.job.repair.price.normal
                  "
                  label="PLN"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" md="4" lg="2" class="d-flex flex-wrap pt-5 pb-1">
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Wdrożenie</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="getServiceImplement.details.job.installation.time"
                  label="Czas"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="
                    getServiceImplement.details.job.dedicated
                      ? getServiceImplement.details.job.installation.price
                          .special
                      : getServiceImplement.details.job.installation.price
                          .normal
                  "
                  label="PLN"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" md="4" lg="2" class="d-flex flex-wrap pt-5 pb-1">
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Diagnostyka</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="getServiceImplement.details.job.diagnostics.time"
                  label="Czas"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="
                    getServiceImplement.details.job.dedicated
                      ? getServiceImplement.details.job.diagnostics.price
                          .special
                      : getServiceImplement.details.job.diagnostics.price.normal
                  "
                  label="PLN"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" md="4" lg="2" class="d-flex flex-wrap pt-5 pb-1">
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Usługa IT</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="getServiceImplement.details.job.itService.time"
                  label="Czas"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="
                    getServiceImplement.details.job.dedicated
                      ? getServiceImplement.details.job.itService.price.special
                      : getServiceImplement.details.job.itService.price.normal
                  "
                  label="PLN"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="2"
              class="d-flex flex-wrap pt-5 pb-1"
              style="background-color: #eaecd1"
            >
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Razem</span>
              </v-col>
              <v-col cols="12" md="6" class="pb-1">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="timeSumarry"
                  label="Czas"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-3">
                <v-text-field
                  dense
                  readonly
                  class="no-pointer"
                  :value="moneySumarry"
                  label="PLN"
                ></v-text-field>
              </v-col>
            </v-col>
          </div>
        </v-col>

        <v-col cols="12" md="12" class="d-flex flex-wrap pt-1">
          <v-col cols="12" md="12" class="pb-0">
            <Separator :text="'Liczniki'" />
          </v-col>

          <v-col cols="12" md="6" class="d-flex flex-wrap pa-0">
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Licznik urządzenia mono</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.mono.start"
                label="Początkowy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.mono.end"
                label="Końcowy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="company != 'Biuromax-Balcer'">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.scan"
                label="Skany"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-wrap pa-0">
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Licznik urządzenia kolor</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.color.start"
                label="Początkowy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.color.end"
                label="Końcowy"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col
            cols="12"
            md="9"
            class="d-flex flex-wrap pa-0"
            style="margin-left: auto"
          >
            <v-col cols="12" md="12" class="py-0">
              <span class="grey--text">Licznik samochodowy (km)</span>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.car.start"
                label="Początkowy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.car.end"
                label="Końcowy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.car.sum"
                label="Suma"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <!-- <v-text-field
                dense
                readonly
                class="no-pointer"
                :value="getServiceImplement.details.meter.car.cost"
                label="Koszt transportu"
              ></v-text-field>-->
              <div class="input-effect price" style="text-align: center">
                <span style="text-align: left; margin-top: -10px"
                  >Koszt transportu (netto)</span
                >
                <money
                  style="
                    border-bottom: 1px solid #8e8e8e !important;
                    color: #9f9f9f;
                  "
                  class="effect-17"
                  v-model="getServiceImplement.details.meter.car.cost"
                  v-bind="cost"
                  disabled
                ></money>
                <span class="focus-border"></span>
              </div>
            </v-col>
          </v-col>
        </v-col>

        <v-col cols="12" md="12" class="d-flex flex-wrap py-0">
          <v-col cols="12" md="12" class="py-0">
            <Separator :text="'Rozliczenie interwencji'" />
          </v-col>
          <!-- <v-col cols="12" md="2">
                        <v-text-field
                            dense
                            readonly
                            class="no-pointer"
                            :value="getServiceImplement.details.clearance.documentNumber"
                            label="Nr dokumentu"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            dense
                            readonly
                            class="no-pointer"
                            label="Wartość części do prowizji"
                            :value="getServiceImplement.details.clearance.partValue"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            dense
                            readonly
                            class="no-pointer"
                            :value="`${getServiceImplement.details.clearance.technic.name} ${getServiceImplement.details.clearance.technic.lastname}`"
                            label="Dotyczy technika"
                        ></v-text-field>
          </v-col>-->
          <div class="d-flex flex-wrap">
            <v-col cols="12" md="6" class="d-flex flex-wrap pa-0">
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Rozliczenie wewnętrzne</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  :ripple="ripp"
                  class="no-pointer"
                  dense
                  :value="
                    getServiceImplement.details.clearance.inside.date
                      ? $moment(
                          getServiceImplement.details.clearance.inside.date
                        ).format('YYYY-MM-DD')
                      : 'Brak'
                  "
                  label="Data"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  :ripple="ripp"
                  class="no-pointer"
                  dense
                  :value="getServiceImplement.details.clearance.inside.number"
                  label="Numer dok."
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-wrap pa-0">
              <v-col cols="12" md="12" class="py-0">
                <span class="grey--text">Rozliczenie zewnętrzne</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  :ripple="ripp"
                  class="no-pointer"
                  dense
                  :value="
                    getServiceImplement.details.clearance.outside.date
                      ? $moment(
                          getServiceImplement.details.clearance.outside.date
                        ).format('YYYY-MM-DD')
                      : 'Brak'
                  "
                  label="Data"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  readonly
                  :ripple="ripp"
                  class="no-pointer"
                  dense
                  :value="getServiceImplement.details.clearance.outside.number"
                  label="Numer dok."
                ></v-text-field>
              </v-col>
            </v-col>
          </div>
        </v-col>
        <v-divider
          style="position: absolute; top: 10px; right: 0px"
          vertical
        ></v-divider>
      </v-col>
      <v-col cols="12" md="12" lg="5" class="d-flex flex-wrap">
        <v-col cols="12" md="5" class="d-flex align-center py-1 flex-wrap">
          <!-- {{getServiceImplement.details.signature.img}} -->
          <!-- {{getServiceImplement.details.signature.img}} -->
          <!-- <img :src="getServiceImplement.details.signature.img" /> -->
          <span>Wymagana:</span>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              class="no-pointer ma-0 pa-0"
              v-model="getServiceImplement.details.requirements.continuation"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Kontynuacja</span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              class="no-pointer ma-0 pa-0"
              v-model="
                getServiceImplement.details.requirements.valuation.checkbox
              "
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Wycena</span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.requirements.itService"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Usługa IT</span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.requirements.claim.checkbox"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Claim</span>
            <span class="green--text ml-1 service__checkbox">
              <b>{{
                getServiceImplement.details.requirements.claim.number
                  ? getServiceImplement.details.requirements.claim.number
                  : ""
              }}</b>
            </span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.requirements.replacement"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Urządzenie zastępcze</span
            >
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0 mt-5">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="
                getServiceImplement.details.requirements.invoice.checkbox
              "
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Faktura</span>
          </v-col>
        </v-col>
        <v-col
          cols="12"
          md="7"
          style="max-height: 170px"
          class="d-flex align-center py-1 flex-wrap"
        >
          <span>Uwagi:</span>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.comment.repairWorkshop"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Naprawa warsztatowa</span
            >
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.comment.remoteService"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox">Usługa zdalna</span>
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.comment.irrational"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Usługa nieuzasadniona</span
            >
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.comment.machineWorks"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Klient stwierdza poprawność pracy urzadzenia po wykonanej
              usłudze</span
            >
          </v-col>
          <v-col cols="12" md="12" class="d-flex align-center py-1 px-0">
            <v-checkbox
              hide-details
              readonly
              :ripple="ripp"
              v-model="getServiceImplement.details.comment.vat"
              class="no-pointer ma-0 pa-0"
            ></v-checkbox>
            <span class="grey--text ml-1 service__checkbox"
              >Klient został poinformowany o kosztach</span
            >
          </v-col>
        </v-col>

        <v-col cols="12" md="12">
          <v-textarea
            label="Zakres wykonanej interwencji"
            readonly
            class="no-pointer"
            :value="getServiceImplement.details.description.doneJob"
            no-resize
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            label="Uwagi technika do kolejnej interwencji"
            readonly
            class="no-pointer"
            :value="getServiceImplement.details.description.nextIntervention"
            no-resize
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  data: () => ({
    ripp: false,
    cost: {
      decimal: ",",
      thousands: ",",
      suffix: " PLN",
      precision: 2,
      company: `${process.env.VUE_APP_FIRM}`,
      masked: false,
    },
  }),
  components: {
    Separator,
  },
  computed: {
    ...mapGetters(["getServiceImplement"]),
    timeSumarry() {
      let sum = 0;
      Object.keys(this.getServiceImplement.details.job).forEach((item) => {
        if (item !== "dedicated")
          sum += this.getServiceImplement.details.job[item].time;
      });
      return Math.round((sum + Number.EPSILON) * 100) / 100;
    },
    moneySumarry() {
      let sum = 0;
      let priceTyp = this.getServiceImplement.details.job.dedicated
        ? "special"
        : "normal";
      Object.keys(this.getServiceImplement.details.job).forEach((item) => {
        if (item !== "dedicated")
          sum += this.getServiceImplement.details.job[item].price[priceTyp];
      });
      return Math.round((sum + Number.EPSILON) * 100) / 100;
    },
  },
};
</script>