<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="editInformation()">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions([
            "openModal",
            "internalContactsDeatils",
            "fetchDepartments",
            "fetchJobDictionary",
            "fetchPositionItems"
        ]),
        editInformation() {
            this.fetchPositionItems(this.$route.name);
            this.internalContactsDeatils({
                id: this.$route.params.id,
                type: "modal",
                route: this.$route.name
            });
            this.openModal({
                headLine: "global.create",
                open: true,
                type: "edit",
                width: 600,
                route: "internalContacts",
                tab: "internal-contacts"
            }).then(() => {
                this.fetchDepartments();
                this.fetchJobDictionary();
            });
        }
    }
};
</script>