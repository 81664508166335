<template>
  <v-tooltip left v-if="getPermission.level > 1">
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        dark
        v-on="on"
        small
        class="buttons--add"
        @click="createInformation"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    </template>
    <span>Dodaj nową informację</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vm from "./../../Global/Modal/Modal.vue";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["getPermission"]),
  },
  methods: {
    ...mapActions([
      "openModal",
      //"fetchModels", 'fetchSeries'
    ]),
    createInformation() {
      this.openModal({
        headLine: "global.create",
        width: 600,
        open: true,
        type: "create",
        route: "currentInformations",
        tab: "current-informations",
      });
    },
  },
};
</script>

