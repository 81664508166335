var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,style:(item.status &&
      item.status.possibleStatus &&
      item.status.possibleStatus.resolved
        ? 'color: #9e9e9e'
        : '')},[_c('td',{staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(item.startDate ? _vm.$moment(item.startDate).format('YYYY-MM-DD HH:mm') : 'Brak')+" ")]),_c('td',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(item.title ? item.title : 'Brak')+" ")]),(_vm.getRegistryHeader.status)?_c('td',[_c('v-chip',{style:(("color: " + (item.status &&
            item.status.possibleStatus &&
            item.status.possibleStatus.color &&
            item.status.possibleStatus.color.font
              ? item.status.possibleStatus.color.font
              : '#FFFFFF'))),attrs:{"hidden":item.status && !item.status.possibleStatus,"color":item.status &&
          item.status.possibleStatus &&
          item.status.possibleStatus.color &&
          item.status.possibleStatus.color.background
            ? item.status.possibleStatus.color.background
            : '#555555',"dark":"","label":"","small":""}},[_vm._v(_vm._s(item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak'))])],1):_vm._e(),_c('td',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.lastname)+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[(item.folders)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"width":"25px","height":"25px"},attrs:{"fab":"","dark":"","color":"#19aa8d","target":"_blank"},on:{"click":function($event){return _vm.checkFile(item)}}},on),[_c('v-icon',{attrs:{"size":"12","color":"white"}},[_vm._v("mdi-file-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Podgląd")])]):_vm._e()],1),(_vm.getRegistryHeader.action)?_c('td',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ path: ("/current-informations/" + (item._id)) })}}},[_c('v-icon',{attrs:{"color":"#19aa8d","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }