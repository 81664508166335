<template>
    <v-dialog
        v-model="getSignature.modal"
        persistent
        @input="v => v || clearSignature()"
        width="600px"
    >
        <v-card>
            <div class="AE__header">
                <p class="font-weight-light AE__header__title py-3 pl-5">Potwierdzenie</p>
                <v-btn
                    class="AE__header__close"
                    color="white"
                    small
                    icon
                    @click="(getSignature.modal = false), clearSignature()"
                >
                    <v-icon color="#a1a1a1" size="15" medium>mdi-close</v-icon>
                </v-btn>
            </div>
            <p class="px-4 pt-5">Stwierdzam poprawność pracy urządzenia po wykonanej usłudze:</p>
            <div id="app" class="ma-4 d-flex justify-center">
                <VueSignaturePad
                    style="border: 1px solid red"
                    :options="{  minWidth: 5, }"
                    saveType="image/png"
                    width="600px"
                    height="300px"
                    ref="signaturePad"
                />
            </div>

            <v-footer class="AE__footer" color="#d9d9d9">
                <v-btn class="buttons mr-3 mt-0" @click="clearSignature">Wyczyść</v-btn>
                <v-btn dark class="buttons buttons--add mr-3" @click="save()">Zapisz</v-btn>
            </v-footer>
        </v-card>
    </v-dialog>
</template>
<script>
import VueSignaturePad from "vue-signature-pad";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Vue from "vue";
export default {
    effect: null,
    methods: {
        ...mapMutations([
            "printOrder",
            "setServicePartsDetails",
            "setSnackbars",
        ]),
        ...mapActions(["editServiceImplementation"]),

        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        save() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            Vue.axios({
                method: "POST",
                url: `/service/signature/${this.getServiceImplement.details._id}`,
                data: {
                    signature: data,
                    id: this.getServiceImplement.details._id,
                },
            }).then((response) => {
                this.setSnackbars({
                    type: "success",
                    text: "Podpis został przyjęty",
                });
            });
            this.clearSignature();
            this.getSignature.modal = false;
        },
    },

    computed: {
        ...mapGetters(["getSignature", "getServiceImplement"]),
    },
};
</script>
