<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" class="pt-0">
        <v-text-field
          dense
          v-model="getAccreditationModal.title"
          :label="getAccreditationFieldsNames.title"
          :error-messages="getAccreditationError.title"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <v-menu
          v-model="modaldata"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getAccreditationModal.archiveDate"
              dense
              clearable
              :label="getAccreditationFieldsNames.archiveDate"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="getAccreditationModal.archiveDate"
            @input="modaldata = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-select
          v-if="getModal.type == 'edit'"
          dense
          :items="getPositionItems"
          item-text="title"
          item-value="position"
          label="Ustaw za"
          v-model="getAccreditationModal.changePosition"
        ></v-select>
      </v-col>

      <v-col cols="12" md="12">
        <v-textarea
          :label="getAccreditationFieldsNames.information"
          v-model="getAccreditationModal.information"
        ></v-textarea>
      </v-col>
      <v-autocomplete
        v-model="getAccreditationModal.responsiblePerson"
        label="Osoba odpowiedzialna"
        chips
        :items="getPossibleMenagersItems"
        multiple
        item-value="_id"
        :filter="filter"
        :loading="getPossibleMenagersItems.loading"
        :hide-no-data="getPossibleMenagersItems.hideNoData"
      >
        <template v-slot:selection="data">
          <v-chip
            pill
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
            close
            @click:close="removePerson(data.item._id)"
          >
            <v-avatar left color="secondary" class="mr-2">
              <span v-if="!data.item.picture" class="white--text caption">
                {{
                avatarNick(
                data.item.name,
                data.item.lastname
                )
                }}
              </span>
              <img
                :src="
                                    `${getGlobalPaths.profilePic}${data.item.picture}`
                                "
                v-else
                style="object-fit: cover"
              />
            </v-avatar>
            {{ data.item.lastname }} {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-avatar color="secondary">
              <span v-if="!data.item.picture" class="white--text headline">
                {{
                avatarNick(
                data.item.name,
                data.item.lastname
                )
                }}
              </span>
              <img
                :src="
                                    `${getGlobalPaths.profilePic}${data.item.picture}`
                                "
                v-else
                style="object-fit: cover"
              />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.lastname }}
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
              data.item.job
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data: () => ({
    modaldata: false,
    ACReader: "users",
  }),
  computed: {
    ...mapGetters([
      "getAccreditationModal",
      "getPossibleMenagersItems",
      "getAccreditationFieldsNames",
      "getGlobalPaths",
      "getAccreditationError",
      "getPositionItems",
      "getModal",
    ]),
  },
  methods: {
    ...mapActions([""]),
    ...mapMutations(["removeUserAccreditation"]),
    filter(item, queryText, itemText) {
      return (
        item.name.toLowerCase().includes(queryText.toLowerCase()) ||
        item.lastname.toLowerCase().includes(queryText.toLowerCase())
      );
    },
    avatarNick(name, lastname) {
      let nick = name[0] + lastname[0];
      return nick.toUpperCase();
    },
    removePerson(payload) {
      this.removeUserAccreditation(payload);
    },
  },
};
</script>
