import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';
import findIndex from 'lodash.findindex';

const state = {
	modalBenefitsValue: false,
	BenefitsValue: {
		title: 'WARTOŚĆ PUNKTOWA ŚWIADCZEŃ',
		header: [
			// {
			// 	text: 'Data utworzenia',
			// 	align: 'left',
			// 	value: 'createdAt',
			// 	class: 'central__table__header',
			// 	width: '20%',
			// },
			{
				text: 'Nazwa',
				value: 'title',
				class: 'central__table__header',
				width: '75%',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '15%',
			},
			// {
			// 	text: 'Menager',
			// 	value: 'menager',
			// 	class: 'central__table__header',
			// 	width: '20%',
			// },
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
				width: '10%',
			},
		],
	},
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
			responsiblePerson: [],
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
			responsiblePerson: [],
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
			responsiblePerson: [],
		},
	},
	fieldsNames: {
		title: 'Nazwa',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		responsiblePerson: 'Osoba odpowiedzialna',
	},
};

const getters = {
	getBenefitsValue: (state) => state.BenefitsValue,
	getBenefitsValueModal: (state) => state.modal.fields,
	getBenefitsValueError: (state) => state.modal.errors,
	getBenefitsValueDetails: (state) => state.modal.details,
	getBenefitsValueFieldsNames: (state) => state.fieldsNames,
	getBenefitsValueResponsible: (state) => state.modal.details.responsiblePerson,
};

const actions = {
	createBenefitsValue({ dispatch, state, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/benefits-value',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then(() => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'benefits-value',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano WPŚ',
				});
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response.data);
				commit('setErrors', {
					route: 'BenefitsValue',
					err: error.response.data.error,
				});
			});
	},

	updateBenefitsValue({ state, commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/benefits-value/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setBenefitsValueDeatils', response.data.item);
				commit('closeModal');
				commit('setBenefitsValueModal', {});
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano WPŚ',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'BenefitsValue',
					err: error.response.data.error,
				});
			});
	},

	benefitsValueDetails({ state, commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/benefits-value/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setBenefitsValueModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setBenefitsValueDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'BenefitsValue',
					err: error.response.data.error,
				});
			});
	},
};

const mutations = {
	setBenefitsValueDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setBenefitsValueModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	removeUserBenefitsValue(state, payload) {
		let arr = state.modal.fields.responsiblePerson;
		let index = findIndex(arr, (e) => e == payload);
		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
