<template>
  <v-col cols="12" md="12" lg="6" class="d-flex flex-wrap">
    <v-col cols="12" md="12">
      <Separator :text="'Dane kontaktu'" />
    </v-col>

    <v-col cols="12" md="5">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.name"
        v-model="getContactsDetailsFields.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.shortName"
        v-model="getContactsDetailsFields.shortName"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.contactId"
        v-model="getContactsDetailsFields.contactId"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.NIP"
        v-if="getContactsDetailsFields.contactType=='Osoba prawna'"
        v-model="getContactsDetailsFields.NIP"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <Separator :text="'Dane szczegółowe'" />
    </v-col>

    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.street"
        v-model="getContactsDetailsFields.street"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.city"
        v-model="getContactsDetailsFields.city"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.houseNumber"
        v-model="getContactsDetailsFields.houseNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.apartmentNumber"
        v-model="getContactsDetailsFields.apartmentNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.post"
        v-model="getContactsDetailsFields.post"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.postCode"
        v-model="getContactsDetailsFields.postCode"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.state"
        v-model="getContactsDetailsFields.state"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.email"
        v-model="getContactsDetailsFields.email"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.phoneNumber"
        v-model="getContactsDetailsFields.phoneNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        readonly
        v-bind:label="getContactFieldsNames.website"
        v-model="getContactsDetailsFields.website"
      ></v-text-field>
    </v-col>
    <Separator :text="'Rodzaj'"></Separator>
    <v-col cols="12" md="6">
      <v-select
        readonly
        :items="select.type"
        v-bind:label="getContactFieldsNames.type"
        v-model="getContactsDetailsFields.type"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        readonly
        :items="select.kind"
        v-bind:label="getContactFieldsNames.contactType"
        v-model="getContactsDetailsFields.contactType"
      ></v-select>
    </v-col>
    <!-- <div class="central__actions">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab small dark class="buttons--add" @click="openEdit">
                        <v-icon>mdi-file-document-edit</v-icon>
                    </v-btn>
                </template>
                <span>Edytuj kontakt</span>
            </v-tooltip>
    </div>-->
  </v-col>
</template>

<script>
import Vue from "vue";
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
var clone = require("lodash.clone");
export default {
  components: {
    Separator
  },
  data: () => ({
    select: {
      type: ["Wszyscy", "Kontrahenci", "Klienci"],
      kind: ["Osoba prawna", "Osoba fizyczna"]
    },
    fields: {}
  }),
  computed: {
    ...mapGetters([
      "getContactsData",
      "getContactsErrors",
      "getContactsDetailsFields",
      "getContactFieldsNames"
    ])
  },
  methods: {
    ...mapActions(["fetchItems", "contactDetails", "setContactsModalFields"])
    // openEdit() {
    //     this.$store.commit("setModal", {
    //         headLine: "global.edit",
    //         open: true,
    //         width: 600,
    //         type: "edit",
    //         route: "contacts"
    //     });
    // }
  }
  // beforeMount() {
  //     this.contactDetails(this.$route.params.id);
  // }
};
</script>