<template>
  <v-container>
    <v-row>
        <v-col cols="12" md="12" lg="6" class="d-flex flex-wrap py-0 ma-0">
            Add IT
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";


export default {

  computed: {
    ...mapGetters([
      "getModalLeasing",
      "getLeasingFieldsNames",
      "getLeasingItems",
      "getLeasingRepresentatives",
    ])
  },
    methods: {
     ...mapActions(["fetchContacts"]),
     ...mapMutations(["closeModal", "setClientThings", "setClientItems"]),
    findContacts: debounce(function(e) {
      this.fetchContacts(e.target.value);
    }, 500),
  },

};

</script>