
<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="p-relative" md="12">
                <v-data-table
                    :headers="getTasks.header"
                    :items="getTable.items"
                    :mobile-breakpoint="1100"
                    class="central__table"
                    hide-default-footer
                    :options.sync="options"
                    :items-per-page="11"
                >
                    <!-- === === FROM === === -->
                    <template v-slot:item.createdBy="{ item }">
                        <span small>{{item.createdBy.name}} {{item.createdBy.lastname}}</span>
                    </template>

                    <!-- === NAME ===  -->
                    <template v-slot:item.name="{ item }">
                        <v-list-item-title
                            style="font-size: 14px !important"
                            :style="item.isDone === true ? 'color: #9e9e9e' : ''"
                        >{{item.name}}</v-list-item-title>
                        <v-list-item-subtitle
                            class="grey--text lighten-1"
                            v-if="item.description"
                        >{{shortDescription(item.description, 100)}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="grey--text lighten-1" v-else>Brak</v-list-item-subtitle>
                    </template>

                    <!-- === === DATE === ===  -->
                    <template v-slot:item.termDate="{ item }">
                        <div v-if="item.termDate">
                            <div v-if="item.termTime">
                                <v-list-item-title
                                    style="font-size: 14px !important"
                                    :style="item.isDone === true ? 'color: #9e9e9e' : ''"
                                >{{dataCorrect(item.termDate)}}</v-list-item-title>
                                <v-list-item-subtitle class="grey--text lighten-1">{{item.termTime}}</v-list-item-subtitle>
                            </div>
                            <div v-else-if="!item.termTime">
                                <v-list-item-title
                                    :style="item.isDone === true ? 'color: #9e9e9e' : ''"
                                >{{dataCorrect(item.termDate)}}</v-list-item-title>
                            </div>
                            <span v-else :style="item.isDone === true ? 'color: #9e9e9e' : ''">Brak</span>
                        </div>
                        <div :style="item.isDone === true ? 'color: #9e9e9e' : ''" v-else>Brak</div>
                    </template>

                    <!-- === === PRIORITY === === -->
                    <template v-slot:item.priorityTasks="{ item }">
                        <v-chip
                            :color="item.priority.background"
                            :style="`color: ${item.priority.color}`"
                            label
                            v-if="item.priority"
                            small
                        >{{ item.priority.priorityName }}</v-chip>
                        <v-chip dark v-else label small>Brak</v-chip>
                    </template>

                    <!-- === === CATEGORY === === -->
                    <template v-slot:item.category="{ item }">
                        <v-chip
                            :color="item.category.background"
                            :style="`color: ${item.category.color}`"
                            label
                            small
                            v-if="item.category"
                        >{{ item.category.name }}</v-chip>
                        <v-chip dark label small v-else>Brak</v-chip>
                    </template>

                    <!-- === === ACTIONS === === -->
                    <template v-slot:item.action="{ item }">
                        <v-btn icon @click="openDetails(item._id)">
                            <v-icon small>mdi-arrow-right-thick</v-icon>
                        </v-btn>
                        <!-- <v-btn
                            v-if="item.attachments && item.attachments.length > 0"
                            icon
                            color="#19aa8d"
                        >
                            <v-icon small>mdi-paperclip</v-icon>
                        </v-btn>
                        <v-btn v-else disabled icon>
                            <v-icon small>mdi-paperclip</v-icon>
                        </v-btn>-->
                    </template>
                </v-data-table>

                <!-- === === PAGINATION === === -->
                <div class="text-md-center pt-2">
                    <v-pagination
                        circle
                        :length="getTable.pages"
                        v-model="table.page"
                        @input="fetchTableItems"
                    ></v-pagination>
                </div>
            </v-col>
        </v-row>
        <!-- <Modal /> -->
    </v-container>
</template>




<script>
import { mapActions, mapGetters } from "vuex";
import truncate from "lodash.truncate";
import Buttons from "@/components/Global/Details/Tasks/ButtonsAE.vue";
export default {
    components: {
        Buttons
    },
    data: () => ({
        options: {},
        table: {
            page: 1
        }
    }),
    watch: {
        options: {
            handler() {
                this.fetchTableItems();
                return;
            }
        }
    },
    methods: {
        ...mapActions(["fetchData", "taskDetails"]),
        dataCorrect(date) {
            return this.$moment(date).format("YYYY-MM-DD");
        },
        shortDescription(description, length) {
            return truncate(description, { length });
        },
        fetchTableItems() {
            const { sortBy, sortDesc } = this.options;
            const { page } = this.table;

            this.fetchData({
                page: this.table.page,
                pageSize: 11,
                search: "",
                sortBy: sortBy || [],
                sortDesc: sortDesc || [],
                routeName: "tasks_tab",
                registryName: this.$route.name,
                registryID: this.$route.params.id
            });
        },
        openDetails(id) {
            this.taskDetails({
                id,
                registryName: this.$route.name
            });
            this.$store.commit("setModal", {
                headLine: "global.edit",
                open: true,
                width: 800,
                type: "details",
                tab: "tasks_devices"
            });
        }
    },
    computed: {
        ...mapGetters(["getTasks", "getTable"])
    }

    // beforeMount() {
    //     this.fetchTableItems();
    // }
};
</script>




































      
     
   

