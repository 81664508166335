<template>
    <div>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn fab dark v-on="on" small class="buttons--add mx-2" @click="showLogs">
                    <v-icon small>mdi-login-variant</v-icon>
                </v-btn>
            </template>
            <span>Raport logowań</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn fab dark v-on="on" small class="buttons--add" @click="banModal">
                    <v-icon small>mdi-account-cancel</v-icon>
                </v-btn>
            </template>
            <span>Zobacz zablokowanych użytkowników</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: { ...mapGetters(["getPreloader"]) },
    methods: {
        ...mapActions(["openModal", "fetchItems"]),
        banModal() {
            this.fetchItems("users");
            this.openModal({
                headLine: "global.edit",
                open: true,
                width: 1200,
                type: "",
                route: "devices",
                tab: "session_logs",
            });
        },
        showLogs() {
            this.getPreloader.active = true;
            let routeData = this.$router.resolve({
                name: "logs-details",
            });
            window.open(routeData.href, "_blank");
        },
    },
};
</script>

