<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" lg="6" class="d-flex flex-wrap">
                <v-col cols="12" md="12" lg="12">
                    <Separator :text="'Informacje podstawowe'" />
                </v-col>
                <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                        class="no-pointer"
                        readonly
                        v-model="getCarsDetails.model"
                        dense
                        :label="getCarsFieldsNames.model"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" class="pt-0">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.classes"
                        readonly
                        :label="getCarsFieldsNames.classes"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pt-0">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.plate"
                        readonly
                        :label="getCarsFieldsNames.plate"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" v-if="getCarsDetails.weight">
                    <v-text-field
                        :label="getCarsFieldsNames.weight"
                        dense
                        :value="Number(getCarsDetails.weight).toFixed(2)"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="12" class="mt-5">
                    <Separator :text="'Informacje dodatkowe'" />
                </v-col>
                <v-col cols="12" md="12" class="d-flex pa-0">
                    <v-col cols="12" md="3" class="mt-5">
                        <v-text-field
                            dense
                            class="no-pointer"
                            v-model="getCarsDetails.rejdat"
                            readonly
                            :label="getCarsFieldsNames.rejdat"
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="5" class="mt-5">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsFields.type"
                        readonly
                        :label="Rodzaj pojazdu"
                    ></v-text-field>
                    </v-col>-->

                    <v-col cols="12" md="4" class="mt-5">
                        <v-text-field
                            dense
                            class="no-pointer"
                            readonly
                            :label="getCarsFieldsNames.evidence"
                            v-model="getCarsDetails.evidence"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-5">
                        <v-text-field
                            dense
                            class="no-pointer"
                            readonly
                            :label="getCarsFieldsNames.inSuranceExpiration"
                            v-model="getCarsDetails.inSuranceExpiration"
                        ></v-text-field>
                    </v-col>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.survey"
                        readonly
                        :label="getCarsFieldsNames.survey"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.vin"
                        readonly
                        :label="getCarsFieldsNames.vin"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.year"
                        readonly
                        :label="getCarsFieldsNames.year"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.tires"
                        readonly
                        :label="getCarsFieldsNames.tires"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        dense
                        class="no-pointer"
                        v-model="getCarsDetails.warranty"
                        readonly
                        :label="getCarsFieldsNames.warranty"
                    ></v-text-field>
                </v-col>

                <!-- <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        class="mt-5 no-pointer"
                        :value="getCarsDetails.guarantor && getCarsDetails.guarantor.name ? getCarsDetails.guarantor.name : 'Brak'"
                        readonly
                        :label="getCarsFieldsNames.guarantor"
                    ></v-text-field>
                </v-col>-->
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        class="mt-5 no-pointer"
                        readonly
                        v-model="getCarsDetails.property"
                        :label="getCarsFieldsNames.property"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        :label="getCarsFieldsNames.driver"
                        class="mt-5 no-pointer"
                        readonly
                        :value="getCarsDetails.driver ? `${getCarsDetails.driver.lastname} ${getCarsDetails.driver.name}` : 'Brak'"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                        :label="getCarsFieldsNames.description"
                        v-model="getCarsDetails.description"
                        class="no-pointer"
                        readonly
                        rows="1"
                        auto-grow
                    ></v-textarea>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        Separator,
    },
    computed: {
        ...mapGetters([
            "getCarsFields",
            "getCarsFieldsNames",
            "getCarsDetails",
        ]),
    },
    // methods: {
    //     ...mapActions(["carsDeatils"])
    // }
};
</script>