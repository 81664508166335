<template>
    <v-tooltip left v-if=" getPermission.level >= 4">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="createIso">
                <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>Dodaj nową procedurę</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission"]),
    },
    methods: {
        ...mapActions([
            "openModal",
            "fetchItems",
            //"fetchModels", 'fetchSeries'
        ]),
        createIso() {
            this.fetchItems("users");
            this.openModal({
                headLine: "global.create",
                width: 700,
                open: true,
                type: "create",
                route: "Iso",
                tab: "iso",
            });
            // this.isoDetails({
            //   id: this.$route.params.id,
            //   type: "modal",
            //   route: this.$route.name
            // });
            // this.cleariso();
        },
    },
};
</script>