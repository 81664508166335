<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" class="pt-0">
                <v-text-field
                    dense
                    v-model="getModalAgreement.nrAgreement"
                    :label="getAgreementFieldsNames.nrAgreement"
                    :error-messages="getAgreementErrors.nrAgreement"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="getModalAgreement.annexAgreement"
                    dense
                    label="Czy umowa jest aneksem?"
                    color="#19aa8d"
                    class="mt-2 check__servis"
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="14" md="12">
                <v-text-field
                    dense
                    v-model="getModalAgreement.titleAgreement"
                    :label="getAgreementFieldsNames.titleAgreement"
                    :error-messages="getAgreementErrors.titleAgreement"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="14" md="12">
                <v-autocomplete
                    v-model="getModalAgreement.contractorAgreement"
                    dense
                    class="font-devices"
                    :label="getAgreementFieldsNames.contractorAgreement"
                    :hide-no-data="true"
                    hint="Zacznij pisać..."
                    @keyup="findContacts($event)"
                    :items="getAgreementItems"
                    item-text="name"
                    return-object
                    @input="setClientThings($event)"
                >
                    <template slot="prepend">
                        <v-icon color="#b3b3b3" size="18">mdi-account</v-icon>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" class="py-0">
                <v-tabs centered>
                    <v-tab ripple>reprezentant</v-tab>
                    <v-tab ripple>dodaj nowego reprezentanta</v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-autocomplete
                                    v-model="getModalAgreement.responsibleAgreement"
                                    dense
                                    class="font-devices"
                                    :label="getAgreementFieldsNames.responsibleAgreement"
                                    item-value="_id"
                                    item-text="name"
                                    :items="getAgreementRepresentatives"
                                >
                                    <template v-slot:selection="data">
                                        <p class="pa-0 ma-0">
                                            {{data.item.name}} {{data.item.lastname}}
                                            <span
                                                v-if="data.item.phoneNumber"
                                            >• {{data.item.phoneNumber}}</span>
                                            <span
                                                v-if="data.item.email"
                                            >&nbsp;&nbsp;• {{data.item.email}}</span>
                                        </p>
                                    </template>
                                    <template v-slot:item="data">
                                        <p class="pa-0 ma-0">
                                            {{data.item.name}} {{data.item.lastname}}
                                            <span
                                                v-if="data.item.phoneNumber"
                                            >• {{data.item.phoneNumber}}</span>
                                            <span
                                                v-if="data.item.email"
                                            >&nbsp;&nbsp;• {{data.item.email}}</span>
                                        </p>
                                    </template>
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-phone</v-icon>
                                    </template>
                                    <template slot="append">
                                        <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getAgreementFieldsNames.responsiblenewnameAgreement"
                                            required
                                            v-model="getModalAgreement.responsiblenewnameAgreement"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getAgreementFieldsNames.responsiblenewlastAgreement"
                                            required
                                            v-model="getModalAgreement.responsiblenewlastAgreement"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getAgreementFieldsNames.responsiblenewemailAgreement"
                                            required
                                            v-model="getModalAgreement.responsiblenewemailAgreement"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getAgreementFieldsNames.responsiblenewephoneAgreement"
                                            required
                                            v-model="getModalAgreement.responsiblenewephoneAgreement"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
                <v-menu
                    v-model="beginningdata"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="getModalAgreement.databeginningAgreement"
                            dense
                            :label="getAgreementFieldsNames.databeginningAgreement"
                            prepend-icon="mdi-calendar"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getModalAgreement.databeginningAgreement"
                        @input="beginningdata = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md class="pt-0">
                <v-menu
                    v-model="enddata"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="getModalAgreement.dataendAgreement"
                            dense
                            :label="getAgreementFieldsNames.dataendAgreement"
                            prepend-icon="mdi-calendar"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        first-day-of-week="1"
                        v-model="getModalAgreement.dataendAgreement"
                        @input="enddata = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
                <v-select
                    dense
                    :label="getAgreementFieldsNames.typeAgreement"
                    v-model="getModalAgreement.typeAgreement"
                    class="mt-5"
                    :items="typeItems"
                ></v-select>
            </v-col>
            <v-col cols="12" md="12">
                <v-textarea
                    rows="2"
                    :label="getAgreementFieldsNames.commentAgreement"
                    v-model="getModalAgreement.commentAgreement"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";

export default {
    data() {
        return {
            responsibleAgreement: "not_accepted"
        };
    },
    data: () => ({
        typeItems: [
            "Umowa administracyjna",
            "Umowa przetargowa",
            "Umowa sprzedaży do odbiorców",
            "Umowa zakupu do dostawców",
            "Umowa serwisu dla Klienta"
        ],
        beginningdata: false,
        enddata: false
    }),
    computed: {
        ...mapGetters([
            "getModalAgreement",
            "getAgreementFieldsNames",
            "getAgreementItems",
            "getAgreementRepresentatives",
            "getAgreementErrors"
        ])
    },
    methods: {
        ...mapActions(["fetchContacts"]),
        ...mapMutations(["closeModal", "setClientThings", "setClientItems"]),
        findContacts: debounce(function(e) {
            this.fetchContacts(e.target.value);
        }, 500)
    }
};
</script>
