<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="7" lg="7" class="d-flex flex-wrap mt-5">
        <v-col cols="12" md="12" class="pt-0">
          <v-col class="px-0" cols="6">
            <v-text-field
              class="no-pointer"
              readonly
              v-model="getIsoDetails.procesName"
              dense
              :label="getIsoFieldsNames.procesName"
            ></v-text-field>
          </v-col>
        </v-col>
        <v-col cols="8" md="2" class="pt-0">
          <v-text-field
            class="no-pointer"
            readonly
            v-model="getIsoDetails.isoIndex"
            dense
            :label="getIsoFieldsNames.isoIndex"
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="2" class="pt-0">
          <v-text-field
            dense
            class="no-pointer"
            v-model="getIsoDetails.version"
            readonly
            :label="getIsoFieldsNames.version"
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="6" class="pt-0">
          <v-text-field
            dense
            class="no-pointer"
            v-model="getIsoDetails.name"
            readonly
            :label="getIsoFieldsNames.name"
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="2" class="pt-0">
          <v-text-field
            dense
            class="no-pointer"
            v-model="getIsoDetails.dataArchive"
            readonly
            :label="getIsoFieldsNames.dataArchive"
          ></v-text-field>
        </v-col>

        <v-col cols="22" md="12">
          <v-textarea
            :label="getIsoFieldsNames.comment"
            v-model="getIsoDetails.comment"
            class="no-pointer"
            readonly
            rows="10"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col cols="8" md="6" class="pt-0">
          <v-text-field
            dense
            class="no-pointer"
            :value="responsiblePerson()"
            readonly
            :label="getIsoFieldsNames.responsiblePerson"
          ></v-text-field>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Separator
  },
  computed: {
    ...mapGetters(["getIsoDetails", "getIsoFieldsNames"])
  },
  methods: {
    responsiblePerson() {
      let person = "";
      for (let i = 0; i < this.getIsoDetails.responsiblePerson.length; i++) {
        person += `${this.getIsoDetails.responsiblePerson[i].name} ${this.getIsoDetails.responsiblePerson[i].lastname}, `;
      }
      return person;
    }
  }
};
</script>