<template>
    <v-container>
        <v-select
            label="Dodaj pliki ze schowka"
            v-model="getStorageAttachment.file"
            item-value="_id"
            :items="getStorageAttachments"
        >
            <template v-slot:item="data">{{data.item.realFileName}}</template>
            <template v-slot:selection="data">
                <span style="padding-right: 10px">{{data.item.realFileName}}</span>
            </template>
        </v-select>
    </v-container>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["getStorageAttachments", "getStorageAttachment"])
    },
    methods: {
        ...mapActions(["getStorage"])
    },
    beforeMount() {
        this.getStorage({});
    }
};
</script>