import Vue from 'vue';
import router from '@/router.js';
import moment from 'moment-timezone';

const state = {
	modalPharmacotherapy: false,
	pharmacotherapy: {
		title: 'FARMAKOTERAPIA',
		header: [
			{
				text: 'Nazwa',
				value: 'title',
				class: 'central__table__header',
				width: '55%',
			},
			{
				text: 'Index',
				align: 'left',
				value: 'pharmIndex',
				class: 'central__table__header',
				width: '115px',
			},
			{
				text: 'Menager',
				value: 'menager',
				class: 'central__table__header',
				width: '10%',
			},
			{
				text: 'Status',
				value: 'status',
				class: 'central__table__header',
				width: '20%',
			},
			{
				text: 'Akcja',
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			title: '',
			archiveDate: '',
			information: '',
			version: '',
			pharmIndex: '',
		},
		errors: {
			title: '',
			archiveDate: '',
			information: '',
		},
		details: {
			title: '',
			archiveDate: '',
			information: '',
			version: '',
			pharmIndex: '',
		},
	},
	fieldsNames: {
		title: 'Nazwa',
		archiveDate: 'Data archiwizacji',
		information: 'Informacja',
		pharmIndex: 'Index',
		version: 'Wersja',
	},
};

const getters = {
	getPharmacotherapy: (state) => state.pharmacotherapy,
	getPharmacotherapyModal: (state) => state.modal.fields,
	getPharmacotherapyError: (state) => state.modal.errors,
	getPharmacotherapyDetails: (state) => state.modal.details,
	getPharmacotherapyFieldsNames: (state) => state.fieldsNames,
};

const actions = {
	createPharmacotherapy({ dispatch, state, commit }, payload) {
		Vue.axios({
			method: 'POST',
			url: '/pharmacotherapy',
			data: {
				...state.modal.fields,
				...payload,
			},
		})
			.then((response) => {
				dispatch('fetchData', {
					page: 1,
					pageSize: 11,
					search: '',
					sortBy: [],
					sortDesc: [],
					routeName: 'pharmacotherapy',
				});
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie dodano informację',
				});
			})
			.catch((error) => {
				console.log(error.response.data);
				commit('setErrors', {
					route: 'pharmacotherapy',
					err: error.response.data.error,
				});
			});
	},

	PharmacotherapyDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/pharmacotherapy/${payload.id}`,
		})
			.then((response) => {
				if (payload.type == 'modal') {
					commit('setPharmacotherapyModal', response.data.item);
				} else {
					commit('setDetailsMenager', response.data.item.menager);
					commit('setDetailsStatus', response.data.item.status);
					commit('setDetailsStatuses', response.data.statuses);
					commit('setPharmacotherapyDeatils', response.data.item);
					commit('setDetailsIndex', response.data.item.index);
					if (payload.next) {
						payload.next();
					}
				}
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'pharmacotherapy',
					err: error.response.data.error,
				});
			});
	},

	updatePharmacotherapy({ state, commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/pharmacotherapy/edit/${payload}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setPharmacotherapyDeatils', response.data.item);
				commit('closeModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano informacje',
				});
			})
			.catch((error) => {
				console.log(error);
				commit('setErrors', {
					route: 'pharmacotherapy',
					err: error.response.data.error,
				});
			});
	},
};

const mutations = {
	setPharmacotherapyDeatils(state, payload) {
		state.modal.details = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
	setPharmacotherapyModal(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.archiveDate = payload.archiveDate
			? moment(payload.archiveDate).format('YYYY-MM-DD')
			: '';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
