import Vue from 'vue';
import findIndex from 'lodash.findindex';
import moment from 'moment-timezone';
import router from '@/router.js';
const state = {
    global: {
        paths: {
            profilePic: `${process.env.VUE_APP_SERVER}/secured/profile/`,
            attachments: `${process.env.VUE_APP_SERVER}/secured/attachments/`,
        },
    },
    dialog: false,
    deleteFolderModal: {
        open: false,
    },

    editedItem: {
        name: '',
    },
    headers: [
        {
            text: 'Nazwa pliku',
            align: 'left',
            value: 'realFileName',
            width: '50%',
        },
        {
            text: 'Opis',
            value: 'description',
            width: '25%',
        },
        {
            text: 'Status',
            value: 'status',
            width: '10%',
            class: 'central__table__header',
        },

        {
            text: 'Akcja',
            value: 'action',
            width: '15%',
            sortable: false,
            align: 'right',
        },
    ],
    items: [],
    folderNameValue: '',
    folderItems: [],
    folderName: [],
    modal: {
        fields: {
            addFolder: '',
            hover: 0,
            files: [],
            action: '',
            folderName: '',
            fakeFileName: '',
            transferredFolderName: '',
            link: '',
            comments: '',
            description: '',
            dataArchive: '',
            createdBy: '',
            createdAt: '',
            status: '',
            fileName: '',
            comments: '',
        },
        errors: {
            files: '',
            link: '',
            folderName: '',
            description: '',
            dataArchive: '',
            status: '',
            fileName: 'Ten folder już istnieje',
        },
    },
    fieldsNames: {
        fullName: 'Nazwa pełna',
        shortName: 'Nazwa skrócona',
        comment: 'Uwagi',
        superiorDepartment: 'Departament nadrzędny',
        leader: 'Kierownik departamentu',
        users: 'Pracownicy departamentu',
        status: 'Status',
    },
    templates: {
        items: [],
    },
    count: '',
    loading: [],
};

const getters = {
    getFolderNameValue: (state) => state.folderNameValue,
    getAttachmentsFields: (state) => state.modal.fields,
    getAttachmentsHeaders: (state) => state.headers,
    getAttachmentsItems: (state) => state.items,
    getAttachmentsError: (state) => state.modal.errors,
    getDeleteModal: (state) => state.deleteFolderModal,
    getFolderItems: (state) => state.folderItems,
    getEditFolder: (state) => state.editedItem,
    getAttachmentLoader: (state) => state.modal.loader,
    getTemplatesItems: (state) => state.templates.items,
    getAttachmentsCount: (state) => state.count,
    getAttachmentsLoading: (state) => state.loading,
};

const actions = {
    // fech Folder
    fetchFolderAttachments({ state, commit, dispatch }, payload) {
        if (payload) {
            if (!payload.templates) commit('setClearFolderItems');
            Vue.axios({
                method: 'GET',
                url: `/attachments/${payload.registryName}/${payload.registryID}`,
            })
                .then((response) => {
                    if (!payload.templates) {
                        // show folder
                        commit('setAttachmentsFolder', response.data.items);

                        commit(
                            'setAttachmentsItems',
                            response.data.attachmentFirstFolder
                                ? response.data.attachmentFirstFolder
                                : []
                        );

                        commit(
                            'setAddFolderValue',
                            response.data.arrAttachments[0]
                                ? response.data.arrAttachments[0].folderName
                                : ''
                        );
                        commit(
                            'setaddDefaultValue',
                            response.data.firstFolder
                                ? response.data.firstFolder
                                : []
                        );

                        //add prefix
                        dispatch(
                            'addPrefixToAttachment',
                            response.data.attachmentFirstFolder
                                ? response.data.attachmentFirstFolder
                                : []
                        );

                        dispatch('attachmentsCount', {
                            registryName: payload.registryName,
                            registryID: payload.registryID,
                        });
                    } else {
                        commit('setTemplatesItems', response.data.items);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },

    updateFolderOrder({ state, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: `/updateFolderOrder/${payload.registryName}/${payload.registryID}`,
            data: state.folderItems,
        })
            .then((response) => {})
            .catch((error) => {
                console.log(error);
            });
    },

    createAttachments({ state, commit, dispatch }, payload) {
        if (!state.folderNameValue && state.modal.fields.folderName == '') {
            state.modal.errors.folderName = 'To pole jest wymagane';
        } else if (
            state.modal.fields.files.length === 0 &&
            !state.modal.fields.link &&
            state.folderNameValue
        ) {
            state.modal.errors.files = 'Wybierz pliki lub podaj link';
            state.modal.errors.link = 'Wybierz pliki lub podaj link';
        } else {
            commit('setButtonsDisabled');
            state.modal.loader = true;
            let formData = new FormData();
            formData.append('link', state.modal.fields.link);
            formData.append('description', state.modal.fields.description);
            formData.append('dataArchive', state.modal.fields.dataArchive);
            formData.append('status', state.modal.fields.status);
            formData.append('registryID', payload.registryID);
            formData.append('registryName', payload.registryName);
            formData.append('comments', state.modal.fields.comments);
            formData.append('folderNameValue', state.folderNameValue);
            formData.append('folderName', state.modal.fields.folderName);

            state.modal.fields.files.forEach((item) => {
                formData.append('files', item);
            });

            Vue.axios({
                method: 'POST',
                url: `/attachments/${payload.registryName}`,
                data: formData,
            })
                .then((response) => {
                    // dodawanie nazwy foldera do usuwania
                    commit('setFolderNameToDelete', payload.folderName);
                    // odświeżanie załączników
                    commit('setAddFolderItems', response.data.folderItems);
                    //Pobieranie folderów
                    //Funkcja na załączniki w folderze
                    commit('setAttachmentsFolder', response.data.items);
                    dispatch(
                        'addPrefixToAttachment',
                        response.data.folderItems
                            ? response.data.folderItems
                            : []
                    );
                    //Funkcja na nazwy folderów
                    commit('closeModal');
                    commit('setButtonsEnabled');
                    state.modal.loader = false;
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie dodano nowe załączniki',
                    });
                    dispatch('attachmentsCount', {
                        registryName: payload.registryName,
                        registryID: payload.registryID,
                    });
                })
                .catch((error) => {
                    commit('setButtonsEnabled');

                    if (error.response.data.exist === true) {
                        state.modal.errors.folderName =
                            'Ten folder już istnieje';
                    } else if (error.response.request.status === 413) {
                        state.modal.errors.files =
                            'Plik jest zbyt duży. Maksymalna dozwolona wielkość 50MB';
                    } else if (
                        error.response.data.error.name === 'ValidationError'
                    ) {
                        state.modal.errors.folderName = 'To pole jest wymagane';
                    }

                    // commit('setErrors', {
                    // 	route: 'attachments',
                    // 	err: error.response.data.error,
                    // });
                });
        }
    },

    createFromTemplate({ state, commit, dispatch }, payload) {
        let data = {
            link: '',
            description: '',
            dataArchive: '',
            status: '',
            registryID: payload.registryID,
            registryName: payload.registryName,
            comments: '',
            folderNameValue: state.folderNameValue,
            folderName: '',
        };
        Vue.axios({
            method: 'POST',
            url: `/attachments/fromTemplate/${payload.registryName}`,
            data: {
                template: {
                    registryID: payload.templateRegistryID,
                    attachmentID: payload.attachmentID,
                },
                data: data,
            },
        })
            .then((response) => {
                if (response.data.done == false) {
                    commit('setSnackbars', {
                        type: '',
                        text: 'Brak określonego pliku na serwerze',
                    });
                } else {
                    // dodawanie nazwy foldera do usuwania
                    commit('setFolderNameToDelete', payload.folderName);
                    // odświeżanie załączników
                    commit('setAddFolderItems', response.data.folderItems);
                    //Pobieranie folderów
                    //Funkcja na załączniki w folderze
                    commit('setAttachmentsFolder', response.data.items);

                    dispatch(
                        'addPrefixToAttachment',
                        response.data.folderItems
                            ? response.data.folderItems
                            : []
                    );
                    //Funkcja na nazwy folderów
                    commit('closeModal');
                    state.modal.loader = false;
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie dodano nowe załączniki',
                    });
                    dispatch('attachmentsCount', {
                        registryName: payload.registryName,
                        registryID: payload.registryID,
                    });
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    },

    attachmentDetails({ state, commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/attachmentsUpdate/${payload.fakeFileName}/${payload.registryName}/${payload.registryID}`,
            params: {
                folderName: payload.folderName,
            },
        })
        .then((response) => {                
                commit('setAttachmentDetails', response.data.item[0]);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },
    // <a href="ms-word:ofe|u|http://192.168.0.136:3000/api/v1/file.doc">DOWNLOAD</a>

    // pobieranie plików do foldera

    fetchItemFolderAttachments({ state, commit, dispatch }, payload) {      
        Vue.axios({
            method: 'GET',
            url: `/attachments/folder/${payload.registryName}/${payload.registryID}`,
            params: {
                folderName: payload.folderName,
            },
        })
            .then((response) => {
                dispatch('addPrefixToAttachment', response.data.items);
                commit('setAttachmentsItems', response.data.items);
                commit('folderNameValue', payload.folderName);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },

    updateFolderAttachment({ state, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: `/attachments/update/FolderName/${payload.registryName}/${payload.registryID}/${payload.action}`,
            data: {
                ...state.modal.fields,
            },
            params: {
                folderName: state.modal.fields.transferredFolderName,
            },
        })
            .then((response) => {
                if (payload.action == 'editFolder') {
                    commit('setAttachmentsFolder', response.data.items);
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie zmieniono nazwę folderu',
                    });
                } else if (payload.action == 'deleteFolder') {
                    commit('setAttachmentsFolder', response.data.items);

                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie usunięto folder',
                    });
                }
            })
            .catch((error) => {
                commit('setSnackbars', {
                    type: 'error',
                    text: 'Coś poszło nie tak. Spróbuj później.',
                });
            });
    },

    updateAttachment({ state, commit }, payload) {
        if (state.modal.fields.fileName || state.modal.fields.link)
            Vue.axios({
                method: 'PUT',
                url: `/attachments/update/${state.modal.fields.fakeFileName}/${payload.registryName}/${payload.registryID}`,
                params: {
                    folderName: payload.folderName,
                },
                data: {
                    ...state.modal.fields,
                },
            })
                .then((response) => {
                    commit('updateAttachmentsItem', {
                        item: response.data.item,
                        registryName: payload.registryName,
                    });

                    // commit('setAttachmentsFolder', response.data.items);
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie edytowano dany wpis',
                    });
                })
                .catch((error) => {
                    commit('setSnackbars', {
                        type: 'error',
                        text: 'Coś poszło nie tak. Spróbuj później.',
                    });
                });
        else {
            state.modal.errors.fileName = 'Pole wymagane';
        }
    },
    deleteItemAttachment({ state, commit, dispatch }, payload) {
        Vue.axios({
            method: 'PUT',
            url: `/attachments/delete/${payload.fakeFileName}/${payload.registryName}/${payload.registryID}`,
            params: {
                folderName: payload.transferredFolderName,
            },
        })
            .then((response) => {
                commit('setAttachmentsItems', response.data.item);
                dispatch('attachmentsCount', {
                    registryName: payload.registryName,
                    registryID: payload.registryID,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    addPrefixToAttachment({ state, commit }, payload) {
        payload.forEach((element) => {
            if (element.fakeFileName) {
                if (
                    element.fakeFileName.includes('.doc') ||
                    element.fakeFileName.includes('.docx')
                ) {
                    element.prefix = `ms-word:ofe|u|`;
                } else if (
                    element.fakeFileName.includes('.xls') ||
                    element.fakeFileName.includes('.xlsx')
                ) {
                    element.prefix = `ms-excel:ofe|u|`;
                } else if (
                    element.fakeFileName.includes('.ppt') ||
                    element.fakeFileName.includes('.pptx')
                ) {
                    element.prefix = `ms-powerpoint:ofe|u|`;
                } else {
                    element.prefix = '';
                }
            }
        });
        return payload;
    },
    showFirstAttachments({ state, commit, dispatch }, payload) {
        if (payload) {
            commit('setClearFolderItems');
            Vue.axios({
                method: 'GET',
                url: `/attachments/${payload.registryName}/${payload.registryID}`,
            })
                .then((response) => {
                    if (response.data.attachmentFirstFolder[0] === undefined) {
                        window.open('/#/NotFound', '_blank');
                    } else if (response.data.attachmentFirstFolder[0].link) {
                        window.open(
                            response.data.attachmentFirstFolder[0].link,
                            '_blank'
                        );
                    } else {
                        let fileName =
                            response.data.attachmentFirstFolder[0].fakeFileName;
                        let ext = fileName.split('.').pop();
                        const exts = [
                            'doc',
                            'docx',
                            'xls',
                            'xlsx',
                            'ppt',
                            'pptx',
                        ];
                        if (exts.includes(ext)) {
                            commit('openOfficePreview', {
                                url: `${process.env.VUE_APP_SERVER}/${process.env.VUE_APP_WEBDAV}/${response.data.attachmentFirstFolder[0].fakeFileName}`,
                            });
                        } else {
                            window.open(
                                state.global.paths.attachments +
                                    response.data.attachmentFirstFolder[0]
                                        .fakeFileName,
                                '_blank'
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    attachmentsCount({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/global/find/count/attachments`,
            params: payload,
        }).then((response) => {
            state.count = response.data;
        });
    },
    moveAttachment({ commit, dispatch }, payload) {
        Vue.axios({
            method: 'POST',
            url: `/attachments/move/${payload.registryName}/${payload.registryID}`,
            data: {
                attachmentID: payload.attachmentID,
                folderID: payload.folderID,
            },
        }).then((response) => {
            dispatch('fetchFolderAttachments', payload);
            commit('setSnackbars', {
                type: 'success',
                text: 'Pomyślnie przeniesiono załącznik',
            });
        });
    },
    copyAttachment({ commit, dispatch }, payload) {
        Vue.axios({
            method: 'POST',
            url: `/attachments/copy/${payload.registryName}/${payload.registryID}`,
            data: {
                to: {
                    folderID: payload.folderID,
                    registryID: payload.registryID,
                    registryName: payload.registryName,
                },
                attachmentID: payload.attachmentID,
            },
        }).then((response) => {
            dispatch('fetchFolderAttachments', payload);
            commit('setSnackbars', {
                type: 'success',
                text: 'Pomyślnie skopiowano załącznik',
            });
        });
    },

    convertAttachmentToPdf({ commit, dispatch }, payload) {
        state.loading.push(payload.attachmentID);
        Vue.axios({
            method: 'POST',
            url: `/attachments/convertToPdf/${payload.registryName}/${payload.registryID}`,
            data: {
                attachmentID: payload.attachmentID,
                fakeFileName: payload.fakeFileName,
            },
        }).then((response) => {
            dispatch('fetchFolderAttachments', payload);
            commit('setSnackbars', {
                type: 'success',
                text: 'Pomyślnie utworzono PDF załącznika',
            });

            state.loading.splice(
                state.loading.indexOf(payload.attachmentID),
                1
            );
        });
    },
};

const mutations = {
    setAddFolderValue(state, payload) {
        state.folderNameValue = payload;
    },
    setFolderNameToDelete(state, payload) {
        payload
            ? (state.modal.fields.transferredFolderName = payload)
            : (state.modal.fields.transferredFolderName = '');
    },
    updateListDragAndDrop(state, payload) {
        state.folderItems = payload;
    },
    setcloseDeleteModal(state, payload) {
        state.deleteFolderModal.open = false;
        state.modal.fields.folderName = '';
    },
    setClearFolderItems(state, payload) {
        state.items = [];
    },
    setaddDefaultValue(state, item) {
        state.modal.fields.hover = 0;
        if (item.length > 0) {
            state.folderNameValue = item[0].folderName;
        }
        // else {
        // 	state.folderNameValue = 'Główny';
        // }
    },
    setAddFolderItems(state, payload) {
        state.items = payload;
    },
    setclearFolderName(state) {
        state.folderNameValue = '';
    },
    folderNameValue(state, payload) {
        state.folderNameValue = payload;
    },
    setAttachmentDetails(state, payload) {
        console.log(payload);
        state.modal.fields.fileName = payload.realFileName;
        state.modal.fields.description = payload.description;
        state.modal.fields.dataArchive = payload.dataArchive;
        state.modal.fields.status = payload.status;
        state.modal.fields.comments = payload.comments;
        state.modal.fields.fakeFileName = payload.fakeFileName;
        state.modal.fields.link = payload.link;
        state.modal.fields.createdAt = moment(payload.createdAt).format('YYYY-MM-DD HH:mm')
            
        // state.modal.fields.createdBy = payload.createdBy
        //     ? payload.createdBy
        //     : '';
    },
    setAttachmentsItems(state, payload) {
        payload ? (state.items = payload) : (state.items = []);
    },
    setAttachmentsFolder(state, payload) {
        payload ? (state.folderItems = payload) : (state.folderItems = []);
        state.items = [];
        // tutaj dodać
        state.folderNameValue = '';
    },
    updateAttachmentsItem(state, payload) {
        console.log(payload);
        let arr = state.items;
        let index = findIndex(arr, {
            fakeFileName: payload.item.fakeFileName,
        });
        if (
            payload.registryName == 'resources_details' &&
            payload.item.status == 'Archiwalny'
        ) {
            arr.splice(index, 1);
        } else {
            arr.splice(index, 1, payload.item);
        }
    },
    updateAttachmentsItems(state, payload) {
        state.items = [...payload, ...state.items];
    },
    setTemplatesItems(state, payload) {
        payload
            ? (state.templates.items = payload)
            : (state.templates.items = []);
    },
    removeAttachmentItem(state, payload) {
        let arr = state.items;
        let index = findIndex(arr, {
            fakeFileName: payload,
        });
        arr.splice(index, 1);
    },
    clearTemplatesItems(state, payload) {
        state.templates.items = [];
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
