<template>
    <v-container>
        <v-row>
            <v-col cols="4">
                <v-col cols="12">
                    <v-text-field
                        class="no-pointer"
                        label="Twórca"
                        readonly
                        :value="
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.turnedOn &&
                            getImplementationDetails.implementation
                                .implementationCreatedBy &&
                            getImplementationDetails.implementation
                                .implementationCreatedBy.name
                                ? `${getImplementationDetails.implementation.implementationCreatedBy.name} ${getImplementationDetails.implementation.implementationCreatedBy.lastname}`
                                : ''
                        "
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        class="no-pointer"
                        readonly
                        label="Klient"
                        :value="
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.turnedOn &&
                            getImplementationDetails.contractorOffers &&
                            getImplementationDetails.contractorOffers.name
                                ? getImplementationDetails.contractorOffers.name
                                : ''
                        "
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        class="no-pointer"
                        readonly
                        label="Reprezentant"
                        :value="`${
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.turnedOn &&
                            getImplementationDetails.responsibleOffers &&
                            getImplementationDetails.responsibleOffers.name
                                ? getImplementationDetails.responsibleOffers
                                      .name +
                                  ' ' +
                                  getImplementationDetails.responsibleOffers
                                      .lastname
                                : ''
                        } ${
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.turnedOn &&
                            getImplementationDetails.responsibleOffers &&
                            getImplementationDetails.responsibleOffers.email
                                ? '• ' +
                                  getImplementationDetails.responsibleOffers
                                      .email
                                : ''
                        } ${
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.turnedOn &&
                            getImplementationDetails.responsibleOffers &&
                            getImplementationDetails.responsibleOffers
                                .phoneNumber
                                ? getImplementationDetails.responsibleOffers
                                      .phoneNumber
                                : ''
                        }`"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        class="no-pointer"
                        readonly
                        label="Utworzono wdrożenie"
                        :value="
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation
                                .implementationDateChecked &&
                            getImplementationDetails.implementation.turnedOn
                                ? $moment(
                                      getImplementationDetails.implementation
                                          .implementationDateChecked
                                  ).format('YYYY-MM-DD HH:mm:ss')
                                : ''
                        "
                    ></v-text-field>
                </v-col>
            </v-col>
            <v-col cols="8">
                <v-col cols="6">
                    <v-text-field
                        class="no-pointer"
                        readonly
                        label="Data i czas wdrożenia"
                        :value="`${
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation
                                .implementationDate
                                ? $moment(
                                      getImplementationDetails.implementation
                                          .implementationDate
                                  ).format('YYYY-MM-DD')
                                : ''
                        } ${
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation
                                .implementationTime
                                ? getImplementationDetails.implementation
                                      .implementationTime
                                : ''
                        }`"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        class="no-pointer"
                        readonly
                        label="Podstawa wdrożenia"
                        :value="
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.basic
                                ? getImplementationDetails.implementation.basic
                                : ''
                        "
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-textarea
                        class="no-pointer"
                        readonly
                        no-resize
                        label="Opis"
                        :value="
                            getImplementationDetails &&
                            getImplementationDetails.implementation &&
                            getImplementationDetails.implementation.description
                                ? getImplementationDetails.implementation
                                      .description
                                : ''
                        "
                    ></v-textarea>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["getImplementationDetails"]),
    },
};
</script>
