import Vue from 'vue';

const state = {
	storage: {
		attachments: [],
		headers: [
			{
				text: 'Nazwa pliku',
				value: 'realFileName',
				class: 'central__table__header',
			},
			{
				text: 'Opis pliku',
				value: 'description',
				class: 'central__table__header',
			},
			{
				text: 'Rozmiar pliku',
				value: 'size',
				class: 'central__table__header',
				width: '10%',
				align: 'left',
			},
			{
				text: 'Akcja',
				value: 'action',
				width: '15%',
				sortable: false,
				align: 'right',
			},
		],
		attachment: {},
	},
};
const getters = {
	getStorageAttachments: (state) => state.storage.attachments,
	getStorageHeaders: (state) => state.storage.headers,
	getStorageAttachment: (state) => state.storage.attachment,
};
const actions = {
	async getStorage({ commit, state, dispatch }, payload) {
		Vue.axios({
			url: `/storage/${payload.userId}`,
			method: 'GET',
		}).then((response) => {
			state.storage.attachments = response.data.attachments;
		});
		if (payload.next) payload.next();
	},
	async copyAttachmentToStorage({ commit, state, dispatch }, payload) {
		await Vue.axios({
			url: `/attachments/copy/${payload.fromRegistryName}/${payload.fromRegistryID}`,
			method: 'POST',
			data: {
				to: {
					registryID: payload.registryID,
					registryName: payload.registryName,
					folderID: payload.folderID,
				},
				attachmentID: payload.attachmentID,
				storage: payload.storage2attachments ? false : true,

				taskID: payload.taskID,
			},
		}).then((response) => {
			if (payload.storage2attachments)
				dispatch('fetchItemFolderAttachments', {
					registryID: payload.registryID,
					registryName: payload.registryName,
					folderName: payload.folderID,
				});
			commit('setSnackbars', {
				type: 'success',
				text: 'Przeniesiono do schowka',
			});
			if (!payload.taskID) commit('closeModal');
			if (!response.data.done)
				state.storage.attachments = response.data.attachments;
			else dispatch('getStorage', { userId: undefined });
		});
	},
	async removeAttachmentFromStorage({ commit, state, dispatch }, payload) {
		Vue.axios({
			url: `/storage/${payload.userId}`,
			method: 'PUT',
			data: { attachmentID: payload.attachmentID },
		}).then((response) => {
			state.storage.attachments = response.data.attachments;
			commit('closeSecondModal');
		});
	},
};
const mutations = {
	setStorageAttachment(state, payload) {
		state.storage.attachment = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
