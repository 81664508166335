<template>
    <v-tooltip left v-if="getPermission.level > 1">
        <template v-slot:activator="{ on }">
            <v-btn fab dark v-on="on" small class="buttons--add" @click="editModel">
                <v-icon small>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <span>Edytuj model</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions([
            "openModal",
            "modelDetails",
            "fetchManufacturerBrand",
            "fetchServiceType",
            "fetchOptionName",
            "fetchSPrice"
        ]),

        editModel() {
            this.fetchManufacturerBrand();
            this.fetchSPrice();
            this.fetchOptionName();
            this.fetchServiceType();
            this.modelDetails({
                id: this.$route.params.id,
                type: "modal"
            });

            this.openModal({
                headLine: "global.edit",
                open: true,
                width: 600,
                type: "edit",
                route: "models",
                tab: "models"
            });
        }
    },
    computed: {
        ...mapGetters(["getPermission"])
    }
};
</script>