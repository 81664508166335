<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="7">
                <div class="pl-1">
                    <span class="font-weight-light" style="font-size:18px">Rotacja materiału:</span>
                </div>
                <v-data-table
                    disable-sort
                    fixed-header
                    height="calc(100vh - 585px)"
                    disable-pagination
                    hide-default-footer
                    :headers="headers"
                    :items="getMaterialRotation"
                >
                    <template v-slot:item="{item}">
                        <tr>
                            <td>{{item.rotationType}}</td>
                            <td>{{item.wzDate ? $moment(item.wzDate).format('YYYY-MM-DD') : $moment(item.createdAt).format('YYYY-MM-DD HH:mm')}}</td>
                            <td>{{correctWeight(item.grossWeight, item.tareWeight)}} [{{item.materialType.unit}}]</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        headers: [
            {
                text: "Rotacja",
                value: "rotationType",
                class: "central__table__header",
            },
            {
                text: "Data",
                value: "rotationType",
                class: "central__table__header",
            },
            {
                text: "Ilość",
                value: "rotationType",
                class: "central__table__header",
            },
        ],
    }),
    computed: {
        ...mapGetters(["getMaterialRotation"]),
    },
    methods: {
        ...mapActions(["materialRotation"]),
        correctWeight(gross, tare) {
            return (
                Math.round(
                    (Number(gross) - Number(tare) + Number.EPSILON) * 100
                ) / 100
            );
        },
    },
    beforeMount() {
        this.materialRotation({ id: this.$route.params.id });
    },
};
</script>