var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buttons__mobile"},[(
      _vm.getServiceDetails.service.temporaryInformation.client != '' ||
      _vm.getServiceDetails.service.temporaryInformation.devices !== ''
    )?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add mx-2",attrs:{"dark":"","fab":"","small":""},on:{"click":function($event){_vm.menu = true}}},on),[_vm._v("S/N")])]}}],null,true)},[_c('span',[_vm._v("Przypisz do S/N")])])]}}],null,false,2371795792),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-4",attrs:{"height":"110"}},[_c('v-text-field',{attrs:{"dense":"","label":"Numer seryjny urządzenia"},model:{value:(_vm.newSN),callback:function ($$v) {_vm.newSN=$$v},expression:"newSN"}},[_c('template',{slot:"prepend"},[_c('v-avatar',{staticClass:"mt-1",attrs:{"color":"#19aa8d","size":"20"}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"10px"}},[_vm._v("S/N")])])],1)],2),_c('v-btn',{staticClass:"buttons buttons--add no-print",staticStyle:{"position":"absolute","right":"30px"},attrs:{"dark":""},on:{"click":function($event){return _vm.attributionSN({ id: _vm.getServiceDetails._id, SN: _vm.newSN })}}},[_vm._v("Zapisz")])],1)],1):_vm._e(),(_vm.checkContinuation)?_c('v-tooltip',{attrs:{"left":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add mr-3",style:(("background: " + (_vm.getServiceDetails.service.realisation.continuationIssued == true
            ? 'grey !important'
            : '#19aa8d !important') + "; width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","target":"_BLANK","small":"","disabled":_vm.getServiceDetails.service.realisation.continuationIssued == true
            ? true
            : false},on:{"click":function($event){return _vm.newContinuation(_vm.getServiceDetails)}}},on),[_vm._v("K")])]}}],null,false,3380019474)},[_c('span',[_vm._v("Kontynuacja")])]):_vm._e(),(_vm.getPermission.level > 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","target":"_BLANK","small":""},on:{"click":function($event){return _vm.print('client')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-note-multiple-outline")])],1)]}}],null,false,989824550)},[_c('span',[_vm._v("Podgląd zlecenia")])]):_vm._e(),(_vm.getPermission.level > 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add mx-3",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px'))),attrs:{"fab":"","dark":"","target":"_BLANK","small":""},on:{"click":function($event){return _vm.print('logistic')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-outline")])],1)]}}],null,false,2165991615)},[_c('span',[_vm._v("Podgląd karty obiegowej")])]):_vm._e(),(_vm.getPermission.level > 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"buttons--add",style:((" width: " + (_vm.window.width < 1200 ? '50px' : '40px') + " ; height: " + (_vm.window.width < 1200 ? '50px' : '40px') + "; background: " + (_vm.getDetailsStatus.name == 'Zamknięty' && _vm.getIsTechnics ? 'gray' : '') + " !important; cursor: " + (_vm.getDetailsStatus.name == 'Zamknięty' && _vm.getIsTechnics
            ? 'default'
            : 'pointer'))),attrs:{"fab":"","dark":"","small":""},on:{"click":_vm.serviceEdit}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,false,694380728)},[_c('span',[_vm._v("Edytuj informacje")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }