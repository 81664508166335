<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-wrap">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
          dense
          readonly
          v-model="getTendersDetails.nrTenders"
          :label="getTendersFieldsNames.nrTenders"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4"  sm="6">
            <v-text-field
            dense
            readonly
            v-model="getTendersDetails.dataTenders"
            :label="getTendersFieldsNames.dataTenders"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" sm="6">
            <v-text-field
            dense
            readonly
            v-model="getTendersDetails.timeTenders"
            :label="getTendersFieldsNames.timeTenders"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <v-text-field
            readonly
            v-model="getTendersDetails.contractorTenders.name"
            dense
            :label="getTendersFieldsNames.contractorTenders"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <v-text-field
            readonly
            :value="getTendersDetails.responsibleTenders ? showcorrectResponsible(getTendersDetails.responsibleTenders.name, getTendersDetails.responsibleTenders.lastname, getTendersDetails.responsibleTenders.phoneNumber, getTendersDetails.responsibleTenders.email) : 'Brak'"
            dense
            :label="getTendersFieldsNames.responsibleTenders"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <v-text-field
            readonly
            v-model="getTendersDetails.linkTenders"
            dense
            :label="getTendersFieldsNames.linkTenders"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <v-text-field
            readonly
            v-model="getTendersDetails.commentTenders"
            dense
            :label="getTendersFieldsNames.commentTenders"
            ></v-text-field>
          </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Separator from "@/components/Global/Separator.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Separator
  },
  methods: {
    ...mapActions([
            "openModal",
        ]),
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },

    showcorrectResponsible(name, lastname, phoneNumber, email){
      let Responsible;
      if (name != undefined && name !='') {
        Responsible = name;
      }
      if (lastname != undefined && lastname !='') {
        Responsible = Responsible +' '+ lastname;
      }
      if (email != undefined && email !='') {
         Responsible = Responsible +' • '+ email;
      }
      if (phoneNumber != undefined && phoneNumber !='') {
         Responsible = Responsible +' • '+ phoneNumber;
      }
      return `${Responsible}`;
    },


  },
  computed: {
    ...mapGetters(["getTendersDetails", "getTendersFieldsNames"])
  },

};
</script>