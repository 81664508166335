<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === NAME === === -->
            <td>{{item.name}}</td>

            <!-- === === OPIS === === -->
            <td>{{item.description}}</td>

            <!-- === === WPROWADZAJĄCY === === -->
            <td>{{item.createdBy ? `${item.createdBy.name} ${item.createdBy.lastname}` : 'Brak'}}</td>

            <!-- === === DATA WPROWADZENIA === === -->
            <td>{{$moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}}</td>
            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="details(item._id)">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    methods: {
        ...mapActions(["groupDetails", "fetchItems"]),
        details(id) {
            this.groupDetails(id);
            this.fetchItems("users");
            this.$store.dispatch("openModal", {
                headLine: "global.edit",
                open: true,
                type: "edit",
                width: 800,
                route: this.$route.name
            });
        }
    }
};
</script>