<template>
    <v-slide-x-transition mode="out-in">
        <router-view />
    </v-slide-x-transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    data: () => ({}),

    methods: {
        ...mapMutations(["setWSD"]),
    },
    // mounted() {
    //     this.setWSD(localStorage.getItem("isChecked"));
    // }
};
</script>

<style lang="sass">
@import './assets/sass/main'
</style>

