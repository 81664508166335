import Vue from 'vue';
import i18n from '@/i18n.js';

const state = {
    resources: {
        title: 'ZASOBY WSPÓŁDZIELONE',
        header: [
            {
                text: 'Nazwa',
                value: 'name',
                width: '15%',
                class: 'central__table__header',
            },
            {
                text: 'Opis',
                value: 'description',
                width: '62%',
                class: 'central__table__header',
            },
            {
                text: 'Ostatnia modyfikacja',
                value: 'updatedAt',
                width: '15%',
                class: 'central__table__header',
            },
            {
                text: i18n.t('global.actions'),
                align: 'right',
                width: '8%',
                value: 'action',
                sortable: false,
                class: 'central__table__header',
            },
        ],
        items: [],
        sharedResources: {
            items: [],
        },
    },
    modal: {
        fields: {
            name: '',
            description: '',
        },
        errors: {
            name: '',
        },
    },
    details: {
        fields: {
            name: '',
            description: '',
        },
    },
};
const getters = {
    getResources: (state) => state.resources,
    getResourcesFields: (state) => state.modal.fields,
    getResourcesDetails: (state) => state.details.fields,
    getResourcesErrors: (state) => state.modal.errors,
    getResourcesItems: (state) => state.resources.items,
    getSharedResources: (state) => state.resources.sharedResources,
};
const actions = {
    createResource({ commit, dispatch }) {
        state.modal.errors.name = '';
        Vue.axios({
            method: 'POST',
            url: `/resources`,
            data: state.modal.fields,
        })
            .then((response) => {
                dispatch('fetchData', {
                    page: 1,
                    pageSize: 11,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    routeName: 'resources',
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie dodano nowy zasób',
                });
            })
            .catch((error) => {
                commit('setSnackbars', {
                    type: 'error',
                    text: 'Coś poszło nie tak!',
                });
                if (error.response.data.errors.name.kind === 'required') {
                    state.modal.errors.name = 'Pole jest wymagane';
                }
            });
    },

    resourceDetails({ commit }, payload) {
        console.log(payload);
        Vue.axios({
            method: 'GET',
            url: `/resources/${payload.id}`,
        }).then((response) => {
            console.log(response.data);
            if (payload.type === 'details') {
                commit('setResourceDetails', response.data.item);
                if (payload.next) payload.next();
            } else if (payload.type === 'modal') {
                commit('setResourcesModal', response.data.item);
            }
        });
    },

    resourceUpdate({ commit }) {
        Vue.axios({
            method: 'PUT',
            url: `/resources/${state.modal.fields._id}`,
            data: state.modal.fields,
        })
            .then((response) => {
                console.log(response);
                commit('setResourceDetails', response.data);
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano zasób',
                });
            })
            .catch((error) => {
                commit('setSnackbars', {
                    type: 'error',
                    text: 'Coś poszło nie tak!',
                });
                if (error.response.data.errors.name.kind === 'required') {
                    state.modal.errors.name = 'Pole jest wymagane';
                }
            });
    },

    fetchResources({ commit }) {
        Vue.axios({
            method: 'GET',
            url: '/resources',
        }).then((response) => {
            commit('setResourceItems', response.data.items);
        });
    },

    setSharedInAttachments({ commit, dispatch }, payload) {
        Vue.axios({
            method: 'POST',
            url: `/resources/share`,
            data: {
                registryID: payload.registryID,
                registryName: payload.registryName,
                folderName: payload.folderName,
                resources: state.resources.sharedResources.items,
            },
        }).then((response) => {
            // dodawanie nazwy foldera do usuwania
            commit('setFolderNameToDelete', payload.folderName);
            // odświeżanie załączników
            commit('setAddFolderItems', response.data.folderItems);
            dispatch(
                'addPrefixToAttachment',
                response.data.folderItems ? response.data.folderItems : []
            );
            //Funkcja na nazwy folderów
            commit('closeModal');
            commit('setSnackbars', {
                type: 'success',
                text: 'Pomyślnie dodano nowe załączniki',
            });
        });
    },
};
const mutations = {
    setResourceDetails(state, payload) {
        console.log('PAYLOAd');
        console.log(payload);
        state.details.fields.name = payload.name;
        state.details.fields.description = payload.description;
    },
    setResourceItems(state, payload) {
        state.resources.items = payload;
    },
    setResourcesModal(state, payload) {
        state.modal.fields.name = payload.name;
        state.modal.fields._id = payload._id;
        state.modal.fields.description = payload.description;
    },
    clearResources(state) {
        state.modal.fields.name = '';
        state.modal.fields.description = '';
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
