<template>
    <div>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn fab dark v-on="on" small class="buttons--add mx-2" @click="printContacts">
                    <v-icon small>mdi-printer</v-icon>
                </v-btn>
            </template>
            <span>Drukuj aktualne kontakty</span>
        </v-tooltip>
        <v-tooltip left v-if="getPermission.level > 1">
            <template v-slot:activator="{ on }">
                <v-btn fab dark v-on="on" small class="buttons--add" @click="createInformation">
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Dodaj nowy kontakt wewnętrzny</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({}),
    computed: { ...mapGetters(["getPermission"]) },
    methods: {
        ...mapActions(["openModal", "fetchDepartments", "fetchJobDictionary"]),
        printContacts() {
            // this.$router.push("/printInternalContacts");
            let routeData = this.$router.resolve({
                name: "printInternalContacts",
            });
            window.open(routeData.href, "_blank");
        },
        createInformation() {
            this.fetchDepartments();
            this.fetchJobDictionary();
            this.openModal({
                headLine: "global.create",
                width: 500,
                open: true,
                type: "create",
                route: "internalContacts",
                tab: "internal-contacts",
            });
        },
    },
};
</script>

