import Vue from 'vue';
import Vuex from 'vuex';

import users from '@/store/modules/users.js';
import central from '@/store/modules/central.js';
import tasks from '@/store/modules/Personals/task.js';
import registries from '@/store/modules/Admin/registries.js';
import groups from '@/store/modules/Admin/groups.js';
import departments from '@/store/modules/Registry/departments.js';
import TaskCategory from '@/store/modules/Dictionaries/TaskCategory.js';
import carsDictionary from '@/store/modules/Dictionaries/Cars.js';
import UsersJob from '@/store/modules/Dictionaries/UsersJob.js';
import UsersRole from '@/store/modules/Dictionaries/UsersRole.js';
import UsersContract from '@/store/modules/Dictionaries/UsersContract.js';
import UsersDayJob from './modules/Dictionaries/UsersDayJob';
import profile from '@/store/modules/Personals/profile.js';
import TaskPriority from '@/store/modules/Dictionaries/TaskPriority.js';
import attachments from '@/store/modules/GlobalTabs/attachments.js';
import logs from '@/store/modules/GlobalTabs/logs.js';
import archive from '@/store/modules/GlobalTabs/archive.js';
import devices from '@/store/modules/Registry/devices.js';
import notifications from '@/store/modules/notifications.js';
import staff from '@/store/modules/Tabs/staff.js';
import note from '@/store/modules/GlobalTabs/note.js';
import parts from '@/store/modules/Registry/parts.js';
import service from '@/store/modules/Registry/service.js';
import Iso from '@/store/modules/Registry/iso.js';
import Agreement from '@/store/modules/Registry/agreement.js';
import Offers from '@/store/modules/Registry/offers.js';
import Leasing from '@/store/modules/Registry/leasing.js';
import Tenders from '@/store/modules/Registry/tenders.js';
import directorOrders from '@/store/modules/Registry/directorOrders.js';
import printers from '@/store/modules/Registry/printers.js';
import currentInformations from '@/store/modules/Registry/currentInformations.js';
import duty from '@/store/modules/Registry/duty.js';
import accreditation from '@/store/modules/Registry/accreditation.js';
import pharmacotherapy from '@/store/modules/Registry/pharmacotherapy.js';
import nonMedicalPrint from '@/store/modules/Registry/nonMedicalPrint.js';
import externalPrints from '@/store/modules/Registry/externalPrints.js';
import internalPrints from '@/store/modules/Registry/internalPrints.js';
import patientsConsent from '@/store/modules/Registry/patientsConsent.js';
import extraordinaryOccurence from '@/store/modules/Registry/extraordinaryOccurence.js';
import training from '@/store/modules/Registry/training.js';
import bhp from '@/store/modules/Registry/bhp.js';
import teamAnalysisResult from '@/store/modules/Registry/teamAnalysisResult.js';
import securityPolicy from '@/store/modules/Registry/securityPolicy.js';
import DietNutrition from '../store/modules/Registry/dietNutrition.js';
import internalContacts from '@/store/modules/Registry/internalContacts.js';
import coronavirus from '@/store/modules/Registry/coronavirus.js';

// import diet from '@/store/modules/Registry/diet.js';
import BenefitsValue from '../store/modules/Registry/benefitsValue.js';
import Wifi from '../store/modules/Registry/wifi.js';

import cars from '@/store/modules/Registry/cars.js';
import models from '@/store/modules/Registry/models.js';
import representatives from '@/store/modules/Contacts/representative.js';
import branches from '@/store/modules/Contacts/branch.js';
import contacts from '@/store/modules/Contacts/contact.js';
import ManufacturerBrand from '../store/modules/Dictionaries/ManufacturerBrand';
import serviceCore from '../store/modules/Dictionaries/ServiceCore';
import ServiceType from '../store/modules/Dictionaries/ServiceType';
import Price from '../store/modules/Dictionaries/Price';
import options from '../store/modules/Dictionaries/Options';
import ModelSeries from '../store/modules/Dictionaries/ModelSeries';
import session_logs from '../store/modules/Admin/sessionLogs';
import sessionTimer from '../store/modules/sessionTimer';
import Templates from '../store/modules/Admin/templates.js';
import resources from './modules/resources';
import storage from './modules/Personals/storage';
import constructions from './modules/Registry/constructions.js';
import warehouse from './modules/Registry/warehouse.js';
import materials from './modules/Registry/material.js';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		users,
		tasks,
		central,
		registries,
		groups,
		departments,
		TaskCategory,
		TaskPriority,
		profile,
		attachments,
		devices,
		archive,
		logs,
		staff,
		notifications,
		note,
		parts,
		service,
		printers,
		cars,
		Iso,
		Agreement,
		Leasing,
		Tenders,
		UsersJob,
		UsersRole,
		UsersContract,
		UsersDayJob,
		representatives,
		branches,
		contacts,
		ManufacturerBrand,
		ServiceType,
		models,
		options,
		carsDictionary,
		Price,
		ModelSeries,
		serviceCore,
		session_logs,
		sessionTimer,
		directorOrders,
		currentInformations,
		duty,
		accreditation,
		pharmacotherapy,
		extraordinaryOccurence,
		BenefitsValue,
		nonMedicalPrint,
		externalPrints,
		internalPrints,
		patientsConsent,
		training,
		securityPolicy,
		teamAnalysisResult,
		bhp,
		DietNutrition,
		Wifi,
		internalContacts,
		Templates,
		Offers,
		resources,
		storage,
		constructions,
		warehouse,
		materials,
		coronavirus
	},
	state: {},
	mutations: {},
	actions: {},
});