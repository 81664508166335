import Vue from 'vue';

import findIndex from 'lodash.findindex';

const state = {
    modalIso: false,
    Iso: {
        title: 'Procedury jakości',
        header: [{
            text: 'Proces',
            value: 'procesName',
            class: 'central__table__header',
            width: '8%',
        },
        {
            text: 'Nazwa procesu',
            value: 'name',
            class: 'central__table__header',
            width: '37%',
        },
        {
            text: 'Index',
            align: 'left',
            value: 'isoIndex',
            class: 'central__table__header',
            width: '10%',
        },
        // {
        //     text: 'Wersja',
        //     value: 'version',
        //     class: 'central__table__header',
        //     width: '8%'
        // },
        {
            text: 'Data ostatniej modyfikacji',
            value: 'updatedAt',
            class: 'central__table__header',
            width: '14%',
        },
        {
            text: 'Menager',
            value: 'menager',
            class: 'central__table__header',
            width: '13%',
        },
        {
            text: 'Status',
            value: 'status',
            class: 'central__table__header',
            width: '5%',
        },
        {
            text: 'Akcja',
            align: 'right',
            value: 'action',
            sortable: false,
            width: '4%',
            class: 'central__table__header',
        },
        ],
    },
    modal: {
        fields: {
            index: null,
            isoIndex: null,
            procesName: null,
            name: null,
            comment: null,
            responsiblePerson: [],
        },

        errors: {
            index: null,
            name: null,
            comment: null,
            procesName: null,
            responsiblePerson: null,
        },
    },
    details: {
        isoindex: '',
        name: '',
        comment: '',
        responsiblePerson: '',
    },
    fieldsNames: {
        name: 'Nazwa procedury',
        isoIndex: 'Index',
        dataArchive: 'Data archiwizacji',
        comment: 'Opis procedury',
        version: 'Wersja',
        procesName: 'Proces',
        responsiblePerson: 'Osoby odpowiedzialne',
    },
};

const getters = {
    getIso: (state) => state.Iso,
    getModalIso: (state) => state.modal.fields,
    getErrorsIso: (state) => state.modal.errors,
    getIsoDetails: (state) => state.details,
    getIsoFieldsNames: (state) => state.fieldsNames,
};

const actions = {
    createIso({
        dispatch,
        commit
    }, payload) {

        Vue.axios({
            method: 'POST',
            url: '/iso',
            data: {
                ...state.modal.fields,
                ...payload,
            },
        })
            .then((response) => {
                dispatch('fetchData', {
                    page: 1,
                    pageSize: 11,
                    search: '',
                    sortBy: [],
                    sortDesc: [],
                    routeName: 'iso',
                });
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie dodano procedurę',
                });
            })
            .catch((error) => {
                commit('setErrors', {
                    route: 'Iso',
                    err: error.response.data.error,
                });
            });

    },

    isoDetails({
        commit
    }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/iso/${payload.id}`,
        })
            .then((response) => {
                if (payload.type == 'modal') {
                    commit('setIsoModal', response.data.item);
                } else {
                    commit('setIsoDetails', response.data.item);
                    commit('setDetailsMenager', response.data.item.menager);
                    commit('setDetailsStatus', response.data.item.status);
                    commit('setDetailsStatuses', response.data.statuses);
                    commit('setDetailsIndex', response.data.item.index);
                    if (payload.next) {
                        payload.next();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == '404') router.push('/NotFound');
            });
    },

    updateIso({
        commit
    }, payload) {
        Vue.axios({
            method: 'PUT',
            url: `/iso/edit/${payload}`,
            data: {
                ...state.modal.fields,
            },
        })
            .then((response) => {
                commit('setIsoDetails', response.data.item);
                commit('closeModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie zmodyfikowano',
                });
            })
            .catch((error) => {
                commit('setErrors', {
                    route: 'Iso',
                    err: error.response.data.error,
                });
            });
    },
};

const mutations = {
    removeUserISO(state, payload) {
        let arr = state.modal.fields.responsiblePerson;
        let index = findIndex(arr, (e) => e == payload);
        arr.splice(index, 1);
    },
    setIsoDetails(state, payload) {
        state.details = payload;
    },
    setIsoModal(state, payload) {
        state.modal.fields = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};