import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
	modal: {
		fields: {
			carModelname: '',
		},
		open: false,
	},
	type: '',
	items: [],
	errors: {
		carModelname: '',
	},
};

const getters = {
	getCarsDictionary: (state) => state.modal.fields,
	getCarsDictionaryItems: (state) => state.items,
	getCarsOpen: (state) => state.modal,
	getCarsErr: (state) => state.errors,
	getCarsType: (state) => state.type,
};

const actions = {
	createModelCar({ commit, state }) {
		state.errors.carModelname = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/cars/models',
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				}),
					commit('setCarItem', response.data.item),
					commit('clearModelCar');
			})
			.catch((error) => {
				console.log(error);
				if (error.response.data.error === 'Exists')
					state.errors.carModelname = 'Nazwa jest już używana';
				else if (
					error.response.data.error.errors.carModelname.kind === 'required'
				) {
					state.errors.carModelname = 'Pole jest wymagane';
				}
			});
	},

	fetchModelCar({ commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/cars/models',
		}).then((response) => {
			commit('setModelCarItems', response.data.items);
		});
	},

	deleteModelCar({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/cars/models/${payload}`,
		})
			.then(() => {
				commit('deleteCarItem', payload);
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	ModelCarDeatils({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/cars/models/${payload}`,
		})
			.then((response) => {
				commit('setCarsDetailsDictionary', response.data.item);
				response.data.item;
			})
			.catch((errors) => {
				console.log(errors);
			});
	},

	ModelCarUpdate({ commit }) {
		state.errors.carModelname = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/cars/models/update/${state.modal._id}`,
			data: {
				...state.modal.fields,
			},
		})
			.then((response) => {
				commit('clearModelCar');
				commit('updateModelCar', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch((error) => {
				if (error.response.data.error === 'Exists') {
					state.errors.carModelname = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.carModelname.kind === 'required'
				) {
					state.errors.carModelname = 'Pole jest wymagane';
				}
			});
	},
};

const mutations = {
	setModelCarDeatils(state, payload) {
		state.type = payload;
	},
	setModelCarItems(state, payload) {
		state.items = payload;
	},
	clearModelCar(state) {
		state.modal.open = false;
		state.modal.fields.carModelname = '';
		state.errors.carModelname = '';
	},
	updateModelCar(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
	setCarsDetailsDictionary(state, payload) {
		state.modal.fields.carModelname = payload.carModelname;
		state.modal._id = payload._id;
		state.modal.open = true;
	},
	setCarItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	deleteCarItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, (e) => e._id == payload);

		arr.splice(index, 1);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
