<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="14" md="6">
                <v-text-field
                    dense
                    v-model="getModalTenders.nrTenders"
                    :label="getTendersFieldsNames.nrTenders"
                    :error-messages="getTendersErrors.nrTenders"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-0 pl-3">
                <v-menu
                    v-model="dateT"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="getModalTenders.dataTenders"
                    dense
                    style="padding-top: 18px"
                    :label="getTendersFieldsNames.dataTenders"
                    prepend-icon="mdi-calendar"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    first-day-of-week="1"
                    v-model="getModalTenders.dataTenders"
                    @input="dateT = false"
                ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="pa-0 pl-3">
                <v-menu
                ref="menu"
                v-model="timeT"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    dense
                    v-model="getModalTenders.timeTenders"
                    :label="getTendersFieldsNames.timeTenders"
                    style="padding-top: 18px"
                    prepend-icon="mdi-clock-outline"
                    v-on="on"
                    ></v-text-field>
                </template>
                    <v-time-picker
                    v-if="timeT"
                    @click:minute="$refs.menu.save(time)"
                    v-model="getModalTenders.timeTenders"
                    format="24hr"
                    full-width
                    ></v-time-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="14" md="12">
                <v-autocomplete
                    v-model="getModalTenders.contractorTenders"
                    dense
                    class="font-devices"
                    :label="getTendersFieldsNames.contractorTenders"
                    :hide-no-data="true"
                    hint="Zacznij pisać..."
                    @keyup="findContacts($event)"
                    :items="getTendersItems"
                    item-text="name"
                    return-object
                    @input="setClientThings($event)"
                >
                    <template slot="prepend">
                        <v-icon color="#b3b3b3" size="18">mdi-account</v-icon>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0" >
                <v-tabs centered>
                    <v-tab ripple>reprezentant</v-tab>
                    <v-tab ripple>dodaj nowego reprezentanta</v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-autocomplete
                                    v-model="getModalTenders.responsibleTenders"
                                    dense
                                    class="font-devices"
                                    :label="getTendersFieldsNames.responsibleTenders"
                                    item-value="_id"
                                    item-text="name"
                                    :items="getTendersRepresentatives"
                                >
                                    <template v-slot:selection="data">
                                        <p class="pa-0 ma-0">
                                            {{data.item.name}} {{data.item.lastname}}
                                            <span
                                                v-if="data.item.phoneNumber"
                                            >• {{data.item.phoneNumber}}</span>
                                            <span
                                                v-if="data.item.email"
                                            >&nbsp;&nbsp;• {{data.item.email}}</span>
                                        </p>
                                    </template>
                                    <template v-slot:item="data">
                                        <p class="pa-0 ma-0">
                                            {{data.item.name}} {{data.item.lastname}}
                                            <span
                                                v-if="data.item.phoneNumber"
                                            >• {{data.item.phoneNumber}}</span>
                                            <span
                                                v-if="data.item.email"
                                            >&nbsp;&nbsp;• {{data.item.email}}</span>
                                        </p>
                                    </template>
                                    <template slot="prepend">
                                        <v-icon color="#b3b3b3" size="18">mdi-phone</v-icon>
                                    </template>
                                    <template slot="append">
                                        <v-icon color="#445D75" size="18">mdi-menu</v-icon>
                                    </template>
                                </v-autocomplete>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getTendersFieldsNames.responsiblenewnameTenders"
                                            required
                                            v-model="getModalTenders.responsiblenewnameTenders"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getTendersFieldsNames.responsiblenewlastTenders"
                                            required
                                            v-model="getModalTenders.responsiblenewlastTenders"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getTendersFieldsNames.responsiblenewemailTenders"
                                            required
                                            v-model="getModalTenders.responsiblenewemailTenders"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            :label="getTendersFieldsNames.responsiblenewephoneTenders"
                                            required
                                            v-model="getModalTenders.responsiblenewephoneTenders"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-col>
            <v-col cols="12" md="12" sm="6" >
                <v-text-field
                    dense
                    v-model="getModalTenders.linkTenders"
                    :label="getTendersFieldsNames.linkTenders"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="6">
                <v-textarea
                    rows="2"
                    :label="getTendersFieldsNames.commentTenders"
                    v-model="getModalTenders.commentTenders"
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="6" class="pt-0">
                <v-col cols="12" md="12">
                    <Separator :text="'Uprawnienia twórcy'" />
                </v-col>
                <v-col cols="12" md="6">
                               


                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import debounce from "lodash.debounce";

export default {
    components: {
        Separator
    },
    data() {
        return {
            responsibleTenders: "not_accepted"
        };
    },
    defaultMenagerItems: [],
    data: () => ({
        dateT: false,
        timeT: false,
        time: null,
    }),
    computed: {
        ...mapGetters([
            "getModalTenders",
            "getTendersFieldsNames",
            "getTendersItems",
            "getTendersRepresentatives",
            "getTendersErrors"
        ])
    },
    methods: {
        ...mapActions(["fetchContacts"]),
        ...mapMutations(["closeModal", "setClientThings", "setClientItems"]),
        findContacts: debounce(function(e) {
            this.fetchContacts(e.target.value);
        }, 500)
    }
};
</script>