var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"label":"Twórca","readonly":"","value":_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.turnedOn &&
                        _vm.getImplementationDetails.implementation
                            .implementationCreatedBy &&
                        _vm.getImplementationDetails.implementation
                            .implementationCreatedBy.name
                            ? ((_vm.getImplementationDetails.implementation.implementationCreatedBy.name) + " " + (_vm.getImplementationDetails.implementation.implementationCreatedBy.lastname))
                            : ''}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"readonly":"","label":"Klient","value":_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.turnedOn &&
                        _vm.getImplementationDetails.contractorOffers &&
                        _vm.getImplementationDetails.contractorOffers.name
                            ? _vm.getImplementationDetails.contractorOffers.name
                            : ''}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"readonly":"","label":"Reprezentant","value":((_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.turnedOn &&
                        _vm.getImplementationDetails.responsibleOffers &&
                        _vm.getImplementationDetails.responsibleOffers.name
                            ? _vm.getImplementationDetails.responsibleOffers
                                  .name +
                              ' ' +
                              _vm.getImplementationDetails.responsibleOffers
                                  .lastname
                            : '') + " " + (_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.turnedOn &&
                        _vm.getImplementationDetails.responsibleOffers &&
                        _vm.getImplementationDetails.responsibleOffers.email
                            ? '• ' +
                              _vm.getImplementationDetails.responsibleOffers
                                  .email
                            : '') + " " + (_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.turnedOn &&
                        _vm.getImplementationDetails.responsibleOffers &&
                        _vm.getImplementationDetails.responsibleOffers
                            .phoneNumber
                            ? _vm.getImplementationDetails.responsibleOffers
                                  .phoneNumber
                            : ''))}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"readonly":"","label":"Utworzono wdrożenie","value":_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation
                            .implementationDateChecked &&
                        _vm.getImplementationDetails.implementation.turnedOn
                            ? _vm.$moment(
                                  _vm.getImplementationDetails.implementation
                                      .implementationDateChecked
                              ).format('YYYY-MM-DD HH:mm:ss')
                            : ''}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"readonly":"","label":"Data i czas wdrożenia","value":((_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation
                            .implementationDate
                            ? _vm.$moment(
                                  _vm.getImplementationDetails.implementation
                                      .implementationDate
                              ).format('YYYY-MM-DD')
                            : '') + " " + (_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation
                            .implementationTime
                            ? _vm.getImplementationDetails.implementation
                                  .implementationTime
                            : ''))}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"no-pointer",attrs:{"readonly":"","label":"Podstawa wdrożenia","value":_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.basic
                            ? _vm.getImplementationDetails.implementation.basic
                            : ''}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{staticClass:"no-pointer",attrs:{"readonly":"","no-resize":"","label":"Opis","value":_vm.getImplementationDetails &&
                        _vm.getImplementationDetails.implementation &&
                        _vm.getImplementationDetails.implementation.description
                            ? _vm.getImplementationDetails.implementation
                                  .description
                            : ''}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }