<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === OD === === -->
            <td>
                <v-list-item-title class="tabeleFont">{{item.createdBy ? `${item.createdBy.name} ${item.createdBy.lastname}` : 'Brak'}}</v-list-item-title>
                <v-list-item-subtitle
                    class="grey--text tabeleFont"
                >{{ item.createdBy._id.toString() == getUser.id.toString() ? `Własne` : '' }}</v-list-item-subtitle>
            </td>

            <!-- === === DO === === -->
            <td>
                <v-menu open-on-hover v-if="item.to">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-list-item-title class="tabeleFont">
                                <span v-if="item.to.users.length > 0">
                                    {{ item.to.users[0].name }}
                                    {{ item.to.users[0].lastname }}
                                </span>
                                <span
                                    v-else-if="item.to.departments.length > 0"
                                >{{ item.to.departments[0].fullName }}</span>
                                <span v-else>Brak</span>
                            </v-list-item-title>
                            <v-list-item-subtitle
                                class="grey--text lighten-1"
                                v-if="item.to.users.length + item.to.departments.length > 1"
                                v-on="on"
                            >
                                + {{ item.to.users.length + item.to.departments.length - 1 }}
                                <span
                                    v-if="item.to.users.length + item.to.departments.length - 1 == 1"
                                >inny</span>
                                <span v-else>innych</span>
                            </v-list-item-subtitle>
                        </div>
                    </template>
                    <v-card class="pa-3">
                        <div v-if="item.to.users.length > 0">
                            <p class="mb-0">Pracownicy:</p>
                            <ul>
                                <li v-for="user in item.to.users" :key="user._id">
                                    {{ user.name }}
                                    {{ user.lastname }}
                                </li>
                            </ul>
                        </div>
                        <div v-if="item.to.departments.length > 0">
                            <p class="mb-0">Departamenty:</p>
                            <ul>
                                <li
                                    v-for="department in item.to.departments"
                                    :key="department._id"
                                >{{department.fullNames}}</li>
                            </ul>
                        </div>
                    </v-card>
                </v-menu>
            </td>

            <!-- === === TREŚĆ === ===  -->
            <td class="tabeleFont">{{item.description}}</td>

            <!-- === === KIEDY === === -->
            <td>
                <v-list-item-title class="tabeleFont">{{ $moment(item.dueDate).format('YYYY-MM-DD') }}</v-list-item-title>
                <v-list-item-subtitle class="grey--text lighten-1 tabeleFont">{{ item.dueTime }}</v-list-item-subtitle>
            </td>

            <!-- === === PRIORITY === ===  -->
            <td>
                <v-chip
                    :color="item.priority.background"
                    :style="`color: ${item.priority.color}`"
                    label
                    v-if="item.priority"
                    small
                >{{item.priority.priorityName}}</v-chip>
                <v-chip dark v-else label small>Brak</v-chip>
            </td>
            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="details(item._id)">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters(["getUser"])
    },
    methods: {
        ...mapActions(["alertDetails"]),
        details(id) {
            this.alertDetails({ id, registryName: this.$route.name });
            this.$store.dispatch("openModal", {
                headLine: "global.details",
                open: true,
                type: "details",
                width: 600,
                route: "notifications",
                tab: "notification"
            });
        }
    }
};
</script>