<template>
  <v-col cols="12" md="12" class="d-flex flex-wrap">
    <v-col cols="12" md="12" class="py-0">
      <Separator :text="'Dane kontaktu'"></Separator>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field
        dense
        label="Pełna nazwa"
        v-model="getContactsData.name"
        :error-messages="getContactsErrors.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Nazwa skrócona"
        v-model="getContactsData.shortName"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="ID Kontaktu"
        v-model="getContactsData.contactId"
        :error-messages="getContactsErrors.contactId"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field
        dense
        label="NIP"
        v-if="getContactsData.contactType == 'Osoba prawna'"
        v-model="getContactsData.NIP"
        :error-messages="getContactsErrors.NIP"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" class="py-0">
      <Separator :text="'Dane szczegółowe'"></Separator>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Ulica"
        v-model="getContactsData.street"
        :error-messages="getContactsErrors.street"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Miasto"
        v-model="getContactsData.city"
        :error-messages="getContactsErrors.city"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        dense
        label="Numer domu"
        v-model="getContactsData.houseNumber"
        :error-messages="getContactsErrors.houseNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        dense
        label="Numer lokalu"
        v-model="getContactsData.apartmentNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Poczta"
        v-model="getContactsData.post"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Kod pocztowy"
        v-model="getContactsData.postCode"
        :error-messages="getContactsErrors.postCode"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Państwo"
        v-model="getContactsData.state"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Email"
        v-model="getContactsData.email"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Numer telefonu"
        v-model="getContactsData.phoneNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        dense
        label="Adres www"
        v-model="getContactsData.website"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" class="py-0">
      <Separator :text="'Rodzaj'"></Separator>
    </v-col>
    <v-col cols="12" md="6" class="pb-0 py-1">
      <v-select
        :items="select.type"
        label="Typ kontaktu"
        v-model="getContactsData.type"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6" class="pb-0 py-1">
      <v-select
        :items="select.kind"
        label="Rodzaj kontaktu"
        v-model="getContactsData.contactType"
        :error-messages="getContactsErrors.contactType"
      ></v-select>
    </v-col>
  </v-col>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    Separator,
  },
  data: () => ({
    select: {
      type: ["Wszyscy", "Kontrahenci", "Klienci"],
      kind: ["Osoba prawna", "Osoba fizyczna"],
    },
  }),
  computed: {
    ...mapGetters(["getContactsData", "getContactsErrors"]),
  },
  methods: {
    ...mapActions(["fetchItems"]),
  },
};
</script>