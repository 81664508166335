<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" lg="7" class="d-flex flex-wrap py-0">
                <v-col cols="12" md="12" class="pt-0 p-relative">
                    <v-col cols="12" md="12">
                        <Separator :text="'Wyszukiwanie części'" />
                    </v-col>
                    <v-col cols="6" md="4" class="pt-0">
                        <v-text-field
                            dense
                            v-model="search"
                            append-icon="mdi-search"
                            label="Szukaj"
                            single-line
                            hide-details
                            class="mb-3"
                        >
                            <template slot="prepend">
                                <img
                                    src="@/assets/img/global/search.png"
                                    width="16"
                                    height="16"
                                    alt
                                    class="mt-1"
                                />
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-data-table
                        style="border-bottom: 1px solid grey"
                        height="190"
                        fixed-header
                        :mobile-breakpoint="1100"
                        hide-default-footer
                        :headers="headers"
                        :items="getServicePartsDetails ? getServicePartsDetails.partsItems : []"
                        :search="search"
                    >
                        <template v-slot:item.price="{item}">
                            <money
                                style="text-align: left; font-size: 14px;color: #272727; "
                                v-model="item.price"
                                v-bind="money"
                            ></money>
                        </template>
                        <template v-slot:item.sum="{item}">
                            <span
                                class="sum"
                            >{{item.price ? `${(item.price * item.quantity).toFixed(2)} PLN` : `0.00 PLN`}}</span>
                        </template>
                    </v-data-table>
                    <span
                        class="d-flex justify-end"
                        style="margin-top: 5px; font-size: 14px; font-weight: 500"
                    >
                        <span>RAZEM: {{getServicePartsDetails.totalSum ? getServicePartsDetails.totalSum.toFixed(2) + ' PLN ' : '0'}}</span>
                    </span>
                </v-col>
                <v-col cols="12" md="12" class="pt-5">
                    <Separator :text="'Rozliczenie części'" />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        :value="`${getServicePartsDetails && getServicePartsDetails.invoiceNumber ? getServicePartsDetails.invoiceNumber : ''}`"
                        readonly
                        class="no-pointer"
                        label="Nr faktury sprzedanych części"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                        dense
                        readonly
                        class="no-pointer"
                        auto-grow
                        rows="1"
                        :value="`${getServicePartsDetails && getServicePartsDetails.description ? getServicePartsDetails.description : ''}`"
                        label="Uwagi"
                        no-resize
                    ></v-textarea>
                </v-col>

                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        readonly
                        class="no-pointer"
                        :value="`${getServicePartsDetails.inside && getServicePartsDetails.inside.date ? $moment(getServicePartsDetails.inside.date).format('YYYY-MM-DD') : ''}`"
                        label="Data rozliczenia wewnętrznego"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        readonly
                        class="no-pointer"
                        :value="`${getServicePartsDetails && getServicePartsDetails.inside ? getServicePartsDetails.inside.number : ''}` "
                        label="Numer dokumentu wewn."
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        readonly
                        class="no-pointer"
                        label="Data rozliczenia zewnętrznego"
                        :value="`${getServicePartsDetails.outside && getServicePartsDetails.outside.date ? $moment(getServicePartsDetails.outside.date).format('YYYY-MM-DD') : ''}` "
                    ></v-text-field>
                </v-col>
                <!-- {{getServicePartsDetail}} -->
                <v-col cols="12" md="3">
                    <v-text-field
                        dense
                        readonly
                        class="no-pointer"
                        label="Numer dokumentu zewn."
                        :value="`${getServicePartsDetails && getServicePartsDetails.outside ? getServicePartsDetails.outside.number :''}` "
                    ></v-text-field>
                </v-col>
            </v-col>
            <v-col cols="12" md="5"></v-col>
        </v-row>
    </v-container>
</template>


    
<script>
import { mapActions, mapGetters } from "vuex";
import { Money } from "v-money";
import Separator from "@/components/Global/Separator.vue";
export default {
    components: {
        Separator
    },
    data: () => ({
        search: "",
        money: {
            decimal: ".",
            thousands: ",",
            suffix: " PLN",
            precision: 2,
            masked: false
        },
        headers: [
            {
                text: "Index",
                align: "left",
                value: "producent",
                width: 80
            },
            { text: "Nazwa", value: "name", width: 150 },
            // { text: "Model", value: "model", width: 100 },
            { text: "Zamiennik", value: "replacement", width: 120 },
            { text: "Cena", value: "price", width: 80 },
            { text: "Ilość", value: "quantity", width: 80 },
            { text: "Razem", value: "sum", width: 105 }
        ]
    }),
    computed: {
        ...mapGetters(["getServicePartsDetails"])
    }
};
</script>