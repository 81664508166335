var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,style:(item.status &&
      item.status.possibleStatus &&
      item.status.possibleStatus.resolved
        ? 'color: #9e9e9e'
        : '')},[_c('td',{staticClass:"serviceMobileView"},[_vm._v(_vm._s(item.index))]),_c('td',{staticClass:"hidedMobileColumns",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.createdAt ? _vm.$moment(item.createdAt).format('YYYY-MM-DD') : 'Brak')+" "),_c('br'),_vm._v(" "+_vm._s(item.createdAt ? _vm.$moment(item.createdAt).format('HH:mm') : 'Brak')+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('td',_vm._g({staticClass:"service-text-14",class:item.device.client && item.device.client._id ? 'pointer' : '',on:{"click":function($event){return _vm.goToContacts(item.device.client._id)}}},on),[_vm._v(" "+_vm._s(item.device && item.device.client && item.device.client._id ? item.device.client.name : item.service.temporaryInformation.client ? item.service.temporaryInformation.client : 'Brak')+" ")])]}}],null,true)},[_c('span',[_vm._v("ID: "+_vm._s(item.device && item.device.client && item.device.client.contactId ? item.device.client.contactId : 'Brak'))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('td',_vm._g({staticClass:"serviceMobileView"},on),[_c('v-list-item-title',{staticClass:"tabeleFont"},[(item.device.modelDevices.producer)?_c('span',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(item.device.modelDevices.producer)+" "+_vm._s(item.device.modelDevices.model)+" ")]):_c('span',{staticClass:"pa-0"},[_vm._v(_vm._s(item.service.temporaryInformation.devices ? item.service.temporaryInformation.devices : 'Brak'))])]),_c('v-list-item-subtitle',{staticClass:"grey--text lighten-1",staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(item.device.modelDevices.deviceType ? item.device.modelDevices.deviceType : '')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("S/N: "+_vm._s(item.device.SN))])]),_c('td',{staticClass:"hidedMobileColumns serviceMobileView"},[_c('v-menu',{attrs:{"disabled":item.status === 'Usunięty' || _vm.getPermission.level < 2},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var menu = ref.on;
return [_c('span',_vm._g({staticClass:"pointer",on:{"click":function($event){return _vm.getServiceman()}}},menu),[_vm._v(" "+_vm._s(item.service.descriptionIntervention.technicPerforming ? ((item.service.descriptionIntervention.technicPerforming.name) + " " + (item.service.descriptionIntervention.technicPerforming.lastname)) : 'Brak')+" ")])]}}],null,true)},[_c('v-list',[_vm._l((_vm.serviceman),function(servicemen,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"36px,"},on:{"click":function($event){return _vm.serviceEditInTable({
                id: item._id,
                technic: servicemen,
              })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(servicemen.name)+" "+_vm._s(servicemen.lastname)+" ")])],1)}),_c('v-list-item',{on:{"click":function($event){return _vm.serviceEditInTable({
                id: item._id,
                technic: 'Brak',
              })}}},[_vm._v("Brak")])],2)],1)],1),_c('td',[_c('v-menu',{attrs:{"disabled":!_vm.isLogistic},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var menu = ref.on;
return [_c('v-chip',_vm._g({staticClass:"serviceMobileView",style:(("color: " + (item.status &&
                item.status.possibleStatus &&
                item.status.possibleStatus.color &&
                item.status.possibleStatus.color.font
                  ? item.status.possibleStatus.color.font
                  : '#FFFFFF'))),attrs:{"color":item.status &&
              item.status.possibleStatus &&
              item.status.possibleStatus.color &&
              item.status.possibleStatus.color.background
                ? item.status.possibleStatus.color.background
                : '#555555',"dark":"","label":"","small":""},on:{"click":_vm.getStatuses}},menu),[_vm._v(" "+_vm._s(item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak')+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.statuses),function(status){return _c('v-list-item',{key:status._id,on:{"click":function($event){_vm.setStatus({
                registryName: 'service_details',
                registryID: item._id,
                status: status,
              }).then(function () { return (item.status.possibleStatus = status); })}}},[_vm._v(_vm._s(status.name))])}),1)],1)],1),_c('td',[_c('v-list-item-title',{staticClass:"title--smaller tabeleFont"},[_c('v-avatar',{staticStyle:{"margin-left":"5px","margin-right":"6px"},attrs:{"color":"orange","size":"12"}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"11px","margin-top":"2px"}},[_vm._v("P")])]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px","disabled":_vm.getPermission.level < 2},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({staticClass:"pointer",on:{"click":function($event){return _vm.showCorrecrDateTerm(
                  item.service.descriptionIntervention.finishDate
                )}}},on),[_vm._v(" "+_vm._s(item.service.descriptionIntervention.finishDate ? _vm.$moment( item.service.descriptionIntervention.finishDate ).format('YYYY-MM-DD') : '------')+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){return _vm.serviceEditInTable({
                id: item._id,
                finishDate: _vm.termsDate,
              })}},model:{value:(_vm.termsDate),callback:function ($$v) {_vm.termsDate=$$v},expression:"termsDate"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","disabled":_vm.getPermission.level < 2},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-1 pointer",on:{"click":function($event){return _vm.setTermsTime(item.service.descriptionIntervention.finishTime)}}},on),[_vm._v(" "+_vm._s(item.service.descriptionIntervention.finishDate && !item.service.descriptionIntervention.finishTime ? '--' : item.service.descriptionIntervention.finishTime)+" ")])]}}],null,true)},[_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.serviceEditInTable({
                id: item._id,
                finishTime: _vm.termsTime,
              })}},model:{value:(_vm.termsTime),callback:function ($$v) {_vm.termsTime=$$v},expression:"termsTime"}})],1)],1),(
          item.implementation &&
            item.implementation.service &&
            item.implementation.service.dates &&
            item.implementation.service.dates.end
        )?_c('v-list-item-title',{staticClass:"title--smaller tabeleFont"},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"red","size":"15"}},[_vm._v("mdi-stop-circle")]),_vm._v(" "+_vm._s(_vm.$moment(item.implementation.service.dates.end).format('YYYY-MM-DD'))+" "+_vm._s(item.implementation.service && item.implementation.service.times && item.implementation.service.times.end ? item.implementation.service.times.end : 'Brak')+" ")],1):_c('v-list-item-title',{staticClass:"title--smaller"},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"red","size":"15"}},[_vm._v("mdi-stop-circle")]),_vm._v("------ ")],1)],1),_c('td',[_c('div',{staticClass:"d-flex"},[(item.implementation)?_c('div',{staticClass:"d-flex"},[(item.implementation.requirements.invoice.checkbox)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",style:(("background-color: " + (item.implementation.requirements.invoice.issue) + ";  width: " + (_vm.window.width < 1200 ? '20px' : '28px') + " ; height: " + (_vm.window.width < 1200 ? '20px' : '28px'))),attrs:{"icon":"","disabled":("" + (item.implementation.requirements.invoice.issue)) ===
                  'grey'
                    ? true
                    : false},on:{"click":function($event){return _vm.changeStatus(item._id, 'invoice_status')}}},on),[_c('span',{staticStyle:{"color":"white"}},[_vm._v("F")])])]}}],null,true)},[_c('span',[_vm._v("Faktura")])]):_vm._e(),(item.implementation.requirements.claim.checkbox)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",style:(("background-color: " + (item.implementation.requirements.claim.issue) + "; width: " + (_vm.window.width < 1200 ? '20px' : '28px') + " ; height: " + (_vm.window.width < 1200 ? '20px' : '28px') + ";\n                                      cursor: " + (item.implementation.requirements.claim
                                          .issue == 'grey'
                                          ? 'default'
                                          : 'pointer'))),attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.changeStatus(
                    item._id,
                    'claim_status',
                    item.implementation.requirements.claim.issue
                  )}}},on),[_c('span',{staticStyle:{"color":"white"}},[_vm._v("C")])])]}}],null,true)},[_c('span',[_vm._v(" Claim "),(
                  item.implementation.requirements.claim &&
                    item.implementation.requirements.claim.number
                )?_c('span',[_vm._v("nr: "+_vm._s(item.implementation.requirements.claim.number))]):_vm._e()])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(
                  item.implementation.requirements.valuation &&
                    item.implementation.requirements.valuation.checkbox
                )?_c('v-btn',_vm._g({staticClass:"mr-1",style:(("background-color: " + (item.implementation.requirements.valuation.issue) + "; width: " + (_vm.window.width < 1200 ? '20px' : '28px') + " ; height: " + (_vm.window.width < 1200 ? '20px' : '28px'))),attrs:{"small":"","icon":"","disabled":("" + (item.implementation.requirements.valuation.issue)) ===
                  'grey'
                    ? true
                    : false},on:{"click":function($event){return _vm.changeStatus(item._id, 'valuation_status')}}},on),[_c('span',{staticStyle:{"color":"white"}},[_vm._v("W")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Wycena")])]),(item.implementation.requirements.continuation)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-1",attrs:{"color":"orange","size":_vm.window.width > 1200 ? '28' : '20'}},on),[_c('v-btn',{staticClass:"white--text",style:(("background: " + (item.service.realisation.continuationIssued == true
                        ? 'grey !important'
                        : 'orange !important') + "; ")),attrs:{"disabled":item.service.realisation.continuationIssued == true
                      ? true
                      : false},on:{"click":function($event){return _vm.newContinuation(item)}}},[_c('span',{staticClass:"white--text"},[_vm._v("K")])])],1)]}}],null,true)},[_c('span',[_vm._v("Kontynuacja")])]):_vm._e()],1):_vm._e(),(item.service.realisation.continuation)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-1",attrs:{"color":item.status.possibleStatus.name == 'Zamknięty'
                  ? '#808080'
                  : 'green',"size":_vm.window.width > 1200 ? '28' : '20'}},on),[_c('span',{staticClass:"white--text"},[_vm._v("K")])])]}}],null,true)},[_c('span',[_vm._v("Kontynuacja | "+_vm._s(item.lastIndex))])]):_vm._e(),(item.service.realisation.urgent)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-1",attrs:{"color":"red","size":_vm.window.width > 1200 ? '28' : '20'}},on),[_c('span',{staticClass:"white--text"},[_vm._v("P")])])]}}],null,true)},[_c('span',[_vm._v("Pilne")])]):_vm._e(),(item.service.realisation.warranty)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-1",attrs:{"color":"#964B84","size":_vm.window.width > 1200 ? '28' : '20'}},on),[_c('span',{staticClass:"white--text"},[_vm._v("G")])])]}}],null,true)},[_c('span',[_vm._v("Gwarancja")])]):_vm._e()],1)]),_c('td',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ path: ("/service/" + (item._id)) })}}},[_c('v-icon',{style:(("font-size: " + (_vm.window.width < 1200 ? '22px' : '16px'))),attrs:{"color":"#19aa8d","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }