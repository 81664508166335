<template>
    <div>
        <v-tooltip left v-if="getPermission.level > 1">
            <template v-slot:activator="{ on }">
                <v-btn fab dark v-on="on" small class="buttons--add" @click="createCar">
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>Dodaj nowy samochód</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    data: () => ({}),
    methods: {
        ...mapActions(["openModal", "fetchModelCar", "fetchItems"]),
        ...mapMutations(["resetDeviceItems"]),
        createCar() {
            this.resetDeviceItems();
            this.fetchItems("users");
            this.fetchModelCar();
            this.openModal({
                headLine: "global.create",
                open: true,
                width: 900,
                type: "create",
                route: "cars",
                tab: "cars"
            });
        }
    },

    computed: {
        ...mapGetters(["getPermission"])
    }
};
</script>
