<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="6">
                <v-textarea
                    row-height="50"
                    auto-grow
                    class="no-pointers"
                    label="Poświadczenia:"
                    v-model="getModalDevices.implementationData.precertifying"
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
                <v-textarea
                    row-height="50"
                    auto-grow
                    class="no-pointers"
                    label="Ustawienia:"
                    v-model="getModalDevices.implementationData.settings"
                ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["getModalDevices"])
    },
    methods: {},
    components: {
        Separator
    }
};
</script>