
<template>
  <v-container class="px-5">
    <v-row>
      <v-col cols="5" class>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              readonly
              v-model="getLeasingDetails.nrLeasing"
              :label="getLeasingFieldsNames.nrLeasing"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              readonly
              v-model="getLeasingDetails.titleLeasing"
              :label="getLeasingFieldsNames.titleLeasing"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              readonly
              :value="getLeasingDetails.contractorLeasing ? getLeasingDetails.contractorLeasing.name :''"
              dense
              :label="getLeasingFieldsNames.contractorLeasing"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              readonly
              :value="getLeasingDetails.responsibleLeasing ? showcorrectResponsible(getLeasingDetails.responsibleLeasing.name, getLeasingDetails.responsibleLeasing.lastname, getLeasingDetails.responsibleLeasing.phoneNumber, getLeasingDetails.responsibleLeasing.email) : 'Brak'"
              dense
              :label="getLeasingFieldsNames.responsibleLeasing"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              readonly
              v-model="getLeasingDetails.subjectLeasing"
              dense
              :label="getLeasingFieldsNames.subjectLeasing"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              readonly
              v-model="getLeasingDetails.commentLeasingt"
              dense
              :label="getLeasingFieldsNames.commentLeasingt"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-row>
          <v-col cols="6">
            <v-text-field
              readonly
              v-model="getLeasingDetails.databeginningLeasing"
              dense
              :label="getLeasingFieldsNames.databeginningLeasing"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <div class="input-effect price" style="margin-top: -10px; margin-bottom: 20px">
              <span class="mt-0 mb-0">{{getLeasingFieldsNames.valuenettoLeasing}}</span>
              <money
                style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
                v-bind="money"
                readonly
                v-model="getLeasingDetails.valuenettoLeasing"
              ></money>
              <span class="focus-border"></span>
            </div>
          </v-col>

          <v-col cols="6">
            <v-text-field
              readonly
              v-model="getLeasingDetails.dataendLeasing"
              dense
              :label="getLeasingFieldsNames.dataendLeasing"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <div class="input-effect price" style="margin-top: -10px; margin-bottom: 20px">
              <span class="mt-0 mb-0">{{getLeasingFieldsNames.valuemonthlyLeasing}}</span>
              <money
                style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
                v-bind="money"
                readonly
                v-model="getLeasingDetails.valuemonthlyLeasing"
              ></money>
              <span class="focus-border"></span>
            </div>
            <v-row>
              <v-col cols="12">
                <div class="input-effect price" style="margin-top: -10px; margin-bottom: 20px">
                  <span class="mt-0 mb-0">{{getLeasingFieldsNames.installmentLeasing}}</span>
                  <money
                    style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
                    v-bind="money"
                    readonly
                    v-model="getLeasingDetails.installmentLeasing"
                  ></money>
                  <span class="focus-border"></span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="input-effect price" style="margin-top: -10px; margin-bottom: 20px">
                  <span class="mt-0 mb-0">{{getLeasingFieldsNames.repurchaseLeasing}}</span>
                  <money
                    style="text-align: left; font-size: 14px; width: 100%; border-bottom: 1px solid #949494; color: #272727; "
                    v-bind="money"
                    readonly
                    v-model="getLeasingDetails.repurchaseLeasing"
                  ></money>
                  <span class="focus-border"></span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="12">
            <span class="title font-weight-light">Dodaj przedmioty leasingu</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <div class="px-4 pt-2 dictionary__header">
              <span class="title font-weight-light">Pojazdy</span>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="addAuto">
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Dodaj pojazd</span>
              </v-tooltip>
            </div>
            <v-simple-table height="250" fixed-header>
              <thead>
                <tr>
                  <th>Marka</th>
                  <th>VIN</th>
                  <th class="text-right">Akcje</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tabAuto in getLeasingDetails.tabAuto" :key="tabAuto._id">
                  <td>{{ tabAuto.tabAutoName }}</td>
                  <td>{{ tabAuto.tabAutoVIN }}</td>
                  <td class="text-right">
                    <v-btn icon x-small @click="deleteModel(models._id)">
                      <v-icon class="red--text">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col cols="6" md="6">
            <div class="px-4 pt-2 dictionary__header">
              <span class="title font-weight-light">Sprzęt IT</span>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="addIT">
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Dodaj sprzęt IT</span>
              </v-tooltip>
            </div>
            <v-simple-table height="250" fixed-header>
              <thead>
                <tr>
                  <th>Model</th>
                  <th>SN</th>
                  <th class="text-right">Akcje</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tabIT in getLeasingDetails.tabIT" :key="tabIT._id">
                  <td>{{ tabIT.tabModel }}</td>
                  <td>{{ tabIT.tabITSN }}</td>
                  <td class="text-right">
                    <v-btn icon x-small @click="deleteModel(tabIT._id)">
                      <v-icon class="red--text">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>-->
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import Separator from "@/components/Global/Separator.vue";
import { mapActions, mapGetters } from "vuex";
import { money } from "v-money";
export default {
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        suffix: " PLN",
        precision: 2,
        masked: false,
      },
    };
  },
  components: {
    Separator,
  },
  methods: {
    ...mapActions([
      "openModal",
      "leasingCarsDeatils",
      //"fetchTPriority",
      //"fetchServiceBase"
    ]),
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },

    showcorrectResponsible(name, lastname, phoneNumber, email) {
      let Responsible;
      if (name != undefined && name != "") {
        Responsible = name;
      }
      if (lastname != undefined && lastname != "") {
        Responsible = Responsible + " " + lastname;
      }
      if (email != undefined && email != "") {
        Responsible = Responsible + " • " + email;
      }
      if (phoneNumber != undefined && phoneNumber != "") {
        Responsible = Responsible + " • " + phoneNumber;
      }
      return `${Responsible}`;
    },

    addIT() {
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 1000,
        type: "create",
        route: "Leasing",
        tab: "leasingaddIT",
      });
    },
    addAuto() {
      this.leasingCarsDeatils({
        route: this.$route.name,
      });
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 1000,
        type: "create",
        route: "Leasing",
        tab: "leasingaddAuto",
      });
    },

    deleteModel(CMDD) {
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 1000,
        type: "create",
        route: "Leasing",
        tab: "leasingaddAuto",
      });
    },
  },
  computed: {
    ...mapGetters(["getLeasingDetails", "getLeasingFieldsNames"]),
  },
};
</script>