<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" lg="6" class="d-flex flex-wrap">
                <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Model"
                        v-model="getModelDetails.model"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Seria modeli"
                        v-model="getModelDetails.series"
                    ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="4">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Producent"
                        v-model="getModelDetails.producer"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Rodzaj urządzenia"
                        v-model="getModelDetails.deviceType"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Pakiet cenowy"
                        v-model="getModelDetails.price.priceName"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        readonly
                        class="no-pointer"
                        dense
                        label="Wskaźnik kompetencji"
                        v-model="getModelDetails.difficulty"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                        readonly
                        class="no-pointer"
                        no-resize
                        dense
                        label="Opcje"
                        rows="3"
                        v-model="correctOptions"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                        readonly
                        class="no-pointer"
                        dense
                        auto-grow
                        label="Uwagi"
                        rows="1"
                        v-model="getModelDetails.description"
                    ></v-textarea>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["getModelDetails"]),
        correctOptions() {
            let string = "";
            for (let i = 0; i < this.getModelDetails.options.length; i++) {
                if (i == 0) string += `${this.getModelDetails.options[i]},`;
                else string += ` ${this.getModelDetails.options[i]},`;
            }
            return string;
        }
    }
};
</script>