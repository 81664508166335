<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === ZDJĘCIA === === -->
            <td>
                <v-avatar size="30" color="#19AA8D">
                    <img
                        v-if="item.picture"
                        :src="`${getGlobalPaths.profilePic}` + item.picture"
                        alt="avatar"
                    />
                    <span style="color: white" v-else>{{ item.name[0]}}{{ item.lastname[0] }}</span>
                </v-avatar>
            </td>

            <!-- === === IMIĘ === === -->
            <td>{{item.name}}</td>

            <!-- === === NAZWISKO === === -->
            <td>{{item.lastname}}</td>

            <!-- === === STANOWISKO === === -->
            <td>{{item.job}}</td>

            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/workers/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters(["getGlobalPaths"])
    }
};
</script>