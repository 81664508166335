<template>
  <div class="separator">
    <p style="font-weight: 400 !important">
      {{text}}
      <span v-if="subtext" style="font-weight: 400 !important">{{subtext}}</span>
      <span class="ma-0" v-if="clientID" style="font-weight: 600 !important">{{clientID}}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["text", "subtext", "clientID"]
};
</script>

