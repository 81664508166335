<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" class="pt-0">
        <v-text-field
          dense
          v-model="getCurrentInformationModal.title"
          :label="getCurrentInformationFieldsNames.title"
          :error-messages="getCurrentInformationError.title"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4" class="pt-0">
        <v-menu
          v-model="modaldata"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              readonly
              v-model="getCurrentInformationModal.archiveDate"
              dense
              clearable
              :label="getCurrentInformationFieldsNames.archiveDate"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="getCurrentInformationModal.archiveDate"
            @input="modaldata = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="md-6">
        <v-menu
          v-model="actualdata"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              readonly
              v-model="getCurrentInformationModal.startDate"
              dense
              clearable
              :label="getCurrentInformationFieldsNames.startDate"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="getCurrentInformationModal.startDate"
            @input="actualdata = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" v-if="getLayoutWSD.wsd == false">
        <v-autocomplete
          label="Dla kogo?"
          dense
          v-model="getCurrentInformationModal.to[ACPeople]"
          item-value="_id"
          :items="getDropdown.items[ACPeople]"
          multiple
          :filter="filter"
          :class="getModal.type === 'details' ? 'no-pointer' : ''"
          :error-messages="getCurrentInformationError.to"
          :item-disabled="
            getModal.type === 'details' ? [] : getDropdown.items[ACPeople]
          "
        >
          <template slot="append-outer" v-if="getModal.type !== 'details'">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon @click="setAllPeople" v-on="on"
                  >mdi-account-group</v-icon
                >
              </template>
              <span>Wybierz wszystkich</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="data">
            <!-- === === CHIPS === === -->

            <!-- === USERS === -->
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              :close="getModal.type !== 'details'"
              v-if="ACPeople === 'users'"
              @click:close="
                removeIACPeople({
                  id: data.item._id,
                  section: ACPeople,
                })
              "
            >
              <v-avatar left color="secondary" class="mr-2">
                <span class="white--text caption" v-if="!data.item.picture">
                  {{ avatarNick(data.item.name, data.item.lastname) }}
                </span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
              {{ data.item.lastname }} {{ data.item.name }}
            </v-chip>
            <!-- === DEPARTMENTS === -->
            <v-chip
              pill
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
              :close="getModal.type !== 'details'"
              @click:close="
                removeIACPeople({
                  id: data.item._id,
                  section: ACPeople,
                })
              "
              v-else
            >
              <v-avatar left color="secondary" class="mr-2">
                <span class="white--text caption">
                  {{ data.item.shortName.toUpperCase() }}
                </span>
              </v-avatar>
              {{ data.item.fullName }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <!-- === === AVATARS === ===  -->
            <!-- === USERS ===  -->
            <v-list-item-avatar v-if="ACPeople === 'users'">
              <v-avatar size="35" color="secondary">
                <span
                  v-if="!data.item.picture"
                  class="white--text central__avatarFont"
                >
                  {{ avatarNick(data.item.name, data.item.lastname) }}
                </span>
                <img
                  :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                  v-else
                  style="object-fit: cover"
                />
              </v-avatar>
            </v-list-item-avatar>
            <!-- === DEPARTMENTS === -->
            <v-list-item-avatar v-else>
              <v-avatar size="35" color="#445D75">
                <span class="white--text central__avatarFont">
                  {{ data.item.shortName.toUpperCase() }}
                </span>
              </v-avatar>
            </v-list-item-avatar>

            <!-- === === CONTENT === === -->
            <!-- === USERS ===  -->
            <v-list-item-content
              v-if="ACPeople === 'users'"
              :disabled="getModal.type !== 'details'"
            >
              <v-list-item-title>
                {{ data.item.lastname }} {{ data.item.name }}
                <v-chip
                  v-if="data.item.disabled"
                  label
                  x-small
                  color="grey darken-3"
                  class="ml-4"
                >
                  <span class="white--text">Zajęte</span>
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ data.item.job }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- === DEPARTMENTS === -->
            <v-list-item-content v-else>
              <v-list-item-title>
                {{ data.item.fullName }}
                <v-chip
                  v-if="data.item.disabled"
                  label
                  x-small
                  color="grey darken-3"
                  class="ml-4"
                >
                  <span class="white--text">Zajęte</span>
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-tabs
            slot="prepend-item"
            centered
            style="position: sticky; top: 0; z-index: 2; background: white"
            class="elevation-1"
          >
            <v-tab @click="ACPeople = 'users'">Pracownicy</v-tab>
            <v-tab @click="ACPeople = 'departments'">Departamenty</v-tab>
          </v-tabs>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="12" class="pt-0">
        <v-textarea
          :label="getCurrentInformationFieldsNames.information"
          v-model="getCurrentInformationModal.information"
        ></v-textarea>
      </v-col>

      <v-col cols="12" md="7">
        <v-checkbox
          v-model="getCurrentInformationModal.homePage"
          dense
          :label="getCurrentInformationFieldsNames.homePage"
          color="#19aa8d"
          class="mt-1 check__servis"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="5">
        <v-select
          dense
          label="Priorytet"
          v-model="getCurrentInformationModal.priority"
          :items="getTaskDictionary.priority"
          :class="getModal.type === 'details' ? 'no-pointer' : ''"
          return-object
          item-value="priorityName"
          :error-messages="getCurrentInformationError.priority"
          :readonly="getModal.type === 'details'"
        >
          <template slot="append-outer">
            <v-tooltip top>
              <!-- <template v-slot:activator="{ on }">
                <v-icon @click="clearTDPriority" v-on="on">mdi-window-close</v-icon>
              </template>-->
              <span>Wyczyść</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="data">
            <v-chip
              label
              small
              :color="data.item.background"
              :style="`color: ${data.item.color}`"
              >{{ data.item.priorityName }}</v-chip
            >
          </template>
          <template v-slot:item="data">
            <v-chip
              label
              small
              :color="data.item.background"
              :style="`color: ${data.item.color}`"
              >{{ data.item.priorityName }}</v-chip
            >
            <span class="ml-3">{{ data.item.priorityName }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data: () => ({
    ACPeople: "users",
    timePicker: false,
    datePicker: false,
    modaldata: false,
    actualdata: false,
  }),
  computed: {
    ...mapGetters([
      "getDropdown",
      "getCurrentInformationModal",
      "getLayoutWSD",
      "getCurrentInformationError",
      "getModal",
      "getTaskDictionary",
      "getCurrentInformationFieldsNames",
      "getGlobalPaths",
    ]),
  },
  methods: {
    ...mapActions(["fetchTPriority", "fetchItems"]),
    ...mapMutations([
      "removeIACPeople",
      "setAllUsers",
      "clearTDCategory",
      "clearNPriority",
      "setAllIUsers",
    ]),
    avatarNick(name, lastname) {
      let nick = name[0] + lastname[0];
      return nick.toUpperCase();
    },
    setAllPeople() {
      this.ACPeople = "users";
      this.setAllIUsers(this.getDropdown.items.users);
    },
    filter(item, queryText, itemText) {
      if (item.lastname)
        return (
          item.name.toLowerCase().includes(queryText.toLowerCase()) ||
          item.lastname.toLowerCase().includes(queryText.toLowerCase())
        );
      else if (item.fullName) {
        return item.fullName.toLowerCase().includes(queryText.toLowerCase());
      } else return item.name.toLowerCase().includes(queryText.toLowerCase());
    },
  },
  mounted() {
    this.fetchItems("users");
    this.fetchItems("departments");
    // this.fetchTCategory();
    this.fetchTPriority();
  },
};
</script>
