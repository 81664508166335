<template>
  <v-tooltip left v-if="getPermission.level > 1">
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        dark
        v-on="on"
        small
        class="buttons--add"
        @click="createInformation"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    </template>
    <span>Dodaj nową informacje</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({}),
  computed: {
    ...mapGetters(['getPermission']),
  },
  methods: {
    ...mapActions(['openModal']),
    createInformation() {
      this.openModal({
        headLine: 'global.create',
        width: 600,
        open: true,
        type: 'create',
        route: 'coronavirus',
        tab: 'coronavirus',
      });
    },
  },
};
</script>
