import Vue from 'vue';
import findIndex from 'lodash.findindex';
import router from '../../../router';
import i18n from '@/i18n.js';
const state = {
	holiday: false,
	password: false,
	user: {},
	userLanguage: {},
	registries: [],
	modal: {
		password: {
			fields: {
				oldPass: '',
				newPass: '',
				repeatPass: '',
			},
			errors: {
				oldPass: '',
				newPass: '',
				repeatPass: '',
			},
			info: '',
		},
	},
	languages: [{
			name: 'Polski',
			language: 'pl',
			avatar: 'https://image.flaticon.com/icons/svg/197/197529.svg',
		},
		{
			name: 'English',
			language: 'en',
			avatar: 'https://image.flaticon.com/icons/svg/197/197374.svg',
		},
		{
			name: 'Ukraine',
			language: 'ua',
			avatar: 'https://image.flaticon.com/icons/svg/197/197572.svg',
		},
	],
};

const getters = {
	getUser: state => state.user,
	getHoliday: state => state.holiday,
	getPassword: state => state.password,
	getPasswordData: state => state.modal.password,
	getPasswordError: state => state.modal.password.errors,
	getLanguages: state => state.languages,
	getUserLanguage: state => state.userLanguage,
	getUserRegistries: state => state.registries,
};

const actions = {
	async tryChangePassword({
		state,
		commit,
		dispatch
	}) {
		commit('clearCPError');
		await Vue.axios({
				method: 'PUT',
				url: '/password',
				data: {
					...state.modal.password.fields,
				},
			})
			.then(() => {
				commit('closePassword');
				commit('setSecondModal', {
					width: 500,
					type: "change_password",
					open: true,
					persistent: true
				})
				// Vue.swal({
				// 	title: 'Hasło zmienione!',
				// 	text: 'Nastąpi teraz wylogowanie',
				// 	type: 'success',
				// 	confirmButtonText: 'Wyloguj',
				// 	allowOutsideClick: false,
				// }).then(result => {
				// 	if (result.value) {
				// 		Vue.axios({
				// 			method: 'POST',
				// 			url: '/logout',
				// 		}).then(response => {
				// 			if (response.data.done) router.push('/login');
				// 		});
				// 	}
				// });
			})
			.catch(error => {
				commit('setCPError', error.response.data.error);
			});
	},
	async tryChangeLanguage({
		state,
		commit
	}, payload) {
		Vue.axios({
				method: 'PUT',
				url: '/users/language',
				data: {
					language: payload,
				},
			})
			.then(() => {
				commit('setUserLanguage', payload);
				state.user.language = payload;
				i18n.locale = payload;
				// Vue.swal('Sukces!', 'Język został zmieniony', 'success');
				commit("setSnackbars", {
					type: "success",
					text: "Język został zmieniony"
				});
			})
			.catch(error => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit("setSnackbars", {
					type: "error",
					text: "Coś poszło nie tak"
				});
			});
	},
};

const mutations = {
	changeHoliday(state) {
		state.holiday = true;
	},
	closeHoliday(state) {
		state.holiday = false;
	},
	changePassword(state) {
		state.password = true;
	},
	closePassword(state) {
		state.password = false;
		state.modal.password.errors.newPass = '';
		state.modal.password.errors.oldPass = '';
		state.modal.password.errors.repeatPass = '';
		state.modal.password.fields.newPass = '';
		state.modal.password.fields.oldPass = '';
		state.modal.password.fields.repeatPass = '';
	},
	setUser(state, payload) {
		state.user = payload;
	},
	generatePassword(state) {
		const chars =
			'0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

		let code = '';
		for (let i = 0; i < 12; i++) {
			let index = Math.floor(Math.random() * chars.length);
			code += chars[index];
		}
		state.modal.password.fields.newPass = code;
		state.modal.password.fields.repeatPass = code;
		state.modal.password.info = `Wygenerowane hasło to: ${code}`;
	},
	setCPError(state, payload) {
		switch (payload) {
			case 'required':
				state.modal.password.errors.newPass =
					'Wszystkie pola są wymagane';
				state.modal.password.errors.oldPass =
					'Wszystkie pola są wymagane';
				state.modal.password.errors.repeatPass =
					'Wszystkie pola są wymagane';
				break;
			case 'match':
				state.modal.password.errors.oldPass = 'Hasła nie są takie same';
				state.modal.password.errors.repeatPass =
					'Hasła nie są takie same';
				break;
			case 'short':
				state.modal.password.errors.oldPass =
					'Hasło jest za krótkie (8 znaków)';
				state.modal.password.errors.repeatPass =
					'Hasło jest za krótkie (8 znaków)';
				break;
			case 'wrongPass':
				state.modal.password.errors.newPass = 'Podano złe hasło';
				break;
		}
	},
	clearCPError(state) {
		state.modal.password.errors.newPass = '';
		state.modal.password.errors.oldPass = '';
		state.modal.password.errors.repeatPass = '';
	},
	setUserLanguage(state, payload) {
		let element;
		let arr = state.languages;
		payload ? (element = payload) : (element = state.user.language);
		let index = findIndex(arr, e => e.language == element);
		state.userLanguage = arr[index];
	},
	setUserPicture(state, payload) {
		state.user.picture = payload;
	},
	setUserRegistries(state, payload) {
		state.registries = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};