<template>
    <div class="AE__wrapper">
        <v-row>
            <v-col cols="12" md="12">
                <v-text-field
                    v-model="getConstructionsFields.name"
                    dense
                    :label="getConstructionsFieldsNames.name"
                    :error-messages="getConstructionsErrors.name"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="getConstructionsFields.city"
                    dense
                    :label="getConstructionsFieldsNames.city"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="getConstructionsFields.postCode"
                    dense
                    :label="getConstructionsFieldsNames.postCode"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="getConstructionsFields.street"
                    dense
                    :label="getConstructionsFieldsNames.street"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
                <v-autocomplete
                    v-model="getConstructionsFields.investor"
                    dense
                    :label="getConstructionsFieldsNames.investor"
                    :loading="getDropdown.loading"
                    :hide-no-data="getDropdown.hideNoData"
                    :items="getDropdown.items.contacts"
                    item-value="_id"
                    item-text="name"
                    @change="fetchRepresentatives()"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
                <v-combobox
                    v-model="getConstructionsFields.responsiblePerson"
                    dense
                    :label="getConstructionsFieldsNames.responsiblePerson"
                    :items="getDropdown.items.representatives"
                    :loading="getDropdown.loading"
                    item-value="_id"
                    :hide-no-data="getDropdown.hideNoData"
                    @input="getConstructionsFields.responsiblePerson = $event.srcElement.value"
                >
                    <template v-slot:selection="data">
                        <span>{{correctRepresentative(data.item)}}</span>
                    </template>
                    <template v-slot:item="data">
                        <span>{{correctRepresentative(data.item)}}</span>
                    </template>
                </v-combobox>
            </v-col>
            <v-col cols="12" md="12">
                <v-text-field
                    v-model="getConstructionsFields.description"
                    dense
                    :label="getConstructionsFieldsNames.description"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Separator from "@/components/Global/Separator.vue";
export default {
    data: () => ({
        investors: [],
    }),
    components: { Separator },
    computed: {
        ...mapGetters([
            "getConstructionsFieldsNames",
            "getConstructionsFields",
            "getDropdown",
            "getConstructionsErrors",
        ]),
    },
    methods: {
        ...mapActions(["fetchItems"]),
        fetchRepresentatives() {
            this.getConstructionsFields.responsiblePerson = "";
            this.fetchItems(
                `representatives?contactId=${this.getConstructionsFields.investor}&`
            );
        },
        correctRepresentative(item) {
            if (item.name && item.lastname) {
                return item.name + " " + item.lastname;
            } else {
                return item;
            }
        },
    },
};
</script>