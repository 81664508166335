<template>
    <v-container>
        <v-row>
            <v-col cols="6" class="d-flex flex-wrap">
                <v-col cols="12" md="8" class="pt-0">
                    <v-text-field
                        readonly
                        dense
                        v-model="getDietNutritionDetails.title"
                        :label="getDietNutritionFieldsNames.title"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-0">
                    <v-text-field
                        readonly
                        :value="getDietNutritionDetails.archiveDate ? correctDate(getDietNutritionDetails.archiveDate) : 'Brak'"
                        dense
                        :label="getDietNutritionFieldsNames.archiveDate"
                        prepend-icon="mdi-calendar"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-textarea
                        readonly
                        :label="getDietNutritionFieldsNames.information"
                        v-model="getDietNutritionDetails.information"
                    ></v-textarea>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    data: () => ({
        modaldata: false
    }),
    computed: {
        ...mapGetters([
            "getDietNutritionDetails",
            "getDietNutritionFieldsNames"
        ])
    },
    methods: {
        correctDate(date) {
            return this.$moment(date).format("YYYY-MM-DD");
        }
    }
};
</script>