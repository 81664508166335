<template>
    <v-dialog width="500" v-model="getPassword" @click:outside="closePassword()">
        <v-card>
            <div class="AE__header">
                <h1 class="font-weight-light AE__header__title py-3 pl-5">Zmiana hasła</h1>
                <v-btn class="AE__header__close" color="white" small icon @click="closePassword()">
                    <v-icon medium>mdi-close</v-icon>
                </v-btn>
            </div>

            <v-container class="p-relative">
                <v-row>
                    <v-col cols="12" sm="12">
                        <div class="password">
                            <v-text-field
                                :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :error-messages="getPasswordError.newPass"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Wpisz stare hasło"
                                v-model="getPasswordData.fields.oldPass"
                                counter
                                @click:append="show1 = !show1"
                                class="password__input"
                            ></v-text-field>
                            <v-text-field
                                :append-icon="show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :error-messages="getPasswordError.oldPass"
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Nowe hasło"
                                v-model="getPasswordData.fields.newPass"
                                counter
                                @click:append="show2 = !show2"
                                class="password__input"
                            ></v-text-field>
                            <v-text-field
                                :append-icon="show3 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :error-messages="getPasswordError.repeatPass"
                                :type="show3 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Powtórz nowe hasło"
                                v-model="getPasswordData.fields.repeatPass"
                                counter
                                @click:append="show3 = !show3"
                                class="password__input"
                            ></v-text-field>
                        </div>
                        <span>{{ getPasswordData.info }}</span>
                        <br />
                    </v-col>
                </v-row>
                <small class="red--text">*W przypadku wygenerowania hasła, zalecamy zapisanie go.</small>
            </v-container>

            <v-footer class="password__footer">
                <v-spacer></v-spacer>
                <div>
                    <v-btn @click="closePassword" class="mr-3 buttons">Anuluj</v-btn>
                    <v-btn
                        @click="generatePassword"
                        class="mr-3 buttons password__footer__btn"
                    >Generuj Hasło</v-btn>
                    <v-btn
                        dark
                        @click="tryChangePassword"
                        class="buttons buttons--add password__footer__btn"
                    >Zmień hasło</v-btn>
                </div>
            </v-footer>
        </v-card>
    </v-dialog>
</template>

<script>
import Header from "@/components/Global/Modal/ModalHeader.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    data: () => {
        return {
            // SHOW EYE
            show1: false,
            show2: false,
            show3: false
        };
    },
    computed: {
        ...mapGetters([
            "getPassword",
            "getDropdown",
            "getPasswordData",
            "getPasswordError"
        ])
    },
    methods: {
        ...mapMutations(["closePassword", "generatePassword"]),
        ...mapActions(["tryChangePassword"])
    },
    components: {
        Header
    }
};
</script>





