<template>
  <div style="display: flex">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }`"
          class="buttons--add mr-2"
          @click="goToLocation"
        >
          <v-icon small>mdi-map-marker</v-icon>
        </v-btn>
      </template>
      <span>Przejdź do mapy z lokalizacją</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-speed-dial
          v-model="filtersIconTooltip"
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-2"
        >
          <template v-slot:activator>
            <v-btn
              v-model="filtersTooltip"
              v-on="on"
              color="buttons--add"
              :style="` width: ${
                window.width < 1200 ? '50px' : '40px'
              } ; height: ${window.width < 1200 ? '50px' : '40px'}`"
              dark
              fab
            >
              <v-icon v-if="filtersTooltip" small>mdi-close</v-icon>
              <v-icon v-else small>mdi-filter-plus</v-icon>
            </v-btn>
          </template>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#0665ca"
                v-on="on"
                @click="filtrStatus('Wycena')"
              >
                <span>W</span>
              </v-btn>
            </template>
            <span>Wycena</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#e8c70c"
                v-on="on"
                @click="filtrStatus('Claim')"
              >
                <span>C</span>
              </v-btn>
            </template>
            <span>Claim</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#009fff"
                v-on="on"
                @click="filtrStatus('Faktura')"
              >
                <span>F</span>
              </v-btn>
            </template>
            <span>Faktura</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#009fff"
                v-on="on"
                @click="filtrStatus('Gwarancja')"
              >
                <span>G</span>
              </v-btn>
            </template>
            <span>Gwarancja</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#F44336"
                v-on="on"
                @click="filtrStatus('Pilne')"
              >
                <span>P</span>
              </v-btn>
            </template>
            <span>Pilne</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#4CAF50"
                v-on="on"
                @click="filtrStatus('Kontynuacja')"
              >
                <span>K</span>
              </v-btn>
            </template>
            <span>Kontynuacja</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
      <span>Filtry (ikony)</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-speed-dial
          v-model="filtersTooltip"
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-2"
        >
          <template v-slot:activator>
            <v-btn
              v-model="filtersTooltip"
              v-on="on"
              color="buttons--add"
              :style="` width: ${
                window.width < 1200 ? '50px' : '40px'
              } ; height: ${window.width < 1200 ? '50px' : '40px'}`"
              dark
              fab
            >
              <v-icon v-if="filtersTooltip" small>mdi-close</v-icon>
              <v-icon v-else small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                class="buttons--add mr-2"
                v-on="on"
                @click="showAllService"
              >
                <v-icon small>mdi-wrench</v-icon>
              </v-btn>
            </template>
            <span>Wszystkie</span>
          </v-tooltip>
          <v-tooltip left v-for="status in getTable.statuses" :key="status._id">
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                :color="status.color.background"
                v-on="on"
                @click="filtrStatus(status.name)"
              >
                <span>{{ status.name[0] }}</span>
              </v-btn>
            </template>
            <span>{{ status.name }}</span>
          </v-tooltip>
          <!-- <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                small
                                color="#555555"
                                v-on="on"
                                @click="filtrStatus('Przypisany')"
                            >
                                <v-icon small>mdi-walk</v-icon>
                            </v-btn>
                        </template>
                        <span>Przypisane</span>
                    </v-tooltip>-->
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="#ff9800"
                v-on="on"
                @click="filtrStatus('Kontynuacja')"
              >
                <span>K</span>
              </v-btn>
            </template>
            <span>Kontynuacja</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
      <span>Filtry</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }`"
          class="buttons--add mr-2"
          @click="showOrder({ day: 0 })"
        >
          <v-icon small>mdi-format-list-checks</v-icon>
        </v-btn>
      </template>
      <span>Stan na dziś</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          :style="` width: ${window.width < 1200 ? '50px' : '40px'} ; height: ${
            window.width < 1200 ? '50px' : '40px'
          }`"
          class="buttons--add"
          @click="newService"
        >
          <v-icon small>mdi-clipboard-plus</v-icon>
        </v-btn>
      </template>
      <span>Dodaj nowe zlecenie</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    filtersTooltip: false,
    filtersIconTooltip: false,
    // === === CHECK WINDOW SIZE === ===
    window: {
      width: 0,
      height: 0,
    },
  }),
  computed: {
    ...mapGetters([
      "getSearchedDevicesItems",
      "getPermission",
      "getTechnicsItems",
      "getTable",
    ]),
  },
  created() {
    // === === CHECK WINDOW SIZE === ===
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    // === === CHECK WINDOW SIZE === ===
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions(["openModal", "findTechnics", "fetchData", "orderToday"]),
    ...mapMutations(["ResetSearch", "setExtras"]),
    // === === CHECK WINDOW SIZE === ===
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    newService() {
      this.ResetSearch();
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 1500,
        type: "create",
        route: "service",
        tab: "new_service",
      }).then(() => {
        this.findTechnics();
      });
    },
    showOrder(item) {
      this.orderToday(item).then(
        this.openModal({
          headLine: "global.create",
          open: true,
          width: 1800,
          type: "details",
          route: "service",
          tab: "orderToday",
        })
      );
    },
    filtrStatus(status) {
      this.setExtras({ status: status });
      this.fetchData({
        page: 1,
        search: "",
        sortBy: [],
        sortDesc: [],
        routeName: this.$route.name,
        pageSize: 11,
      });
    },

    showAllService() {
      this.setExtras({ all: true });
      this.fetchData({
        page: 1,
        search: "",
        sortBy: [],
        sortDesc: [],
        routeName: this.$route.name,
        pageSize: 11,
      });
    },
    goToLocation() {
      window.open("https://www.google.com/maps", "_blank");
    },
  },
};
</script>
