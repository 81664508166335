<template>
    <v-col cols="12" md="12" style="position: relative">
        <v-data-table
            :headers="table.header"
            :items="getBranchesTable.items"
            :mobile-breakpoint="1100"
            class="mt-4 central__table"
            hide-default-footer
            :options.sync="options"
            :items-per-page="11"
        >
            <template v-slot:item.action="{ item }">
                <!-- === GO TO DETAILS BUTTON === -->

                <v-dialog v-model="dialog" width="800">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            small
                            @click="getCoords(item._id)"
                            v-on="on"
                            v-if="item.location"
                        >
                            <v-icon small>mdi-map-marker</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <GmapMap
                            ref="mymap"
                            :center="coords"
                            :zoom="16"
                            map-type-id="terrain"
                            style="width: 800px; height: 500px"
                        >
                            <GmapMarker ref="myMarker" :position="coords" />
                        </GmapMap>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialog = false">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-tooltip left v-if="getPermission.level > 1">
                    <template v-slot:activator="{ on }">
                        <v-btn icon small v-on="on" @click="openEdit(item._id)">
                            <v-icon small>mdi-arrow-right-thick</v-icon>
                        </v-btn>
                    </template>
                    <span>Edytuj</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <div class="text-md-center pt-2">
            <v-pagination
                circle
                v-model="table.page"
                :length="getBranchesTable.pages"
                @input="refreshData"
            ></v-pagination>
            <v-text-field
                label="Szukaj..."
                v-model="table.search"
                @input="searchBranch"
                style="position: absolute; bottom: 0; right: -80px; width: 200px; transform: translate(-50%)"
            ></v-text-field>
        </div>
    </v-col>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import GMap from "../../../components/Global/Map";
import debounce from "lodash.debounce";
export default {
    data: () => ({
        dialog: false,
        table: {
            search: "",
            page: 1,
            header: [],
            items: [],
        },
        options: {},
        coords: {},
    }),
    computed: {
        ...mapGetters([
            "getBranches",
            "getBranchesTable",
            "getBranchesData",
            "getPermission",
        ]),
    },
    watch: {
        options: {
            handler() {
                this.refreshData();
                return;
            },
            deep: true,
        },
    },
    methods: {
        async getCoords(id) {
            await Vue.axios({
                method: "GET",
                url: `/branches/${id}`,
            }).then((response) => {
                this.coords = response.data.doc.location;
            });
        },
        setHeaders() {
            this.table.header = this.getBranches.header;
        },
        openEdit(id) {
            this.$store.commit("resetErrors", "branches");
            this.branchDetails(id);
            this.$store.commit("setModal", {
                headLine: "global.edit",
                open: true,
                width: 600,
                type: "edit",
                route: "branches",
                tab: "contacts_branches",
            });
        },
        async refreshData() {
            const { sortBy, sortDesc } = this.options;
            const { page, search } = this.table;

            this.getBranchesByContact({
                page,
                search,
                sortBy,
                sortDesc,
                route: this.$route.params.id,
            });

            // this.fetchData({
            //     page,
            //     search,
            //     sortBy,
            //     sortDesc,
            //     routeName: "branches",
            //     pageSize: 11,
            //     query: this.$route.params.id
            // });
        },
        searchBranch: debounce(function (e) {
            this.refreshData();
        }, 500),
        ...mapActions(["fetchData", "branchDetails", "getBranchesByContact"]),
    },
    beforeMount() {
        this.setHeaders();
    },
};
</script>