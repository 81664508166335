import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
    price: {
        open: false,
        priceName: '',
        description: '',
        survey: {
            price: '',
            value: ''
        },
        repair: {
            price: '',
            value: ''
        },
        installation: {
            price: '',
            value: ''
        },
        diagnostics: {
            price: '',
            value: ''
        },
        itService: {
            price: '',
            value: ''
        },
        remote: {
            price: '',
            value: ''
        }
    },
    type: '',
    items: [],
    errors: {
        priceName: '',
        description: ''
    }
};

const getters = {
    getSPrice: state => state.price,
    getSPriceType: state => state.type,
    getSPriceItems: state => state.items,
    getSPriceErrors: state => state.errors
};

const actions = {
    createSPrice({ commit }, payload) {
        state.errors.priceName = '';
        state.errors.description = '';
        Vue.axios({
            method: 'POST',
            url: '/dictionary/service/price',
            data: {
                ...state.price
            }
        })
            .then(response => {
                commit('setSPriceItem', response.data.item);
                commit('clearSPrice');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie stworzono nowy wpis'
                });
            })
            .catch(error => {
                if (error.response.data.error === 'Exists') {
                    state.errors.priceName = 'Nazwa jest już używana';
                } else {
                    if (
                        error.response.data.error.errors.priceName &&
                        error.response.data.error.errors.priceName.kind ===
                            'required'
                    )
                        state.errors.priceName = 'Pole wymagane';
                    if (
                        error.response.data.error.errors.description &&
                        error.response.data.error.errors.description.kind ===
                            'required'
                    )
                        state.errors.description = 'Pole wymagane';
                }
            });
    },

    fetchSPrice({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: '/dictionary/service/price'
        })
            .then(response => {
                commit('setSPriceItems', response.data.items);
            })
            .catch(error => {
                console.log(error.response.data);
            });
    },

    deleteSPrice({ commit }, payload) {
        Vue.axios({
            method: 'PUT',
            url: `/dictionary/service/price/delete/${payload}`
        })
            .then(response => {
                commit('deleteSPItem', payload);
                commit('closeSecondModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie usunięto wpis'
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    },

    SPDetails({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/dictionary/service/price/${payload}`
        })
            .then(response => {
                commit('setSpDetails', response.data.item);
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateSPrice({ commit }, payload) {
        state.errors.priceName = '';
        state.errors.description = '';
        Vue.axios({
            method: 'PUT',
            url: `/dictionary/service/price/update/${state.price._id}`,
            data: {
                ...state.price
            }
        })
            .then(response => {
                commit('clearSPrice');
                commit('updateSPItem', response.data.item);
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano dany wpis'
                });
            })
            .catch(error => {
                if (error.response.data.error === 'Exists') {
                    state.errors.priceName = 'Nazwa jest już używana';
                } else {
                    if (
                        error.response.data.error.errors.priceName &&
                        error.response.data.error.errors.priceName.kind ===
                            'required'
                    )
                        state.errors.priceName = 'Pole wymagane';
                    if (
                        error.response.data.error.errors.description &&
                        error.response.data.error.errors.description.kind ===
                            'required'
                    )
                        state.errors.description = 'Pole wymagane';
                }
            });
    }
};

const mutations = {
    clearSPrice(state) {
        state.price.open = false;
        state.price.priceName = '';
        state.price.description = '';
        state.errors.priceName = '';
        state.errors.description = '';
        state.price.survey.price = '';
        state.price.survey.value = '';
        state.price.repair.price = '';
        state.price.repair.value = '';
        state.price.installation.price = '';
        state.price.installation.value = '';
        state.price.diagnostics.price = '';
        state.price.diagnostics.value = '';
        state.price.itService.value = '';
        state.price.itService.price = '';
        state.price.remote.price = '';
        state.price.remote.value = '';
    },
    setSPrice(state, payload) {
        state.type = payload;
    },
    setSPriceItems(state, payload) {
        state.items = payload;
    },
    setSPriceItem(state, payload) {
        state.items = [payload, ...state.items];
    },
    deleteSPItem(state, payload) {
        let arr = state.items;
        let index = findIndex(arr, e => e._id == payload);
        arr.splice(index, 1);
    },
    setSpDetails(state, payload) {
        let prices = [
            'survey',
            'repair',
            'installation',
            'diagnostics',
            'itService',
            'remote'
        ];
        state.price = payload;
        for (let i = 0; i < prices.length; i++) {
            if (!state.price[prices[i]]) {
                state.price[prices[i]] = {
                    price: '',
                    value: ''
                };
            }
        }
        state.price.open = true;
    },
    updateSPItem(state, payload) {
        let arr = state.items;
        let index = findIndex(arr, {
            _id: payload._id
        });
        arr.splice(index, 1, payload);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
