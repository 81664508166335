<template>
  <div class="buttons__mobile">
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          small
          class="buttons--add"
          @click="newService(getDevicesDetails.SN)"
        >
          <v-icon small>mdi-wrench</v-icon>
        </v-btn>
      </template>
      <span>Wystaw zlecenie</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          dark
          v-on="on"
          @click="printQR('printQR')"
          small
          class="mx-3"
          :color=" getModalDevices.qrCodeIsPrint ? '#939191 !important' :'#19aa8d'"
        >
          <v-icon small>mdi-qrcode-scan</v-icon>
        </v-btn>
      </template>
      <span>Drukuj QR Code</span>
    </v-tooltip>
    <v-tooltip left v-if="getPermission.level > 1">
      <template v-slot:activator="{ on }">
        <v-btn fab dark v-on="on" small class="buttons--add" @click="editDevices">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edytuj informacje</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({}),
  methods: {
    ...mapMutations(["resetDeviceItems", "ResetSearch"]),
    ...mapActions([
      "openModal",
      "devicesDeatils",
      "findTechnics",
      "fetchContactsDeliver",
      "fetchTPriority",
      "fetchServiceBase"
    ]),
    editDevices() {
      this.fetchTPriority();
      this.fetchContactsDeliver();
      this.devicesDeatils({ id: this.$route.params.id, type: "modal" });
      this.resetDeviceItems();
      this.fetchServiceBase();
      this.openModal({
        headLine: "global.create",
        open: true,
        type: "edit",
        route: "devices",
        tab: "info_devices"
      });
    },
    newService(SN) {
      this.ResetSearch();
      this.openModal({
        headLine: "global.create",
        open: true,
        SN,
        width: 1500,
        type: "create",
        route: "service",
        tab: "new_service"
      }).then(() => {
        this.findTechnics();
      });
    },
    printQR() {
      this.openModal({
        headLine: "global.create",
        open: true,
        width: 600,
        type: "qrcode",
        route: "devices",
        tab: "qrCode"
      });
    }
  },
  computed: {
    ...mapGetters([
      "getPermission",
      "getModalDevices",
      "getDevicesDetails",
      "getServiceData"
    ])
  }
};
</script>
