<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" class="pt-0">
        <v-text-field
          dense
          v-model="getDutyModal.title"
          :label="getDutyFieldsNames.title"
          :error-messages="getDutyError.title"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <v-menu
          v-model="modaldata"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="getDutyModal.archiveDate"
              dense
              readonly
              clearable
              :label="getDutyFieldsNames.archiveDate"
              prepend-icon="mdi-calendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="getDutyModal.archiveDate"
            @input="modaldata = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="12">
        <v-combobox v-model="getDutyModal.month" :items="items" :label="getDutyFieldsNames.month"></v-combobox>
      </v-col>
      <v-col cols="12">
        <v-select
          v-if="getModal.type == 'edit'"
          dense
          :items="getPositionItems"
          item-text="title"
          item-value="position"
          label="Ustaw za"
          v-model="getDutyModal.changePosition"
        ></v-select>
      </v-col>
      <v-col cols="12" md="12">
        <v-textarea :label="getDutyFieldsNames.information" v-model="getDutyModal.information"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    modaldata: false,
    items: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ],
  }),
  computed: {
    ...mapGetters([
      "getDutyError",
      "getDutyFieldsNames",
      "getDutyModal",
      "getPositionItems",
      "getModal",
    ]),
  },
  methods: {
    ...mapActions([""]),
  },
};
</script>
