<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td>{{item.index}}</td>
            <td>{{item.materialIndex}}</td>
            <td>{{item.name}}</td>
            <td>{{correctVolume(item.currentVolume, item.unit)}}</td>
            <td>{{correctVolume(item.minimalLimit, item.unit)}}</td>
            <td v-if="getRegistryHeader.status">
                <v-chip
                    :hidden="!item.status.possibleStatus"
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>
            <td v-if="getRegistryHeader.action" style="text-align: right">
                <v-btn icon small @click="deleteEntry(item._id)">
                    <v-icon color="#ff334d" small>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon small @click="$router.push({path:`/materials/${item._id}`})">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters([
            "getPermission",
            "getDefaultMenager",
            "getRegistryHeader",
            "getMaterialRotation",
        ]),
    },
    methods: {
        ...mapActions(["openSecondModal"]),
        correctVolume(weight, unit) {
            if (weight && unit) return `${Number(weight).toFixed(2)} [${unit}]`;
            else if (weight && !unit) return Number(weight).toFixed(2);
            else return "0";
        },
        deleteEntry(id) {
            this.openSecondModal({
                width: 500,
                open: true,
                data: id,
                type: "deleteMaterial",
            });
        },
    },
};
</script>