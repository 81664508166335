import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

// Translation provided by Vuetify (typescript)
import pl from 'vuetify/es5/locale/pl';

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		themes: {
			light: {
				primary: '#445D75', // #E53935
				secondary: '#19aa8d', // #FFCB00 - YELLOW, #19AA8D - GREEN
				accent: colors.indigo.base, // #3F51B5
			}
		},
	},
	lang: {
		locales: {
			pl
		},
		current: 'pl',
	},
});