<template>
  <v-footer class="AE__footer" color="#d9d9d9">
    <v-alert
      v-if="
        getTaskData.isDone &&
          (getModal.tab == 'tasks_devices' || $route.name == 'tasks')
      "
      dense
      type="success"
      class="ma-0 mr-3"
      >Zadanie zrealizowane</v-alert
    >
    <v-btn
      dark
      v-if="
        getModal.type == 'details' &&
          ($route.name == 'tasks' || getModal.tab === 'tasks_devices') &&
          !getTaskData.isDone
      "
      class="buttons buttons--add mr-3"
      @click="
        doneTask({
          registryName: $route.name,
          registryID: $route.params.id,
        })
      "
      >Zakończ</v-btn
    >
    <v-btn
      v-if="
        getModal.type == 'details' &&
          $route.name == 'tasks' &&
          getTaskData.registryName &&
          getTaskData.registryID
      "
      class="buttons mr-3 mt-0"
      router
      @click="closeModal"
      :to="
        `/${getTaskData.registryName.split('_')[0]}/${getTaskData.registryID}`
      "
      >Więcej</v-btn
    >
    <v-spacer></v-spacer>
    <div>
      <v-btn
        style="position: absolute; left: 20px; background-color: white"
        @click="clear"
        v-if="
          getModal.type === 'create' &&
            !['resources', 'resources_details'].includes($route.name)
        "
        class="mx-3 buttons"
        >{{ $t('global.clear') }}</v-btn
      >

      <v-btn
        style="position: absolute; left: 20px"
        v-if="getModal.tab === 'start_intervention'"
        class="buttons__intervention mr-3"
        >Zakończ interwencje</v-btn
      >
      <v-btn
        style="position: absolute; left: 200px"
        v-if="getModal.tab === 'start_intervention'"
        class="buttons__intervention buttons__intervention__start mr-3"
        >Rozpocznij interwencje</v-btn
      >

      <v-btn
        v-if="$route.name != 'storage'"
        @click="clearModal"
        class="buttons mr-3"
        >Anuluj</v-btn
      >
      <v-progress-circular
        v-if="getAttachmentLoader"
        indeterminate
        color="white"
        size="24"
        width="2"
      ></v-progress-circular>
      <v-btn
        dark
        @click="save"
        :disabled="getButtons.save"
        v-else-if="getModal.type == 'create'"
        class="buttons buttons--add"
      >
        <span>{{ $t('global.save') }}</span>
      </v-btn>

      <v-btn
        dark
        @click="QrPrint('printQR')"
        v-if="getModal.type == 'qrcode'"
        class="buttons buttons--add"
        >Drukuj</v-btn
      >
      <v-btn
        dark
        @click="edit"
        v-if="getModal.type == 'edit' && $route.name != 'alerts'"
        class="buttons buttons--add"
        >{{ $t('global.save') }}</v-btn
      >

      <v-btn
        class="buttons mr-3 buttons--add"
        @click="saveTComment({ registryName: $route.name })"
        v-if="
          getModal.type == 'details' &&
            ($route.name == 'tasks' || getModal.tab === 'tasks_devices') &&
            !getTaskData.isDone
        "
        >Zapisz</v-btn
      >
    </div>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters([
      'getModal',
      'getButtons',
      'getTaskData',
      'getFolderNameValue',
      'getTaskData',
      'getPermission',
      'getAttachmentsFields',
      'getAttachmentLoader',
      'getUser',
      'getStorageAttachment',
      'getFolderNameValue',
      'getLayoutWSD',
    ]),
  },
  methods: {
    ...mapActions([
      'createRegistry',
      'createAttachmentsToFolder',
      'createGroup',
      'updateGroup',
      'createDepartment',
      'updateDepartment',
      'createUser',
      'createTask',
      'doneTask',
      'saveTComment',
      'updateRegistry',
      'updateUser',
      'editStaff',
      'createTaskGlobal',
      'createAttachments',
      'createContact',
      'createBranch',
      'createRepresentative',
      'updateContact',
      'updateBranch',
      'updateRepresentatives',
      'updateAttachment',
      'createAlert',
      'createNote',
      'createCar',
      'createPart',
      'noteEdit',
      'carsEdit',
      'alertUpdate',
      'createModel',
      'createIso',
      'createPrinters',
      'updateDirectorOrder',
      'updatecurrentInformations',
      'updateIso',
      'createAgreement',
      'updateAgreement',
      'createOffers',
      'updateOffers',
      'createTenders',
      'updateTenders',
      'createDirectorOrders',
      'createCoronavirus',
      'updateModel',
      'updateDevices',
      'partsEdit',
      'serviceEdit',
      'createDevice',
      'editImplementation',
      'updateDevices',
      'editServiceParts',
      'createService',
      'qrCode',
      'createCurrentInformation',
      'editServiceImplementation',
      'createDuty',
      'createAccreditation',
      'updateDuty',
      'updateCoronavirus',
      'updateAccreditation',
      'createPharmacotherapy',
      'updatePharmacotherapy',
      'createBenefitsValue',
      'updateBenefitsValue',
      'createNonMedicalPrint',
      'updateNonMedicalPrints',
      'updateInternalPrints',
      'updateExternalPrints',
      'createInternalPrints',
      'createExternalPrints',
      'createPatientsConsent',
      'updatePatientsConsents',
      'createExtraordinaryOccurence',
      'updateExtraordinaryOccurence',
      'createTraining',
      'updateTraining',
      'createBhp',
      'createTeamAnalysisResult',
      'createSecurityPolicy',
      'updateTeamAnalysisResult',
      'updateSecurityPolicy',
      'updateBhp',
      'createDietNutrition',
      'updateDietNutrition',
      'createWifi',
      'updateWifi',
      'createInternalContacts',
      'updateInternalContacts',
      'createLeasing',
      'updateLeasing',
      'createResource',
      'resourceUpdate',
      'setSharedInAttachments',
      'copyAttachmentToStorage',
      'createConstruction',
      'constructionUpdate',
      'createWarehouse',
      'createMaterial',
      'updateMaterial',
      'warehouseUpdate',
      'updateImplementationOffers',
    ]),
    ...mapMutations([
      'signaturePad',
      'resetModal',
      'resetErrors',
      'clearModal',
      'closeModal',
    ]),

    QrPrint(QRimg) {
      this.qrCode(this.$route.params.id);
      let printContents = document.getElementById(QRimg).innerHTML;
      document.body.innerHTML = `
            <div class="printContent" >
                <div class="printQRCode">                    
                    <span style="display: block; margin-top: 8px; margin-left: 1px">${printContents}</span>                   
                </div>
                             
                
            </div>`;
      window.print();
      setTimeout(function() {
        // window.location.reload(true);
      }, 1500);
    },
    clearModal() {
      this.$store.commit('setModal', {
        headLine: '',
        open: false,
        width: 0,
        type: '',
      });
    },
    save() {
      if (this.getModal.global) {
        switch (this.getModal.global) {
          case 'tasks':
            this.createTaskGlobal({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
            });

            break;
          case 'attachments':
            // if (this.getAttachmentsFields.folderName == "") {
            //   this.createAttachmentsToFolder({
            //     registryName: this.$route.name,
            //     registryID: this.$route.params.id,
            //     folderName: this.getFolderNameValue
            //   });
            // } else {
            //   this.createAttachments({
            //     registryName: this.$route.name,
            //     registryID: this.$route.params.id
            //   });
            // }

            this.createAttachments({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
              folderName: this.getFolderNameValue,
            });

            break;
          case 'notifications':
            this.createAlert({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
            });

            break;
          case 'note':
            this.createNote({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
            });

            break;
          case 'shares':
            this.setSharedInAttachments({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
              folderName: this.getFolderNameValue,
            });
            break;
          case 'storage':
            this.copyAttachmentToStorage({
              fromRegistryID: this.getUser.id,
              fromRegistryName: 'users',
              attachmentID: this.getStorageAttachment.file,
              registryID: this.$route.params.id,
              registryName: this.$route.name,
              folderID: this.getFolderNameValue,
              storage2attachments: true,
            });
            break;
          case 'offersImplementation':
            this.updateImplementationOffers(this.$route.params.id);
            break;
        }
      } else {
        switch (this.$route.name) {
          case 'registries':
            this.createRegistry();
            break;
          case 'groups':
            this.createGroup();
            break;
          case 'departments':
            this.createDepartment();
            break;
          case 'users':
            this.createUser();
            break;
          case 'tasks':
            this.createTask({
              registryName: this.$route.name,
            });
            break;
          case 'workers_details':
            if (this.getModal.tab === 'staff_users')
              this.editStaff(this.$route.params.id);

            break;
          case 'contacts':
            this.createContact(this.getLayoutWSD);
            break;
          case 'cars':
            this.createCar({
              registryName: this.$route.name,
            });
            break;
          case 'alerts':
            this.createAlert({
              registryName: this.$route.name,
            });
            break;
          case 'models':
            this.createModel();
            break;
          case 'printers':
            this.createPrinters();
            break;
          case 'devices':
            this.createDevice({
              registryName: this.$route.name,
            });
            break;
          case 'devices_details':
            // tworzenie nowego zlecenia serwisu z poziomu urządzenia
            if (this.getModal.SN) {
              this.createService({
                registryName: 'service',
              });
              this.$router.push({ path: `/service` });
            }
            break;
          case 'service':
            this.createService({
              registryName: this.$route.name,
            });
            break;
          case 'director-orders':
            this.createDirectorOrders({
              registryName: this.$route.name,
            });
            break;
          case 'coronavirus':
            this.createCoronavirus({
              registryName: this.$route.name,
            });
            break;
          case 'iso':
            this.createIso({
              registryName: this.$route.name,
            });
            break;
          case 'pharmacotherapy':
            this.createPharmacotherapy({
              registryName: this.$route.name,
            });
            break;
          case 'current-informations':
            this.createCurrentInformation({
              registryName: this.$route.name,
            });
            break;
          case 'agreement':
            this.createAgreement({
              registryName: this.$route.name,
            });
            break;
          case 'offers':
            this.createOffers({
              registryName: this.$route.name,
            });
            break;
          case 'tenders':
            this.createTenders({
              registryName: this.$route.name,
            });
            break;
          case 'accreditation':
            this.createAccreditation({
              registryName: this.$route.name,
            });
            break;
          case 'parts':
            this.createPart({
              registryName: this.$route.name,
            });
            break;
          case 'contacts_details':
            switch (this.getModal.route) {
              case 'branches':
                this.createBranch({
                  contactId: this.$route.params.id,
                });
                break;
              case 'representatives':
                this.createRepresentative(this.$route.params.id);
                break;
            }
            break;
          case 'diet-nutrition':
            this.createDietNutrition({
              registryName: this.$route.name,
            });
            break;
          case 'wifi':
            this.createWifi({ registryName: this.$route.name });
            break;

          case 'benefits-value':
            this.createBenefitsValue({
              registryName: this.$route.name,
            });
            break;
          case 'non-medical-print':
            this.createNonMedicalPrint({
              registryName: this.$route.name,
            });
            break;
          case 'internal-prints':
            this.createInternalPrints({
              registryName: this.$route.name,
            });
            break;
          case 'external-prints':
            this.createExternalPrints({
              registryName: this.$route.name,
            });
            break;
          case 'patients-consent':
            this.createPatientsConsent({
              registryName: this.$route.name,
            });
            break;
          case 'training':
            this.createTraining({
              registryName: this.$route.name,
            });
            break;
          case 'information-security-policy':
            this.createSecurityPolicy({
              registryName: this.$route.name,
            });
            break;
          case 'bhp':
            this.createBhp({
              registryName: this.$route.name,
            });
            break;
          case 'team-analysis-result':
            this.createTeamAnalysisResult({
              registryName: this.$route.name,
            });
            break;
          case 'extraordinary-occurence':
            this.createExtraordinaryOccurence({
              registryName: this.$route.name,
            });
            break;
          case 'internal-contacts':
            this.createInternalContacts({
              registryName: this.$route.name,
            });
            break;
          case 'resources':
            this.createResource();
            break;
          case 'duty':
            this.createDuty();
            break;
          case 'leasing':
            this.createLeasing({
              registryName: this.$route.name,
            });
            break;
          case 'constructions':
            this.createConstruction();
            break;
          case 'warehouse':
            this.createWarehouse();
            break;
          case 'materials':
            this.createMaterial();
            break;
        }
      }
    },

    clear() {
      switch (this.getModal.tab) {
        case 'attachment':
          this.resetModal('attachments');
          this.resetErrors('attachments');
          break;
        case 'note':
          this.resetModal('note');
          this.resetErrors('note');
          break;
        case 'cars':
          this.resetModal('cars');
          this.resetErrors('cars');
          break;
        case 'tasks_devices':
          this.resetModal('tasks');
          this.resetErrors('tasks');
          break;
        case 'notification':
          this.resetModal('notifications');
          this.resetErrors('notifications');
          break;
        case 'session_logs':
          this.resetModal('session_logs');
          this.resetErrors('session_logs');
          break;
        case 'director-orders':
          this.resetModal('directorOrder');
          break;
        case 'pharmacotherapy':
          this.resetModal('pharmacotherapy');
          this.resetErrors('pharmacotherapy');
          break;
        case 'iso':
          this.resetModal('Iso');
          this.resetErrors('Iso');
          break;
        case 'external-prints':
          this.resetModal('externalPrints');
          break;
        case 'internal-prints':
          this.resetModal('internalPrints');
          break;
        case 'patients-consent':
          this.resetModal('patientsConsent');
          break;
        case 'training':
          this.resetModal('training');
          break;
        case 'information-security-policy':
          this.resetModal('securityPolicy');
          break;
        case 'bhp':
          this.resetModal('bhp');
          break;
        case 'team-analysis-result':
          this.resetModal('teamAnalysisResult');
          break;
        case 'extraordinary-occurence':
          this.resetModal('extraordinaryOccurence');
          break;
        case 'current-informations':
          this.resetModal('currentInformations');
          this.resetErrors('currentInformations');
          break;
        case 'agreement':
          this.resetModal('Agreement');
          break;
        case 'offers':
          this.resetModal('Offers');
          break;
        case 'tenders':
          this.resetModal('Tenders');
          break;
        case 'benefits-value':
          this.resetModal('BenefitsValue');
          this.resetErrors('BenefitsValue');
          break;
        case 'diet-nutrition':
          this.resetModal('DietNutrition');
          this.resetErrors('DietNutrition');
          break;
        case 'wifi':
          this.resetModal('Wifi');
          this.resetErrors('Wifi');
        case 'internal-contacts':
          this.resetModal('internalContacts');
          this.resetErrors('internalContacts');
          break;
        case 'leasing':
          this.resetModal('Leasing');
          break;
        case 'non-medical-print':
          this.resetModal('nonMedicalPrint');
          break;
        case 'new_service':
          this.resetModal('service');
          break;
        case 'shares':
          this.resetModal('resources');
          this.resetErrors('resources');
          break;
        case 'attachmentFromStorage':
          this.resetModal('attachmentFromStorage');
          break;
        case 'warehouse':
          this.resetModal('warehouse');
          break;
        case 'materials':
          this.resetModal('materials');
          break;
        default:
          this.resetModal(this.$route.name);
          this.resetErrors(this.$route.name);
          break;
      }
    },
    edit() {
      if (this.getModal.global) {
        switch (this.getModal.global) {
          case 'attachments':
            this.updateAttachment({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
              folderName: this.getFolderNameValue,
            });
            break;
          case 'note':
            this.noteEdit({
              registryName: this.$route.name,
              registryID: this.$route.params.id,
            });
            break;
          case 'notifications':
            this.alertUpdate({ registryName: this.$route.name });
            break;
        }
      } else {
        switch (this.$route.name) {
          case 'devices_details':
            switch (this.getModal.tab) {
              case 'implementation_devices':
                this.editImplementation(this.$route.params.id);
                break;
              case 'info_devices':
                this.updateDevices(this.$route.params.id);
                break;
            }
            break;
          case 'parts_details':
            this.partsEdit(this.$route.params.id);
            break;
          case 'director-orders_details':
            this.updateDirectorOrder(this.$route.params.id);
            break;
          case 'current-informations_details':
            this.updatecurrentInformations(this.$route.params.id);
            break;
          case 'duty_details':
            this.updateDuty(this.$route.params.id);
            break;
          case 'coronavirus_details':
            this.updateCoronavirus(this.$route.params.id);
            break;
          case 'accreditation_details':
            this.updateAccreditation(this.$route.params.id);
            break;
          case 'pharmacotherapy_details':
            this.updatePharmacotherapy(this.$route.params.id);
            break;
          case 'iso_details':
            this.updateIso(this.$route.params.id);
            break;
          case 'agreement_details':
            this.updateAgreement(this.$route.params.id);
            break;
          case 'offers_details':
            this.updateOffers(this.$route.params.id);
            break;
          case 'tenders_details':
            this.updateTenders(this.$route.params.id);
            break;
          case 'benefits-value_details':
            this.updateBenefitsValue(this.$route.params.id);
            break;
          case 'non-medical-print_details':
            this.updateNonMedicalPrints(this.$route.params.id);
            break;
          case 'internal-prints_details':
            this.updateInternalPrints(this.$route.params.id);
            break;
          case 'patients-consent_details':
            this.updatePatientsConsents(this.$route.params.id);
            break;
          case 'training_details':
            this.updateTraining(this.$route.params.id);
            break;
          case 'team-analysis-result_details':
            this.updateTeamAnalysisResult(this.$route.params.id);
            break;
          case 'information-security-policy_details':
            this.updateSecurityPolicy(this.$route.params.id);
            break;
          case 'bhp_details':
            this.updateBhp(this.$route.params.id);
            break;
          case 'extraordinary-occurence_details':
            this.updateExtraordinaryOccurence(this.$route.params.id);
            break;
          case 'external-prints_details':
            this.updateExternalPrints(this.$route.params.id);
            break;
          case 'cars_details':
            this.carsEdit(this.$route.params.id);
            break;
          case 'groups':
            this.updateGroup();
            break;
          case 'departments':
            this.updateDepartment();
            break;
          case 'registries':
            this.updateRegistry();
            break;
          case 'workers_details':
            this.updateUser(this.$route.params.id);
            break;
          case 'models_details':
            this.updateModel(this.$route.params.id);
            break;
          case 'contacts_details':
            switch (this.getModal.route) {
              case 'contacts':
                this.updateContact();
                break;
              case 'branches':
                this.updateBranch();
                break;
              case 'representatives':
                this.updateRepresentatives();
                break;
            }
          case 'service_details':
            switch (this.getModal.tab) {
              case 'parts_service':
                this.editServiceParts(this.$route.params.id);
                break;
              case 'implementation_service':
                this.editServiceImplementation(this.$route.params.id);
                break;
              case 'new_service':
                this.serviceEdit(this.$route.params.id);
                break;
            }
            break;
          case 'diet-nutrition_details':
            this.updateDietNutrition(this.$route.params.id);
            break;
          case 'wifi_details':
            this.updateWifi(this.$route.params.id);
          case 'internal-contacts_details':
            this.updateInternalContacts(this.$route.params.id);
            break;
          case 'leasing_details':
            this.updateLeasing(this.$route.params.id);
            break;
          case 'resources_details':
            this.resourceUpdate();
            break;
          case 'constructions_details':
            this.constructionUpdate({ id: this.$route.params.id });
            break;
          case 'materials_details':
            this.updateMaterial({ id: this.$route.params.id });
            break;
          case 'warehouse_details':
            this.warehouseUpdate({ id: this.$route.params.id });
            break;
        }
      }
    },
  },
};
</script>
