<template>
  <v-container>
    <v-row v-if="!files">
      <v-col cols="12" class="pa-0">
        <v-list style="height: 290px; overflow-y: auto">
          <v-list-item
            v-for="item in getResourcesItems"
            :key="item._id"
            @click="showFiles(item.name, item._id)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.description || 'Brak opisu'
              }}</v-list-item-subtitle>
              <v-divider insert></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="pa-0">
        <span @click="files = false" style="cursor: pointer;">
          <v-icon>mdi-arrow-left</v-icon>
          <span style="font-size: 22px;" class="ml-3">{{ resourceName }}</span>
        </span>
        <v-autocomplete
          :items="attachments"
          v-model="getSharedResources.items"
          label="Wybierz pliki z zasobów"
          item-value="fakeFileName"
          return-object
          multiple
          chips
        >
          <template v-slot:selection="data">
            <v-chip label small>
              {{ data.item.fileName }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.fileName }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  data.item.fileDescription || 'Brak opisu'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    files: false,
    resourceName: '',
    attachments: [],
  }),
  computed: {
    ...mapGetters(['getResourcesItems', 'getSharedResources']),
  },
  methods: {
    async showFiles(name, id) {
      await Vue.axios({
        method: 'GET',
        url: `/resources/attachments/${id}`,
      })
        .then((response) => {
          console.log(response);
          this.attachments = response.data;
          this.resourceName = name;
          this.files = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
