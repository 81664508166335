import Vue from 'vue';
import router from '@/router.js';

const state = {
    modalParts: false,
    parts: {
        title: 'Baza części',
        status: '',
        header: [
            {
                text: 'Index producenta',
                value: 'producent',
                width: '15%',
                class: 'central__table__header',
            },
            {
                text: 'Nazwa',
                value: 'partName',
                width: '25%',
                class: 'central__table__header',
            },
            {
                text: 'Producent ',
                value: 'manufacturer',
                width: '15%',
                class: 'central__table__header',
            },
            {
                text: 'Model',
                value: 'modelParts',
                width: '20%',
                class: 'central__table__header',
            },
            {
                text: 'Seria Modeli',
                value: 'seriesParts',
                width: '15%',
                class: 'central__table__header',
            },
            {
                text: 'Zamiennik',
                value: 'replacement',
                width: '18%',
                class: 'central__table__header',
            },
            {
                text: 'Status',
                value: 'status',
                width: '10%',
                class: 'central__table__header',
            },
            {
                text: 'Akcja',
                align: 'right',
                width: '5%',
                value: 'action',
                sortable: false,
                class: 'central__table__header',
            },
        ],
    },
    items: {
        manufacturer: [],
        series: [],
        model: [],
    },
    modal: {
        fields: {
            status: null,
            menager: '',
            producent: '',
            name: '',
            replacement: null,
            series: null,
            price: '',
            stateParts: '',
            model: '',
            comment: '',
            manufacturer: null,
        },
        errors: {
            producent: '',
            series: '',
            name: '',
            replacement: '',
            price: '',
            stateParts: '',
            model: '',
            comment: '',
        },
    },
    fieldsNames: {
        status: 'Status',
        menager: 'Menadżer',
        producent: 'Index producenta',
        name: 'Nazwa',
        replacement: 'Zamiennik',
        series: 'Seria modeli',
        price: 'Cena',
        stateParts: 'Żywotność części',
        model: 'Model',
        comment: 'Uwagi',
        manufacturer: 'Producent',
    },
    details: {
        status: null,
        menager: '',
        producent: '',
        name: '',
        replacement: '',
        series: {
            seriesName: '',
        },
        price: '',
        stateParts: '',
        model: {
            model: '',
        },
        comment: '',
        manufacturer: {},
    },
};

const getters = {
    getParts: (state) => state.parts,
    getModalParts: (state) => state.modal.fields,
    getItems: (state) => state.items,
    getModalPartsError: (state) => state.modal.errors,
    getPartsFieldsNames: (state) => state.fieldsNames,
    getPartsDetails: (state) => state.details,
};

const actions = {
    createPart({ dispatch, commit }, payload) {

        if (state.modal.fields.price >= 0) {
            Vue.axios({
                method: 'POST',
                url: '/parts',
                data: {
                    ...state.modal.fields,
                },
            })
                .then((response) => {
                    dispatch('fetchData', {
                        page: payload.page,
                        pageSize: 11,
                        search: '',
                        sortBy: [],
                        sortDesc: [],
                        routeName: 'parts',
                    });

                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie dodano nową część',
                    });
                })
                .catch((error) => {
                    if (error.response.data.error == 'Exists') {
                        state.modal.errors.producent = 'Taki numer już istnieje';
                    }
                    commit('setErrors', {
                        route: 'parts',
                        err: error.response.data.error,
                    });
                });
        }

    },
    partsDeatils({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/parts/${payload.id}`,
        })
            .then((response) => {
                if (payload.type == 'details') {
                    commit('setDetailsIndex', response.data.item.index);
                    commit('setDetailsMenager', response.data.item.menager);
                    commit('setPartsDetails', response.data.item);
                    commit('setDetailsStatus', response.data.item.status);
                    commit('setDetailsStatuses', response.data.statuses);
                    if (payload.next) {
                        payload.next();
                    }
                } else if (payload.route === 'parts_details') {
                    commit('setPartsModal', response.data.item);
                } else {
                    commit('setPartsModal', response.data.item);
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == '404') router.push('/NotFound');
            });
    },

    searchProducer({ state, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: '/search/Producer',
            data: {
                manufacturer: payload,
            },
        })
            .then((response) => {
                commit('setProducer', response.data.items);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    searchPartsSeries({ state, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: '/dictionary/ModelSeries',
            data: {
                model: payload,
            },
        })
            .then((response) => {
                commit('setPartsSeries', response.data.items);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    searchModelParts({ state, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: '/search/ModelParts',
            data: {
                modelDevices: payload,
            },
        }).then((response) => {
            commit('setModelParts', response.data.items);
        });
    },

    partsEdit({ state, commit }, payload) {
        if (state.modal.fields.price >= 0) {
            commit('resetErrors', 'parts');
            Vue.axios({
                method: 'PUT',
                url: `/parts/edit/${payload}`,
                data: {
                    ...state.modal.fields,
                },
            })
                .then((response) => {
                    commit('setPartsDetails', response.data.item);
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie edytowano wpis',
                    });
                })
                .catch((error) => {
                    if (error.response.data.error == 'Exists') {
                        state.modal.errors.producent = 'Taki numer już istnieje';
                    }
                    commit('setErrors', {
                        route: 'parts',
                        err: error.response.data.error,
                    });
                });
        }
    },
};
const mutations = {
    clearParts(state) {
        state.items.manufacturer = [];
        state.items.model = [];
        state.items.series = [];
    },
    selectDefaultReplacement() {
        state.modal.fields.replacement = 'Nie';
    },
    setPartsDetails(state, payload) {
        state.details = payload;
    },
    setProducer(state, payload) {
        state.items.manufacturer = payload;
    },
    setPartsSeries(state, payload) {
        state.items.series = payload;
    },
    setModelParts(state, payload) {
        state.items.model = payload;
    },
    setPartsModal(state, payload) {
        state.modal.fields = payload;
        state.items.manufacturer = payload.manufacturer
            ? [payload.manufacturer]
            : [];
        state.items.series = payload.series ? [payload.series] : [];
        state.items.model = [payload.model];
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
