<template>
    <v-dialog
        :width="getSecondModal.width"
        v-model="getSecondModal.open"
        right
        scrollable
        :persistent="getSecondModal.type && getSecondModal.type === 'sessionEnds'"
        temporary
        app
        style="z-index:150;"
        @input="v => v || clearData()"
    >
        <v-card
            v-if="getSecondModal.type && getSecondModal.type === 'change_password'"
            class="pa-5"
        >
            <div class="d-flex justify-center py-3">
                <!-- <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" /> -->
                <v-icon size="120" color="green">mdi-check-circle-outline</v-icon>
            </div>
            <v-card-title
                class="d-flex justify-center pa-2"
                style="font-size: 30px; color: #595959"
            >Hasło zmienione!</v-card-title>

            <v-card-text
                class="d-flex justify-center pa-2"
                style="font-size: 20px; color: #545454"
            >Nastąpi teraz wylogowanie!</v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    width="110"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="closeSecondModal(), logout()"
                >Wyloguj</v-btn>
            </v-card-actions>
        </v-card>
        <!-- CHANGE INVOICE STATUS========================== -->
        <v-card v-if="getSecondModal.type === 'invoice_status'" class="pa-5">
            <div class="d-flex justify-center py-5">
                <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" />
            </div>

            <v-card-title
                class="d-flex justify-center pa-5"
                style="font-size: 20px; color: #595959"
            >Czy została wystawiona faktura?</v-card-title>
            <!-- {{getSecondModal.id}} -->
            <v-card-text class="d-flex">
                <v-col cols="6" lg="6" sm="12" md="12">
                    <v-menu
                        v-model="invoiceMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="getInvoice.date"
                                label="Data rozliczenia zewnętrznego"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                hide-details
                                dense
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="getInvoice.date" @input="invoiceMenu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6" lg="6" sm="12" md="12">
                    <v-text-field
                        dense
                        label="Nr dokumentu"
                        v-model="getInvoice.number"
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    color="rgb(117, 117, 117)"
                    width="110"
                    height="40"
                    class="white--text"
                    @click="clearDataModal"
                >NIE</v-btn>
                <v-btn
                    width="110"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="confirmInvoice(getSecondModal.id)"
                >TAK</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="getSecondModal.type === 'claim_status'" class="pa-5">
            <div class="d-flex justify-center py-5">
                <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" />
            </div>

            <v-card-title
                class="d-flex justify-center pa-5"
                style="font-size: 20px; color: #595959"
            >
                <p>Czy został zrealizowany claim?</p>
                <v-col cols="8">
                    <v-text-field
                        dense
                        label="Nr claim"
                        v-model="getClaim.number"
                        :error-messages="errors.claim"
                    ></v-text-field>
                </v-col>
            </v-card-title>

            <!-- {{getSecondModal.id}} -->
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    color="rgb(117, 117, 117)"
                    width="110"
                    height="40"
                    class="white--text"
                    @click="clearDataModal"
                >NIE</v-btn>

                <v-btn
                    width="110"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="confirmClaim(getSecondModal.id)"
                >TAK</v-btn>
            </v-card-actions>
        </v-card>
        <!-- Wycena modal -->
        <v-card v-if="getSecondModal.type === 'valuation_status'" class="pa-5">
            <div class="d-flex justify-center py-5">
                <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" />
            </div>

            <v-card-title
                class="d-flex justify-center pa-5"
                style="font-size: 20px; color: #595959"
            >Czy została wykonana wycena?</v-card-title>
            <!-- {{getSecondModal.id}} -->
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    color="rgb(117, 117, 117)"
                    width="110"
                    height="40"
                    class="white--text"
                    @click="clearDataModal"
                >NIE</v-btn>

                <v-btn
                    width="110"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="confirmValuation(getSecondModal.id)"
                >TAK</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="getSecondModal.type === 'new_continuation'" class="pa-5">
            <div class="d-flex justify-center py-5">
                <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" />
            </div>
            <!-- {{getSecondModal.item.index}} -->
            <v-card-title
                class="d-flex justify-center pa-5"
                style="font-size: 20px; color: #595959"
            >Czy chcesz wystawić kontynuacje zlecenia?</v-card-title>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    color="rgb(117, 117, 117)"
                    width="110"
                    height="40"
                    class="white--text"
                    @click="clearDataModal"
                >NIE</v-btn>

                <v-btn
                    width="110"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="confirmNewContinuation(getSecondModal)"
                >TAK</v-btn>
            </v-card-actions>
        </v-card>
        <v-card
            v-if="
        getSecondModal.type !== 'change_password' &&
          getSecondModal.type !== 'sessionEnds' &&
          getSecondModal.type !== 'invoice_status' &&
          getSecondModal.type !== 'new_continuation' &&
          getSecondModal.type !== 'valuation_status' &&
          getSecondModal.type !== 'claim_status'
      "
            class="pa-5"
        >
            <div class="d-flex justify-center py-5">
                <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" />
            </div>

            <v-card-title
                class="d-flex justify-center pa-2"
                style="font-size: 30px; color: #595959"
            >Jesteś pewny?</v-card-title>

            <v-card-text
                class="d-flex justify-center pa-2"
                style="font-size: 20px; color: #545454"
            >Można to później przywrócić</v-card-text>

            <v-card-actions class="d-flex justify-center">
                <v-btn
                    color="rgb(117, 117, 117)"
                    width="110"
                    height="40"
                    class="white--text"
                    @click="closeSecondModal"
                >Anuluj</v-btn>

                <v-btn
                    width="110"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="confirmDelete"
                >Usuń</v-btn>
            </v-card-actions>
        </v-card>
        <!-- === === SESSION MODAL === === -->
        <v-card v-if="getSecondModal.type && getSecondModal.type === 'sessionEnds'" class="pa-5">
            <div class="d-flex justify-center py-3">
                <!-- <img width="100" height="100" src="@/assets/img/global/warning.svg" alt="warning" /> -->
                <!-- <v-icon size="120" color="#FF5252">mdi-alert-circle-outline</v-icon> -->
                <v-progress-circular
                    rotate="270"
                    size="140"
                    :value="circularTime"
                    color="#19aa8d"
                    width="8"
                >
                    <span style="font-size: 35px">
                        {{
                        timeCorrection(getSession.timer.minutes()) +
                        ":" +
                        timeCorrection(getSession.timer.seconds())
                        }}
                    </span>
                </v-progress-circular>
            </div>
            <v-card-title
                class="d-flex justify-center"
                style="font-size: 30px; color: #595959"
            >Sesja!</v-card-title>
            <!-- {{timeCorrection(getSession.timer.minutes())+":"+timeCorrection(getSession.timer.seconds())}} -->
            <v-card-text
                class="d-flex justify-center"
                style="font-size: 20px; color: #545454"
            >Niedługo kończy się Twój czas sesji</v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    width="110"
                    height="40"
                    color="rgb(117, 117, 117)"
                    class="white--text"
                    @click="closeSecondModal(), logout(), closeModal()"
                >Wyloguj</v-btn>
                <v-btn
                    width="150"
                    height="40"
                    color="#445d75"
                    class="white--text"
                    @click="closeSecondModal(), refreshSessionTime()"
                >Przedłuż sesję</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// === === GLOBAL === === //
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

// === CONTENTS === === //

export default {
    components: {},
    data: () => ({
        errors: {
            claim: "",
        },
        invoiceMenu: false,
    }),

    computed: {
        ...mapGetters([
            "getSecondModal",
            "getFolderItems",
            "getSession",
            "getClaim",
            "getInvoice",
        ]),
        circularTime() {
            let first =
                this.getSession.timer.minutes() * 60 +
                this.getSession.timer.seconds();
            let result = parseInt((first / 120) * 100);
            return result;
        },
    },
    methods: {
        ...mapMutations(["closeSecondModal", "closeModal"]),
        ...mapActions([
            "deleteTCategory",
            "deleteTPriority",
            "deleteUContract",
            "removeUDayJob",
            "deleteUJob",
            "deleteURole",
            "deleteNote",
            "deleteManufacturerBrand",
            "deleteServiceType",
            "deleteModelCar",
            "deleteSPrice",
            "deleteOptionName",
            "deleteMS",
            "sendNewContinuation",
            "sendInvoiceStatus",
            "sendClaimStatus",
            "sendValuationStatus",
            "deleteServiceCore",
            "fetchData",
            "deleteAttachment",
            "notesCount",
            "attachmentsCount",
            "removeAttachmentFromStorage",
        ]),
        confirmInvoice(id) {
            this.sendInvoiceStatus(id);
            this.clearDataModal();
            this.fetchData({
                page: 1,
                search: "",
                sortBy: [],
                sortDesc: [],
                pageSize: 11,
                routeName: "service",
            });
        },
        confirmClaim(id) {
            if (this.getClaim.number.trim() !== "") {
                this.errors.claim = "";
                this.sendClaimStatus(id);
                this.fetchData({
                    page: 1,
                    search: "",
                    sortBy: [],
                    sortDesc: [],
                    pageSize: 11,
                    routeName: "service",
                });
                this.clearDataModal();
            } else {
                this.errors.claim = "Uzupełnij pole";
            }
        },
        confirmValuation(id) {
            this.sendValuationStatus(id);
            this.fetchData({
                page: 1,
                search: "",
                sortBy: [],
                sortDesc: [],
                pageSize: 11,
                routeName: "service",
            });
        },
        confirmNewContinuation(items) {
            this.sendNewContinuation(items);
        },
        confirmDelete() {
            switch (this.getSecondModal.type) {
                case "dictionary_tasks_category":
                    this.deleteTCategory(this.getSecondModal.data.CID);
                    break;
                case "dictionary_tasks_priority":
                    this.deleteTPriority(this.getSecondModal.data.PID);
                    break;
                case "dictionary_user_contract":
                    this.deleteUContract(this.getSecondModal.data.CID);
                    break;
                case "dictionary_user_day_job":
                    this.removeUDayJob(this.getSecondModal.data.DID);
                    break;
                case "dictionary_user_job":
                    this.deleteUJob(this.getSecondModal.data.JID);
                    break;
                case "dictionary_user_role":
                    this.deleteURole(this.getSecondModal.data.UID);
                    break;
                case "note_tab":
                    this.deleteNote({
                        id: this.getSecondModal.data.ND,
                        registryName: this.$route.name,
                        registryID: this.$route.params.id,
                        page: this.getSecondModal.data.page,
                    });
                    this.notesCount({
                        registryName: this.$route.name,
                        registryID: this.$route.params.id,
                    });
                    break;
                case "dictionary_manufacturer_brand":
                    this.deleteManufacturerBrand(this.getSecondModal.data.MID);
                    break;
                case "dictionary_service_type":
                    this.deleteServiceType(this.getSecondModal.data.STID);
                    break;
                case "dictionary_cars_models":
                    this.deleteModelCar(this.getSecondModal.data.CMDD);
                    break;
                case "dictionary_price":
                    this.deleteSPrice(this.getSecondModal.data.PID);
                case "dictionary_options":
                    this.deleteOptionName(this.getSecondModal.data.DOC);
                    break;
                case "dictionary_model_series":
                    this.deleteMS(this.getSecondModal.data.SID);
                    break;
                case "dictionary_service_core":
                    this.deleteServiceCore(this.getSecondModal.data.SCID);
                    break;
                case "iso":
                    let index = this.getSecondModal.number;
                    this.getFolderItems.splice(index, 1);
                    this.closeSecondModal();
                    break;
                case "attachments":
                    this.deleteAttachment({
                        fakeName: this.getSecondModal.data.fakeName,
                        registryName: this.getSecondModal.data.registryName,
                        registryID: this.getSecondModal.data.registryID,
                    });
                    this.attachmentsCount({
                        registryName: this.$route.name,
                        registryID: this.$route.params.id,
                    });
                    break;
                case "sessionEnds":
                    break;
                case "storage":
                    this.removeAttachmentFromStorage(this.getSecondModal.data);
                    break;
                case "deleteWarehouse":
                    this.axios
                        .delete(`/warehouse/${this.getSecondModal.data}`)
                        .then((response) => {
                            if (response.data) {
                                this.fetchData({
                                    page: 1,
                                    search: "",
                                    sortBy: [],
                                    sortDesc: [],
                                    pageSize: 11,
                                    routeName: "warehouse",
                                });
                                this.closeSecondModal();
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                    break;
                case "deleteMaterial":
                    this.axios
                        .delete(`/materials/${this.getSecondModal.data}`)
                        .then((response) => {
                            if (response.data) {
                                this.fetchData({
                                    page: 1,
                                    search: "",
                                    sortBy: [],
                                    sortDesc: [],
                                    pageSize: 11,
                                    routeName: "materials",
                                });
                                this.closeSecondModal();
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                    break;
            }
        },
        logout() {
            Vue.axios({
                method: "POST",
                url: "/logout",
            }).then((response) => {
                if (response.data.done) this.$router.push("/login");
            });
        },
        clearDataModal() {
            this.closeSecondModal();
            this.getInvoice.number = "";
            this.getInvoice.date = "";
            this.getClaim.number = "";
            this.errors.claim = "";
        },
        timeCorrection(time) {
            if (time < 10) return (time = "0" + time);
            else return time;
        },
        async refreshSessionTime() {
            await Vue.axios({ method: "GET", url: "/refreshSessionTime" }).then(
                (response) => {
                    this.getSession.cookie = response.data.session.cookie;
                }
            );
        },
        clearData() {
            this.clearDataModal();
        },
    },
};
</script>
