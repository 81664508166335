<template>
    <v-dialog
        v-model="getOfficePreview.open"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        @input="v => v || closeOfficePreview()"
    >
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeOfficePreview">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{getOfficePreview.url}}</v-toolbar-title>
            </v-toolbar>

            <VueDocPreview :type="previewType" :value="emptyValue" :url="getOfficePreview.url"></VueDocPreview>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import VueDocPreview from "vue-doc-preview";
export default {
    components: {
        VueDocPreview
    },
    data() {
        return {
            emptyValue: "",
            previewType: "office"
        };
    },
    methods: {
        ...mapMutations(["closeOfficePreview"])
    },
    computed: {
        ...mapGetters(["getOfficePreview"])
    }
};
</script>