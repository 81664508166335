<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved  ? 'color: #9e9e9e' : ''"
    >
      <td style="font-size: 14px">{{item.index}}</td>

      <td style="width: 470px">
        <v-list-item-title
          style="font-size: 14px; max-width: 460px; width: 470px"
          @click="$router.push(`/contacts/${item.contractorOffers._id}`)"
        >{{ item.contractorOffers ? truncate(item.contractorOffers.name,60) : 'Brak'}}</v-list-item-title>

        <v-list-item-subtitle
          style="font-size: 12px"
          class="grey--text lighten-1"
          @click="$router.push(`/contacts/${item.contractorOffers._id}`)"
        >{{item.responsibleOffers ? truncate(showcorrectResponsible(item.responsibleOffers.name, item.responsibleOffers.lastname,item.responsibleOffers.phoneNumber,item.responsibleOffers.email), 30) : 'Brak'}}</v-list-item-subtitle>
      </td>

      <td style="font-size: 14px">{{item.createdBy.name}} {{item.createdBy.lastname}}</td>

      <td>
        <v-list-item-title
          style="font-size: 14px"
        >{{item.titleOffers ? truncate(item.titleOffers,30) : ' '}}</v-list-item-title>
        <v-list-item-subtitle
          style="font-size: 12px; color: #9e9e9e"
        >{{item.typeOffers ? truncate(item.typeOffers,30) : ' '}}</v-list-item-subtitle>
      </td>

      <td style="font-size: 14px" class="d-flex flex-column justify-center">
        <span>{{item.createdAt ? $moment(item.createdAt).format('YYYY-MM-DD') : ''}}</span>
        <span
          style="font-size: 12px; color: #9e9e9e"
        >{{item.createdAt ? $moment(item.createdAt).format('HH:mm'): ''}}</span>
      </td>

      <td style="font-size: 14px">{{item.dataimportanceOffers ? item.dataimportanceOffers : 'Brak'}}</td>

      <td>
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
      </td>
      <td style="text-align: right">
        <v-btn icon small @click="$router.push({path:`/offers/${item._id}`})">
          <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
  props: ["items"],
  data: () => ({}),
  computed: { ...mapGetters(["getPermission", "getUser"]) },
  methods: {
    truncate(source, size) {
      return source.length > size ? source.slice(0, size - 1) + "…" : source;
    },
    showcorrectResponsible(name, lastname, phoneNumber, email) {
      let Responsible;
      if (name != undefined && name != "") {
        Responsible = name;
      }
      if (lastname != undefined && lastname != "") {
        Responsible = Responsible + " " + lastname;
      }
      if (email != undefined && email != "") {
        Responsible = Responsible + " • " + email;
      }
      if (phoneNumber != undefined && phoneNumber != "") {
        Responsible = Responsible + " • " + phoneNumber;
      }
      return `${Responsible}`;
    },
  },
};
</script>
