<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === NAZWA === === -->
            <td>{{ item.name }}</td>

            <!-- === === OPIS === === -->
            <td>{{ shortDescription(item.description, 150) }}</td>

            <!-- === === DATA OSTATNIEJ MODYFIKACJI PLIKU === === -->
            <td>{{ $moment(item.updatedAt).format('YYYY-MM-DD HH:mm') }}</td>

            <!-- === === AKCJE === === -->
            <td style="text-align: right">
                <v-btn
                    icon
                    small
                    @click="$router.push({ path: `/resources/${item._id}` })"
                >
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from 'vue';
import truncate from 'lodash.truncate';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    props: ['items'],
    data: () => ({}),
    computed: {
        ...mapGetters(['getGlobalPaths']),
    },
    methods: {
        ...mapActions(['openModal', 'resourceDetails']),
        shortDescription(description, length) {
            return truncate(description, { length });
        },
    },
};
</script>
