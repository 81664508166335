<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === NAZWA PROCEDURY === === -->
            <td>{{item.procesName}}</td>

            <!-- === === NAZWA === === -->
            <td>{{item.name}}</td>

            <!-- === === INDEX === === -->
            <td>{{item.isoIndex}}</td>

            <!-- === === WERSJA === === -->
            <!-- <td>{{item.version}}</td> -->

            <!-- === === DATA OSTATNIEJ MODYFIKACJI === === -->
            <td style="font-size: 12px">{{$moment(item.updatedAt).format("YYYY-MM-DD HH:mm")}}</td>

            <!-- === === MENAGER === === -->
            <td
                style="font-size: 12px"
            >{{item.menager ? `${item.menager.name} ${item.menager.lastname}` : 'Brak'}}</td>

            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/iso/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
export default {
    props: ["items"],
    data: () => ({}),
};
</script>