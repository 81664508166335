<template>
    <v-container>
        <v-row>
            <v-col cols="6" class="d-flex flex-wrap">
                <v-col cols="12" md="12">
                    <v-text-field
                        v-model="getConstructionsDetails.name"
                        dense
                        readonly
                        :label="getConstructionsFieldsNames.name"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="getConstructionsDetails.city"
                        dense
                        readonly
                        :label="getConstructionsFieldsNames.city"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="getConstructionsDetails.postCode"
                        dense
                        readonly
                        :label="getConstructionsFieldsNames.postCode"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="getConstructionsDetails.street"
                        dense
                        readonly
                        :label="getConstructionsFieldsNames.street"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        no-resize
                        rows="1"
                        readonly
                        :value="getConstructionsDetails.investor ? getConstructionsDetails.investor.name : ''"
                        dense
                        :label="getConstructionsFieldsNames.investor"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        no-resize
                        rows="1"
                        readonly
                        :value="getConstructionsDetails.responsiblePerson ? fullName(getConstructionsDetails.responsiblePerson) : ''"
                        dense
                        :label="getConstructionsFieldsNames.responsiblePerson"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        readonly
                        v-model="getConstructionsDetails.description"
                        dense
                        :label="getConstructionsFieldsNames.description"
                    ></v-text-field>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Separator from "@/components/Global/Separator.vue";
export default {
    data: () => ({
        investors: [],
    }),
    components: { Separator },
    computed: {
        ...mapGetters([
            "getConstructionsFieldsNames",
            "getConstructionsDetails",
        ]),
    },
    methods: {
        ...mapActions(["fetchItems"]),
        fullName(person) {
            return person.name && person.lastname
                ? `${person.name} ${person.lastname}`
                : person;
        },
        // fetchRepresentatives() {
        //     this.getConstructionsFields.responsiblePerson = "";
        //     this.fetchItems({
        //         registry: "representatives",
        //         contactId: this.getConstructionsFields.investor
        //     });
        // }
    },
};
</script>