import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/';
import vuetify from './plugins/vuetify';
// import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment-timezone';
import cookies from 'vue-cookies';
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from './i18n';
import VueQuillEditor from 'vue-quill-editor';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSignaturePad from 'vue-signature-pad';
import money from 'v-money';


import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueAxios, axios);
Vue.use(VueSignaturePad);
Vue.use(money, {
	precision: 4,
});
// === === GOOGLE MAPS === === //
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAP_API,
	},
});

Vue.use(VueQuillEditor, {
	placeholder: 'Wpisz tekst',
	modules: {
		toolbar: [
			['bold', 'italic', 'underline'], // toggled buttons

			[
				{
					list: 'ordered',
				},
				{
					list: 'bullet',
				},
			],

			[
				{
					size: ['small', false, 'large', 'huge'],
				},
			], // custom dropdown

			[
				{
					color: [],
				},
				{
					background: [],
				},
			], // dropdown with defaults from theme

			[
				{
					align: [],
				},
			],

			['link'],

			['clean'],
		],
	},
});


Vue.config.productionTip = false;

axios.defaults.baseURL = `${process.env.VUE_APP_SERVER}/api/v1`;
axios.defaults.withCredentials = true;
axios.defaults.mode = 'no-cors';
axios.defaults.credentials = 'same-origin';

moment.tz.setDefault('Europe/Warsaw');
Vue.prototype.$moment = moment;
new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
