import Vue from 'vue';
import i18n from '@/i18n.js';
import findIndex from 'lodash.findindex';
import moment from 'moment-timezone';
const state = {
	representative: {
		header: [{
				text: i18n.t('representatives.header.name'),
				value: 'name',
				class: 'central__table__header',
			},
			{
				text: i18n.t('representatives.header.lastname'),
				value: 'lastname',
				class: 'central__table__header',
			},
			{
				text: i18n.t('representatives.header.position'),
				value: 'position',
				class: 'central__table__header',
			},
			{
				text: i18n.t('representatives.header.email'),
				value: 'email',
				class: 'central__table__header',
			},
			{
				text: i18n.t('representatives.header.phoneNumber'),
				value: 'phoneNumber',
				class: 'central__table__header',
			},
			{
				text: i18n.t('global.actions'),
				align: 'right',
				value: 'action',
				sortable: false,
				class: 'central__table__header',
			},
		],
	},
	modal: {
		fields: {
			name: null,
			lastname: null,
			position: null,
			phoneNumber: null,
			email: null,
			birthday: null,
			nameDay: null,
			comments: null,
		},
		errors: {
			name: null,
			lastname: null,
			position: null,
		},
	},
	table: {},
};

const getters = {
	getRepresentatives: state => state.representative,
	getRepresentativesData: state => state.modal.fields,
	getRepresentativesErrors: state => state.modal.errors,
	getRepresentativesTable: state => state.table,
};

const actions = {
	async createRepresentative({
		commit,
		dispatch
	}, payload) {
		commit('resetErrors', 'representatives');
		Vue.axios({
				method: 'POST',
				url: '/representatives',
				data: {
					...state.modal.fields,
					contactId: payload,
				},
			})
			.then(() => {
				dispatch('getRepresentativesByContact', {
					page: 1,
					search: '',
					sortBy: [],
					sortDesc: [],
					route: payload,
				});
				commit('closeModal');
				commit("setSnackbars", {
					type: "success",
					text: "Pomyślnie stworzono nowego reprezentanta"
				});

			})
			.catch(error => {
				commit('setErrors', {
					route: 'representatives',
					err: error.response.data,
				});
			});
	},
	async updateRepresentatives({
		commit,
		dispatch
	}, payload) {

		await Vue.axios({
				method: 'PUT',
				url: `/representatives/${state.modal.fields._id}`,
				data: {
					...state.modal.fields,
				},
			})
			.then(response => {
				let arr = state.table.items;
				let index = findIndex(arr, {
					_id: response.data._id
				});
				arr.splice(index, 1, response.data);
				commit('closeModal');
				commit("setSnackbars", {
					type: "success",
					text: "Pomyślnie edytowano reprezentanta"
				});
			})
			.catch(error => {
				commit('setErrors', {
					route: 'representatives',
					err: error.response.data,
				});
			});
	},
	async getRepresentativesByContact({
		commit
	}, payload) {
		await Vue.axios({
			method: 'GET',
			url: `/global/find/representatives`,
			params: {
				page: payload.page,
				search: payload.search,
				sortBy: payload.sortBy,
				sortDesc: payload.sortDesc,
				pageSize: 11,
				contactId: payload.route,
			},
		}).then(response => {

			state.table = response.data;
		});
	},
	async representativeDetails({
		commit
	}, payload) {
		Vue.axios({
				method: 'GET',
				url: `/representatives/${payload}`,
			})
			.then(resposne => {

				commit('setRepresentativesFields', resposne.data.doc);
			})
			.catch(error => {
				console.error(error);
			});
	},
};

const mutations = {
	setRepresentativesFields(state, payload) {
		state.modal.fields = payload;
		state.modal.fields.birthday = payload.birthday ? moment(payload.birthday).format('YYYY-MM-DD') : '';
		state.modal.fields.nameDay = payload.nameDay ? moment(payload.nameDay).format('YYYY-MM-DD') : '';
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};