<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="px-2 flex-wrap d-flex" v-if="getUser.isSuperAdmin">
                <v-col cols="12" md="12">
                    <Separator :text="'Dane rejestru'" />
                </v-col>
                <v-col cols="12" md="5">
                    <v-text-field
                        label="Nazwa rejestru*"
                        v-model="getRegistryData.name"
                        :error-messages="getRegistryErrors.name"
                        :class="getUser.isSuperAdmin ? '' : 'no-pointer'"
                        :readonly="!getUser.isSuperAdmin"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Index*"
                        v-model="getRegistryData.index"
                        :error-messages="getRegistryErrors.index"
                        :class="getUser.isSuperAdmin ? '' : 'no-pointer'"
                        :readonly="!getUser.isSuperAdmin"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="URL*"
                        v-model="getRegistryData.url"
                        :error-messages="getRegistryErrors.url"
                        :class="getUser.isSuperAdmin ? '' : 'no-pointer'"
                        :readonly="!getUser.isSuperAdmin"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Ikona*"
                        v-model="getRegistryData.icon"
                        :error-messages="getRegistryErrors.icon"
                        :class="getUser.isSuperAdmin ? '' : 'no-pointer'"
                        :readonly="!getUser.isSuperAdmin"
                    >
                        <template slot="prepend-inner">
                            <span style="font-size: 14px">mdi-</span>
                        </template>
                        <template slot="append">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon x-small @click="goToMdi" v-on="on">
                                        <v-icon>mdi-open-in-new</v-icon>
                                    </v-btn>
                                </template>
                                <span>Link do ikon</span>
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        label="Status rejestru*"
                        :items="select.status"
                        v-model="getRegistryData.status"
                        item-text="title"
                        item-value="value"
                        :error-messages="getRegistryErrors.status"
                        :disabled="!getUser.isSuperAdmin"
                    ></v-select>
                </v-col>
            </v-col>
            <v-col cols="12" md="12" class="px-2 flex-wrap d-flex">
                <v-col cols="12" md="12">
                    <Separator :text="'Ustawienia rejestru'" />
                </v-col>
                <v-col md="4">
                    <v-select
                        label="Domyślny status"
                        v-model="getRegistryData.defaultStatus"
                        :items="getPossibleStatuses"
                        :error-messages="getRegistryErrors.defaultStatus"
                        item-text="name"
                        item-value="_id"
                        return-object
                        hide-selected
                        ref="select"
                    >
                        <template slot="append-outer">
                            <v-menu
                                :close-on-content-click="false"
                                max-width="500"
                                min-width="500"
                                origin="center"
                                v-model="showMenu"
                                @input="v => v || clearMiniModal()"
                            >
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-icon
                                                v-on="{...menu, ...tooltip}"
                                                @click="type = 'create'"
                                            >mdi-plus</v-icon>
                                        </template>
                                        <span>Dodaj status</span>
                                    </v-tooltip>
                                </template>
                                <v-card>
                                    <v-container>
                                        <v-row>
                                            <v-col md="12" class="my-0 py-0">
                                                <span
                                                    class="headline font-weight-light"
                                                >Dodawanie statusu</span>
                                            </v-col>
                                            <v-col md="8" class="py-0 my-0">
                                                <v-text-field
                                                    label="Nazwa"
                                                    v-model="newStatus.name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col md="4" class="py-0 my-0">
                                                <v-checkbox
                                                    dense
                                                    v-model="newStatus.resolved"
                                                    label="Zamknięty"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col md="6">
                                                <span>Kolor tła:</span>
                                                <v-color-picker
                                                    v-model="newStatus.color.background"
                                                    hide-inputs
                                                ></v-color-picker>
                                            </v-col>
                                            <v-col md="6">
                                                <span>Kolor czcionki:</span>
                                                <v-color-picker
                                                    v-model="newStatus.color.font"
                                                    hide-inputs
                                                ></v-color-picker>
                                            </v-col>
                                            <v-col
                                                md="12"
                                                class="py-0 my-0"
                                                style="display: flex; justify-content:flex-end"
                                            >
                                                <v-btn
                                                    v-if="type == 'create'"
                                                    class="buttons buttons--add"
                                                    @click="addToPossibleStatus(newStatus), clearMiniModal()"
                                                >Dodaj</v-btn>
                                                <v-btn
                                                    v-else
                                                    class="buttons buttons--add"
                                                    @click="updatePossibleStatus(newStatus), clearMiniModal(), showMenu =false"
                                                >Edytuj</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-menu>
                        </template>
                        <template v-slot:item="data">
                            <div style="position: relative; width: 100%; height: 100%">
                                <span>{{data.item.name}}</span>
                                <div
                                    style="position: absolute; right: 0; top: 50%; transform: translateY(-50%)"
                                    @click.stop
                                >
                                    <v-icon small @click="editPossibleStatus(data.item)">mdi-pencil</v-icon>

                                    <v-icon
                                        small
                                        @click="removePossibleStatus(data.item)"
                                    >mdi-delete</v-icon>
                                </div>
                            </div>
                        </template>
                    </v-select>
                </v-col>
            </v-col>
            <v-col cols="12" md="12" class="px-2 flex-wrap d-flex">
                <v-col cols="12" md="12">
                    <Separator :text="'Uprawnienia'" />
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        v-model="getRegistryData.menager"
                        label="Możliwi menagerowie*"
                        chips
                        item-value="_id"
                        :error-messages="getRegistryErrors.menager"
                        :items="getDropdown.items.users"
                        multiple
                        :filter="filter"
                        :loading="getDropdown.loading"
                        :hide-no-data="getDropdown.hideNoData"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                close
                                @click:close="removeMenager(data.item._id)"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                        v-if="!data.item.picture"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                                {{ data.item.lastname }} {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-avatar>
                                <v-avatar color="secondary">
                                    <span
                                        v-if="!data.item.picture"
                                        class="white--text headline"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{data.item.lastname}} {{data.item.name}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{data.item.job}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        v-model="getRegistryData.defaultMenager"
                        label="Domyślny menager"
                        chips
                        item-value="_id"
                        :items="defaultMenagerItems"
                        :filter="filter"
                        item-disabled="false"
                        no-data-text="Brak możliwych menagerów"
                        :error-messages="getRegistryErrors.defaultMenager"
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                close
                                @click:close="removeDefaultMenager"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                        v-if="!data.item.picture"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                                {{ data.item.lastname }} {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-avatar>
                                <v-avatar color="secondary">
                                    <span
                                        v-if="!data.item.picture"
                                        class="white--text headline"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{data.item.lastname}} {{data.item.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{data.item.job}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete
                        label="User"
                        chips
                        v-model="getRegistryData.user[ACUser]"
                        item-value="_id"
                        :items="getDropdown.items[ACUser]"
                        :filter="filter"
                        multiple
                        :hide-no-data="getDropdown.hideNoData"
                        :messages="`${getRegistryData.user.users.length} użytkowników, ${getRegistryData.user.groups.length} grup, ${getRegistryData.user.departments.length} departamentów`"
                    >
                        <!-- === === === DATA IN AUTOCOMPLETE FIELD === === === -->
                        <template v-slot:selection="data">
                            <!-- === === CHIPS === === -->

                            <!-- === USERS === -->
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                v-if="ACUser === 'users'"
                                @click:close="removeUser({
                            id: data.item._id,
                            section: ACUser
                        })"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                        v-if="!data.item.picture"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                                {{ data.item.lastname }} {{ data.item.name }}
                            </v-chip>
                            <!-- === GROUPS ===  -->
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                close
                                v-else-if="ACUser === 'groups'"
                                @click:close="removeUser({
                            id: data.item._id,
                            section: ACUser
                        })"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                    >{{data.item.name[0].toUpperCase()}}</span>
                                </v-avatar>
                                {{ data.item.lastname }} {{ data.item.name }}
                            </v-chip>
                            <!-- === DEPARTMENTS === -->
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                v-else
                                @click:close="removeUser({
                            id: data.item._id,
                            section: ACUser
                        })"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                    >{{ data.item.shortName.toUpperCase()}}</span>
                                </v-avatar>
                                {{ data.item.fullName }}
                            </v-chip>
                        </template>
                        <!-- === === === DATA IN AUTOCOMPLETE MENU === === === -->
                        <template v-slot:item="data">
                            <!-- === === AVATARS === ===  -->
                            <!-- === USERS ===  -->
                            <v-list-item-avatar v-if="ACUser === 'users'">
                                <v-avatar color="secondary">
                                    <span
                                        v-if="!data.item.picture"
                                        class="white--text headline"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                            </v-list-item-avatar>
                            <!-- === GROUPS ===  -->
                            <v-list-item-avatar v-else-if="ACUser === 'groups'">
                                <v-avatar color="secondary">
                                    <span
                                        class="white--text headline"
                                    >{{data.item.name[0].toUpperCase()}}</span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <!-- === DEPARTMENTS === -->
                            <v-list-item-avatar v-else>
                                <v-avatar color="secondary">
                                    <span
                                        class="white--text headline"
                                    >{{ data.item.shortName.toUpperCase()}}</span>
                                </v-avatar>
                            </v-list-item-avatar>

                            <!-- === === CONTENT === === -->
                            <!-- === USERS ===  -->
                            <v-list-item-content v-if="ACUser === 'users'">
                                <v-list-item-title>
                                    {{ data.item.lastname}} {{data.item.name}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{data.item.job}} {{showDepartments(data.item._id)}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <!-- === GROUPS === -->
                            <v-list-item-content v-else-if="ACUser === 'groups'">
                                <v-list-item-title>
                                    {{data.item.name}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span
                                        v-for="(GWorker, index) in data.item.users"
                                        :key="GWorker._id"
                                    >
                                        <span v-if="index !== 0">&nbsp;</span>
                                        {{GWorker.name}} {{GWorker.lastname}}
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <!-- === DEPARTMENTS === -->
                            <v-list-item-content v-else>
                                <v-list-item-title>
                                    {{data.item.fullName}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-tabs
                            slot="prepend-item"
                            centered
                            style="position: sticky; top: 0; z-index: 2;; background: white"
                            background-color="white"
                            class="elevation-1"
                        >
                            <v-tab @click="ACUser = 'users'">Pracownicy</v-tab>
                            <v-tab @click="ACUser = 'groups'">Grupy</v-tab>
                            <v-tab @click="ACUser = 'departments'">Departamenty</v-tab>
                        </v-tabs>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        label="Reader"
                        chips
                        v-model="getRegistryData.reader[ACReader]"
                        item-value="_id"
                        :items="getDropdown.items[ACReader]"
                        :filter="filter"
                        multiple
                        :hide-no-data="getDropdown.hideNoData"
                        :messages="`${getRegistryData.reader.users.length} użytkowników, ${getRegistryData.reader.groups.length} grup, ${getRegistryData.reader.departments.length} departamentów`"
                    >
                        <!-- === === === DATA IN AUTOCOMPLETE FIELD === === === -->
                        <template v-slot:selection="data">
                            <!-- === === CHIPS === === -->

                            <!-- === USERS === -->
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                close
                                v-if="ACReader === 'users'"
                                @click:close="removeReader({
                            id: data.item._id,
                            section: ACReader
                        })"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                        v-if="!data.item.picture"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                                {{ data.item.lastname }} {{ data.item.name }}
                            </v-chip>
                            <!-- === GROUPS ===  -->
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                close
                                v-else-if="ACReader === 'groups'"
                                @click:close="removeReader({
                            id: data.item._id,
                            section: ACReader
                        })"
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                    >{{data.item.name[0].toUpperCase()}}</span>
                                </v-avatar>
                                {{ data.item.lastname }} {{ data.item.name }}
                            </v-chip>
                            <!-- === DEPARTMENTS === -->
                            <v-chip
                                pill
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                @click="data.select"
                                close
                                @click:close="removeReader({
                            id: data.item._id,
                            section: ACReader
                        })"
                                v-else
                            >
                                <v-avatar left color="secondary" class="mr-2">
                                    <span
                                        class="white--text caption"
                                    >{{ data.item.shortName.toUpperCase()}}</span>
                                </v-avatar>
                                {{ data.item.fullName }}
                            </v-chip>
                        </template>
                        <!-- === === === DATA IN AUTOCOMPLETE MENU === === === -->
                        <template v-slot:item="data">
                            <!-- === === AVATARS === ===  -->
                            <!-- === USERS ===  -->
                            <v-list-item-avatar v-if="ACReader === 'users'">
                                <v-avatar color="secondary">
                                    <span
                                        v-if="!data.item.picture"
                                        class="white--text headline"
                                    >{{avatarNick(data.item.name, data.item.lastname)}}</span>
                                    <img
                                        :src="`${getGlobalPaths.profilePic}${data.item.picture}`"
                                        v-else
                                        style="object-fit: cover"
                                    />
                                </v-avatar>
                            </v-list-item-avatar>
                            <!-- === GROUPS ===  -->
                            <v-list-item-avatar v-else-if="ACReader === 'groups'">
                                <v-avatar color="secondary">
                                    <span
                                        class="white--text headline"
                                    >{{data.item.name[0].toUpperCase()}}</span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <!-- === DEPARTMENTS === -->
                            <v-list-item-avatar v-else>
                                <v-avatar color="secondary">
                                    <span
                                        class="white--text headline"
                                    >{{ data.item.shortName.toUpperCase()}}</span>
                                </v-avatar>
                            </v-list-item-avatar>

                            <!-- === === CONTENT === === -->
                            <!-- === USERS ===  -->
                            <v-list-item-content v-if="ACReader === 'users'">
                                <v-list-item-title>
                                    {{data.item.lastname}} {{data.item.name}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{data.item.job}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <!-- === GROUPS === -->
                            <v-list-item-content v-else-if="ACReader === 'groups'">
                                <v-list-item-title>
                                    {{data.item.name}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                            <!-- === DEPARTMENTS === -->
                            <v-list-item-content v-else>
                                <v-list-item-title>
                                    {{data.item.fullName}}
                                    <v-chip
                                        v-if="data.item.disabled"
                                        label
                                        x-small
                                        color="grey darken-3"
                                        class="ml-4"
                                    >
                                        <span class="white--text">Zajęte</span>
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-tabs
                            slot="prepend-item"
                            centered
                            style="position: sticky; top: 0; z-index: 2; background: white"
                            class="elevation-1"
                        >
                            <v-tab @click="ACReader = 'users'">Pracownicy</v-tab>
                            <v-tab @click="ACReader = 'groups'">Grupy</v-tab>
                            <v-tab @click="ACReader = 'departments'">Departamenty</v-tab>
                        </v-tabs>
                    </v-autocomplete>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import debounce from "lodash.debounce";
import findIndex from "lodash.findindex";
export default {
    components: {
        Separator
    },
    data: () => ({
        select: {
            status: [
                { title: "Aktywny", value: true },
                { title: "Nieaktywny", value: false }
            ]
        },
        ACUser: "users",
        ACReader: "users",
        defaultMenagerItems: [],
        newStatus: {
            name: "",
            resolved: false,
            isDeleted: false,
            color: {
                background: "#000000",
                font: "#000000"
            }
        },
        type: "",
        showMenu: false
    }),
    computed: {
        ...mapGetters([
            "getRegistryData",
            "getRegistryErrors",
            "getDropdown",
            "getUser",
            "getGlobalPaths",
            "getPossibleStatuses"
        ]),
        userItems() {
            return this.getRegistryData.user[this.ACUser];
        },
        readerItems() {
            return this.getRegistryData.reader[this.ACReader];
        },
        menagerItems() {
            return this.getRegistryData.menager;
        }
    },
    watch: {
        menagerItems(newArray) {
            let arr = this.getDropdown.items.users;
            this.defaultMenagerItems = [];
            arr.filter(e => {
                if (newArray.includes(e._id)) {
                    this.defaultMenagerItems.push(e);
                }
            });
            let arrItems = [
                ...newArray,
                ...this.getRegistryData.reader.users,
                ...this.getRegistryData.user.users
            ];
            arr.forEach(element => {
                element.disabled = false;
            });
            arrItems.forEach(element => {
                let index = findIndex(arr, e => e._id == element);
            });
        },
        userItems(newArray) {
            let arr = this.getDropdown.items[this.ACUser];
            let arrItems = [
                ...newArray,
                ...this.getRegistryData.reader[this.ACUser]
            ];
            if (this.ACUser === "users") {
                arrItems = [...arrItems, ...this.getRegistryData.menager];
            }
            arr.forEach(element => {
                element.disabled = false;
            });
            arrItems.forEach(element => {
                let index = findIndex(arr, e => e._id == element);
                if (arr[index]) arr[index].disabled = true;
            });
        },
        readerItems(newArray) {
            let arr = this.getDropdown.items[this.ACReader];
            let arrItems = [
                ...newArray,
                ...this.getRegistryData.user[this.ACReader]
            ];
            if (this.ACReader === "users") {
                arrItems = [...arrItems, ...this.getRegistryData.menager];
            }
            arr.forEach(element => {
                element.disabled = false;
            });
            arrItems.forEach(element => {
                let index = findIndex(arr, e => e._id == element);
                if (arr[index]) arr[index].disabled = true;
            });
        }
    },
    methods: {
        ...mapMutations([
            "removeMenager",
            "removeUser",
            "removeReader",
            "removeDefaultMenager",
            "addToPossibleStatus",
            "clearDefaultStatus",
            "updatePossibleStatus"
        ]),
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0];
            return nick.toUpperCase();
        },
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                );
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
            } else
                return item.name
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
        },
        showDepartments(id) {
            let arr = this.getDropdown.items.departments;
            let str = "";
            for (let i of arr) {
                if (i.users) {
                    for (let j of i.users) {
                        if (j == id) str += i.fullName + " ";
                    }
                }
            }
            return str;
        },
        goToMdi() {
            window.open("https://materialdesignicons.com/", "_blank");
        },
        removePossibleStatus(obj) {
            let index = this.getPossibleStatuses.findIndex(
                item => item._id == obj._id
            );
            this.getPossibleStatuses[index].isDeleted = true;
        },
        editPossibleStatus(obj) {
            let index = this.getPossibleStatuses.findIndex(
                item => item._id == obj._id
            );
            this.type = "edit";
            this.newStatus = obj;
            this.newStatus.index = index;
            this.showMenu = true;
            this.$refs.select.blur();
        },

        clearMiniModal() {
            this.newStatus = {
                name: "",
                resolved: false,
                isDeleted: false,
                color: { background: "#000000", font: "#000000" }
            };
        }
    }
};
</script>
