<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" lg="6" class="d-flex flex-wrap mt-5">
        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            dense
            readonly
            v-model="getPatientsConsentDetails.title"
            :label="getPatientsConsentFieldsNames.title"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-text-field
            dense
            readonly
            v-model="getPatientsConsentDetails.patientsConsentIndex"
            :label="getPatientsConsentFieldsNames.patientsConsentIndex"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-text-field
            dense
            readonly
            v-model="getPatientsConsentDetails.version"
            :label="getPatientsConsentFieldsNames.version"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-text-field
            dense
            readonly
            :value="getPatientsConsentDetails.archiveDate ? correctData(getPatientsConsentDetails.archiveDate) : ''"
            :label="getPatientsConsentFieldsNames.archiveDate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-textarea
            :label="getPatientsConsentFieldsNames.information"
            v-model="getPatientsConsentDetails.information"
            readonly
            no-resize
          ></v-textarea>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    menu2: false,
    person: []
  }),
  computed: {
    ...mapGetters([
      "getPatientsConsentDetails",
      "getPatientsConsentFieldsNames"
    ])
  },
  methods: {
    correctData(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    }
  },
  components: {}
};
</script>