import Vue from 'vue';
import i18n from '@/i18n.js';
var clone = require('lodash.clone');
const state = {
    contact: {
        title: 'contacts.title',
        header: [{
                text: i18n.t('contacts.header.name'),
                value: 'name',
                class: 'central__table__header',
                width: '40%'
            },
            {
                text: i18n.t('contacts.header.NIP'),
                value: 'NIP',
                class: 'central__table__header',
                width: '10%'
            },
            {
                text: i18n.t('contacts.header.city'),
                value: 'city',
                class: 'central__table__header',
                width: '10%'
            },
            {
                text: i18n.t('contacts.header.street'),
                value: 'street',
                class: 'central__table__header',
                width: '10%'
            },
            {
                text: i18n.t('contacts.header.phoneNumber'),
                value: 'phoneNumber',
                class: 'central__table__header',
                width: '10%'
            },
            {
                text: 'Adres www',
                value: 'website',
                class: 'central__table__header',
                width: '10%'
            },
            {
                text: i18n.t('global.actions'),
                align: 'right',
                value: 'action',
                sortable: false,
                class: 'central__table__header',
                width: '10%'
            }
        ]
    },
    modal: {
        fields: {
            name: null,
            shortName: null,
            contactId: null,
            NIP: null,
            street: null,
            apartmentNumber: null,
            houseNumber: null,
            postCode: null,
            post: null,
            website: null,
            city: null,
            email: null,
            state: null,
            contactType: null,
            type: null,
            phoneNumber: null
        },
        errors: {
            name: null,
            NIP: null,
            street: null,
            houseNumber: null,
            postCode: null,
            city: null,
            contactType: null,
            contactId: null
        }
    },
    details: {
        fields: {}
    },
    fieldsNames: {
        name: 'Pełna nazwa',
        shortName: 'Nazwa skrócona',
        contactId: 'ID Kontaktu',
        NIP: 'NIP',
        street: 'Ulica',
        apartmentNumber: 'Numer lokalu',
        houseNumber: 'Numer domu',
        postCode: 'Kod pocztowy',
        post: 'Poczta',
        city: 'Miasto',
        email: 'Email',
        website: 'Adres www',
        state: 'Państwo',
        contactType: 'Rodzaj kontaktu',
        type: 'Typ kontaktu',
        phoneNumber: 'Numer telefonu'
    },
    RightBox: []
};

const getters = {
    getContacts: state => state.contact,
    getContactsData: state => state.modal.fields,
    getContactsErrors: state => state.modal.errors,
    getContactsDetailsFields: state => state.details.fields,
    getContactFieldsNames: state => state.fieldsNames,
    getRBContacts: state => state.RightBox
};

const actions = {
    setContactsModalFields({
        commit,
        dispatch
    }, payload) {
        commit('resetErrors', 'contacts');
        commit('setContactsDetails', payload);
    },
    async createContact({
        commit,
        dispatch
    }, payload) {
    console.log(payload);
        if (state.modal.fields.contactType == 'Osoba prawna' && payload.wsd != true) {
            if (state.modal.fields.NIP == '') {
                return state.modal.errors.NIP = 'To pole jest wymagane'
            } else {
                commit('resetErrors', 'contacts');

                Vue.axios({
                        method: 'POST',
                        url: '/contacts',
                        data: {
                            ...state.modal.fields
                        }
                    })
                    .then(() => {
                        dispatch('fetchData', {
                            page: 1,
                            pageSize: 11,
                            search: '',
                            sortBy: [],
                            sortDesc: [],
                            routeName: 'contacts'
                        });
                        commit('closeModal');
                        commit('setSnackbars', {
                            type: 'success',
                            text: 'Pomyślnie stworzono nowy kontkakt'
                        });
                    })
                    .catch(error => {
                        if (error.response.data.errors.duplicates) {
                            if (error.response.data.errors.duplicates.NIP) {
                                state.modal.errors.NIP = 'Ta wartość jest już używana';
                            }
                            if (error.response.data.errors.duplicates.ID) {
                                state.modal.errors.contactId =
                                    'Ta wartość jest już używana';
                            }
                        }
                        console.log(error.response.data);
                        commit('setErrors', {
                            route: 'contacts',
                            err: error.response.data
                        });
                    });
            }

        } else {
            commit('resetErrors', 'contacts');
            Vue.axios({
                    method: 'POST',
                    url: '/contacts',
                    data: {
                        ...state.modal.fields
                    }
                })
                .then(() => {
                    dispatch('fetchData', {
                        page: 1,
                        pageSize: 11,
                        search: '',
                        sortBy: [],
                        sortDesc: [],
                        routeName: 'contacts'
                    });
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie stworzono nowy kontkakt'
                    });
                })
                .catch(error => {
                    if (error.response.data.errors.duplicates) {
                        if (error.response.data.errors.duplicates.NIP) {
                            state.modal.errors.NIP = 'Ta wartość jest już używana';
                        }
                        if (error.response.data.errors.duplicates.ID) {
                            state.modal.errors.contactId =
                                'Ta wartość jest już używana';
                        }
                    }
                    console.log(error.response.data);
                    commit('setErrors', {
                        route: 'contacts',
                        err: error.response.data
                    });
                });
        }

    },
    async contactDetails({
        commit
    }, payload) {
        await Vue.axios({
                method: 'GET',
                url: `/contacts/${payload.id}`
            })
            .then(response => {
                commit('setDetailsIndex', response.data.doc.index);
                commit('setDetailsMenager', {
                    ...response.data.doc.menager,
                    update: false
                });
                commit('setContactsDetails', response.data.doc);
                commit('setDetailsStatus', response.data.doc.status);
                commit('setDetailsStatuses', response.data.statuses);
                commit('setContactsDetailsFields', response.data.doc);
                if (payload.next) {
                    payload.next();
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    async updateContact({
        commit
    }, payload) {

        if (state.modal.fields.contactType == 'Osoba prawna') {
            if (state.modal.fields.NIP == '') {
                return state.modal.errors.NIP = 'To pole jest wymagane'
            } else {
                await Vue.axios({
                        method: 'PUT',
                        url: `/contacts/${state.modal.fields._id}`,
                        data: {
                            ...state.modal.fields
                        }
                    })
                    .then(response => {
                        commit('setContactsDetailsFields', response.data);
                        commit('updateItem', response.data);
                        commit('closeModal');
                        commit('setSnackbars', {
                            type: 'success',
                            text: 'Pomyślnie edytowano kontkakt'
                        });
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        commit('setErrors', {
                            route: 'contacts',
                            err: error.response.data
                        });
                    });
            }
        } else {
            await Vue.axios({
                    method: 'PUT',
                    url: `/contacts/${state.modal.fields._id}`,
                    data: {
                        ...state.modal.fields
                    }
                })
                .then(response => {
                    commit('setContactsDetailsFields', response.data);
                    commit('updateItem', response.data);
                    commit('closeModal');
                    commit('setSnackbars', {
                        type: 'success',
                        text: 'Pomyślnie edytowano kontkakt'
                    });
                })
                .catch(error => {
                    console.log(error.response.data);
                    commit('setErrors', {
                        route: 'contacts',
                        err: error.response.data
                    });
                });
        }
    },

    RBContacts({
        commit
    }, payload) {
        Vue.axios({
                method: 'GET',
                url: `/rightbox/contacts/${payload}`
            })
            .then(response => {
                commit('setRBContacts', response.data.items);
            })
            .catch(error => {
                console.log(error.response);
            });
    }
};

const mutations = {
    setContactsDetails(state, payload) {
        state.modal.fields = clone(payload);
    },
    setContactsDetailsFields(state, payload) {
        state.details.fields = clone(payload);
    },
    setRBContacts(state, payload) {
        state.RightBox = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};