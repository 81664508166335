<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">Przypomnienia</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    header: "Example file"
  })
};
</script>
