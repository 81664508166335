<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
        >
            <!-- === === DATA === === -->
            <!-- <td>{{item.startDate ? $moment(item.startDate).format('YYYY-MM-DD HH:mm:ss') : 'Brak'}}</td> -->

            <!-- === === JEDNOSTKA ORGANIZACYJNA WSD === === -->
            <td class="tabeleFont">{{item.unit && item.unit.fullName ? item.unit.fullName : ''}}</td>
            <td class="tabeleFont">{{item.organizationalUnit ? item.organizationalUnit : ''}}</td>
            <td
                class="tabeleFont"
            >{{item.worker ? `${item.worker.name} ${item.worker.lastname}` : ''}}</td>
            <td class="tabeleFont">{{item.landlinePhone ? item.landlinePhone : ''}}</td>
            <td class="tabeleFont">{{item.phone ? item.phone : ''}}</td>
            <td v-html="mailTo(item.email)" class="tabeleFont"></td>
            <td class="tabeleFont">{{item.location ? item.location : ''}}</td>
            <td
                v-if="getRegistryHeader.updatedAt"
                class="tabeleFont"
            >{{item.updatedAt ? $moment(item.updatedAt).format('YYYY-MM-DD HH:mm') : ''}}</td>

            <!-- === === STATUS === === -->
            <!-- <td>
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>-->

            <!-- === === DODAŁ === === -->

            <!-- <td style="font-size: 12px">{{item.menager.name}} {{item.menager.lastname}}</td> -->

            <!-- === === AKCJA === === -->
            <td v-if="getRegistryHeader.action" style="text-align: right">
                <v-btn icon small @click="$router.push({path:`/internal-contacts/${item._id}`})">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission", "getUser", "getRegistryHeader"]),
    },
    methods: {
        mailTo(mail) {
            return mail ? `<a href="mailto:${mail}">${mail}</a>` : "";
        },
    },
};
</script>