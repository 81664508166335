<template>
    <tbody>
        <tr
            :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved ? 'color: #9e9e9e' : ''"
            v-for="(item, index) in items"
            :key="index"
        >
            <!-- === === DATA === === -->
            <!-- <td>{{item.createdAt ? $moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'Brak'}}</td> -->

            <!-- === === NAZWA === === -->
            <td>{{item.title ? item.title : 'Brak'}}</td>

            <!-- === === STATUS === === -->
            <!-- <td>
                <v-chip
                    :hidden="!item.status.possibleStatus"
                    :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
                    :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
                    dark
                    label
                    small
                >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
            </td>-->

            <!-- === === MENAGER === === -->

            <td
                style="font-size: 12px"
            >{{item.menager ? `${item.menager.name} ${item.menager.lastname}` : `${getDefaultMenager.name} ${getDefaultMenager.lastname}` }}</td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right">
                <v-btn icon small @click="$router.push({ path: `/diet-nutrition/${item._id}` })">
                    <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
                </v-btn>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
    props: ["items"],
    data: () => ({}),
    computed: {
        ...mapGetters(["getPermission", "getDefaultMenager"]),
    },
};
</script>