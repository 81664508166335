import Vue from 'vue';
import findIndex from 'lodash.findindex';
const state = {
	priority: {
		open: false,
		priorityName: '',
		background: '#000000',
		color: '#000000',
	},
	type: '',
	items: [],
	errors: {
		priorityName: '',
	},
};

const getters = {
	getTPriority: state => state.priority,
	getTPriorityType: state => state.type,
	getTPriorityItems: state => state.items,
	getPriorityErrors: state => state.errors,
};

const actions = {
	createTPriority({ state, commit }) {
		state.errors.priorityName = '';
		Vue.axios({
			method: 'POST',
			url: '/dictionary/tasks/priority',
			data: {
				...state.priority,
			},
		})
			.then(response => {
				commit('clearTPriority');
				commit('setPriorityItem', response.data.item);

				// Vue.swal('Dodano!', 'Pomyślnie stworzono nowy wpis', 'success');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie stworzono nowy wpis',
				});
			})
			.catch(error => {
				if (error.response.data.error === 'Exists') {
					state.errors.priorityName = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.priorityName.kind ===
					'required'
				) {
					state.errors.priorityName = 'Pole jest wymagane';
				}
			});
	},

	deleteTPriority({ commit }, payload) {
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/tasks/priority/delete/${payload}`,
		})
			.then(() => {
				commit('deleteTPItem', payload);
				// Vue.swal('Usunięto!', 'Pomyślnie usunięto wpis', 'success');
				commit('closeSecondModal');
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie usunięto wpis',
				});
			})
			.catch(() => {
				// Vue.swal('Błąd!', 'Coś poszło nie tak', 'error');
				commit('setSnackbars', {
					type: 'error',
					text: 'Coś poszło nie tak',
				});
			});
	},

	fetchTPriority({ state, commit }) {
		Vue.axios({
			method: 'GET',
			url: '/dictionary/tasks/priority',
		}).then(response => {
			commit('setPriorityItems', response.data.items);
		});
	},

	TPriorityDetails({ commit }, payload) {
		Vue.axios({
			method: 'GET',
			url: `/dictionary/tasks/priority/${payload}`,
		}).then(response => {
			commit('setTPriorityDetails', response.data.item);
		});
	},
	TPriorityUpdate({ commit }) {
		state.errors.priorityName = '';
		Vue.axios({
			method: 'PUT',
			url: `/dictionary/tasks/priority/update/${state.priority._id}`,
			data: {
				...state.priority,
			},
		})
			.then(response => {
				commit('clearTPriority');
				commit('updatePTItem', response.data.item);
				commit('setSnackbars', {
					type: 'success',
					text: 'Pomyślnie edytowano dany wpis',
				});
			})
			.catch(error => {
				if (error.response.data.error === 'Exists') {
					state.errors.priorityName = 'Nazwa jest już używana';
				} else if (
					error.response.data.error.errors.priorityName.kind ===
					'required'
				) {
					state.errors.priorityName = 'Pole jest wymagane';
				}
			});
	},
};

const mutations = {
	setTPriorityType(state, payload) {
		state.type = payload;
	},
	setPriorityItem(state, payload) {
		state.items = [payload, ...state.items];
	},
	setPriorityItems(state, payload) {
		state.items = payload;
	},
	clearTPriority(state) {
		state.priority.open = false;
		state.priority.priorityName = '';
		state.priority.background = '#000000';
		state.priority.color = '#000000';
		state.errors.priorityName = '';
	},
	deleteTPItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, e => e._id == payload);
		arr.splice(index, 1);
	},
	setTPriorityDetails(state, payload) {
		state.priority.priorityName = payload.priorityName;
		state.priority.color = payload.color;
		state.priority.background = payload.background;
		state.priority._id = payload._id;
		state.priority.open = true;
	},
	updatePTItem(state, payload) {
		let arr = state.items;
		let index = findIndex(arr, {
			_id: payload._id,
		});
		arr.splice(index, 1, payload);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
