<template>
	<div>
		<v-tooltip left v-if="$route.name == 'devices' && getPermission.level > 1">
			<template v-slot:activator="{ on }">
				<v-btn
					fab
					dark
					v-on="on"
					small
					class="buttons--add"
					@click="editDevices"
				>
					<v-icon small>mdi-plus</v-icon>
				</v-btn>
			</template>
			<span>Dodaj urządzenie</span>
		</v-tooltip>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
	data: () => ({}),
	methods: {
		...mapMutations(['resetDeviceItems']),
		...mapActions([
			'openModal',
			'fetchContactsDeliver',
			'fetchTPriority',
			'fetchServiceBase',
		]),
		editDevices() {
			this.fetchTPriority();
			this.fetchContactsDeliver();
			this.fetchServiceBase();
			this.resetDeviceItems();
			this.openModal({
				headLine: 'global.create',
				open: true,
				type: 'create',
				width: 1900,
				route: 'devices',
				tab: 'info_devices',
			});
		},
	},
	computed: {
		...mapGetters(['getPermission']),
	},
};
</script>
