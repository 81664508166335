<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn fab dark v-on="on" small class="buttons--add" @click="createOffers">
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    </template>
    <span>Dodaj nową ofertę</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data: () => ({}),
   methods: {
     ...mapMutations(["resetOffersItems"]),
     ...mapActions(["openModal","fetchItems"]),
     createOffers() {
      this.resetOffersItems();
      this.openModal({
        headLine: "global.create",
        width: 900,
        open: true,
        type: "create",
        route: "Offers",
        tab: "offers"
      });
     }



   }
};
</script>