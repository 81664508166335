<template>
  <v-col cols="12" md="12" class="d-flex flex-wrap">
    <v-col cols="12" md="12" class="pb-0">
      <Separator :text="'Dane oddziału'" />
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field
        label="Nazwa oddziału"
        v-model="getBranchesData.name"
        :error-messages="getBranchesErrors.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        label="Miasto"
        v-model="getBranchesData.city"
        :error-messages="getBranchesErrors.city"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        label="Kod pocztowy"
        v-model="getBranchesData.postCode"
        :error-messages="getBranchesErrors.postCode"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <v-text-field
        label="Adres"
        v-model="getBranchesData.address"
        :error-messages="getBranchesErrors.address"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12" class="pb-0">
      <Separator :text="'Dane dodatkowe'" />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field label="Numer telefonu" v-model="getBranchesData.phoneNumber"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field label="Adres email" v-model="getBranchesData.email"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <span v-if="false" style="color: red">*Podana lokalizacja nie istnieje!</span>
    </v-col>
  </v-col>
</template>
<script>
import Separator from "@/components/Global/Separator.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    Separator
  },
  computed: {
    ...mapGetters(["getBranchesData", "getBranchesErrors"])
  }
};
</script>