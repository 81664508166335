import Vue from 'vue';
import findIndex from 'lodash.findindex';

const state = {
    series: {
        open: false,
        seriesName: ''
    },
    errors: {
        seriesName: ''
    },
    items: [],
    type: ''
};

const getters = {
    getSeries: state => state.series,
    getSeriesItems: state => state.items,
    getSeriesErrors: state => state.errors,
    getSeriesType: state => state.type
};

const actions = {
    createSeries({ commit }) {
        state.errors.seriesName = '';
        Vue.axios({
            method: 'POST',
            url: '/dictionary/service/series',
            data: {
                ...state.series
            }
        })
            .then(response => {
                commit('clearMS');
                commit('setMSItem', response.data.item);
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie dodano dany wpis'
                });
            })
            .catch(error => {
                console.log(error.response.data);
                if (error.response.data.msg === 'Exists') {
                    state.errors.seriesName = 'Nazwa jest już używana';
                } else {
                    if (
                        error.response.data.error.errors.seriesName &&
                        error.response.data.error.errors.seriesName.kind ===
                            'required'
                    )
                        state.errors.seriesName = 'Pole wymagane';
                }
            });
    },

    searchSeriesModel({ state, commit }, payload) {
        Vue.axios({
            method: 'POST',
            url: '/dictionary/ModelSeries',
            data: {
                model: payload
            }
        })
            .then(response => {
                commit('setSeriesModelItems', response.data.items);
            })
            .catch(error => {
                console.log(error);
            });
    },

    fetchSeries({ commit }) {
        Vue.axios({
            method: 'GET',
            url: '/dictionary/service/series'
        })
            .then(response => {
                commit('setModelSeriesItems', response.data.items);
            })
            .catch(error => {
                console.log(error.response);
            });
    },

    deleteMS({ commit }, payload) {
        Vue.axios({
            method: 'PUT',
            url: `/dictionary/service/series/${payload}`
        })
            .then(response => {
                commit('deleteMSItem', payload);
                commit('closeSecondModal');
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie usunięto wpis'
                });
            })
            .catch(error => {
                console.log(error);
            });
    },
    MSDetails({ commit }, payload) {
        Vue.axios({
            method: 'GET',
            url: `/dictionary/service/series/${payload}`
        })
            .then(response => {
                commit('setMSDetails', response.data.item);
            })
            .catch(error => {
                console.log(error);
            });
    },
    MSUpdate({ commit }, payload) {
        state.errors.seriesName = '';
        Vue.axios({
            method: 'PUT',
            url: `/dictionary/service/series/update/${state.series._id}`,
            data: {
                ...state.series
            }
        })
            .then(response => {
                commit('clearMS');
                commit('updateMSItem', response.data.item);
                commit('setSnackbars', {
                    type: 'success',
                    text: 'Pomyślnie edytowano dany wpis'
                });
            })
            .catch(error => {
                console.log(error.response.data);
                if (error.response.data.msg === 'Exists') {
                    state.errors.seriesName = 'Nazwa jest już używana';
                } else {
                    if (
                        error.response.data.error.errors.seriesName &&
                        error.response.data.error.errors.seriesName.kind ===
                            'required'
                    )
                        state.errors.seriesName = 'Pole wymagane';
                }
            });
    }
};

const mutations = {
    setSeriesModelItems(state, payload) {
        state.items = payload;
    },

    clearSerial(state) {
        state.items = [];
    },

    setModelSeriesItems(state, payload) {
        state.items = payload;
    },
    setMSItem(state, payload) {
        state.items = [payload, ...state.items];
    },
    clearMS(state) {
        state.series.seriesName = '';
        state.errors.seriesName = '';
        state.series.open = false;
    },
    deleteMSItem(state, payload) {
        let arr = state.items;
        let index = findIndex(arr, e => e._id == payload);
        arr.splice(index, 1);
    },
    setMSDetails(state, payload) {
        state.series = payload;
        state.series.open = true;
    },
    setMSType(state, payload) {
        state.type = payload;
    },
    updateMSItem(state, payload) {
        let arr = state.items;
        let index = findIndex(arr, {
            _id: payload._id
        });
        arr.splice(index, 1, payload);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
