<template>
  <tbody>
    <tr
      v-for="(item, index) in items"
      :key="index"
      :style="item.status && item.status.possibleStatus && item.status.possibleStatus.resolved  ? 'color: #9e9e9e' : ''"
    >
      <!-- === === INDEX === === -->
      <td style="font-size: 14px">{{item.index}}</td>
      <!-- === === NUMER === === -->
      <td style="font-size: 14px">{{item.nrLeasing}}</td>
      <!-- === === Leasingodawca ====== -->
      <td>
        <v-list-item-title
          style="font-size: 14px"
          @click="$router.push(`/contacts/${item.contractorLeasing._id}`)"
        >{{ item.contractorLeasing ? truncate(item.contractorLeasing.name , 75) : 'Brak'}}</v-list-item-title>

        <v-list-item-subtitle
          style="font-size: 12px"
          class="grey--text lighten-1"
          @click="$router.push(`/contacts/${item.contractorLeasing._id}`)"
        >
          {{item.responsibleLeasing ? truncate(showcorrectResponsible(item.responsibleLeasing.name, item.responsibleLeasing.lastname,item.responsibleLeasing.phoneNumber,item.responsibleLeasing.email), 85) : 'Brak'}}
          <!-- {{item.responsibleLeasing.name}}
            {{item.responsibleLeasing.lastname}} • 
            {{item.responsibleLeasing.email}} • 
          {{item.responsibleLeasing.phoneNumber}}-->
        </v-list-item-subtitle>
      </td>
      <!-- === === DATA POCZĄTKU === === -->
      <td style="font-size: 14px">{{item.databeginningLeasing ? item.databeginningLeasing : 'Brak'}}</td>
      <!-- === === DATA KOŃCA === === -->
      <td style="font-size: 14px">{{item.dataendLeasing ? item.dataendLeasing : 'Brak'}}</td>
      <!-- === === Wartość miesięczna netto === === -->
      <td style="text-align: left">
        <money v-bind="money" readonly v-model="item.valuemonthlyLeasing"></money>
      </td>
      <!-- === === STATUS === === -->
      <td>
        <v-chip
          :color="item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.background ? item.status.possibleStatus.color.background : '#555555'"
          :style="`color: ${item.status && item.status.possibleStatus && item.status.possibleStatus.color && item.status.possibleStatus.color.font ? item.status.possibleStatus.color.font : '#FFFFFF'}`"
          dark
          label
          small
        >{{ item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak' }}</v-chip>
      </td>
      <!-- === === AKCJA === === -->
      <td style="text-align: right">
        <v-btn icon small @click="$router.push({path:`/leasing/${item._id}`})">
          <v-icon color="#19aa8d" small>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>


<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import { money } from "v-money";
export default {
  props: ["items"],

  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      suffix: " PLN",
      precision: 2,
      masked: false,
    },
  }),
  computed: { ...mapGetters(["getPermission", "getUser"]) },
  methods: {
    truncate(source, size) {
      return source.length > size ? source.slice(0, size - 1) + "…" : source;
    },

    showcorrectResponsible(name, lastname, phoneNumber, email) {
      let Responsible;
      if (name != undefined && name != "") {
        Responsible = name;
      }
      if (lastname != undefined && lastname != "") {
        Responsible = Responsible + " " + lastname;
      }
      if (email != undefined && email != "") {
        Responsible = Responsible + " • " + email;
      }
      if (phoneNumber != undefined && phoneNumber != "") {
        Responsible = Responsible + " • " + phoneNumber;
      }
      return `${Responsible}`;
    },
  },
};
</script>