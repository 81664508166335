var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,style:(item.status &&
			item.status.possibleStatus &&
			item.status.possibleStatus.resolved
				? 'color: #9e9e9e'
				: '')},[_c('td',[_vm._v(_vm._s(item.index))]),_c('td',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('YYYY-MM-DD')))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.investor ? item.investor.name : ''))]),(_vm.getRegistryHeader.status)?_c('td',[_c('v-chip',{style:(("color: " + (item.status &&
						item.status.possibleStatus &&
						item.status.possibleStatus.color &&
						item.status.possibleStatus.color.font
							? item.status.possibleStatus.color.font
							: '#FFFFFF'))),attrs:{"hidden":!item.status.possibleStatus,"color":item.status &&
					item.status.possibleStatus &&
					item.status.possibleStatus.color &&
					item.status.possibleStatus.color.background
						? item.status.possibleStatus.color.background
						: '#555555',"dark":"","label":"","small":""}},[_vm._v(_vm._s(item.status && item.status.possibleStatus && item.status.possibleStatus.name ? item.status.possibleStatus.name : 'Brak'))])],1):_vm._e(),(_vm.getRegistryHeader.action)?_c('td',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ path: ("/constructions/" + (item._id)) })}}},[_c('v-icon',{attrs:{"color":"#19aa8d","small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }